import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ImpactActions,
    selectDateRangeEvents,
    selectIsLoadingEvents,
    selectEvents,
} from '@cityair/modules/impact/store/impact.feature';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { TEXTS } from '@libs/common';
import { MeasureScheme } from '@libs/common';
import { EventImpact } from '@cityair/modules/impact/service/api-model-impact';
import { Router } from '@angular/router';
import { MAIN_PAGES } from '@libs/common';
import * as moment from 'moment-timezone';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EventDateRange } from '@libs/common';
import { CheckboxItem } from '@libs/common';
import { selectCurrentMeasureScheme } from '@libs/shared-store';

@Component({
    selector: 'ca-impact-event-list',
    templateUrl: 'impact-event-list.component.html',
    styleUrls: ['impact-event-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactEventListComponent implements OnDestroy {
    selectIsLoadingEvents = selectIsLoadingEvents;
    textsScheme = TEXTS.MEASURES_SCHEME;
    schema = MeasureScheme.default;
    currentSchema: MeasureScheme;
    textNames = TEXTS.NAMES;
    labelPeriod = TEXTS.PLUMES.runResultLabelPeriod;
    texts = TEXTS.IMPACT.eventList;
    dateRange: EventDateRange;
    calendarIsOpened = false;
    public dateTimeBegin: number;
    public dateTimeEnd: number;
    numberOfFutureDays = 1;
    handleUpdateDays: (numberOfFutureDays?: number, minDate?: string) => void;
    mmtsFilterIsOpen = false;
    mmtList: CheckboxItem[] = [];
    public ngDestroyed$ = new Subject<void>();
    events: EventImpact[] = [];
    currentMmtFilter: CheckboxItem = {
        id: null,
        label: this.texts.filterLabelAll,
        selected: true,
    };
    public sortDirection = 1;
    public sortField = 'start';
    constructor(
        readonly store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router
    ) {
        store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.list }));
        store
            .select(selectDateRangeEvents)
            .pipe(
                filter((v) => v !== null),
                takeUntil(this.ngDestroyed$)
            )
            .subscribe((dateRange) => {
                this.dateRange = dateRange;
                this.dateTimeBegin = moment(dateRange.start).valueOf();
                this.dateTimeEnd = moment(dateRange.end).valueOf();
            });
        store
            .select(selectEvents)
            .pipe(
                filter((v) => v !== null),
                takeUntil(this.ngDestroyed$)
            )
            .subscribe((events) => {
                this.events = events;
                const list = events.reduce((acc, item) => {
                    if (acc.includes(item.param)) {
                        return acc;
                    }
                    return [...acc, item.param];
                }, []);

                this.mmtList = this.prepareFilterListMmt(list);
            });
        this.store
            .select(selectCurrentMeasureScheme)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.currentSchema = data;
                _changeDetectorRef.markForCheck();
            });
    }

    selectEvent(event: EventImpact) {
        this.router.navigate([`${MAIN_PAGES.events}/${IMPACT_PAGES.list}/${event.id}`]);
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    toggleCalendar() {
        if (this.calendarIsOpened) {
            this.handleUpdateDays(this.numberOfFutureDays);
        }
    }

    updateDays = (fn: (numberOfFutureDays?: number, minDate?: string) => void) =>
        (this.handleUpdateDays = fn);

    updateDateRange = (begin: number, finish: number) => {
        this.calendarIsOpened = false;
        const start = moment(begin).startOf('day').toISOString();
        const end = moment(finish).endOf('day').toISOString();
        this.store.dispatch(ImpactActions.clearEvents());
        this.store.dispatch(
            ImpactActions.updateDateRangeEvents({
                payload: {
                    start,
                    end,
                },
            })
        );
    };

    updateMmtFilter($event) {
        this.currentMmtFilter = $event.find((v) => v.selected);
    }

    private prepareFilterListMmt(list) {
        const result = [
            {
                id: null,
                label: this.texts.filterLabelAll,
                selected: this.currentMmtFilter === null || this.currentMmtFilter?.id === null,
            },
        ];
        list?.forEach((v) => {
            result.push({
                id: v,
                label: this.textNames[v],
                selected: v === this.currentMmtFilter?.id,
            });
        });

        return result;
    }

    public setSortingCb(sortCb: string): void {
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
    }
}
