import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { formatNumber } from '@angular/common';
import { LANGUAGE, TEXTS, customNumberFormat, isFalseNumber } from '@libs/common';

@Component({
    selector: 'cityair-control-point-plume-pin',
    templateUrl: './control-point-plume-pin.component.html',
    styleUrls: ['./control-point-plume-pin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlPointPlumePinComponent implements OnChanges {
    isFalseNumber = isFalseNumber;
    texts = TEXTS.PLUMES;
    public textScheme = TEXTS.MEASURES_SCHEME;
    public textNames = TEXTS.NAMES;
    @Input() value: number;
    @Input() numberAfterDot?: number = 0;
    @Input() selected: boolean;
    @Input() name: string;
    @Input() color: string;
    @Input() decimalFormat = false; // обрезать нули после запятой
    @Input() draggable = false;
    @Input() isCollapse = false;

    public displayValue: number | string;
    public currentLang = LANGUAGE;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.value) {
            const { value, numberAfterDot } = this;
            const hasNoData = isFalseNumber(value);
            const formatValue = formatNumber(
                value,
                LANGUAGE,
                '1.' + numberAfterDot + '-' + numberAfterDot
            );
            this.displayValue = hasNoData
                ? '–'
                : customNumberFormat(formatValue, numberAfterDot, LANGUAGE);
        }
    }
}
