<div
    class="page-tabs"
    [class.page-tabs--full]="isFullWidth"
    [class.page-tabs--height]="isFullHeight"
>
    <div
        class="page-tabs__tab"
        *ngFor="let tab of tabs; index as i;"
        [class.page-tabs__tab--inactive]="tab !== selectedTab"
        [class.page-tabs__tab--disable]="tab.isDisabled"
        [class.separator]="tab.withSeparator"
        [class.after-separator]="i > 0 && tabs[i - 1].withSeparator"
        (click)="selectTab(tab)"
        [attr.data-cy]="tab.type"
    >
        <div class="page-tabs__tab__title" [ngClass]="{ 'separator': tab.withSeparator }" [innerHTML]="tab.title"></div>
        <div [class.page-tabs__tab__underline]="tab === selectedTab"></div>
        <div class="page-tabs__tab__hover"></div>
    </div>
</div>
