import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { CityPinModule } from '../city-pin/city-pin.module';
import { GlobalMapComponent } from './global-map.component';
import { MarkerIconComponent } from './marker-icon/marker-icon.component';
import { IndoorMarkerComponent } from './indoor-marker/indoor-marker.component';

const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1IjoiY2l0eWFpciIsImEiOiJjazk4MWhja3IwNHZiM25waDJkeWhheHQ0In0.2cWaHj0iCYNPagxl9GBTuA';
@NgModule({
    exports: [GlobalMapComponent],
    declarations: [GlobalMapComponent, MarkerIconComponent, IndoorMarkerComponent],
    imports: [
        NgxMapboxGLModule.withConfig({
            accessToken: MAPBOX_ACCESS_TOKEN,
        }),
        CommonModule,
        CityPinModule,
    ],
})
export class GlobalMapModule {}
