import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { AQI } from '@libs/common';
import { AqiType } from '@libs/common';
import { TEXTS } from '@libs/common';

@Component({
    selector: 'chart-checkbox-aqi',
    templateUrl: './chart-checkbox-aqi.component.html',
    styleUrls: ['./chart-checkbox-aqi.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartCheckboxAqiComponent {
    @Input() mmt: string;
    @Input() unit: string;
    @Input() aqiTooltip: TemplateRef<any>;
    @Input() isSelected: boolean;
    @Input() mmtInfoIcon?: {
        name: string;
        cb: () => void;
    };
    @Output() selectMmt = new EventEmitter<void>();
    @Output() toggleMmt = new EventEmitter<void>();

    textNames = TEXTS.NAMES;
    textLongNames = TEXTS.MMT_LONG_NAMES;
    textAdd = TEXTS.STATION_BLOCK.add;
    textSelect = TEXTS.STATION_BLOCK.select;
    textSelectAQI = TEXTS.AQI_DETAILED_INFO.selectAqi;
    AqiType = AqiType;
    AQI = AQI;
}
