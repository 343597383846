import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputRadioSquareComponent } from './input-radio-square.component';

@NgModule({
    exports: [InputRadioSquareComponent],
    declarations: [InputRadioSquareComponent],
    imports: [CommonModule],
})
export class InputRadioSquareModule {}
