import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'cityair-network-pin',
    templateUrl: './network-pin.component.html',
    styleUrls: ['./network-pin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkPinComponent {
    @Input() color: string;
    @Input() selected: boolean;
}
