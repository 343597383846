import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export type Positioning = 'left' | 'right';

@Component({
    selector: 'input-for-coordinate',
    templateUrl: './input-for-coordinate.component.html',
    styleUrls: ['./input-for-coordinate.component.less'],
})
export class InputForCoordinateComponent {
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() valueForm;
    @Input() textInfo = '';
    @Input() disabled = false;
    @Input() textError = '';
    @Input() positioning: Positioning = 'left';
}
