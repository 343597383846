import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TEXTS } from '@libs/common';

@Component({
    selector: 'cs-feedback-button',
    templateUrl: 'feedback-button.component.html',
    styleUrls: ['feedback-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackButtonComponent {
    TEXTS = TEXTS;
}
