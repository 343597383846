<div class="color-legend">
    <div class="color-legend__measure" [innerHTML]="measure" caTooltip
        caTooltipPlacement="top-left"
        [caTooltipAllowHover]="true"
        [caTooltipTemplate]="tooltipInfo">

    </div>
    <ca-info-icon *ngIf="showInfo" class="color-legend__info_icon" [withGradient]="true" (click)="setActionInfo()"></ca-info-icon>
    <div
        class="color-legend__grades"
        [ngStyle]="{
            background: getGradient()
        }"
    >
        <span
            class="color-legend__grade"
            [ngStyle]="{
                'min-width': !isLimit ? minWidth + 'px' : '8px'
            }"
        >
            {{ min }}
        </span>
        <span
            class="color-legend__grade"
            [ngStyle]="{
                'min-width': !isLimit ? minWidth + 'px' : 'none'
            }"
            *ngFor="let value of values"
        >
            {{ getDisplayedValue(value) }}
        </span>
        <span
            *ngIf="!isLimit"
            class="color-legend__grade"
            [ngStyle]="{
                'min-width': minWidth + 'px'
            }"
        ></span>
    </div>
    <div class="color-legend__unit" [innerHTML]="unit"></div>
    <ng-template #tooltipInfo>
       <ca-tooltip *ngIf="tooltipInfoText" class="tooltip-info" [pointerColor]="'rgb(56, 56, 56)'">
           <div class="text-tooltip" [innerHtml]="tooltipInfoText"></div>
       </ca-tooltip>
    </ng-template>
</div>
