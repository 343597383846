<div class="impact-sources">
    @if(store.select(isLoadingSources) | async){
        <div class="loading">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
    }  
    @else {
        <div class="summary">
            <div class="summary-wrapper">
                <div *ngFor="let item of summaryItems" class="summary-item">
                    <div 
                        class="summary-item__title"
                        [class.highlight]="item.isTitleHighlight"   
                    >{{ item.title }}</div>
                    <div class="summary-item__value">
                        {{ item.value.toLocaleString(currentLang) }}
                        <span *ngIf="item.unit">{{ item.unit }}</span>
                    </div>
                </div>  
            </div>
        </div>
        <div class="filters">
            <div class="search-wrapper">
                <search-input-basic
                    [textPlaceholderInner]="textImpact.sources.filters.searchPlaceholder"
                    [textInner]="searchQuery"
                    [debounceTimeInner]="400"
                    (textChangeInner)="textChangeIn($event)"
                ></search-input-basic>
            </div>
        </div>
        <div class="sources-table">
            <div class="sources-table__body" *ngIf="store.select(selectFilteredSources) | async as sources">
                <ng-container *ngIf="sources?.length else noDataTemp">
                    <div class="table-header">
                        <shared-sort-list-item
                            class="column-industry"
                            (click)="setSortingCb('icon_type')"
                            [sortingDirection]="sortField === 'icon_type' ? sortDirection : 0"
                            [text]="textImpact.sources.tableHeaders.industry"
                        ></shared-sort-list-item>
                        <shared-sort-list-item
                            class="column-name"
                            (click)="setSortingCb('name')"
                            [sortingDirection]="sortField === 'name' ? sortDirection : 0"
                            [text]="textImpact.sources.tableHeaders.name"
                        ></shared-sort-list-item>
                        <div class="column-mmt">
                            <div class="table-header-column">{{ textImpact.sources.tableHeaders.mmt }}</div>
                        </div>
                        <div class="column-type">
                            <div class="table-header-column">{{ textImpact.sources.tableHeaders.type }}</div>
                        </div>
                        <!-- <shared-sort-list-item
                            class="column-emission"
                            (click)="setSortingCb('value')"
                            [sortingDirection]="sortField === 'value' ? sortDirection : 0"
                            [text]="textImpact.sources.tableHeaders.emissionIntensity"
                        ></shared-sort-list-item> -->
                        <div class="column-emission">
                            <div class="table-header-column">{{ textImpact.sources.tableHeaders.emissionIntensity }}</div>
                        </div>
                        <div class="column-height">
                            <div class="table-header-column">{{ textImpact.sources.tableHeaders.height }}</div>
                        </div>
                        <div class="column-area">
                            <div class="table-header-column">{{ textImpact.sources.tableHeaders.area }}</div>
                        </div>
                    </div>
                    <div class="source-list scroll">
                        <div
                            *ngFor="let source of sources | orderBy : sortField : sortDirection"
                            class="source-item"
                            (click)="goToSource(source)"
                        >
                            <div class="column-industry column-industry__value">
                                <source-type-icon [type]="source.icon_type ? source.icon_type : 'factory'"></source-type-icon>
                            </div>
                            <div class="column-name column-name__value">{{ source.name }}</div>
                            <div class="column-mmt column-mmt__value">
                                <div 
                                    *ngFor="let item of source.emissions; index as i" 
                                    class="column-mmt__value-item"
                                >
                                    <span class="mmt-label" [innerHtml]="textNames[item.species]"></span>
                                </div>
                            </div>
                            <div class="column-type column-type__value">
                                @if(source.geo_type) {
                                    @if(source.geo_type === SourceType.area) {
                                        {{ textImpact.sources.sourceCard.sourceType.square }}
                                    }
                                    @if(source.geo_type === SourceType.point) {
                                        {{ textImpact.sources.sourceCard.sourceType.point }}
                                    }
                                }
                                @else {
                                    <span *ngIf="source | impactSourceType as type">{{ textImpact.sources.sourceCard.sourceType[type]}}</span>
                                    
                                }
                            </div>
                            <div class="column-emission column-emission__value">
                                {{ source | impactSourceEmission : null : false | valueFormatNumber : 4 }} {{ textImpact.sources.sourceCard.modelingParamsUnit}}
                            </div>
                            <div class="column-height column-height__value">{{ source?.max_height.toFixed(2) ?? ' - '}} м</div>
                            <div class="column-area column-area__value" [innerHtml]="source | impactSourceSquare : currentLang"></div>
                            <div class="column-more column-more__value">
                                <span>{{ textImpact.sources.buttons.more }}</span>    
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #noDataTemp>
                    @if(searchQuery) {
                        <search-result-placeholder 
                            [clearText]="textImpact.sources.filters.clear"
                            (clear)="clearFilter()"
                        ></search-result-placeholder>
                    }
                    @else {
                        <non-data-screen
                            class="screen-placeholders"
                            [text]="textImpact.sources.noData"
                        ></non-data-screen>
                    }
                </ng-template>
            </div>
        </div>
    }
</div>
