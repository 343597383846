import { Injectable, TemplateRef } from '@angular/core';

type TplRef = TemplateRef<HTMLDivElement>;

const always = () => true;

@Injectable({
    providedIn: 'root',
})
export class OffPanelPopupService {
    private templateRef: TplRef;
    private confirmCallback: () => boolean;
    deferred: () => boolean;

    setTemplate(templateRef: TplRef, callback?: () => boolean) {
        this.templateRef = templateRef;
        this.confirmCallback = callback;
        this.deferred = always;
    }

    getTemplate() {
        return this.templateRef;
    }

    clear() {
        this.templateRef = null;
        this.confirmCallback = null;
        this.deferred = null;
    }

    confirm(deferredCallback: () => void, isFinal: boolean = true) {
        this.deferred = () => {
            this.clear();
            deferredCallback();
            return !isFinal;
        };
        if (this.confirmCallback) {
            return this.confirmCallback();
        }
        return false;
    }
}
