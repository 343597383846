import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TEXTS } from '@libs/common';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { TabModel } from '@libs/common';

import { IMPACT_PAGES, IMPACTS_INIT_PAGE } from '@cityair/modules/impact/models';
import { filter, takeUntil } from 'rxjs/operators';
import { MAIN_PAGES, OBSERVER_ID, OPERATOR_ID } from '@libs/common';
import {
    ImpactActions,
    selectActiveTab,
    selectRunsLoadError,
} from '@cityair/modules/impact/store/impact.feature';
import {
    selectCurrentGroup,
    selectUserRoleId,
    setModuleConfig,
    SharedCoreActions,
    selectSidebarIsOpen,
    VangaAuthService,
} from '@libs/shared-store';
import { impactModuleConfig } from '@cityair/modules/impact/module-config';

@Component({
    selector: 'cityair-impact-main',
    templateUrl: './impact-main.component.html',
    styleUrls: ['./impact-main.component.less'],
})
export class ImpactMainComponent implements OnInit, OnDestroy {
    public translateText = TEXTS.IMPACT;
    public title = TEXTS.ADMIN_PANEL.impact;
    public titleEvents = TEXTS.ADMIN_PANEL.events;
    public errorListLoadText = TEXTS.FORECAST.errorListLoad;
    public ngDestroyed$ = new Subject<void>();
    public isOperator = false;
    readonly mainComponentUrl = `/${MAIN_PAGES.impact}/`;
    readonly eventsMainComponentURL = `/${MAIN_PAGES.events}/`;
    public isFullPageLayout = false;
    private fullLayoutList: string[] = [IMPACT_PAGES.sources];

    tabs: TabModel[] = [
        {
            isDisabled: false,
            type: 1,
            id: IMPACT_PAGES.cities,
            title: TEXTS.IMPACT.cities.title,
        },
    ];
    selectedTab: TabModel = null;
    activeTab: string;
    selectRunsLoadError = selectRunsLoadError;
    selectSidebarIsOpen = selectSidebarIsOpen;
    isEvents = false;
    constructor(
        private router: Router,
        public store: Store,
        private vangaAuthService: VangaAuthService,
        private _cdRef: ChangeDetectorRef
    ) {
        let isValidToken = true;
        this.store.dispatch(ImpactActions.setIsAvailable({ payload: true }));
        const token = this.vangaAuthService.getAccessToken();
        if (!token || token === 'null') {
            isValidToken = false;
        }
        this.store
            .select(selectCurrentGroup)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(async (group) => {
                if (group) {
                    if (isValidToken) {
                        this.store.dispatch(ImpactActions.initModule());
                    } else {
                        this.store.dispatch(SharedCoreActions.vangaTokenRefresh());
                    }
                }
            });
    }

    ngOnInit(): void {
        this.store.dispatch(setModuleConfig({ config: impactModuleConfig }));
        this.store
            .select(selectUserRoleId)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((roleId) => !!roleId)
            )
            .subscribe((roleId) => {
                if (roleId !== OBSERVER_ID) {
                    this.isOperator = roleId === OPERATOR_ID;
                    if (this.tabs.length === 1) {
                        this.addTabs();
                        this.selectedTab = this.tabs.find((item) => item.id == this.activeTab);
                        this._cdRef.detectChanges();
                    }
                }
            });
        this.store
            .select(selectActiveTab)
            .pipe(filter((v) => v !== null))
            .subscribe((tab) => {
                if (this.router.url === `/${MAIN_PAGES.impact}`) {
                    this.isEvents = false;
                    if (!tab) {
                        this.openPage({ id: IMPACTS_INIT_PAGE });
                    } else {
                        this.openPage({ id: tab });
                    }
                } else if (this.router.url.indexOf(`${MAIN_PAGES.events}`) >= 0) {
                    this.isEvents = true;
                    this._cdRef.detectChanges();
                } else {
                    this.isEvents = false;
                    this.activeTab = tab;
                    const selectedTab = this.tabs.find((item) => item.id == this.activeTab);
                    if (selectedTab) {
                        this.selectedTab = selectedTab;
                        this._cdRef.detectChanges();
                    }
                    this.isFullPageLayout = this.fullLayoutList.includes(this.activeTab);
                }
            });
    }

    ngOnDestroy(): void {
        this.store.dispatch(ImpactActions.setIsAvailable({ payload: false }));
        this.ngDestroyed$.next();
    }

    openPage(event: TabModel) {
        this.selectedTab = this.tabs.find((item) => item.id === event.id);
        this.isFullPageLayout = this.fullLayoutList.includes(event.id);
        this.router.navigate([this.mainComponentUrl + event.id]);
    }

    private addTabs(): void {
        this.tabs.push({
            title: TEXTS.IMPACT.sources.title,
            type: 2,
            id: IMPACT_PAGES.sources,
        });
        this.tabs.push({
            title: TEXTS.IMPACT.tabs.posts,
            type: 3,
            id: IMPACT_PAGES.posts,
        });
        this.tabs.push({
            title: TEXTS.IMPACT.tabs.calculations,
            type: 4,
            id: IMPACT_PAGES.controlPoints,
        });
    }

    public toggleSideBar() {
        this.store.dispatch(SharedCoreActions.toggleSidebar({ payload: null }));
    }
}
