<div
    [ngClass]="{
        'floor-pin': true,
        'floor-pin_state-active': selected
    }"
>
    <div class="floor-pin__value" [innerHTML]="value | safeHtml"></div>
    <svg class="floor-pin__end" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            class="floor-pin__end-border"
            d="M5.64341 7.58119L4.78958 8.10174L4.79062 8.10343L5.64341 7.58119ZM1 1V0L0.603548 1.91806L1 1ZM15 1L15.3965 1.91806L15 0V1ZM10.3566 7.58119L11.2075 8.10647L11.2104 8.10173L10.3566 7.58119ZM9.37985 9.16349L8.52891 8.63822L8.52817 8.63943L9.37985 9.16349ZM8.77519 9.7682L8.28078 8.89897L8.25502 8.91362L8.23017 8.92977L8.77519 9.7682ZM6.6124 9.16349L7.47304 8.65428L7.46917 8.64774L7.4652 8.64125L6.6124 9.16349ZM6.49724 7.06064C6.18774 6.55297 5.88502 5.96227 5.55626 5.31217C5.23397 4.67486 4.88765 3.98253 4.51153 3.32767C3.78106 2.05588 2.81628 0.69508 1.39645 0.0819447L0.603548 1.91806C1.37752 2.25229 2.06778 3.08857 2.77723 4.32378C3.12107 4.92243 3.44093 5.56107 3.7715 6.21474C4.0956 6.85562 4.43242 7.5159 4.78958 8.10173L6.49724 7.06064ZM1 2H7.9845V0H1V2ZM7.9845 2H15V0H7.9845V2ZM14.6035 0.0819447C13.1837 0.69508 12.2189 2.05588 11.4885 3.32767C11.1124 3.98253 10.766 4.67486 10.4437 5.31217C10.115 5.96227 9.81226 6.55297 9.50276 7.06064L11.2104 8.10173C11.5676 7.5159 11.9044 6.85562 12.2285 6.21474C12.5591 5.56107 12.8789 4.92243 13.2228 4.32378C13.9322 3.08857 14.6225 2.25229 15.3965 1.91806L14.6035 0.0819447ZM9.50566 7.05591L8.52891 8.63822L10.2308 9.68877L11.2075 8.10646L9.50566 7.05591ZM8.52817 8.63943C8.44358 8.7769 8.35581 8.85629 8.28078 8.89897L9.26961 10.6374C9.6907 10.3979 10.006 10.054 10.2315 9.68756L8.52817 8.63943ZM8.23017 8.92977C8.15759 8.97696 8.07518 9 8 9V11C8.46745 11 8.92768 10.8618 9.32022 10.6066L8.23017 8.92977ZM8 9C7.92533 9 7.82708 8.97682 7.72338 8.91593L6.71073 10.6406C7.08765 10.8619 7.53203 11 8 11V9ZM7.72338 8.91593C7.64001 8.86698 7.54843 8.78169 7.47304 8.65428L5.75176 9.67271C5.98646 10.0694 6.31348 10.4074 6.71073 10.6406L7.72338 8.91593ZM7.4652 8.64125L6.49621 7.05894L4.79062 8.10343L5.75961 9.68574L7.4652 8.64125Z"
        />
        <path
            class="floor-pin__end-bg"
            d="M5.64341 6.58119C4.31008 4.39418 3.1938 0.947368 1 0H7.9845H15C12.8062 0.947368 11.6899 4.39418 10.3566 6.58119L9.37985 8.16349C9.22481 8.41545 9.02326 8.6271 8.77519 8.7682C8.54264 8.91937 8.27132 9 8 9C7.72868 9 7.45736 8.91937 7.21705 8.77828C6.97674 8.63718 6.76744 8.42553 6.6124 8.16349L5.64341 6.58119Z"
        />
    </svg>
</div>
