<div class="list-events">
    <div class="list-events__filters"  *ngIf="false">
        <div class="calendar-filter"
             (clickOutside)="calendarIsOpened = false"
        >
            <div (click)="toggleCalendar()">
                <input-for-dropdown
                    [label]="labelPeriod"
                    [inputValue]="dateRange | dateRangeText"
                    [(isOpenDropdown)]="calendarIsOpened"
                ></input-for-dropdown>
            </div>
            <div class="list-events__calendar">
                <calendar-months
                    [show]="calendarIsOpened"
                    [timeBegin]="dateTimeBegin"
                    [timeEnd]="dateTimeEnd"
                    [applyCb]="updateDateRange"
                    [monthsVisible]="1"
                    [updateDays]="updateDays"
                    [columns]="1"
                    [numberOfMonths]="12"
                ></calendar-months>
            </div>
        </div>
        <div (clickOutside)="mmtsFilterIsOpen = false" class="mmt-filter">
            <input-for-dropdown
                [label]="texts.details.mmtHeader"
                [inputValue]="currentMmtFilter?.label"
                [(isOpenDropdown)]="mmtsFilterIsOpen"
            ></input-for-dropdown>
            <div class="selectbox-wrapper" *ngIf="mmtsFilterIsOpen">
                <selectbox-radio
                    class="mo-view-select-box"
                    [listItems]="mmtList"
                    (listItemsChange)="
                        updateMmtFilter($event); mmtsFilterIsOpen = false
                    "
                ></selectbox-radio>
            </div>
        </div>
    </div>
    <div *ngIf="store.select(selectIsLoadingEvents) | async else contentTemp">
        <div class="wrapper-loading">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
    </div>
    <ng-template #contentTemp>
        <non-data-screen *ngIf="events.length === 0 else eventsContent"
            class="screen-placeholders"
            [text]="texts.emptyDataEvents"
        ></non-data-screen>
        <ng-template #eventsContent>
            <div class="table-header" *ngIf="false">
                <shared-sort-list-item
                    class="sort_name"
                    (click)="setSortingCb('start')"
                    [sortingDirection]="sortField === 'start' ? sortDirection : 0"
                    [text]="texts.headerStart"
                ></shared-sort-list-item>
                <shared-sort-list-item
                    class="sort_max"
                    (click)="setSortingCb('max_value')"
                    [sortingDirection]="sortField === 'max_value' ? sortDirection : 0"
                    [text]="texts.details.maxHeader"
                ></shared-sort-list-item>
            </div>
            <div class="list scroll">
                <ng-container *ngIf="events | orderEvents : sortField : sortDirection as result">
                    <div class="card-event" *ngFor="let item of result" (click)="selectEvent(item)">
                        <ca-impact-event-card
                            [currentSchema]="currentSchema"
                            [item]="item"
                            *ngIf="currentMmtFilter?.id === null || item.param === currentMmtFilter?.id"
                        ></ca-impact-event-card>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </ng-template>
</div>
