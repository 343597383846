export const PM25 = 'PM25';
export const PM10 = 'PM10';
export const HUM = 'HUMIDITY';
export const TEMP = 'TEMPERATURE';
export const PRES = 'PRESSURE';
export const WDA = 'WDA';
export const WVA = 'WVA';
export const AQI = 'AQI';
export const CO = 'CO';
export const CO2 = 'CO2';
export const NO = 'NO';
export const NO2 = 'NO2';
export const NH3 = 'NH3';
export const SO2 = 'SO2';
export const O3 = 'O3';
export const H2S = 'H2S';
export const CH2O = 'CH2O';
export const SUM = 'SUM'; // TODO: convenience alias for SibAGRO
export const TSP = 'TSP';
export const CS137 = 'CS137';
export const WVVA = 'WVVA';
export const WDA_V = 'WDA_V';
export const WVA_V = 'WVA_V';
export const NOISELEVEL = 'NOISELEVEL';
// meteo forecasts consts
export const SP = 'SP';
export const T = 'T';
export const U = 'U';
export const V = 'V';
export const R2 = 'R2';
export const WD = 'WD';
export const WV = 'WV';
