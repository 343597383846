<div class="notifications__header notifications__settings">
    <ng-container [ngSwitch]="store.select(selectCurrentFormType) | async">
        <settings-measurements
            *ngSwitchCase="NotificationType.pdkExcess"
            [subscription]="store.select(selectCurrentSettingForm) | async"
            [currentUser]="store.select(selectIAm) | async"
            [groupId]="store.select(selectGroupId) | async"
            [isLoadingForm]="store.select(selectIsLoadingForm) | async"
            (cancelEmit)="closeFromSetting($event)"
        >
        </settings-measurements>
       <settings-service
            *ngSwitchCase="NotificationType.deviceIncident"
            [subscription]="store.select(selectCurrentSettingForm) | async"
            [currentUser]="store.select(selectIAm) | async"
            [groupId]="store.select(selectGroupId) | async"
            [isLoadingForm]="store.select(selectIsLoadingForm) | async"
            (cancelEmit)="closeFromSetting($event)"
        >
        </settings-service>
    </ng-container>
</div>
