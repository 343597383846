<div class="analytics-content">
     <div class="control">
        <div *ngIf="showCitySelector" class="select-cities">
            <input-dropdown
                [inputText]="selectedCity ? selectedCity?.name : textsNoSelect"
                [label]="textsAnalytics.labelCitySelector"
                [(showDropdown)]="isShowDropdownForCities"
                (clickOutside)="isShowDropdownForCities = false"
            >
                <selectbox-radio
                    [listItems]="citiesList"
                    (listItemsChange)="
                        selectCity($event); isShowDropdownForCities = false
                    "
                ></selectbox-radio>
            </input-dropdown>
        </div>
        <div class="tabs-wrapper">
            <switch-item
                class="analytic__switch"
                [currentTab]="currentTabsSeasons"
                [tabs]="tabsSeasons"
                (action)="selectSeason($event)"
            ></switch-item>
        </div>
    </div>
    <ng-container *ngIf="isLoading else contentTemp">
       <div class="spinner-wrapper">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
       </div>
    </ng-container>
    <ng-template #contentTemp>
        <div *ngIf="noDataForSelectPeriod" class="no_data">
            <div class="no_data__title">{{ textsCityCard.noData }}</div>
            <div class="no_data__img"></div>
        </div>

        <div *ngIf="!noDataForSelectPeriod" class="charts">
            <cs-doughnut-chart
                class="chart-wrapper"
                [isDemo]="isDemo"
                [data]="chartCount"
                [tooltipDescription]="tooltipDescription"
                [measure]="textsNames['AQI']"
            ></cs-doughnut-chart>
            <cityscreen-bar-chart
                class="chart-wrapper"
                [isDemo]="isDemo"
                [data]="chartHourAvg"
                [chartName]="CHART_BAR_NAME.dayHour"
                [tooltipDescription]="tooltipDescription"
                [measure]="textsNames['AQI']"
            ></cityscreen-bar-chart>
            <cityscreen-bar-chart
                class="chart-wrapper"
                [isDemo]="isDemo"
                [data]="chartDayOfWeek"
                [chartName]="CHART_BAR_NAME.weekDay"
                [tooltipDescription]="tooltipDescription"
                [measure]="textsNames['AQI']"
            ></cityscreen-bar-chart>
        </div>
    </ng-template>
</div>
