import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { isFalseNumber, markerState, LANGUAGE, TEXTS } from '@libs/common';

import { PipesModule } from '@libs/shared-ui';

@Component({
    standalone: true,
    selector: 'cityair-impact-post-pin',
    imports: [CommonModule, PipesModule],
    templateUrl: './impact-post-pin.component.html',
    styleUrls: ['./impact-post-pin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactPostPinComponent {
    markerState = markerState;
    texts = TEXTS.PLUMES;
    public textScheme = TEXTS.MEASURES_SCHEME;
    public textNames = TEXTS.NAMES;
    isFalseNumber = isFalseNumber;
    @Input() value: number;
    @Input() color: string;
    @Input() numberAfterDot?: number = 0;
    @Input() selected: boolean;
    @Input() isCollapse = false;

    public displayValue: number | string;
    public hasNoData: boolean;
    public currentLang = LANGUAGE;
}
