import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SlideIndicatorComponent } from './slide-indicator.component';

@NgModule({
    imports: [CommonModule],
    exports: [SlideIndicatorComponent],
    declarations: [SlideIndicatorComponent],
})
export class SlideIndicatorModule {}
