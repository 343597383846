import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'btn-cancel',
    templateUrl: './btn-cancel.component.html',
    styleUrls: ['./btn-cancel.component.less'],
})
export class BtnCancelComponent {
    @Input() disabled = false;
    @Output() cbClick = new EventEmitter<void>();

    click() {
        this.cbClick.emit();
    }
}
