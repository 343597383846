<div class="sort_list">
    <ng-container *ngFor="let property of properties">
        <div class="sort_list__props" (click)="cbSortFun(property.props)">
            <span [innerHTML]="property.title"></span>
            <div
                [ngClass]="{
                    sort_list__img: true,
                    'sort_list__img-active':
                        sortingProps === property.props && sortingDirection > 0,
                    'sort_list__img-revers-active':
                        sortingProps === property.props && sortingDirection < 0
                }"
            ></div>
        </div>
    </ng-container>
</div>
