import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
    selector: 'search-input',
    templateUrl: 'search-input.component.html',
    styleUrls: ['search-input.component.less'],
})
export class SearchInputComponent implements OnChanges {
    @Input() textPlaceholder = '';
    @Input() text = '';
    @Input() debounceTime = 0;
    @Output() textChange = new EventEmitter<string>();

    ngOnChanges() {
        if (this.text == '') {
            this.textChange.emit('');
        }
    }

    textChangeIn(event) {
        this.textChange.emit(event);
    }
}
