import { BaseNotificationEvent, EventStatus } from '@libs/common';

export function getRelevantTime(event: BaseNotificationEvent) {
    switch (event.status) {
        case EventStatus.End:
            return new Date(event.end_time);
        case EventStatus.Continue:
            return new Date(event.update_time);
        default:
            return new Date(event.begin_time);
    }
}
