import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChartDataset, ChartType } from 'chart.js';
import { options } from './stats-bar-chart.config';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'impact-stats-bar-chart',
    standalone: true,
    imports: [CommonModule, BaseChartDirective],
    templateUrl: './stats-bar-chart.component.html',
    styleUrls: ['./stats-bar-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsBarChartComponent implements OnInit {
    @Input() data: number[];
    @Input() tooltipDescription: string;
    @Input() measure?: string = '%';
    @Input() labels: string[] = [];
    @Input() colors: string[] = [];

    options = options;
    public chartType: ChartType = 'bar';
    public tooltip: string;
    public datasets: ChartDataset<'bar', number[]>[] = [];

    ngOnInit() {
        const backgroundColor = this.colors;
        this.datasets = [
            {
                data: this.data,
                backgroundColor,
                hoverBackgroundColor: backgroundColor,
                hoverBorderColor: 'transparent',
            },
        ];
    }
}
