import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ListHeaderComponent } from './list-header.component';

@NgModule({
    imports: [CommonModule],
    exports: [ListHeaderComponent],
    declarations: [ListHeaderComponent],
})
export class ListHeaderModule {}
