import { Pipe, PipeTransform } from '@angular/core';
import { TimeTrackView } from './../../models/core';
import { DataQualityTimeline } from '@libs/common';
import { DAY_OR_HOUR_BOUND } from './../../constants';
import * as moment from 'moment-timezone';
import { toShortISOString } from '@libs/common';
import { DAY_FORMAT, MONTH_FORMAT, TIME_FORMAT } from '@libs/common';
import { getIntervalInHours } from './../../utils';

const MAX_LABEL_WIDTH = 65;

@Pipe({
    name: 'toTimelineData',
})
export class ToTimelineDataPipe implements PipeTransform {
    transform(
        dates: string[],
        hasDataArr: boolean[],
        width: number,
        showNow: boolean,
        dataQuality: DataQualityTimeline[]
    ): TimeTrackView[] {
        const numberLabel = Math.round(width / MAX_LABEL_WIDTH);
        const result = [];
        if (dates.length && numberLabel) {
            const hours = this.getHourByRange(dates[0], dates[dates.length - 1]);
            const hoursInterval = getIntervalInHours(dates[0], dates[1]);
            const stepDataInterval = this.getStepIntervalMinutes(dates[0], dates[1]);

            const items = stepDataInterval === DAY_OR_HOUR_BOUND ? dates.length : hours;
            const isShowDayFormat = hoursInterval >= 24;

            let currentDisplayIndex = 0;

            const m = moment(dates[currentDisplayIndex]).add(1, 'day').startOf('day');
            const nextDay = m.milliseconds(0).toDate();
            let ndIndex = dates.findIndex((d) => d === toShortISOString(nextDay));
            if (ndIndex === -1) {
                ndIndex = dates.findIndex((d) => d === nextDay.toISOString());
            }

            const stepSize = this.getLabelsStep(items, numberLabel, stepDataInterval);

            if (ndIndex !== -1) {
                currentDisplayIndex = ndIndex % stepSize;
            }

            const datesHaveMicroseconds = dates[0].indexOf('.000') !== -1 ? '.000' : '';
            const nowTimeUTC = this.getNowTimeByTypeInterval(
                stepDataInterval,
                datesHaveMicroseconds
            );
            let indexNow = dates.indexOf(nowTimeUTC) === -1 ? null : dates.indexOf(nowTimeUTC) + 1;
            if (indexNow && indexNow === dates.length) {
                indexNow = indexNow - 1;
            }
            dates.forEach((value, index) => {
                const hasLabel = index === currentDisplayIndex;
                const label = hasLabel ? this.getDisplayValue(value, isShowDayFormat) : '';
                const isNow = showNow && indexNow === index;
                result.push({
                    index,
                    value,
                    label,
                    isNow,
                    hasData: hasDataArr[index],
                    hasQualityData: dataQuality[index],
                });

                if (hasLabel) {
                    currentDisplayIndex = stepSize + currentDisplayIndex;
                }
            });
        }

        return result;
    }
    private getHourByRange(startDate: string, finishDate: string): number {
        const finish = moment(finishDate);
        const start = moment(startDate);
        return Math.ceil(finish.diff(start, 'hour'));
    }

    private getStepIntervalMinutes(first: string, second: string): number {
        const init = moment(first);
        const next = moment(second);
        return next.diff(init, 'minutes');
    }

    private getLabelsStep(itemsCount: number, maxLabelsCount: number, stepSizeMinutes: number) {
        const roundInterval = this.getRoundInterval(Math.ceil(itemsCount / maxLabelsCount));

        const coefficientTime =
            {
                20: 3,
                5: 8,
            }[stepSizeMinutes] || 1;

        return roundInterval * coefficientTime;
    }

    private getDisplayValue(value: string, isShowDayFormat: boolean): string {
        const m = moment(value);
        const startDay = moment(value).startOf('day');

        if (m.valueOf() === startDay.valueOf() || isShowDayFormat) {
            const month = MONTH_FORMAT(m);
            return `<span class="time-line-track-date">${m.format(DAY_FORMAT)} ${month}</span>`;
        } else {
            return `<span class="time-line-track-time">${m.format(TIME_FORMAT)}</span>`;
        }
    }

    private getNowTimeByTypeInterval(
        stepDataInterval: number,
        datesHaveMicroseconds: string
    ): string {
        const now = new Date().getTime();
        const interval = stepDataInterval * 60 * 1000;
        const time = moment.utc(new Date(Math.ceil(now / interval) * interval - interval)).format();
        if (time.indexOf(datesHaveMicroseconds) === -1) {
            return time.replace('Z', '.000Z');
        }

        return time;
    }

    private getRoundInterval(interval: number) {
        if (interval <= 2) {
            return interval;
        } else if (interval <= 4) {
            return 4;
        } else if (interval <= 6) {
            return 6;
        } else if (interval <= 12) {
            return 12;
        } else {
            return (Math.trunc(interval / 24) + 1) * 24;
        }
    }
}
