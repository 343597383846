export const NOTIFICATIONS_EVENT_COUNT = 100;
export const DIFF_SCROLL_COUNT = 10;
export const AVAILABLE_DATA_IN_MONTH = 12;
export const initQueryState = {
    pdk: {
        queryNumber: 0,
        isAvailableLoadMore: true,
    },
    device: {
        queryNumber: 0,
        isAvailableLoadMore: true,
    },
};
