import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { IReport } from '@libs/common';

@Injectable({ providedIn: 'root' })
export class ReportsService extends EntityCollectionServiceBase<IReport> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Report', serviceElementsFactory);
    }
}
