import { createAction, props } from '@ngrx/store';

export enum CoreActions {
    onIsEnabledChart = '[timeline] onIsEnabledChart',
    playerStart = '[timeline] Player Start',
    playerStop = '[timeline] Player Stop',
    playerLoadingData = '[timeline] Player is loading data',
    playerSetProgress = '[timeline] Set player progress',
    playerReady = '[timeline] Player is ready',
    playerSetManaged = '[timeline] Player set managed',
    correctStartIndex = '[timeline] correctStartIndex',
    setMainChartLoading = '[timeline] chartLoading',
}

// TODO: computedProperty ??
// TODO remove in other modules
export const onIsEnabledChart = createAction(
    CoreActions.onIsEnabledChart,
    props<{ payload: boolean }>()
);

export const playerSetProgress = createAction(
    CoreActions.playerSetProgress,
    props<{ progress: number }>()
);

export const playerReady = createAction(CoreActions.playerReady);

export const playerSetManaged = createAction(
    CoreActions.playerSetManaged,
    props<{ payload: boolean }>()
);

export const correctStartIndex = createAction(
    CoreActions.correctStartIndex,
    props<{ payload: boolean }>()
);

export const playerStart = createAction(CoreActions.playerStart);

export const playerStop = createAction(CoreActions.playerStop);

export const playerLoadingData = createAction(CoreActions.playerLoadingData);

export const setMainChartChartLoading = createAction(
    CoreActions.setMainChartLoading,
    props<{ payload: boolean }>()
);
