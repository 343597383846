import { Component, OnDestroy } from '@angular/core';
import { MeasureScheme } from '@libs/common';
import { TabModel } from '@libs/common';
import { RoomRate } from '@cityair/modules/indoor/services/api';
import { Store } from '@ngrx/store';
import { IndoorState } from '@cityair/modules/indoor/store/reducers';
import { indoorSelectors } from '@cityair/modules/indoor/store/selectors';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { getIndoorColor, indoorZones, MMT_INDOOR } from '@cityair/modules/indoor/config';
import { selectPoint } from '../../store/actions';
import * as moment from 'moment-timezone';

@Component({
    selector: 'room-rating',
    templateUrl: './room-rating.component.html',
    styleUrls: ['./room-rating.component.less'],
})
export class RoomRatingComponent implements OnDestroy {
    selectPoint = selectPoint;
    selectPost = indoorSelectors.selectPost;

    roomPercentRating;
    roomCircleRating;

    onDestroy$ = new Subject<void>();
    data: RoomRate;

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    constructor(public store: Store<IndoorState>) {
        store
            .select(indoorSelectors.statInfo)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((statInfo) => !!statInfo)
            )
            .subscribe((statInfo) => {
                this.data = statInfo.RoomRate;
                this.updateData();
            });
    }

    tabs = [
        {
            title: moment().format('DD MMM'),
            type: 1,
        },
        {
            title: 'Last week',
            type: 2,
        },
        {
            title: 'Last month',
            type: 3,
        },
    ];

    selectedTab: TabModel = this.tabs[0];

    showSelectedTab(tab: TabModel) {
        this.selectedTab = tab;
        this.updateData();
    }

    async updateData() {
        this.roomPercentRating = [];
        this.roomCircleRating = [];
        let period;

        if (this.selectedTab.type == 1) period = this.data.LastDay;
        if (this.selectedTab.type == 2) period = this.data.LastWeek;
        if (this.selectedTab.type == 3) period = this.data.LastMonth;

        if (period.PercentBest) {
            this.roomPercentRating[0] = {
                id: period.PercentBest.MoId,
                grade: period.PercentBest.Value,
                titleDescription: 'Most comfortable zone 😍',
                gradeDescription: 'Good air, time',
            };
        }

        if (period.PercentWorse) {
            this.roomPercentRating[1] = {
                id: period.PercentWorse.MoId,
                grade: period.PercentWorse.Value,
                titleDescription: 'Least comfortable zone 😡',
                gradeDescription: '',
            };
        }

        if (period.IaqBest) {
            this.roomCircleRating[0] = {
                id: period.IaqBest.MoId,
                grade: period.IaqBest.Value,
                color: getIndoorColor(
                    indoorZones[MeasureScheme.default][MMT_INDOOR.indoorAqi],
                    period.IaqBest.Value
                ),
                titleDescription: 'Safest zone 😍',
                numberAfterDot: 2,
            };
        }

        if (period.IaqWorse) {
            this.roomCircleRating[1] = {
                id: period.IaqWorse.MoId,
                grade: period.IaqWorse.Value,
                color: getIndoorColor(
                    indoorZones[MeasureScheme.default][MMT_INDOOR.indoorAqi],
                    period.IaqWorse.Value
                ),
                titleDescription: 'Least safe zone 😡',
                numberAfterDot: 2,
            };
        }
    }
}
