<div
    class="label-button"
    [ngClass]="{ 'selected-item': isSelected }"
    (click)="selectMmt.emit()"
    caTooltip
    caTooltipPlacement="top"
    [caTooltipAllowHover]="true"
    [caTooltipTemplate]="tooltip"
>
    <span [innerHTML]="textNames[mmt] || mmt"></span>
    <span class="label-mmt" *ngIf="unit" [innerHTML]="unit | safeHtml"></span>
</div>

<ng-template #tooltip>
    <ng-container
        *ngIf="mmt === AQI || mmt === AqiType.instant"
        [ngTemplateOutlet]="aqiTooltip"
    ></ng-container>
    <ng-container
        *ngIf="mmtWithTooltip.indexOf(mmt) >= 0"
    >
        <ca-tooltip>
            <div class="aqi_tooltip" [innerHTML]="textLongNames[mmt]"></div>
        </ca-tooltip>
    </ng-container>
</ng-template>
