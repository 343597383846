import { Pipe, PipeTransform } from '@angular/core';
import { RegionApi } from '@libs/common';

@Pipe({ name: 'impactDensityRegion' })
export class ImpactDensityRegionPipe implements PipeTransform {
    transform(region: RegionApi): number {
        const postNumber = region?.post_ids?.length;
        if (postNumber === 0 || !postNumber) {
            return 0;
        }
        const density = Math.round(((postNumber * 4) / region.region_square) * 100) / 100;
        return density >= 1 ? 100 : density * 100;
    }
}
