<cityair-map-control *ngIf="store.select(selectActiveRun)| async as run"
    [isOpenSidebar]="store.select(selectSidebarIsOpen) | async"
    [isShowLayer]="store.select(selectIsAdditionalLayer) | async"
    [title]="textTitleMapControl"
>
    <div map-control-period>
        <ca-impact-run-selector
            [runs]="store.select(selectRunsImpact) | async"
            [activeRun]="run"
            (setRun)="changeRun($event)"
        ></ca-impact-run-selector>
    </div>
    <div map-control-mmts>
        <shared-ui-mmt-selector
            class="impact-mmt"
            [showLayerButton]="false"
            [title]="textOnMap"
            [mmts]="store.select(selectSpeciesList) | async"
            [activeMmt]="store.select(selectActiveMmt) | async"
            (selectMmt)="changeMmt($event)"
        ></shared-ui-mmt-selector>
    </div>
    <div switch-selector-map-object>
        <ca-impact-map-object-selector class="map-object-selector"
            [list]="store.select(selectDisplayObjOnMap) | async"
           (changesList)="mapObjectChanges($event)"
        >
        </ca-impact-map-object-selector>
    </div>
    <div switch-layer-map>
        <div class="mmt-selector">
            <div class="title" >
                Слои
            </div>
            <div class="mmts-wrapper">
                <div class="values-wrapper">
                    <div class="values">
                        <div class="mmt-value" *ngIf="store.select(selectIsAllowLensImpact) | async"
                            [ngClass]="{
                                'with-border': store.select(selectIsAllowMrrImpact) | async,
                                'active': isActiveLens,
                            }"
                            [innerHtml]="'Трафик'"
                            (click)="isActiveLens ? disableLens() : enableLens()"
                        >
                        </div>
                        <ng-container  *ngIf="store.select(selectIsAllowMrrImpact) | async">
                            <div class="mmt-value"
                                [ngClass]="{
                                    'active': isActiveMrr,
                                }"
                                [innerHtml]="'МPP'"
                                (click)="isActiveMrr ? disableMrr() : enableMrr()"
                            >
                            </div>
                            <div class="mmt-value"
                                [ngClass]="{
                                    'active': isActiveMrrSource,
                                }"
                                [innerHtml]="'Источ.'"
                                (click)="isActiveMrrSource ? disableMrrSource() : enableMrrSource()"
                            >
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</cityair-map-control>
