<div class="impact-post-pin impact-post-pin_state-selected"
    [ngClass]="{
        'impact-post-pin_state-collapse': isCollapse,
        'impact-post-pin_state-selected': selected,
     }"
>
    <div class="impact-post-pin__body" [ngStyle]="{
        backgroundColor: color,
        border: selected ? '4px solid ' + color : '4px solid #fff' }"
    >
        <div class="wrapper-value" >
            <div class="impact-post-pin__value"
            >
                <ng-container *ngIf="value===null else valueTemp">
                    -
                </ng-container>
                <ng-template #valueTemp>
                     {{ value | valueFormatNumber : numberAfterDot }}
                </ng-template>
            </div>
        </div>
    </div>
    <div class="impact-post-pin__line"></div>
    <div
        class="impact-post-pin__end"
        [style]="{ backgroundColor: color }"
    ></div>
</div>

