<div class="impact-control-point" [ngClass]="{ active: isActive }">
    <div class="impact-control-point__content" [ngClass]="{ active: isActive }">
        <div class="impact-control-point__name">{{ point.name }}</div>
    </div>

    <div class="impact-control-point__value">
        <div class="impact-control-point__color-block" *ngIf="getColorFromZone(zone, currentPointValues.value) as color"
             [ngStyle]="{'backgroundColor': color}"
        ></div>
        <div class="impact-control-point__value_number">
            <span *ngIf="isFalseNumber(currentPointValues.value) else valueTemp">-</span>
            <ng-template #valueTemp>{{ currentPointValues.value | valueFormatNumber : numberAfterDot }}</ng-template>
        </div>
        <div class="impact-control-point__value_description" [innerHTML]="measureUnit"></div>
    </div>
    <kebab-menu (clickActions)="openActions($event)"></kebab-menu>
</div>
