import { Observable } from 'rxjs';
import { BBox, GeoJSON } from 'geojson';
import { LngLat } from 'mapbox-gl';
import { Extent } from 'ol/extent';
import { BasicDataResponse, Geometry, RegionApi } from '@libs/common';

export interface PinModel extends BasicDataResponse {
    id: string;
    name: string;
    geometry: Geometry;
    location_id?: number;
}

export class ControlPointModel {
    id?: string;
    name: string;
    lat: number;
    lon: number;
    color?: string;
    type?: string;
}
export class MapPins {
    getPins: Observable<PinModel[]>;
    selectDigitsAfterDot?: Observable<number>;
    getSelectedPinIds: Observable<string[]>;
    getValue: (pin: PinModel) => Observable<number>;
    getColor: (pin: PinModel) => Observable<string>;
    clickCb: (pin: PinModel) => void;
    getHover?: Observable<string[]>;
    getTooltip?: (pin: PinModel) => Observable<string>;
    isValueMode?: Observable<boolean>;
    isCollapsed?: (id: string) => Observable<boolean>;
}
export class SourceLine {
    getLine: Observable<GeoJSON.Feature<GeoJSON.Geometry>[]>;
    clickCb?: (line) => void;
    mouseEnter?: ($event, line) => void;
    mouseLeave?: () => void;
    mouseMove?: ($event, line) => void;
    getHoverLine?: Observable<GeoJSON.Feature[]>;
}
export class MapControlPins {
    getPins: Observable<any[]>;
    selectDigitsAfterDot?: Observable<number>;
    getSelectedPinIds: Observable<string[]>;
    getValue?: (pin: ControlPointModel) => Observable<number>;
    getColor?: (pin: ControlPointModel) => Observable<string>;
    isWD?: Observable<boolean>;
    clickCb: (pin: ControlPointModel) => void;
    dragEnd?: ($event, pin?: ControlPointModel) => void;
    isDraggable?: (pin: any) => Observable<boolean>;
    isCollapsed?: Observable<boolean>;
}
export class InfoPins {
    getPins: Observable<ControlPointModel[]>;
}
export class SourcePins {
    getPins: Observable<any[]>;
    isCollapsed?: (id: string) => Observable<boolean>;
}
export class RegionPins {
    getPins: Observable<RegionApi[]>;
}
export class MapPolygonInfo {
    getPolygon: Observable<GeoJSON.FeatureCollection<GeoJSON.Geometry>>;
}
export class MapInfoPin {
    getPins: Observable<ControlPointModel[]>;
}
export class MapCenterAndZoom {
    center: LngLat;
    zoom: number;
}
export interface IMapClick {
    isAllow: boolean;
    coordinates?: {
        lat: number;
        lon: number;
    };
}

export interface ScaleInfoNetwork {
    id: string;
    url: string;
    coordinates: number[][];
}
export interface NetworkPolygonData {
    polygon: GeoJSON.FeatureCollection;
    scales: ScaleInfoNetwork[];
}
export interface NetworkArea {
    name: string;
    value: number;
    id: number;
    lng: number;
    lat: number;
    unit: string;
}
export class GridNetworkMap {
    isShow: Observable<boolean>;
    domain?: Observable<BBox>;
    hoverArea?: Observable<NetworkArea>;
    getPolygon?: Observable<NetworkPolygonData>;
    mouseOut?: ($event) => void;
    mouseMove?: ($event) => void;
}

export type DomainConfigType = {
    slug: string;
    substances: string[];
    coordinates: number[][];
    start?: string;
    end?: string;
    extent?: Extent;
    timeStep?: number;
    altitudeIndex?: string;
};

export enum DataType {
    Raster = 'raster',
    Vector = 'vector',
}

export enum TileType {
    DomainTiles = 'custom',
    Tiles = 'xyz',
}
export interface IAuthorizeHelper {
    getAuthHeader: () => Record<string, string>;
    refreshToken: () => Promise<void>;
}
export class DomainConfig {
    dateFormat = 'YYYYMMDD_HHmmss';
    timeStep = 3600 * 1000;
    opacity = 0.5;
    url?: string;
    minzoom?: number;
    maxzoom?: number;
    domain: DomainConfigType;
    altitudeIndex = '0';

    constructor(options: Partial<DomainConfig> & { domain: DomainConfigType }) {
        Object.assign(this, options);
        if (options?.domain?.altitudeIndex) {
            this.altitudeIndex = options?.domain?.altitudeIndex;
        }
    }

    getImagePath(substance: string, dataType: DataType, tileType: TileType, altitudeIndex: string) {
        return [this.url, this.domain.slug, dataType, tileType, altitudeIndex, substance].join('/');
    }
}
export interface TileConfig {
    slug: string;
    coordinates: number[][];
    minZoom?: number;
    maxZoom?: number;
    opacity?: number;
}
export interface TilesMap {
    getTiles?: Observable<TileConfig[]>;
    getUrl?: Observable<string>;
}
