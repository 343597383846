import { Pipe, PipeTransform } from '@angular/core';
import { RunImpact } from '@libs/common';

import * as moment from 'moment-timezone';
import { formatTime, formatDate } from '@cityair/modules/impact/utils';

@Pipe({ name: 'impactRunTime' })
export class ImpactRunTimePipe implements PipeTransform {
    transform(run: RunImpact): string {
        const dateStart = moment(run.evaluation_time || 0);
        const dateEnd = moment(dateStart).add(run.duration_minutes, 'minutes');
        return dateStart.isSame(dateEnd, 'day')
            ? `${formatTime(dateStart)} – ${formatDate(dateEnd)}`
            : `${formatDate(dateStart)} –
           ${formatDate(dateEnd)}`;
    }
}
