import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { isFalseNumber } from '@libs/common';

@Component({
    selector: 'cs-control-point',
    templateUrl: './control-point.component.html',
    styleUrls: ['./control-point.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlPointComponent {
    @Input() item: { name: string };
    @Input() units: string;
    @Input() isActive = false;
    @Input() formattedValue: number;
    @Input() numberAfterDot: number;
    @Input() isObserver: boolean;

    @Output() openMenu = new EventEmitter<any>();

    isFalseNumber = isFalseNumber;

    clickMenu(event: any) {
        this.openMenu.emit(event);
    }
}
