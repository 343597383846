import { AqiType } from '../enums/aqi.type';

export const AVAILABLE_AQIS = [
    AqiType.aqiNN,
    AqiType.CAPI,
    AqiType.aqiIn,
    AqiType.instant,
    AqiType.R,
    AqiType.EU,
    AqiType.aqiPm,
];
