<div class="widget">
    <div class="widget-header">
        <div class="widget__title">
            {{ title }}
            <span class="widget__title-info"></span>
        </div>
        <div *ngIf="widgetLink" class="widget__link">
            <ng-container *ngTemplateOutlet="widgetLink" ></ng-container>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M1.30461 9.53508C0.914088 9.92561 0.914088 10.5588 1.30461 10.9493C1.69514 11.3398 2.3283 11.3398 2.71883 10.9493L1.30461 9.53508ZM11.497 1.75691C11.497 1.20462 11.0493 0.756906 10.497 0.756906L1.497 0.756906C0.944715 0.756906 0.497 1.20462 0.497 1.75691C0.497 2.30919 0.944715 2.75691 1.497 2.75691L9.497 2.75691L9.497 10.7569C9.497 11.3092 9.94472 11.7569 10.497 11.7569C11.0493 11.7569 11.497 11.3092 11.497 10.7569L11.497 1.75691ZM2.71883 10.9493L11.2041 2.46401L9.78989 1.0498L1.30461 9.53508L2.71883 10.9493Z" fill="#404655"/>
            </svg>
        </div>
    </div>
    <ng-content></ng-content>
</div>
