import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TEXTS } from '@libs/common';

@Component({
    selector: 'plumes-calculation-results-card',
    templateUrl: 'plumes-calculation-results-card.component.html',
    styleUrls: ['plumes-calculation-results-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlumesCalculationResultsCardComponent {
    @Input() isActive: boolean;
    @Input() isLoading: boolean;
    nowOnMapText = TEXTS.PLUMES.nowOnMap;
}
