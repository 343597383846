import { Pipe, PipeTransform } from '@angular/core';
import { CONTRIBUTIONS_COLORS_SOURCES_ORDER, Feature, getColorByIndex } from '@libs/common';

@Pipe({
    name: 'getWidthList',
})
export class GetWidthListPipe implements PipeTransform {
    transform(data: Feature[], mmts: string[]): number {
        if (data.length === 1 && mmts?.length === 1 && data[0]?.properties?.contributions) {
            if (data[0]?.properties?.has_any_contribution) {
                const contribData = data[0]?.properties?.contributions[mmts[0]];
                if (contribData) {
                    return data[0]?.properties?.contributionsDetails?.order?.length;
                }
                return 2;
            }
            return 2;
        }
        return mmts?.length;
    }
}
