import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'source-type-icon',
    templateUrl: 'source-type-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceTypeIconComponent {
    @Input() type: string;
}
