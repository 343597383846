import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { AuthService } from '@libs/shared-store';
import { datesUpdate } from '../../store/actions';
import { CITYAIR_API_BASIC_URL, STATION_ENTITY_STORE_KEY } from '../../constants';
import { Station, StationResponse } from './models';
import { isRU } from '@libs/common';

@Injectable({
    providedIn: 'root',
})
export class StationDataService extends DefaultDataService<Station> {
    private urlPath = this.urlGenerator.collectionResource(STATION_ENTITY_STORE_KEY, '');
    private lang = isRU ? 'ru' : 'en';
    constructor(
        http: HttpClient,
        private urlGenerator: HttpUrlGenerator,
        private store: Store,
        private authService: AuthService
    ) {
        super(STATION_ENTITY_STORE_KEY, http, urlGenerator, {
            root: `${environment.api_url}${CITYAIR_API_BASIC_URL}`,
        });
    }

    getAll(): Observable<Station[]> {
        throw new Error('not implemented');
    }

    getById(id: string | number): Observable<Station> {
        throw new Error('not implemented');
    }

    getWithQuery(params: QueryParams): Observable<Station[] | any> {
        const url = this.urlPath + params.group_id + '/Timeline/';
        const token = this.authService.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': this.lang,
            },
            params: params,
        };

        return this.http.get<StationResponse>(url, httpOptions).pipe(
            tap((response) => {
                if (response?.meta?.extra?.dates) {
                    this.store.dispatch(datesUpdate({ payload: response.meta.extra.dates }));
                }
            }),
            map((response) => response.data),
            catchError((error) => [])
        );
    }
}
