import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@libs/common';

@Pipe({
    name: 'getObjIsEmptyKey',
})
export class GetObjIsEmptyKeyPipe implements PipeTransform {
    transform(data: Feature[]): { [key: string]: boolean } {
        return data?.reduce((acc, item) => {
            const { timeseries } = item.properties;

            for (const key in timeseries) {
                if (key !== 'date') {
                    if (!acc.hasOwnProperty(key) || acc[key] !== false) {
                        acc[key] = !timeseries[key]?.some((v: number) => v !== null);
                    }
                }
            }
            if (item.properties?.contributions) {
                for (const key in item.properties?.contributions) {
                    if (key !== 'date') {
                        if (!acc.hasOwnProperty(key) || acc[key] !== false) {
                            acc[key] = false;
                        }
                    }
                }
            }
            if (item.properties.timelineContributions) {
                for (const key in item.properties?.timelineContributions) {
                    acc[key] = !item.properties?.timelineContributions[key]?.some(
                        (v: number) => v !== null
                    );
                }
            }
            return acc;
        }, {});
    }
}
