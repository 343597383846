<div class="devices-list-view">
    <div class="devices-list-view__search-line">
        <div class="search-wrapper">
            <search-input-basic
                [textPlaceholderInner]="TEXTS?.POSTS_AND_DEVICES.searchDevice"
                [textInner]="searchQuery"
                [debounceTimeInner]="400"
                (textChangeInner)="textChangeIn($event)"
            ></search-input-basic>
        </div>
    </div>
    <div
        [@inOutAnimation]
        *ngIf="!isShowSearchResultPlaceholder"
        class="devices-list-view__main_block scroll"
    >
        <div>
            <div class="devices-list-view__organizing-buttons">
                <shared-sort-list-item
                    (click)="
                        postsAndDevicesFacade.makeDeviceSorting(
                            postsAndDevicesFacade.sortDeviceSerialNum,
                            'sortDeviceSerialNum'
                        )
                    "
                    class="devices-list-view__organizing-buttons--item"
                    [sortingDirection]="postsAndDevicesFacade.sortingDirection.sortDeviceSerialNum"
                    [text]="TEXTS?.POSTS_AND_DEVICES.device"
                ></shared-sort-list-item>
                <!--                <shared-sort-list-item-->
                <!--                        (click)="makeSorting(sortType, 'sortType')"-->
                <!--                        class="devices-list-view__organizing-buttons&#45;&#45;item"-->
                <!--                        [sortingDirection]="sortingDirection.sortType"-->
                <!--                        [text]="TEXTS.POSTS_AND_DEVICES.type"></shared-sort-list-item>-->
                <shared-sort-list-item
                    (click)="
                        postsAndDevicesFacade.makeDeviceSorting(
                            postsAndDevicesFacade.sortDeviceState,
                            'sortDeviceState'
                        )
                    "
                    class="devices-list-view__organizing-buttons--item"
                    [sortingDirection]="postsAndDevicesFacade.sortingDirection.sortDeviceState"
                    [text]="TEXTS?.POSTS_AND_DEVICES.state"
                ></shared-sort-list-item>
            </div>
        </div>
            <ng-container
                *ngFor="
                    let device of devicesList
                        | multipleSearchfilter : ['arrSerialNumber', 'arrMoName'] : searchQuery
                        | sorting
                            : postsAndDevicesFacade.sortingDevice
                            : postsAndDevicesFacade.sortingDeviceDirectionMain
                "
            >
                <device-card
                    class="devices-list-view__monitoring-object-plate"
                    [serialNumber]="device.serial_number"
                    [isOnline]="!device.is_offline"
                    [isNetworks]="device.ext_info?.ps220"
                    [isBattery]="device.ext_info?.bat_ok"
                    [post]="device.post"
                    (openActionsPopup)="clickMenu($event, device.serial_number)"
                    (cbClickForStaticTags)="showDetailsMo($event)"
                ></device-card>
            </ng-container>
            <search-result-placeholder
                *ngIf="!isShowNotDate && searchQuery"
                class="screen-placeholders"
                [clearText]="TEXTS?.LIST_OM.clearSearch" (clear)="searchQuery = ''"
            ></search-result-placeholder>
            <non-data-screen
                *ngIf="isShowNotDate"
                class="screen-placeholders"
                [text]="TEXTS?.POSTS_AND_DEVICES.noDevices"
            ></non-data-screen>
    </div>
</div>

<ng-template #cardMenu>
    <card-actions-list
        class="devices-list-view__card-actions-list"
        [@inOutAnimation]
        *ngIf="isShowMenu"
        [position]="menuPositionTop"
        (clickOutside)="closePopup($event)"
    >
        <card-actions-item
            data-cy="action-rename"
            [text]="TEXTS?.POSTS_AND_DEVICES.viewInformation"
            (click)="openPage($event, path + pages.details + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
            data-cy="action-close"
            [text]="TEXTS?.POSTS_AND_DEVICES.loadPrimaryData"
            (click)="openPage($event, path + pages.deviceReport + '/' + idTargetCard)"
        ></card-actions-item>
    </card-actions-list>
</ng-template>
