import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MmtMapSelectorComponent } from './mmt-map-selector.component';
import { OverlayModule, TooltipsModule } from '@libs/shared-ui';

@NgModule({
    imports: [CommonModule, OverlayModule, TooltipsModule],
    exports: [MmtMapSelectorComponent],
    declarations: [MmtMapSelectorComponent],
})
export class MmtMapSelectorComponentModule {}
