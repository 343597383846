import { Component, Input } from '@angular/core';

@Component({
    selector: 'cs-analytic-chart-title',
    templateUrl: './analytic-chart-title.component.html',
    styleUrls: ['./analytic-chart-title.component.less'],
})
export class AnalyticChartTitleComponent {
    @Input() title: string;
    @Input() tooltip1: string;
    @Input() tooltip2: string;
}
