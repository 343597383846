import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonAddComponent } from './button-add.component';

@NgModule({
    exports: [ButtonAddComponent],
    declarations: [ButtonAddComponent],
    imports: [CommonModule],
})
export class ButtonAddModule {}
