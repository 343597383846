import { METEO_FORECAST_TO_BASIC, METEO_MMTS_FORECAST } from '@libs/common';

export function transformMeteoForecastToBasicMmt(mmt: string) {
    if (METEO_MMTS_FORECAST.includes(mmt)) {
        return METEO_FORECAST_TO_BASIC[mmt] ?? null;
    }
    return mmt;
}
export function transformBasicMmtToForecast(mmt: string) {
    if (Object.keys(METEO_FORECAST_TO_BASIC).includes(mmt)) {
        return METEO_FORECAST_TO_BASIC[mmt];
    }
    return mmt;
}
