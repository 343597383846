<shared-ui-ol-map *ngIf="showMap"
    class="ol-map mapbox-map"
    [type]="store.select(selectCurrentMapStyleType) | async"
    [style]="style"
    [zoom]="mapZoom"
    [minZoom]="mapSettings.minzoom"
    [maxZoom]="mapSettings.maxzoom"
    [extent]="mapExtent"
    [enableRotation]="false"
    [center]="mapCenter"
    (zoomEvt)="onZoom($event)"
    (mapCreate)="mapLoad($event)"
    (mapMoveEnd)="moveEnd($event)"
    (mapMoveStart)="moveStart()"
    (mapClick)="clickedMap($event)"
>
    <!-- Group layers -->
    <ng-container *ngIf="groupFeaturesLayer">
        <shared-ui-ol-layer
            [features]="groupFeaturesLayer"
        ></shared-ui-ol-layer>
    </ng-container>
    <!-- Forecast (public) -->
    <ng-container *ngIf="(store.select(selectIsShowOnMapPublicForecast) | async) && domainTilesPlayer?.imageUrl$ | async as url">

        <ng-container  *ngIf="domainTilesPlayer?.imageUrl$ | async as url">
            <shared-ui-ol-image
                [url]="url"
                [imageExtent]="domainTilesPlayer?.config?.domain?.extent"
                [projection]="'EPSG:3857'"
                [imageLoadFunction]="authorizeTileRequest"
                [opacity]="domainTilesPlayer?.config?.opacity"
            ></shared-ui-ol-image>
            <domain-border *ngIf="url" [coordinates]="domainTilesPlayer?.config.domain.coordinates" [isOpenLayers]="true"></domain-border>
        </ng-container>
    </ng-container>
    <!-- Lens Forecast (public) -->
    <ng-container
        *ngIf="(store.select(selectIsShowOnMapLensForecast) | async) && lensTilesPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="lensTilesPlayer?.config as cfg">
            <shared-ui-ol-image
                [url]="url"
                [imageExtent]="lensTilesPlayer?.config?.domain?.extent"
                [projection]="'EPSG:3857'"
                [imageLoadFunction]="authorizeTileRequest"
                [opacity]="lensTilesPlayer?.config?.opacity"
            ></shared-ui-ol-image>
            <domain-border *ngIf="url" [coordinates]="lensTilesPlayer?.config.domain.coordinates" [isOpenLayers]="true"></domain-border>
        </ng-container>
    </ng-container>
    <!-- Forecast (private) -->
    <ng-container
        *ngIf="(showForecastLayer$ | async) && domainTilesForecastPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="domainTilesForecastPlayer.config as cfg">
            <shared-ui-ol-image
                [url]="url"
                [imageExtent]="domainTilesForecastPlayer?.config?.domain?.extent"
                [projection]="'EPSG:3857'"
                [imageLoadFunction]="authorizeTileRequest"
                [opacity]="domainTilesForecastPlayer?.config?.opacity"
            ></shared-ui-ol-image>
            <domain-border *ngIf="url" [coordinates]="domainTilesForecastPlayer?.config.domain.coordinates" [isOpenLayers]="true"></domain-border>
        </ng-container>
    </ng-container>
    <!-- Plumes -->
    <ng-container
        *ngIf="(showPlumesLayer$ | async) && plumesTilesPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="plumesTilesPlayer.config as cfg">
              <shared-ui-ol-image
                [url]="url"
                [imageExtent]="cfg.domain?.extent"
                [projection]="'EPSG:3857'"
                [imageLoadFunction]="authorizeTileRequest"
                [opacity]="cfg.opacity"
            ></shared-ui-ol-image>

            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates" [isOpenLayers]="true"></domain-border>
        </ng-container>
    </ng-container>
    <!-- Run Sources -->
    <ng-container *ngIf="plumesAvailable | async">
        <shared-ui-ol-overlay
            *ngFor="let source of runSources$ | async"
            [position]="source.centroid?.coordinates"
            [positioning]="'bottom-center'"
        >
            <plume-source-marker [name]="source.name"></plume-source-marker>
        </shared-ui-ol-overlay>
    </ng-container>
    <!-- Regular markers -->
    <ng-container *ngIf="postPins?.getSelectedPinIds | async as selected">
        <shared-ui-ol-overlay
            *ngFor="let pin of postPins?.getPins | async"
            [className]="
                [
                    'overlay-marker',
                    selected.includes(pin.id) ? 'selected' : '',
                ].join(' ')
            "
            [position]="pin.geometry?.coordinates"
            [positioning]="'bottom-center'"
        >
            <post-pin
                *ngIf="{ a: postPins.getValue(pin) | async } as val"
                [value]="val.a"
                [numberAfterDot]="(postPins.selectDigitsAfterDot | async) ?? 1"
                [color]="postPins.getColor(pin) | async"
                [selected]="selected.includes(pin.id)"
                [state]="getMarkerState(pin.id) | async"
                (click)="postPins.clickCb(pin)"
                (mouseenter)="pinTooltipText = isTouchDevice ? '' : pin.name"
                caTooltip
                caTooltipPlacement="left"
                [caTooltipFollowPointer]="true"
                [caTooltipTemplate]="markerTooltip"
            ></post-pin>
        </shared-ui-ol-overlay>
    </ng-container>
     <!-- City markers -->
    <ng-container *ngIf="cityPins?.getSelectedPinIds | async as selected">
        <shared-ui-ol-overlay
            *ngFor="let pin of cityPins?.getPins | async"
            [className]="
                [
                    'overlay-marker',
                    selected.includes(pin.id) ? 'selected' : '',
                ].join(' ')
            "
            [position]="pin?.geometry?.coordinates"
            [positioning]="'bottom-center'"
        >
            <city-pin
                *ngIf="{ a: cityPins.getValue(pin) | async } as val"
                [city]="pin.name"
                [value]="val.a"
                [numberAfterDot]="(cityPins.selectDigitsAfterDot | async) ?? 1"
                [color]="cityPins.getColor(pin) | async"
                [selected]="selected.includes(pin.id)"
                [state]="getMarkerState(pin.id) | async"
                (click)="cityPins.clickCb(pin)"
            ></city-pin>
        </shared-ui-ol-overlay>
    </ng-container>
    <!-- Markers selected for notifications  -->
    <shared-ui-ol-overlay
        *ngFor="let pin of notificationSelectedPins?.getPins | async"
        [className]="'overlay-marker'"
        [position]="pin?.geometry?.coordinates"
        [positioning]="'bottom-center'"
    >
        <post-pin
            *ngIf="{ a: notificationSelectedPins.getValue(pin) | async } as val"
            [value]="val.a"
            [numberAfterDot]="(notificationSelectedPins.selectDigitsAfterDot | async) ?? 1"
            [color]="postPins.getColor(pin) | async"
            [selected]="true"
            [state]="markerState.selectPost"
            (click)="notificationSelectedPins.clickCb(pin)"
            (mouseenter)="pinTooltipText = isTouchDevice ? '' : pin.name"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipFollowPointer]="true"
            [caTooltipTemplate]="markerTooltip"
        ></post-pin>
    </shared-ui-ol-overlay>

    <!-- Control points -->
    <ng-container *ngIf="controlPointPins?.getSelectedPinIds | async as selected">
        <shared-ui-ol-overlay
            *ngFor="let point of controlPointPins?.getPins | async"
            [className]="
                [
                    'overlay-marker',
                    selected.includes(point.id) ? 'selected_without_top' : ''
                ].join(' ')
            "
            [position]="[point.lon, point.lat]"
            [positioning]="'bottom-center'"
            [dragging]="controlPointPins.isDraggable(point) | async"
            (overlayDragEnd)="controlPointPins.dragEnd($event, point)"
        >
            <control-point-pin
                *ngIf="{ a: controlPointPins.getValue(point) | async } as val"
                [value]="val.a"
                [color]="controlPointPins.getColor(point) | async"
                [numberAfterDot]="(controlPointPins.selectDigitsAfterDot | async) ?? 1"
                [name]="point?.name"
                [selected]="selected.includes(point.id)"
                [draggable]="controlPointPins.isDraggable(point) | async"
                (click)="controlPointPins.clickCb(point)"
                (mouseenter)="pinTooltipText = isTouchDevice ? '' : point.name"
                caTooltip
                caTooltipPlacement="left"
                [caTooltipHidden]="controlPointPins.isDraggable(point) | async"
                [caTooltipFollowPointer]="true"
                [caTooltipTemplate]="markerTooltip"
            ></control-point-pin>
        </shared-ui-ol-overlay>
    </ng-container>
    <!-- Markers for analysis  -->
    <ng-container *ngIf="correlationPins?.getSelectedPinIds | async as selected">
        <ng-container  *ngIf="correlationPins?.getHover | async as hovered">
            <shared-ui-ol-overlay
                *ngFor="let pin of correlationPins?.getPins | async"
                    [className]="[
                           'overlay-marker',
                            selected.includes(pin.id) ? 'selected' : ''
                        ].join(' ')"
                    [dynamicClass]="[hovered.includes(pin.id) ? 'hovered' : ''].join(' ')
                    "
                [position]="pin?.geometry?.coordinates"
                [positioning]="'bottom-center'"
            >
                <cityair-correlation-pin
                    [value]="correlationPins.getValue(pin)| async"
                    [color]="selected.includes(pin.id)? '#653CD8' : '#404655'"
                    [selected]="selected.includes(pin.id)"
                    [notActive]="selected.length === 2 && !selected.includes(pin.id)"
                    [valueMode]="correlationPins?.isValueMode | async"
                    (click)="correlationPins.clickCb(pin)"
                    caTooltip
                    caTooltipPlacement="left"
                    [caTooltipFollowPointer]="true"
                    [caTooltipTemplate]="correlationPinTooltip"
                ></cityair-correlation-pin>
                <ng-template #correlationPinTooltip>
                    <ca-info-tooltip *ngIf="!isTouchDevice" [text]="(correlationPins.getTooltip(pin) | async)"></ca-info-tooltip>
                </ng-template>
            </shared-ui-ol-overlay>
        </ng-container>
    </ng-container>
    <!-- Line beetween correlation pins for analysis module  -->
    <ng-container *ngIf="sourceLine?.getLine | async as data">
        <ng-container *ngFor="let line of data">
            <shared-ui-ol-layer
                [features]="line"
                (layerMouseOver)="sourceLine?.mouseEnter($event, line)"
                (layerMouseOut)="sourceLine?.mouseLeave()"
            ></shared-ui-ol-layer>
        </ng-container>
   </ng-container>
    <ng-container *ngIf="polygon?.getPolygon | async as dataPolygon">
        <shared-ui-ol-layer
            [features]="dataPolygon"
        ></shared-ui-ol-layer>
    </ng-container>
    <ng-container *ngIf="infoPins?.getPins | async as infoPinsNetwork">
        <shared-ui-ol-overlay
                *ngFor="let pin of infoPinsNetwork"
                [position]="[pin.lat, pin.lon]"
                [positioning]="'bottom-center'"
            >
            <cityair-info-pin [name]="pin.name" [color]="pin.color"></cityair-info-pin>
        </shared-ui-ol-overlay>
    </ng-container>
</shared-ui-ol-map>
<ng-template #markerTooltip>
    <ca-info-tooltip [text]="pinTooltipText"></ca-info-tooltip>
</ng-template>
