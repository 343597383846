import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupListItemMobileComponent } from './group-list-item-m.component';

@NgModule({
    imports: [CommonModule],
    exports: [GroupListItemMobileComponent],
    declarations: [GroupListItemMobileComponent],
})
export class GroupListItemMobileModule {}
