<ca-tooltip-placement>
    <div
        class="detailed-tooltip"
        [class.detailed-tooltip--content]="hasContent"
        *ngIf="tooltipsService.currentTarget"
        [@inOutAnimation]
    >
        <ca-tooltip-pointer [fill]="arrowColor"></ca-tooltip-pointer>
        <div class="detailed-tooltip__header">
            <div class="detailed-tooltip__title" [innerHtml]="title"></div>
        </div>
        <div class="detailed-tooltip__content" *ngIf="hasContent">
            <div
                class="detailed-tooltip__text detailed-tooltip__p detailed-tooltip__em"
                *ngIf="description"
                [innerHtml]="description"
            ></div>
            <div class="detailed-tooltip__text detailed-tooltip__p" *ngFor="let textItem of text">
                {{ textItem }}
            </div>
        </div>
    </div>
</ca-tooltip-placement>
