import { IntervalEnum } from '../enums/interval.enum';

export const AVAILABLE_INTERVALS = [
    {
        interval: IntervalEnum.min5,
        days: 3.3,
    },
    {
        interval: IntervalEnum.min20,
        days: 12,
    },
    {
        interval: IntervalEnum.hour,
        days: 36,
    },
    {
        interval: IntervalEnum.day,
        days: Infinity,
    },
];
