import {
    createAction,
    createFeature,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';
import { MoItemsDataToExcelRequestProps, StationDataToExcelRequestProps } from '@libs/common';
import { HttpErrorResponse } from '@angular/common/http';

export enum PostActions {
    addPostError = '[PostActions] add post error',
    updatePostError = '[PostActions] update post error',
    deletePost = '[PostActions] delete post',
    deletePostSuccess = '[PostActions] delete post success',
    deletePostError = '[PostActions] delete post error',
    isLoadingPostForm = '[PostActions] is loading post form',
    closePostForm = '[PostActions] close post form',
    downloadDataPosts = '[PostActions] download data posts',
    downloadDataResult = '[PostActions] download data result',
    downloadDataDevice = '[PostActions] download data device',
}
export const loadingPostsForm = createAction(
    PostActions.isLoadingPostForm,
    props<{ payload: boolean }>()
);

export const addPostError = createAction(
    PostActions.addPostError,
    props<{ payload: HttpErrorResponse }>()
);
export const updatePostError = createAction(
    PostActions.updatePostError,
    props<{ payload: HttpErrorResponse }>()
);
export const deletePostError = createAction(PostActions.deletePostError);
export const closePostForm = createAction(PostActions.closePostForm, props<{ payload: boolean }>());
export const downloadDataPosts = createAction(
    PostActions.downloadDataPosts,
    props<{ params: MoItemsDataToExcelRequestProps; filename: string }>()
);
export const downloadDataDevice = createAction(
    PostActions.downloadDataDevice,
    props<{ params: StationDataToExcelRequestProps; filename: string }>()
);
export const downloadDataResult = createAction(
    PostActions.downloadDataResult,
    props<{ payload: boolean }>()
);

type PostsState = {
    isLoadingForm: boolean;
    closePostForm: boolean;
    formError: HttpErrorResponse;
    downloadIsSuccess: boolean;
};

const initialState: PostsState = {
    isLoadingForm: false,
    closePostForm: false,
    formError: null,
    downloadIsSuccess: null,
};
export const postsFeature = createFeature({
    name: 'posts',
    reducer: createReducer(
        initialState,
        on(closePostForm, (state, { payload }) => ({
            ...state,
            closePostForm: payload,
        })),
        on(addPostError, (state, { payload }) => ({
            ...state,
            formError: payload,
        })),
        on(updatePostError, (state, { payload }) => ({
            ...state,
            formError: payload,
        })),
        on(downloadDataResult, (state, { payload }) => ({
            ...state,
            downloadIsSuccess: payload,
        }))
    ),
});
const featureSelector = createFeatureSelector<PostsState>('posts');
export const isLoadingForm = createSelector(featureSelector, (state) => state.isLoadingForm);
export const selectIsClosePostForm = createSelector(
    featureSelector,
    (state) => state.closePostForm
);
export const selectPostFormError = createSelector(featureSelector, (state) => state.formError);
export const selectDownloadResult = createSelector(
    featureSelector,
    (state) => state.downloadIsSuccess
);
