<div class="event_item"
    [ngClass]="{'event_item--visited': notification.is_viewed, 'not-allow-navigation': disableNavigation }"
    (click)="gotoMonitoringObject.emit(notification); markAsVisited()"
>
    <div class="event_item__header_wrapper">
        <div class="event_item__header_left">
            <div class="event_item__header_title"
                *ngIf="configurable; else blankTitle"
                caTooltip
                caTooltipPlacement="top-left"
                [caTooltipTemplate]="eventTooltip"
                [ngClass]="{'not_active': !settings?.is_active, 'active': settings?.is_active }"
                (click)="settings?.is_active && openSettings()"
            >
                <div class="ellipsis">
                    {{ settings?.name }}
                </div>
            </div>
            <ng-template #eventTooltip>
                <ca-tooltip *ngIf="settings?.is_active" [text]="textsNotification.tooltipEventTitle"></ca-tooltip>
            </ng-template>
            <ng-template #blankTitle>
                <div class="event_item__header_title event_item__header_title-no_select ellipsis">
                    {{ settings?.name }}
                </div>
            </ng-template>
        </div>
    </div>
    <ng-container [ngSwitch]="this.notification.type">
        <div class="event_body" *ngSwitchCase="NotificationType.pdkExcess">
            <div class="event_body__wrapper_str status">
                <div class="event_body__status" [ngClass]="{'event_body__status-red': notification.status !== eventTypes.End }">
                    {{ textsNotification.notificationAlertLabel.pdk[notification.status] }}
                </div>
                <div class="duration">
                    <div
                        class="event_item__header_right"
                        caTooltip
                        caTooltipPlacement="top-right"
                        [caTooltipTemplate]="durationTooltip"
                    >
                        <div class="event_item__header_period">
                            {{ textsNotification.durationDescription[durationType] }}:
                        </div>
                        <div class="event_item__header_timer">
                            {{ headerDateTimeText }}
                        </div>
                    </div>
                    <ng-template #durationTooltip>
                        <ca-tooltip
                            *ngIf="notification.status !== BasicNotificationEventType.Begin || notification.update_time"
                        >
                            <div class="event_item__duration-tooltip" *ngIf="hasEnded; else inProgress">
                                <div class="event_item__duration-tooltip__label">
                                    {{ textsNotification.tooltipDurationLabel[1] }}
                                </div>
                                <div class="event_item__duration-tooltip__details">
                                    {{ durationTooltipText }}
                                </div>
                            </div>
                            <ng-template #inProgress>
                                <div class="event_item__duration-tooltip">
                                    <div class="event_item__duration-tooltip__label">
                                        {{ textsNotification.tooltipDurationLabel[0] }}
                                    </div>
                                    <div class="event_item__duration-tooltip__details">
                                        {{ textsNotification.duration }}: {{ durationTooltipText }}
                                    </div>
                                </div>
                            </ng-template>
                        </ca-tooltip>
                    </ng-template>
                </div>
            </div>
            <div class="event_body__wrapper_str">
                <div class="event_body__mo_name" [ngClass]="{'event_body__mo_name--clickable': !disableNavigation }">
                    {{ post?.name }}
                </div>
            </div>
            <div class="event_body__wrapper_str event_body__wrapper_str-content_left" *ngIf="notification.status === eventTypes.End">
                <ca-substances-list [items]="[textNames[notification.pdk_value_type] ?? notification.pdk_value_type]"></ca-substances-list>
                <div class="wrapper">
                    <div class="event_body__description">
                        {{ textsNotification.statusTexts.pdk[hasEnded ? 1 : 0] }}
                        {{
                            hasEnded
                                ? ''
                                : [
                                      notification.excess_info.pdk_multiplier,
                                      textsNotification.excessLevelUnits
                                  ].join(' ')
                        }}
                    </div>
                    <div class="event_body__qm" [innerHTML]="pdkValues"></div>
                </div>
                <div class="time" *ngIf="firstEventVisible">
                    <span style="margin-top: 5px;">
                        {{ todayDate  | shortDateWithToday :notification.end_time ?? notification.update_time}}
                    </span>
                </div>
            </div>
            <div class="event_body__wrapper_str first-event" *ngIf="firstEventVisible || notification.status !== eventTypes.End">
                <div class="begin-wrapper">
                    <ca-substances-list [items]="[textNames[notification.pdk_value_type] ?? notification.pdk_value_type]"></ca-substances-list>
                    <div class="event_body__description">
                        {{ textsNotification.statusTexts.pdk[0] }}
                        {{ [notification.excess_info.pdk_multiplier, textsNotification.excessLevelUnits].join(' ')}}
                    </div>
                </div>
                <div class="time" *ngIf="notification.status === eventTypes.End">
                    <span>{{ todayDate  | shortDateWithToday : notification.begin_time}}</span>
                </div>
            </div>
        </div>
        <div class="event_body" *ngSwitchCase="NotificationType.deviceIncident">
            <div class="event_body__wrapper_str status">
                 <div class="event_body__status" [ngClass]="{
                    'event_body__status-blue': notification.status === eventTypes.End && notification.service_excess_type === serviceExcessTypes.DeviceNoData,
                    'event_body__status-yellow': notification.status !== eventTypes.End && notification.service_excess_type === serviceExcessTypes.DeviceNoPs220,
                    'event_body__status-grey': notification.status !== eventTypes.End && notification.service_excess_type === serviceExcessTypes.DeviceNoData
                 }"
                 >
                    <ng-container
                        *ngIf="notification.service_excess_type === serviceExcessTypes.DeviceNoData"
                    >
                        {{ textsNotification.notificationAlertLabel.nodata[notification.status] }}
                    </ng-container>
                    <ng-container
                        *ngIf="notification.service_excess_type === serviceExcessTypes.DeviceNoPs220"
                    >
                        {{ textsNotification.notificationAlertLabel.nopower[notification.status] }}
                    </ng-container>
                </div>
                <div class="duration">
                    <div
                        class="event_item__header_right"
                        caTooltip
                        caTooltipPlacement="top-right"
                        [caTooltipTemplate]="durationTooltip"
                    >
                        <div class="event_item__header_period">
                            {{ textsNotification.durationDescription[durationType] }}:
                        </div>
                        <div class="event_item__header_timer">
                            {{ headerDateTimeText }}
                        </div>
                    </div>
                    <ng-template #durationTooltip>
                        <ca-tooltip
                            *ngIf="notification.status !== BasicNotificationEventType.Begin || notification.update_time"
                        >
                            <div class="event_item__duration-tooltip" *ngIf="hasEnded; else inProgress">
                                <div class="event_item__duration-tooltip__label">
                                    {{ textsNotification.tooltipDurationLabel[1] }}
                                </div>
                                <div class="event_item__duration-tooltip__details">
                                    {{ durationTooltipText }}
                                </div>
                            </div>
                            <ng-template #inProgress>
                                <div class="event_item__duration-tooltip">
                                    <div class="event_item__duration-tooltip__label">
                                        {{ textsNotification.tooltipDurationLabel[0] }}
                                    </div>
                                    <div class="event_item__duration-tooltip__details">
                                        {{ textsNotification.duration }}: {{ durationTooltipText }}
                                    </div>
                                </div>
                            </ng-template>
                        </ca-tooltip>
                    </ng-template>
                </div>
            </div>
            <div class="event_body__wrapper_str serial-number">
                <div class="event_body__mo_name" *ngIf="notification.service_excess_info.device_serial;"
                    [ngClass]="{'event_body__mo_name--clickable': !disableNavigation }"
                >
                     {{ notification.service_excess_info.device_serial }}
                </div>
            </div>
            <div class="event_body__wrapper_str device">
                <div class="event_body__mo_device" *ngIf="post; else notFound">
                    <div class="event_body__mo_device__name">
                        {{ post?.name }}
                    </div>
                </div>
                <ng-template #notFound>
                    <div class="event_body__mo_name event_body__mo_name--notfound">
                        {{ textsNotification.moNotFoundOrDeleted }}
                    </div>
                </ng-template>
            </div>
            <div class="event_body__wrapper_str event_body__wrapper_str-content_left"
                 style="margin-bottom: 0"
                 *ngIf="notification.status !== eventTypes.End"
            >
                <div class="event_body__description" [ngSwitch]="notification.service_excess_type">
                    <ng-container *ngSwitchCase="serviceExcessTypes.DeviceNoData">
                        {{ textsNotification.statusTexts.nodata[0] }} {{ notification.begin_time | durationFullFormat: currentTime : textDuration }}
                    </ng-container>
                    <ng-container *ngSwitchCase="serviceExcessTypes.DeviceNoPs220">
                        {{ textsNotification.statusTexts.nopower[0] }} {{ notification.begin_time | durationFullFormat: currentTime : textDuration }}
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>

   <div class="event_item__more" *ngIf="this.notification.type === NotificationType.pdkExcess && hasEnded">
        <div class="event_item__more__text"
            [ngClass]="{ 'event_item__more__text--revert': firstEventVisible }"
            (click)="toggleFirstEvent($event)"
        >
            {{ firstEventVisible ? textsNotification.collapse : textsNotification.showFirstEvent }}
        </div>
   </div>
</div>

