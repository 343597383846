<div class="indoor-marker" [ngClass]="{ selected: selected }">
    <div class="body">
        <div class="circle">
            <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="34" height="34" rx="17" [attr.fill]="color" />
                <path d="M10 27V8H24V27H10Z" fill="white" />
                <rect x="12" y="9.99902" width="2" height="3" [attr.fill]="color" />
                <rect x="16" y="9.99902" width="2" height="3" [attr.fill]="color" />
                <rect x="20" y="9.99902" width="2" height="3" [attr.fill]="color" />
                <rect x="12" y="14" width="2" height="3" [attr.fill]="color" />
                <rect x="12" y="18" width="2" height="3" [attr.fill]="color" />
                <rect x="12" y="22" width="2" height="3" [attr.fill]="color" />
                <rect x="16" y="14" width="2" height="3" [attr.fill]="color" />
                <rect x="16" y="18" width="2" height="3" [attr.fill]="color" />
                <rect x="16" y="22" width="2" height="3" [attr.fill]="color" />
                <rect x="20" y="14" width="2" height="3" [attr.fill]="color" />
                <rect x="20" y="18" width="2" height="3" [attr.fill]="color" />
                <rect x="20" y="22" width="2" height="3" [attr.fill]="color" />
                <rect x="8" y="27" width="18" height="1" fill="white" />
                <rect x="12" y="7" width="10" height="1" fill="white" />
                <rect x="14" y="6" width="6" height="1" fill="white" />
                <rect width="2" height="2" transform="matrix(1 0 0 -1 16 6)" fill="white" />
            </svg>
        </div>
        <div class="name" [style]="{ color: color }">{{ name }}</div>
    </div>
    <div class="line" [style.background-color]="color"></div>
    <div class="end" [style.background-color]="color"></div>
</div>
