import { Pipe, PipeTransform } from '@angular/core';
import { EventImpact, EventItem } from '@cityair/modules/impact/service/api-model-impact';

import { Dictionary } from '@ngrx/entity';
import { CONTRIBUTIONS_COLORS_SOURCES_ORDER } from '@libs/common';
import * as moment from 'moment-timezone';
import { I18nPluralPipe } from '@angular/common';
import { formatDuration } from '@libs/common';

@Pipe({
    name: 'repeatExceedEvent',
})
export class RepeatExceedEventPipe implements PipeTransform {
    constructor(private i18nPipe: I18nPluralPipe) {}
    transform(data: EventItem, event: EventImpact): string {
        if (data && event) {
            const durationCurrent = moment.duration(moment(data.end).diff(data.start));
            const durationEvent = moment.duration(moment(event.end).diff(event.start));
            const percent = Math.round(
                (durationCurrent.asMilliseconds() * 100) / durationEvent.asMilliseconds()
            );
            const text = formatDuration(durationCurrent);
            if (isNaN(percent)) {
                return `${text}`;
            }
            return `${text} (${percent}%)`;
        }
        return '';
    }
}
