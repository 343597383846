import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticTagsComponent } from './static-tags.component';

@NgModule({
    imports: [CommonModule],
    exports: [StaticTagsComponent],
    declarations: [StaticTagsComponent],
})
export class StaticTagsModule {}
