<div class="plumes-run-summary">
    <div class="plumes-run-summary__config-header">
        <div class="config-name">{{config?.name}}</div>
        <div class="wind" *ngIf="config?.wind_on">
            <span>{{textPlumes.quickWindButton}}</span>
        </div>
    </div>
    <div class="plumes-run-summary__evaluated">
        <div class="plumes-run-summary__forecast">
            <div class="plumes-run-summary__subtitle">
                {{ textPlumes.forecastOn }}
            </div>
            <div
                class="plumes-run-summary__item plumes-run-summary__item-bold"
                *ngIf="dateStart && dateEnd"
            >
                {{ dateStart.isSame(dateEnd, 'day') ? formatTime(dateStart) : formatDate(dateStart) }} –
                {{ formatDate(dateEnd) }}
            </div>
        </div>
        <div class="plumes-run-summary__item run-time" *ngIf="runFinished">
            {{ textPlumes.resultsAt }} {{ formatDate(runFinished) }}
        </div>
    </div>
</div>
