import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

import { PipesModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { MapboxMapComponent } from '@cityair/modules/map/components/mapbox/mapbox.component';
import { BoundaryMarkerComponent } from '@cityair/modules/map/components/boundary-marker/boundary-marker.component';
import { DomainBorderComponent } from '@cityair/modules/map/components/domain-border/domain-border.component';
import { PlumeSourceMarkerModule } from '@cityair/modules/map/components/plume-source-marker/plume-source-marker.module';
import { ControlPointMarkerModule } from '@cityair/modules/map/components/control-point-marker/control-point-marker.module';
import { CityPinModule } from '@cityair/components/city-pin/city-pin.module';
import { PostPinModule } from '@cityair/components/post-pin/post-pin.module';
import { ImpactSourcePinComponent } from '@cityair/components/impact-source-pin/impact-source-pin.component';
import { ControlPointPinModule } from '@cityair/components/control-point-pin/control-point-pin.module';
import { InfoPinModule } from '@cityair/components/info-pin/info-pin.modules';
import { OlMapComponent } from '@cityair/modules/map/components/ol-map/ol-map.component';
import { CorrelationPinModule, OlModule } from '@libs/shared-ui';
import { ControlPointPlumePinModule } from '@cityair/components/control-point-plume-pin/control-point-plume-pin.module';
import { ImpactPostPinComponent } from '@cityair/components/impact-post-pin/impact-post-pin.component';
import { NetworkPinModule } from '@cityair/components/network-pin/network-pin.module';
import { ImpactRegionPinComponent } from '@cityair/components/impact-region-pin/impact-region-pin.component';
const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1IjoiY2l0eWFpciIsImEiOiJjazk4MWhja3IwNHZiM25waDJkeWhheHQ0In0.2cWaHj0iCYNPagxl9GBTuA';

const YANDEX_ACCESS_TOKEN = '62e6389e-8a8c-4a54-963f-4ebfdb288fa7';

@NgModule({
    imports: [
        CommonModule,
        NgxMapboxGLModule.withConfig({
            accessToken: MAPBOX_ACCESS_TOKEN,
        }),
        OlModule.withConfig({
            key: MAPBOX_ACCESS_TOKEN,
            yaKey: YANDEX_ACCESS_TOKEN,
        }),
        PipesModule,
        TooltipsModule,
        CityPinModule,
        PostPinModule,
        CorrelationPinModule,
        ControlPointPinModule,
        ControlPointPlumePinModule,
        PlumeSourceMarkerModule,
        ControlPointMarkerModule,
        InfoPinModule,
        ImpactSourcePinComponent,
        ImpactPostPinComponent,
        NetworkPinModule,
        ImpactRegionPinComponent,
    ],
    exports: [MapboxMapComponent, OlMapComponent],
    declarations: [
        MapboxMapComponent,
        BoundaryMarkerComponent,
        DomainBorderComponent,
        OlMapComponent,
    ],
})
export class MapModule {}
