<div
    *showDirective="show"
    [ngClass]="{
        calendar_mobile__months: true,
        'calendar_mobile__months-m': true
    }"
>
    <div class="calendar_mobile__header">
        <div class="calendar_mobile__arrow_back" (mousedown)="toggleShowCb()"></div>
        <div
            class="calendar_mobile__apply calendar_mobile__apply-m {{
                calendarState.begin && calendarState.end
                    ? 'calendar_mobile__apply-show'
                    : 'calendar_mobile__apply-disabled'
            }}"
            (click)="calendarActions.apply()"
        >
            {{ TEXTS.COMMON.apply }}
        </div>
    </div>
    <table>
        <tr class="calendar_mobile__table_header">
            <th>{{ TEXTS.WEEKDAY.mo }}</th>
            <th>{{ TEXTS.WEEKDAY.tu }}</th>
            <th>{{ TEXTS.WEEKDAY.we }}</th>
            <th>{{ TEXTS.WEEKDAY.th }}</th>
            <th>{{ TEXTS.WEEKDAY.fr }}</th>
            <th>{{ TEXTS.WEEKDAY.sa }}</th>
            <th>{{ TEXTS.WEEKDAY.su }}</th>
        </tr>
        <div
            id="calendar_mobile__table_body"
            class="calendar_mobile__table_body"
            (afterInitDirective)="scrollTo()"
            (click)="dayClickMobile($event)"
        >
            <tbody
                *ngFor="let month of calendarState.monthsMobile"
                class="calendar_mobile__month-m"
            >
                <div class="calendar_mobile__month_name-m">{{ month.name }}</div>
                <tr *ngFor="let week of month.daysArr">
                    <td
                        *ngFor="let day of week; let i = index"
                        id="{{ day ? day.timestamp : '' }}"
                        class="{{
                            day && !day.disabled
                                ? 'calendar_mobile__day calendar_mobile__day-m'
                                : 'calendar_mobile__day-disabled'
                        }} {{ isHover(day, i) }}"
                    >
                        {{ day ? day.text : '' }}
                    </td>
                </tr>
            </tbody>
        </div>
    </table>

    <div class="calendar_mobile__bottom calendar_mobile__bottom-m">
        <div
            class="calendar_mobile__time_button calendar_mobile__time_button-m {{
                calendarState.activeBtn === '1' ? 'calendar_mobile__time_button-active' : ''
            }}"
            (click)="timeButtonClick('1'); scrollToTimeout()"
            [innerHTML]="TEXTS.TIME_NAMES.watches24"
        ></div>
        <div
            class="calendar_mobile__time_button calendar_mobile__time_button-m {{
                calendarState.activeBtn === '3' ? 'calendar_mobile__time_button-active' : ''
            }}"
            (click)="timeButtonClick('3'); scrollToTimeout()"
            [innerHTML]="TEXTS.TIME_NAMES.days3"
        ></div>
        <div
            class="calendar_mobile__time_button calendar_mobile__time_button-m {{
                calendarState.activeBtn === 'week' ? 'calendar_mobile__time_button-active' : ''
            }}"
            (click)="timeButtonClick('week'); scrollToTimeout()"
            [innerHTML]="TEXTS.TIME_NAMES.week"
        ></div>
        <div
            class="calendar_mobile__time_button calendar_mobile__time_button-m {{
                calendarState.activeBtn === 'month' ? 'calendar_mobile__time_button-active' : ''
            }}"
            (click)="timeButtonClick('month'); scrollToTimeout()"
            [innerHTML]="TEXTS.TIME_NAMES.month"
        ></div>
    </div>
</div>
