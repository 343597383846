export class TicksHelper {
    private mMajor: number;
    private hasMajor: boolean;
    private mIntervalMajor: number;

    private mMinor: number;
    private hasMinor: boolean;
    private mIntervalMinor: number;

    constructor(intervalMajor: number, intervalMinor: number) {
        this.mMajor = this.getDecimalExp(intervalMajor);
        this.hasMajor = !isNaN(intervalMajor);
        this.mIntervalMajor = intervalMajor * this.mMajor;

        this.mMinor = this.getDecimalExp(intervalMinor);
        this.hasMinor = !isNaN(intervalMinor);
        this.mIntervalMinor = intervalMinor * this.mMinor;
    }

    isMajor(value: number) {
        const isMajor = (value * this.mMajor) % this.mIntervalMajor === 0;
        return this.hasMajor && isMajor;
    }

    isMinor(value: number) {
        const isMinor = (value * this.mMinor) % this.mIntervalMinor === 0;
        return this.hasMinor && isMinor;
    }

    private getDecimalExp(x: number) {
        return Math.pow(10, `${x}`.split('.')[1]?.length || 0);
    }
}
