<ng-template #popupOutlet>
    <ca-popup-container
        *ngIf="showConfirmationPopup"
        [title]="textsPopup.title"
        (closeAction)="onClosePopup()"
    >
        <confirm-unsaved-popup
            [text]="TEXTS.POPUP_THREE_QUESTIONS.body"
            [continueText]="TEXTS.COMMON.doNotSave"
            [cancelText]="TEXTS.POPUP_THREE_QUESTIONS.cancel"
            (continueAction)="onCancelAndNavigation()"
            (cancelAction)="onClosePopup()"
        ></confirm-unsaved-popup>
    </ca-popup-container>
</ng-template>

<div class="settings_block scroll">
    <form (ngSubmit)="onSubmit()" [formGroup]="settingsForm" novalidate>
        <div class="settings_input_forms scroll">
            <div class="settings_header">
                <div class="settings_header-wrapper">
                    <header-page
                        [cbBack]="cancel"
                        [textObject]="{
                            btnBack: textsNotification.back,
                            titlePage:
                                subscription === null
                                    ? textsNotification.newSubscription
                                    : textsNotification.editSubscription
                        }"
                    ></header-page>
                </div>
            </div>

            <div class="settings__section">
                <div class="settings__input-wrapper settings__input-wrapper&#45;&#45;text">
                    <ca-input
                        type="text"
                        controlName="name"
                        [form]="settingsForm"
                        [valueForm]="getValueForm('name')"
                        [label]="textsNotification.titleSubscription"
                        [textError]="getError('name')"
                    ></ca-input>
                </div>
            </div>

            <div class="settings_subtitle">
                <div class="settings_subtitle__name">
                    {{ textsNotification.notificationsFromEquipment }}
                </div>
            </div>

            <div class="settings__section">
                <div class="settings__input-wrapper settings__input-wrapper--text">
                    <input-dropdown
                        class="input-dropdown"
                        [inputText]="selectedCheckboxText(getTags().length)"
                        [label]="textsNotification.selectPosts"
                        [(showDropdown)]="isShowDropdownForStations"
                        (clickOutside)="isShowDropdownForStations = false"
                        [textError]="
                            getTags().length === 0 ? textsNotification.errMsgPost : ''
                        "
                    >
                        <cs-selectbox
                            [listItems]="listStations"
                            (listItemsChange)="changePosts($event)"
                            [searchPlaceholderText]="TEXTS.NOTIFICATIONS.searchMoPlaceholderText"
                            [selectAllText]="TEXTS.NOTIFICATIONS.selectAll"
                        ></cs-selectbox>
                    </input-dropdown>
                </div>
                <div class="settings__input-wrapper settings__input-wrapper--text">
                    <tags-list
                        class="tags-list"
                        [tags]="getTags()"
                        [textNodesFns]="postsListLabels()"
                        [truncateTo]="3"
                        [editable]="true"
                        (removeTag)="removeFromList($event)"
                    ></tags-list>
                </div>
                <div
                    class="setting_input setting_wrapper setting_input-service mt-10"
                    formGroupName="serviceSettings"
                >
                    <div class="settings__input-wrapper settings__input-wrapper&#45;&#45;text">
                        <div class="setting_input__str setting_input__str-service">
                            <div class="setting_input__title setting_input__title-service">
                                {{ textsNotification.lackOfData }}
                            </div>
                            <input
                                class="input_name setting_input__input_text-service"
                                formControlName="nodata"
                                type="number"
                                min="1"
                                required
                                [class.alert-error]="nodata.invalid"
                            />
                            <div class="setting_input__title">
                                {{
                                    nodata.value || 0
                                        | i18nPlural : textsNotification.timeIntervalHours
                                }}
                            </div>
                        </div>
                        <div
                            class="alert_error alert_error-relative"
                            [class.alert_error-active]="nodata.invalid"
                        >
                            <ng-container *ngIf="nodata.invalid">
                                <div *ngIf="nodata.errors.required || nodata.errors.min">
                                    {{ textsNotification.errMsgValueMustBeGreaterThanZero }}
                                </div>
                                <div
                                    *ngIf="
                                        !(nodata.errors.required || nodata.errors.min) &&
                                        nodata.errors.pattern
                                    "
                                >
                                    {{ textsNotification.errMsgValueMustBeIntegerNumber }}
                                </div>
                            </ng-container>
                        </div>

                        <div class="setting_input__str setting_input__str-service">
                            <div class="setting_input__title setting_input__title-service">
                                {{ textsNotification.lackOfPower }}
                            </div>
                            <input
                                class="input_name setting_input__input_text-service"
                                formControlName="nopower"
                                type="number"
                                min="1"
                                required
                                [class.alert-error]="nopower.invalid"
                            />
                            <div class="setting_input__title">
                                {{
                                    nopower.value || 0
                                        | i18nPlural : textsNotification.timeIntervalHours
                                }}
                            </div>
                        </div>

                        <div
                            class="alert_error alert_error-relative"
                            [class.alert_error-active]="nopower.invalid"
                        >
                            <ng-container *ngIf="nopower.invalid">
                                <div *ngIf="nopower.errors.required || nopower.errors.min">
                                    {{ textsNotification.errMsgValueMustBeGreaterThanZero }}
                                </div>
                                <div
                                    *ngIf="
                                        !(nopower.errors.required || nopower.errors.min) &&
                                        nopower.errors.pattern
                                    "
                                >
                                    {{ textsNotification.errMsgValueMustBeIntegerNumber }}
                                </div>
                            </ng-container>
                        </div>

                        <div class="setting_input__str setting_input__str-service">
                            <div class="setting_input__title setting_input__title-service">
                                {{ textsNotification.verificationExpires }}
                            </div>
                            <input
                                class="input_name setting_input__input_text-service"
                                formControlName="verification"
                                type="number"
                                min="1"
                                required
                                [class.alert-error]="verification.invalid"
                            />
                            <div class="setting_input__title">
                                {{
                                    verification.value || 0
                                        | i18nPlural : textsNotification.timeIntervalDays
                                }}
                            </div>
                        </div>

                        <div
                            class="alert_error alert_error-relative"
                            [class.alert_error-active]="verification.invalid"
                        >
                            <ng-container *ngIf="verification.invalid">
                                <div
                                    *ngIf="verification.errors.required || verification.errors.min"
                                >
                                    {{ textsNotification.errMsgValueMustBeGreaterThanZero }}
                                </div>
                                <div
                                    *ngIf="
                                        !(
                                            verification.errors.required || verification.errors.min
                                        ) && verification.errors.pattern
                                    "
                                >
                                    {{ textsNotification.errMsgValueMustBeIntegerNumber }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="settings_subtitle">
                <div class="settings_subtitle__name">
                    {{ textsNotification.recipients }}
                </div>
            </div>
            <div class="settings__section">
                <div class="settings__input-wrapper settings__input-wrapper&#45;&#45;text">
                    <div class="settings__input-label settings__input-label&#45;&#45;text">
                        {{ textsNotification.emailListInputLabel }}
                    </div>
                    <input
                        class="input_name"
                        type="text"
                        minlength="1"
                        autocomplete="email"
                        formControlName="emailsList"
                        [class.alert-error]="emailsListProxy.invalid"
                    />
                    <div [formGroup]="emailsListProxy" style="display: none">
                        <input
                            type="email"
                            email
                            *ngFor="let item of emailsListData"
                            [formControlName]="item.name"
                            [value]="item.value"
                        />
                    </div>
                    <div class="alert_error" [class.alert_error-active]="emailsListProxy.invalid">
                        <ng-container *ngIf="emailsListProxy.invalid">
                            {{ textsNotification.errMsgIncorrectEmails }}
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="settings_subtitle">
                <div class="settings_subtitle__name">
                    {{ textsNotification.dailyReport }}
                </div>
                <div
                    class="settings_subtitle__icon"
                    caTooltip
                    [caTooltipTemplate]="emailTooltip"
                ></div>
                <ng-template #emailTooltip>
                    <ca-info-tooltip
                        [text]="textsNotification.tooltipDeviceDailyReport"
                    ></ca-info-tooltip>
                </ng-template>
            </div>
            <div class="settings__section">
                <div class="settings__input-wrapper settings__input-wrapper&#45;&#45;text">
                    <div class="settings__input-label settings__input-label&#45;&#45;text">
                        {{ textsNotification.emailListInputLabel }}
                    </div>
                    <input
                        class="input_name"
                        type="text"
                        minlength="1"
                        autocomplete="email"
                        formControlName="emailsListReports"
                        [class.alert-error]="emailsListReportsProxy.invalid"
                    />
                    <div [formGroup]="emailsListReportsProxy" style="display: none">
                        <input
                            type="email"
                            email
                            *ngFor="let item of emailsListReportsData"
                            [formControlName]="item.name"
                            [value]="item.value"
                        />
                    </div>
                    <div
                        class="alert_error"
                        [class.alert_error-active]="emailsListReportsProxy.invalid"
                    >
                        <div *ngIf="emailsListReportsProxy.invalid">
                            {{ textsNotification.errMsgIncorrectEmails }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="settings_footer">
            <div class="action_button__wrapper">
                <ca-button type="cancel" (clickAction)="cancel()">
                    {{ textsNotification.cancel }}
                </ca-button>
                <ca-button
                    type="primary"
                    (clickAction)="save()"
                    [disabled]="
                        settingsForm.disabled
                        ||
                        settingsForm.invalid
                        ||
                        !formDataHasChanged
                        ||
                        (getMoIds()?.length === 0)
                    "
                >
                    <ca-spinner *ngIf="saving; else showContent"></ca-spinner>
                    <ng-template #showContent>
                        {{ textsNotification.save }}
                    </ng-template>
                </ca-button>
            </div>
        </div>
    </form>
</div>
