import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgLocalization } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MAX_INLINE_MOS } from '@cityair/modules/notifications/notifications.settings';

import { NotificationSettings, Post, postsListLabels, TEXTS } from '@libs/common';
import { selectAllPosts } from '@libs/shared-store';
import { updateNotifiablePosts } from '@cityair/modules/notifications/store/actions';

@Component({
    selector: 'subscription-card-measurements',
    templateUrl: './subscription-card-measurements.component.html',
    styleUrls: ['subscription-card-measurements.component.less'],
})
export class SubscriptionCardMeasurements implements OnInit, OnDestroy {
    @Input() subscription: NotificationSettings;

    currentMos: string[];
    allMos: string[];
    allValues: number[];
    saving = false;
    maxInlineMos = MAX_INLINE_MOS;
    monitoringObjects: Post[];

    textsNotification = TEXTS.NOTIFICATIONS;

    stubTags = [this.postsListLabels().all()];
    subscriptions: Subscription[] = [];
    truncateToSender = 2;
    showMoreSender = false;
    constructor(private ngLocalization: NgLocalization, private store: Store) {
        const monitoringObjectsSub = store
            .select(selectAllPosts)
            .pipe(filter((v) => !!v.length))
            .subscribe((mos) => {
                this.monitoringObjects = mos;
                this.allMos = mos.map((v) => v.id);
            });

        this.subscriptions.push(monitoringObjectsSub);
    }

    ngOnInit() {
        this.currentMos = this.initialMosList()
            .map((mo) => this.getMoName(mo))
            .filter((v) => v);
    }

    ngOnDestroy() {
        this.store.dispatch(updateNotifiablePosts({ postIds: [] }));
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    initialMosList() {
        return this.subscription.pdk_check_settings.is_for_all_posts
            ? this.allMos
            : this.subscription.pdk_check_settings.selected_posts ?? [];
    }

    removeMo(id: string) {
        this.subscription.pdk_check_settings.selected_posts =
            this.subscription.pdk_check_settings.selected_posts.filter((v) => v !== id);
    }

    getValueName(value: string) {
        return TEXTS.NAMES[value] ?? value;
    }

    getMoName(id: string) {
        return this.monitoringObjects.find((v) => v.id === id)?.name;
    }

    getEmails() {
        return this.subscription.notify_settings?.notification_emails;
    }

    toggleEmailMore() {
        if (this.showMoreSender) {
            this.showMoreSender = false;
            this.truncateToSender = 2;
        } else {
            this.showMoreSender = true;
            this.truncateToSender = this.getEmails().length;
        }
    }
    editSubscription() {
        // this.stateService.openSettings(this.subscription);
    }

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    addSubscription() {
        this.saving = true;
    }
}
