<div
    class="btn-accept"
    [ngClass]="{ 'delete-button': isDeleteAction, 'btn-accept--disabled': disabled }"
    (click)="click()"
>
    <ca-spinner *ngIf="isLoading; else showContentSave" [color]="'#ffffff'"></ca-spinner>
    <ng-template #showContentSave>
        <ng-content></ng-content>
    </ng-template>
</div>
