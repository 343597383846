import { environment } from 'environments/environment';
import { MMT_WITH_PDK_SORTED, AVAILABLE_AQIS, detectMobile, IntervalEnum } from '@libs/common';

export const MIN_PASSWORD_LENGTH = 7;

export const CITYAIR_DEVICE_ID = 3;

export const NO_ACCESS_ID = 0;
export const ADMIN_ID = 1;
export const OPERATOR_ID = 2;
export const OBSERVER_ID = 3;
export const SERVICE_ID = 4;

export const RESIZE_TIMEOUT = 200;

export const MAIN_CHART_HEIGHT = 150;
export const MAIN_CHART_HEIGHT_M = 95;

export const DEFAULT_QUALITY_DATA_PERCENT = 50;

export function getColorIndex(zone: number[], value: number) {
    if (value >= zone[zone.length - 1]) {
        return zone.length;
    }

    return zone.findIndex((v) => value < v);
}

export const MMT_FOR_PINS = [...AVAILABLE_AQIS, ...MMT_WITH_PDK_SORTED];

export const getShiftMapCityCard = (): [number, number] => [
    -250,
    document.documentElement.clientHeight / 2 - 350,
];
export const getShiftMapByCorrelationPins = (): [number, number] => [-250, 0];
export const getShiftMapMobile = (): [number, number] => [
    0,
    421 /* таймлайн+шторка */ - document.documentElement.clientHeight / 2,
];

export type ModelProps = {
    measure: string;
    contours: boolean;
    overlay: boolean;
};

export const STND_INTERVAL = (
    detectMobile() ? IntervalEnum.hour : IntervalEnum.min20
) as IntervalEnum;

export function getClientAssetPath(asset: string) {
    return asset ? `${environment.deploy_url}client_assets/${asset}` : null;
}
