import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@libs/shared-ui';
import { LittleComponentsModule } from '@libs/shared-ui';
import { InputDropdownModule } from '@libs/shared-ui';
import { SelectboxModule } from '@libs/shared-ui';
import { SelectboxRadioModule } from '@libs/shared-ui';
import { InputDateRangeModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';
import { TagsListModule } from '@libs/shared-ui';
import { SwitchItemModule } from '@libs/shared-ui';
import { CheckboxModule } from '@libs/shared-ui';
import { ButtonModule } from '@libs/shared-ui';
import { ButtonsSaveCancelModule } from '@libs/shared-ui';
import { PopupConfirmComponent } from './components/popup-confirm/popup-confirm.component';
import { PopupContainerComponent } from './components/popup-container/popup-container.component';
import { DownloadPopupMoComponent } from './components/download-popup-mo/download-popup-mo.component';
import { ConfirmUnsavedPopupComponent } from './components/confirm-unsaved-popup/confirm-unsaved-popup.component';
import { PopupOutletComponent } from './components/popup-outlet/popup-outlet.component';


@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        LittleComponentsModule,
        InputDropdownModule,
        SelectboxModule,
        SelectboxRadioModule,
        InputDateRangeModule,
        LoadersModule,
        TagsListModule,
        SwitchItemModule,
        CheckboxModule,
        ButtonModule,
        ButtonsSaveCancelModule,
    ],
    exports: [
        PopupOutletComponent,
        PopupConfirmComponent,
        PopupContainerComponent,
        DownloadPopupMoComponent,
        ConfirmUnsavedPopupComponent,
    ],
    declarations: [
        PopupOutletComponent,
        PopupConfirmComponent,
        PopupContainerComponent,
        DownloadPopupMoComponent,
        ConfirmUnsavedPopupComponent,
    ],
})
export class PopupsModule {}
