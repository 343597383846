import { createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';

import * as coreActions from './core.actions';

export interface ITimelineState {
    chartEnabled: boolean;
    correctStartIndex: boolean;
    chartLoading: boolean;
    player: {
        playing: boolean;
        loading: boolean;
        loaded: boolean;
        progress: number;
        managed: boolean;
    };
}

export const initialState: ITimelineState = {
    chartEnabled: false,
    correctStartIndex: false,
    chartLoading: false,
    player: {
        playing: false,
        loading: false,
        loaded: false,
        progress: 0,
        managed: false,
    },
};

const innerCoreReducer: ActionReducer<ITimelineState, any> = createReducer(
    initialState,
    on(coreActions.onIsEnabledChart, (state: ITimelineState, data) => ({
        ...state,
        chartEnabled: data.payload,
    })),
    on(coreActions.playerStop, (state: ITimelineState) => ({
        ...state,
        player: {
            ...state.player,
            playing: false,
            loading: false,
            loaded: false,
            progress: 0,
        },
    })),
    on(coreActions.playerLoadingData, (state: ITimelineState) => ({
        ...state,
        player: {
            ...state.player,
            playing: true,
            loading: true,
            loaded: false,
            progress: 0,
        },
    })),
    on(coreActions.playerSetProgress, (state: ITimelineState, { progress }) => ({
        ...state,
        player: {
            ...state.player,
            progress,
        },
    })),
    on(coreActions.playerReady, (state: ITimelineState) => ({
        ...state,
        player: {
            ...state.player,
            loading: false,
            loaded: true,
        },
    })),
    on(coreActions.playerSetManaged, (state: ITimelineState, props) => ({
        ...state,
        player: {
            ...state.player,
            managed: props.payload,
        },
    })),
    on(coreActions.correctStartIndex, (state: ITimelineState, props) => ({
        ...state,
        correctStartIndex: props.payload,
    })),

    on(coreActions.setMainChartChartLoading, (state: ITimelineState, props) => ({
        ...state,
        chartLoading: props.payload,
    }))
);

export function coreReducers(state, action): any {
    return innerCoreReducer(state, action);
}
