import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoCorrelationPopupComponent } from './info-correlation-popup.component';
import { CrossButtonModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';

@NgModule({
    imports: [CommonModule, CrossButtonModule, TooltipsModule],
    exports: [InfoCorrelationPopupComponent],
    declarations: [InfoCorrelationPopupComponent],
})
export class InfoCorrelationPopupModule {}
