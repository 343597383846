import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'confirm-unsaved-popup',
    templateUrl: 'confirm-unsaved-popup.component.html',
    styleUrls: ['confirm-unsaved-popup.component.less'],
})
export class ConfirmUnsavedPopupComponent {
    @Input() text: string;
    @Input() continueText: string;
    @Input() cancelText: string;

    @Output() continueAction = new EventEmitter<void>();
    @Output() cancelAction = new EventEmitter<void>();

    close() {
        this.cancelAction.emit();
    }

    continue() {
        this.continueAction.emit();
    }
}
