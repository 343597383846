import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectboxComponent } from './selectbox.component';
import { SearchInputModule } from '../search-input/search-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { TextButtonModule } from '../text-button/text-button.module';
import { CheckboxModule } from '../checkbox/checkbox.module';

@NgModule({
    exports: [SelectboxComponent],
    declarations: [SelectboxComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SearchInputModule,
        CheckboxModule,
        PipesModule,
        TextButtonModule,
    ],
})
export class SelectboxModule {}
