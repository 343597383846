import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ControlPointForecast } from '../models';

@Injectable({ providedIn: 'root' })
export class ControlPointService extends EntityCollectionServiceBase<ControlPointForecast> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('ControlPoint', serviceElementsFactory);
    }
}
