<div
    *ngIf="iconUrl"
    class="group_list_item__icon"
    [ngStyle]="{ 'background-image': 'url(' + iconUrl + ')' }"
></div>
<div *ngIf="!iconUrl" class="group_list_item__icon group_list_item__custom_icon">
    {{ name | slice : 0 : 1 }}
</div>

<div class="group_list_item__right">
    <div class="group_list_item__name">{{ name }}</div>
    <div *ngIf="postsCount" class="group_list_item__posts">
        {{ postsCount }} {{ postsCount | i18nPlural : TEXTS.PROFILE.post }}
    </div>
</div>

<div *ngIf="selected" class="group_list_item__active"></div>
