import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from './search-input.component';
import { CrossButtonModule } from '@libs/shared-ui';
import { SearchInputBasicModule } from '@libs/shared-ui';

@NgModule({
    exports: [SearchInputComponent],
    declarations: [SearchInputComponent],
    imports: [CommonModule, CrossButtonModule, SearchInputBasicModule],
})
export class SearchInputModule {}
