import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ForecastConfig } from '../models';

@Injectable({ providedIn: 'root' })
export class ForecastsService extends EntityCollectionServiceBase<ForecastConfig> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Forecasts', serviceElementsFactory);
    }
}
