<div class="logout-wrapper" *ngIf="userData" (clickOutside)="showLogout = false">
    <div class="user-profile-icon" (click)="showLogout = !showLogout">
        <div class="icon-text">
            {{ userData.login | shortUserName }}
        </div>
    </div>
    <div class="user-data-wrapper" *ngIf="showLogout">
        <div class="user-profile-data">
            <div class="login">{{ userData.email }}</div>
            <div class="role">{{ currentLang === 'ru' ? userData.roleNameRu : userData.roleName }}</div>
            <div class="logout-button" (click)="logOut()">
                {{ exitText }}
            </div>
        </div>
    </div>
</div>
