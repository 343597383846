import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { AqiIndicatorModule, LittleComponentsModule } from '@libs/shared-ui';
import { DirectivesModule } from '@libs/shared-ui';
import { KebabMenuModule } from '@libs/shared-ui';
import { OverlayModule } from '@libs/shared-ui';

import { OnboardingModule } from './onboarding/onboarding.module';
import { PopupsModule } from '@libs/shared-ui';
import { MobileHeaderModule } from './mobile-header/mobile-header.module';
import { StaticTagsModule } from '@libs/shared-ui';
import { GroupListItemModule } from './group-list-item/group-list-item.module';
import { GroupListItemMobileModule } from './group-list-item-mobile/group-list-item-m.module';
import { InstantAqiLegendModule } from '@libs/shared-ui';
import { InstantAqiFaqModule } from './instant-aqi-faq/instant-aqi-faq.module';
import { SubstanceTagModule } from '@libs/shared-ui';

import { AqiLegendComponent } from '@libs/shared-ui';

import { CsTopRightElements } from './top-right-elements/top-right-elements.component';
import { DownloadPopupsWrapperComponent } from './download-popups-wrapper/download-popups-wrapper.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { TopRightElementsMobile } from './top-right-elements-m/top-right-elements-m.component';
import { CapiAqiLegendComponent } from '@libs/shared-ui';
import { InfoMessageComponent } from './info-message/info-message.component';
import { ButtonModule } from '@libs/shared-ui';
import { ButtonsSaveCancelModule } from '@libs/shared-ui';
import { BackButtonModule } from '@libs/shared-ui';
import { CrossButtonModule } from '@libs/shared-ui';

@NgModule({
    imports: [
        DirectivesModule,
        LittleComponentsModule,
        CommonModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        CalendarModule,
        PopupsModule,
        MobileHeaderModule,
        KebabMenuModule,
        StaticTagsModule,
        GroupListItemModule,
        GroupListItemMobileModule,
        OverlayModule,
        OnboardingModule,
        BackButtonModule,
        InstantAqiLegendModule,
        InstantAqiFaqModule,
        AqiIndicatorModule,
        SubstanceTagModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        CrossButtonModule,
        AqiIndicatorModule,
        AqiLegendComponent,
        CapiAqiLegendComponent,
    ],
    exports: [
        CsTopRightElements,
        TopRightElementsMobile,
        DownloadPopupsWrapperComponent,
        MobileHeaderComponent,
        OnboardingModule,
        InstantAqiLegendModule,
        InstantAqiFaqModule,
        SubstanceTagModule,
        InfoMessageComponent,
    ],
    declarations: [
        CsTopRightElements,
        TopRightElementsMobile,
        DownloadPopupsWrapperComponent,
        InfoMessageComponent,
    ],
})
export class CityscreenComponentsModule {}
