import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkPinComponent } from '@cityair/components/network-pin/network-pin.component';

@NgModule({
    exports: [NetworkPinComponent],
    declarations: [NetworkPinComponent],
    imports: [CommonModule],
})
export class NetworkPinModule {}
