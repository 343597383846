import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TEXTS } from '@libs/common';
import { ANIMATION_OPACITY } from '@libs/common';

@Component({
    selector: 'shared-ui-aqi-legend',
    standalone: true,
    imports: [CommonModule],
    templateUrl: 'aqi-legend.component.html',
    styleUrls: ['aqi-legend.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class AqiLegendComponent implements OnChanges {
    @Input() aqi: number;

    TEXTS = TEXTS;

    currentAqiDangerLevel: number;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.aqi) {
            this.currentAqiDangerLevel = this.aqiToDangerLevel(this.aqi);
        }
    }

    aqiToDangerLevel(i: number) {
        if (i >= 1 && i <= 3) {
            return 0;
        } else if (i >= 4 && i <= 7) {
            return 1;
        } else if (i >= 8 && i <= 10) {
            return 2;
        }
    }
}
