import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoginUpdateService {
    private email = '';

    private verificationCode = '';

    private isInviteState = false;

    setEmail(email: string) {
        this.email = email;
    }

    setVerificationCode(code: string) {
        this.verificationCode = code;
    }

    getEmail() {
        return this.email;
    }

    getVerificationCode() {
        return this.verificationCode;
    }

    setParams(email: string, code: string) {
        this.setEmail(email);
        this.setVerificationCode(code);
        this.isInviteState = false;
    }

    setInviteParams(email: string, code: string) {
        this.setEmail(email);
        this.setVerificationCode(code);
        this.isInviteState = true;
    }

    getParams() {
        return {
            email: this.email,
            verificationCode: this.verificationCode,
        };
    }

    clear() {
        this.setParams('', '');
        this.isInviteState = false;
    }

    isInvite() {
        return this.isInviteState;
    }
}
