import { AqiType } from '../enums/aqi.type';
import { MeasureScheme } from '../enums/measure-scheme';
import { MeasuresZones } from '../types/measures-zones';
import { pdkmr } from './pdk.consts';
import {
    AQI,
    CO,
    CO2,
    H2S,
    NH3,
    NO,
    NO2,
    O3,
    PM10,
    PM25,
    SO2,
    SUM,
    CH2O,
    NOISELEVEL,
} from './substance.consts';

const AQI_ZONES = [2, 3, 4, 5, 6, 7, 8, 9, 10];
const DEFAULT_COLORS = [
    '#8CB917',
    '#A2C617',
    '#BEC617',
    '#FFCC33',
    '#FFA33B',
    '#FF7344',
    '#FF494B',
    '#D63B50',
    '#AD2D55',
    '#821E5A',
];

const aqiZones: MeasuresZones = {
    [AQI]: {
        zone: AQI_ZONES,
        color: DEFAULT_COLORS,
    },
    [AqiType.indoor]: {
        zone: AQI_ZONES,
        color: DEFAULT_COLORS,
    },
    [AqiType.instant]: {
        zone: AQI_ZONES,
        color: DEFAULT_COLORS,
    },
    [AqiType.epa]: {
        zone: AQI_ZONES,
        color: DEFAULT_COLORS,
    },
    [AqiType.cityair]: {
        zone: AQI_ZONES,
        color: DEFAULT_COLORS,
    },
    [AqiType.CAPI]: {
        zone: [5, 7, 14],
        color: ['#9CBD1C', '#FDA347', '#EE4F46', '#A23242'],
    },
    [AqiType.R]: {
        zone: [25, 50, 75, 100],
        color: ['#8CB917', '#BEC617', '#FFCC33', '#FF494B', '#821E5A'],
    },
    [AqiType.EU]: {
        zone: [25, 50, 75, 100],
        color: ['#8CB917', '#BEC617', '#FFCC33', '#FF494B', '#821E5A'],
    },
    [AqiType.aqiIn]: {
        zone: [50, 100, 200, 300, 400],
        color: ['#8CB917', '#BEC617', '#FFCC33', '#FF7344', '#D63B50', '#821E5A'],
    },
    [AqiType.aqiNN]: {
        zone: [2, 3, 4, 5, 6, 7, 8, 9, 10],
        color: [
            '#8CB917',
            '#A2C617',
            '#BEC617',
            '#ffcf48',
            '#FFCC33',
            '#ffb300',
            '#ffa000',
            '#FF494B',
            '#D63B50',
            '#AD2D55',
        ],
    },
    [AqiType.aqiPm]: {
        zone: [50, 100, 200, 300, 400],
        color: ['#8CB917', '#BEC617', '#FFCC33', '#FF7344', '#D63B50', '#821E5A'],
    },
};

export const zone_default: MeasuresZones = {
    ...aqiZones,
    [PM25]: {
        zone: [11, 23, 35, 66, 97, 128, 160, 285, 410],
        color: DEFAULT_COLORS,
    },
    [PM10]: {
        zone: [20, 40, 60, 120, 180, 240, 300, 540, 780],
        color: DEFAULT_COLORS,
    },
    [CO]: {
        zone: [1000, 2000, 3000, 3500, 4000, 4500, 5000, 7000, 9000],
        color: DEFAULT_COLORS,
    },
    [NO2]: {
        zone: [13, 26, 40, 80, 120, 160, 200, 360, 520],
        color: DEFAULT_COLORS,
    },
    [SO2]: {
        zone: [16, 33, 50, 162, 275, 387, 500, 950, 1400],
        color: DEFAULT_COLORS,
    },
    [O3]: {
        zone: [10, 20, 30, 62, 95, 127, 160, 290, 420],
        color: DEFAULT_COLORS,
    },
    [NO]: {
        zone: [20, 40, 60, 145, 230, 315, 400, 740, 1080],
        color: DEFAULT_COLORS,
    },
    [CO2]: {
        zone: [550, 700, 900, 1100, 1300, 1500, 2000, 2500, 3000],
        color: DEFAULT_COLORS,
    },
    [H2S]: {
        zone: [1, 2, 3, 4, 5, 6, 8, 12, 20],
        color: DEFAULT_COLORS,
    },
    [NH3]: {
        zone: [40, 69, 100, 125, 150, 175, 200, 400, 650],
        color: DEFAULT_COLORS,
    },
    [CH2O]: {
        zone: [3, 6, 10, 20, 30, 40, 50, 90, 130],
        color: DEFAULT_COLORS,
    },
    [SUM]: {
        zone: [39, 68, 99, 124, 149, 175, 199, 399, 649],
        color: DEFAULT_COLORS,
    },
};

const _aqiInclude = (key) => [AQI, ...Object.values(AqiType)].includes(key);

export const calcZoneMg = (zone: MeasuresZones) => {
    const newZone: MeasuresZones = {};

    Object.keys(zone).forEach((key) => {
        newZone[key] = {
            zone: zone[key].zone.map((value) => value / (_aqiInclude(key) ? 1 : 1000)),
            color: zone[key].color,
        };
    });

    return newZone;
};

export const calcZoneMpc = (zone, pdkmr) => {
    const newZone: MeasuresZones = {};

    Object.keys(zone).forEach((key) => {
        newZone[key] = {
            zone: zone[key].zone.map((value) => value / (_aqiInclude(key) ? 1 : pdkmr[key])),
            color: zone[key].color,
        };
    });

    return newZone;
};

export const calcAllZones = (
    defaultZone: MeasuresZones,
    pdkmr
): { [key in MeasureScheme]: MeasuresZones } => ({
    [MeasureScheme.default]: defaultZone,
    [MeasureScheme.usa_default]: defaultZone,
    [MeasureScheme.c_mmhg_mg]: calcZoneMg(defaultZone),
    [MeasureScheme.mpc]: calcZoneMpc(defaultZone, pdkmr),
});

// use in NGRX settings
export const ZONES = calcAllZones(zone_default, pdkmr);
