import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarMenuComponent } from './sidebar-menu.component';
import { SidebarModule } from '@libs/shared-ui';
import { GroupChangeSelectorComponent } from './group-change-selector/group-change-selector.component';
import { GroupListItemComponent } from './group-change-selector/group-list-item/group-list-item.component';
import { DirectivesModule } from '@libs/shared-ui';
import { FeedbackModule } from '@libs/shared-ui';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PipesModule } from '@libs/shared-ui';

@NgModule({
    exports: [SidebarMenuComponent],
    declarations: [
        SidebarMenuComponent,
        GroupChangeSelectorComponent,
        GroupListItemComponent,
        UserProfileComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        SidebarModule,
        DirectivesModule,
        FeedbackModule,
        PipesModule,
    ],
})
export class SidebarMenuModule {}
