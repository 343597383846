<div class="attribution">
    <div class="compact-show"  *ngIf="!showYandexLogo">
        <div class="inner" [ngClass]="{'show': !collapse}">
            <div *ngFor="let attrib of attribs;">
                <ng-container *ngIf="attrib.link else textTemp">
                    <a [href]="attrib.link" target="_blank" title="{{attrib.text}}">© {{attrib.text}}&nbsp;</a>
                </ng-container>
                <ng-template #textTemp>
                    © {{attrib.text}}&nbsp;
                </ng-template>
            </div>
        </div>
        <button class="attribution-button" type="button" (click)="collapse = !collapse">
            <span class="icon"></span>
        </button>
    </div>
    <a *ngIf="showYandexLogo" [className]="'yandex-logo ' + lang " target="_blank" [href]="'https://yandex.ru/maps'"></a>
</div>


