import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloorPinComponent } from './floor-pin.component';
import { PipesModule } from '@libs/shared-ui';

@NgModule({
    exports: [FloorPinComponent],
    declarations: [FloorPinComponent],
    imports: [CommonModule, PipesModule],
})
export class FloorPinModule {}
