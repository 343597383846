import { EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
    Station: {},
    ControlPoint: {
        entityDispatcherOptions: {
            optimisticAdd: false,
            optimisticUpdate: false,
            optimisticDelete: true,
            optimisticUpsert: false,
        },
    },
    Forecasts: {},
};

const pluralNames = {
    Station: 'group',
    ControlPoint: 'control-points',
    Forecasts: 'forecasts',
};
export const entityForecastConfig = {
    entityMetadata,
    pluralNames,
};
