import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

import { CommonLoaderService } from '../../../core/services/common-loader/common-loader.service';
import { HarvesterApiService } from '../../services/harvester-api/harvester-api.service';
import { LoginNavigationService } from '../../services/login-navigation/login-navigation.service';
import { LoginUpdateService } from '../../services/login-update/login-update.service';
import { LoginPage } from '../../login.settings';

@Component({
    selector: 'ca-login-page-container',
    templateUrl: 'login-page-container.component.html',
    styleUrls: ['login-page-container.component.less'],
})
export class LoginPageContainerComponent implements OnInit {
    @Input() inviteProps: {
        email: string;
        code: string;
    };

    @Output() afterLogin = new EventEmitter<void>();

    constructor(
        private commonLoaderService: CommonLoaderService,
        private harvester: HarvesterApiService,
        private navigation: LoginNavigationService,
        private router: Router,
        private loginUpdateService: LoginUpdateService
    ) {}

    ngOnInit() {
        if (this.inviteProps) {
            const { email, code } = this.inviteProps;

            if (email && code) {
                this.commonLoaderService.showHtmlLoader();

                this.harvester
                    .verifyResetCode(email, code)
                    .pipe(
                        tap(
                            () => {
                                this.loginUpdateService.setInviteParams(email, code);
                                this.router.navigate([LoginPage.Login, LoginPage.NewPassword]);
                            },
                            () => {
                                this.navigation.openInviteLinkExpiredPopup();
                            }
                        ),
                        finalize(() => {
                            this.commonLoaderService.hideHtmlLoader();
                            this.clearInviteUrl();
                        })
                    )
                    .subscribe();
            } else {
                this.commonLoaderService.hideHtmlLoader();
            }
        } else {
            this.commonLoaderService.hideHtmlLoader();
        }
    }

    clearInviteUrl() {
        history.replaceState(null, null, location.origin + location.pathname);
    }
}
