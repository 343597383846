import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'button-add',
    templateUrl: './button-add.component.html',
    styleUrls: ['./button-add.component.less'],
})
export class ButtonAddComponent {
    @Input() text: string;
    @Output() action = new EventEmitter<void>();

    add() {
        this.action.emit();
    }
}
