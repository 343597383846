import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@libs/common';
import { isRU, TEXTS } from '@libs/common';

@Pipe({
    name: 'featureCurrentType',
})
export class FeatureCurrentTypePipe implements PipeTransform {
    transform(obj: Feature) {
        if (!obj) {
            return '';
        }
        if (
            obj.properties.source_type &&
            TEXTS.STATION_BLOCK.sourceTypeName.hasOwnProperty(obj.properties.source_type)
        ) {
            return TEXTS.STATION_BLOCK.sourceTypeName[obj.properties.source_type];
        } else {
            return TEXTS.STATION_BLOCK.type[obj.properties.obj] || '';
        }
    }
}
