<div class="post-create scroll">
    <div class="post-create__form scroll">
        <header-of-panel
            class="post-create__header-of-panel"
            [path]="navigationLink"
            (outputPath)="backToPrevPage($event)"
            [title]="TEXTS.POSTS_AND_DEVICES.newMO"
        ></header-of-panel>

        <form (ngSubmit)="onSubmit()" [formGroup]="PostCreate" novalidate>
            <div class="post-create__wrapper-content">
                <ca-input
                    class="post-create__input-wrapper"
                    type="text"
                    controlName="name"
                    [form]="PostCreate"
                    [valueForm]="getValueForm('name')"
                    [label]="TEXTS.POSTS_AND_DEVICES.name"
                    [textError]="getError('name')"
                ></ca-input>
            </div>
            <section-line [text]="TEXTS.POSTS_AND_DEVICES.dataSources"></section-line>
            <div class="post-create__wrapper-content">
                <input-dropdown
                    [inputText]="selectedStation !== null ? selectedStation?.serial_number : ''"
                    [label]="TEXTS.POSTS_AND_DEVICES.device+'<sup>*</sup>'"
                    [(showDropdown)]="isShowDropdownForStations"
                    (click)="!isShowDropdownForStations ? PostCreate.get('device_id').markAsTouched(): ''"
                    (clickOutside)="clickOutsideDevice()"
                    [textError]="getError('device_id') ?? ''"
                >
                    <selectbox-radio
                        [listItems]="devicesListForCheckbox"
                        (listItemsChange)="
                            getSelectedStation($event); isShowDropdownForStations = false
                        "
                    ></selectbox-radio>
                </input-dropdown>
                <gray-background
                    [@maxHeightAnimation]="{
                        value: selectedStation !== null,
                        params: { numberMaxHeight: 300 }
                    }"
                    class="post-create__gray-background"
                >
                    <div class="post-create__information-column">
                        <information-column
                            [title]="TEXTS.POSTS_AND_DEVICES.type"
                            [description]="store.select(selectDeviceSourceNameByType(selectedStation?.source_type))| async"
                        ></information-column>
                        <information-column
                            [title]="TEXTS.EDIT_STATION.versionH"
                            [description]="selectedStation?.ext_info?.hardware_version"
                        ></information-column>
                        <information-column
                            [title]="TEXTS.EDIT_STATION.versionS"
                            [description]="selectedStation?.ext_info?.software_version"
                        ></information-column>
                    </div>
                    <div class="post-create__information-column">
                        <information-column
                            [title]="TEXTS.EDIT_STATION.startTimeStation"
                            [description]="selectedStation?.device_first_date | shortData: '---'"
                        ></information-column>
                        <information-column
                            [title]="TEXTS.EDIT_STATION.lastTimeStation"
                            [description]="selectedStation?.device_last_date | timeDate : '---'"
                        ></information-column>
                        <information-column
                            [title]="TEXTS.LIST_DEVICES.interval"
                            [description]="
                                selectedStation?.ext_info?.data_delivery_period_sec
                                    ? selectedStation?.ext_info?.data_delivery_period_sec / 60 + TEXTS.LIST_DEVICES.min
                                    : '--'
                            "
                        ></information-column>
                    </div>
                </gray-background>
            </div>
            <section-line [text]="TEXTS.POSTS_AND_DEVICES.locationOnTheMap"></section-line>
            <div class="post-create__wrapper-content">
                <div class="post-create__wrapper-coordinate">
                    <input-for-coordinate
                        type="text"
                        controlName="latitude"
                        [form]="PostCreate"
                        [valueForm]="getValueForm('latitude')"
                        [label]="TEXTS.PLUMES.latitude"
                        [textError]="getError('latitude')"
                        [positioning]="'left'"
                    ></input-for-coordinate>
                    <input-for-coordinate
                        type="text"
                        controlName="longitude"
                        [form]="PostCreate"
                        [valueForm]="getValueForm('longitude')"
                        [label]="TEXTS.PLUMES.longitude"
                        [textError]="getError('longitude')"
                        [positioning]="'right'"
                    ></input-for-coordinate>
                </div>
                <input-dropdown
                    class="post-create__input-dropdown"
                    [inputText]="selectedCity.label"
                    [label]="TEXTS.POSTS_AND_DEVICES.location+'<sup>*</sup>'"
                    [(showDropdown)]="isShowDropdownForLocation"
                    (click)="!isShowDropdownForLocation ? PostCreate.get('locality_id').markAsTouched(): ''"
                    (clickOutside)="clickOutsideLocality()"
                    [textError]="getError('locality_id') ?? ''"
                >
                    <selectbox-radio
                        [listItems]="getCities"
                        (listItemsChange)="
                            getSelectedCity($event); isShowDropdownForLocation = false
                        "
                    ></selectbox-radio>
                </input-dropdown>
            </div>
        </form>
    </div>
    <div class="post-create__btn">
        <btn-cancel (click)="openPage(navigationLink[0].path)">
            {{ TEXTS.COMMON.cancel }}
        </btn-cancel>
        <btn-accept
            [disabled]="PostCreate.pristine || PostCreate.invalid"
            [isLoading]="store.select(isLoadingForm) | async"
            (cbClick)="onSubmit()"
        >
            {{ TEXTS.STATION_BLOCK.add }}
        </btn-accept>
    </div>
</div>
