import { Component, AfterContentChecked, TemplateRef, HostListener } from '@angular/core';

import { TooltipsService, ITooltipComponent } from '../../tooltips.service';

@Component({
    selector: 'ca-tooltip-outlet',
    template: ` <ng-container *ngTemplateOutlet="tooltipTemplateRef"></ng-container> `,
})
export class TooltipOutletComponent implements AfterContentChecked {
    tooltipTemplateRef: TemplateRef<ITooltipComponent>;

    constructor(private tooltipsService: TooltipsService) {}

    ngAfterContentChecked() {
        this.tooltipTemplateRef = this.tooltipsService.template;
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.tooltipsService.hoverOverTooltip();
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.tooltipsService.leaveTooltip();
    }
}
