import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UserInfo } from '@libs/common';
import { LANGUAGE, TEXTS } from '@libs/common';

@Component({
    selector: 'shared-ui-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
    @Input() userData: UserInfo;
    @Output() exit = new EventEmitter<string>();

    public exitText = TEXTS.LOGIN_WINDOWS.logout;
    public showLogout = false;
    public currentLang = LANGUAGE;
    public logOut() {
        this.exit.emit();
    }
}
