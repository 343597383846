import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { setComparisonMode } from '@cityair/modules/core/store/actions';
import { clearCompareList } from '@cityair/modules/core/store/compared-list/compared-list.actions';
import { selectComparedItems } from '@cityair/modules/core/store/compared-list/compared-list.selectors';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ResetPanelStateGuard implements CanActivate {
    constructor(private store: Store) {}

    public canActivate() {
        return this.store.select(selectComparedItems).pipe(
            map((comparedList) => {
                if (comparedList.length > 1) this.store.dispatch(clearCompareList());

                this.store.dispatch(setComparisonMode({ payload: false }));

                return true;
            })
        );
    }
}
