import { Pipe, PipeTransform } from '@angular/core';
import { RunImpact, SourceImpact } from '@libs/common';
import { ImpactEmissionValue } from '@cityair/modules/impact/models';

@Pipe({ name: 'impactSourceEmission' })
export class ImpactSourceEmissionPipe implements PipeTransform {
    transform(source: SourceImpact, mmt: string, isTotal: boolean): number {
        let emission;
        if (mmt) {
            emission = source.emissions
                .filter((data) => data.species === mmt)
                .reduce(function (acc, emission) {
                    return acc + emission.modeling_mass;
                }, 0);
        } else {
            emission = source.emissions.reduce(function (acc, emission) {
                return acc + emission.modeling_mass;
            }, 0);
        }
        return isTotal ? (emission / 1000) * 3600 * 24 * 365 : emission;
    }
}
@Pipe({ name: 'impactSourceEmissionTotal' })
export class ImpactSourceEmissionTotalPipe implements PipeTransform {
    transform(sources: SourceImpact[], run: RunImpact): ImpactEmissionValue {
        const emissionTotal: ImpactEmissionValue = {};
        const mmts = run?.species_list ?? [];
        mmts?.forEach((mmt) => {
            let total = 0;
            sources?.forEach((source) => {
                const emission = source.emissions
                    .filter((data) => data.species === mmt)
                    .reduce(function (acc, emission) {
                        return acc + emission.modeling_mass;
                    }, 0);
                total += emission;
            });
            emissionTotal[mmt] = (total / 1000) * 3600 * 24 * 365;
        });
        return emissionTotal;
    }
}
@Pipe({ name: 'impactTotalEmission' })
export class ImpactTotalEmissionPipe implements PipeTransform {
    transform(total: ImpactEmissionValue): number {
        const arr = Object.values(total);
        return arr.reduce(function (acc, emission) {
            return acc + Number(emission.toFixed(2));
        }, 0);
    }
}
@Pipe({ name: 'impactEmissionPercentByTotal' })
export class ImpactEmissionPercentByTotalPipe implements PipeTransform {
    transform(total: ImpactEmissionValue, mmt: string): number {
        const arr = Object.values(total);
        const totalValue = arr.reduce(function (acc, emission) {
            return acc + Number(emission.toFixed(2));
        }, 0);

        return (total[mmt] * 100) / totalValue;
    }
}
