import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TEXTS } from '@libs/common';
import { CrossButtonModule } from '@libs/shared-ui';
import { DirectivesModule } from '@libs/shared-ui';
import { MapStyleType } from '@libs/common';

@Component({
    selector: 'map-style-selector',
    standalone: true,
    imports: [CommonModule, CrossButtonModule, DirectivesModule],
    templateUrl: './map-style-selector.component.html',
    styleUrls: ['./map-style-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapStyleSelectorComponent {
    @Input() title? = TEXTS.MAP_STYLE_SELECTOR.title;
    @Input() types: MapStyleType[];
    @Input() currentType: MapStyleType;
    @Input() loading = false;

    @Output() setType = new EventEmitter<MapStyleType>();
    @ViewChild('button', { static: true }) button: ElementRef<HTMLElement>;
    public showPopup = false;
    public text = TEXTS.MAP_STYLE_SELECTOR;
    position = 'top';
    height = 330;
    maxHeight = 330;

    constructor(private _changeDetectorRef: ChangeDetectorRef) {}

    toggle() {
        if (this.loading) {
            return;
        }
        this.showPopup = !this.showPopup;
        if (this.showPopup) {
            this.updatePosition();
        }
    }

    close() {
        this.showPopup = false;
    }

    clickOutside() {
        setTimeout(() => this.close(), 10);
    }

    selectType(type: MapStyleType) {
        if (type === this.currentType) {
            return;
        }
        this.setType.emit(type);
        this.close();
    }

    private updatePosition() {
        const needHeight = this.types.length * 64;
        const position = this.button?.nativeElement.getBoundingClientRect();
        if (position.top < this.maxHeight && position.top + this.maxHeight < window.innerHeight) {
            this.position = 'bottom';
        } else {
            this.position = 'top';
            if (position.top - 180 < needHeight) {
                this.height = position.top - 190 > 61 ? position.top - 190 : 61;
            } else {
                this.height = needHeight;
            }
        }
        this._changeDetectorRef.detectChanges();
    }
}
