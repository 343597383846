import { Injectable, NgZone } from '@angular/core';

import {
    detectMobile,
    GLOBAL_ZOOM_LEVEL,
    GroupExtConfigName,
    GroupMapSettings,
    isFalseNumber,
    Locality,
    MAP_ACTION_DURATION,
    STND_CITY_ZOOM,
    STND_GLOBAL_MIN_ZOOM,
} from '@libs/common';
import { Store } from '@ngrx/store';
import { GroupFeaturesService } from '@libs/shared-store';
import { selectMapZoom, setToNewPosition } from '@libs/shared-store';
import { FeatureIdentifier, LngLat, LngLatLike, Map, PointLike } from 'mapbox-gl';

@Injectable({
    providedIn: 'root',
})
export class MapboxActionsService {
    preventZooming = false;
    currentZoom: number = STND_CITY_ZOOM;
    isMobile = detectMobile();
    private _map: Map;
    constructor(
        private store: Store,
        private zone: NgZone,
        private groupFeaturesService: GroupFeaturesService
    ) {
        this.store.select(selectMapZoom).subscribe((v) => (this.currentZoom = v));
    }

    setMapObject(mapObj: Map): void {
        this._map = mapObj;
    }

    setFeature(
        feature: FeatureIdentifier | mapboxgl.MapboxGeoJSONFeature,
        state: { [key: string]: any }
    ) {
        this._map.setFeatureState(feature, state);
    }

    async centringMap(city: Locality, isCityMode: boolean) {
        this.preventZooming = true;
        setTimeout(() => {
            this.preventZooming = false;
        }, MAP_ACTION_DURATION);

        const mapSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.mapSettings
        ) as GroupMapSettings;
        const minZoom = (mapSettings?.zoom ?? STND_GLOBAL_MIN_ZOOM - 1) + 1; // probably need to exclude global zoom

        const zoom = isCityMode ? STND_CITY_ZOOM : minZoom;

        const newZoom = zoom - 1;

        this.currentZoom = newZoom;
        const lat = city?.geometry.coordinates[1];
        const lng = city?.geometry.coordinates[0];

        if (!isFalseNumber(lat) && !isFalseNumber(lng)) {
            if (this._map) {
                this.centerTo({ lat, lng }, newZoom);
            } else {
                this.store.dispatch(
                    setToNewPosition({
                        payload: {
                            center: new LngLat(lng, lat),
                            zoom: newZoom,
                        },
                    })
                );
            }
        } else {
            if (this._map) {
                this.zoomTo(newZoom);
            } else {
                this.store.dispatch(
                    setToNewPosition({
                        payload: {
                            center: null,
                            zoom: newZoom,
                        },
                    })
                );
            }
        }
    }

    moveMap(center: LngLat, zoom: number, offset?: [number, number]) {
        if (this._map) {
            this.moveTo(center, zoom, offset);
        } else {
            this.store.dispatch(
                setToNewPosition({
                    payload: {
                        center,
                        zoom: zoom,
                    },
                })
            );
        }
    }

    centringOnMarker = (
        lat: number,
        lng: number,
        zooming: boolean,
        isCityMode: boolean,
        shiftMap?: [number, number]
    ) => {
        const zoom = isCityMode ? STND_CITY_ZOOM : GLOBAL_ZOOM_LEVEL - 1;
        let newZoom: number;

        if (zooming && this.currentZoom < zoom) {
            newZoom = zoom - 1;
        }

        this.gotoLocation(lat, lng, zooming, newZoom, shiftMap);
    };

    centringOnLine = (
        lat: number,
        lng: number,
        zooming: boolean,
        zoom: number,
        shiftMap?: [number, number]
    ) => {
        if (zooming && this.currentZoom < zoom) {
            this.preventZooming = true;
            setTimeout(() => (this.preventZooming = false), MAP_ACTION_DURATION);
        }
        if (this._map) {
            this.moveToLine({ lat, lng }, zoom, shiftMap?.map((v) => -v) as [number, number]);
        } else {
            const offsetY = shiftMap && shiftMap[1] <= 0 ? 0 : 346;
            this.store.dispatch(
                setToNewPosition({
                    payload: {
                        center: new LngLat(lng, lat),
                        zoom,
                        offset: this.isMobile ? [0, 0, 246, 0] : [0, 0, offsetY, 546],
                    },
                })
            );
        }
    };

    private gotoLocation(
        lat: number,
        lng: number,
        zooming: boolean,
        zoomTo?: number,
        panTo?: [number, number]
    ) {
        if (zooming && this.currentZoom < zoomTo) {
            this.preventZooming = true;
            setTimeout(() => (this.preventZooming = false), MAP_ACTION_DURATION);
        }
        const center = new LngLat(lng, lat);
        const offset = panTo?.map((v) => -v) as [number, number];
        if (this._map) {
            this.moveTo({ lat, lng }, zoomTo, offset);
        } else {
            this.store.dispatch(
                setToNewPosition({
                    payload: {
                        center,
                        zoom: zoomTo,
                        offset: this.isMobile ? [0, 0, 246, 0] : [0, 0, 346, 546],
                    },
                })
            );
        }
    }

    public getCenter = () => this._map?.getCenter().wrap();

    private centerTo(center: LngLatLike, zoom: number) {
        if (!this._map) {
            return;
        }
        this.zone.runOutsideAngular(() => {
            this._map.jumpTo({
                center,
                zoom,
            });
        });
    }

    private zoomTo(zoom: number) {
        if (!this._map) {
            return;
        }
        this.zone.runOutsideAngular(() => {
            this._map.zoomTo(zoom);
        });
    }

    private moveToLine(center: LngLatLike, zoom?: number, offset?: PointLike) {
        if (!this._map) {
            return;
        }
        this.zone.runOutsideAngular(() => {
            if (isNaN(zoom)) {
                return this.panTo(center, offset);
            }

            const options: any = {
                center,
                zoom,
                duration: 500,
                easing: (t: number) => t,
            };

            if (offset) {
                options.offset = offset;
            }

            this._map.easeTo(options);
        });
    }

    private moveTo(center: LngLatLike, zoom?: number, offset?: PointLike) {
        if (!this._map) {
            return;
        }
        this.zone.runOutsideAngular(() => {
            if (isNaN(zoom)) {
                return this.panTo(center, offset);
            }

            const options: any = {
                center,
                zoom,
                duration: 1000,
                easing: (t: number) => t,
            };

            if (offset) {
                options.offset = offset;
            }

            this._map.easeTo(options);
        });
    }

    private panTo(coords: LngLatLike, offset?: PointLike) {
        if (!this._map) {
            return;
        }
        this.zone.runOutsideAngular(() => {
            this._map.panTo(coords, offset ? { offset } : undefined);
        });
    }

    private panBy(coords: PointLike) {
        if (!this._map) {
            return;
        }
        this.zone.runOutsideAngular(() => {
            this._map.panBy(coords);
        });
    }
}
