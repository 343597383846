import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ANIMATION_OPACITY, RunImpact } from '@libs/common';
import {
    DirectivesModule,
    ImpactRunTimePipe,
    InputForDropdownModule,
    ItemRadioModule,
} from '@libs/shared-ui';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        InputForDropdownModule,
        DirectivesModule,
        ItemRadioModule,
        ImpactRunTimePipe,
    ],
    selector: 'ca-impact-run-selector',
    templateUrl: 'impact-run-selector.component.html',
    styleUrls: ['impact-run-selector.component.less'],
    animations: ANIMATION_OPACITY,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactRunSelectorComponent {
    @Input() runs: RunImpact[];
    @Input() activeRun: RunImpact;

    @Output() setRun = new EventEmitter<RunImpact>();
    showDropDown = false;

    select(run: RunImpact) {
        if (this.activeRun.id !== run.id) {
            this.setRun.emit(run);
        }
        this.showDropDown = false;
    }
}
