import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { BaseChartDirective } from 'ng2-charts';
import 'chartjs-adapter-moment';

import { ButtonPlayModule } from './button-play/button-play.module';
import { TimeRunnerModule } from './time-runner/time-runner.module';

import { TimelinePanelComponent } from './timeline-panel.component';
import { ChartTimelineComponent } from './chart-timeline/chart-timeline.component';
import { TrackPanelComponent } from './track-panel/track-panel.component';
import { IsNumberPipe } from './pipes/is-number.pipe';
import { ExceedsPdkValuePipe } from './pipes/exceeds-pdk-value.pipe';

import * as fromStore from './store';
import { ChartCheckboxComponent } from './chart-checkbox/chart-checkbox.component';
import { ChartCheckboxButtonComponent } from './chart-checkbox-button/chart-checkbox-button.component';
import { ChartCheckboxAqiComponent } from './chart-checkbox-aqi/chart-checkbox-aqi.component';
import { AqiDetailsTooltipModule } from '../aqi-details-tooltip/aqi-details-tooltip.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { CrossButtonModule } from '../cross-button/cross-button.module';
import { InfoIconModule } from '../icons/components/info-icon/info-icon.module';
import { InputRadioSquareModule } from '../input-radio-square/input-radio-square.module';
import { PipesModule } from '../pipes/pipes.module';
import { LoadersModule } from '../spinner/loaders.module';
import { TooltipsModule } from '../tooltips/tooltips.module';
import { ValueCircleModule } from '../value-circle/value-circle.module';
import { NoValueFormatPipe, ValueFormatNumberPipe } from '@libs/shared-ui';
import { ToTimelineDataPipe } from './track-panel/pipes/to-timeline-data.pipe';
import { TimelineBlockBackgroundColorPipe } from './track-panel/pipes/timeline-block-background.pipe';
import { DataListComponent } from './data-list/data-list.component';
import { FeatureNamePipe } from './data-list/pipes/feature-name.pipe';
import { FeatureCurrentTypePipe } from './data-list/pipes/feature-current-type.pipe';
import { FeatureAncestorNamePipe } from './data-list/pipes/feature-ancestor-name.pipe';
import { GetFeatureValuePipe } from './data-list/pipes/get-feature-value.pipe';
import { IsEmptyChartDataPipe } from './chart-timeline/pipes/is-empty-chart-data.pipe';
import { IsEmptyDataByMmtsPipe } from './chart-timeline/pipes/is-empty-data-by-mmts.pipe';
import { ChartDataPipe } from './chart-timeline/pipes/chart-data.pipe';
import { GetObjIsEmptyKeyPipe } from './chart-timeline/pipes/get-obj-is-empty-key.pipe';
import { ContributionLabelsPipe } from './data-list/pipes/contribution-labels.pipe';
import { ContributionDataPipe } from './data-list/pipes/contribution-data.pipe';
import { ContributionTimelineDataPipe } from './data-list/pipes/contribution-timeline-data.pipe';
import {
    IssetContributionTimelineDataPipe,
    IssetContributionPipe,
} from './data-list/pipes/isset-contribution-timeline.pipe';
import { GetWidthListPipe } from './data-list/pipes/get-width-list.pipe';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        CrossButtonModule,
        CheckboxModule,
        BaseChartDirective,
        StoreModule.forFeature('timeline', fromStore.reducers),
        ButtonPlayModule,
        TimeRunnerModule,
        TooltipsModule,
        InfoIconModule,
        LoadersModule,
        ValueCircleModule,
        InputRadioSquareModule,
        AqiDetailsTooltipModule,
    ],
    exports: [TimelinePanelComponent],
    providers: [IsNumberPipe, ExceedsPdkValuePipe, ValueFormatNumberPipe, NoValueFormatPipe],
    declarations: [
        TimelinePanelComponent,
        ChartTimelineComponent,
        TrackPanelComponent,
        IsNumberPipe,
        ExceedsPdkValuePipe,
        ChartCheckboxComponent,
        ChartCheckboxButtonComponent,
        ChartCheckboxAqiComponent,
        ToTimelineDataPipe,
        TimelineBlockBackgroundColorPipe,
        DataListComponent,
        FeatureNamePipe,
        FeatureCurrentTypePipe,
        FeatureAncestorNamePipe,
        GetFeatureValuePipe,
        IsEmptyChartDataPipe,
        IsEmptyDataByMmtsPipe,
        ChartDataPipe,
        GetObjIsEmptyKeyPipe,
        ContributionLabelsPipe,
        ContributionDataPipe,
        ContributionTimelineDataPipe,
        IssetContributionTimelineDataPipe,
        IssetContributionPipe,
        GetWidthListPipe,
    ],
})
export class TimelinePanelModule {}
