import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NotificationSettings, NotificationType, TabModel, TEXTS } from '@libs/common';
import { Store } from '@ngrx/store';
import {
    selectCurrentSettingsType,
    selectNotificationsSettingsByType,
} from '@cityair/modules/notifications/store/selectors';
import {
    changeCurrentSettingsType,
    openSettingForm,
} from '@cityair/modules/notifications/store/actions';
import { selectGroupAndIAm } from '@cityair/modules/core/store/selectors';

@Component({
    selector: 'subscriptions',
    styles: `::ng-deep .notifications_subscriptions .ca_switch_item {
        padding: 5px !important;
    }`,
    templateUrl: 'subscriptions.component.html',
})
export class Subscriptions implements OnInit, OnDestroy {
    @ViewChild('subs') subs: CdkVirtualScrollViewport;

    onDestroy$ = new Subject<void>();

    textsNotification = TEXTS.NOTIFICATIONS;
    textsAdminPanel = TEXTS.ADMIN_PANEL;
    textsConfig = TEXTS.CONFIG;

    filterTabs: TabModel[] = [
        {
            title: this.textsNotification.measures,
            type: NotificationType.pdkExcess,
        },
        {
            title: this.textsNotification.service,
            type: NotificationType.deviceIncident,
        },
    ];

    selectedTab: TabModel;
    NotificationType = NotificationType;
    settings: NotificationSettings[];
    initialScrollPosition = true;
    selectedCount = 0;
    userGroupData: any;

    constructor(public store: Store) {
        store
            .select(selectNotificationsSettingsByType)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                this.settings = data;
                this.selectedCount = data?.length;
            });
        store
            .select(selectCurrentSettingsType)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((type) => (this.selectedTab = this.filterTabs.find((v) => v.type === type)));
        this.store
            .select(selectGroupAndIAm)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((v) => v !== null)
            )
            .subscribe((data) => {
                this.userGroupData = data;
            });
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    showSelectedTab(tab: TabModel) {
        this.store.dispatch(changeCurrentSettingsType({ payload: tab.type }));
    }

    scrollTop() {
        this.subs.scrollToIndex(0);
    }

    addSubscription() {
        this.store.dispatch(
            openSettingForm({ settings_type: NotificationType.pdkExcess, currentSetting: null })
        );
    }
}
