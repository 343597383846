import { RESIZE_TIMEOUT } from '@cityair/config';
import { WindowGlobalVars } from '@libs/common';
import { environment } from 'environments/environment';

declare let window: WindowGlobalVars;

function calcCssVars() {
    document.documentElement.style.setProperty('--full-height', `${window.innerHeight}px`);
    document.documentElement.style.setProperty(
        '--height-mobile-chart-measures',
        `${window.innerHeight - 111}px`
    );

    let padding = '0px';
    if (environment.is_mobile_app && window.innerHeight > window.innerWidth) {
        padding = '35px';
    } else {
        const standalone = window.matchMedia('(display-mode: standalone)').matches;
        const userAgent = window.navigator.userAgent.toLowerCase();
        const safari = /safari/.test(userAgent);
        const iphone = /iphone/.test(userAgent);

        if (
            ((iphone && !standalone && !safari) || userAgent.includes('wv')) &&
            window.innerHeight > window.innerWidth
        ) {
            // iOS webview or Android wevView
            padding = '25px';
        }
    }

    document.documentElement.style.setProperty('--mobile-app-padding-top', padding);
}

export function createCssVars() {
    calcCssVars();
    setTimeout(calcCssVars, RESIZE_TIMEOUT * 2);

    (window.visualViewport || window).addEventListener('resize', calcCssVars);

    window.addEventListener('orientationchange', calcCssVars);
}
