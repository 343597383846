import { Pipe, PipeTransform } from '@angular/core';
import { EventImpact } from '@cityair/modules/impact/service/api-model-impact';
import moment from 'moment-timezone';

@Pipe({
    name: 'maxValueEvent',
})
export class MaxValueEventPipe implements PipeTransform {
    transform(data: EventImpact, currentPostId: string): { value: number; time: string } {
        const arr = currentPostId
            ? data?.events?.filter((v) => v.post_id === currentPostId).map((v) => v.max_value)
            : data?.events?.map((v) => v.max_value);
        const value = Math.max(...arr);
        const maxTimeEvent = data?.events?.find((v) => v.max_value === value);
        if (maxTimeEvent) {
            return {
                value,
                time: moment(maxTimeEvent.start).add(5, 'minutes').toISOString(),
            };
        }

        return null;
    }
}
