<div class="chart" *ngIf="chartEnabled && data?.length" [@inOutAnimation]>
    <ng-container *ngIf="(data | getObjIsEmptyKey) as isEmptyDataByKey">
        <div class="data-container">
            <!-- Project legacy buttons -->
            <ng-content></ng-content>
        </div>
        <div class="timeline-loader" *ngIf="chartLoading$ | async else emptyDataTemp">
            <div class="wrapper-loader spinner">
                <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
            </div>
        </div>
        <ng-template #emptyDataTemp>
            <div class="timeline-loader">
                <div class="empty" *ngIf="isEmptyDataByKey | isEmptyChartData else noDataMMt">
                    <div class="wrapper-no-data">{{ noDataText }}</div>
                </div>
                <ng-template #noDataMMt>
                    <div class="empty" *ngIf="isEmptyDataByKey | isEmptyDataByMmts : mmts">
                        <div class="wrapper-no-data">{{ noDataForMmtText }}</div>
                    </div>
                </ng-template>
            </div>
        </ng-template>
        <div
            class="chart-content"
            [ngClass]="{
                'hidden': (isEmptyDataByKey | isEmptyChartData)
                 || (isEmptyDataByKey | isEmptyDataByMmts : mmts)
                 || (chartLoading$ | async)
             }"
            (mouseleave)="hoverLineVisible = false"
        >
            <canvas
                #chart
                baseChart
                [legend]="false"
                [datasets]="data | chartData : mmts: measureScheme: cityNameField"
                [options]="chartOptions"
                [plugins]="chartPlugins"
                (chartClick)="chartClick($event)"
            ></canvas>
            <div
                class="chart-hover-line"
                [style.visibility]="hoverLineVisible ? 'visible' : 'hidden'"
                [style.left.px]="hoverLinePosition"
            ></div>
        </div>
    </ng-container>
</div>
