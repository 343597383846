<div
    class="range-slider-option"
    [ngStyle]="{
        background: getGradient()
    }"
>
    <div class="range-slider__track">
        <div
            class="range-slider__track_before"
            [ngStyle]="{
                width: handleMinPosition + '%'
            }"
        ></div>
        <div
            class="range-slider__track_active"
            [ngStyle]="{
                left: handleMinPosition + '%',
                width: 100 - handleMinPosition - (100 - handleMaxPosition) + '%'
            }"
        ></div>
        <div
            class="range-slider__track_after"
            [ngStyle]="{
                width: 100 - handleMaxPosition + '%'
            }"
        ></div>
    </div>
    <div class="range-slider__handle handle-min" [style]="{ left: handleMinPosition + '%' }">
        <span class="handle__value">{{ currentMinValue }}</span>
    </div>
    <div class="range-slider__handle handle-max" [style]="{ left: handleMaxPosition + '%' }">
        <span class="handle__value">{{ currentMaxValue }}</span>
    </div>
    <div class="range-slider__measure">
        <div class="measure__min">{{ minValue }}</div>
        <div class="measure__max">{{ maxValue }}</div>
        <div class="measure__unit">{{ measure }}</div>
    </div>
</div>
