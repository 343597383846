import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TEXTS } from '@libs/common';
import { AuthService } from '@libs/shared-store';
import { HarvesterApiService } from '../../services/harvester-api/harvester-api.service';
import {
    ClientConfig,
    ClientConfigService,
} from '../../../core/services/client-config/client-config.service';
import { LoginPage } from '../../login.settings';
import { environment } from 'environments/environment';
import { AIRVOICE_DOMAIN } from '@libs/common';

@Component({
    selector: 'ca-login-form',
    templateUrl: 'login-form.component.html',
    styleUrls: ['login-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
    @Input() subtitle: string;

    TEXTS = TEXTS;
    isAirVoiceDomain = false;
    showPassword = false;

    hasErrors = false;

    isLoading = false;

    loginForm: UntypedFormGroup;

    clientConfig: ClientConfig;

    loginPage = LoginPage;

    isShowMarketLinks = environment.is_mobile_app;

    constructor(
        private router: Router,
        private authService: AuthService,
        readonly harvester: HarvesterApiService,
        private fb: UntypedFormBuilder,
        clientConfigService: ClientConfigService,
        private _cdr: ChangeDetectorRef
    ) {
        this.clientConfig = clientConfigService.getConfig();
        this.isAirVoiceDomain = location.host === AIRVOICE_DOMAIN;
    }

    ngOnInit() {
        this.loginForm = this.fb.group({
            login: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    public getError(field: string): string {
        if (
            this.loginForm &&
            this.loginForm.controls[field].invalid &&
            (this.loginForm.controls[field].dirty || this.loginForm.controls[field].touched)
        ) {
            if (this.loginForm.controls[field].errors.required) {
                return field === 'login' ? 'Email is required.' : 'Password is required';
            }

            if (this.loginForm.controls[field].errors.incorrect) {
                return 'No active account found with the given credentials';
            }
        }

        return '';
    }

    public getValueForm(field) {
        return this.loginForm?.get(field)?.value || '';
    }

    onSubmit() {
        if (this.isLoading) {
            return;
        }

        this.hasErrors = false;

        if (this.loginForm.invalid) {
            return (this.hasErrors = true);
        }

        const { login, password } = this.loginForm.value;

        this.isLoading = true;

        this.authService.login(login.trim(), password).subscribe(
            () => {
                this.isLoading = false;
                this.router.navigate(['/']);
            },
            () => {
                this.isLoading = false;
                if (this.isAirVoiceDomain) {
                    this.loginForm.controls['password'].setErrors({ incorrect: true });
                } else {
                    this.hasErrors = true;
                }
                this._cdr.detectChanges();
            }
        );
    }
}
