import { on } from '@ngrx/store';
import { ICoreState } from '../reducers';
import * as comparedListCommonActions from './compared-list.actions';

export const comparedListReducers = [
    on(comparedListCommonActions.updateComparedItems, (state: ICoreState, props) => ({
        ...state,
        comparedItems: props.items,
    })),

    on(comparedListCommonActions.compareListAddUnique, (state: ICoreState, props) => ({
        ...state,
        comparedItems: [
            ...state.comparedItems.filter((el) => props.objects.every((o) => o.id !== el.id)),
            ...props.objects,
        ],
    })),

    on(comparedListCommonActions.compareListRemove, (state: ICoreState, props) => ({
        ...state,
        comparedItems: [...state.comparedItems.filter((el) => el.id !== props.id)],
        chartData: [...state.chartData.filter((el) => el.properties.uuid !== props.id)],
    })),

    on(comparedListCommonActions.clearCompareList, (state: ICoreState) => ({
        ...state,
        comparedItems: [],
        qualityDataTimeline: [],
    })),

    on(comparedListCommonActions.clearCompareListWithoutFirst, (state: ICoreState) => ({
        ...state,
        comparedItems: state.comparedItems[0] ? [state.comparedItems[0]] : [],
    })),
];
