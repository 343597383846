export const E = 'E';
export const N = 'N';
export const S = 'S';
export const W = 'W';
export const NE = 'NE';
export const NW = 'NW';
export const SE = 'SE';
export const SW = 'SW';
export const WIND_DIR_DEG = {
    [N]: -135,
    [NE]: -90,
    [E]: -45,
    [SE]: 0,
    [S]: 47,
    [SW]: 90,
    [W]: 135,
    [NW]: 180,
};
