import { Component } from '@angular/core';

import { TEXTS } from '@libs/common';

@Component({
    selector: 'ca-password-updated-popup',
    templateUrl: 'password-updated-popup.component.html',
    styleUrls: ['password-updated-popup.component.less'],
})
export class PasswordUpdatedPopupComponent {
    TEXTS = TEXTS;
}
