import { Component, Input } from '@angular/core';

@Component({
    selector: 'ca-language-item',
    templateUrl: 'language-item.component.html',
    styleUrls: ['language-item.component.less'],
})
export class LanguageItemComponent {
    @Input() code: string;

    @Input() name: string;

    @Input() showFlag = true;
}
