import { Component, Input } from '@angular/core';

@Component({
    selector: 'room-item-percent',
    templateUrl: './room-item-percent.component.html',
    styleUrls: ['./room-item-percent.component.less'],
})
export class RoomItemPercentComponent {
    @Input() title: string;
    @Input() titleDescription: string;
    @Input() grade: number;
    @Input() gradeDescription: string;
}
