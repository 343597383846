<div
    [ngClass]="{
        'city-pin': true,
        'city-pin_state-selected': selected,
        'city-pin_state-add': state === markerState.add && !selected,
        'city-pin_state-disable': hasNoData
    }"
>
    <div class="ny-hat" *ngIf="isHoliday"></div>
    <div class="city-pin__body">
        <value-circle
            [value]="value"
            [color]="color"
            [selected]="selected"
            [state]="state"
            [numberAfterDot]="numberAfterDot"
        ></value-circle>
        <div class="city-pin__title" data-cy="city-marker-name">{{ city }}</div>
    </div>
    <div class="city-pin__point">
        <div class="city-pin__center" [style]="{ backgroundColor: color }"></div>
    </div>
</div>
