<div class="room-rating">
    <div class="room-rating__header">
        <div class="room-rating__title">Room rating</div>
        <div class="room-rating__period">
            <switch-item
                [tabs]="tabs"
                [currentTab]="selectedTab"
                (action)="showSelectedTab($event)"
            ></switch-item>
        </div>
    </div>
    <div class="room-rating__sections">
        <div *ngIf="roomPercentRating?.length" class="room-rating__section">
            <div
                *ngFor="let room of roomPercentRating; let i = index"
                class="room-rating__item"
                [attr.data-cy]="'room-percent-' + i"
            >
                <room-item-percent
                    [title]="room.id + ' • ' + (store.select(selectPost(room.id)) | async)?.name"
                    [titleDescription]="room.titleDescription"
                    [grade]="room.grade"
                    [gradeDescription]="room.gradeDescription"
                    (click)="store.dispatch(selectPoint({ id: room.id }))"
                ></room-item-percent>
            </div>
        </div>

        <div *ngIf="roomCircleRating?.length" class="room-rating__section">
            <div *ngFor="let room of roomCircleRating" class="room-rating__item">
                <room-item-circle
                    [title]="room.id + ' • ' + (store.select(selectPost(room.id)) | async)?.name"
                    [titleDescription]="room.titleDescription"
                    [grade]="room.grade"
                    [color]="room.color"
                    [numberAfterDot]="room.numberAfterDot"
                    (click)="store.dispatch(selectPoint({ id: room.id }))"
                ></room-item-circle>
            </div>
        </div>
    </div>
</div>
