import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { InfoMessage, TEXTS } from '@libs/common';

export const INFO_MESSAGE_KEY_OFFLINE = 'internet_offline';
@Component({
    selector: 'info-message',
    templateUrl: './info-message.component.html',
    styleUrls: ['./info-message.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoMessageComponent implements OnChanges {
    @Input() data: InfoMessage[];
    @Output() hide = new EventEmitter<InfoMessage>();

    // key for translate
    private textArray = {
        [INFO_MESSAGE_KEY_OFFLINE]: 'internetOff',
    };
    public errorTextTitle = TEXTS.INFO_MESSAGE.title;
    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.data?.currentValue?.length) {
            this.data.forEach((item) => {
                if (!item?.fullScreen) {
                    const duration = item?.duration ?? 2500;
                    setTimeout(() => this.close(item), duration);
                }
            });
        }
    }

    public getMessage(item: InfoMessage): string {
        const key = this.textArray[item.messageKey] ?? item.messageKey;
        return TEXTS.INFO_MESSAGE[key] ?? item.message ?? null;
    }

    public close(item) {
        this.hide.emit(item);
    }
}
