import { EntityMetadataMap } from '@ngrx/data';
import * as CONSTANTS from './constants';

const entityMetadata: EntityMetadataMap = {
    [CONSTANTS.STATION_ENTITY_STORE_KEY]: {},
    [CONSTANTS.CONTROL_POINT_ENTITY_STORE_KEY]: {},
    [CONSTANTS.RUN_CONFIG_ENTITY_STORE_KEY]: {},
    [CONSTANTS.RUN_ENTITY_STORE_KEY]: {},
};

const pluralNames = {
    [CONSTANTS.STATION_ENTITY_STORE_KEY]: 'group',
    [CONSTANTS.CONTROL_POINT_ENTITY_STORE_KEY]: 'control-points',
    [CONSTANTS.RUN_CONFIG_ENTITY_STORE_KEY]: 'run-config',
    [CONSTANTS.RUN_ENTITY_STORE_KEY]: 'runs',
};

export const entityPlumesConfig = {
    entityMetadata,
    pluralNames,
};
