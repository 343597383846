import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isEmptyDataByMmts',
})
export class IsEmptyDataByMmtsPipe implements PipeTransform {
    transform(data: any, mmts: string[]): boolean {
        return !mmts.some((key) => !data[key]);
    }
}
