import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AqiType, GroupExtConfigName, MeasureScheme, isRU, TEXTS } from '@libs/common';
import { MapControlPins, MapPins } from '@libs/common';
import {
    selectGlobalMeasurement,
    selectIsCityMode,
    selectPdkForChart,
} from '@cityair/modules/core/store/selectors';
import { GroupFeaturesService } from '@libs/shared-store';
import {
    currentForecastMmt,
    getControlPointsForMap,
    getControlPointValues,
    getStationsForMapForecast,
    getStationsValues,
    isDraggableControlPoint,
    selectChartData,
    selectCurrentTimeForecast,
    selectDatesForecast,
    selectForecastSchema,
    selectForecastSchemaZones,
    selectForecastZone,
    selectListActive,
    selectMmtIsWD,
    stationLoaded,
} from '../../store/selectors';
import { ControlPointForecast, Station } from '../../models';
import { ForecastState } from '../../store/reducers';
import {
    setActivePoint,
    setActiveStation,
    setCoordinates,
    updateTimeIndex,
} from '../../store/actions';
import { GroupChartConfig, getDigitsAfterDot } from '@libs/common';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { getColorFromZone, getColorFromZoneForecast } from '@cityair/utils/utils';
import { NEW_CONTROL_POINT_OBJ_TYPE, NUMBER_ROUND_COORDINATES } from '../../constants';
import { loadCity, openInstantAqiFaq } from '@cityair/modules/core/store/actions';
import {
    MapAdapterService,
    selectCurrentMapType,
    selectCurrentMeasureScheme,
    selectExtConfig,
    selectMapStyleTypes,
    selectSidebarIsOpen,
    SharedCoreActions,
    selectMapStyleLoading,
    selectUserTimezoneLabel,
} from '@libs/shared-store';

@Component({
    selector: 'forecast-map',
    templateUrl: './forecast-map.component.html',
    styleUrls: ['./forecast-map.component.less'],
})
export class ForecastMapComponent implements OnDestroy {
    TEXTS = TEXTS;
    isRU = isRU;
    AqiType = AqiType;
    onDestroy$ = new Subject<void>();
    selectSidebarIsOpen = selectSidebarIsOpen;
    currentForecastMmt = currentForecastMmt;
    selectDatesForecast = selectDatesForecast;
    stationLoaded = stationLoaded;
    selectGlobalMeasurement = selectGlobalMeasurement;
    chartMinMax: GroupChartConfig;
    MeasureScheme = MeasureScheme;
    selectMeasureScheme = selectCurrentMeasureScheme;
    updateTimeIndex = updateTimeIndex;
    selectCurrentTimeForecast = selectCurrentTimeForecast;
    selectForecastSchemaZones = selectForecastSchemaZones;
    selectForecastSchema = selectForecastSchema;
    selectForecastZone = selectForecastZone;
    selectPdkForChart = selectPdkForChart;
    selectChartData = selectChartData;
    selectIsCityMode = selectIsCityMode;
    selectUserTimezoneLabel = selectUserTimezoneLabel;
    setMapStyleType = SharedCoreActions.setMapStyleType;
    selectCurrentMapStyleType = selectCurrentMapType;
    selectMapStyleTypes = selectMapStyleTypes;
    selectMapStyleLoading = selectMapStyleLoading;

    constructor(
        readonly store: Store,
        readonly storeForecast: Store<ForecastState>,
        private mapAdapterService: MapAdapterService,
        private groupFeaturesService: GroupFeaturesService
    ) {
        store
            .select(selectExtConfig)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((id) => id !== null)
            )
            .subscribe((data) => this.init());
    }

    init() {
        this.chartMinMax = this.groupFeaturesService.getGroupChartConfig();
        const postPins: MapPins = {
            getPins: this.storeForecast.select(getStationsForMapForecast),
            selectDigitsAfterDot: this.store
                .select(selectForecastSchema)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.storeForecast.select(selectListActive),
            getValue: (pin) => this.storeForecast.select(getStationsValues(pin as Station)),
            getColor: (pin) =>
                combineLatest([
                    this.storeForecast.select(getStationsValues(pin as Station)),
                    this.storeForecast.select(selectForecastZone),
                ]).pipe(map(([value, zone]) => getColorFromZone(zone, value))),
            clickCb: (pin) => {
                const station = pin as Station;
                if (station?.data?.measurements) {
                    this.store.dispatch(setActiveStation({ payload: station }));
                }
            },
        };

        const controlPointPins: MapControlPins = {
            getPins: this.storeForecast.select(getControlPointsForMap),
            selectDigitsAfterDot: this.store
                .select(selectForecastSchema)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.storeForecast.select(selectListActive),
            getValue: (pin) => this.storeForecast.select(getControlPointValues(pin)),
            getColor: (pin) =>
                combineLatest([
                    this.storeForecast.select(getControlPointValues(pin)),
                    this.storeForecast.select(selectForecastZone),
                    this.storeForecast.select(currentForecastMmt),
                ]).pipe(map(([value, zone, mmt]) => getColorFromZoneForecast(zone, value, mmt))),
            isWD: this.storeForecast.select(selectMmtIsWD),
            clickCb: (pin) => {
                const cp = pin as ControlPointForecast;
                if (cp?.obj === NEW_CONTROL_POINT_OBJ_TYPE) {
                    return;
                }
                if (cp.timeseries) {
                    this.store.dispatch(setActivePoint({ payload: cp }));
                }
            },
            isDraggable: (pin: ControlPointForecast) =>
                this.storeForecast.select(isDraggableControlPoint(pin)),
            dragEnd: ($event, pin: ControlPointForecast) => {
                const lat = Array.isArray($event) ? $event[1] : $event?._lngLat.lat;
                const lng = Array.isArray($event) ? $event[0] : $event?._lngLat.lng;
                this.store.dispatch(
                    setCoordinates({
                        payload: {
                            lat: parseFloat(lat.toFixed(NUMBER_ROUND_COORDINATES)),
                            lon: parseFloat(lng.toFixed(NUMBER_ROUND_COORDINATES)),
                        },
                    })
                );
            },
        };
        const forecastSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.forecastMapSettings
        );
        this.mapAdapterService.set({
            zoom: forecastSettings?.zoom || undefined,
            center: forecastSettings?.center || undefined,
            postPins,
            controlPointPins,
        });
    }

    ngOnDestroy() {
        this.onDestroy$.complete();
    }

    openAqiFaqLink(aqiType: AqiType) {
        if (aqiType === AqiType.instant) {
            this.store.dispatch(openInstantAqiFaq());
        }
    }

    public goToCity(cityId: string) {
        this.store.dispatch(loadCity({ cityId, centringMap: true }));
    }
}
