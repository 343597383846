import { Post } from '@cityair/modules/indoor/services/api';

export const INDOOR_MAP_STYLE = {
    version: 8,
    name: 'Empty',
    sources: {},
    layers: [],
};

export class ImgToMap {
    imgSize: [number, number];

    d = 1000;

    coordinates: {
        [postId: number]: {
            lat: number;
            lon: number;
        };
    } = {};

    constructor(imgSize: [number, number]) {
        this.imgSize = imgSize;
    }

    setCoordinates(posts: Post[], pixels: { [pinId: number]: [number, number] }) {
        posts.forEach((post) => {
            const coord = pixels[post.id];

            this.coordinates[post.id] = {
                lon: coord ? coord[0] / this.d : post.geo.longitude,
                lat: coord ? coord[1] / this.d : post.geo.latitude,
            };
        });
    }

    getRightLon = () => this.imgSize[0] / this.d;

    getLeftLon = () => 0;

    getTopLat = () => this.imgSize[1] / this.d;

    getBottomLat = () => 0;

    getCoordinates(coord: [number, number]): { lon: number; lat: number } {
        return {
            lon: coord[0] / this.d,
            lat: coord[1] / this.d,
        };
    }
}
