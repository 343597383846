import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { debounceTime, fromEvent } from 'rxjs';
import { Group, MAIN_PAGES, isRU, UserInfo } from '@libs/common';

@Component({
    selector: 'shared-ui-sidebar-menu',
    templateUrl: './sidebar-menu.component.html',
    styleUrls: ['./sidebar-menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent implements OnInit, OnChanges {
    @Input() groupList: Group[];
    @Input() userData: UserInfo;
    @Input() currentGroupId: string;
    @Input() availableModules: MAIN_PAGES[];
    @Input() activeModule: string;
    @Input() assets: any;

    @Output() selectModule = new EventEmitter<any>();
    @Output() changeGroup = new EventEmitter<any>();
    @Output() showFeedback = new EventEmitter<boolean>();
    @Output() logOut = new EventEmitter<any>();

    isRu = isRU;

    constructor(private _cDRef: ChangeDetectorRef) {}

    ngOnInit() {
        fromEvent(window, 'resize')
            .pipe(debounceTime(100))
            .subscribe(() => {
                this.fixDisplayLogo();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.availableModules?.currentValue || changes?.assets?.currentValue) {
            this.fixDisplayLogo();
        }
    }

    public showLogo = false;
    public MAIN_PAGE = MAIN_PAGES;
    public selectGroup(groupId) {
        if (this.currentGroupId !== groupId) {
            this.changeGroup.emit(groupId);
        }
    }

    public select(page: MAIN_PAGES) {
        this.selectModule.emit(page);
    }

    public isEnabled(page: MAIN_PAGES): boolean {
        return this.availableModules && this.availableModules.indexOf(page) >= 0;
    }

    public showFeedbackForm() {
        this.showFeedback.emit(true);
    }

    public exit() {
        this.logOut.emit();
    }

    private fixDisplayLogo() {
        if (this.assets?.logo) {
            return false;
        } else {
            const availableHeight =
                window.innerHeight - 62 - 86 - this.availableModules?.length * 65 - 52;
            this.showLogo = availableHeight >= 150 && this.isRu;
        }
        this._cDRef.markForCheck();
    }
}
