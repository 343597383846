import { ChartConfiguration } from 'chart.js';
import { ALERT_COLORS } from '@libs/common';

export const options: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        tooltip: {
            borderWidth: 1,
            bodyColor: '#000',
            titleColor: '#000',
            displayColors: true,
            borderColor: ALERT_COLORS[1],
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            callbacks: {
                label: (tooltipItem) => `${tooltipItem.label} ${tooltipItem.formattedValue}%`,
            },
        },
    },
};
