import {
    Directive,
    Input,
    HostListener,
    ElementRef,
    TemplateRef,
    OnInit,
    OnDestroy,
} from '@angular/core';

import { fromEvent, Subject, merge } from 'rxjs';
import { takeUntil, filter, take } from 'rxjs/operators';

import { TooltipsService, TooltipPlacement, ITooltipComponent } from './tooltips.service';

const HOVER_RECATCH_GRACE_TIME_MS = 300;

@Directive({
    selector: '[caTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
    @Input() caTooltipTemplate: TemplateRef<ITooltipComponent>;
    @Input() caTooltipPlacement: TooltipPlacement = 'right';
    @Input() caTooltipFollowPointer?: boolean;
    @Input() caTooltipAllowHover?: boolean;
    @Input() hoverDetachTime: number = HOVER_RECATCH_GRACE_TIME_MS;
    @Input() caTooltipHidden?: boolean;

    private onDestroy$ = new Subject<void>();
    private onLeave$ = new Subject<void>();
    private onClear$ = merge(this.onLeave$, this.onDestroy$);

    constructor(private el: ElementRef, private tooltipsService: TooltipsService) {}

    ngOnInit() {
        this.onClear$.subscribe(() => {
            this.tooltipsService.clearBy(this.el);
        });

        this.tooltipsService.isHoverOverTooltip$
            .pipe(
                takeUntil(this.onDestroy$),
                filter((isHoverOverTooltip) => !isHoverOverTooltip)
            )
            .subscribe(() => {
                if (this.caTooltipAllowHover) {
                    this.onLeave$.next();
                }
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.onLeave$.complete();
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.tooltipsService.setFloating(this.caTooltipFollowPointer);
        if (this.caTooltipFollowPointer && !this.caTooltipHidden) {
            this.watchPointer();
        }
        if (!this.caTooltipHidden) {
            this.tooltipsService.show(this.el, this.caTooltipTemplate, this.caTooltipPlacement);
        }
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        if (!this.caTooltipAllowHover) {
            this.onLeave$.next();
        } else {
            // if tooltip itself wasn't hovered over
            // then trigger onLeave but only after some grace period
            // to give a chance to hover over tooltip
            setTimeout(() => {
                this.tooltipsService.isHoverOverTooltip$.pipe(take(1)).subscribe((isHovered) => {
                    if (!isHovered) {
                        this.onLeave$.next();
                    }
                });
            }, this.hoverDetachTime);
        }
    }

    private watchPointer() {
        fromEvent(window, 'wheel')
            .pipe(takeUntil(this.onClear$))
            .subscribe((e: WheelEvent) => {
                const { left, right, top, bottom } = this.el.nativeElement.getBoundingClientRect();
                const { clientX, clientY } = e;

                if (clientX > right || clientX < left || clientY < top || clientY > bottom) {
                    this.onMouseLeave();
                }
            });

        fromEvent(document, 'mousemove')
            .pipe(takeUntil(this.onClear$))
            .subscribe((e: MouseEvent) => {
                this.updatePosition(e);
            });
    }

    private updatePosition({ clientX, clientY }: MouseEvent) {
        const { position } = this.tooltipsService;
        if (this.caTooltipHidden) {
            this.tooltipsService.clearBy(this.el);
            return;
        }
        if (position) {
            position.x = clientX;
            position.y = clientY;
        }
    }
}
