<div class="top_right_panel">
    <ng-container *ngIf="store.select(selectIsAllowPublicForecast) | async">
        <!-- Exclude public forecast button when Forecast module is enabled -->
        <ca-layer-button *ngIf="store.select(selectMmtsPublicForecast) | async as mmts"
            class="layer_button"
            [type]="LayerButtonIconType.FORECAST"
            [active]="isActiveModelling"
            (clickOnIcon)="
                isActiveModelling ? disableModelling() : enableModelling()
            "
        >
            <ca-layer-button-item
                *ngFor="let opt of mmts"
                [label]="TEXTS.NAMES[opt]"
                [active]="(store.select(selectActiveMmtPublicForecast) | async) === opt"
                (click)="store.dispatch(setActiveMmt({payload: opt}))"
            ></ca-layer-button-item>
        </ca-layer-button>
    </ng-container>
    <ng-container *ngIf="store.select(selectIsAllowLensForecast) | async">
        <!-- lens forecast button when Forecast module is enabled -->
        <ca-layer-button
            class="layer_button lens"
            [type]="LayerButtonIconType.LENS"
            [active]="isActiveLens"
            (clickOnIcon)="
                isActiveLens ? disableLens() : enableLens()
            "
        >
        </ca-layer-button>
    </ng-container>
    <ng-container *ngIf="(moduleConfig$ | async).enableAqi">
        <ng-container
            *ngIf="store.select(selectShowAqiWidget) | async"
        >
            <ng-container
                *ngIf="{
                    aqi:
                        store.select(
                            selectCityCurrentValue(currentLocationId$ | async)
                        ) | async
                } as temp"
            >
                <shared-ui-aqi-indicator
                    class="aqi-indicator"

                    [@inOutAnimation]
                    (clickOutside)="showAqiLegend = false"
                    (click)="showAqiLegend = !showAqiLegend"
                    [value]="temp.aqi"
                    [dateTime]="store.select(selectCurrentTimeByIndex) | async"
                    [mmt]="store.select(selectGlobalMeasurement) | async"
                ></shared-ui-aqi-indicator>

                <ng-container *ngIf="showAqiLegend">
                     <ng-container *ngIf="isRU; else nonRU">
                        <ng-container
                            [ngSwitch]="store.select(selectGlobalMeasurement) | async"
                        >
                            <shared-ui-aqi-legend
                                class="aqi-legend"
                                *ngSwitchCase="AqiType.cityair"
                                [aqi]="temp.aqi"
                            ></shared-ui-aqi-legend>
                            <shared-ui-instant-aqi-legend
                                class="aqi-legend"
                                *ngSwitchCase="AqiType.instant"
                                (openAqiFaqLink)="openDetailsLink()"
                                [aqi]="temp.aqi"
                            ></shared-ui-instant-aqi-legend>
                            <shared-ui-capi-aqi-legend
                                class="aqi-legend"
                                *ngSwitchCase="AqiType.CAPI"
                                [aqi]="temp.aqi"
                            ></shared-ui-capi-aqi-legend>
                        </ng-container>
                    </ng-container>
                    <ng-template #nonRU>
                        <shared-ui-aqi-legend class="aqi-legend" [aqi]="temp.aqi"></shared-ui-aqi-legend>
                    </ng-template>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
