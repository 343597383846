<div class="indoor_calendar-mobile" (click)="toggleShow()">
    <div class="date">
        <span class="icon"></span>
        <span>{{ intervalText }}</span>
    </div>
</div>
<calendar-months-mobile
    [show]="show"
    [timeBegin]="begin"
    [timeEnd]="end"
    [tzOffset]="tzOffset"
    [toggleShowCb]="toggleShow"
    [applyCb]="updateTime"
    [updateDays]="updateDays"
    [numberOfMonths]="12"
></calendar-months-mobile>
