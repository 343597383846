import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoIconComponent } from './info-icon.component';

@NgModule({
    declarations: [InfoIconComponent],
    exports: [InfoIconComponent],
    imports: [CommonModule],
})
export class InfoIconModule {}
