import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MapService } from '../map/map.service';
import { LayerEvents } from '../map/map.types';
import { Layer, VectorImage } from 'ol/layer';
import { Options } from 'ol/layer/Layer';
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import VectorImageLayer from 'ol/layer/VectorImage';
import { Feature } from 'ol';

@Component({
    selector: 'shared-ui-ol-layer',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerComponent implements OnInit, OnDestroy, OnChanges, LayerEvents {
    @Input() className?: Options['className'];
    @Input() opacity?: Options['opacity'];
    @Input() visible?: Options['visible'];
    @Input() extent?: Options['extent'];
    @Input() zIndex: Options['zIndex'];
    @Input() minResolution?: Options['minResolution'];
    @Input() maxResolution?: Options['maxResolution'];
    @Input() minZoom?: Options['minZoom'];
    @Input() maxZoom?: Options['maxZoom'];
    @Input() source?: Options['source'];
    @Input() map?: Options['map'];
    @Input() render?: Options['render'];
    @Input() properties?: Options['properties'];
    @Input() features: GeoJSON.Feature | GeoJSON.FeatureCollection;
    @Input() color?: string;
    @Input() paint?: any;

    @Output() layerClick = new EventEmitter<any>();
    @Output() layerDblClick = new EventEmitter<any>();
    @Output() layerMouseOver = new EventEmitter<any>();
    @Output() layerMouseOut = new EventEmitter<any>();
    @Output() layerMouseMove = new EventEmitter<any>();

    private layerAdded = false;
    private layerInstance: Layer;
    private sub: Subscription;

    constructor(private mapService: MapService) {}

    ngOnInit() {
        this.sub = this.mapService.mapCreated$.subscribe(() => {
            this.init();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.layerAdded) {
            return;
        }
    }

    ngOnDestroy() {
        if (this.layerAdded) {
            this.mapService.removeLayer(this.layerInstance);
        }
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    private init() {
        const source = new VectorSource({
            features: new GeoJSON().readFeatures(this.features),
        });

        this.layerInstance = new VectorImageLayer({
            source,
            style: this.styleFunction,
        });
        this.mapService.addLayer(this.layerInstance);

        if (this.features?.type === 'Feature' && this.layerMouseOver.observed) {
            const current = this.features as GeoJSON.Feature;
            const currentId = current.properties.id;
            const self = this;
            const map = this.mapService.getMap();
            let mouseOver = false;
            if (currentId) {
                map.on('pointermove', function (evt) {
                    if (evt.dragging) {
                        return;
                    }
                    const pixel = map.getEventPixel(evt.originalEvent);
                    const features = map.getFeaturesAtPixel(pixel);
                    if (features.length === 0) {
                        if (mouseOver) {
                            self.layerMouseOut.emit();
                            self.setLineStyle(self.layerInstance, false);
                            map.getViewport().style.cursor = 'default';
                            mouseOver = false;
                        }

                        return;
                    }

                    const currentLayer = features?.filter((f) => {
                        if (f instanceof Feature) {
                            const props = f.getProperties();

                            if (props.id === currentId) {
                                return f;
                            }
                        }
                    });

                    if (currentLayer.length && !mouseOver) {
                        mouseOver = true;
                        self.setLineStyle(self.layerInstance, true);
                        map.getViewport().style.cursor = 'pointer';
                        self.layerMouseOver.emit({ x: pixel[0], y: pixel[1] });
                    }
                });
            }
        }
        this.layerAdded = true;
    }

    private styleFunction = (feature) => {
        const geometry = feature.getGeometry();
        const properties = feature.getProperties();
        if (geometry.getType() === 'MultiLineString') {
            const styles = [
                new Style({
                    stroke: new Stroke({
                        color: properties?.color ?? '#eee',
                        width: properties?.width ?? 1,
                        lineDash: properties?.dasharray ?? null,
                    }),
                }),
            ];
            return styles;
        }
        if (geometry.getType() === 'Polygon') {
            const styles = [
                new Style({
                    stroke: new Stroke({
                        color: properties?.border ?? '#eee',
                        width: properties?.width ?? 1,
                        lineDash: properties?.dasharray ?? null,
                    }),
                    fill: new Fill({
                        color:
                            this.hexToRGB(properties?.color, properties?.opacity) ??
                            'rgba(255, 102, 0, 0.3)',
                    }),
                }),
            ];
            return styles;
        }
        if (geometry.getType() === 'LineString') {
            const styles = [
                new Style({
                    stroke: new Stroke({
                        color: properties?.color ?? '#eee',
                        width: properties?.width ?? 1,
                    }),
                }),
            ];
            return styles;
        }
        return new Style();
    };

    private hexToRGB(hex: string, alpha?: number) {
        if (!hex) {
            return 'rgba(255, 102, 0, 0.3)';
        }
        if (hex.indexOf('rgba') >= 0) {
            return hex;
        }
        if (hex.indexOf('rgb') >= 0) {
            const opacity = alpha ?? 1;
            return hex.replace(')', `, ${opacity})`).replace('rgb', 'rgba');
        }
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
        } else {
            return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        }
    }

    private setLineStyle(vector, active) {
        if (this.features?.type === 'Feature') {
            const current = this.features as GeoJSON.Feature;
            const properties = current.properties;
            const style = new Style({
                stroke: new Stroke({
                    color: properties?.color ?? '#eee',
                    width: active ? 7 : properties.width,
                }),
            });
            vector.setStyle(style);
        }
    }
}
