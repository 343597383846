<div class="event__header">
    <div class="event__title_wrapper">
        <search-input
            [textPlaceholder]="textsNotification.eventsFilterPrompt"
            [text]="filterText"
            (textChange)="filterUpdate($event)"
            [debounceTime]="200"
        ></search-input>
    </div>
    <div
        class="event__filter-tabs"
        [class.filter-tabs--initial-position]="initialScrollPosition || !selectedCount"
        [class.with-analysis]="store.select(allowModule(eventsName)) | async"
    >
        <switch-item
            class="tabs-wrapper"
            [currentTab]="selectedTab"
            [tabs]="filterTabs"
            (action)="showSelectedTab($event)"
        ></switch-item>
        <div *ngIf="store.select(allowModule(eventsName)) | async"
             class="analysis-event"
             (click)="gotToAnalysisEvents()"
        >
            <div class="button">
                <div class="image"></div>
                {{textsNotification.analysisEvent.buttonText}}
            </div>
        </div>
    </div>
</div>
<div *ngIf="loading" class="loader_for_notification_list">
    <loader_map></loader_map>
</div>
<ng-container *ngIf="data?.length && !loading">
    <cdk-virtual-scroll-viewport
        class="event__list scroll"
        [class.event__list&#45;&#45;within-page]="!disableNavigation"
        itemSize="190"
        minBufferPx="400"
        maxBufferPx="1600"
        #feed
        isScrollTop
        (isTop)="initialScrollPosition = $event"
        (scrolledIndexChange)="handlerScroll($event)"
    >
        <ng-container *ngIf="selectedCount">
            <ng-container *cdkVirtualFor="let event of data; templateCacheSize: 0">
                <div class="event__block" *ngIf="event.annotation">
                    <div class="event__block_name event__annotation">
                        {{ event.annotation }}
                    </div>
                </div>
                <cityair-event-item *ngIf="store.select(selectNotificationSettingById(event.item.settings_id)) | async as settings"
                    [notification]="event.item"
                    [settings]="settings"
                    [post]="(store.select(selectPostEntitiesById(getPostId(event.item))) | async)"
                    [todayDate]="todayDate"
                    [disableNavigation]="checkNotifyClickable(event.item)"
                    [configurable]="(store.select(selectMyRole) | async)?.edit_group"
                    (gotoMonitoringObject)="gotoMonitoringObject($event)"
                    (openSubscriptionSettings)="openSettingsFor(settings)"
                    (markNotificationAsVisited)="
                        markNotificationAsVisited(event.item)
                    "
                ></cityair-event-item>
            </ng-container>
        </ng-container>
    </cdk-virtual-scroll-viewport>
</ng-container>
<ng-container *ngIf="!filterText && !selectedCount && !loading">
     <div
        *ngIf="!(store.select(selectSettingsNotifyByType) | async)?.length && (store.select(selectMyRole) | async)?.edit_group else noEventsTemplate"
        class="event__list notifications_tips"
    >
        <div class="notifications_tips__img notifications_tips__img-{{ selectedTab?.type }}"></div>
        <div class="notifications_tips__text">
            <div class="notifications_tips__text-bold notifications_tips__text-padding_button">
                <ng-container [ngSwitch]="selectedTab?.type">
                    <ng-container *ngSwitchCase="NotificationType.pdkExcess">
                        {{ textsNotification.eventsHelpMessageMeasurements }}
                    </ng-container>
                    <ng-container *ngSwitchCase="NotificationType.deviceIncident">
                        {{ textsNotification.eventsHelpMessageService }}
                    </ng-container>
                </ng-container>
            </div>
            <div class="button_link button_link-blue" (click)="openSubscriptions()">
                {{ textsNotification.subscribeSuggestion }}
            </div>
        </div>
    </div>
    <ng-template #noEventsTemplate>
        <div
            class="event__list notifications_tips"
        >
            <div class="notifications_tips__not-available">
                {{ textsNotification.noEventsAvailable }}
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-container
    *ngIf="filterText && data?.length === 0"
>
    <div class="event__list">
        <div class="no-data-wrapper">
            <search-result-placeholder
                [text]="textsNotification.eventsNotFound"
                [clearText]="textClear" (clear)="filterText = ''"
            ></search-result-placeholder>
        </div>
    </div>
</ng-container>
<ca-scroll-top
    *ngIf="!initialScrollPosition && selectedCount"
    (click)="scrollTop()"
></ca-scroll-top>
