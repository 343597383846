<div class="control-point-create scroll" *ngIf="!loading">
    <div class="form scroll">
        <header-of-panel
            class="header-of-panel"
            [path]="navigationLink"
            (outputPath)="back()"
            [title]="isEditMode ? translateText.editControlPoint : translateText.newControlPoint"
        ></header-of-panel>
        <div class="name-wrapper">
            <static-tags
                class="post-edit__header-static-tags"
                typeTag="mo"
                [text]="(store.select(selectActiveConfig) | async)?.name"
            ></static-tags>
        </div>
        <form [formGroup]="ControlPointCreate" novalidate>
            <div class="wrapper-content">
                <ca-input
                    class="input-wrapper"
                    type="text"
                    controlName="name"
                    [form]="ControlPointCreate"
                    [valueForm]="getFormValue('name')"
                    [label]="translateText.nameLabel"
                    [textError]="getError('name')"
                ></ca-input>
            </div>
            <section-line [text]="locationOnTheMap"></section-line>
            <div class="info-wrapper">
                <div class="info">
                    <ca-info-icon
                            class="icon-info"
                            [withGradient]="true"
                        ></ca-info-icon>
                    <div class="info-description" [innerHTML]="infoText"></div>
                </div>
            </div>
            <div class="wrapper-content">
                <div class="wrapper-coordinate">
                    <input-for-coordinate
                        type="text"
                        controlName="lat"
                        [form]="ControlPointCreate"
                        [valueForm]="getFormValue('lat')"
                        [label]="translateText.latLabel"
                        [textError]="getError('lat')"
                        [positioning]="'left'"
                    ></input-for-coordinate>
                    <input-for-coordinate
                        type="text"
                        controlName="lon"
                        [form]="ControlPointCreate"
                        [valueForm]="getFormValue('lon')"
                        [label]="translateText.lngLabel"
                        [textError]="getError('lon')"
                        [positioning]="'right'"
                    ></input-for-coordinate>
                </div>
            </div>
            <ng-container *ngIf="showDomainError">
                <div class="domain-error">
                    {{translateText.domainError}}
                </div>
            </ng-container>
        </form>
    </div>
    <div class="btn-wrapper" [ngClass]="{ 'edit-mode': isEditMode }">
        <btn-extra (click)="showDeletePopup()" *ngIf="isEditMode">
            {{ deleteText }}
        </btn-extra>
        <btn-cancel (click)="back()">
            {{ cancelText }}
        </btn-cancel>
        <btn-accept
            [disabled]="ControlPointCreate.pristine || ControlPointCreate.invalid"
            (cbClick)="onSubmit()"
            [isLoading]="store.select(selectIsLoadingControlPointForm) | async"
        >
            <span *ngIf="isEditMode; else addText">{{ saveText }}</span>
            <ng-template #addText>{{ acceptText }}</ng-template>
        </btn-accept>
    </div>
    <div class="error-response-wrapper" *ngIf="errorResponse">
        <div class="title-status">{{ translateText.errorTitle }}</div>
        {{ errorResponse }}.&nbsp;{{ translateText.tryAgain }}.
    </div>
</div>
<div class="loading-wrapper" *ngIf="loading">
    <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
</div>
<ng-template #deleteControl>
    <popup-ask
        class="popup-ask"
        *ngIf="isShowDeletePopup"
        [@inOutAnimation]
        (cbAccept)="deleteAccept()"
        (cbClose)="deleteCancel()"
        [title]="translateText.titleDeletePopup(currentPoint.name)"
        [description]="translateText.bodyDeletePopup"
        [textAccept]="translateText.accept"
        [textCancel]="translateText.cancel"
        [showCloseIcon]="false"
    >
    </popup-ask>
</ng-template>
