import { Pipe, PipeTransform } from '@angular/core';
import { MeasureScheme, pdkmr } from '@libs/common';

@Pipe({ name: 'convertSchemaValue' })
export class ConvertSchemaValuePipe implements PipeTransform {
    transform(value: number, schema: MeasureScheme, mmt: string): number {
        let currentValue: number;
        switch (schema) {
            case MeasureScheme.c_mmhg_mg:
                currentValue = value / 1000;
                break;
            case MeasureScheme.mpc:
                currentValue = value / pdkmr[mmt];
                break;
            default:
                currentValue = value;
                break;
        }

        return currentValue;
    }
}
@Pipe({ name: 'isNeedRoundingEvent' })
export class IsNeedRoundingEventPipe implements PipeTransform {
    transform(value: number, numberAfterDot: number): number {
        if (value > 1) {
            return null;
        } else if (+value.toFixed(numberAfterDot) === 0 && value > 0) {
            return 1 / Math.pow(10, numberAfterDot);
        }
        return null;
    }
}
