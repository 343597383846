import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'input-radio-square',
    template: `<div class="input-radio {{ check ? 'input-radio_check' : '' }}"></div>`,
    styleUrls: ['input-radio-square.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputRadioSquareComponent {
    @Input() check: boolean;
}
