<div class="post-description scroll">
    <div>
        <div class="post-description__information-column">
            <information-column
                [title]="TEXTS.POSTS_AND_DEVICES.nameOnMap"
                [description]="MO.name"
            ></information-column>
            <kebab-menu (clickActions)="clickMenu($event)"></kebab-menu>
        </div>
        <div class="post-description__information-column">
            <information-column
                class="post-description__information-column--coordinate"
                [title]="TEXTS.POSTS_AND_DEVICES.coordinate"
                [description]="getCoordinate()"
            ></information-column>
            <information-column
                class="post-description__information-column--coordinate"
                [title]="TEXTS.POSTS_AND_DEVICES.location"
                [description]="MO.ancestor?.name"
            ></information-column>
        </div>

        <div class="post-description__information-column">
            <information-column
                [title]="TEXTS.POSTS_AND_DEVICES.timeZone"
                [description]="wrapperGMT"
            ></information-column>
        </div>
    </div>
    <section-line [text]="TEXTS.POSTS_AND_DEVICES.dataSources"></section-line>
    <ng-container *ngIf="device else noSourcesTemp">
        <div  class="post-description__data-sources">
                <static-tags
                    (click)="
                        openPage(
                            $event,
                            pages.networks +
                                '/' +
                                pages.devicesList +
                                '/' +
                                pages.details +
                                '/' +
                                device.serial_number
                        )
                    "
                    class="post-description__static-tags"
                    [isActiveElement]="true"
                    [text]="device?.serial_number"
                    [description]="device?.source_type"
                    [isOffline]="device?.source_type === DISPLAY_DEVICE_TYPE ? device?.is_offline : false"
                    [typeTag]="'device'"
                ></static-tags>

        </div>
    </ng-container>
    <ng-template #noSourcesTemp>
        <div class="post-description__not-data-sources"
            [class.post-description__not-data-sources--gray]="!isNoDataSources"
        >
            <ng-container *ngIf="isNoDataSources; else defaultTag">
                {{ TEXTS.CITY_CARD.noDataSources }}
            </ng-container>
            <ng-template #defaultTag>
                <static-tags typeTag="device" [description]="TEXTS.STATION_BLOCK.type.mo"></static-tags>
            </ng-template>
        </div>
    </ng-template>
    <section-line [text]="TEXTS.POSTS_AND_DEVICES.accessToData"></section-line>
    <div class="post-description__users" >
        <div class="post-description__users-text">
            <span [innerHTML]="TEXTS.POSTS_AND_DEVICES.allAdministratorsAndOperators"></span
            ><span
                *ngIf="usersList.length !== 0"
                [innerHTML]="TEXTS.POSTS_AND_DEVICES.asWellAsTheFollowing"
            ></span>
        </div>
        <ng-container>
            <div
            >
                <div class="post-description__users-static-tags">
                    <ng-container *ngFor="let log of usersList">
                        <static-tags
                            class="post-description__static-tags"
                            [text]="log"
                            [typeTag]="'user'"
                        ></static-tags>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #cardMenu>
    <popup-ask
        class="page__popup-ask"
        *ngIf="isShowDeletePopup"
        [@inOutAnimation]
        (cbAccept)="deleteAccept()"
        (cbClose)="deleteCancel()"
        [title]="TEXTS.POPUP_DELETE.title"
        [description]="TEXTS.POPUP_DELETE.body"
        [textAccept]="TEXTS.POPUP_DELETE.accept"
        [textCancel]="TEXTS.POPUP_DELETE.cancelNew"
    >
        <static-tags
            class="page__static-tags"
            [typeTag]="'mo'"
            [text]="nameTargetCard"
        ></static-tags>
    </popup-ask>
    <card-actions-list
        [@inOutAnimation]
        *ngIf="isShowMenu"
        [position]="menuPositionTop"
        (clickOutside)="closePopup($event)"
    >
        <card-actions-item
            *ngIf="isRU"
            data-cy="action-close"
            [text]="TEXTS.POSTS_AND_DEVICES.downloadTZA4Report"
            (click)="openPage($event, path + pages.postReportTza + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
            data-cy="action-close"
            [text]="TEXTS.POSTS_AND_DEVICES.edit"
            (click)="openPage($event, path + pages.postEdit + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-item
            data-cy="action-close"
            type="danger"
            [text]="TEXTS.POSTS_AND_DEVICES.deletePost"
            (click)="showPopup($event, 'delete')"
        ></card-actions-item>
    </card-actions-list>
</ng-template>
