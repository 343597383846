<div class="tabs-view-mode">
    <div
        *ngFor="let tab of tabs; let i = index"
        class="tabs-view-mode__tab"
        [attr.data-cy]="'tabs-view-mode__tab-' + i"
        [class.tabs-view-mode__tab_active]="tab === selectedTab"
        (click)="selectTab(tab)"
    >
        {{ tab.title }}
    </div>
</div>
