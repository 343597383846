import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectboxRadioComponent } from './selectbox-radio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemRadioModule } from '@libs/shared-ui';
import { SidebarModule } from '@libs/shared-ui';

@NgModule({
    exports: [SelectboxRadioComponent],
    declarations: [SelectboxRadioComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ItemRadioModule, SidebarModule],
})
export class SelectboxRadioModule {}
