import type { DomainConfigType } from '@libs/common';
import { NO2, PM10, PM25, SO2 } from '@libs/common';
const coordinatesMoscow = [
    [35.482452392578125, 56.42679977416992],
    [39.207977294921875, 56.42679977416992],
    [39.207977294921875, 54.11769485473633],
    [35.482452392578125, 54.11769485473633],
];
const coordinatesSeattle = [
    [-122.54881965140734, 47.73278768406766],
    [-122.14724489547791, 47.733997671403195],
    [-122.14653791397518, 47.48190904948997],
    [-122.54618463002393, 47.48070486354546],
];
const coordinateMoscowLens = [
    [37.355087115879314, 55.886273086563165],
    [37.84327974867705, 55.886275873563214],
    [37.8418054471976, 55.612351100762965],
    [37.35657129565777, 55.612348328038706],
];
export const DOMAINS_FORECASTS: Record<string, DomainConfigType> = {
    locality_1: {
        slug: 'ru_moscow_5km',
        substances: [PM25, PM10, NO2, SO2],
        coordinates: coordinatesMoscow,
        extent: [
            coordinatesMoscow[0][0],
            coordinatesMoscow[2][1],
            coordinatesMoscow[1][0],
            coordinatesMoscow[0][1],
        ],
    },
    locality_77: {
        slug: 'us_seattle_100m',
        substances: [PM10],
        coordinates: coordinatesSeattle,
        start: '2023-11-09T08:00:00Z',
        end: '2023-11-10T07:00:00Z',
        extent: [
            coordinatesSeattle[0][0],
            coordinatesSeattle[2][1],
            coordinatesSeattle[1][0],
            coordinatesSeattle[0][1],
        ],
    },
};
export const LENS_FORECASTS: Record<string, DomainConfigType> = {
    locality_1: {
        timeStep: 1200 * 1000, // 20 min
        slug: 'ru_moscow_lens',
        substances: [PM25],
        coordinates: coordinateMoscowLens,
        start: '2024-11-15T21:00:00Z',
        end: '2024-11-16T20:00:00Z',
        extent: [
            coordinateMoscowLens[0][0],
            coordinateMoscowLens[2][1],
            coordinateMoscowLens[1][0],
            coordinateMoscowLens[0][1],
        ],
        altitudeIndex: '01',
    },
};
