import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MonitoringObjectPlateComponent } from './monitoring-object-plate.component';
import { KebabMenuModule } from '@libs/shared-ui';
import { StaticTagsModule } from '@libs/shared-ui';
import { ValueCircleModule } from '@libs/shared-ui';

@NgModule({
    imports: [CommonModule, KebabMenuModule, StaticTagsModule, ValueCircleModule],
    exports: [MonitoringObjectPlateComponent],
    declarations: [MonitoringObjectPlateComponent],
})
export class MonitoringObjectPlateModule {}
