import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LinkOnDocumentComponent } from './link-on-document.component';

@NgModule({
    imports: [CommonModule],
    exports: [LinkOnDocumentComponent],
    declarations: [LinkOnDocumentComponent],
})
export class LinkOnDocumentModule {}
