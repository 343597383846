<div class="popup">
    <cross-button
        class="popup__close"
        size="large"
        (click)="close()"
    ></cross-button>
    <div class="popup__title" [innerHTML]="text.infoPopUp.title"></div>
    <div class="popup__content mb-20" [innerHTML]="text.infoPopUp.content"></div>
    <div class="popup__content mb-30" [innerHTML]="text.infoPopUp.content1"></div>
    <div class="popup__title small mb-20" [innerHTML]="text.infoPopUp.title2"></div>
    <div class="scale-wrapper scale mb-30">
        <div
            class="block corr-{{i + 1}}"
            *ngFor="let item of text.infoPopUp.values; let i = index"
            (mouseenter)="tooltipText = text.infoPopUp.labels[i]"
            [innerHtml]="item"
            caTooltip
            caTooltipPlacement="top"
            [caTooltipFollowPointer]="false"
            [caTooltipTemplate]="corrText"
        >
        </div>
    </div>
    <div class="popup__content" [innerHTML]="text.infoPopUp.title3"></div>
</div>
<ng-template #corrText>
    <ca-tooltip class="tooltip-info" [pointerColor]="'rgb(56, 56, 56)'">
        <div class="text-tooltip" [innerHtml]="tooltipText"></div>
    </ca-tooltip>
</ng-template>
