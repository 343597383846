export const WIND_DIR_BY_POST = {
    post_6: {
        velocity: 3,
        direction: 'E',
        daily_stats: {
            N: 0,
            NE: 4,
            E: 13,
            SE: 7,
            S: 0,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_9: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 7,
            E: 5,
            SE: 10,
            S: 1,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_30: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 7,
            E: 5,
            SE: 10,
            S: 1,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_61: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 7,
            E: 5,
            SE: 10,
            S: 1,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_62: {
        velocity: 3,
        direction: 'E',
        daily_stats: {
            N: 0,
            NE: 2,
            E: 13,
            SE: 9,
            S: 0,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_475: {
        velocity: 3,
        direction: 'E',
        daily_stats: {
            N: 0,
            NE: 5,
            E: 12,
            SE: 7,
            S: 0,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_494: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 7,
            E: 5,
            SE: 10,
            S: 1,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_495: {
        velocity: 3,
        direction: 'E',
        daily_stats: {
            N: 0,
            NE: 2,
            E: 14,
            SE: 8,
            S: 0,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_528: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 7,
            E: 5,
            SE: 10,
            S: 1,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_543: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 8,
            E: 5,
            SE: 10,
            S: 0,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
};
