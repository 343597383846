import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { AreaChartComponent } from './area-chart.component';

@NgModule({
    imports: [CommonModule, BaseChartDirective],
    declarations: [AreaChartComponent],
    exports: [AreaChartComponent],
})
export class AreaChartModule {}
