import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isRU } from '@libs/common';
import { environment } from 'environments/environment';
import { VangaAuthService } from '@libs/shared-store';
import { EXCLUDE_CREATE_FORM_KEY, IReport, REPORT_TEMPLATE_PARAMS_KEY, Reports } from '@libs/common';
import { Update } from '@ngrx/entity';

@Injectable({ providedIn: 'root' })
export class ReportDataService extends DefaultDataService<IReport> {
    private lang = isRU ? 'ru' : 'en';
    private urlPath = this.urlGenerator.collectionResource('Report', '');
    constructor(
        http: HttpClient,
        private urlGenerator: HttpUrlGenerator,
        private vangaAuthService: VangaAuthService
    ) {
        super('Report', http, urlGenerator, { root: environment.vanga_api_url + '/socrates/v1' });
    }

    getAll(): Observable<IReport[]> {
        throw new Error('not implemented');
    }

    getById(id: string): Observable<Reports> {
        const httpOptions = this.getHttpOptions();
        const url = this.urlPath + id + '/';

        return this.http
            .get(url, httpOptions)
            .pipe(map((response: any) => new Reports(response as IReport)));
    }

    add(data: IReport): Observable<Reports> {
        const httpOptions = this.getHttpOptions();
        const params = this.getCreateParams(data);
        const formData = new FormData();
        formData.append('request', JSON.stringify(params));
        if (data?.params[REPORT_TEMPLATE_PARAMS_KEY.fileupload]) {
            formData.append('file', data?.params[REPORT_TEMPLATE_PARAMS_KEY.fileupload]);
        }

        return this.http
            .post(this.urlPath, formData, httpOptions)
            .pipe(map((response: any) => new Reports(response as IReport)));
    }

    update(data: Update<IReport>): Observable<Reports> {
        const httpOptions = this.getHttpOptions();
        const urlPath = this.urlPath + data.id + '/';

        return this.http
            .put(urlPath, data.changes, httpOptions)
            .pipe(map((response: any) => new Reports(response as IReport)));
    }

    delete(key: number | string): Observable<number> {
        const httpOptions = this.getHttpOptions();
        const urlPath = this.urlPath + key + '/';

        return this.http
            .delete(urlPath, httpOptions)
            .pipe(map((response: any) => response as number));
    }

    getWithQuery(params: string | QueryParams): Observable<IReport[]> {
        const httpOptions = this.getHttpOptions(params);

        return this.http
            .get(this.urlPath, httpOptions)
            .pipe(
                map((featureResponse: any) =>
                    (featureResponse as IReport[])?.map((r) => new Reports(r))
                )
            );
    }

    private getHttpOptions(params?: string | QueryParams | HttpParams): any {
        const token = this.vangaAuthService.getAccessToken();
        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': this.lang,
        };
        if (params) {
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }

    private getCreateParams(data: IReport): any {
        const defaultParams = {
            kind: data.template_id.toString(),
            post_ids: data.params.moIds,
            start_date: data.params.timeBegin,
            finish_date: data.params.timeEnd,
            name: data.name,
            comment: data.comment,
            group_id: data.params.group_id,
            timezone: data.params.timezone,
        };
        const params = new Object();
        Object.values(REPORT_TEMPLATE_PARAMS_KEY).forEach((field) => {
            if (
                data.params.hasOwnProperty(field) &&
                EXCLUDE_CREATE_FORM_KEY.indexOf(field) === -1 &&
                field !== REPORT_TEMPLATE_PARAMS_KEY.fileupload
            ) {
                params[field] = data.params[field];
            }
        });

        return { ...params, ...defaultParams };
    }
}
