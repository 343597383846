import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import {
    AqiType,
    ANIMATION_OPACITY,
    Device,
    getDigitsAfterDot,
    Post,
    isRU,
    MAIN_PAGES,
    TEXTS,
} from '@libs/common';

import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { OffPanelPopupService } from '@libs/shared-store';
import {
    canClickOnMarker,
    getMarkerState,
    selectPostCurrentValue,
    selectSchemeAndMeasure,
    selectZone,
} from '@cityair/modules/core/store/selectors';
import { clickFromApToMo, openInstantAqiFaq, selectMo } from '@cityair/modules/core/store/actions';
import { getColorFromZone } from '@cityair/utils/utils';
import { selectUserRoleId, selectMyRole } from '@libs/shared-store';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';
import { getComparedListObject } from '@cityair/modules/core/store/compared-list/compared-list.selectors';
import {
    SharedPostActions,
    selectIsLoadingPosts,
    selectPostList,
    selectTotalDevices,
} from '@libs/shared-store';
import { Router } from '@angular/router';
import { OBSERVER_ID } from '@cityair/config';

export class CardMo {
    id: string;
    name: string;
    city: string;
    serialNumber?: string;
    device?: Device;
    deviceSourcesName: string;
    isConnectedDevices?: boolean;
    isNoDataSources?: boolean;
    lastConnectedDevice?: string;
}

@Component({
    selector: 'posts-list',
    templateUrl: './posts-list.component.html',
    styleUrls: ['./posts-list.component.less', '../../posts-and-devices.common-styles.less'],
    animations: ANIMATION_OPACITY,
})
export class PostsListComponent implements OnInit, OnDestroy {
    pages = NETWORK_PAGES;
    cardMo: CardMo[] = [];
    TEXTS = TEXTS;
    AqiType = AqiType;
    isShowMenu = false;
    isRU = isRU;
    isShowNotDate = false;
    isShowResultPlaceholder = false;
    menuPositionTop = 0;
    posts: Post[] = [];
    path = `${NETWORK_PAGES.networks}/${NETWORK_PAGES.postsList}/`;
    idTargetCard = '';
    nameTargetCard = '';
    searchQuery = '';
    isShowDeletePopup = false;
    private popupOpenerElement: HTMLElement;
    public isLoading = true;

    getComparedListObject = (id: string) => this.store.select(getComparedListObject(id));
    getMarkerState = (id: string) => this.store.select(getMarkerState(id));
    getColorFromZone = getColorFromZone;
    selectZone = selectZone;
    selectTotalDevices = selectTotalDevices;
    selectMyRole = selectMyRole;
    selectPostCurrentValue = selectPostCurrentValue;
    selectUserRole = selectUserRoleId;
    OBSERVER_ID = OBSERVER_ID.toString();
    selectIsLoadingPosts = selectIsLoadingPosts;
    postDeviceText = TEXTS?.POSTS_AND_DEVICES;
    subscriptions = [];
    globalMmt: string;
    globalMmtName: string;
    @ViewChild('cardMenu', { static: true }) cardMenu: TemplateRef<HTMLDivElement>;
    digitsAfterDot = 0;
    constructor(
        public postsAndDevicesFacade: PostsAndDevicesFacade,
        public popupProvider: OffPanelPopupService,
        public store: Store,
        private router: Router
    ) {
        const digitDot = this.store.select(selectSchemeAndMeasure).subscribe((value) => {
            this.globalMmt = value.mmt;
            this.globalMmtName = TEXTS.NAMES[this.globalMmt];
            this.digitsAfterDot = getDigitsAfterDot(value.scheme, value.mmt);
        });
        this.subscriptions.push(digitDot);
    }

    ngOnInit() {
        this.getStore();
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getStore() {
        const postsAndSources = this.store.select(selectPostList).subscribe((data) => {
            if (data) {
                this.isLoading = false;
                this.cardMo = data;
                this.isShowNotDate = data.length === 0 ? true : false;
                this.isShowResultPlaceholder = true;
            }
        });
        this.subscriptions.push(postsAndSources);
    }

    showDetails(data: { type: string; id: string }) {
        this.store.dispatch(clickFromApToMo({ moObjId: data.id }));
        const page = data.type == 'MO' ? NETWORK_PAGES.postsList : NETWORK_PAGES.devicesList;
        this.postsAndDevicesFacade.openPage(
            NETWORK_PAGES.networks + '/' + page + '/' + NETWORK_PAGES.details + '/' + data.id
        );
    }

    textChangeIn(event) {
        this.searchQuery = event;
    }

    openPage(target: any, pageName: string) {
        if (target != null) {
            this.closePopup(target);
        }
        this.postsAndDevicesFacade.openPage(pageName);
    }

    clickMenu({ target, positionTop }, TargetId: string) {
        this.idTargetCard = TargetId;
        const id = TargetId;
        this.store.dispatch(selectMo({ id }));
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.cardMenu, () => (this.isShowMenu = false));
        this.popupOpenerElement = target;
        this.menuPositionTop =
            document.documentElement.clientHeight - 242 < positionTop
                ? document.documentElement.clientHeight - 242
                : positionTop;
        this.isShowMenu = true;
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    showPopup(target, type) {
        if (type === 'delete') {
            this.closePopup(target);
            this.nameTargetCard = this.cardMo.find((ic) => ic.id == this.idTargetCard).name;
            this.isShowDeletePopup = true;
        } else if (type === 'redirectReportAll') {
            this.closePopup(target);
            this.popupProvider.confirm(() => {});
            this.popupProvider.setTemplate(this.cardMenu, () => (this.isShowMenu = false));
        }
    }

    deleteAccept() {
        this.store.dispatch(SharedPostActions.deletePost());
        this.isShowDeletePopup = false;
    }

    deleteCancel() {
        this.isShowDeletePopup = false;
    }

    redirectReportModules() {
        this.router.navigate([`/${MAIN_PAGES.reports}`]);
    }

    openAqiFaqLink(aqiType: AqiType) {
        if (aqiType === AqiType.instant) {
            this.store.dispatch(openInstantAqiFaq());
        }
    }

    clickToMarker(cm): void {
        this.store
            .select(canClickOnMarker(cm.id))
            .pipe(first())
            .subscribe((canClick) => {
                if (canClick) {
                    this.store.dispatch(clickFromApToMo({ moObjId: cm.id }));
                }
            });
    }
}
