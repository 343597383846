 <div (clickOutside)="showDropDown = false" class="input_date_range analysis-map-elements">
    <input-for-dropdown
        [inputValue]="activeRun | impactRunTime"
        [(isOpenDropdown)]="showDropDown"
    ></input-for-dropdown>
    <div class="selectbox-wrapper" *ngIf="showDropDown">
        <div class="list-wrapper">
            <div class="list scroll scroll-grey">
                <div class="item" *ngFor="let run of runs" (click)="select(run)" [ngClass]="{'selected': run.id === activeRun.id }">
                    <div class="period">{{run | impactRunTime}}</div>
                    <item-radio
                        class="item__checkmark"
                        [check]="run.id === activeRun.id"
                    ></item-radio>
                </div>
            </div>
        </div>
    </div>
</div>
