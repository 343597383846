import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ca-layer-button',
    templateUrl: 'layer-button.component.html',
    styleUrls: ['layer-button.component.less'],
})
export class LayerButtonComponent {
    @Input() type;
    @Input() size = 'small';
    @Input() active;

    @Output() clickOnIcon = new EventEmitter<void>();
}
