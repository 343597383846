import { createAction, props } from '@ngrx/store';
import { PostInfo, PostsMeasurementsResponse, StatInfo } from '../services/api';
import type { NotificationEvent } from './props.type';
import { analyticType, MMT_INDOOR } from '@cityair/modules/indoor/config';

enum IndoorActions {
    IndoorUpdateTimeIndex = '[IndoorActions] IndoorUpdateTimeIndex',
    IndoorShowEventOnChart = '[IndoorActions] IndoorShowEventOnChart',

    SelectPoint = '[IndoorActions] SelectPoint',
    DeselectPoint = '[IndoorActions] DeselectPoint',
    OpenDownloadPopup = '[IndoorActions] OpenDownloadPopup',
    Init = '[IndoorActions] Init',
    Destroy = '[IndoorActions] Destroy',
    SelectMmt = '[IndoorActions] SelectMmt',
    ChangeAnalyticPage = '[IndoorActions] ChangeAnalyticPage',

    // api request
    UpdateTimeRange = '[IndoorActions] UpdateTimeRange',
    UpdateIntervalData = '[IndoorActions] UpdateIntervalData', // для дозагрузки данных

    // api response
    AllPostsResponse = '[IndoorActions] AllPostsResponse',
    PostInfoResponse = '[IndoorActions] AnalyticResponse',
    StatInfoResponse = '[IndoorActions] StatInfoResponse',
    // header display global link
    SetHeaderGlobalLink = '[IndoorActions] SetHeaderGlobalLink',
    PostErrorResponse = '[IndoorActions] PostErrorResponse',
    SetIsPointsLoading = '[Indoor Actions] SetIsPointsLoading',
    InitOutdoorPins = '[Indoor Actions] InitOutdoorPins',
    SetOutdoorResponse = '[Indoor Actions] setOutdoorResponse',
    SelectPointOutdoor = '[Indoor Actions] SelectPointOutdoor',
}

export const updateTimeRange = createAction(
    IndoorActions.UpdateTimeRange,
    props<{ timeBegin: number; timeEnd: number }>()
);
export const allPostsResponse = createAction(
    IndoorActions.AllPostsResponse,
    props<PostsMeasurementsResponse>()
);
export const setOutdoorResponse = createAction(
    IndoorActions.SetOutdoorResponse,
    props<PostsMeasurementsResponse>()
);
export const updateIntervalData = createAction(IndoorActions.UpdateIntervalData);
export const postInfoResponse = createAction(IndoorActions.PostInfoResponse, props<PostInfo>());
export const selectPoint = createAction(IndoorActions.SelectPoint, props<{ id: number }>());
export const selectPointOutdoor = createAction(
    IndoorActions.SelectPointOutdoor,
    props<{ id: number }>()
);
export const deselectPoints = createAction(IndoorActions.DeselectPoint);
export const changeAnalyticPage = createAction(
    IndoorActions.ChangeAnalyticPage,
    props<{ payload: analyticType }>()
);
export const indoorUpdateTimeIndex = createAction(
    IndoorActions.IndoorUpdateTimeIndex,
    props<{ index: number }>()
);
export const indoorShowEventOnChart = createAction(
    IndoorActions.IndoorShowEventOnChart,
    props<NotificationEvent>()
);
export const openDownloadPopup = createAction(IndoorActions.OpenDownloadPopup);
export const init = createAction(IndoorActions.Init);
export const destroy = createAction(IndoorActions.Destroy);
export const selectMmt = createAction(IndoorActions.SelectMmt, props<{ payload: MMT_INDOOR }>());
export const statInfoResponse = createAction(
    IndoorActions.StatInfoResponse,
    props<{ payload: StatInfo }>()
);
export const setHeaderGlobalLink = createAction(
    IndoorActions.SetHeaderGlobalLink,
    props<{ payload: boolean }>()
);
export const setPostErrorResponse = createAction(IndoorActions.PostErrorResponse);
export const setIsPointsLoading = createAction(
    IndoorActions.SetIsPointsLoading,
    props<{ payload: boolean }>()
);
export const initOutdoorPins = createAction(
    IndoorActions.InitOutdoorPins,
    props<{ ids: number[] }>()
);
