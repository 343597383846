import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { getAssetPath, GroupExtConfigName, TEXTS } from '@libs/common';
import { environment } from 'environments/environment';
import { PostsMeasurementsResponse } from '@cityair/modules/indoor/services/api';
import { Store } from '@ngrx/store';
import { selectGroupId, setModuleConfig } from '@libs/shared-store';
import { GroupFeaturesService } from '@libs/shared-store';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { indoorWidgetModuleConfig } from '@cityair/modules/indoor-widget/module-config';
const API_URL = environment.api_url + '/harvester/v2/posts/measurements';
const UPDATE_INTERVAL_MS = 5 * 60 * 1000;

interface PostsMeasurementsProps {
    timeBegin: number;
    timeEnd: number;
    id: string;
}
const transformProps = (props: PostsMeasurementsProps) => ({
    ids: props.id,
    interval: '5m',
    date__gt: moment(props.timeBegin).toISOString(),
    date__lt: moment(props.timeEnd).toISOString(),
    with_posts: 'true',
    with_iaq: 'true',
    limit: '10',
    measure_scheme: 'default',
});
@Component({
    selector: 'indoor-widget',
    templateUrl: './indoor-widget.component.html',
    styleUrls: ['./indoor-widget.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndoorWidgetComponent implements OnInit, OnDestroy {
    public loading = true;
    public name = '';
    public indoorAqi = null;
    public lastTime: string;
    public showErrorMsg = false;
    public textStatus = '';
    public statusTextOffline = TEXTS.INDOOR_WIDGET_STATUS.offlineStatus;
    public errorSettingWidget = '';
    public noDataText = TEXTS.INDOOR_WIDGET_STATUS.noData;
    public className = 'low';
    readonly _interval: NodeJS.Timeout;
    private currentPostId: string;
    public ngDestroyed$ = new Subject<void>();
    constructor(
        private http: HttpClient,
        private ref: ChangeDetectorRef,
        private store: Store,
        private groupFeaturesService: GroupFeaturesService
    ) {
        this._interval = setInterval(() => {
            this.getData();
        }, UPDATE_INTERVAL_MS);
        store
            .select(selectGroupId)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v)
            )
            .subscribe((data) => {
                this.currentPostId = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.indoorWidgetPostId
                );
                if (this.currentPostId) {
                    this.getData();
                } else {
                    this.errorSettingWidget = TEXTS.INDOOR_WIDGET_STATUS.notSettings;
                    this.loading = false;
                    this.ref.markForCheck();
                }
            });
    }

    getAsset = getAssetPath;

    ngOnInit() {
        this.store.dispatch(setModuleConfig({ config: indoorWidgetModuleConfig }));
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
        if (this._interval) {
            clearInterval(this._interval);
        }
    }

    private getData() {
        const paramsInit = this.getParams();
        const params = transformProps(paramsInit);
        return this.http.get(API_URL, { params: params }).subscribe({
            next: (data: any) => {
                this.showErrorMsg = false;
                this.loading = false;
                if (data?.data.length) {
                    const result = data as PostsMeasurementsResponse;
                    this.name = result.posts[0].name;
                    this.indoorAqi =
                        result.data[result.data.length - 1].aqi?.indoorAqi?.valueScaled10 ?? null;
                    const lastDate = result.data[result.data.length - 1].date ?? null;
                    const date = lastDate ? moment(lastDate) : moment();
                    this.lastTime = `${date.format('dddd')}, ${date.format('D')} ${date
                        .format('MMM')
                        .substring(0, 3)}`;
                    this.textStatus = this.getStatus();
                    this.className = this.updateClass();
                    /* if (lastDate) {
                        this.lastTime += ` ${date.format('HH:mm')}`;
                    } */
                }
                this.ref.markForCheck();
            },
            error: (error: HttpErrorResponse) => {
                this.showErrorMsg = true;
                this.loading = false;
                this.ref.markForCheck();
            },
        });
    }

    private getParams(): PostsMeasurementsProps {
        return {
            timeBegin: moment.utc().subtract(1, 'hours').valueOf(),
            timeEnd: moment.utc().valueOf(),
            id: this.currentPostId,
        };
    }

    private getStatus(): string {
        const i = this.transformAqiGradation(this.indoorAqi);
        return TEXTS.INDOOR_WIDGET_STATUS.statusAQI[i];
    }

    private updateClass(): string {
        const classArray = ['low', 'middle', 'high'];
        const i = this.transformAqiGradation(this.indoorAqi);
        return classArray[i] ?? 'low';
    }

    private transformAqiGradation(aqi: number): 0 | 1 | 2 {
        if (aqi <= 3) {
            return 0;
        }
        if (aqi <= 6) {
            return 1;
        }
        if (aqi <= 10) {
            return 2;
        }
    }
}
