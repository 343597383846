<plumes-map-elements></plumes-map-elements>
<div class="timeline-plumes-wrapper">
    <div class="timeline__wrapper timeline__wrapper-displayFlex">
        <div
            class="timeline__top"
            [class.timeline-panel]="store.select(selectSidebarIsOpen) | async"
        >
            <ng-container *ngIf="store.select(selectPlumesHeights) | async as heights">
                <shared-ui-heights-selector
                    class="heights-selector"
                    *ngIf="heights.length > 1"
                    [heights]="heights"
                    [value]="store.select(selectPlumesCurrentHeight) | async"
                    [isLoading]="isLoading"
                    (changeHeight)="store.dispatch(setHeight({ payload: $event }))"
                ></shared-ui-heights-selector>
            </ng-container>

            <cs-switch-button *ngIf="store.select(isWindLayerAvailable) | async"
                    class="switch-button"
                    [text]="textWindLayer"
                    [active]="showWindOnMap"
                    [disable]="store.select(selectIsDisableWindButton) | async"
                    (activeChange)="toggleWindLayer()"
                    caTooltip
                    caTooltipPlacement="top-left"
                    [caTooltipAllowHover]="true"
                    [caTooltipTemplate]="tooltipWind"
                >
            </cs-switch-button>
            <map-style-selector *ngIf="store.select(selectMapStyleTypes) | async as types"
                [types]="types"
                [currentType]="store.select(selectCurrentMapStyleType) | async"
                [loading]="store.select(selectMapStyleLoading) | async"
                (setType)="store.dispatch(setMapStyleType({payload: $event}))"
            ></map-style-selector>
            <color-legend
                class="legend-timeline"
                *ngIf="store.select(selectPlumesSchemaZones) | async as val"
                [unit]="textScheme[val.scheme][val.mmt]"
                [measure]="textNames[val.mmt]"
                [colors]="(store.select(selectPlumesZone) | async)?.color"
                [values]="(store.select(selectPlumesZone) | async)?.zone"
                [digitsAfterDot]="getDigitsAfterDot(val.scheme, val.mmt)"
            ></color-legend>
            <timeline-panel
                [isCityMode]="store.select(selectIsCityMode) | async"
                [sidebarIsOpened]="store.select(selectSidebarIsOpen) | async"
                [currentTime]="store.select(selectPlumeTime) | async"
                [dates]="store.select(selectDatesPlumes) | async"
                [features]="store.select(selectChartData) | async"
                [initSelectMeasurement]="store.select(currentPlumesMmt) | async"
                [labelMode]="labelMode"
                [showNow]="true"
                [pdk]="store.select(selectPdkForChart) | async"
                [aqiTooltipTemplate]="aqiTooltipTemplate"
                [chartMinMax]="chartMinMax"
                [measureScheme]="store.select(selectMeasureScheme) | async"
                [timezone]="store.select(selectUserTimezone) | async"
                (goToCity)="goToCity($event)"
                (removeFromComparison)="store.dispatch(removeFromComparison({ id: $event }))"
                (changeTimeIndex)="store.dispatch(updateTimeIndex({ payload: $event }))"
                (setPlayingState)="getPlayingState($event)"
            >
            </timeline-panel>
            <ng-template #aqiTooltipTemplate>
                <cs-aqi-details-tooltip
                    *ngIf="
                        isRU && (store.select(selectGlobalMeasurement) | async) === AqiType.instant
                    "
                    (openDetailsLink)="openAqiFaqLink(AqiType.instant)"
                ></cs-aqi-details-tooltip>
            </ng-template>
        </div>
    </div>
    <ng-template #tooltipWind>
            <ng-container
                *ngIf="isPlayerTimeline else windTooltipTemplate"
            >
               <ca-tooltip class="tooltip-wind">
                   <div  class="text-tooltip" [innerHtml]="windTooltipTexts.byPlayer"></div>
               </ca-tooltip>

            </ng-container>
            <ng-template #windTooltipTemplate>
                <ng-container
                    *ngIf="store.select(selectPlumesHeights) | async as heights"
                >
                    <ng-container *ngIf="heights.length > 1">
                        <ca-tooltip class="tooltip-wind">
                           <div class="text-tooltip" [innerHtml]="windTooltipTexts.byHeight"></div>
                       </ca-tooltip>
                    </ng-container>
                </ng-container>
            </ng-template>
    </ng-template>
</div>
