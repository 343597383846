import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TabModel } from '@libs/common';

@Component({
    selector: 'ca-page-tabs',
    templateUrl: 'page-tabs.component.html',
    styleUrls: ['page-tabs.component.less'],
})
export class PageTabs {
    @Input() tabs: TabModel[];
    @Input() selectedTab: TabModel;
    @Input() isFullWidth = false;
    @Input() isFullHeight = false;
    @Output() showTab = new EventEmitter<TabModel>();

    selectTab(tab: TabModel) {
        this.showTab.emit(tab);
    }
}
