import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MapStyleType } from '@libs/common';
import { LANGUAGE } from '@libs/common';

interface Attrib {
    text: string;
    link?: string;
}
@Component({
    selector: 'shared-ui-ol-attribution',
    templateUrl: './attribution.component.html',
    styleUrls: ['./attribution.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributionComponent implements OnChanges {
    @Input() type: MapStyleType;
    attribs: Attrib[] = [];
    collapse = true;
    showYandexLogo = false;
    lang = LANGUAGE;
    ngOnChanges(changes: SimpleChanges) {
        if (changes?.type) {
            this.showYandexLogo = this.type === MapStyleType.yandex;
            if (this.type === MapStyleType.yandex) {
                this.attribs = [
                    {
                        text: 'CityAir |',
                    },
                    { text: 'Yandex', link: 'https://yandex.ru/maps' },
                ];
            } else if (this.type === MapStyleType.osm) {
                this.attribs = [
                    {
                        text: 'CityAir |',
                    },
                    {
                        text: 'OpenStreetMap',
                        link: 'https://www.openstreetmap.org/about/',
                    },
                ];
            } else if (
                this.type === MapStyleType.outdoor ||
                this.type === MapStyleType.satellite ||
                this.type === MapStyleType.cityair
            ) {
                this.attribs = [
                    {
                        text: 'CityAir |',
                    },
                    {
                        text: 'Mapbox',
                        link: 'https://www.mapbox.com/',
                    },
                ];
            }
        }
    }
}
