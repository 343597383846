import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { OffPanelPopupService } from '@libs/shared-store';
import { ANIMATION_OPACITY, TEXTS, Device, Post } from '@libs/common';

import { selectDeviceList, selectPostEntitiesById } from '@libs/shared-store';
import { Store } from '@ngrx/store';
import { selectDevice } from '@cityair/modules/core/store/actions';
import { takeUntil } from 'rxjs/operators';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';
import { Subject } from 'rxjs';

export interface Devices {
    arrSerialNumber: string[];
    arrMoName: string[];
}

export interface DevicesObj extends Device {
    arrSerialNumber?: string[];
    arrMoName?: string[];
    post: Post;
}
class ArrTagDevice {
    id?: string;
    type: 'device' | 'user' | 'mo';
    text: string;
    description?: string;
    isOnline: boolean;
}

@Component({
    selector: 'devices-list',
    templateUrl: './devices-list.component.html',
    styleUrls: ['./devices-list.component.less', '../../posts-and-devices.common-styles.less'],
    animations: ANIMATION_OPACITY,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesListComponent implements OnInit, OnDestroy {
    pages = NETWORK_PAGES;
    devicesList: DevicesObj[] = [];
    path = NETWORK_PAGES.networks + '/' + NETWORK_PAGES.devicesList + '/';
    isShowMenu = false;
    menuPositionTop = 0;
    isShowNotDate = false;
    TEXTS = TEXTS;
    idTargetCard = '';
    searchQuery = '';
    selectedPost: ArrTagDevice[];
    isShowSearchResultPlaceholder = false;
    private popupOpenerElement: HTMLElement;
    public ngDestroyed$ = new Subject<void>();
    selectPostEntitiesById = selectPostEntitiesById;
    constructor(
        public postsAndDevicesFacade: PostsAndDevicesFacade,
        public popupProvider: OffPanelPopupService,
        public store: Store<any>,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.store
            .select(selectDeviceList)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(async (smd) => {
                this.devicesList = smd;
                this.isShowNotDate = this.devicesList.length === 0;
                this.cdr.detectChanges();
            });
    }
    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    @ViewChild('cardMenu', { static: true }) cardMenu: TemplateRef<HTMLDivElement>;

    openPage(target: any, pageName: string) {
        const serialNumber = this.idTargetCard;
        this.store.dispatch(selectDevice({ serialNumber }));
        if (target != null) {
            this.closePopup(target);
        }
        this.postsAndDevicesFacade.openPage(pageName);
    }

    textChangeIn(event) {
        this.searchQuery = event;
    }

    showDetailsMo(data: { type: string; id: string }) {
        const page = data.type == 'MO' ? NETWORK_PAGES.postsList : NETWORK_PAGES.devicesList;
        this.postsAndDevicesFacade.openPage(
            NETWORK_PAGES.networks + '/' + page + '/' + this.pages.details + '/' + data.id
        );
    }

    clickMenu({ target, positionTop }, id: string) {
        this.idTargetCard = id;
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.cardMenu, () => (this.isShowMenu = false));
        this.popupOpenerElement = target;
        this.menuPositionTop = positionTop;
        this.isShowMenu = true;
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    showPopup(target, type) {}
}
