<forecast-map></forecast-map>
<div class="forecast-wrapper" [ngClass]="{ 'open-sidebar': store.select(selectSidebarIsOpen) | async }">
    <div class="header">
        <div class="title-row">
            <div class="title">{{ translateText.mainTitle }}</div>
        </div>
        <div class="tabs-wrapper">
            <ca-page-tabs
                class="page__page-tabs"
                [tabs]="tabs"
                [selectedTab]="getActiveTab()"
                (showTab)="openPage($event)"
            ></ca-page-tabs>
        </div>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>
<ca-sidebar-toggle-button
    class="forecast-toggle-button"
    [ngClass]="{ 'open-sidebar': store.select(selectSidebarIsOpen) | async }"
    [isActive]="store.select(selectSidebarIsOpen) | async"
    (toggle)="toggleSideBar()"
></ca-sidebar-toggle-button>