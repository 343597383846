import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { TEXTS } from '@libs/common';

@Component({
    selector: 'cs-feedback-error',
    templateUrl: 'feedback-error.component.html',
    styleUrls: ['feedback-error.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackErrorComponent {
    @Input() title: string;

    @Input() message: string;

    @Output() closeView = new EventEmitter<void>();

    TEXTS = TEXTS;

    close() {
        this.closeView.emit();
    }
}
