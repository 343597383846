<div>
    <ng-container *ngIf="store.select(selectIsAllowPublicForecast) | async">
        <!-- Exclude public forecast button when Forecast module is enabled -->
        <ca-layer-button *ngIf="store.select(selectMmtsPublicForecast) | async as mmts"
            class="layer_button"
            [type]="LayerButtonIconType.FORECAST"
            [active]="isActiveModelling"
            (clickOnIcon)="
                isActiveModelling ? disableModelling() : enableModelling()
            "
        >
            <ca-layer-button-item
                *ngFor="let opt of mmts"
                [label]="TEXTS.NAMES[opt]"
                [active]="(store.select(selectActiveMmtPublicForecast) | async) === opt"
                (click)="store.dispatch(setActiveMmt({payload: opt}))"
            ></ca-layer-button-item>
        </ca-layer-button>
    </ng-container>
    <ng-container
        *ngIf="(routingService.pageChange$ | async) === 'plumes'">
        <ca-layer-button
            class="layer_button"
           [type]="LayerButtonIconType.PLUME"
            [active]="showPlumesLayer"
            (clickOnIcon)="togglePlumesLayer()"
        >
            <ca-layer-button-item
                [label]="TEXTS.NAMES[currentMeasure] || currentMeasure"
                [active]="true"
            ></ca-layer-button-item>
        </ca-layer-button>
    </ng-container>
</div>
