import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { FeatureIdentifier, LngLat, LngLatLike } from 'mapbox-gl';
import { GridNetworkMap, GroupExtConfigName, TilesMap } from '@libs/common';
import {
    InfoPins,
    MapCenterAndZoom,
    MapControlPins,
    MapPins,
    MapPolygonInfo,
    RegionPins,
    SourceLine,
    SourcePins,
} from '@libs/common';
import { GroupFeaturesService } from '@libs/shared-store';

import { MapboxActionsService } from './mapbox-actions.service';
import { setMapCenter, setMapZoom } from '../state/map/map.feature';

class MapProperties {
    zoom?: number = null;
    center?: LngLatLike = null;

    postPins?: MapPins = null;
    cityPins?: MapPins = null;
    notificationsSelectedPosts?: MapPins = null;
    controlPointPins?: MapControlPins = null;
    correlationPins?: MapPins = null;
    sourceLine?: SourceLine = null;
    polygon?: MapPolygonInfo = null;
    infoPins?: InfoPins = null;
    sourcePins? = null;
    regionPins? = null;
    gridNetwork?: GridNetworkMap = null;
    tilesMap?: TilesMap = null;
    groupFeaturesLayer?: GeoJSON.FeatureCollection<GeoJSON.Geometry> = null;
    onMapDragEnd?: (props: MapCenterAndZoom) => void = null;
    onMapZoomChanged?: (zoom: number) => void = null;
}

@Injectable({
    providedIn: 'root',
})
export class MapAdapterService {
    public zoom: number = null;
    public center: LngLatLike = null;

    public notificationsSelectedPosts: MapPins = null;
    public correlationPins: MapPins = null;
    public postPins: MapPins = null;
    public cityPins: MapPins = null;
    public controlPointPins: MapControlPins = null;

    public sourcePins: SourcePins = null;
    public regionPins: RegionPins = null;
    public groupFeaturesLayer: GeoJSON.FeatureCollection<GeoJSON.Geometry> = null;
    public sourceLine: SourceLine = null;
    public polygon: MapPolygonInfo = null;
    public infoPins: InfoPins = null;
    public pinsAreaData: Observable<GeoJSON.FeatureCollection<GeoJSON.LineString>> = null;
    public gridNetwork: GridNetworkMap = null;
    public tilesMap: TilesMap = null;
    public onMapDragEnd: (props: MapCenterAndZoom) => void = null;
    public onMapZoomChanged: (zoom: any) => void = null;
    public isMovingMap: boolean;
    constructor(
        private store: Store,
        private groupFeaturesService: GroupFeaturesService,
        private mapActions: MapboxActionsService
    ) {}

    set(data: MapProperties) {
        Object.assign(this, new MapProperties(), data);
    }

    setFeature(
        feature: FeatureIdentifier | mapboxgl.MapboxGeoJSONFeature,
        state: { [key: string]: any }
    ) {
        this.mapActions.setFeature(feature, state);
    }

    public async getDefaultGroupFeaturesLayer() {
        await lastValueFrom(
            this.groupFeaturesService.readyBehavior$.pipe(
                filter((v) => v),
                take(1)
            )
        );
        return this.groupFeaturesService.getConfig(GroupExtConfigName.featuresLayer);
    }

    public defaultMapDragEnd = async (props: MapCenterAndZoom) => {
        if (props?.center && props.center instanceof LngLat) {
            this.store.dispatch(setMapZoom({ payload: props.zoom }));
            this.store.dispatch(setMapCenter({ payload: props.center }));
        }
    };

    public defaultMapZoomChanged = (value: any) => this.zoomChanges(value);

    public zoomChanges = (value: any) => {
        if (this.mapActions.preventZooming || this.isMovingMap) {
            return;
        }
        const zoom = value instanceof Object ? value?.zoom : value;
        const center =
            value instanceof Object && value?.zoom ? value?.zoom : this.mapActions.getCenter();
        this.mapActions.currentZoom = zoom;
        this.store.dispatch(setMapZoom({ payload: zoom }));
        this.store.dispatch(setMapCenter({ payload: center ?? this.mapActions.getCenter() }));
    };
}
