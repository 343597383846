<div class="plumes_control_points_card" [ngClass]="{ active: isActive }">
    <div class="plumes_control_points_card__content" [ngClass]="{ active: isActive }">
        <div class="plumes_control_points_card__name">{{ point.name }}</div>
    </div>

    <div class="plumes_control_points_card__value">
        <div class="plumes_control_points_card__value_number">
            <span *ngIf="isFalseNumber(currentPointValues) else valueTemp">-</span>
            <ng-template #valueTemp>{{ currentPointValues | valueFormatNumber : numberAfterDot }}</ng-template>
        </div>
        <div class="plumes_control_points_card__value_description" [innerHTML]="measureUnit"></div>
    </div>
    <kebab-menu (clickActions)="openActions($event)"></kebab-menu>
</div>
