import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnAccordionComponent } from './btn-accordion.component';
import { ArrowModule } from '@libs/shared-ui';

@NgModule({
    exports: [BtnAccordionComponent],
    declarations: [BtnAccordionComponent],
    imports: [CommonModule, ArrowModule],
})
export class BtnAccordionModule {}
