import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EventImpact } from '@cityair/modules/impact/service/api-model-impact';
import { NUMBER_AFTER_DOT, TEXTS } from '@libs/common';
import { MeasureScheme } from '@libs/common';

@Component({
    selector: 'ca-impact-event-card',
    templateUrl: 'impact-event-card.component.html',
    styleUrls: ['impact-event-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactEventCardComponent {
    @Input() item: EventImpact;
    @Input() currentSchema: MeasureScheme = MeasureScheme.default;

    textsScheme = TEXTS.MEASURES_SCHEME;
    NUMBER_AFTER_DOT = NUMBER_AFTER_DOT;
    schema = MeasureScheme.default;
    textNames = TEXTS.NAMES;
    texts = TEXTS.IMPACT.eventList.details;
}
