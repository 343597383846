<div class="instant-aqi-faq">
    <cross-button class="instant-aqi-faq__close-btn" size="large" (click)="close()"></cross-button>
    <nav class="scroll-gray">
        <ul>
            <li
                *ngFor="let menuItem of menuItems; index as i"
                [class.current-section]="selectedSection === i"
                (click)="showSection(i)"
            >
                {{ menuItem }}
            </li>
        </ul>
    </nav>
    <article class="scroll-gray" #article>
        <h1 class="instant-aqi-faq__section-header">Что такое индекс Instant AQI</h1>
        <div class="instant-aqi-faq__splash">
            <img
                [attr.src]="getAsset('articles/instant-aqi-faq/instant-aqi-faq-splash.png')"
                alt="splash"
            />
        </div>

        <section class="instant-aqi-faq__section">
            <p>
                Индекс Instant AQI отражает качество воздуха на текущий момент. В его основу
                заложены показания концентраций загрязняющих веществ в мкг/м<sup>3</sup>, которые
                поступают со станций мониторинга. Эти показания в необработанном виде сложны для
                восприятия, поэтому платформа CityAir преобразует их в простой числовой индекс
                по шкале от 1 до 10.
            </p>
            <div class="instant-aqi-faq__legend">
                <div class="instant-aqi-faq__legend-title">
                    {{ TEXTS.AQI_DETAILED_INFO.legendTitle }}
                </div>
                <div class="instant-aqi-faq__legend-content">
                    <div
                        class="instant-aqi-faq__legend-item"
                        *ngFor="let dangerLevelDescription of aqiDangerLevels; index as i"
                    >
                        <div
                            class="instant-aqi-faq__legend-item-dt instant-aqi-faq__legend-item-{{
                                i
                            }}"
                        ></div>
                        <div class="instant-aqi-faq__legend-item-separator">—</div>
                        <div class="instant-aqi-faq__legend-item-dd">
                            {{ dangerLevelDescription }}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="instant-aqi-faq__section">
            <h2 class="instant-aqi-faq__section-header">Что такое индекс качества воздуха?</h2>
            <p>
                Индекс качества воздуха или AQI (Air Quality Index) предоставляет сложные данные
                о составе воздуха в простой форме и позволяет абсолютно всем людям оценить,
                насколько безопасно дышать на улице в их городе или районе.
            </p>
            <p>
                Внутри индекса «зашиты» сложные измерения и нормативы по нескольким веществам.
                Специалисты, например, экологи или химики, легко ориентируются в этих данных, но для
                не-специалистов и широкой общественности требуется более интуитивно понятный способ
                подачи информации.
            </p>
            <p>
                Во многих странах данные о качестве воздуха предоставляются населению в виде простой
                шкалы, которая отражает риски, связанные с загрязнением. Значения шкалы растут
                по мере увеличения опасности для здоровья: сначала для уязвимых групп населения,
                то есть для людей с заболеваниями легких, сердечно-сосудистой системы, людей старше
                65 лет и детей, а начиная с красного уровня — и для всех остальных.
            </p>
            <p>
                Значения индекса рассчитываются с помощью формулы, которая учитывает несколько
                загрязнителей. Их количество и состав могут быть разными в разных странах, но чаще
                всего в них входит 6 веществ, определенных Всемирной организацией здравоохранения
                как критично влияющие на здоровье: взвешенные частицы PM2,5 и PM10, оксид углерода,
                приземный озон, диоксид серы и диоксид азота.
            </p>
        </section>

        <section class="instant-aqi-faq__section">
            <h2 class="instant-aqi-faq__section-header">Что такое EPA AQI?</h2>
            <p>
                Самый популярный в мире индекс разработан Американским агентством по охране
                окружающей среды, называется Environmental Protection Agency Air Quality Index
                (Индекс качества воздуха Агентства по защите окружающей среды США) или
                <b>EPA AQI</b>. Его используют экологические ведомства стран, у которых нет своих
                шкал, частные компании, занимающиеся мониторингом качества воздуха, ученые
                и исследователи, потому что на сегодняшний день этот индекс лучше всех остальных
                показывает и предсказывает качество воздуха. А также является наиболее авторитетным,
                используется ведущими мировыми организациями, изучающими качество воздуха: ВОЗ,
                Европейским агентством по охране окружающей среды, Всемирной метеорологической
                организацией и другими.
            </p>
            <p>
                Индекс EPA AQI основан на измерении концентраций шести основных загрязнителей,
                принятых ВОЗ. Итоговое значение индекса определяется наибольшей концентрацией самого
                опасного из загрязнителей за последние 24 часа. Разбит на 6 категорий, каждая
                из которых соответствует определенным рискам для здоровья по шкале от 0 до 500
                и обозначается определенным цветом.
            </p>
            <div class="instant-aqi-faq__table scroll-gray">
                <table>
                    <tr>
                        <th scope="col">Цвет</th>
                        <th scope="col">Уровень опасности</th>
                        <th scope="col">Значение индекса</th>
                        <th scope="col">Описание рисков и рекомендации</th>
                    </tr>
                    <tr>
                        <th scope="row" class="table-item table-item--1">Зеленый</th>
                        <td>Все хорошо</td>
                        <td>0-50</td>
                        <td>
                            Качество воздуха удовлетворительное, можно выйти на улицу или открыть
                            окно без всякого риска.
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" class="table-item table-item--2">Желтый</th>
                        <td>Умеренное загрязнение</td>
                        <td>51-100</td>
                        <td>
                            Качество воздуха приемлемое, но есть риск для чувствительных к
                            загрязнению людей.
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" class="table-item table-item--3 table-item--white">
                            Оранжевый
                        </th>
                        <td>Тревожный уровень для чувствительных людей</td>
                        <td>101-150</td>
                        <td>
                            Люди, находящиеся в группе риска по здоровью — пожилые, дети, пациенты
                            с заболеваниями легких и сердечно-сосудистой системы, могут пострадать,
                            вдыхая такой воздух. Группе риска не рекомендуется находиться на улице
                            пока уровень загрязнения не опустится до приемлемой отметки. На всех
                            остальных подобный уровень загрязнения может не повлиять.
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" class="table-item table-item--4 table-item--white">
                            Красный
                        </th>
                        <td>Вредный для здоровья воздух</td>
                        <td>151-200</td>
                        <td>
                            Негативное влияние такого воздуха могут испытывать даже здоровые люди
                            вне группы риска. Чувствительные к качеству воздуха люди могут получить
                            серьезный урон здоровью.
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" class="table-item table-item--5 table-item--white">
                            Фиолетовый
                        </th>
                        <td>Очень вредный для здоровья воздух</td>
                        <td>201-300</td>
                        <td>Тревожный звонок для всех групп населения.</td>
                    </tr>
                    <tr>
                        <th scope="row" class="table-item table-item--6 table-item--white">
                            Бордовый
                        </th>
                        <td>Крайне высокий уровень опасности</td>
                        <td>301 и выше</td>
                        <td>Чрезвычайная ситуация. От такого воздуха точно пострадают все.</td>
                    </tr>
                </table>
            </div>
        </section>

        <section class="instant-aqi-faq__section">
            <h2 class="instant-aqi-faq__section-header">
                Почему мы отказались от индекса EPA AQI?
            </h2>
            <p>
                Ранее в CityAir индекс качества воздуха представлялся по шкале EPA AQI, который
                отражал последствия 24-часового воздействия загрязненного воздуха на здоровье
                человека.
            </p>
            <p>
                Проблема индекса EPA AQI оказалась в том, что он показывает прошлое; показывает, как
                пострадало ваше здоровье после того, как последние 24 часа вы дышали загрязненным
                воздухом.
            </p>
            <p>
                Подобное оповещение не достаточно для администраторов, промышленных экологов,
                руководителей предприятий — для всех, кто ответственен за управление качеством
                воздуха и обязан оперативно реагировать на его ухудшение.
            </p>
            <p>
                Поэтому мы преобразовали надежную, хорошо зарекомендовавшую себя формулу EPA AQI
                таким образом, чтобы усреднение составляло 1 час и показывало актуальное состояние
                воздуха. Получившийся новый индекс называется <b>Instant AQI</b> — «мгновенный
                индекс качества воздуха».
            </p>
            <div class="instant-aqi-faq__image">
                <img
                    [attr.src]="getAsset('articles/instant-aqi-faq/01_RU.png')"
                    alt="Динамика индексов загрязнения по посту мониторинга"
                />
            </div>
            <div class="instant-aqi-faq__image">
                <img
                    [attr.src]="getAsset('articles/instant-aqi-faq/02_RU.png')"
                    alt="Динамика индексов загрязнения по городу"
                />
            </div>
        </section>

        <section class="instant-aqi-faq__section">
            <h2 class="instant-aqi-faq__section-header">Почему мы выбрали Instant AQI?</h2>
            <p>
                Прежде чем применить Instant AQI к платформе CityAir, мы провели исследование
                и сравнили, как четыре разных индекса отражают состояние воздуха. Мы взяли EPA AQI,
                экспериментальный российский индекс качества атмосферы (ИКВ-Р), NowCast AQI,
                основанный на часовом усреднении для частиц PM 2,5 и PM 10 и озона, и Instant AQI,
                основанный на часовом усреднении для шести веществ. В качестве источника данных для
                эксперимента была выбрана сеть мониторинга качества воздуха из станций CityAir.
            </p>
            <p>
                В результате Instant AQI показал себя как достаточно чувствительный к перепадам
                концентраций загрязняющих веществ, тогда как EPA AQI демонстрировал сглаженную
                картину:
            </p>
            <p>
                Таким образом,
                <b
                    >Instant AQI на данный момент лучше соответствует потребностям пользователей
                    CityAir по следующим причинам:</b
                >
            </p>
            <ul>
                <li>
                    быстро реагирует на изменения концентраций загрязняющих веществ в атмосфере;
                </li>
                <li>
                    в его основе лежит хорошо зарекомендовавшая себя формула EPA AQI, изменено лишь
                    время усреднения;
                </li>
                <li>дает возможность учета концентраций любых веществ, не только PM и озона.</li>
            </ul>
        </section>

        <section class="instant-aqi-faq__section">
            <h2 class="instant-aqi-faq__section-header">
                Почему данные с постов мониторинга CityAir выводятся в AQI, а не российском
                индексе ИЗА?
            </h2>
            <p>
                В России также есть национальный индекс, который называется ИЗА (индекс загрязнения
                атмосферы). Он рассчитывается 1 раз в год, пробы воздуха для него забирают вручную,
                ежемесячно, во всех крупных городах. ИЗА полезен и показателен, когда нужно
                отследить долгосрочные тенденции на уровне города, сравнить эти тенденции с другими
                городами, выявить отложенное влияние качества воздуха.
            </p>
            <p>
                Но с помощью ИЗА нельзя контролировать сезонные, суточные или часовые колебания
                качества воздуха, которые станции CityAir умеют отслеживать. Таким образом, AQI
                больше подходит для целей городских управляющих служб, руководителей
                предприятий-эмитентов и широкой общественности, которым необходимы оперативные
                данные о качестве и достоверные прогнозы.
            </p>
            <p>
                Однако, это не значит, что для ИЗА нет места в платформе мониторинга качества
                воздуха CityAir, он отлично работает для определенных задач и его расчет не мешает,
                а дополняет расчет AQI.
                <b
                    >Параметры ИЗА можно получить в личном кабинете CityAir для авторизованных
                    пользователей:</b
                >
                там есть возможность сформировать отчет для подачи данных в Росгидромет в формате
                ТЗА-4, в котором будет указано значение ИЗА.
            </p>
        </section>

        <section>
            <a
                class="instant-aqi-faq__open-btn"
                href="https://cdn.mycityair.ru/docs/Алгоритм расчёта AQI.pdf"
                target="_blank"
            >
                <div class="instant-aqi-faq__open-btn-icon"></div>
                Алгоритм расчёта AQI.pdf
            </a>
        </section>
    </article>
</div>
