import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ANIMATION_OPACITY } from '@libs/common';

@Component({
    selector: 'kebab-menu',
    template: `<div>
        <div
            #popupOpener
            (click)="actions($event)"
            class="kebab_menu"
            [class.kebab_menu-disabled]="disabled"
        >
            <div class="kebab_menu__circles">
                <svg
                    width="4"
                    height="18"
                    viewBox="0 0 4 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="2" cy="2" r="2" class="kebab_menu-icon-dot" />
                    <circle cx="2" cy="9" r="2" class="kebab_menu-icon-dot" />
                    <circle cx="2" cy="16" r="2" class="kebab_menu-icon-dot" />
                </svg>
            </div>
            <div
                class="kebab_menu__object"
                [class.kebab_menu__object-left]="positionOpenedMenu === 'left'"
                *ngIf="isOpenMenu"
                [@inOutAnimation]
            >
                <ng-content></ng-content>
            </div>
        </div>
    </div>`,
    styleUrls: ['kebab-menu.component.less'],
    animations: ANIMATION_OPACITY,
})
export class KebabMenuComponent {
    @Input() disabled = false;
    @Input() positionOpenedMenu: 'right' | 'left' = 'right';

    @Output() clickActions = new EventEmitter<{
        target: HTMLElement;
        positionTop: number;
        positionLeft: number;
    }>();

    isOpenMenu = false;

    @ViewChild('popupOpener', { static: true }) popupOpener: ElementRef;

    actions(e: Event) {
        e.stopPropagation();
        this.isOpenMenu = !this.isOpenMenu;
        if (this.popupOpener) {
            const { nativeElement: target } = this.popupOpener;
            const positionTop =
                target.getBoundingClientRect().top - target.offsetTop + window.scrollY;
            const positionLeft = target.getBoundingClientRect().left;
            this.clickActions.emit({
                target,
                positionTop,
                positionLeft,
            });
        }
    }
}
