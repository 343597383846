import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostPinRectangleComponent } from './post-pin-rectangle.component';

@NgModule({
    exports: [PostPinRectangleComponent],
    declarations: [PostPinRectangleComponent],
    imports: [CommonModule],
})
export class PostPinRectangleModule {}
