import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextButtonComponent } from './text-button.component';

@NgModule({
    imports: [CommonModule],
    exports: [TextButtonComponent],
    declarations: [TextButtonComponent],
})
export class TextButtonModule {}
