import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DirectivesModule, SearchResultPlaceholderComponent } from '@libs/shared-ui';
import { PipesModule } from '@libs/shared-ui';
import { LittleComponentsModule } from '@libs/shared-ui';
import { CalendarMonthsModule, SearchInputModule } from '@libs/shared-ui';
import { SortListItemModule } from '@libs/shared-ui';
import { BtnAcceptModule } from '@libs/shared-ui';
import { SidebarModule } from '@libs/shared-ui';
import { IconsModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { NonDataScreenModule } from '@libs/shared-ui';

import { IndoorMainComponent } from './components/indoor-main/indoor-main.component';
import { IndoorLoadingIndicatorComponent } from './components/module-loader/indoor-loading-indicator.component';
import { IndoorCalendarComponent } from './components/indoor-calendar/indoor-calendar.component';
import { IndoorCalendarMobileComponent } from './components/indoor-calendar-mobile/indoor-calendar-mobile.component';
import { PointListComponent } from './components/point-list/point-list.component';
import { PointItemComponent } from './components/point-item/point-item.component';
import { AnalyticLoaderComponent } from './components/analytic-loader/analytic-loader.component';
import { indoorReducers } from './store/reducers';
import { IndoorEffects } from './store/effects';
import { CityscreenComponentsModule } from '@cityair/components/cityscreen-components.module';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { FloorMapComponent } from './components/floor-map/floor-map.component';
import { PostPinRectangleModule } from '@cityair/components/post-pin-rectangle/post-pin-rectangle.module';
import { OverlayModule } from '@libs/shared-ui';
import { StatInfoComponent } from './components/stat-info/stat-info.component';
import { DailyChartModule } from '@cityair/modules/indoor/components/daily-chart/daily-chart.module';
import { PercentChartModule } from '@cityair/modules/indoor/components/percent-chart/percent-chart.module';
import { TabsViewModeComponent } from './components/tabs-view-mode/tabs-view-mode.component';
import { ColorLegendModule } from '@libs/shared-ui';
import { ValueCircleModule } from '@libs/shared-ui';
import { SwitchItemModule } from '@libs/shared-ui';
import { StatsPopupComponent } from './components/stats-popup/stats-popup.component';
import { CrossButtonModule } from '@libs/shared-ui';
import { RoomRatingComponent } from './components/room-rating/room-rating.component';
import { RoomItemPercentComponent } from './components/room-item-percent/room-item-percent.component';
import { RoomItemCircleComponent } from './components/room-item-circle/room-item-circle.component';
import { FloorPinModule } from '@cityair/components/floor-pin/floor-pin.module';
import { IndoorMainMobileComponent } from '@cityair/modules/indoor/components/indoor-main-mobile/indoor-main-mobile.component';
import { MobileHeaderModule } from '@cityair/components/mobile-header/mobile-header.module';
import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { IndoorSettingsComponent } from './components/indoor-settings/indoor-settings.component';
import { RangeSliderHoursComponent } from './components/range-slider-hours/range-slider-hours.component';
import { RangeSliderOptionComponent } from './components/range-slider-option/range-slider-option.component';
import { TagsListModule } from '@libs/shared-ui';
import { RangeSliderSingleComponent } from './components/range-slider-single/range-slider-single.component';
import { IndoorFaqComponent } from './components/indoor-faq/indoor-faq.component';
import { InputDropdownModule } from '@libs/shared-ui';
import { AnalitycHeaderComponent } from './components/analityc-header/analityc-header.component';
import { PostInfoComponent } from '@cityair/modules/indoor/components/post-info/post-info.component';
import { AqiChartModule } from '@cityair/modules/indoor/components/aqi-chart/aqi-chart.module';
import { OutdoorAirPinModule } from '@cityair/components/outdoor-air-pin/outdoor-air-pin.module';
import { IndoorLogoutComponent } from './components/indoor-logout/indoor-logout.component';
import { TimelinePanelModule } from '@libs/shared-ui';
import { BackButtonModule } from '@libs/shared-ui';
import { SidebarToggleButtonModule } from '@libs/shared-ui';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        StoreModule.forFeature('indoor', indoorReducers),
        EffectsModule.forFeature([IndoorEffects]),
        DirectivesModule,
        PipesModule,
        LittleComponentsModule,
        CalendarMonthsModule,
        TimelinePanelModule,
        SearchInputModule,
        SortListItemModule,
        BtnAcceptModule,
        SearchResultPlaceholderComponent,
        SidebarModule,
        IconsModule,
        TooltipsModule,
        CityscreenComponentsModule,
        NgxMapboxGLModule,
        PostPinRectangleModule,
        OverlayModule,
        DailyChartModule,
        PercentChartModule,
        ColorLegendModule,
        SwitchItemModule,
        ValueCircleModule,
        CrossButtonModule,
        FloorPinModule,
        BackButtonModule,
        MobileHeaderModule,
        CalendarModule,
        TagsListModule,
        InputDropdownModule,
        AqiChartModule,
        NonDataScreenModule,
        OutdoorAirPinModule,
        SidebarToggleButtonModule,
    ],
    declarations: [
        IndoorMainComponent,
        IndoorLoadingIndicatorComponent,
        IndoorCalendarComponent,
        IndoorCalendarMobileComponent,
        PointListComponent,
        PointItemComponent,
        AnalyticLoaderComponent,
        IndoorMainMobileComponent,
        FloorMapComponent,
        StatInfoComponent,
        TabsViewModeComponent,
        StatsPopupComponent,
        RoomRatingComponent,
        RoomItemPercentComponent,
        RoomItemCircleComponent,
        IndoorSettingsComponent,
        RangeSliderHoursComponent,
        RangeSliderOptionComponent,
        RangeSliderSingleComponent,
        IndoorFaqComponent,
        AnalitycHeaderComponent,
        PostInfoComponent,
        IndoorLogoutComponent,
    ],
    exports: [IndoorMainComponent, IndoorMainMobileComponent],
})
export class IndoorModule {}
