export enum LoginPage {
    Login = 'login',
    PasswordReset = 'password_reset',
    ResetCode = 'reset_code',
    NewPassword = 'new_password',
}

export enum LoginPopup {
    PasswordUpdated = 'PasswordUpdated',
    InviteLinkExpired = 'InviteLinkExpired',
    InstructionsSent = 'InstructionsSent',
}
