import { Component, Input } from '@angular/core';

@Component({
    selector: 'section-line',
    templateUrl: './section-line.component.html',
    styleUrls: ['./section-line.component.less'],
})
export class SectionLineComponent {
    @Input() text;
}
