import { MoItem } from '@cityair/modules/login/services/harvester-api/models';

export interface CpUserItem {
    UserId: number;
    Login: string;
    Email: string;
}

export interface LoginResponse {
    CpUser: CpUserItem;
    Token: string;
}

export class ApiRequest {
    Token: string;
}

export interface LoginApiRequest extends ApiRequest {
    AppTitle: string;
    Login: string;
    Pwd: string;
}

export interface PasswordResetCodeRequest extends ApiRequest {
    CheckResetCodeOnly: boolean;
    Email: string;
    ResetCode: string;
    Tag: string;
}

export interface WebResponse<T> {
    Result: T;
    Message: string;
    IsError: boolean;
    ErrorNumber: LoginErrorCode;
    ErrorMessage: string;
    ErrorMessageDetails: string;
}

export enum LoginErrorCode {
    NotSpecified = 0,
    LoginFailed = 101,
    TokenFailed = 102,
    ResetCodeFailed = 109,
    EmailValidation = 110,
    InfoException = 199,
}

export enum MoIntervalType {
    Interval5M = 1,
    Interval20M = 2,
    Interval1H = 3,
    Interval1D = 4,
    Source = 100,
}

export class MoApiItemRequest extends ApiRequest {
    GroupId: number;
    Item: MoItem;

    constructor(groupId: number, item: MoItem) {
        super();
        this.GroupId = groupId;
        this.Item = item;
    }
}
