<ng-template #popupOutlet>
    <stnd-ask-popup-three-questions
        *ngIf="showConfirmationPopup"
        [texts]="questionsText"
        [close]="onClosePopup"
        [extraAction]="backOnCalculationResults"
    >
    </stnd-ask-popup-three-questions>
</ng-template>

<div class="impact-edit-run">
    <ng-container *ngIf="runConfig else loadingTemp">
        <div class="impact-edit-run__title_wrapper">
            <header-page
                [textObject]="{ titlePage: textPlumes.editRunConfigurationTitle }"
            ></header-page>
        </div>

        <div class="impact-edit-run__body">
            <form (ngSubmit)="onSubmit()" [formGroup]="editCalculation" novalidate>
                <div class="impact-edit-run__wrapper_input_name">
                    <ca-input
                        type="text"
                        controlName="name"
                        [form]="editCalculation"
                        [valueForm]="name?.value"
                        [label]="textPlumes.runConfigurationName"
                        [textError]="getError('name')"
                    ></ca-input>
                </div>
                <div class="impact-edit-run__subtitle">{{ textPlumes.runInterval }}</div>
            </form>
        </div>
        <div>
            <div class="impact-edit-run__input_wrapper">
                <div class="impact-edit-run__input_block">
                    <div class="impact-edit-run__label_select">{{ textPlumes.hour }}</div>
                    <control-select
                        class="rows-no_border"
                        data-cy="plume-edit-selector1"
                        [format]="'simple'"
                        [model]="hours"
                        [items]="dropdownSelectHour"
                        [keyValue]="'id'"
                        [keyName]="'name'"
                        (closeEvent)="selectHour($event)"
                    ></control-select>
                </div>
            </div>
        </div>

        <div
            *ngIf="errorObj.schedule_period && hours === 0"
            class="impact-edit-run__note impact-edit-run__note-error"
        >
            {{ errorObj.schedule_period }}
        </div>

        <div *ngIf="hours !== 0" class="impact-edit-run__note">
            {{ textPlumes.runIntervalDescription }}
            {{ hours | i18nPlural : textPlumes.every }}
            {{ hours }} {{ declOfNum(hours, textPlumes.timeIntervalHours) }}
        </div>

        <div class="impact-edit-run__action_btn_wrapper">
            <ca-button type="cancel" (clickAction)="backOnCalculationResults()">
                {{ textEditStation.cancel }}
            </ca-button>
            <ca-button
                type="primary"
                (clickAction)="onSubmit()"
                [disabled]="editCalculation?.pristine || editCalculation?.invalid"
            >
                <ca-spinner *ngIf="store.select(selectIsLoadingEditRun) | async else showContentSave"></ca-spinner>
                <ng-template #showContentSave>{{ textEditStation.save }}</ng-template>
            </ca-button>
        </div>
    </ng-container>
    <ng-template #loadingTemp>
        <div class="loading">Loading...</div>
    </ng-template>
</div>
