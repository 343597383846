import { Pipe, PipeTransform } from '@angular/core';
import { formatDayMonth, RunImpact, TIME_FORMAT } from '@libs/common';

import * as moment from 'moment-timezone';

function formatTime(m: moment.Moment) {
    return m.format(TIME_FORMAT);
}
function formatDate(m: moment.Moment) {
    return `${formatTime(m)} ${formatDayMonth(m)}`;
}
@Pipe({ name: 'impactRunTime', standalone: true })
export class ImpactRunTimePipe implements PipeTransform {
    transform(run: RunImpact): string {
        const dateStart = moment(run.evaluation_time || 0);
        const dateEnd = moment(dateStart).add(run.duration_minutes, 'minutes');
        return dateStart.isSame(dateEnd, 'day')
            ? `${formatTime(dateStart)} – ${formatDate(dateEnd)}`
            : `${formatDate(dateStart)} –
           ${formatDate(dateEnd)}`;
    }
}
