import { Injectable } from '@angular/core';
import { BasicApiService } from '@libs/shared-store';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import {
    FORESACT_AVAILABLE_BEGIN_TIME,
    selectIsShowLensForecast,
    selectIsShowPublicForecast,
    toggleShowLensForecast,
    toggleShowPublicForecast,
} from '@cityair/modules/core/store/public-forecast/public-forecast.feature';
import {
    selectLensForecastConfig,
    selectPublicForecastConfig,
} from '@cityair/modules/core/store/selectors';
import * as moment from 'moment-timezone';
import { intervalUpdate, togglePublicForecast } from '@cityair/modules/core/store/actions';
import { filter } from 'rxjs';

@Injectable()
export class PublicForecastEffects {
    constructor(
        private actions$: Actions,
        private basicApi: BasicApiService,
        private store: Store
    ) {}

    switchOffPublicForecast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(intervalUpdate),
            withLatestFrom(
                this.store.select(selectIsShowPublicForecast),
                this.store.select(selectPublicForecastConfig)
            ),
            filter(([action, isShow, config]) => isShow),
            switchMap(([action, isShow, config]) => {
                const actions = [];
                if (config && config.start && config.end) {
                    if (
                        action.begin !== moment.utc(config.start).valueOf() &&
                        action.end !== moment.utc(config.end).valueOf()
                    ) {
                        actions.push(togglePublicForecast({ payload: false }));
                        actions.push(toggleShowPublicForecast({ payload: false }));
                    }
                } else {
                    if (action.begin < FORESACT_AVAILABLE_BEGIN_TIME) {
                        actions.push(togglePublicForecast({ payload: false }));
                        actions.push(toggleShowPublicForecast({ payload: false }));
                    }
                }

                return actions;
            })
        )
    );

    switchOffLensForecast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(intervalUpdate),
            withLatestFrom(
                this.store.select(selectIsShowLensForecast),
                this.store.select(selectLensForecastConfig)
            ),
            filter(([action, isShow, config]) => isShow),
            switchMap(([action, isShow, config]) => {
                const actions = [];
                if (config && config.start && config.end) {
                    if (
                        action.begin !== moment.utc(config.start).valueOf() &&
                        action.end !== moment.utc(config.end).valueOf()
                    ) {
                        actions.push(togglePublicForecast({ payload: false }));
                        actions.push(toggleShowLensForecast({ payload: false }));
                    }
                } else {
                    if (action.begin < FORESACT_AVAILABLE_BEGIN_TIME) {
                        actions.push(togglePublicForecast({ payload: false }));
                        actions.push(toggleShowLensForecast({ payload: false }));
                    }
                }

                return actions;
            })
        )
    );
}
