import { Injectable } from '@angular/core';

import { LoginPopup } from '../../login.settings';

@Injectable({
    providedIn: 'root',
})
export class LoginNavigationService {
    private popup: LoginPopup;

    get activePopup() {
        return this.popup;
    }

    openPopup(popup: LoginPopup) {
        this.popup = popup;
    }

    openPasswordUpdatedPopup() {
        this.openPopup(LoginPopup.PasswordUpdated);
    }

    openInviteLinkExpiredPopup() {
        this.openPopup(LoginPopup.InviteLinkExpired);
    }

    closePopup() {
        this.popup = null;
    }
}
