import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ControlPointMarkerComponent } from './control-point-marker.component';

@NgModule({
    imports: [CommonModule],
    exports: [ControlPointMarkerComponent],
    declarations: [ControlPointMarkerComponent],
})
export class ControlPointMarkerModule {}
