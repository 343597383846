<div
    class="language-selector"
    (click)="isDropdownOpened = !isDropdownOpened"
    (clickOutside)="isDropdownOpened = false"
>
    <div class="language-selector-item language-selector-item--active">
        <ca-language-item
            [code]="lang.code"
            [name]="lang.name"
            [showFlag]="showFlags"
        ></ca-language-item>

        <ca-triangle-chevron
            class="language-selector__dropdown-icon"
            [direction]="isDropdownOpened ? 'up' : 'down'"
        ></ca-triangle-chevron>
    </div>

    <div class="language-selector__dropdown" *ngIf="isDropdownOpened">
        <ca-language-item
            class="language-selector-item"
            *ngFor="let lang of langItems$ | async"
            (click)="setLang(lang)"
            [code]="lang.code"
            [name]="lang.name"
            [showFlag]="showFlags"
        ></ca-language-item>
    </div>
</div>
