<div class="indoor-faq" (clickOutside)="close()">
    <div class="indoor-faq__close" data-cy="close-indoor-info">
        <cross-button
            class="instant-aqi-faq__close-btn"
            size="large"
            (click)="close()"
        ></cross-button>
    </div>

    <nav class="indoor-faq__nav">
        <ul>
            <li
                class="indoor-faq__nav-item"
                *ngFor="let menuItem of menuItems; index as i"
                [class.current-section]="selectedSection === i"
                (click)="showSection(i)"
            >
                {{ menuItem }}
            </li>
        </ul>
    </nav>

    <div class="indoor-faq__content">
        <article class="indoor-faq__slides">
            <ng-container [ngSwitch]="selectedSection">
                <section *ngSwitchCase="0" class="indoor-faq__slide">
                    <div class="indoor-faq__header">
                        How do we calculate Indoor Air Quality Index (IAQI)?
                    </div>
                    <div class="indoor-faq__col-2">
                        <div class="indoor-faq__description">
                            <div
                                class="indoor-faq__description_strong description_margin-bottom-20"
                            >
                                The methodology for calculating IAQI in AirVoice.Indoor Platform was
                                developed by our experts. It is based on EPA guidelines and recent
                                research on the impact of indoor air quality on people's health and
                                well-being.
                            </div>
                            <div class="indoor-faq__description_common">
                                To calculate the index, we use real-time data on the concentrations
                                of PM2.5, PM10 and carbon dioxide CO2. To accurately measure the
                                concentrations of pollutants in your building real-time sensors are
                                installed in each premises for which the index is calculated.
                            </div>
                        </div>
                        <div class="indoor-faq__image faq-image__slide-1">
                            <img
                                [attr.src]="getAsset('indoor-faq/cityair-iaq-monitor.jpg')"
                                alt="CityAir IAQ Monitor"
                            />
                        </div>
                    </div>
                </section>

                <section *ngSwitchCase="1" class="indoor-faq__slide">
                    <h2 class="indoor-faq__section-title">
                        Dependence of air pollutant concentrations and index levels
                    </h2>
                    <div class="indoor-faq__description_common">
                        Displayed on AirVoice.Indoor Platform IAQI is equal to the maximum of the
                        partial indices of the substance identified as the main pollutant over the
                        past hour. For each air polutant we compute a partial index value Ip as a
                        number in the range from 0 to 500 as described below.
                    </div>
                    <ol class="indoor-faq__list">
                        <li>
                            The system calculates an index for each pollutant from the concentration
                            data averaged over an hour
                        </li>
                    </ol>
                    <div class="calculate-index-table">
                        <div class="calculate-index-row calculate-index-row_header">
                            <div class="calculate-index-column"></div>
                            <div class="calculate-index-column">PM2.5</div>
                            <div class="calculate-index-column">PM10</div>
                            <div class="calculate-index-column">CO2</div>
                        </div>
                        <div class="calculate-index-row calculate-index-row_units">
                            <div class="calculate-index-column calculate-index-column_bold">
                                Units
                            </div>
                            <div class="calculate-index-column">µg/m3</div>
                            <div class="calculate-index-column">µg/m3</div>
                            <div class="calculate-index-column">ppm</div>
                        </div>
                        <div class="calculate-index-row calculate-index-row_standart">
                            <div class="calculate-index-column calculate-index-column_bold">
                                Rounding rule
                            </div>
                            <div class="calculate-index-column">
                                Rounding to one digit after the decimal point
                            </div>
                            <div class="calculate-index-column">Rounding to whole number</div>
                            <div class="calculate-index-column">Rounding to whole number</div>
                        </div>
                    </div>
                </section>

                <section *ngSwitchCase="2" class="indoor-faq__slide">
                    <div class="indoor-faq__description_strong">
                        Dependence of air pollutant concentrations and index levels
                    </div>
                    <div class="level-index-table">
                        <div class="level-index-row level-index-row_header">
                            <div class="level-index-column">PM2.5</div>
                            <div class="level-index-column">PM10</div>
                            <div class="level-index-column">СO2</div>
                            <div class="level-index-column">Values of index</div>
                            <div class="level-index-column">IAQI</div>
                            <div class="level-index-column"></div>
                        </div>
                        <div class="level-index-table__body">
                            <div class="level-index-row level-index-row_standart">
                                <div class="level-index-column">0.0 - 6.0</div>
                                <div class="level-index-column">0 - 27</div>
                                <div class="level-index-column">400 - 525</div>
                                <div class="level-index-column">0 - 25</div>
                                <div class="level-index-column">
                                    <span
                                        class="level-index__circle"
                                        style="background-color: #87af1b"
                                        >1</span
                                    >
                                </div>
                                <div class="level-index-column">Excellent</div>
                            </div>
                            <div class="level-index-row level-index-row_standart">
                                <div class="level-index-column">6.1 - 12.0</div>
                                <div class="level-index-column">28 - 54</div>
                                <div class="level-index-column">526 - 650</div>
                                <div class="level-index-column">25 - 50</div>
                                <div class="level-index-column">
                                    <span
                                        class="level-index__circle"
                                        style="background-color: #9cbd1c"
                                        >2</span
                                    >
                                </div>
                                <div class="level-index-column">Good</div>
                            </div>
                            <div class="level-index-row level-index-row_standart">
                                <div class="level-index-column">12.1 - 23.8</div>
                                <div class="level-index-column">55 - 104</div>
                                <div class="level-index-column">651 - 1075</div>
                                <div class="level-index-column">51 - 75</div>
                                <div class="level-index-column">
                                    <span
                                        class="level-index__circle"
                                        style="background-color: #b6be1e"
                                        >3</span
                                    >
                                </div>
                                <div class="level-index-column">Fair</div>
                            </div>
                            <div class="level-index-row level-index-row_standart">
                                <div class="level-index-column">23.9 - 35.4</div>
                                <div class="level-index-column">105 - 154</div>
                                <div class="level-index-column">1076 - 1500</div>
                                <div class="level-index-column">76 - 100</div>
                                <div class="level-index-column">
                                    <span
                                        class="level-index__circle"
                                        style="background-color: #ffcd41"
                                        >4</span
                                    >
                                </div>
                                <div class="level-index-column">Moderate</div>
                            </div>
                            <div class="level-index-row level-index-row_standart">
                                <div class="level-index-column">35.5 - 45.4</div>
                                <div class="level-index-column">155 - 204</div>
                                <div class="level-index-column">1501 - 1750</div>
                                <div class="level-index-column">101 - 125</div>
                                <div class="level-index-column">
                                    <span
                                        class="level-index__circle"
                                        style="background-color: #fda347"
                                        >5</span
                                    >
                                </div>
                                <div class="level-index-column">Lightly polluted</div>
                            </div>
                            <div class="level-index-row level-index-row_standart">
                                <div class="level-index-column">45.5 - 55.4</div>
                                <div class="level-index-column">205 - 254</div>
                                <div class="level-index-column">1751 - 2000</div>
                                <div class="level-index-column">126 - 150</div>
                                <div class="level-index-column">
                                    <span
                                        class="level-index__circle"
                                        style="background-color: #fd744c"
                                        >6</span
                                    >
                                </div>
                                <div class="level-index-column">Unhealthy for sensitive</div>
                            </div>
                            <div class="level-index-row level-index-row_standart">
                                <div class="level-index-column">55.5 - 150.4</div>
                                <div class="level-index-column">255 - 354</div>
                                <div class="level-index-column">2001 - 2500</div>
                                <div class="level-index-column">151 - 200</div>
                                <div class="level-index-column">
                                    <span
                                        class="level-index__circle"
                                        style="background-color: #ee4f46"
                                        >7</span
                                    >
                                </div>
                                <div class="level-index-column">Unhealthy</div>
                            </div>
                            <div class="level-index-row level-index-row_standart">
                                <div class="level-index-column">150.5 - 250.4</div>
                                <div class="level-index-column">355 - 424</div>
                                <div class="level-index-column">2501 - 5000</div>
                                <div class="level-index-column">201 - 300</div>
                                <div class="level-index-column">
                                    <span
                                        class="level-index__circle"
                                        style="background-color: #c83e49"
                                        >8</span
                                    >
                                </div>
                                <div class="level-index-column">Very unhealthy</div>
                            </div>
                            <div class="level-index-row level-index-row_standart">
                                <div class="level-index-column">250.5 - 350.4</div>
                                <div class="level-index-column">425 - 504</div>
                                <div class="level-index-column">5001 - 10000</div>
                                <div class="level-index-column">301 - 400</div>
                                <div class="level-index-column">
                                    <span
                                        class="level-index__circle"
                                        style="background-color: #a23242"
                                        >9</span
                                    >
                                </div>
                                <div class="level-index-column">Hazardous</div>
                            </div>
                            <div class="level-index-row level-index-row_standart">
                                <div class="level-index-column">350.5 - 500.4</div>
                                <div class="level-index-column">505 - 604</div>
                                <div class="level-index-column">10001 - 15000</div>
                                <div class="level-index-column">401 - 500</div>
                                <div class="level-index-column">
                                    <span
                                        class="level-index__circle"
                                        style="background-color: #871f5a"
                                        >10</span
                                    >
                                </div>
                                <div class="level-index-column">Extremely poor</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section *ngSwitchCase="3" class="indoor-faq__slide">
                    <div class="indoor-faq__formula-header">
                        For the found interval, the algorithm determines the category:
                    </div>
                    <div class="indoor-faq__formula-variables">
                        <div class="indoor-faq__formula-variable">
                            <span>BP<sub>hi</sub></span> — upper limit of the selected concentration
                            range,
                        </div>
                        <div class="indoor-faq__formula-variable">
                            <span>BP<sub>low</sub></span> — lower limit of the concentration range,
                        </div>
                        <div class="indoor-faq__formula-variable">
                            <span>I<sub>hi</sub></span> — IAQI value corresponding to BPhi value,
                        </div>
                        <div class="indoor-faq__formula-variable">
                            <span>I<sub>low</sub></span> — IAQI value corresponding to BPlow value.
                        </div>
                    </div>
                    <ol class="indoor-faq__list" start="2">
                        <li>
                            Based on the average hourly concentration C<sub>p</sub> and parameters
                            BP<sub>hi</sub>, BP<sub>low</sub>, I<sub>hi</sub>, I<sub>low</sub>, the
                            system calculates the partial index I<sub>p</sub>
                            for each of the substances using the formula:
                        </li>
                    </ol>
                    <div class="indoor-faq__formula">
                        <div>I<sub>p</sub> = (<sub>hi</sub> – I<sub>low</sub>)</div>
                        <div class="indoor-faq__formula-divide">
                            <div>С<sub>p</sub> – BP<sub>low</sub></div>
                            <div>BP<sub>hi</sub> – BP<sub>low</sub></div>
                        </div>
                        <div>+ I<sub>low</sub></div>
                    </div>
                    <ol class="indoor-faq__list" start="3">
                        <li>
                            By default, IAQI is calculated every hour. However, for some types of
                            rooms it is necessary to update the index more frequently, so we set up
                            an individual frequency.
                        </li>
                    </ol>
                </section>

                <section *ngSwitchCase="4" class="indoor-faq__slide">
                    <h2 class="indoor-faq__section-title">
                        Why do we use particulate matter and carbon dioxide data to calculate IAQI?
                    </h2>
                    <div class="indoor-faq__description_strong description_margin-bottom-20">
                        PM2.5, PM10 and carbon dioxide CO2 are the main pollutants that determine
                        the quality and safety of indoor air.
                    </div>
                    <div class="indoor-faq__description_common description_margin-bottom-20">
                        With elevated levels of carbon dioxide CO2 stuffiness is felt, and there may
                        be dizziness, cognitive abilities and productivity decrease.
                    </div>
                    <div class="indoor-faq__description_common description_margin-bottom-20">
                        When calculating the index, we use a scale of the effect of carbon dioxide
                        levels on the well-being of people in the room, based on studies by various
                        scientists who received similar results.
                    </div>
                    <div class="indoor-faq__description_common">
                        According to WHO high concentrations of particulate matter PM2.5 and PM10
                        cause various chronic diseases.
                    </div>
                </section>
            </ng-container>
        </article>

        <div class="indoor-faq__dots">
            <div
                class="indoor-faq__dot"
                *ngFor="let menuItem of menuItems; index as i"
                [class.dot-current-section]="selectedSection === i"
                (click)="showSection(i)"
            ></div>
        </div>
    </div>
</div>
