<div class="point_list">
    <div class="point_list__title_wrapper"></div>

    <div class="point_list__sort_wrapper">
        <shared-sort-list-item
            class="point_list__sort_name"
            (click)="setSortingCb(listCbs[ROOM])"
            [sortingDirection]="sortCallBack === listCbs[ROOM] ? sortDirection : 0"
            [text]="TEXTS.INDOOR[ROOM]"
        ></shared-sort-list-item>

        <div class="point_list__plug"></div>

        <div class="iaq_info">
            <shared-sort-list-item
                class="point_list__sort_item"
                (click)="setSortingCb(listCbs[IAQ])"
                [sortingDirection]="sortCallBack === listCbs[IAQ] ? sortDirection : 0"
                [text]="TEXTS.NAMES[IAQ]"
            ></shared-sort-list-item>

            <ca-info-icon
                data-cy="indoor-info-dashboard"
                style="margin-top: -3px"
                (click)="showInfo.emit()"
            ></ca-info-icon>
        </div>

        <shared-sort-list-item
            *ngFor="let name of orderOfMeasures"
            class="point_list__sort_item"
            (click)="setSortingCb(listCbs[name])"
            [sortingDirection]="sortCallBack === listCbs[name] ? sortDirection : 0"
            [text]="TEXTS.NAMES[name]"
        ></shared-sort-list-item>
    </div>

    <div
        *ngIf="(points | multipleSearchFns : searchFilters : searchQuery).length === 0"
        class="point_list__not_found"
    >
        <search-result-placeholder></search-result-placeholder>
    </div>

    <div
        class="point_list__items_wrapper scroll"
        [class.point_list__items_wrapper-shadow]="!isScrollTop"
        isScrollTop
        (isTop)="isScrollTop = $event"
        #list
    >
        <point-item
            *ngFor="
                let point of points
                    | multipleSearchFns : searchFilters : searchQuery
                    | asyncSorting : sortCallBack : sortDirection
                    | async
            "
            data-cy="indoor-point-item"
            [point]="point"
            [measurement]="store.select(indoorSelectors.getMeasurement(point.id)) | async"
            [isActive]="(store.select(indoorSelectors.activePointId) | async) === point.id"
            [mmtScheme]="store.select(selectMeasureScheme) | async"
            (click)="clickOnPoint.emit(point.id)"
        >
        </point-item>
    </div>

    <ca-scroll-top
        *ngIf="!isScrollTop"
        class="point_list__to_top"
        (click)="scrollTop()"
    ></ca-scroll-top>
</div>
