import { Component, OnDestroy } from '@angular/core';
import { PostsAndDevicesFacade } from '@cityair/modules/posts-and-devices/posts-and-devices.facade';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    CheckboxItem,
    TEXTS,
    Device,
    PostCreateRequest,
    DISPLAY_DEVICE_TYPE,
    FIELD_COMMON_FORM_ERROR,
} from '@libs/common';
import { NETWORK_PAGES } from './../../namespace';
import { ANIMATION_MAX_HEIGHT } from '@cityair/libs/shared/utils/config';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { CITYAIR_DEVICE_ID } from '@cityair/config';
import { Subject } from 'rxjs';
import {
    isLoadingForm,
    selectIsClosePostForm,
    selectPostFormError,
    addPostError,
    closePostForm,
} from '@cityair/modules/core/store/posts/posts.feature';
import {
    selectGroupId,
    SharedCoreActions,
    SharedPostActions,
    selectAllCities,
    selectAllDevices,
    selectDeviceSourceNameByType,
} from '@libs/shared-store';

export class PATH {
    name: string;
    path: string;
}

export class DateRange {
    timeBegin: number;
    timeEnd: number;
}

export interface ErrorModel {
    name?: string;
    schedule_period?: string;
}

@Component({
    selector: 'post-create',
    templateUrl: './post-create.component.html',
    styleUrls: ['./post-create.component.less'],
    animations: ANIMATION_MAX_HEIGHT,
})
export class PostCreateComponent implements OnDestroy {
    TEXTS = TEXTS;
    pages = NETWORK_PAGES;
    cityAirDeviceId = CITYAIR_DEVICE_ID;
    PostCreate: UntypedFormGroup;
    devicesList: Device[] = null;

    public ngDestroyed$ = new Subject<void>();
    private groupId: string;
    public translateText = TEXTS.FORECAST;
    isLoadingForm = isLoadingForm;
    navigationLink: PATH[] = [
        {
            name: TEXTS.POSTS_AND_DEVICES.postAndDevices,
            path: this.pages.networks + '/' + this.pages.postsList,
        },
        {
            name: TEXTS.POSTS_AND_DEVICES.newMO,
            path: null,
        },
    ];
    getCities: CheckboxItem[] = [];
    selectedCity: CheckboxItem = {
        id: null,
        label: null,
    };
    isShowDropdownForStations = false;
    isShowDropdownForLocation = false;
    selectedStation: Device = null;
    selectDeviceSourceNameByType = selectDeviceSourceNameByType;
    devicesListForCheckbox: CheckboxItem[] = [];
    selectedDevices: Device[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        public store: Store<any>
    ) {
        this.initStore();
    }

    private initForm() {
        this.PostCreate = this.fb.group({
            name: [null, [Validators.required, Validators.maxLength(100)]],
            latitude: [
                this.selectedStation?.geometry.coordinates[0] ?? null,
                [Validators.required, Validators.min(-90), Validators.max(90)],
            ],
            longitude: [
                this.selectedStation?.geometry.coordinates[1] ?? null,
                [Validators.required, Validators.min(-180), Validators.max(180)],
            ],
            group_id: [this.groupId, [Validators.required]],
            locality_id: [null, [Validators.required]],
            device_id: [null, [Validators.required]],
        });
    }

    ngOnDestroy() {
        this.store.dispatch(closePostForm({ payload: false }));
        this.ngDestroyed$.next();
    }

    byField(field) {
        return (a, b) => (a[field] > b[field] ? 1 : -1);
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    public getValueForm(field) {
        return this.PostCreate?.get(field)?.value || '';
    }

    public getError(field: string) {
        if (
            this.PostCreate &&
            this.PostCreate.controls[field] &&
            this.PostCreate.controls[field].invalid &&
            (this.PostCreate.controls[field].dirty || this.PostCreate.controls[field].touched)
        ) {
            if (this.PostCreate.controls[field].errors.required) {
                return this.TEXTS.POSTS_AND_DEVICES.formError.required;
            }
            if (this.PostCreate.controls[field].errors.max) {
                return (
                    this.translateText.maxError +
                    ' ' +
                    this.PostCreate.controls[field].errors.max.max
                );
            }
            if (this.PostCreate.controls[field].errors.min) {
                return (
                    this.translateText.minError +
                    ' ' +
                    this.PostCreate.controls[field].errors.min.min
                );
            }
            if (this.PostCreate.controls[field].errors.maxlength) {
                return this.TEXTS.POSTS_AND_DEVICES.formError.maxLength(
                    this.PostCreate.controls[field].errors.maxlength.requiredLength
                );
            }

            if (this.PostCreate.controls[field].errors.incorrect) {
                return this.PostCreate.controls[field].errors.message;
            }

            return null;
        }

        return null;
    }

    clickOutsideDevice() {
        if (this.isShowDropdownForStations) {
            this.PostCreate.get('device_id').markAsTouched();
        }
        this.isShowDropdownForStations = false;
    }

    clickOutsideLocality() {
        if (this.isShowDropdownForLocation) {
            this.PostCreate.get('locality_id').markAsTouched();
        }
        this.isShowDropdownForLocation = false;
    }

    onSubmit() {
        const formData = this.PostCreate.getRawValue();
        const params: PostCreateRequest = {
            group_id: formData.group_id,
            name: formData.name,
            coordinates: {
                latitude: formData.latitude,
                longitude: formData.longitude,
            },
            locality_id: formData.locality_id,
            device_id: formData.device_id,
        };
        this.store.dispatch(SharedPostActions.addPost({ payload: { params } }));
    }

    getSelectedStation(stations: CheckboxItem[]) {
        this.selectedStation = this.devicesList.find(
            (dl) => dl.id === stations.find((s) => s.selected == true).id
        );
        this.PostCreate.patchValue({
            latitude: this.selectedStation.geometry.coordinates[0],
            longitude: this.selectedStation.geometry.coordinates[1],
        });
        this.selectedDevices.push(this.selectedStation);
        this.PostCreate.controls.device_id.setValue(this.selectedStation.id);
        this.PostCreate.get('device_id').markAsDirty();
    }

    getSelectedCity(city: CheckboxItem[]) {
        this.selectedCity = city.find((c) => c.selected == true);
        this.PostCreate.controls.locality_id.setValue(this.selectedCity.id);
        this.PostCreate.get('locality_id').markAsDirty();
    }

    openPage(pageName: string) {
        this.postsAndDevicesFacade.openPage(pageName);
    }

    private initStore() {
        this.store
            .select(selectAllDevices)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((smd) => !!smd?.length)
            )
            .subscribe((smd) => {
                this.devicesList = smd;
                this.devicesListForCheckbox = [];
                smd.forEach((s) => {
                    if (s.source_type === DISPLAY_DEVICE_TYPE) {
                        this.devicesListForCheckbox.push({
                            id: s.id,
                            label: s.serial_number,
                            selected: false,
                        });
                    }
                });
            });

        this.store
            .select(selectAllCities)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((sgc) => !!sgc.length)
            )
            .subscribe((sgc) => {
                this.getCities = sgc.map((s) => ({
                    id: s.id,
                    label: s.name,
                    selected: false,
                }));
            });
        this.getCities.sort(this.byField('label'));
        this.store
            .select(selectGroupId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((groupId) => {
                this.groupId = groupId;
                this.initForm();
            });
        this.store
            .select(selectIsClosePostForm)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => v)
            )
            .subscribe((isClose) => {
                this.openPage(this.navigationLink[0].path);
            });
        this.store
            .select(selectPostFormError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((formError) => {
                if (formError) {
                    const data = formError.error?.errors;
                    for (const key in data) {
                        if (data.hasOwnProperty(key) && this.PostCreate?.controls[key]) {
                            this.PostCreate.controls[key].setErrors({
                                incorrect: true,
                                message: data[key].join(', '),
                            });
                        } else if (data.hasOwnProperty(FIELD_COMMON_FORM_ERROR)) {
                            this.store.dispatch(
                                SharedCoreActions.setInfoMessage({
                                    payload: {
                                        id: new Date().valueOf(),
                                        message: `${data[FIELD_COMMON_FORM_ERROR]}. ${this.translateText.tryAgain}.`,
                                        positionX: 'left',
                                        positionY: 'bottom',
                                        iconClass: 'error',
                                        duration: 10000,
                                        showCloseIcon: true,
                                        size: 'lg',
                                    },
                                })
                            );

                            setTimeout(() => {
                                this.store.dispatch(addPostError({ payload: null }));
                            }, 2500);
                        }
                    }
                }
            });
    }
}
