<div class="range-slider-hours">
    <div
        class="range-slider__track"
        [style]="{
            left: handleMinPosition + '%',
            width: 100 - handleMinPosition - (100 - handleMaxPosition) + '%'
        }"
    ></div>
    <div class="range-slider__handle handle-min" [style]="{ left: handleMinPosition + '%' }">
        <span class="handle__value">{{ minPositionName }}</span>
    </div>
    <div class="range-slider__handle handle-max" [style]="{ left: handleMaxPosition + '%' }">
        <span class="handle__value">{{ maxPositionName }}</span>
    </div>
</div>
