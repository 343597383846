import { Component } from '@angular/core';
import { isIos } from '@cityair/libs/shared/utils/other-utils';
import { TEXTS, detectMobile } from '@libs/common';

@Component({
    selector: 'cs-market-links',
    templateUrl: './market-links.component.html',
    styleUrls: ['./market-links.component.less'],
})
export class MarketLinksComponent {
    isIos = isIos();

    isMobile = detectMobile();

    TEXTS = TEXTS;
}
