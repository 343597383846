import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

import { TEXTS } from '@libs/common';
import { ANIMATION_OPACITY } from '@libs/common';
@Component({
    selector: 'shared-ui-instant-aqi-legend',
    templateUrl: 'instant-aqi-legend.component.html',
    styleUrls: ['instant-aqi-legend.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class InstantAqiLegendComponent implements OnChanges {
    @Input() aqi: number;

    @Output() openAqiFaqLink = new EventEmitter<void>();

    TEXTS = TEXTS;

    aqiDangerLevels = [
        TEXTS.AQI_DETAILED_INFO.dangerLevelLow,
        TEXTS.AQI_DETAILED_INFO.dangerLevelMedium,
        TEXTS.AQI_DETAILED_INFO.dangerLevelHigh,
    ];

    currentAqiDangerLevel: number;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.aqi) {
            this.currentAqiDangerLevel = this.aqiToDangerLevel(this.aqi);
        }
    }

    aqiToDangerLevel(i: number) {
        if (i >= 1 && i <= 3) {
            return 0;
        } else if (i >= 4 && i <= 7) {
            return 1;
        } else if (i >= 8 && i <= 10) {
            return 2;
        }
    }

    openDetails(e: Event) {
        e.preventDefault();
        this.openAqiFaqLink.emit();
    }
}
