import { createSelector, select, Store } from '@ngrx/store';
import { lastValueFrom, pipe } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { ICoreState } from './reducers';
import { MMT_FOR_PINS } from '@cityair/config';
import {
    isToday,
    AVAILABLE_INTERVALS,
    IntervalEnum,
    MeasureScheme,
    markerState,
    isRU,
    PDK_SS,
    PDK_MR,
    ColorZone,
    PdkType,
    AqiType,
    DataObjType,
    NameModules,
    MAIN_PAGES,
    DAY_MS,
    HOUR_MS,
    TileConfig,
} from '@libs/common';
import {
    getComparedListObject,
    isComparedListLimited,
    selectComparedItems,
} from './compared-list/compared-list.selectors';
import * as moment from 'moment-timezone';
import {
    allowModule,
    selectAllCities,
    selectAllDevices,
    selectAllPosts,
    selectAllPostsDic,
    selectAvailableModule,
    selectCurrentGroup,
    selectCurrentRegionCoefs,
    selectGroupId,
    selectMapLoaded,
    selectMapStyleTypes,
    selectMapCenter,
    selectMapStyleLoading,
    selectMeasuresZones,
} from '@libs/shared-store';
import { selectCurrentCity } from '@cityair/modules/core/store/current-city/current-city.feature';
import {
    DOMAINS_FORECASTS,
    LENS_FORECASTS,
} from '@cityair/modules/map/components/mapbox/domain-forecasts.settings';
import {
    selectIsShowLensForecast,
    selectIsShowPublicForecast,
} from '@cityair/modules/core/store/public-forecast/public-forecast.feature';

import { findClosestCity } from '@cityair/modules/map/components/mapbox/mapAPI';

import {
    selectCurrentMeasureScheme,
    selectCurrentUser,
    selectMyRole,
    selectUserId,
    selectUserTimezone,
} from '@libs/shared-store';

export interface AppState {
    core: ICoreState;
}
export const coreSelector = (state: AppState) => state.core;

export const selectUserRoleId = createSelector(selectCurrentUser, selectGroupId, (iAm, groupId) => {
    if (iAm && groupId) {
        return iAm?.group_roles[groupId] ?? null;
    }
    return null;
});

export const selectGroupWithUser = createSelector(
    selectCurrentGroup,
    selectUserId,
    (group, userId) => {
        if (group && group?.id && userId) {
            return {
                groupId: group.id,
                userId: userId,
            };
        }
        return null;
    }
);
export const selectGroupAndIAm = createSelector(
    selectCurrentUser,
    selectGroupId,
    (iAm, groupId) => {
        if (iAm && groupId) {
            return {
                groupId,
                iAm,
            };
        }
        return null;
    }
);

export const selectUserData = createSelector(selectCurrentUser, selectMyRole, (iAm, role) => {
    if (iAm && role) {
        return {
            userId: iAm.id ?? null,
            login: iAm.name ?? null,
            email: iAm.email ?? null,
            roleId: role.id ?? null,
            roleName: role.name,
            roleNameRu: role.name_ru,
            shortName: iAm.name ? iAm.name.substring(0, 2).toUpperCase() : '',
        };
    }
    return null;
});
export const selectCurrentMo = createSelector(
    coreSelector,
    selectAllPostsDic,
    (state: ICoreState, posts) => posts[state.selectedMoId] ?? null
);

export const selectDeviceSourceNameByType = (type: string) =>
    createSelector(
        selectDeviceSources,
        (sources) => sources?.find((source) => source.source_type === type)?.name
    );

export const selectCurrentSerialNumberDevice = createSelector(
    coreSelector,
    (state: ICoreState) => state.selectedDeviceSerialNumber
);

export const selectLoadingTimeline = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isTimelineLoading
);

export const selectMapLoading = createSelector(
    selectMapStyleLoading,
    selectMapStyleTypes,
    (isLoadingMapStyle, styleTypes) => styleTypes?.length > 0 && isLoadingMapStyle
);
export const selectLoading = createSelector(
    selectMapLoading,
    selectLoadingTimeline,
    (isLoadingMap, isLoadingTimeline) => isLoadingTimeline || isLoadingMap
);
export const selectMarkersWithoutLocation = createSelector(selectAllPosts, (posts) =>
    posts.filter((m) => !m.ancestor)
);

export const defaultMapIsActive = createSelector(
    coreSelector,
    (state: ICoreState) => state.defaultMapIsActive
);
export const selectMeasurementsForPins = createSelector(coreSelector, (state: ICoreState) =>
    state?.allMeasurements.filter((name) => MMT_FOR_PINS.includes(name))
);

export const selectGlobalMeasurement = createSelector(
    coreSelector,
    (state: ICoreState) => state?.currentMeasurement
);

export const selectSchemeAndMeasure = createSelector(
    selectGlobalMeasurement,
    selectCurrentMeasureScheme,
    (mmt, scheme) => ({
        scheme,
        mmt,
    })
);

export const selectIsInstantAqiFaqOpen = createSelector(
    coreSelector,
    (state: ICoreState) => state.isInstantAqiFaqOpen
);

export const selectTzMinutesOffset = createSelector(
    coreSelector,
    (state: ICoreState) => state?.time.tzMinutesOffset
);

export const selectCurrentTzOffset = createSelector(
    coreSelector,
    (state: ICoreState) => state?.time.tzMinutesOffset
);
export const selectDeviceSources = createSelector(
    coreSelector,
    (state: ICoreState) => state?.deviceSources
);
export const selectTimeRangeWithoutDistinct = createSelector(coreSelector, (state: ICoreState) => ({
    begin: state?.time.begin,
    end: state?.time.end,
}));

export const selectTimeRange = pipe(
    select(selectTimeRangeWithoutDistinct),
    distinctUntilChanged(
        (prev, current) => prev.begin === current.begin && prev.end === current.end
    )
);

export const selectTime = createSelector(coreSelector, (state: ICoreState) => state?.time.current);

const _selectCurrentTime = createSelector(coreSelector, (state: ICoreState) => ({
    current: state?.time.current,
}));

export const selectCurrentTime = pipe(
    select(_selectCurrentTime),
    distinctUntilChanged((prev, current) => prev.current === current.current)
);

export const selectZone = createSelector(
    selectSchemeAndMeasure,
    selectMeasuresZones,
    ({ mmt, scheme }, zones) => {
        return zones[scheme][mmt] as ColorZone;
    }
);

export const selectTimelineDateTimes = createSelector(
    coreSelector,
    (state: ICoreState) => state?.timelineDateTimes
);
export const selectCurrentIndex = createSelector(
    coreSelector,
    (state: ICoreState) => state.currentTimeIndex
);
export const selectCurrentTimeByIndex = createSelector(
    selectCurrentIndex,
    selectTimelineDateTimes,
    (index, dates) => {
        if (index !== null && dates.length) {
            return new Date(dates[index]).getTime() ?? null;
        }
        return null;
    }
);
export const selectCurrentTimeIndex = pipe(
    select(selectCurrentTimeByIndex),
    distinctUntilChanged((prev, current) => prev === current)
);
export const selectCityTimelineById = (id: string) =>
    createSelector(coreSelector, (state: ICoreState) => state.timelinePosts.entities[id] ?? null);

export const selectPostTimelineById = (id: string) =>
    createSelector(coreSelector, (state: ICoreState) => state.timelinePosts.entities[id] ?? null);

export const selectCityCurrentValue = (id: string) =>
    createSelector(
        selectCityTimelineById(id),
        selectGlobalMeasurement,
        selectCurrentIndex,
        (city, mmt, index) => {
            if (city && mmt && index !== null) {
                if (city.data.measurements.hasOwnProperty(mmt)) {
                    return city.data.measurements[mmt].values[index] ?? null;
                }
                if (city.data.indexes.hasOwnProperty(mmt)) {
                    return city.data.indexes[mmt].values[index] ?? null;
                }

                return null;
            }
            return null;
        }
    );
export const selectPostCurrentValue = (id: string) =>
    createSelector(
        selectPostTimelineById(id),
        selectGlobalMeasurement,
        selectCurrentIndex,
        (post, mmt, index) => {
            if (post && mmt && index !== null) {
                if (post.data.measurements.hasOwnProperty(mmt)) {
                    return post.data.measurements[mmt].values[index] ?? null;
                }
                if (post.data.indexes.hasOwnProperty(mmt)) {
                    return post.data.indexes[mmt].values[index] ?? null;
                }

                return null;
            }
            return null;
        }
    );

// helpers for synchronous requests
export const getPostPromiseValue = async (store: Store, markerId: string) =>
    lastValueFrom(store.select(selectPostCurrentValue(markerId)).pipe(take(1)));

export const getLocationPromiseValue = async (store: Store, markerId: string) =>
    lastValueFrom(store.select(selectCityCurrentValue(markerId)).pipe(take(1)));

export const selectIsCityMode = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isCityMode
);
export const selectChartData = createSelector(
    coreSelector,
    (state: ICoreState) => state?.chartData
);
export const selectSourcesAsFeatures = createSelector(
    selectChartData,
    selectAllPostsDic,
    (chartData, posts) => {
        if (chartData.length && posts) {
            const result = chartData.map((item) => {
                const id = item?.properties.uuid;
                const currentPost = posts[id] ? { ...posts[id] } : null;
                if (id && currentPost) {
                    const postProperties = {
                        city_id: `${currentPost.ancestor?.id}`,
                        name: currentPost.name,
                        name_ru: currentPost.name,
                        ancestor: currentPost.ancestor,
                    };
                    const geometry = Object.assign({}, item.geometry, currentPost.geometry);
                    const properties = Object.assign({}, item.properties, postProperties);
                    return {
                        ...item,
                        geometry,
                        properties,
                    };
                }
                return item;
            });

            return result;
        }
    }
);

export const selectErrorMessage = createSelector(
    coreSelector,
    (state: ICoreState) => state.errorMessage
);

export const isCompareMode = createSelector(
    coreSelector,
    (state: ICoreState) => state.isCompareMode
);

export const getMarkerState = (id: string) =>
    createSelector(
        coreSelector,
        isCompareMode,
        isComparedListLimited,
        getComparedListObject(id),
        (state: ICoreState, isCompareMode, isComparedListLimited, comparedListObject) =>
            isCompareMode && (!isComparedListLimited || comparedListObject)
                ? markerState.add
                : markerState.default
    );

export const canClickOnMarker = (id: string) =>
    createSelector(
        coreSelector,
        getComparedListObject(id),
        getMarkerState(id),
        (state, comparedListObject, markerCurrentState) =>
            !(comparedListObject && markerCurrentState === markerState.default)
    );

export const selectTypeInterval = createSelector(
    coreSelector,
    (state: ICoreState) => state?.currentTypeInterval
);

export const selectPdkForChart = createSelector(
    selectCurrentMeasureScheme,
    selectTypeInterval,
    (scheme, interval) => {
        if (scheme === MeasureScheme.mpc || !isRU) return null;

        const isDay = interval === IntervalEnum.day;
        const pdks = (isDay ? PDK_SS : PDK_MR)[scheme];

        return {
            type: isDay ? PdkType.ss : PdkType.mr,
            pdks,
        };
    }
);

export const selectAllowUpdate = createSelector(coreSelector, (state: ICoreState) => {
    const isToday = new Date().getTime() - state.time.end <= HOUR_MS;

    const isNotMaxTime = state.time.end - state.time.begin <= AVAILABLE_INTERVALS[0].days * DAY_MS;

    return isToday && isNotMaxTime;
});

export const getNewBeginEndTime = createSelector(coreSelector, (state: ICoreState) => {
    const delta = new Date().getTime() - state.time.end;

    return {
        begin: state.time.begin + delta,
        end: new Date().getTime(),
    };
});

export const getIntervalUpdateData = createSelector(
    selectAllowUpdate,
    getNewBeginEndTime,
    (isAllowUpdate, newTimeRange) => ({ isAllowUpdate, newTimeRange })
);
export const selectMapClickState = createSelector(
    coreSelector,
    (state: ICoreState) => state?.mapClickState
);
export const isAvailableModule = (module: MAIN_PAGES) =>
    createSelector(selectAvailableModule, (modules) => modules.includes(module));
export const selectQualityDataMode = createSelector(
    coreSelector,
    (state: ICoreState) => state?.qualityDataMode
);
export const selectIsShowQualityDataInfo = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isShowQualityDataInfo
);
export const selectQualityDataMarkers = createSelector(
    coreSelector,
    (state: ICoreState) => state?.qualityDataMarkers
);
export const selectQualityDataTimeline = createSelector(
    coreSelector,
    (state: ICoreState) => state?.qualityDataTimeline
);

export const selectQualityDataPostId = createSelector(
    selectQualityDataMode,
    selectComparedItems,
    (qualityDataMode, comparedItems) => {
        if (
            qualityDataMode &&
            comparedItems.length === 1 &&
            comparedItems[0].obj === DataObjType.outdoorPost
        ) {
            return comparedItems[0].id;
        }

        return null;
    }
);
export const getDataParams = createSelector(
    selectTimeRangeWithoutDistinct,
    selectTypeInterval,
    selectGroupId,
    (time, interval, groupId) => {
        if (time && interval && groupId) {
            const date__lt = isToday(new Date(time.end))
                ? new Date().toISOString()
                : new Date(time.end).toISOString();
            const params = {
                group_id: groupId.toString(),
                date__gt: new Date(time.begin).toISOString(),
                date__lt,
                interval: interval.toString(),
            };

            return params;
        }

        return null;
    }
);
export const selectParamsForTimeline = createSelector(
    getDataParams,
    selectCurrentMeasureScheme,
    (paramsData, scheme) => {
        if (paramsData && scheme) {
            const params = {
                date__gt: paramsData.date__gt,
                date__lt: paramsData.date__lt,
                interval: paramsData.interval,
                measure_scheme: scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                concentration_in_mpc: (scheme === MeasureScheme.mpc).toString(),
            };
            return {
                groupId: Number(paramsData.group_id),
                params,
            };
        }

        return null;
    }
);
export const selectParamsForTimelineOne = createSelector(
    selectParamsForTimeline,
    selectGlobalMeasurement,
    (data, mmt) => {
        if (data && mmt) {
            const params = Object.assign({}, data.params, {
                packet_type: mmt,
            });
            return {
                groupId: data.groupId,
                params,
            };
        }

        return null;
    }
);
export const selectMyDevicesAndPosts = createSelector(
    selectAllDevices,
    selectAllPosts,
    (devices, posts) => ({ devices, posts })
);
export const selectInitMap = createSelector(
    selectCurrentGroup,
    selectMapLoaded,
    (group, mapLoaded) => (group && mapLoaded ? group : null)
);
export const selectShowAqiWidget = createSelector(
    selectGlobalMeasurement,
    selectIsCityMode,
    (mmt, isCityMode) => isCityMode && Object.values(AqiType).includes(mmt as AqiType)
);
export const selectIsShowSettingCoefficient = createSelector(
    selectMyRole,
    selectCurrentRegionCoefs,
    (role, coef) => role?.edit_group && coef !== null
);
export const selectNearestCity = createSelector(
    selectAllCities,
    selectMapCenter,
    (locations, center) => findClosestCity(locations, center)
);
export const selectPublicForecastConfig = createSelector(
    allowModule(NameModules.forecast),
    selectCurrentCity,
    selectIsCityMode,
    (isAllow, city, isCityMode) => {
        if (isAllow && city && isCityMode) {
            return DOMAINS_FORECASTS.hasOwnProperty(city.id) ? DOMAINS_FORECASTS[city.id] : null;
        }
        return null;
    }
);
export const selectLensForecastConfig = createSelector(
    allowModule(NameModules.forecast),
    selectCurrentCity,
    selectIsCityMode,
    (isAllow, city, isCityMode) => {
        if (isAllow && city && isCityMode) {
            return LENS_FORECASTS.hasOwnProperty(city.id) ? LENS_FORECASTS[city.id] : null;
        }
        return null;
    }
);
export const selectIsAllowPublicForecast = createSelector(selectPublicForecastConfig, (config) =>
    config ? true : false
);
export const selectIsAllowLensForecast = createSelector(selectLensForecastConfig, (config) =>
    config ? true : false
);
export const selectIsShowOnMapPublicForecast = createSelector(
    selectIsAllowPublicForecast,
    selectIsShowPublicForecast,
    (isAllow, isShow) => isAllow && isShow
);
export const selectIsShowOnMapLensForecast = createSelector(
    selectIsAllowLensForecast,
    selectIsShowLensForecast,
    (isAllow, isShow) => isAllow && isShow
);
export const selectDefaultPins = createSelector(
    selectIsCityMode,
    selectAllPosts,
    selectMarkersWithoutLocation,
    (isCityMode, allPosts, posts) => {
        return isCityMode ? allPosts : posts;
    }
);
export const selectDefaultCityPins = createSelector(
    selectIsCityMode,
    selectAllCities,
    (isCityMode, allCities) => {
        return isCityMode ? [] : allCities;
    }
);
export const selectDefaultTilesMapConfig = createSelector(
    selectPublicForecastConfig,
    selectIsShowOnMapPublicForecast,
    selectLensForecastConfig,
    selectIsShowOnMapLensForecast,
    (forecastConfig, isAllowForecast, lensConfig, isAllowLens) => {
        const result = [];
        if (forecastConfig && isAllowForecast) {
            const tileConfig: TileConfig = {
                slug: forecastConfig.slug,
                coordinates: forecastConfig.coordinates,
            };
            result.push(tileConfig);
        } else if (lensConfig && isAllowLens) {
            const tileConfig: TileConfig = {
                slug: lensConfig.slug,
                coordinates: lensConfig.coordinates,
            };
            result.push(tileConfig);
        }
        return result;
    }
);
export const selectDefaultTilesUrl = (config) =>
    createSelector(selectDefaultTilesMapConfig, (con) => {
        return 'https://tiles.cityair.ru/v1/public/forecast/ru_moscow_5km/raster/custom/0/PM25/20241210_080000.png';
    });
