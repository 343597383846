<div (clickOutside)="close()" class="input_date_range">
    <input-for-dropdown
        [label]="labelText"
        [inputValue]="getIntervalText()"
        [(isOpenDropdown)]="showCalendar"
    ></input-for-dropdown>
    <div *ngIf="descriptionText" class="input_date_range__description">{{ descriptionText }}</div>
    <calendar-months
        [show]="showCalendar"
        [timeBegin]="timeBegin"
        [timeEnd]="timeEnd"
        [applyCb]="updateTime"
        [monthsVisible]="displayMonth"
        [tzOffset]="tzOffset"
        [columns]="1"
        [dynamicWidth]="true"
        [numberOfMonths]="numberOfMonths"
    ></calendar-months>
</div>
