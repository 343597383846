import { CalendarState } from './CalendarState';
import { createCalendarDays } from './dataModel';
import * as moment from 'moment-timezone';

export class CalendarActions {
    calendar: CalendarState;
    updateTime: (begin: number, end: number) => void;
    numberOfMonths: number;

    constructor(calendar, updateTime, numberOfMonths: number) {
        this.calendar = calendar;
        this.updateTime = updateTime;
        this.numberOfMonths = numberOfMonths;

        this.calendar.months = createCalendarDays(0, numberOfMonths);
        this.calendar.monthsMobile = this.calendar.months.slice().reverse();
    }

    private _reset() {
        this.calendar.begin = this.calendar.end = null;
        this.calendar.finish = false;
    }

    dayClick = (timestamp: number) => {
        const c = this.calendar;
        c.isMainTimePriority = false;
        if (c.finish) this._reset();

        if (!c.begin) {
            // первый клик
            c.begin = c.end = timestamp;
            return;
        }

        c.end = timestamp;
        c.finish = true;

        if (c.begin > c.end) {
            c.end = c.begin;
            c.begin = timestamp;
        }

        c.end = moment(c.end).endOf('day').valueOf();
        c.begin = moment(c.begin).startOf('day').valueOf();

        this.apply();
    };

    dayOver = (timestamp: number) => {
        const c = this.calendar;
        if (c.finish) return;
        c.end = timestamp;
    };

    apply = () => {
        if (!this.calendar.begin || !this.calendar.end) {
            return;
        }

        const begin = this.calendar.begin;
        const end = this.calendar.end;

        this.updateTime(begin, end);

        this.calendar.showCalendar = false;
        this.calendar.finish = true;
        this.calendar.isMainTimePriority = true;

        this.calendar.begin = this.calendar.end = null;
    };

    updateDays = (numberOfFutureDays?: number, minDate?: string) => {
        this.calendar.months = createCalendarDays(this.numberOfMonths, numberOfFutureDays, minDate);
        this.calendar.monthsMobile = this.calendar.months.slice().reverse();
    };
}
