import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';

import { TEXTS } from '@libs/common';
import { DataQualityTimeline } from '@libs/common';
import { detectMobile } from '@libs/common';
import { debounceTime, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

@Component({
    selector: 'ca-track-panel',
    templateUrl: './track-panel.component.html',
    styleUrls: ['./track-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackPanelComponent implements AfterViewInit, OnDestroy {
    @Input() dates: string[];
    @Input() index: number;
    @Input() hasDataByIndex: boolean[] = [];
    @Input() chartEnabled: boolean;
    @Input() showNow: boolean;
    @Input() qualityDataMode: number;
    @Input() dataQuality: DataQualityTimeline[] = [];
    @Input() timezone: string;
    @Output() setPosition = new EventEmitter<number>();
    @Output() showQualityDataInfo = new EventEmitter<number>();

    @ViewChild('trackPanel', { read: ElementRef }) trackPanel: ElementRef<HTMLElement>;

    public nowText = TEXTS.COMMON.now;
    public textsQuality = TEXTS.QUALITY_DATA;
    public textConfig = TEXTS.CONFIG.timeZone;
    private textsMmtNames = TEXTS.NAMES;
    public isMobile = detectMobile();
    private resize = new Subject<void>();
    public currentWidthTimeline: number;
    private onDestroy$ = new Subject<void>();

    constructor(private _changeDetector: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.resize
            .pipe(throttleTime(500), debounceTime(500), takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.setWidth();
            });

        const observer = new ResizeObserver((_) => {
            this.resize.next();
        });

        observer.observe(this.trackPanel.nativeElement);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    private setWidth(): void {
        this.currentWidthTimeline = this.trackPanel.nativeElement.getBoundingClientRect().width;
        this._changeDetector.detectChanges();
    }

    public setIndex(i: number): void {
        this.setPosition.emit(i);
    }

    public openDetailsQuality(index) {
        this.showQualityDataInfo.emit(index);
    }

    public getTooltipText(i) {
        if (this.hasDataByIndex[i] && this.dataQuality[i]) {
            const header = this.textsQuality.tooltipHeader.warning;
            const currentText = [];
            this.dataQuality[i].tooltipData?.forEach((data) => {
                if (this.textsQuality.tooltip[data.key]) {
                    const mmtValues = data.values.map((v) => this.textsMmtNames[v] ?? v);
                    const value = mmtValues.join(', ');
                    currentText.push(this.textsQuality.tooltip[data.key](value));
                }
            });
            const text = currentText.join('<br/>');
            return {
                text: `<div class="header"><b>${header}</b></div><br/>${text}`,
                link: true,
            };
        } else {
            return {
                text: this.textsQuality.tooltip.noData,
                link: false,
            };
        }
    }
}
