import { MeasureScheme } from '../enums/measure-scheme';
import { CH2O, CO, CO2, H2S, NH3, NO, NO2, O3, PM10, PM25, SO2, SUM, TSP } from './substance.consts';

export const pdkcc = {
    [CO]: 3000,
    [CO2]: 900,
    [H2S]: 2,
    [NH3]: 100,
    [NO]: 60,
    [NO2]: 100,
    [O3]: 100,
    [PM10]: 60,
    [PM25]: 35,
    [SO2]: 50,
    [CH2O]: 10,
    [TSP]: 150,
    [SUM]: null,
};

export const pdkmr = {
    [PM25]: 160,
    [PM10]: 300,
    [CO]: 5000,
    [NO2]: 200,
    [SO2]: 500,
    [O3]: 160,
    [NO]: 400,
    [CO2]: 2000,
    [H2S]: 8,
    [NH3]: 200,
    [CH2O]: 50,
    [TSP]: 500,
    [SUM]: null,
};

const _k = Object.keys(pdkcc);

export const PDK_SS = {
    [MeasureScheme.default]: pdkcc,
    [MeasureScheme.usa_default]: pdkcc,
    [MeasureScheme.c_mmhg_mg]: _k.reduce((prev, key) => {
        prev[key] = pdkcc[key] / 1000;
        return prev;
    }, {}),
    [MeasureScheme.mpc]: _k.reduce((prev, key) => {
        prev[key] = pdkcc[key] / pdkmr[key];
        return prev;
    }, {}),
};

export const PDK_MR = {
    [MeasureScheme.default]: pdkmr,
    [MeasureScheme.usa_default]: pdkmr,
    [MeasureScheme.c_mmhg_mg]: _k.reduce((prev, key) => {
        prev[key] = pdkmr[key] / 1000;
        return prev;
    }, {}),
    [MeasureScheme.mpc]: _k.reduce((prev, key) => {
        prev[key] = 1;
        return prev;
    }, {}),
};
