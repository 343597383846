import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderByRegions',
})
export class OrderByRegionsPipe implements PipeTransform {
    transform(items: any[], propName: string, direction = 1) {
        const arr = [...items];
        return arr.sort((a, b) => {
            if (propName.indexOf('.') >= 0) {
                const value = propName.split('.');
                const key = value[0];
                const subKey = value[1];
                a = a[key][subKey];
                b = b[key][subKey];
            } else if (propName === 'post_ids') {
                a = a[propName]?.length;
                b = b[propName]?.length;
            } else if (propName === 'density') {
                a = (a?.post_ids?.length * 4) / a.region_square;
                b = (b?.post_ids?.length * 4) / b.region_square;
            } else {
                a = a[propName];
                b = b[propName];
            }

            if (a < b) {
                return 1 * direction;
            }
            if (a > b) {
                return -1 * direction;
            }

            return 0;
        });
    }
}
