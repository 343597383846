<div class="popup-ask__splash">
    <div class="popup-ask">
        <cross-button
            *ngIf="showCloseIcon"
            class="popup-ask__close"
            (click)="close()"
            [size]="'large'"
        ></cross-button>
        <div class="popup-ask__title">{{ title }}</div>
        <div class="popup-ask__ng-content">
            <ng-content></ng-content>
        </div>
        <div class="popup-ask__description">{{ description }}</div>
        <div class="popup-ask__btn_block">
            <btn-cancel class="popup-ask__btn popup-ask__btn--cancel" (cbClick)="close()">
                {{ textCancel }}
            </btn-cancel>
            <btn-accept
                class="popup-ask__btn popup-ask__btn--accept"
                [isDeleteAction]="isDeleteAction"
                (cbClick)="accept()"
                [isLoading]="isConfirmAccept"
            >
                {{ textAccept }}
            </btn-accept>
        </div>
    </div>
</div>
