<div class="map-style-selector-wrapper" [ngClass]="{'disable': loading }">
    <div #button (click)="toggle()" [className]="'map-style-selector '  + currentType">
        <div class="title">{{title}}</div>
    </div>
    <div class="map-style-list" *ngIf="showPopup"
         (clickOutside)="clickOutside()"
         [ngClass]="{'bottom': position === 'bottom'}"
    >
        <cross-button class="close-button" (click)="close()"></cross-button>
        <div class="title">{{text.title}}</div>
        <div class="list scroll" [ngStyle]="{'height': height + 'px'}">
            <div class="item" *ngFor="let type of types"
                 [ngClass]="{ 'active': type === currentType }"
                 (click)="selectType(type)"
            >
                <div [className]="'img ' + type"></div>
                <div class="label">{{text.labels[type]}}</div>
            </div>
        </div>
    </div>
</div>

