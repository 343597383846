export enum MoExportType {
    mo = 1,
    tza4 = 2,
    moIndoor = 3,
}

export class DownloadPopupData {
    type: MoExportType;
    title: string;
    ids: string[];
    mos?: { id: string; name: string }[];
    devices?: { id: number; serialNumber: string }[];
    currentTabInterval?: number;
}

export type ModelDownloadPopup = {
    timeBegin: number;
    timeEnd: number;
    downloadType: MoExportType;
    downloadTitle: string;
};
