<div class="selectbox-radio">
    <div class="selectbox-radio__list scroll" [formGroup]="form">
        <div class="selectbox-radio__list-item" *ngFor="let item of listItems" [ngClass]="{'with-icon': item.icon }">
            <label class="selectbox-radio__list-item__label" [ngClass]="{'right-position': !isLeftCheckPosition , 'with-icon': item.icon }">
                <input type="radio" formControlName="selectedItem" [value]="item.id" />
                <item-radio *ngIf="isLeftCheckPosition"
                    class="selectbox-radio__list-item__checkmark"
                    [check]="item.selected"
                ></item-radio>
                <div class="wrapper-label">
                    <ca-menu-button *ngIf="item.icon"
                        [showTooltip]="false"
                        class="icon-module"
                        name="{{item.icon}}"
                    ></ca-menu-button>
                    <div class="selectbox-radio__list-item__text"
                         [ngClass]="{'selected': item.selected }"
                         [innerHTML]="item.label"
                    ></div>
                </div>
                <item-radio *ngIf="!isLeftCheckPosition"
                    class="selectbox-radio__list-item__checkmark"
                    [check]="item.selected"
                ></item-radio>
            </label>
        </div>
    </div>
</div>
