import {
    createAction,
    createFeature,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';
import * as moment from 'moment-timezone';
export const FORESACT_BEGIN_TIME = moment().startOf('hour').subtract(24, 'hour').valueOf();
export const FORESACT_END_TIME = moment().add(36, 'hour').endOf('hour').valueOf();
export const FORESACT_AVAILABLE_BEGIN_TIME = moment()
    .startOf('hour')
    .subtract(6, 'months')
    .valueOf();
export enum ForecastActions {
    setMmtsPublicForecast = '[PublicForecastActions] set mmt public Forecast',
    setActiveMmt = '[PublicForecastActions] set active mmt',
    toggleShowPublicForecast = '[PublicForecastActions] toggle show forecast',
    toggleShowLensForecast = '[PublicForecastActions] toggle show lens forecast',
}
export const setMmtsPublicForecast = createAction(
    ForecastActions.setMmtsPublicForecast,
    props<{ payload: string[] }>()
);
export const setActiveMmt = createAction(
    ForecastActions.setActiveMmt,
    props<{ payload: string }>()
);
export const toggleShowPublicForecast = createAction(
    ForecastActions.toggleShowPublicForecast,
    props<{ payload: boolean }>()
);
export const toggleShowLensForecast = createAction(
    ForecastActions.toggleShowLensForecast,
    props<{ payload: boolean }>()
);
type ForecastState = {
    mmts: string[];
    activeMmt: string;
    showLayer: boolean;
    showLensLayer: boolean;
};
const initialState: ForecastState = {
    mmts: [],
    activeMmt: null,
    showLayer: false,
    showLensLayer: false,
};

export const publicForecastFeature = createFeature({
    name: 'publicForecast',
    reducer: createReducer(
        initialState,
        on(setMmtsPublicForecast, (state, { payload }) => ({
            ...state,
            mmts: payload,
            activeMmt: payload[0],
        })),
        on(setActiveMmt, (state, { payload }) => ({
            ...state,
            activeMmt: payload,
        })),
        on(toggleShowPublicForecast, (state, { payload }) => ({
            ...state,
            showLayer: payload,
        })),
        on(toggleShowLensForecast, (state, { payload }) => ({
            ...state,
            showLensLayer: payload,
        }))
    ),
});

const featureSelector = createFeatureSelector<ForecastState>('publicForecast');

export const selectMmtsPublicForecast = createSelector(featureSelector, (state) => state.mmts);
export const selectActiveMmtPublicForecast = createSelector(
    featureSelector,
    (state) => state.activeMmt
);
export const selectIsShowPublicForecast = createSelector(
    featureSelector,
    (state) => state.showLayer
);
export const selectIsShowLensForecast = createSelector(
    featureSelector,
    (state) => state.showLensLayer
);
