import { Pipe, PipeTransform } from '@angular/core';
import { EventItem } from '@cityair/modules/impact/service/api-model-impact';

import { Dictionary } from '@ngrx/entity';
import { CorrelationData, Post } from '@libs/common';
import { sortPostByDistance } from '@libs/common';

@Pipe({
    name: 'correlationChartData',
})
export class CorrelationChartDataPipe implements PipeTransform {
    transform(
        eventItem: EventItem,
        mmt: string,
        postDic: Dictionary<Post>,
        correlationData: CorrelationData
    ) {
        if (eventItem && correlationData && correlationData[mmt]) {
            const posts = Object.values(postDic);
            const currentPostId = eventItem.post_id;
            if (postDic[currentPostId] && posts?.length) {
                sortPostByDistance(postDic[currentPostId], posts);
                const labels = posts.map((v) => v.name);

                const data = correlationData[mmt]['3m']?.coefficients?.[currentPostId];
                const data24hour = getCorrForPosts(
                    posts,
                    correlationData[mmt]['1d']?.coefficients?.[currentPostId]
                );
                const datasets = getCorrForPosts(posts, data);
                return {
                    datasets,
                    labels,
                    data24hour,
                };
            }
            return null;
        }

        return null;
    }
}
function getCorrForPosts(posts: Post[], data): number[] {
    if (posts.length && data) {
        const result = [];
        const id = posts[0]?.id ?? null;
        if (id) {
            posts.forEach((post) => {
                if (post.id !== id) {
                    const value = data[post.id] ?? null;
                    result.push(value);
                }
            });
        }
        return result;
    }
    return [];
}
