import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { LayerButtonComponent } from './components/layer-button/layer-button.component';
import { LayerButtonItemComponent } from './components/layer-button-item/layer-button-item.component';
import { LayerButtonTooltipComponent } from './components/layer-button-tooltip/layer-button-tooltip.component';

@NgModule({
    imports: [CommonModule, DirectivesModule, TooltipsModule],
    exports: [LayerButtonComponent, LayerButtonItemComponent, LayerButtonTooltipComponent],
    declarations: [LayerButtonComponent, LayerButtonItemComponent, LayerButtonTooltipComponent],
})
export class OverlayModule {}
