import { BBox, Point } from 'geojson';
import { ContributionsSeries, Timeseries } from './feature';

export interface DateRangeImpact {
    start: string;
    end: string;
}
export interface DomainImpact {
    id: number;
    name: string;
    bbox: BBox;
    centroid: Point;
    zoom: number;
    group_id: number;
    obj: string;
}
export interface Emission {
    declared_mass: number;
    declared_mass_year?: number;
    species: string;
    modeling_mass: number;
}
export interface SourceImpact {
    id: number;
    name: string;
    centroid: Point;
    params: string;
    emissions: Emission[];
    group_id: number;
    obj: string;
    species: string;
    bbox: number[];
    sourceType?: string;
    geo_type: string;
    max_height: number;
    shape: number;
    area_size: number;
    icon_type: string;
}
export enum SourceType {
    area = "AREA",
    point = "POINT"
}
export interface ControlPointImpact {
    id: number;
    lat: number;
    lon: number;
    name: string;
    obj: string;
    group_id?: string;
    timeseries?: Timeseries;
    contributions?: ContributionsSeries;
}
export interface ControlPointImpactRequest {
    id?: number;
    lat?: number;
    lon?: number;
    name?: string;
    obj?: string;
    group_id?: string;
}
export interface ControlPointTimeline {
    control_point: number;
    timeseries?: Timeseries;
    contributions?: ContributionsSeries;
}
export interface RunImpactAnnotation {
    item: RunImpact;
    annotation: string;
}
export interface RunImpact {
    id: number;
    config: number;
    evaluation_time: string;
    created_at: string;
    started_at: string;
    finished_at: string;
    duration_minutes: number;
    scheduled_at: string;
    heights: number[];
    domain: DomainImpact;
    sources: number[];
    species_list: string[];
    default_species: string;
    control_points_report: ControlPointImpact[];
    sources_snapshot: SourceImpact[];
    step_minutes: number;
    obj: string;
    wind_on: boolean;
    wind_domain: BBox;
}
export interface RunPlumeAnnotation {
    item: RunConfigImpact;
    annotation: string;
}
export class RunConfigImpact {
    id: number;
    name: string;
    config: number;
    duration_minutes: number;
    group_id: number;
    is_active: boolean;
    domain: DomainImpact;
    sources: number[];
    sources_order?: number[];
    sources_snapshot: SourceImpact[];
    step_minutes: number;
    obj: string;
    heights: number[];
    default_species: string;
    species_list: string[];
    schedule_period: number;
    slug: string;
    tiles_on: boolean;
    wind_on: boolean;
    wind_domain: BBox;
}
export interface RunConfigImpactEdit {
    id: number;
    name?: string;
    schedule_period?: number;
    is_active?: boolean;
}
export interface windLayerParams {
    url: string;
    bboxDomain: BBox;
}
