import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'indoor-marker',
    templateUrl: './indoor-marker.component.html',
    styleUrls: ['./indoor-marker.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndoorMarkerComponent implements OnInit {
    @Input() name: string;
    @Input() color = '#404655';
    @Input() selected: boolean;
    constructor() {}

    ngOnInit(): void {}
}
