<div class="login-form" [ngClass]="{ 'airvoice': isAirVoiceDomain }">
    <ca-demo-badge class="login-form--demo" *ngIf="clientConfig.isDemo"></ca-demo-badge>

    <ng-container *ngIf="clientConfig.disableBranding.all; else defaultTitle">
        <div class="login-title login-form-title">{{ TEXTS.LOGIN_WINDOWS.clientSignIn }}</div>
    </ng-container>
    <ng-template #defaultTitle>
        <ng-container *ngIf="isAirVoiceDomain else basicTitle">
            <div class="login-title login-form-title">Sign in to</div>
            <div class="login-subtitle login-form-subtitle">
                Outdoor Air Quality Management Platform
            </div>
        </ng-container>
        <ng-template #basicTitle>
            <div class="login-title login-form-title">{{ TEXTS.LOGIN_WINDOWS.cityscreenSignIn }}</div>
        </ng-template>
    </ng-template>

    <div class="login-subtitle login-form-subtitle" *ngIf="!isAirVoiceDomain">
        {{ subtitle || TEXTS.LOGIN_WINDOWS.toolsFor }}
    </div>

    <form class="login__fieldset" (ngSubmit)="onSubmit()" [formGroup]="loginForm" novalidate>
        <ng-container *ngIf="isAirVoiceDomain else basicForm">
             <div class="input-wrapper">
                 <ca-input
                    class="ca-login-input"
                    type="text"
                    controlName="login"
                    [autocomplete]="true"
                    [autofocus]="true"
                    [form]="loginForm"
                    [valueForm]="getValueForm('login')"
                    [label]="'Email'"
                    [textError]="getError('login')"
                ></ca-input>
            </div>
            <div class="input-wrapper">
                <ca-input
                    class="ca-login-input"
                    type="password"
                    controlName="password"
                    [autocomplete]="true"
                    [form]="loginForm"
                    [valueForm]="getValueForm('password')"
                    [label]="'Password'"
                    [textError]="getError('password')"
                ></ca-input>
            </div>
            <button
                type="submit"
                [disabled]="isLoading"
                class="login-submit-button">
                <ca-login-button [disabled]="isLoading" [showGradient]="true">
                    <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
                    <ng-template #showContent>Sign in</ng-template>
                </ca-login-button>
            </button>
        </ng-container>
        <ng-template #basicForm>
            <input type="submit" [style.display]="'none'" />

            <div class="login-input-group login-form-input-group">
                <input
                    class="login-input"
                    type="text"
                    autocomplete="on"
                    formControlName="login"
                    data-cy="login"
                    [class.login-input--danger]="hasErrors"
                    [attr.placeholder]="TEXTS.LOGIN_WINDOWS.login"
                />
            </div>

            <div class="login-input-group login-form-input-group">
                <input
                    class="login-input"
                    type="{{ showPassword ? 'text' : 'password' }}"
                    autocomplete="on"
                    formControlName="password"
                    data-cy="password"
                    [class.login-input--danger]="hasErrors"
                    [attr.placeholder]="TEXTS.LOGIN_WINDOWS.password"
                />

                <ca-eye-icon
                    class="login-form-input__eye"
                    [isOpened]="showPassword"
                    (click)="showPassword = !showPassword"
                ></ca-eye-icon>

                <div class="login__info login-form__info">
                    <div class="login-errors login-form__errors scroll">
                        <ng-container *ngIf="hasErrors">
                            {{ (harvester.formError$ | async) || TEXTS.LOGIN_WINDOWS.wrong }}
                        </ng-container>
                    </div>

                    <a
                        class="login-nav-link login-form__change-password"
                        [routerLink]="[loginPage.PasswordReset]"
                    >
                        {{ TEXTS.LOGIN_WINDOWS.forgot }}
                    </a>
                </div>
            </div>

            <button type="submit" class="login-submit-button" data-cy="submit">
                <ca-login-button>
                    <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
                    <ng-template #showContent>{{ TEXTS.LOGIN_WINDOWS.sign }}</ng-template>
                </ca-login-button>
            </button>
        </ng-template>
    </form>

    <cs-market-links *ngIf="isShowMarketLinks"></cs-market-links>
</div>
