<svg
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="selected ? '42' : '35' + 'px'"
    [attr.height]="selected ? '54' : '45' + 'px'"
    [ngStyle]="{
        opacity: opacity,
        visibility: opacity !== 0 ? 'visible' : 'hidden'
    }"
    [attr.viewBox]="selected ? '0 0 42 54' : '0 0 35 45'"
    x="0px"
    y="0px"
    (mouseenter)="isMarkerOver = true"
    (mouseleave)="isMarkerOver = false"
>
    <g>
        <ng-container *ngIf="selected; else defaultTemplate">
            <path
                d="M20.9992 0C9.40193 0 0 9.612 0 21.4684C0 29.2237 4.02453 36.0132 10.0539 39.7836C12.7723 41.4836 15.4071 43.4813 16.9122 46.3124L19.0631 50.3584C19.8879 51.9097 22.1108 51.9098 22.9358 50.3587L25.0883 46.3115C26.5931 43.482 29.2265 41.4852 31.9438 39.786C37.9744 36.0151 42 29.2251 42 21.4701C41.9983 9.612 32.5964 0 20.9992 0Z"
                [attr.fill]="getMainColor()"
            />
            <rect
                x="7"
                y="7"
                width="28"
                height="28"
                rx="14"
                stroke="white"
                stroke-width="1.83902"
                [attr.fill]="getMainColor()"
            />
            <text
                fill="white"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Inter"
                line-height="18px"
                font-size="16"
                font-weight="600"
                letter-spacing="-0.52px"
            >
                <tspan [attr.x]="getXPosition(true)" y="26.7182">{{ getTextValue() }}</tspan>
            </text>
        </ng-container>
        <ng-template #defaultTemplate>
            <path
                d="M17.4993 0C7.83494 0 0 8.01 0 17.8903C0 24.0502 3.04675 29.4788 7.68288 32.6948C10.3174 34.5223 12.9793 36.4979 14.4844 39.329L15.5633 41.3584C16.388 42.9097 18.611 42.9098 19.4359 41.3587L20.5161 39.3278C22.0209 36.4983 24.6814 34.5235 27.3148 32.6971C31.9522 29.4807 35 24.0515 35 17.8917C34.9986 8.01 27.1637 0 17.4993 0Z"
                [attr.fill]="getMainColor()"
            />
            <rect x="6" y="6" width="23" height="23" rx="11.5" fill="white" />
            <text
                [attr.fill]="getMainColor()"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Inter"
                line-height="15px"
                font-size="13"
                font-weight="600"
                letter-spacing="-0.31px"
            >
                <tspan [attr.x]="getXPosition(false)" [attr.y]="canSelected ? 21 : 21.5182">
                    {{ getTextValue() }}
                </tspan>
            </text>
        </ng-template>
    </g>
</svg>
