import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@libs/shared-ui';
import { ControlPointPlumePinComponent } from '@cityair/components/control-point-plume-pin/control-point-plume-pin.component';
import { BaseChartDirective } from 'ng2-charts';
@NgModule({
    exports: [ControlPointPlumePinComponent],
    declarations: [ControlPointPlumePinComponent],
    imports: [CommonModule, PipesModule, BaseChartDirective],
})
export class ControlPointPlumePinModule {}
