import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'switch-v2',
    templateUrl: './switch-v2.component.html',
    styleUrls: ['./switch-v2.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchV2Component {
    @Input() text = '';
    @Input() disabled = false;
    @Input() active = false;
    @Output() activeChange = new EventEmitter<boolean>();

    toggle() {
        if (!this.disabled) {
            this.activeChange.emit(!this.active);
        }
    }
}
