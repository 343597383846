import { Component, Input } from '@angular/core';

export type AlertTagsType = 'red' | 'yellow' | 'green' | 'gray' | 'royalBlue';

@Component({
    selector: 'alert-tags',
    template: `<div class="alert_tags ellipsis alert_tags-{{ typeTag }}">{{ text }}</div>`,
    styleUrls: ['alert-tags.component.less'],
})
export class AlertTagsComponent {
    @Input() typeTag: AlertTagsType = 'gray';
    @Input() text = '';
}
