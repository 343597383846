import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortListItemComponent } from './sort-list-item.component';

@NgModule({
    imports: [CommonModule],
    exports: [SortListItemComponent],
    declarations: [SortListItemComponent],
})
export class SortListItemModule {}
