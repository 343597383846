import { Injectable } from '@angular/core';
import { EntityAction, EntityActionFactory, EntityOp, MergeStrategy } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import {
    getErrorTypeByReportStatus,
    IReport,
    IReportKind,
    IReportKindParams,
    Reports,
} from '@libs/common';
import {
    addReportError,
    downloadError,
    downloadSuccess,
    reloadData,
    updateReportError,
    updateReportSuccess,
    ReportActions,
    setAuthVangaError,
    setKindNotFound,
    setLastReportsStatus,
    updateCurrentReportKind,
} from './actions';

import {
    isActiveReport,
    selectCurrentKind,
    selectCurrentKindId,
    selectLastReportStatus,
    selectReportsForUpdate,
} from './selectors';
import { IReportsState } from './reducers';
import { iif, Observable, of } from 'rxjs';
import { ReportApiService } from '../../services/reports/report-api.service';
import { TEXTS, REPORT_STATUS } from '@libs/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { SharedCoreActions, VangaAuthService } from '@libs/shared-store';

const REPORT_KIND_MANY_LOADED_ERROR_ACTION = new EntityActionFactory().create<IReportKind>(
    'ReportKind',
    EntityOp.QUERY_MANY_ERROR
).type;
const REPORT_ADD_ONE_ERROR = new EntityActionFactory().create<IReport>(
    'Report',
    EntityOp.SAVE_ADD_ONE_ERROR
).type;

export const REPORT_UPDATE_ONE_ERROR = new EntityActionFactory().create<IReport>(
    'Report',
    EntityOp.SAVE_UPDATE_ONE_ERROR
).type;
export const REPORT_UPDATE_ONE_SUCCESS = new EntityActionFactory().create<IReport>(
    'Report',
    EntityOp.SAVE_UPDATE_ONE_SUCCESS
).type;
export const QUERY_BY_KEY_SUCCESS = new EntityActionFactory().create<IReport>(
    'Report',
    EntityOp.QUERY_BY_KEY_SUCCESS
).type;
export const KIND_QUERY_BY_KEY_SUCCESS = new EntityActionFactory().create<IReport>(
    'ReportKind',
    EntityOp.QUERY_BY_KEY_SUCCESS
).type;
export const KIND_QUERY_BY_KEY_ERROR = new EntityActionFactory().create<IReport>(
    'ReportKind',
    EntityOp.QUERY_BY_KEY_ERROR
).type;
export const SAVE_DELETE_ONE_ERROR = new EntityActionFactory().create<IReport>(
    'Report',
    EntityOp.SAVE_DELETE_ONE_ERROR
).type;
export function getReportKindById(id) {
    return new EntityActionFactory().create<IReportKindParams>(
        'ReportKind',
        EntityOp.QUERY_BY_KEY,
        id
    );
}
export function onLoadReports(params) {
    return new EntityActionFactory().create<IReportKindParams>(
        'Report',
        EntityOp.QUERY_MANY,
        params
    );
}
export function getReportById(params) {
    return new EntityActionFactory().create<IReport>('Report', EntityOp.QUERY_BY_KEY, params);
}
@Injectable()
export class ReportsEffects {
    private translateText = TEXTS.REPORTS;
    constructor(
        private actions$: Actions,
        private store: Store<IReportsState>,
        private reportApi: ReportApiService,
        private vangaAuthService: VangaAuthService
    ) {}

    loadReportKindById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActions.setCurrentKindId),
            withLatestFrom(this.store.select(selectCurrentKind)),
            filter(([_, currentKind]) => !currentKind),
            switchMap(
                (
                    [action, current] // @ts-ignore
                ) => [getReportKindById(action.id)]
            )
        )
    );

    getReportKindSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(KIND_QUERY_BY_KEY_SUCCESS),
            map((action: EntityAction) => updateCurrentReportKind({ payload: action.payload.data }))
        )
    );

    getReportKindError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(KIND_QUERY_BY_KEY_ERROR),
            switchMap((action: EntityAction) => {
                const actions = [];
                const status = action.payload?.data?.error?.error?.status;
                if (status && status === HttpStatusCode.Unauthorized) {
                    actions.push(setAuthVangaError({ payload: true }));
                } else {
                    actions.push(setKindNotFound({ payload: true }));
                }

                return actions;
            })
        )
    );

    createErrorReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REPORT_ADD_ONE_ERROR),
            switchMap((action: EntityAction) => {
                return iif(
                    () =>
                        action.payload?.data?.error?.error?.status === HttpStatusCode.Unauthorized,
                    this.vangaAuthService.getVangaToken().pipe(
                        map((response) => {
                            if (response?.access) {
                                this.vangaAuthService.setAccessToken(response.access);
                            }

                            return addReportError({
                                payload: action.payload?.data?.error,
                                actionData: action?.payload?.data?.originalAction,
                            });
                        }),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(addReportError({ payload: action.payload?.data?.error }))
                        )
                    ),
                    [addReportError({ payload: action.payload?.data?.error })]
                );
            })
        )
    );

    updateStatusForReportList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActions.updateListByStatus),
            withLatestFrom(this.store.select(selectReportsForUpdate)),
            filter(([_, reports]) => !!reports.length),
            switchMap(([action, reports]) => {
                const actions = [];
                const statusArray = Object.assign(
                    {},
                    ...reports.map((report) => ({ [report.id]: report.status }))
                );
                actions.push(setLastReportsStatus({ payload: statusArray }));
                reports.forEach((report) => actions.push(getReportById(report.id.toString())));
                return actions;
            })
        )
    );

    showMessagesStatusChanged = createEffect(() =>
        this.actions$.pipe(
            ofType(QUERY_BY_KEY_SUCCESS),
            withLatestFrom(this.store.select(selectLastReportStatus)),
            switchMap(([action, obj]) => {
                const actions = [];
                const report = (action as EntityAction).payload.data;
                const id = report?.id;
                if (id && obj && obj.hasOwnProperty(id) && obj[id] !== report.status) {
                    actions.push(
                        SharedCoreActions.setInfoMessage({
                            payload: {
                                id: new Date().valueOf(),
                                message:
                                    report.status === REPORT_STATUS.ERROR
                                        ? this.translateText.messages[REPORT_STATUS.ERROR].text(
                                              report.name
                                          )
                                        : this.translateText.messages[report.status].title(
                                              report.name
                                          ),
                                positionX: 'right',
                                positionY: 'bottom',
                                iconClass: getErrorTypeByReportStatus(report.status),
                                duration: 3000,
                                showCloseIcon: true,
                                size: 'lg',
                            },
                        })
                    );
                }

                return actions;
            })
        )
    );

    errorLoadReportKind$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REPORT_KIND_MANY_LOADED_ERROR_ACTION),
            switchMap((action: EntityAction) => {
                const actions = [];
                const status = action.payload?.data?.error?.error?.status;
                if (status && status === HttpStatusCode.Unauthorized) {
                    actions.push(setAuthVangaError({ payload: true }));
                }
                return actions;
            })
        )
    );

    authErrorLoadControlPoints$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActions.setAuthVangaError),
            take(1),
            switchMap((action: EntityAction) => {
                const actions = [];
                if (action.payload) {
                    actions.push(SharedCoreActions.vangaTokenRefresh());
                }
                return actions;
            })
        )
    );

    updateVangaToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedCoreActions.vangaTokenUpdated),
            withLatestFrom(
                this.store.select(isActiveReport),
                this.store.select(selectCurrentKindId)
            ),
            filter(([_, isActive, currentKindId]) => isActive),
            switchMap(([action, isActive, currentKindId]) => {
                const actions = [];
                if (currentKindId) {
                    actions.push(getReportKindById(currentKindId));
                } else {
                    actions.push(reloadData({ payload: true }));
                }

                return actions;
            })
        )
    );

    downloadReport$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ReportActions.downloadReport),
                switchMap((action: Reports) =>
                    this.reportApi.downloadFile(action).pipe(
                        map((response: { presigned_url: string }) => {
                            if (response && response.presigned_url) {
                                return downloadSuccess({
                                    payload: { url: response.presigned_url, report: action },
                                });
                            }
                        }),
                        catchError((error) => of(downloadError({ payload: error })))
                    )
                )
            ) as Observable<Action>
    );

    updateErrorReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REPORT_UPDATE_ONE_ERROR),
            switchMap((action: EntityAction) => {
                const actions = [];
                let data = null;
                let report = action.payload?.data?.originalAction?.payload?.data?.changes;
                if (action.payload?.data?.error?.error) {
                    data = action.payload?.data.error;
                }
                if (report) {
                    report = report as Reports;
                }
                actions.push(updateReportError({ payload: { error: data, report: report } }));
                return actions;
            })
        )
    );

    updateSuccessReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REPORT_UPDATE_ONE_SUCCESS),
            switchMap((action: EntityAction) => {
                const actions = [];
                let report = action.payload?.data?.changes;
                if (report) {
                    report = report as Reports;
                }
                actions.push(updateReportSuccess({ payload: report }));
                return actions;
            })
        )
    );

    deleteReportError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SAVE_DELETE_ONE_ERROR),
            switchMap((action: EntityAction) => {
                const actions = [];
                const id = action.payload?.data?.originalAction?.payload?.data;
                if (id) {
                    actions.push(
                        SharedCoreActions.setInfoMessage({
                            payload: {
                                id: new Date().valueOf(),
                                message: this.translateText.messageDeleteError,
                                positionX: 'right',
                                positionY: 'bottom',
                                iconClass: 'error',
                                duration: 3000,
                                showCloseIcon: true,
                                size: 'lg',
                            },
                        })
                    );
                    const undoOneAction = new EntityActionFactory().create(
                        'Report',
                        EntityOp.UNDO_ONE,
                        action.payload.data.originalAction.payload.data,
                        {
                            correlationId: action.payload.data.originalAction.payload.correlationId,
                            mergeStrategy: MergeStrategy.PreserveChanges,
                            isOptimistic: true,
                        }
                    );
                    actions.push(undoOneAction);
                }

                return actions;
            })
        )
    );
}
