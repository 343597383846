import { Injectable, NgZone } from '@angular/core';
import { Map as Mapbox } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';

import * as MapboxTypos from '@cityair/modules/map/components/mapbox/mapbox-typos.json';
import {
    MAPBOX_BOUNDARY_LAYERS,
    MAPBOX_LABELS_COLOR,
    MAPBOX_STYLES,
} from '@cityair/libs/shared/utils/config';
import { LANGUAGE } from '@libs/common';

@Injectable({
    providedIn: 'root',
})
export class MapboxFacadeService {
    private map: Mapbox;

    constructor(private zone: NgZone) {}

    setMap(map: Mapbox) {
        this.map = map;
    }

    private _stylesReady$ = new BehaviorSubject<boolean>(false);
    stylesReady$ = this._stylesReady$.asObservable();

    configureMapAfterStyleLoad(callback: () => void = () => {}) {
        const sourceHandler = ({ isSourceLoaded, source }) => {
            if (isSourceLoaded) {
                this.zone.runOutsideAngular(() => {
                    this.map?.off('sourcedata', sourceHandler);
                });
                callback();
                this._stylesReady$.next(true);
            }
        };

        this.zone.runOutsideAngular(() => {
            this.map?.on('sourcedata', sourceHandler);
        });
    }

    skipCustomStyles() {
        this.configureMapAfterStyleLoad(() => {
            this._setMapLanguage(LANGUAGE);
            this.map?.touchZoomRotate.disableRotation();
        });
    }

    applyCustomStyles() {
        this.configureMapAfterStyleLoad(() => {
            this._applyCustomStyles();
            this._setMapLanguage(LANGUAGE);
            this.map?.touchZoomRotate.disableRotation();
        });
    }

    private _applyCustomStyles() {
        const { map } = this;
        // Render under the labels.
        // this.beforeLayerId = map.getStyle().layers.find(l => l.type === 'symbol').id;

        ['hillshade', ...MAPBOX_BOUNDARY_LAYERS]
            .filter((layerName) => map.getLayer(layerName))
            .forEach((layerName) => {
                map.setLayoutProperty(layerName, 'visibility', 'none');
            });

        map.getStyle()
            .layers.filter((layer) => layer.id.endsWith('-label'))
            .forEach(({ id }) => {
                map.setPaintProperty(id, 'text-color', MAPBOX_LABELS_COLOR);
            });

        Object.entries(MAPBOX_STYLES)
            .filter(([layerName]) => map?.getLayer(layerName))
            .forEach(([layerName, styles]) => {
                Object.entries(styles).forEach(([style, value]) => {
                    map?.setPaintProperty(layerName, style, value);
                });
            });
    }

    private _setMapLanguage(lang: string) {
        const { map } = this;
        const textField = ['coalesce', ['get', 'name_' + lang], ['get', 'name']];
        map?.getStyle().layers.forEach(function (layer) {
            if (layer.id === 'poi-label') {
                map.setLayoutProperty(layer.id, 'visibility', 'none');
            }
            // hidden boundary line
            if (layer.id.includes('boundary')) {
                map.setLayoutProperty(layer.id, 'visibility', 'none');
            }
        });
        map?.getStyle()
            .layers.filter((layer) => layer.id.endsWith('-label'))
            .forEach(({ id }) => {
                map.setLayoutProperty(id, 'text-field', textField);

                // Dynamically fix mapbox typos
                const typos = MapboxTypos[lang]?.[id];

                if (typos) {
                    map.setLayoutProperty(
                        id,
                        'text-field',
                        this.getTypoFixExpression(typos, `name_${lang}`)
                    );
                }
            });
    }

    private getTypoFixExpression(typos: [], field: string) {
        return [
            'case',
            ...typos.reduce(
                (rules: any, names: { [key: string]: string }) => [
                    ...rules,
                    ['==', ['get', 'name_en'], names.name_en],
                    names[field],
                ],
                []
            ),
            ['get', field],
        ];
    }
}
