import { Component, OnDestroy } from '@angular/core';

import { TEXTS } from '@libs/common';
import { Store } from '@ngrx/store';
import {
    selectCurrentFormType,
    selectCurrentSettingForm,
    selectIsLoadingForm,
} from '@cityair/modules/notifications/store/selectors';
import { NotificationType } from '@libs/common';
import { closeSettingForm } from '@cityair/modules/notifications/store/actions';
import { selectGroupId, selectCurrentUser } from '@libs/shared-store';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['settings.component.less'],
})
export class Settings implements OnDestroy {
    textsNotification = TEXTS.NOTIFICATIONS;
    selectCurrentFormType = selectCurrentFormType;
    selectCurrentSettingForm = selectCurrentSettingForm;
    selectIAm = selectCurrentUser;
    selectGroupId = selectGroupId;
    selectIsLoadingForm = selectIsLoadingForm;

    NotificationType = NotificationType;
    constructor(public store: Store) {}

    ngOnDestroy() {}
    closeFromSetting($event) {
        this.store.dispatch(closeSettingForm({ settings_type: $event }));
    }
}
