import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'shared-ui-ca-toggle-button',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './ca-toggle-button.component.html',
    styleUrl: './ca-toggle-button.component.less',
})
export class CaToggleButtonComponent {
    @Input() active = false;
    @Input() disable = false;
    @Output() activeChange = new EventEmitter<boolean>();
    toggle() {
        if (!this.disable) {
            this.activeChange.emit(!this.active);
        }
    }
}
