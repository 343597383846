<div class="tags-list">
    <div
        class="tags-list__item"
        *ngFor="let tag of tags | slice : 0 : (isExpanded ? undefined : truncateTo); index as i"
    >
        {{ tagsMapper(tag) }}
        <cross-button
            class="tags-list__delete"
            size="small"
            *ngIf="editable"
            (click)="remove(i)"
        ></cross-button>
    </div>
    <ng-container *ngIf="textNodesFns">
        <div
            class="tags-list__item tags-list__item--expand"
            *ngIf="!isExpanded && tags.length - truncateTo > 0"
            (click)="isExpanded = true"
        >
            {{ textNodesFns.expand(tags.length - truncateTo) }}
        </div>
        <div
            class="tags-list__item tags-list__item--expand"
            *ngIf="isExpanded && tags.length - truncateTo > 0"
            (click)="isExpanded = false"
        >
            {{ textNodesFns.collapse() }}
        </div>
    </ng-container>
</div>
