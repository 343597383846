import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipsModule } from '@libs/shared-ui';
import { IconsModule } from '@libs/shared-ui';
import { AnalyticChartTitleComponent } from './analytic-chart-title.component';

@NgModule({
    imports: [CommonModule, IconsModule, TooltipsModule],
    declarations: [AnalyticChartTitleComponent],
    exports: [AnalyticChartTitleComponent],
})
export class AnalyticChartTitleModule {}
