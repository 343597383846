import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule, DefaultDataServiceConfig } from '@ngrx/data';

import { SidebarToggleButtonModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { DirectivesModule } from '@libs/shared-ui';
import { SortListItemModule } from '@libs/shared-ui';
import { KebabMenuModule } from '@libs/shared-ui';
import { PageTabsModule } from '@libs/shared-ui';
import { OverlayModule } from '@libs/shared-ui';
import { ButtonAddModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';
import { NonDataScreenModule } from '@libs/shared-ui';
import { HeaderOfPanelModule } from '@libs/shared-ui';
import { InputModule } from '@libs/shared-ui';
import { InputForCoordinateModule } from '@libs/shared-ui';
import { BtnAcceptModule } from '@libs/shared-ui';
import { BtnExtraModule } from '@libs/shared-ui';
import { SectionLineModule } from '@libs/shared-ui';
import { PopupAskModule } from '@libs/shared-ui';
import { CardActionsModule } from '@libs/shared-ui';
import { StaticTagsModule, CalendarMonthsModule } from '@libs/shared-ui';
import { CityscreenComponentsModule } from '@cityair/components/cityscreen-components.module';
import { ColorLegendModule } from '@libs/shared-ui';
import { ControlPointsComponent } from './components/control-points/control-points.component';
import { ControlPointComponent } from './components/control-point/control-point.component';
import { ForecastMainComponent } from './components/forecast-main/forecast-main.component';
import { ForecastMapComponent } from './components/forecast-map/forecast-map.component';
import { CreateControlPointComponent } from './components/create-control-point/create-control-point.component';
import { ForecastTopElements } from './components/forecast-top-elements/forecast-top-elements';
import { ForecastCalendarComponent } from './components/forecast-top-elements/forecast-calendar/forecast-calendar.component';
import { MapStyleSelectorComponent } from '@libs/shared-ui';

import { FORECAST_API_URL } from './constants';
import { forecastRoutes } from './forecast-routing';
import { forecastReducers } from './store/reducers';
import { ForecastEffects } from './store/effects';
import { entityForecastConfig } from './entity-metadata';
import { EntityStoreForecastModule } from './entity-store.module';
import { BtnCancelModule } from '@libs/shared-ui';
import { PipesModule } from '@libs/shared-ui';
import { AqiDetailsTooltipModule } from '@libs/shared-ui';
import { TimelinePanelModule } from '@libs/shared-ui';
import { MapControlModule } from '@libs/shared-ui';
import { MmtSelectorModule } from '@libs/shared-ui';
import { InputForDropdownModule } from '@libs/shared-ui';
import { GetValueControlPointPipe } from './pipes/get-value-control-point.pipe';
import { InfoIconModule } from '@libs/shared-ui';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
    root: FORECAST_API_URL,
};

@NgModule({
    declarations: [
        ControlPointsComponent,
        ControlPointComponent,
        ForecastMainComponent,
        CreateControlPointComponent,
        ForecastTopElements,
        ForecastCalendarComponent,
        ForecastMapComponent,
    ],
    imports: [
        RouterModule,
        RouterModule.forChild(forecastRoutes),
        CommonModule,
        StoreModule.forFeature('forecast', forecastReducers),
        EffectsModule.forFeature([ForecastEffects]),
        EntityDataModule.forRoot(entityForecastConfig),
        EntityStoreForecastModule,
        FormsModule,
        ReactiveFormsModule,
        PageTabsModule,
        OverlayModule,
        ButtonAddModule,
        LoadersModule,
        NonDataScreenModule,
        HeaderOfPanelModule,
        InputModule,
        InputForCoordinateModule,
        BtnAcceptModule,
        BtnCancelModule,
        BtnExtraModule,
        SectionLineModule,
        SortListItemModule,
        PipesModule,
        KebabMenuModule,
        PopupAskModule,
        StaticTagsModule,
        CardActionsModule,
        DirectivesModule,
        CityscreenComponentsModule,
        CalendarMonthsModule,
        AqiDetailsTooltipModule,
        ColorLegendModule,
        TimelinePanelModule,
        MapControlModule,
        MmtSelectorModule,
        InputForDropdownModule,
        MapStyleSelectorComponent,
        GetValueControlPointPipe,
        TooltipsModule,
        InfoIconModule,
        SidebarToggleButtonModule,
    ],
    exports: [ForecastMapComponent],
    providers: [
        {
            provide: DefaultDataServiceConfig,
            useValue: defaultDataServiceConfig,
        },
    ],
})
export class ForecastModule {}
