export const ALERT_COLORS = [
    '#B8BFCC',
    '#8CB917',
    '#A2C617',
    '#BEC617',
    '#FFCC33',
    '#FFA33B',
    '#FF7344',
    '#FF494B',
    '#D63B50',
    '#AD2D55',
    '#821E5A',
];
