<div
    [ngClass]="{
        'control-point-pin': true,
        'control-point-pin_state-selected': selected
    }"
>
    <div class="control-point-pin__body" *ngIf="!draggable; else draggableTemplate">
        <ng-container *ngIf="showArrowWD && value !== null else defaultCircleTemp">
            <div class="control-point-pin__arrow-wrapper">
                <div class="control-point-pin__arrow" *ngIf="value !== null"
                    [style.transform]="'rotate(' + value + 'deg)'"
                ></div>
            </div>
        </ng-container>
        <ng-template #defaultCircleTemp>
            <div class="control-point-pin__circle" [style]="{ backgroundColor: color }"></div>
        </ng-template>
        <div class="control-point-pin__value">{{ displayValue }}</div>
    </div>
    <ng-template #draggableTemplate>
        <div class="control-point-pin__body draggable">
            <div class="control-point-pin__name">{{ name }}</div>
        </div>
    </ng-template>
    <div class="control-point-pin__line"></div>
    <div
        class="control-point-pin__end"
        *ngIf="!draggable; else draggableEndTemplate"
        [style]="{ backgroundColor: color }"
    ></div>
    <ng-template #draggableEndTemplate>
        <div class="control-point-pin__end draggable"></div>
    </ng-template>
</div>
