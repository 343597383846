import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EntityDataModule } from '@ngrx/data';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CalendarMonthsModule, LittleComponentsModule } from '@libs/shared-ui';
import { PipesModule } from '@libs/shared-ui';
import { DirectivesModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { SidebarToggleButtonModule } from '@libs/shared-ui';
import { PageTabsModule } from '@libs/shared-ui';
import { CardModule } from '@libs/shared-ui';
import { CardActionsModule } from '@libs/shared-ui';
import { InputModule } from '@libs/shared-ui';
import { InputForCoordinateModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';
import { ButtonAddModule } from '@libs/shared-ui';

import { OverlayModule } from '@libs/shared-ui';
import { SwitchItemModule } from '@libs/shared-ui';
import { KebabMenuModule } from '@libs/shared-ui';
import { ColorLegendModule } from '@libs/shared-ui';
import { SwitchButtonModule } from '@libs/shared-ui';

import { PlumesCalculationResultsCardModule } from './components/plumes-calculation-results-card/plumes-calculation-results-card.module';
import { PlumesRunSummaryModule } from './components/plumes-run-summary/plumes-run-summary.module';
import { PlumesPage } from './components/plumes-page/plumes-page.component';
import { PlumesSourcesList } from './components/plumes-sources-list/plumes-sources-list.component';
import { PlumesControlPoints } from './components/plumes-control-points/plumes-control-points.component';
import { PlumesCalculationResults } from './components/plumes-calculation-results/plumes-calculation-results.component';
import { PlumesControlPointsCard } from './components/plumes-control-points-card/plumes-control-points-card.component';
import { EditControlPointComponent } from './components/edit-control-point/edit-control-point.component';
import { PlumesCalculation } from './components/plumes-calculation/plumes-calculation.component';
import { PlumesSource } from './components/plumes-source/plumes-source.component';
import { CalculationResultSelectorComponent } from './components/calculation-result-selector/calculation-result-selector.component';
import { PlumesCalculationResultsEditComponent } from './components/plumes-calculation-results-edit/plumes-calculation-results-edit.component';
import { PlumesMapElementsComponent } from './components/plumes-map-elements/plumes-map-elements.component';
import { PlumesMapComponent } from './components/plumes-map/plumes-map.component';
import { PlumesDropdownListComponent } from './components/plumes-dropdown-list/plumes-dropdown-list.component';
import { entityPlumesConfig } from './entity-metadata';
import { EntityStorePlumesModule } from './entity-store.module';
import { plumesReducers } from './store/reducers';
import { PlumesEffects } from './store/effects';
import { PLUMES_FEATURE_KEY } from './constants';
import { AqiDetailsTooltipModule } from '@libs/shared-ui';
import { TimelinePanelModule } from '@libs/shared-ui';
import { ButtonModule } from '@libs/shared-ui';
import { ButtonsSaveCancelModule } from '@libs/shared-ui';
import { MapControlModule } from '@libs/shared-ui';
import { MmtSelectorModule } from '@libs/shared-ui';
import { PlumesRunTimePipe } from '@cityair/modules/plumes/plumes-run.pipe';
import { HeightsSelectorModule } from '@libs/shared-ui';
import { InputForDropdownModule } from '@libs/shared-ui';
import { InputDropdownModule } from '@libs/shared-ui';
import { SelectboxRadioModule } from '@libs/shared-ui';
import { MapStyleSelectorComponent } from '@libs/shared-ui';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        CardModule,
        TooltipsModule,
        ButtonAddModule,
        PageTabsModule,
        CardActionsModule,
        InputModule,
        InputForCoordinateModule,
        CalendarMonthsModule,
        LoadersModule,
        OverlayModule,
        TimelinePanelModule,
        SwitchItemModule,
        AqiDetailsTooltipModule,
        StoreModule.forFeature(PLUMES_FEATURE_KEY, plumesReducers),
        EffectsModule.forFeature([PlumesEffects]),
        EntityDataModule.forRoot(entityPlumesConfig),
        EntityStorePlumesModule,
        PlumesCalculationResultsCardModule,
        PlumesRunSummaryModule,
        KebabMenuModule,
        ColorLegendModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        SwitchButtonModule,
        MapControlModule,
        MmtSelectorModule,
        HeightsSelectorModule,
        InputForDropdownModule,
        InputDropdownModule,
        SelectboxRadioModule,
        MapStyleSelectorComponent,
        SidebarToggleButtonModule,
    ],
    exports: [PlumesPage, PlumesMapComponent],
    declarations: [
        PlumesPage,
        PlumesSourcesList,
        PlumesCalculationResults,
        PlumesControlPoints,
        PlumesControlPointsCard,
        EditControlPointComponent,
        PlumesCalculation,
        PlumesSource,
        CalculationResultSelectorComponent,
        PlumesCalculationResultsEditComponent,
        PlumesMapElementsComponent,
        PlumesMapComponent,
        PlumesDropdownListComponent,
        PlumesRunTimePipe,
    ],
})
export class PlumesModule {}
