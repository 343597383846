import {
    createAction,
    createFeature,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';
import { AqiType, AQI_IN_ANALYTICS_GROUP_IDS, Locality, BasicSummaryResponse } from '@libs/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { selectExtConfig, selectGroupId } from '@libs/shared-store';
import { ANALYTIC_INDORE_DATA } from '../consts/analytics-indoore-data.const';

export const setCityCard = createAction(
    '[CityCardActions] SetCityCard',
    props<{ city: Locality }>()
);
export const setCitySummary = createAction(
    '[CityCardActions] SetCitySummary',
    props<{ payload: any }>()
);
export const loadSummaryData = createAction(
    '[CityCardActions] loadSummaryData',
    props<{ id: string }>()
);
export const isLoadingSummaryData = createAction(
    '[CityCardActions] isLoadingSummaryData',
    props<{ payload: boolean }>()
);

export const summaryAdapter: EntityAdapter<BasicSummaryResponse> =
    createEntityAdapter<BasicSummaryResponse>({
        selectId: (summary) => summary.locality_id,
    });

interface CityCardState {
    city: Locality;
    summary: EntityState<BasicSummaryResponse>;
    isLoadingSummary: boolean;
}

const initialState: CityCardState = {
    city: null,
    summary: summaryAdapter.getInitialState(),
    isLoadingSummary: true,
};

export const cityCardFeature = createFeature({
    name: 'cityCard',
    reducer: createReducer(
        initialState,
        on(setCityCard, (state, { city }) => ({
            ...state,
            city,
        })),
        on(setCitySummary, (state, { payload }) => {
            const response = payload as BasicSummaryResponse;
            const summary = summaryAdapter.setOne(response, state.summary);
            return {
                ...state,
                summary,
                isLoadingSummary: false,
            };
        }),
        on(isLoadingSummaryData, (state, { payload }) => ({
            ...state,
            isLoadingSummary: payload,
        }))
    ),
});

const featureSelector = createFeatureSelector<CityCardState>('cityCard');

export const selectCityCard = createSelector(featureSelector, (state) => state.city);
export const selectCityForAnalytics = createSelector(
    selectCityCard,
    selectExtConfig,
    selectGroupId,
    (city, extConfig, groupId) => {
        if (city) {
            if (extConfig?.excludeIndexes) {
                const excludeIndexes = extConfig?.excludeIndexes.split(',');
                return excludeIndexes.indexOf(AqiType.instant) === -1 ||
                    AQI_IN_ANALYTICS_GROUP_IDS.indexOf(groupId) >= 0
                    ? city
                    : null;
            }

            return city;
        }

        return null;
    }
);
export const selectIsLoadingSummary = createSelector(
    featureSelector,
    (state) => state.isLoadingSummary
);
export const selectAllSummaryDic = createSelector(featureSelector, (state) =>
    summaryAdapter.getSelectors().selectEntities(state.summary)
);
export const selectIsDemoAnalytic = createSelector(
    selectGroupId,
    (groupId) => groupId && AQI_IN_ANALYTICS_GROUP_IDS.indexOf(groupId) >= 0
);
export const selectCurrentSummaryData = createSelector(
    selectCityCard,
    selectIsDemoAnalytic,
    selectAllSummaryDic,
    (city, isDemo, data) => {
        if (isDemo && city) {
            return ANALYTIC_INDORE_DATA;
        } else {
            const id = city?.id;
            if (id && data[id]) {
                return data?.[id]?.distribution_summary_items ?? null;
            }
            return null;
        }
    }
);
