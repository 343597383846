import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'cs-back-button',
    templateUrl: 'back-button.component.html',
    styleUrls: ['back-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
    @Input() disabled = false;
}
