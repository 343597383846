import { Component, Input, OnInit } from '@angular/core';
import { ANIMATION_OPACITY } from '@libs/common';
import { ITooltipComponent, TooltipPlacement, TooltipsService } from '../../tooltips.service';

type EnabledPlacement = 'left' | 'right' | 'right-top' | 'left-top';

const ARROW_COLORS: {
    [key in TooltipPlacement & EnabledPlacement]: string;
} = {
    right: '#8669D6',
    left: '#6982d5',
    'right-top': '#8669D6',
    'left-top': '#6982d5',
};

@Component({
    selector: 'ca-detailed-tooltip',
    templateUrl: 'detailed-tooltip.component.html',
    styleUrls: ['detailed-tooltip.component.less'],
    animations: ANIMATION_OPACITY,
})
export class DetailedTooltipComponent implements ITooltipComponent, OnInit {
    @Input() title: string;
    @Input() description?: string;
    @Input() text?: string[];

    arrowColor: string;

    constructor(readonly tooltipsService: TooltipsService) {}

    get hasContent() {
        return this.description || this.text;
    }

    ngOnInit() {
        this.arrowColor = ARROW_COLORS[this.tooltipsService.placement];
    }
}
