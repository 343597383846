import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MmtSelectorComponent } from './mmt-selector.component';

@NgModule({
    exports: [MmtSelectorComponent],
    declarations: [MmtSelectorComponent],
    imports: [CommonModule],
})
export class MmtSelectorModule {}
