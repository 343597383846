import { Pipe, PipeTransform } from '@angular/core';
import { ControlPointForecast } from '../models';

@Pipe({
    name: 'getValueControlPoint', standalone: true
})
export class GetValueControlPointPipe implements PipeTransform {
    transform(obj: ControlPointForecast, mmt: string, index: number): number {
        if (!obj) {
            return null;
        }
        const data = obj?.timeseries?.values?.[mmt] || null;

        return data ? data[index] : null;
    }
}
