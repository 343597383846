import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '@libs/shared-ui';
import { BasicDateRangeComponent } from './basic-date-range.component';

@NgModule({
    imports: [CommonModule, PipesModule],
    exports: [BasicDateRangeComponent],
    declarations: [BasicDateRangeComponent],
})
export class BasicDateRangeModule {}
