<ng-template #markerTooltip>
    <ca-info-tooltip [text]="pinTooltipText"></ca-info-tooltip>
</ng-template>

<mgl-map
    *ngIf="imgToMap && impSourcePath"
    [center]="[imgToMap.getRightLon() / 2, imgToMap.getTopLat() / 2]"
    [zoom]="[configMap.zoom]"
    [minZoom]="configMap.minZoom"
    [maxZoom]="configMap.maxZoom"
    [style]="INDOOR_MAP_STYLE"
    [ngStyle]="{ backgroundColor: configMap.imgBackgroundColor }"
    [dragRotate]="false"
    [maxBounds]="bounds"
>
    <mgl-image-source
        [id]="'image-source'"
        [url]="getAsset(impSourcePath)"
        [coordinates]="[
            [imgToMap.getLeftLon(), imgToMap.getTopLat()],
            [imgToMap.getRightLon(), imgToMap.getTopLat()],
            [imgToMap.getRightLon(), imgToMap.getBottomLat()],
            [imgToMap.getLeftLon(), imgToMap.getBottomLat()]
        ]"
    ></mgl-image-source>
    <mgl-layer [id]="'q123'" [type]="'raster'" [source]="'image-source'"></mgl-layer>

    <mgl-marker
        *ngFor="let pin of pins?.getPins | async"
        [lngLat]="[imgToMap.coordinates[pin.id].lon, imgToMap.coordinates[pin.id].lat]"
        [anchor]="'bottom'"
    >
        <post-pin-rectangle
            *ngIf="{ a: pins.getValue(pin) | async } as val"
            data-cy="floor-pins"
            [value]="val.a"
            [numberAfterDot]="1"
            [color]="pins.getColor(val.a, store.select(indoorSelectors.getZone) | async)"
            [selected]="(pins?.getSelectedPinIds | async) === pin.id"
            [state]="markerState.default"
            (click)="pins.clickCb(pin)"
            (mouseenter)="pinTooltipText = isTouchDevice ? '' : pin.name"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipFollowPointer]="true"
            [caTooltipTemplate]="markerTooltip"
        ></post-pin-rectangle>
    </mgl-marker>

    <mgl-marker
        [lngLat]="floorPinCoordinates"
        [anchor]="'bottom'"
        *ngIf="floorPin && { a: (store.select(indoorSelectors.analyticType) | async) } as val"
    >
        <floor-pin
            data-cy="floor-pin"
            class="floor-pin"
            [value]="floorPin?.label"
            [selected]="val.a === analyticType.floor"
            (click)="store.dispatch(onIsEnabledChart({ payload: false }))"
        ></floor-pin>
    </mgl-marker>
    <mgl-marker
        *ngFor="let pin of outdoorPins?.getPins | async"
        [lngLat]="getOutDoorCoordinate(pin)"
        [anchor]="'bottom'"
    >
        <outdoor-air-pin
            *ngIf="{ a: outdoorPins.getValue(pin) | async } as val"
            class="outdoor-pin"
            [title]="'Outdoor'"
            [value]="val.a"
            [numberAfterDot]="1"
            [color]="
                outdoorPins.getColor(
                    val.a,
                    store.select(indoorSelectors.currentMmtAndScheme) | async
                )
            "
            [selected]="(outdoorPins?.getSelectedPinIds | async) === pin.id"
            (mouseenter)="pinTooltipText = isTouchDevice ? '' : pin.name"
            (click)="outdoorPins.clickCb(pin)"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipFollowPointer]="true"
            [caTooltipTemplate]="markerTooltip"
        ></outdoor-air-pin>
    </mgl-marker>
</mgl-map>

<ca-layer-button class="layer_button" [type]="LayerButtonIconType.FORECAST" [active]="true">
    <ca-layer-button-item
        *ngFor="let opt of MMT_ARRAY"
        data-cy="layer-buttons"
        [label]="names[opt]"
        [active]="(store.select(indoorSelectors.currentMmt) | async) === opt"
        (click)="store.dispatch(selectMmt({ payload: opt }))"
    ></ca-layer-button-item>
</ca-layer-button>
