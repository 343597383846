import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubstancesListComponent } from './substances-list.component';

@NgModule({
    imports: [CommonModule],
    exports: [SubstancesListComponent],
    declarations: [SubstancesListComponent],
})
export class SubstancesListModule {}
