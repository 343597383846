import { LngLatBoundsLike, LngLatLike } from 'mapbox-gl';
import * as moment from 'moment-timezone';
export const GLOBAL_MAP_STYLE = 'mapbox://styles/cityair/cl6vpu0mb006v14n0a6ig45l3';
export interface MapSettings {
    accessToken?: string;
    tiles?: string[];
    tileSize?: number;
    center?: LngLatLike;
    bounds?: LngLatBoundsLike;
    zoom?: number;
    minZoom?: number;
    maxZoom?: number;
}
export const GLOBAL_MAP_SETTING: MapSettings = {
    center: {
        lng: 77.596,
        lat: 12.973,
    },
    bounds: [
        {
            lng: -360,
            lat: -84,
        },
        {
            lng: 360,
            lat: 84,
        },
    ],
    zoom: 2,
    minZoom: 1,
    maxZoom: 16,
};
export const GLOBAL_ZOOM_SHOW_STATION = 11;
const currentDate = moment();
export const GLOBAL_FORECAST_DATE = currentDate.subtract(1, 'days').format('YYYYMMDD_HH0000');
export const MARKER_HEIGHT = 52.3;
export const MARKER_HOVER_COLORS = [
    '#b2b3b3',
    '#85a818',
    '#97b518',
    '#b2b218',
    '#efb930',
    '#ce7e36',
    '#d85a41',
    '#d6434b',
    '#c1384f',
    '#932a50',
    '#681b4d',
];

export interface Timeseries {
    AQI: number[];
}
export interface Geometry {
    type: string;
    coordinates: LngLatLike;
}
export interface Properties {
    uuid: string;
    name: string;
    name_ru: string;
    timeseries: Timeseries;
    obj: string;
    is_small?: boolean;
    time_zone?: string;
    sort_rank?: number;
    source_type?: number;
}

export interface Feature {
    type?: string;
    geometry: Geometry;
    properties: Properties;
    is_selected?: boolean;
    getColor?: any;
}
const INDOOR_CENTER_PIN: LngLatLike = [77.58650316660452, 12.990206262577898];
export const BENGALURU_MARKER_STATION: Feature[] = [
    {
        geometry: {
            type: 'Point',
            coordinates: INDOOR_CENTER_PIN,
        },
        properties: {
            uuid: 'Source_9lvRTDseeAfthryxURk9EXw',
            name: 'Bengaluru',
            name_ru: 'Bengaluru',
            timeseries: {
                AQI: [5],
            },
            sort_rank: 5,
            obj: 'city',
            is_small: false,
        },
        is_selected: false,
        getColor: (value: number, colors: string[]) => colors[value],
    },
    {
        geometry: {
            type: 'Point',
            coordinates: [77.67452742713999, 12.953069168315238],
        },
        properties: {
            uuid: 'Source_9lvRTDseeAfthryxURk9EXw',
            name: '',
            name_ru: '',
            timeseries: {
                AQI: [1],
            },
            obj: 'source',
            sort_rank: 1,
            is_small: false,
        },
        is_selected: false,
    },
    {
        geometry: {
            type: 'Point',
            coordinates: [77.51492304587418, 12.926300951933783],
        },
        properties: {
            uuid: '',
            name: '',
            name_ru: '',
            timeseries: {
                AQI: [3],
            },
            obj: 'source',
            is_small: false,
        },
        is_selected: false,
    },
    {
        geometry: {
            type: 'Point',
            coordinates: [77.59905559841485, 12.917239149888687],
        },
        properties: {
            uuid: 'Source_9lvRTDseeAfthryxURk9EXw',
            name: '',
            name_ru: '',
            timeseries: {
                AQI: [2],
            },
            obj: 'source',
            is_small: false,
        },
        is_selected: false,
    },
    {
        geometry: {
            type: 'Point',
            coordinates: [77.62712466706478, 12.993951334320556],
        },
        properties: {
            uuid: 'Source_9lvRTDseeAfthryxURk9EXw',
            name: '',
            name_ru: '',
            timeseries: {
                AQI: [5],
            },
            obj: 'source',
            is_small: false,
        },
        is_selected: false,
    },
    {
        geometry: {
            type: 'Point',
            coordinates: [77.59167195063236, 12.977581425729895],
        },
        properties: {
            uuid: 'Source_9lvRTDseeAfthryxURk9EXw',
            name: '',
            name_ru: '',
            timeseries: {
                AQI: [4],
            },
            obj: 'source',
            is_small: false,
        },
        is_selected: false,
    },
    {
        geometry: {
            type: 'Point',
            coordinates: [77.60037056524556, 12.978997782770008],
        },
        properties: {
            uuid: 'Source_9lvRTDseeAfthryxURk9EXw',
            name: '',
            name_ru: '',
            timeseries: {
                AQI: [2],
            },
            obj: 'source',
            is_small: false,
        },
        is_selected: false,
    },
    {
        geometry: {
            type: 'Point',
            coordinates: [77.64638659520904, 12.97816885207213],
        },
        properties: {
            uuid: 'Source_9lvRTDseeAfthryxURk9EXw',
            name: '',
            name_ru: '',
            timeseries: {
                AQI: [1],
            },
            obj: 'source',
            is_small: false,
        },
        is_selected: false,
    },
];
export const BENGALURU_INDOOR_MARKER: Feature = {
    geometry: {
        type: 'Point',
        coordinates: [77.596, 12.973],
    },
    properties: {
        uuid: 'Source_9lvRTDseeAfthryxURk9EXw',
        name: 'GOLF LINKS',
        name_ru: '',
        timeseries: {
            AQI: [5],
        },
        sort_rank: 5,
        obj: 'indoor',
        is_small: false,
    },
    is_selected: false,
};
export const BENGALURU_INDOOR_MARKER_V2: Feature = {
    geometry: {
        type: 'Point',
        coordinates: INDOOR_CENTER_PIN,
    },
    properties: {
        uuid: 'Source_9lvRTDseeAfthryxURk9EXw',
        name: '28 Sankey Road',
        name_ru: '',
        timeseries: {
            AQI: [5],
        },
        sort_rank: 5,
        obj: 'indoor',
        is_small: false,
    },
    is_selected: false,
};
