import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isNumber',
})
export class IsNumberPipe implements PipeTransform {
    transform(value: number) {
        return value !== null && value !== undefined && !isNaN(value);
    }
}
