<div
    class="login-page login-page--{{ clientConfig.name || 'unknown' }}"
    [style.backgroundImage]="assets.background"
>
    <div class="login-page__header">
        <div class="login-page__logo">
            <ca-cityair-logo *ngIf="!clientConfig.disableBranding?.all"></ca-cityair-logo>
        </div>
        <div class="login-page__language">
            <ca-language-selector (selectLang)="setLang($event)"></ca-language-selector>
        </div>
    </div>

    <div class="login-page__body">
        <ca-login-pane class="login-page__login-pane">
            <div
                class="login-page__client-logo"
                *ngIf="assets.logo"
                [style.backgroundImage]="assets.logo"
            ></div>
            <router-outlet></router-outlet>
            <div
                class="login-page__note"
                *ngIf="currentLanguage === 'ru' && router.url === '/login'"
            >
                Реализовано технологическими партнёрами
                <br />
                <a href="https://cityair.io/" target="_blank">ООО «Ситиэйр»</a> и
                <a href="https://big3.ru/" target="_blank">ООО «Большая Тройка»</a>
            </div>
        </ca-login-pane>
    </div>

    <div class="login-page__footer" *ngIf="!clientConfig.disableBranding?.all">
        <ca-contacts-info></ca-contacts-info>
        <ca-legal-notice *ngIf="currentLanguage === 'ru'" [showFond]="false"></ca-legal-notice>
    </div>

    <ng-container [ngSwitch]="navigation.activePopup">
        <ca-password-updated-popup
            *ngSwitchCase="loginPopup.PasswordUpdated"
        ></ca-password-updated-popup>
        <ca-link-expired-popup *ngSwitchCase="loginPopup.InviteLinkExpired"></ca-link-expired-popup>
    </ng-container>
</div>
