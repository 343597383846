<div class="chart-content" (mouseleave)="hoverLineVisible = false"
>
    <canvas
        #chart
        baseChart
        [legend]="false"
        [datasets]="data | chartDataPost : mmts: measureScheme"
        [options]="chartOptions"
        [plugins]="chartPlugins"
    ></canvas>
    <div  class="chart-hover-line"
        [style.visibility]="hoverLineVisible ? 'visible' : 'hidden'"
        [style.left.px]="hoverLinePosition"
    ></div>
</div>
