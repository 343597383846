import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { formatNumber } from '@angular/common';
import { customNumberFormat, isFalseNumber } from '@libs/common';
import { LANGUAGE } from '@libs/common';
import { getColorFromZone } from '@cityair/utils/utils';
import { ColorZone } from '@libs/common';
import { Store } from '@ngrx/store';
import { selectImpactSchemaZones } from '@cityair/modules/impact/store/impact.feature';
import { Subject, takeUntil } from 'rxjs';
import { getDigitsAfterDot } from '@libs/common';

@Component({
    standalone: true,
    selector: 'impact-region-pin',
    templateUrl: './impact-region-pin.component.html',
    styleUrls: ['./impact-region-pin.component.less'],
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactRegionPinComponent implements OnInit, OnChanges, OnDestroy {
    @Input() value: number;
    @Input() numberAfterDot?: number = 0;
    @Input() selected: boolean;
    @Input() name: string;
    @Input() zoom: number;
    isFalseNumber = isFalseNumber;
    getColorFromZone = getColorFromZone;
    selectImpactSchemaZones = selectImpactSchemaZones;
    public zone: ColorZone;
    public displayValue: number | string;
    public displayName: string;
    public hasNoData: boolean;
    public ngDestroyed$ = new Subject<void>();
    public digitsAfterDot: number;
    public isPinHover: boolean = false;

    constructor(private store: Store) {
        store
            .select(selectImpactSchemaZones)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.digitsAfterDot = getDigitsAfterDot(data?.scheme, data?.mmt);
                this.zone = data?.zone;
            });
    }

    ngOnInit(): void {
        this.showFullPin(this.zoom);
        this.displayName = this.name?.replace(' район', '');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            const { value, numberAfterDot } = this;
            const hasNoData = isFalseNumber(value);
            const formatValue = formatNumber(
                value,
                LANGUAGE,
                '1.' + numberAfterDot + '-' + numberAfterDot
            );
            this.displayValue = hasNoData
                ? '–'
                : customNumberFormat(formatValue, numberAfterDot, LANGUAGE);
        }
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    showFullPin(zoom: number) {
        return zoom > 10.5;
    }
}
