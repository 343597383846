import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SectionLineComponent } from './section-line.component';

@NgModule({
    imports: [CommonModule],
    exports: [SectionLineComponent],
    declarations: [SectionLineComponent],
})
export class SectionLineModule {}
