<div class="feedback-form" [class.feedback-form--mobile]="isMobile">
    <cross-button
        class="feedback-form__close-button"
        size="large"
        *ngIf="!isMobile"
        (click)="onClose()"
    ></cross-button>

    <form (ngSubmit)="onSubmit()" [formGroup]="feedbackForm" novalidate>
        <div class="feedback-form__container">
            <div class="feedback-form__title">
                {{ textFeedback.formTitle }}
            </div>

            <div class="feedback-form__description">
                {{ textFeedback.formDescription }}
            </div>

            <div class="feedback-form__content-wrapper">
                <textarea
                    class="feedback-form__content scroll-gray"
                    [class.feedback-form__content--danger]="errors$ | async"
                    [attr.placeholder]="textFeedback.formPlaceholder"
                    formControlName="message"
                ></textarea>
                <div
                    class="feedback-form__error-message ellipsis"
                    *ngIf="errors$ | async as message"
                >
                    {{ message }}
                </div>
            </div>
        </div>

        <div class="feedback-form__actions">
            <ng-container *ngIf="isMobile; else default">
                <dark-button (click)="onSubmit()">
                    <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
                    <ng-template #showContent>{{ textFeedback.submit }}</ng-template>
                </dark-button>
            </ng-container>

            <ng-template #default>
                <ca-button type="primary" (click)="onSubmit()">
                    <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
                    <ng-template #showContent>{{ textFeedback.submit }}</ng-template>
                </ca-button>
            </ng-template>
        </div>
    </form>
</div>
