import { LngLat } from 'mapbox-gl';
import { Locality } from '@libs/common';

const DISTANCE_TO_CITY_IN_METERS = 100_000;
export function findClosestCity(cities: Locality[], center: LngLat): Locality {
    if (!cities || !cities.length || !center) {
        return null;
    }

    let closest: { distance: number; city: Locality };

    cities.forEach((city) => {
        const distance = new LngLat(
            city.geometry.coordinates[0],
            city.geometry.coordinates[1]
        ).distanceTo(center);

        if (!closest || distance <= closest.distance) {
            closest = { distance, city };
        }
    });

    if (closest && closest.distance < DISTANCE_TO_CITY_IN_METERS) {
        return closest.city;
    }

    return null;
}
