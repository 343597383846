<div (clickOutside)="close()" class="calendar">
    <ng-container *ngIf="dropdownMode else defaultTemp">
        <div (click)="toggleShow()">
            <input-for-dropdown
                [inputValue]="intervalText"
            ></input-for-dropdown>
        </div>
    </ng-container>
    <ng-template #defaultTemp>
        <div class="calendar-button" (click)="toggleShow()" data-test="calendar-button">
            <div class="calendar-button__time_interval">
                {{ intervalText }}
            </div>
        </div>
    </ng-template>

    <calendar-months
        setHeight
        [show]="showCalendar"
        [timeBegin]="begin"
        [timeEnd]="end"
        [applyCb]="updateTime"
        [updateDays]="updateDays"
        [monthsVisible]="monthsVisible"
        [columns]="columns"
        [numberOfMonths]="numberOfMonth"
    ></calendar-months>
</div>
