import { ChartConfiguration } from 'chart.js';
import { ALERT_COLORS } from '@libs/common';
import * as moment from 'moment-timezone';

export const options: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
        x: {
            type: 'time',
            display: false,
            ticks: {
                display: true,
            },
            time: {
                tooltipFormat: 'DD MMM',
                parser: (value: string) => moment(value).valueOf(),
            },
            grid: {
                display: false,
            },
        },
        y: {
            display: true,
            ticks: {
                display: true,
            },
            grid: {
                display: false,
            },
        },
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        tooltip: {
            borderWidth: 1,
            bodyColor: '#000',
            titleColor: '#000',
            displayColors: true,
            borderColor: ALERT_COLORS[1],
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            callbacks: {
                label: (tooltipItem) => `${tooltipItem.formattedValue}`,
            },
        },
    },
};
