import { Component } from '@angular/core';
import { CalendarMonth, Day } from './../../dataModel';
import { CalendarMonthsComponent } from './../calendar-months/calendar-months.component';

@Component({
    selector: 'calendar-months-mobile',
    templateUrl: './calendar-months-mobile.component.html',
    styleUrls: ['./../calendar-months.component.less'],
})
export class CalendarMonthsMobileComponent extends CalendarMonthsComponent {
    scrollTo() {
        const els = document.getElementsByClassName('calendar__day-end');
        if (els.length) {
            els[els.length - 1].scrollIntoView(false);
            document.getElementById('calendar_mobile__table_body').scrollBy(0, 100);
        }
    }

    scrollToTimeout() {
        setTimeout(() => this.scrollTo(), 100);
    }

    dayClickMobile = (e) => {
        const timestamp = parseInt(e.target.id, 10);
        let day: Day = null;
        // @ts-ignore
        this.calendarState.months.find((m: CalendarMonth) =>
            m.daysArr.find((w: Day[]) =>
                w.find((d: Day) => {
                    if (d && d.timestamp === timestamp) {
                        day = d;
                        return true;
                    }
                    return false;
                })
            )
        );

        if (timestamp) {
            this.dayClick(day);
        }
    };
}
