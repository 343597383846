import { Component, OnInit } from '@angular/core';
import { createCssVars } from '@cityair/modules/core/css-vars';

@Component({
    selector: 'ca-main-page',
    templateUrl: 'main-page.component.html',
})
export class MainPageComponent implements OnInit {
    ngOnInit() {
        createCssVars();
    }
}
