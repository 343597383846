import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@libs/common';

@Pipe({
    name: 'contributionTimelineData',
})
export class ContributionTimelineDataPipe implements PipeTransform {
    transform(data: Feature[], mmts: string[], index: number): number {
        if (!data[0]) {
            return null;
        }

        const { timelineContributions } = data[0].properties;
        const result = timelineContributions?.[mmts[0]] || null;
        if (!result) {
            return null;
        } else {
            return result[index];
        }
    }
}
