<svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    class="arrow arrow--{{ size }} arrow--{{ direction }} arrow--{{ type }}"
    [class.arrow--disabled]="disabled"
>
    <path d="M1 0.999999L7 7L13 1" class="arrow__path" />
</svg>
