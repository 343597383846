import { Component } from '@angular/core';

import { TEXTS } from '@libs/common';
import { LanguageService } from '@cityair/libs/shared/modules/core/services/language.service';
import {
    ClientConfig,
    ClientConfigService,
} from '../../../core/services/client-config/client-config.service';
import { LoginNavigationService } from '../../services/login-navigation/login-navigation.service';
import { LoginPage, LoginPopup } from '../../login.settings';
import { getClientAssetPath } from '@cityair/config';

@Component({
    selector: 'ca-spb112-login-page',
    templateUrl: 'spb112-login-page.component.html',
    styleUrls: ['../login-page/login-page.component.less', 'spb112-login-page.component.less'],
})
export class Spb112LoginPageComponent {
    clientName: string;

    currentLanguage: string;

    TEXTS = TEXTS;

    loginPage = LoginPage;

    loginPopup = LoginPopup;

    clientConfig: ClientConfig;

    assets = {
        background: null,
        logo: null,
    };

    constructor(
        clientConfigService: ClientConfigService,
        languageService: LanguageService,
        readonly navigation: LoginNavigationService
    ) {
        this.clientConfig = clientConfigService.getConfig();

        this.currentLanguage = languageService.getLanguage();

        this.assets = {
            background: getClientAssetPath(this.clientConfig.login?.background),
            logo: getClientAssetPath(this.clientConfig.login?.logo),
        };
    }

    setLang(lang: string) {
        localStorage.setItem('lang', lang);
        location.reload();
    }
}
