import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@libs/common';
import { isRU } from '@libs/common';

@Pipe({
    name: 'featureName',
})
export class FeatureNamePipe implements PipeTransform {
    transform(obj: Feature): string {
        if (!obj) {
            return '';
        }
        const langPropName = isRU ? 'name_ru' : 'name';
        return obj.properties[langPropName];
    }
}
