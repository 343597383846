export const CONTRIBUTIONS_COLORS_SOURCES_ORDER = [
    '#0039A6',
    '#FF6319',
    '#6CBE19',
    '#996633',
    '#A7A9AC',
    '#FCCC0A',
    '#808183',
    '#EE352E',
    '#00933C',
    '#B933AD',
];
export const DEMO_IMPACT_POLYGON: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {
                color: '#B933AD',
                dasharray: [2, 2],
            },
            geometry: {
                coordinates: [
                    [83.0548690480154, 54.979391036602834],
                    [83.05636195478792, 54.97641795210387],
                    [83.0749826904376, 54.973391848852344],
                    [83.08831996778366, 54.97100757803548],
                    [83.09902420295447, 54.96917404620669],
                    [83.1236270033566, 54.96422126120925],
                    [83.13185285788927, 54.962249969677885],
                    [83.14573697591624, 54.95881522778828],
                    [83.14825784088038, 54.95948917574151],
                    [83.14548558695111, 54.96384282103625],
                    [83.1432461641574, 54.96595468889936],
                    [83.13820609072246, 54.9686175264022],
                    [83.13822736622592, 54.97155900084684],
                    [83.14131620572653, 54.9728887099792],
                    [83.14393207698947, 54.9755901576805],
                    [83.14463135343522, 54.9789755270518],
                    [83.12775445966508, 54.985620204588855],
                    [83.12304238456596, 54.98630510257101],
                    [83.11212982681275, 54.98609496960765],
                    [83.10030245102632, 54.98211089113306],
                    [83.08726753689206, 54.98293682481071],
                    [83.07459908814258, 54.982619638836866],
                    [83.06808628051766, 54.98227987264289],
                    [83.0548690480154, 54.979391036602834],
                ],
                type: 'LineString',
            },
        },
    ],
};
