import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationStringComponent } from './information-string.component';

@NgModule({
    exports: [InformationStringComponent],
    declarations: [InformationStringComponent],
    imports: [CommonModule],
})
export class InformationStringModule {}
