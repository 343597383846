<ca-login-info-popup>
    <div class="password-updated-popup">
        <div class="password-updated-popup-icon"></div>
        <div class="password-updated-popup-title">{{ TEXTS.LOGIN_WINDOWS.passUpd }}</div>
        <div
            class="password-updated-popup-description"
            [innerHTML]="TEXTS.LOGIN_WINDOWS.yourPassHasBeen"
        ></div>
    </div>
</ca-login-info-popup>
