import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

import { declOfNum, LANGUAGE, TEXTS, sortMeasurements } from '@libs/common';
import { Source } from '@cityair/modules/plumes/services/run/models';
import { Dictionary } from '@ngrx/entity';
import { NgLocalization } from '@angular/common';

@Component({
    selector: 'plumes-calculation',
    templateUrl: 'plumes-calculation.component.html',
    styleUrls: ['plumes-calculation.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlumesCalculation implements OnChanges {
    @Input() minutes: number;
    @Input() durations: number;
    @Input() valueTypes: string[];
    @Input() windIsOn: boolean;
    @Input() sources: number[];
    @Input() sourcesSnapshot: Dictionary<Source>;

    textsPlumes = TEXTS.PLUMES;
    textNames = TEXTS.NAMES;
    textShowList = TEXTS.PLUMES.showSources;
    valText: string;
    valDurationsText: string;
    isShowSourcesList = false;
    mmts: string[] = [];

    constructor(private ngLocalization: NgLocalization) {}
    ngOnChanges(changes: SimpleChanges) {
        if (changes?.valueTypes?.currentValue) {
            const mmts = [...this.valueTypes].sort(sortMeasurements);
            this.mmts = mmts.map((item) => this.textNames[item] ?? item);
        }
        if (changes?.minutes?.currentValue) {
            const hours = changes.minutes.currentValue / 60;
            const everyCategory = this.ngLocalization.getPluralCategory(hours, LANGUAGE);

            this.valText = `${TEXTS.PLUMES.every[everyCategory]} ${hours} ${declOfNum(
                hours,
                TEXTS.PLUMES.timeIntervalHours
            )}`;
        }
        if (changes?.durations?.currentValue) {
            const hours = changes.durations.currentValue / 60;
            this.valDurationsText = `${hours} ${declOfNum(hours, TEXTS.PLUMES.timeIntervalHours)}`;
        }
    }
}
