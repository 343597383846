import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { CityDistrictChartComponent } from './city-district-chart.component';

@NgModule({
    imports: [CommonModule, BaseChartDirective],
    declarations: [CityDistrictChartComponent],
    exports: [CityDistrictChartComponent],
})
export class CityDistrictChartModule {}
