import { MapStyleType } from '@libs/common';
import { Style } from 'mapbox-gl';
import { GroupMapSettings } from '@libs/common';

export const MAP_ACTION_DURATION = 1000;
export const DEFAULT_MAP_STYLES = [
    MapStyleType.cityair,
    MapStyleType.satellite,
    MapStyleType.outdoor,
];
export const SATELLITE_STYLE = 'mapbox://styles/mapbox/satellite-streets-v12';
export const OUTDOOR_STYLE = 'mapbox://styles/mapbox/outdoors-v12';
export const OSM_STYLE = {
    version: 8,
    sources: {
        osm: {
            type: 'raster',
            tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
            tileSize: 256,
            attribution:
                '&copy; <a href="https://www.mapbox.com"> Mapbox</a> | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        },
    },
    layers: [
        {
            id: 'osm',
            type: 'raster',
            source: 'osm',
        },
    ],
} as Style;

export const STND_CITY_ZOOM = 8;
export const CITY_ZOOM_SHOW_HEXAGON = 10;
export const STND_CITY_MAX_ZOOM = 16;
export const STND_GLOBAL_MIN_ZOOM = 1.5;
export const GLOBAL_ZOOM_LEVEL = 8;

export const getShiftMapCityCard = (): [number, number] => [
    -250,
    document.documentElement.clientHeight / 2 - 350,
];
export const getShiftMapByLine = (): [number, number] => {
    const y =
        document.documentElement.clientHeight < 700
            ? 400 - document.documentElement.clientHeight / 2
            : document.documentElement.clientHeight / 2 - 350;
    return [-250, y];
};
export const getShiftMapByCorrelationPins = (): [number, number] => [-250, 0];
export const getShiftMapMobile = (): [number, number] => [
    0,
    421 /* таймлайн+шторка */ - document.documentElement.clientHeight / 2,
];

export const CUSTOM_MAP_STYLE = 'mapbox://styles/cityair/cl521x0og003x14moz3e6g3je';
export const DEFAULT_MAP_SETTINGS: GroupMapSettings = {
    style: CUSTOM_MAP_STYLE,
    center: {
        lat: 57,
        lng: 57,
    },
    bounds: [
        {
            lng: -360,
            lat: -84,
        },
        {
            lng: 360,
            lat: 84,
        },
    ],
    zoom: 2,
    minzoom: STND_GLOBAL_MIN_ZOOM,
    maxzoom: STND_CITY_MAX_ZOOM - 1.1,
    showMarkersArea: false,
    type: MapStyleType.cityair,
};

export function createBoundaries(bbox: number[]): [number, number][] {
    const [west, south, east, north] = bbox;

    return [
        [west, north],
        [east, north],
        [east, south],
        [west, south],
    ];
}

export function createBBoxFromRectangle(coordinates: number[][]): number[] {
    let bbox = [];

    if (coordinates.length === 4) {
        const [west, north] = coordinates[0];
        const [east, south] = coordinates[2];

        bbox = [west, south, east, north];
    }

    return bbox;
}