import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonSimpleComponent } from './button-simple.component';

@NgModule({
    imports: [CommonModule],
    exports: [ButtonSimpleComponent],
    declarations: [ButtonSimpleComponent],
})
export class ButtonSimpleModule {}
