import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrossButtonComponent } from './cross-button.component';

@NgModule({
    imports: [CommonModule],
    exports: [CrossButtonComponent],
    declarations: [CrossButtonComponent],
})
export class CrossButtonModule {}
