import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntitySelectorsFactory } from '@ngrx/data';
import { IReport, IReportKind, STATUS_NEED_UPDATE } from '@libs/common';
import * as moment from 'moment-timezone';
import { IReportsState, ReportsState } from './reducers';
import { selectAllPosts, selectIsLoadingPosts } from '@libs/shared-store';
import { selectGroupId, selectUserTimezone } from '@libs/shared-store';

export const reportKindSelectors = new EntitySelectorsFactory().create<IReportKind>('ReportKind');
export const reportsSelectors = new EntitySelectorsFactory().create<IReport>('Report');
export const reportState = createFeatureSelector<IReportsState>('reports');
export const selectReports = createSelector(
    reportsSelectors.selectEntities,
    (entities) => entities
);
export const selectReport = createSelector(
    reportState,
    (state: IReportsState): ReportsState => state.core
);
export const selectCurrentKind = createSelector(
    selectReport,
    (state: ReportsState) => state.currentKind
);
export const selectCurrentKindId = createSelector(
    selectReport,
    (state: ReportsState) => state.currentKindId
);
export const selectCurrentKindNotFound = createSelector(
    selectReport,
    (state: ReportsState) => state.currentKindNotFound
);
export const dataForCreateReport = createSelector(
    selectGroupId,
    selectCurrentKind,
    selectAllPosts,
    selectUserTimezone,
    (groupId, template, posts, timezone) => {
        if (groupId !== undefined && template) {
            let moObj = null;
            if (posts) {
                moObj = posts;
            }

            return {
                groupId,
                moObj,
                template,
                timezone,
            };
        }
        return null;
    }
);
export const selectReportCreateError = createSelector(
    selectReport,
    (state: ReportsState) => state.createReportError
);
export const selectReportDateRange = createSelector(
    selectReport,
    (state: ReportsState) => state.initDateRange
);
export const selectParamsReport = createSelector(
    selectGroupId,
    selectReportDateRange,
    selectAllPosts,
    selectIsLoadingPosts,
    (groupId, date, posts, isLoading) => {
        if (date && groupId !== undefined && posts !== null && !isLoading) {
            let mObj = {};
            if (posts) {
                mObj = Object.assign({}, ...posts.map((item) => ({ [item.id]: item.name })));
            }

            return {
                startDate: date.startDate,
                finishDate: date.finishDate,
                groupId: groupId,
                postList: mObj,
            };
        }
        return null;
    }
);
export const selectReportsForUpdate = createSelector(selectReports, (reports) =>
    reports.filter((report) => (STATUS_NEED_UPDATE.indexOf(report.status) >= 0 ? true : false))
);
export const selectLastReportStatus = createSelector(
    selectReport,
    (state: ReportsState) => state.reportsUpdateStatus
);
export const needReloadData = createSelector(
    selectReport,
    (state: ReportsState) => state.needReloadData
);
export const isActiveReport = createSelector(selectReport, (state: ReportsState) => state.isActive);
export const selectLastSorting = createSelector(
    selectReport,
    (state: ReportsState) => state.sortingList
);
export const selectDownLoadLink = createSelector(
    selectReport,
    (state: ReportsState) => state.downloadLink
);
export const renameReportError = createSelector(
    selectReport,
    (state: ReportsState) => state.renameReportError
);
export const renameReportSuccess = createSelector(
    selectReport,
    (state: ReportsState) => state.renameReportSuccess
);
export const selectQuerySearchReportList = createSelector(
    selectReport,
    (state: ReportsState) => state.querySearch
);
export const selectIsOpenReportKindList = createSelector(
    selectReport,
    (state: ReportsState) => state.isOpenReportKindList
);
export const selectIsDisableClearFilter = createSelector(
    selectQuerySearchReportList,
    selectReportDateRange,
    (query, dateRange) => {
        if (
            query ||
            (dateRange &&
                (!moment(dateRange.startDate).isSame(
                    moment().startOf('day').subtract(1, 'months'),
                    'day'
                ) ||
                    !moment(dateRange.finishDate).isSame(moment(moment().endOf('day')), 'day')))
        ) {
            return false;
        }
        return true;
    }
);
