<impact-map></impact-map>
<div
    class="impact-wrapper"
    [ngClass]="{
        'open-sidebar': store.select(selectSidebarIsOpen) | async
    }"
    [class.impact-wrapper__full-layout]="isFullPageLayout"
>
     <div class="ca-header-wrapper with-tabs" *ngIf="!isEvents else eventHeader">
        <div class="header">
            <header-page
                [textObject]="{
                    titlePage: title
                }"
            ></header-page>
        </div>
         <ca-page-tabs
            [tabs]="tabs"
            [selectedTab]="selectedTab"
            (showTab)="openPage($event)"
        ></ca-page-tabs>
    </div>
    <ng-template #eventHeader>
        <div class="header-events">
          {{titleEvents}}
        </div>
     </ng-template>
    <div class="content" [ngClass]="{'events': isEvents }">
        @if (store.select(selectRunsLoadError) | async) {
            <div class="load-error-data">
                <span>{{ errorListLoadText }}</span>
            </div>
        } @else {
            <router-outlet></router-outlet>
        }
    </div>
</div>
<ca-sidebar-toggle-button *ngIf="!isFullPageLayout"
    class="toggle-button"
    [ngClass]="{ 'open-sidebar': store.select(selectSidebarIsOpen) | async }"
    [isActive]="store.select(selectSidebarIsOpen) | async"
    (toggle)="toggleSideBar()"
></ca-sidebar-toggle-button>