import { ChangeDetectionStrategy, Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { isFalseNumber } from '@libs/common';

@Component({
    selector: 'value-rounded',
    templateUrl: './value-rounded.component.html',
    styleUrls: ['./value-rounded.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueRoundedComponent implements OnChanges {
    @Input() value: number;
    @Input() color: string;
    @Input() selected = false;
    @Input() numberAfterDot?: number = 0;

    displayValue: number;
    hasNoData: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            const { value, numberAfterDot } = this;
            const hasNoData = isFalseNumber(value);
            this.displayValue = hasNoData ? 0 : Number(value.toFixed(numberAfterDot));
            this.hasNoData = hasNoData;
        }
    }
}
