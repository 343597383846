import { MeasureScheme } from '@libs/common';
import type { BBox, Point } from 'geojson';

export enum FORECAST_PAGES {
    forecast = 'forecast',
    points = 'points',
    create = 'create',
    edit = 'edit',
}

export const FORECAST_INIT_PAGE = FORECAST_PAGES.points;
export interface DateRange {
    startDate: number;
    finishDate: number;
}

export interface ITimeSeries {
    object: string;
    indexes: string[];
    values: any;
    hash: string;
}
export interface ControlPointForecast {
    id?: string;
    name: string;
    lat: number;
    lon: number;
    obj?: string;
    timeseries?: ITimeSeries;
    group_id?: string;
}

export interface ControlPointResponse {
    id: number;
    control_points: ControlPointForecast[];
}
export interface TimeSeries {
    obj: string;
    values: {
        [mmt: string]: number[];
    };
    details?: {
        [mmt: string]: number[];
    };
}
export interface IGeoPoint {
    coordinates: [number, number];
    type: string;
}
export interface DataMeasurements {
    measurements: TimeSeries;
    indexes: TimeSeries;
}
export interface LocationData {
    id: number;
    timeseries: ITimeSeries;
}
export interface MetaExtraStation {
    dates: string[];
    group_id: number;
}
export interface MetaStationResponse {
    extra: MetaExtraStation;
    status: string;
    type: string;
    error: any;
}
export interface StationResponse {
    data: Station[];
    meta: MetaStationResponse;
}
export interface Station {
    id: string;
    name: string;
    geometry: IGeoPoint;
    tzOffset: number;
    gmt_offset_seconds: number;
    data: DataMeasurements;
    group_id?: number;
    obj: string;
    ancestor?: Station;
}
export interface IStationParams {
    group_id: string;
    date__gt: string;
    date__lt: string;
    packet_type: string;
}
export interface ForecastDomain {
    id: number;
    bbox: BBox;
    centroid: Point;
    slug: string;
    start?: string;
    end?: string;
}
export interface ForecastConfig {
    id: number;
    start: string;
    end: string;
    species_list: string[];
    step_minutes: number;
    heights: number[];
    domain: ForecastDomain;
    meteo: boolean;
}
type VangaSchemeType = 'default' | 'mpc_instantaneous' | 'c_mmhg_mg';
export function getVangaScheme(scheme: MeasureScheme): VangaSchemeType {
    switch (scheme) {
        case MeasureScheme.mpc:
            return 'mpc_instantaneous';
            break;
        case MeasureScheme.c_mmhg_mg:
            return 'c_mmhg_mg';
            break;
        default:
            return 'default';
            break;
    }
}
