import { CalendarMonth } from './dataModel';

export class CalendarState {
    months: CalendarMonth[];
    monthsMobile: CalendarMonth[]; // reverse
    begin: number;
    end: number;
    finish = true;
    isMainTimePriority = true;
    activeBtn: string = null;
    showCalendar = false;
}
