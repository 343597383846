import { Component, OnDestroy } from '@angular/core';
import { PATH } from '@cityair/modules/posts-and-devices/components/post-create/post-create.component';
import { StationDataToExcelRequestProps } from '@cityair/namespace';
import { TabModel, CheckboxItem, TEXTS, LANGUAGE } from '@libs/common';
import { UntypedFormBuilder } from '@angular/forms';
import { PostsAndDevicesFacade } from '@cityair/modules/posts-and-devices/posts-and-devices.facade';
import * as moment from 'moment-timezone';
import { LOAD_HISTORY_DEFAULT } from '@cityair/libs/shared/utils/config';

import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';
import { ActivatedRoute } from '@angular/router';
import { NgLocalization } from '@angular/common';
import { selectAllDevices } from '@libs/shared-store';
import { selectCurrentSerialNumberDevice } from '@cityair/modules/core/store/selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
    downloadDataDevice,
    selectDownloadResult,
} from '@cityair/modules/core/store/posts/posts.feature';
import { Subject } from 'rxjs';

@Component({
    selector: 'device-report',
    templateUrl: './device-report.component.html',
    styleUrls: ['./device-report.component.less'],
})
export class DeviceReportComponent implements OnDestroy {
    TEXTS = TEXTS;
    pages = NETWORK_PAGES;

    isActiveLoader = false;
    navigationLink: PATH[] = [];
    listDevices: CheckboxItem[] = [];
    isShowDropdownForStations = false;
    errorDownloadMsg: string;
    objectForReportStation: StationDataToExcelRequestProps = {
        type: 2,
        timeBegin: null,
        timeEnd: null,
        ids: [],
    };

    serialNum;
    isSelectAll = false;
    tags: string[] = [];
    maxLength = 4;
    editable = false;
    disabledDevices = true;
    idRouter = null;
    private onDestroy$ = new Subject<void>();
    listReportType: TabModel[] = [
        {
            type: 1,
            title: 'Excel',
        },
        {
            type: 2,
            title: 'CSV',
        },
    ];

    selectedListReportType: TabModel = this.listReportType[1];

    postsListLabels = (() => {
        const { showMore, collapse } = TEXTS.NOTIFICATIONS;

        return {
            all: () => '',
            expand: (num: number) =>
                ({
                    ru: `${showMore} ${num}`,
                }[LANGUAGE] || `${num} ${showMore}`),
            collapse: () => collapse,
        };
    })();

    constructor(
        private fb: UntypedFormBuilder,
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        private activatedRoute: ActivatedRoute,
        public store: Store<any>,
        private ngLocalization: NgLocalization
    ) {
        this.idRouter = this.activatedRoute.snapshot.params.id;
        const isCheckExists = this.postsAndDevicesFacade.checkExistsPage(this.idRouter);
        if (isCheckExists || this.idRouter == 'all') {
            this.getDataForDeviceReport();
            this.isSelectAll = this.idRouter == 'all';
        } else {
            this.postsAndDevicesFacade.openPage(this.pages.networks + '/' + this.pages.devicesList);
        }
        this.store
            .select(selectDownloadResult)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                if (data !== null) {
                    this.offActiveLoader();
                }
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
    getDataForDeviceReport() {
        this.objectForReportStation.timeBegin = this.getStndTimeBegin();
        this.objectForReportStation.timeEnd = this.getStndTimeEnd();
        this.store
            .select(selectCurrentSerialNumberDevice)
            .pipe(take(1))
            .subscribe((smd) => {
                this.serialNum = smd;
            });

        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.postAndDevices,
            path: this.pages.networks + '/' + this.activatedRoute.snapshot.url[0],
        });
        if (this.activatedRoute.snapshot.url[1].path == this.pages.details) {
            this.navigationLink.push({
                name: this.serialNum,
                path:
                    this.pages.networks +
                    '/' +
                    this.activatedRoute.snapshot.url[0] +
                    '/' +
                    this.activatedRoute.snapshot.url[1] +
                    '/' +
                    this.activatedRoute.snapshot.params.id,
            });
        }
        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.dataDevice,
            path: null,
        });

        this.createItems();
    }

    private createItems() {
        const isAllMo = this.activatedRoute.snapshot.params.id == 'all';

        this.store
            .select(selectAllDevices)
            .pipe(
                filter((smd) => !!smd?.length),
                take(1)
            )
            .subscribe((devices) => {
                const items = devices.map((s) => ({
                    id: s.id,
                    label: s.serial_number,
                    selected: s.serial_number === this.serialNum || isAllMo,
                }));
                this.updateStations(items);
            });
    }

    updateStations(items: CheckboxItem[]) {
        this.tags = [];
        this.listDevices = items;
        const ids = [];
        this.objectForReportStation = { ...this.objectForReportStation, ids };
        items.forEach((item) => {
            if (item.selected) {
                this.tags.push(item.label);
                ids.push(Number(item.id.toString().replace('device_', '')));
            }
        });
        this.objectForReportStation = { ...this.objectForReportStation, ids };
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    getDateBeginRangeFromCalendar(time) {
        this.objectForReportStation = { ...this.objectForReportStation, timeBegin: time };
    }

    getDateEndRangeFromCalendar(time) {
        this.objectForReportStation = { ...this.objectForReportStation, timeEnd: time };
    }

    removeFromList(index: number) {
        this.listDevices.find((off) => {
            if (off.label === this.tags[index]) {
                off.selected = false;
                return true;
            }
        });

        this.updateStations([...this.listDevices]);
    }

    getStndTimeBegin: () => number = () =>
        moment().startOf('hour').subtract(LOAD_HISTORY_DEFAULT, 'days').valueOf();

    getStndTimeEnd: () => number = () => {
        const m = moment();
        m.startOf('minutes');
        m.subtract(m.get('minutes') % 20, 'minutes'); // floor of 20 minutes
        return m.valueOf();
    };

    showSelectedTab(tab: TabModel) {
        this.editable = true;
        this.disabledDevices = false;
        this.selectedListReportType = tab;
        this.objectForReportStation = { ...this.objectForReportStation, type: tab.type };
        if (tab.type == 2) {
            this.editable = false;
            this.disabledDevices = true;
            this.createItems();
        }
    }

    selectedCheckboxText(num: number = 0) {
        const { selectedStation, station } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);

        return [selectedStation[category], num, station[category]].join(' ');
    }

    downloadReportStation = () => {
        const fileExtension = this.objectForReportStation.type == 1 ? '.xlsx' : '.csv';
        const filename =
            TEXTS.DOWNLOAD_POPUP.filenameDataDevice +
            moment().format('_YYYYMMDD_HHmm') +
            fileExtension;
        this.isActiveLoader = true;
        this.store.dispatch(downloadDataDevice({ params: this.objectForReportStation, filename }));
    };

    offActiveLoader() {
        setTimeout(() => {
            this.isActiveLoader = false;
        }, 1000);
    }
}
