import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'search-input-basic',
    templateUrl: './search-input-basic.component.html',
    styleUrls: ['./search-input-basic.component.less'],
})
export class SearchInputBasicComponent implements OnInit, OnChanges {
    @Input() textPlaceholderInner = '';
    @Input() textInner = '';
    @Input() debounceTimeInner = 0;
    @Output() textChangeInner = new EventEmitter<string>();

    form: UntypedFormGroup;
    focus: boolean;

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            textInput: [this.textInner],
        });

        this.form.controls.textInput.valueChanges
            .pipe(debounceTime(this.debounceTimeInner), distinctUntilChanged())
            .subscribe((value) => {
                this.textInner = value;
                this.textChangeInner.emit(value);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.textInner == '' && typeof this.form != 'undefined') {
            this.form.reset();
        }
        if (changes.text && !changes.text.firstChange) {
            this.form.controls.textInput.patchValue(changes.text.currentValue);
        }
    }

    clear() {
        this.form.controls.textInput.patchValue('');
    }

    onFocus() {
        this.focus = true;
    }

    onBlur() {
        this.focus = false;
    }
}
