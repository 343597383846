<ca-popup-container [title]="title" (closeAction)="cbCancelBtn.emit()">
    <div class="popup__wrapper_popup_confirm">
        <ng-content></ng-content>
        <div class="popup__wrapper_btn">
            <ca-button
                class="popup__btn popup__btn-left"
                data-cy="cancel-btn"
                type="cancel"
                (clickAction)="cbCancelBtn.emit()"
            >
                {{ btnCancel }}
            </ca-button>
            <ca-button
                [ngClass]="{ popup__btn: true, 'popup__btn-disabled': isSaveBtnDisabled }"
                type="primary"
                (clickAction)="cbAcceptBtn.emit()"
                [disabled]="isSaveBtnDisabled || isActiveLoader"
            >
                <ca-spinner *ngIf="isActiveLoader; else showContent"></ca-spinner>
                <ng-template #showContent>
                    {{ btnAccept }}
                </ng-template>
            </ca-button>
        </div>
    </div>
</ca-popup-container>
