import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ForecastMainComponent } from './components/forecast-main/forecast-main.component';
import { ControlPointsComponent } from './components/control-points/control-points.component';
import { CreateControlPointComponent } from './components/create-control-point/create-control-point.component';
import { FORECAST_INIT_PAGE, FORECAST_PAGES } from './models';

export const forecastRoutes: Routes = [
    {
        path: '',
        component: ForecastMainComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: FORECAST_INIT_PAGE,
            },
            {
                path: FORECAST_PAGES.points,
                component: ControlPointsComponent,
            },
            {
                path: FORECAST_PAGES.points + '/' + FORECAST_PAGES.create,
                component: CreateControlPointComponent,
            },
            {
                path: FORECAST_PAGES.points + '/' + FORECAST_PAGES.edit + '/:id',
                component: CreateControlPointComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(forecastRoutes)],
    exports: [RouterModule],
})
export class ForecastRoutingModule {}
