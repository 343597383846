import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'ca-airvoice-login-page',
    templateUrl: 'airvoice-login-page.component.html',
    styleUrls: ['airvoice-login-page.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirvoiceLoginPageComponent {
    clientName: string;
    logoSVG = 'airvoice-logo.svg';
    privacyPolicyLink = 'https://myindoor.global/privacy-policy';

    phone1 = '+91 76830 45836';
    phone1Link = '+917683045836';

    phone2 = '+44 (0)20 8089 9027';
    phone2Link = `+4402080899027`;

    infoEmail = `support@airvoice.global`;
}
