<div class="network-pin">
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="55" viewBox="0 0 49 55" fill="none">
      <g filter="url(#filter0_d_21146_22987)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5 4C14.2827 4 6 12.2827 6 22.5C6 30.3876 10.9363 37.1224 17.8881 39.7834C19.1037 40.5703 19.9855 41.9276 20.8191 43.2105C21.1975 43.793 21.566 44.3602 21.9513 44.8517L22.999 46.1823C23.1666 46.4026 23.3929 46.5806 23.6527 46.6992C23.9125 46.8179 24.2058 46.8857 24.4992 46.8857C24.7925 46.8857 25.0859 46.8179 25.3373 46.6908C25.6055 46.5721 25.8234 46.3941 25.991 46.1823L27.047 44.8517C27.4323 44.3602 27.8008 43.793 28.1793 43.2105C29.0123 41.9284 29.8935 40.5721 31.1079 39.785C38.0618 37.125 43 30.3892 43 22.5C43 12.2827 34.7173 4 24.5 4Z" attr.fill="{{ color }}"/>
        <rect x="15.5303" y="13.5303" width="17.9399" height="17.9399" rx="8.96997" fill="white"/>
      </g>
      <defs>
        <filter id="filter0_d_21146_22987" x="0" y="0" width="49" height="54.8857" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="3"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.209069 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21146_22987"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21146_22987" result="shape"/>
        </filter>
      </defs>
    </svg>
</div>
