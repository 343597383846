<div class="plumes_calc scroll">
    <div class="plumes_calc__date-filter">
        <div class="run-filter" *ngIf="configByRuns?.length > 2">
            <input-dropdown
                [inputText]="selectedFilter ? selectedFilter?.label : textAllFilter"
                [label]="labelConfigFilter"
                [(showDropdown)]="isShowDropdownForFilter"
                (clickOutside)="isShowDropdownForFilter = false"
            >
                <selectbox-radio
                    [isLeftCheckPosition]="false"
                    [listItems]="configByRuns"
                    (listItemsChange)="
                        selectFilter($event); isShowDropdownForFilter = false
                    "
                ></selectbox-radio>
            </input-dropdown>
        </div>
        <div class="calendar-filter"
             (clickOutside)="calendarIsOpened = false"
            [ngClass]="{ 'no-filter': configByRuns?.length <= 2 }"
        >
            <div (click)="toggleCalendar()">
                <input-for-dropdown
                    [label]="labelPeriod"
                    [inputValue]="dateRange | dateRangeText"
                    [(isOpenDropdown)]="calendarIsOpened"
                ></input-for-dropdown>
            </div>
            <div class="plumes_calc__calendar">
                <calendar-months
                    [ngClass]="{ 'no-filter': configByRuns?.length <= 2 }"
                    [show]="calendarIsOpened"
                    [timeBegin]="dateTimeBegin"
                    [timeEnd]="dateTimeEnd"
                    [applyCb]="updateDateRange"
                    [monthsVisible]="1"
                    [updateDays]="updateDays"
                    [columns]="1"
                    [numberOfMonths]="12"
                ></calendar-months>
            </div>
        </div>
    </div>
    <div *ngIf="loadError; else resultTemplate">
        <div class="load-error-data">
            <span>{{ errorListLoadText }}</span>
        </div>
    </div>
    <ng-template #resultTemplate>
        <div *ngIf="loaded$ | async as loaded; else loadingTemp">
            <ng-container *ngIf="runs.length === 0; else runsContent">
                <div class="plumes_calc__plug_text">{{ textNoRunsAvailable }}</div>
                <div class="plumes_calc__svg"></div>
            </ng-container>
            <ng-template #runsContent>
                <div class="plumes_calc__content scroll" (scroll)="onScroll()" #scrollable>
                    <ng-container *ngFor="let run of runs">
                        <div class="plumes_calc__annotation" *ngIf="run.annotation">
                            {{ run.annotation }}
                        </div>
                        <div class="plumes_calc__card_wrapper" (click)="selectRun(run.item)">
                            <plumes-calculation-results-card
                                [isActive]="run.item.id === currentRunId"
                                [isLoading]="run.item.id === currentRunId && isLoadingRun"
                            >
                                <plumes-run-summary
                                    class="plumes_calc__wrapper_run_summary"
                                    [run]="run.item"
                                    [config]="store.select(selectRunsConfigById(run.item.config)) | async"
                                ></plumes-run-summary>
                            </plumes-calculation-results-card>
                        </div>
                    </ng-container>
                </div>
                <ca-scroll-top *ngIf="!isScrolledToTop" (click)="scrollTop()"></ca-scroll-top>
            </ng-template>
        </div>
        <ng-template #loadingTemp>
            <div class="wrapper-loading">
                <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
            </div>
        </ng-template>
    </ng-template>
</div>
