<div class="event-detail">
    <div class="back" (click)="back()">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                <path d="M2.71599 8.00027L8.6705 2.12309C9.10983 1.68946 9.10983 0.986399 8.6705 0.552765C8.23116 0.119131 7.51884 0.119131 7.0795 0.552765L0.329505 7.2151C-0.109835 7.64874 -0.109835 8.3518 0.329505 8.78543L7.0795 15.4478C7.51884 15.8814 8.23116 15.8814 8.6705 15.4478C9.10983 15.0141 9.10983 14.3111 8.6705 13.8774L2.71599 8.00027Z" fill="#6097F3"/>
            </svg>
        </div>
        <div class="text">
            {{texts.backTitle}}
        </div>
    </div>
    <div class="content scroll">
        <div class="event" *ngIf="currentEvent">
            <ca-impact-event-card [currentSchema]="currentSchema" [item]="currentEvent"></ca-impact-event-card>
            <div class="posts-list">
                <div class="header">{{texts.postsTitle}}</div>
                <div class="table-post">
                    <div class="header">
                        <div class="post">{{texts.postHeader}}</div>
                        <div class="value-block">{{texts.maxHeader}}</div>
                    </div>
                    <div class="list-posts"  *ngIf="currentEvent?.events | postIdsByEvents as uniquePosts">
                        <div class="post-row" *ngFor="let postId of uniquePosts">
                            <div class="post">
                                <ng-container *ngIf="(store.select(selectPostNameById(postId))| async) as name">{{name}}</ng-container>
                            </div>
                            <div class="max-value value-block"  *ngIf="currentEvent | maxValueEvent : postId as maxData">
                                <span class="value">
                                    {{maxData.value | convertSchemaValue : currentSchema : currentEvent.param | valueFormatNumber: NUMBER_AFTER_DOT[currentSchema]}}
                                </span>
                                <span class="unit" [innerHtml]="textsScheme[currentSchema][currentEvent.param]"></span>
                                <span class="time"> {{maxData.time | shortDateFormat}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="posts-list">
                <div class="header">{{texts.mainImpactHeader}}</div>
                <div class="desc" *ngIf="currentEvent?.events | postIdsByEvents as uniquePosts">
                    {{texts.mainImpactDescription}} {{postText(uniquePosts.length)}} {{texts.mainImpactDescriptionFor}}
                    <span [innerHtml]="textNames[currentEvent.param]"></span>
                </div>
            </div>
            <div class="doughnut__wrapper" *ngIf=" chartEventData | dataSourceEvent : currentEvent.param : NUMBER_AFTER_DOT[currentSchema] as chartData">
                <div class="doughnut-chart">
                    <canvas
                        baseChart
                        [type]="doughnutChartType"
                        [datasets]="chartData?.datasets"
                        [labels]="chartData?.labels"
                        [options]="options"
                    ></canvas>
                </div>
                <div class="doughnut__items_wrapper">
                    <div *ngFor="let val of chartData?.datasets[0]?.data; index as i">
                        <div class="doughnut__item">
                            <span class="doughnut__circle" [style]="{ background: chartData.colors[i] }"></span>
                            <span class="doughnut__point">{{chartData?.labels[i]}}</span>
                            <span class="doughnut__value-block">
                                <span class="doughnut__value">
                                    <ng-container *ngIf="{a: val | isNeedRoundingSource : NUMBER_AFTER_DOT[currentSchema] } as value">
                                        <ng-container *ngIf="value?.a !== null else defaultVal">
                                            < {{ value?.a | valueFormatNumber : NUMBER_AFTER_DOT[currentSchema]  }}
                                        </ng-container>
                                        <ng-template #defaultVal>
                                             {{ val | valueFormatNumber : NUMBER_AFTER_DOT[currentSchema]  }}
                                        </ng-template>
                                    </ng-container>
                                </span>
                                <span class="doughnut__unit" [innerHtml]="textsScheme[currentSchema][currentEvent.param]"></span>
                                <span class="doughnut__percent">
                                    <ng-container *ngIf="{val: chartData?.percents[i] | isNeedRoundingSource : 0 } as value">
                                        <ng-container *ngIf="value?.val !== null else defaultVal">
                                            < {{ value?.val | valueFormatNumber : 0  }}%
                                        </ng-container>
                                        <ng-template #defaultVal>
                                             {{ chartData?.percents[i] | valueFormatNumber : 1  }}%
                                        </ng-template>
                                    </ng-container>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show-more" (click)="setDetails()">
                <div class="show-more-btn">
                    {{texts.showMoreBtn}}
                </div>
            </div>
        </div>
    </div>
    <div class="details" *ngIf="showDetails">
        <div class="content-details">
            <div class="close" (click)="closeDetail()">
                {{texts.closeBtn}}
            </div>
            <div class="header">
                {{texts.postsHeader}}
            </div>
            <div class="print">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                      <path d="M5 7V1H17V7" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5 16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V9C1 8.46957 1.21071 7.96086 1.58579 7.58579C1.96086 7.21071 2.46957 7 3 7H19C19.5304 7 20.0391 7.21071 20.4142 7.58579C20.7893 7.96086 21 8.46957 21 9V14C21 14.5304 20.7893 15.0391 20.4142 15.4142C20.0391 15.7893 19.5304 16 19 16H17" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M17 12H5V19H17V12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="text">{{texts.printRtn}}</div>
            </div>
            <div class="data scroll">
                <div class="item" *ngFor="let event of currentEvent?.events">
                    <div class="post-data" *ngIf="chartEventData?.[event.post_id] | eventContribution : currentEvent.param : currentSchema as eventData">
                        <div class="post-name">
                            <ng-container *ngIf="(store.select(selectPostNameById(event?.post_id))| async) as name">{{name}}</ng-container>
                        </div>
                        <div class="event-post-data">
                             <div class="column-1">
                                <div class="header-column">
                                    {{texts.maxHeader}}
                                </div>
                                <div class="max-value">
                                    <span class="value">
                                        {{event.max_value  | convertSchemaValue : currentSchema : currentEvent.param | valueFormatNumber: NUMBER_AFTER_DOT[currentSchema]}}
                                    </span>
                                    <span class="unit" [innerHtml]="textsScheme[currentSchema][currentEvent.param]"></span>
                                    <span class="time"> {{event.max_time | shortDateFormat}}</span>
                                </div>
                                <div class="header-column">{{texts.mainImpact}}</div>
                                <div class="event-data-main">
                                    <div class="event-row-data" *ngIf="eventData[0] as row">
                                        <div class="circle" [style]="{ background: row.color }"></div>
                                        <div class="label">{{row.label}}</div>
                                        <div class="value">{{row.value | valueFormatNumber : NUMBER_AFTER_DOT[currentSchema] }}</div>
                                        <div class="unit" [innerHtml]="textsScheme[currentSchema][currentEvent.param]"></div>
                                        <div class="percent">
                                            <ng-container *ngIf="{val: row.percent | isNeedRoundingSource : 0 } as value">
                                                <ng-container *ngIf="value?.val !== null else defaultVal">
                                                    < {{ value?.val | valueFormatNumber : 0  }}%
                                                </ng-container>
                                                <ng-template #defaultVal>
                                                     {{ row.percent | valueFormatNumber : 1  }}%
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                     </div>
                                </div>
                            </div>
                            <div class="column-2">
                                <div class="header-column">{{texts.repeatHeader}}</div>
                                <div class="repeat-duration">{{event | repeatExceedEvent : currentEvent }}</div>
                                <div class="header-column">{{texts.mainImpact}}</div>
                                <div class="event-data-main">
                                    <div class="event-row-data" *ngIf="event | eventContributionByTime : chartEventData?.[event.post_id] : currentEvent.param  as row">
                                        <div class="circle" [style]="{ background: row.color }"></div>
                                        <div class="label">{{row.label}}</div>
                                        <div class="value">{{row.value | valueFormatNumber : NUMBER_AFTER_DOT[currentSchema]}}</div>
                                        <div class="unit" [innerHtml]="textsScheme[currentSchema][currentEvent.param]"></div>
                                        <div class="percent">
                                            <ng-container *ngIf="{val: row.percent | isNeedRoundingSource : 0 } as value">
                                                <ng-container *ngIf="value?.val !== null else defaultVal">
                                                    < {{ value?.val | valueFormatNumber : 0 }}%
                                                </ng-container>
                                                <ng-template #defaultVal>
                                                     {{ row.percent | valueFormatNumber : 1  }}%
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                     </div>
                                </div>
                            </div>
                            <div class="column-3">
                                <div class="header-column">{{texts.allSourceHeader}}</div>
                                <div class="event-data">
                                    <div class="event-row-data" *ngFor="let row of eventData">
                                        <div class="circle" [style]="{ background: row.color }"></div>
                                        <div class="label">{{row.label}}</div>
                                        <div class="percent">
                                            <ng-container *ngIf="{val: row.percent | isNeedRoundingSource : 1 } as value">
                                                <ng-container *ngIf="value?.val !== null else defaultVal">
                                                    < {{ value?.val | valueFormatNumber : 1  }}%
                                                </ng-container>
                                                <ng-template #defaultVal>
                                                     {{ row.percent | valueFormatNumber : 1  }}%
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="chartEventData?.[event.post_id] as chartPostData">
                            <div class="chart-data-wrapper">
                                <cs-chart-post-correlation
                                    [data]="chartPostData"
                                    [mmts]="[currentEvent.param]"
                                    [measureScheme]="currentSchema"
                                    (sizeUpdate)="updateChartSize($event)"
                                ></cs-chart-post-correlation>
                            </div>
                            <div class="line-chart-panel"  #timelineTrack
                                    [style.width.px]="(charSize$ | async)?.width"
                            >
                                <div class="line-chart-wrapper">
                                    <ng-container  *ngIf="chartPostData[0].properties?.contributions?.date as dates">
                                        <ng-container *ngIf="dates  | calculateCurrentTickFreq : currentWidthTimeline as tickFreq">
                                            <div class="line-chart-data">
                                                <div class="line-chart-block-wrapper" *ngFor="let item of dates; index as i;">
                                                    <ng-container *ngIf="i | needRenderTick : tickFreq.freq">
                                                        <div
                                                            *ngIf="i | needRenderTick : tickFreq.formatFreq; else timeLabel"
                                                            class="label-time-block"
                                                        >
                                                            <span class="time-line-track">{{ item | shortDateTimeline  }}</span>
                                                            <div class="tick-block"></div>
                                                        </div>
                                                        <ng-template #timeLabel>
                                                            <div class="label-time-block">
                                                                <span class="time-line-track">{{ item | caTime }}</span>
                                                                <div class="tick-block"></div>
                                                            </div>
                                                        </ng-template>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="correlation">
                        <div class="title">
                            {{texts.correlationChartHeader}}
                            <ca-info-icon
                                    class="icon-info"
                                    [withGradient]="true"
                                    (click)="isShowInfoPopupCorrelation = !isShowInfoPopupCorrelation"
                            ></ca-info-icon>
                        </div>
                        <div class="chart-wrapper-correlation" *ngIf="event | correlationChartData : currentEvent.param :posts : correlationData as corData">
                            <div class="legend">
                                <div class="label-data">
                                    <div class="border" [ngStyle]="{'background-color': '#5938D2' }"></div>
                                    <div class="label">— {{texts.threeMonthLabel}}</div>
                                </div>
                                <div class="label-data">
                                    <div class="border" [ngStyle]="{'background-color': '#5FC6B1' }"></div>
                                    <div class="label">— {{texts.lastDayLabel}}</div>
                                </div>
                            </div>
                            <div class="chart-wrapper" style="display: block; width: 100%; height: 239px">
                                <cs-impact-area-chart [data]="corData"></cs-impact-area-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="popup-wrapper" *ngIf="isShowInfoPopupCorrelation">
    <shared-ui-info-correlation-popup (closePopup)="isShowInfoPopupCorrelation = false"></shared-ui-info-correlation-popup>
</div>
