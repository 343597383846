import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    selector: 'cityair-impact-source-pin',
    templateUrl: './impact-source-pin.component.html',
    styleUrls: ['./impact-source-pin.component.less'],
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactSourcePinComponent {
    @Input() name: string;
    @Input() id: number;
    @Input() isCollapse = false;
    @Input() type: string;
}
