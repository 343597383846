<div *ngIf="path.length > 0" class="path-block">
    <ng-container *ngFor="let p of path; let i = index">
        <span
            (click)="setOutputPath(p)"
            [ngClass]="{ 'path-block__element': true, 'path-block__element--end': p.path === null }"
            >{{ p.name }}</span
        >
        <span *ngIf="i < path.length - 1" class="path-block__dots">&#8226;</span>
    </ng-container>
</div>
<div class="title ellipsis">
    {{ title }}
</div>
