import { Pipe, PipeTransform } from '@angular/core';
import { EventImpact, EventItem } from '@cityair/modules/impact/service/api-model-impact';
import moment from 'moment-timezone';

@Pipe({
    name: 'postIdsByEvents',
})
export class PostIdsByEventsPipe implements PipeTransform {
    transform(events: EventItem[]): string[] {
        const list = events.reduce((acc, item) => {
            if (acc.includes(item.post_id)) {
                return acc;
            }
            return [...acc, item.post_id];
        }, []);

        return list;
    }
}
