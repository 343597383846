<ng-container *ngIf="isCollapse else defaultTemp">
    <div class="impact-source-pin__collapsed">
        <div class="end"></div>
    </div>
</ng-container>
<ng-template #defaultTemp>
    <div class="impact-source-pin">
        <div class="impact-source-pin__body">
            <div class="impact-source-pin__circle">
                <div [className]="'icon ' + type"></div>
            </div>
        </div>
        <div class="impact-source-pin__line"></div>
        <div class="impact-source-pin__end"></div>
    </div>
</ng-template>
