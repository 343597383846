<div
    class="range-slider-single"
    [ngStyle]="{
        background: getGradient()
    }"
>
    <div class="range-slider__track">
        <div
            class="range-slider__track_active"
            [ngStyle]="{
                width: handleMinPosition + '%'
            }"
        ></div>
        <div
            class="range-slider__track_after"
            [ngStyle]="{
                width: 100 - handleMinPosition + '%'
            }"
        ></div>
    </div>
    <div class="range-slider__handle" [style]="{ left: handleMinPosition + '%' }">
        <span class="handle__value">{{ currentMinValue }}</span>
    </div>
    <div class="range-slider__measure">
        <div class="measure__min">{{ minValue }}</div>
        <div class="measure__max">{{ maxValue }}</div>
        <div class="measure__unit">{{ measure }}</div>
    </div>
</div>
