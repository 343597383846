import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarMonthsComponent } from './calendar-months/calendar-months.component';
import { CalendarMonthsMobileComponent } from './calendar-months-mobile/calendar-months-mobile.component';
import { DirectivesModule } from '@libs/shared-ui';

@NgModule({
    exports: [CalendarMonthsComponent, CalendarMonthsMobileComponent],
    declarations: [CalendarMonthsComponent, CalendarMonthsMobileComponent],
    imports: [CommonModule, DirectivesModule],
})
export class CalendarMonthsModule {}
