import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'range-slider-hours',
    templateUrl: './range-slider-hours.component.html',
    styleUrls: ['./range-slider-hours.component.less'],
})
export class RangeSliderHoursComponent {
    @Input() handleMinPosition: number;
    @Input() minPositionName: string;
    @Input() handleMaxPosition: number;
    @Input() maxPositionName: string;
}
