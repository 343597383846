<div class="subscription_card">
    <ca-card
        [title]="subscription.name"
        [isActive]="subscription.is_active"
        [statusText]="[textsNotification.statusActive2, textsNotification.statusNotActive]"
        [actionsActive]="popupActive"
        [actionsTooltipText]="TEXTS.COMMON.actions"
        (openActionsPopup)="openPopup($event)"
    >
        <ng-content></ng-content>

        <ng-template #popupOutlet>
            <ca-popup-confirm
                *ngIf="isDownloadPopupOpen"
                [title]="textsNotification.popupDownloadFeetHistory.title"
                [btnAccept]="textsDownloadPopup.download"
                (cbCancelBtn)="downloadFeedHistoryCancel()"
                (cbCloseBtn)="downloadFeedHistoryCancel()"
                (cbAcceptBtn)="downloadFeedHistoryAccept()"
            >
                <div class="subscription_card__popup_wrapper_input">
                    <input-date-range
                        [(timeBegin)]="timeBegin"
                        [(timeEnd)]="timeEnd"
                    ></input-date-range>
                </div>
            </ca-popup-confirm>

            <stnd-ask-popup
                *ngIf="isDeletePopupOpen"
                [accept]="deleteSubscriptionAccept"
                [close]="deleteSubscriptionCancel"
                [texts]="textsNotification.popupDelete"
            ></stnd-ask-popup>

            <card-actions-list
                *ngIf="popupActive"
                [position]="popupPositionTop"
                (clickOutside)="closePopup($event)"
            >
                <card-actions-item
                    data-cy="action-edit"
                    [text]="textsNotification.edit"
                    (click)="editSubscription()"
                ></card-actions-item>

                <card-actions-item
                    data-cy="action-history-dl"
                    [text]="textsNotification.popupDownloadFeetHistory.title"
                    (click)="downloadFeedHistoryDialog($event)"
                ></card-actions-item>

                <card-actions-separator></card-actions-separator>

                <card-actions-item
                    data-cy="action-activate"
                    *ngIf="!subscription.is_active"
                    [text]="textsNotification.activateSubscription"
                    (click)="activateSubscription($event)"
                ></card-actions-item>

                <card-actions-item
                    data-cy="action-deactivate"
                    *ngIf="subscription.is_active"
                    [text]="textsNotification.deactivateSubscription"
                    (click)="deactivateSubscription($event)"
                ></card-actions-item>
                <card-actions-separator></card-actions-separator>
                <card-actions-item
                    type="danger"
                    data-cy="action-delete"
                    [text]="textsNotification.deleteSubscription"
                    (click)="deleteSubscriptionDialog($event)"
                ></card-actions-item>
            </card-actions-list>
        </ng-template>
    </ca-card>
</div>
