<ng-container *ngIf="store.select(selectImpactStations) | async as stations">
    <ng-container *ngIf="store.select(selectIsLoadingPostsTimeline) | async as loading else contentTemp">
        <div class="loading">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
    </ng-container>
    <ng-template #contentTemp>

        <ng-container *ngIf="stations.length === 0 else contentStation">
            <non-data-screen
                class="screen-placeholders"
                [text]="texts.noStations"
            ></non-data-screen>
        </ng-container>
        <ng-template #contentStation>
            <div class="impact-posts">
                <div class="post-data scroll">
                    <div class="wrapper" *ngFor="let station of stations" (click)="setActiveStation(station)">
                        <div class="post-item"
                             *ngIf="(store.select(selectPostNameById(station?.id))| async) as name"
                             [ngClass]="{'active': station.id === activePost } "
                        >
                            <div class="region-row">
                                <div class="name-block">
                                    <div class="name">{{name}}</div>
                                    <div class="decs">{{texts.sites.subTitle}} </div>
                                </div>
                                <div class="value-block" *ngIf="store.select(getStationImpactValue(station?.id))| async as value else emptyVal">
                                    <div class="color-block" *ngIf="getColorFromZone(zone, value) as color"
                                         [ngStyle]="{'backgroundColor': color}"
                                    ></div>
                                    <div class="value">
                                        {{value | valueFormatNumber : digitsAfterDot}}
                                    </div>
                                    <div class="unit" [innerHtml]="textsScheme[store.select(selectMeasureScheme) | async][
                                        currentMeasure
                                    ]"></div>
                                </div>
                                <ng-template #emptyVal>
                                    <div class="value-block">
                                        <div class="color-block"
                                             [ngStyle]="{'backgroundColor': '#b8bfcc' }"
                                        ></div>
                                        <div class="value">
                                           -
                                        </div>
                                        <div class="unit" [innerHtml]="textsScheme[store.select(selectMeasureScheme) | async][
                                            currentMeasure
                                        ]"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
</ng-container>
