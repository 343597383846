<div class="posts-list-view">
    <div class="posts-list-view__search-line">
        <div class="search-wrapper">
            <search-input-basic
                [textPlaceholderInner]="postDeviceText?.searchPost"
                [textInner]="searchQuery"
                [debounceTimeInner]="400"
                (textChangeInner)="textChangeIn($event)"
            ></search-input-basic>
        </div>
    </div>

    <div [@inOutAnimation] class="posts-list-view__main_block scroll">
        <button-add
            class="posts-list-view__button-add"
            *ngIf="
                (store.select(selectMyRole) | async)?.view_device_data &&
                (store.select(selectTotalDevices) | async) > 0
            "
            [text]="postDeviceText?.addPost"
            (action)="openPage($event, path + pages.postCreate)"
        ></button-add>

        <div class="posts-list-view__organizing-buttons">
            <shared-sort-list-item
                class="posts-list-view__organizing-buttons--item"
                [sortingDirection]="postsAndDevicesFacade.sortingDirection.sortPostName"
                [text]="postDeviceText?.post"
                (click)="
                    postsAndDevicesFacade.makePostSorting(
                        postsAndDevicesFacade.sortPostName,
                        'sortPostName'
                    )
                "
            ></shared-sort-list-item>
            <switch-v2
                class="posts-list-view__organizing-buttons--item"
                *ngIf="(store.select(selectMyRole) | async)?.view_device_data"
                [text]="TEXTS.CITY_CARD.dataSources"
                [active]="postsAndDevicesFacade.isSwitchDataSources"
                (activeChange)="postsAndDevicesFacade.showDataSources($event)"
            ></switch-v2>
            <shared-sort-list-item
                class="posts-list-view__organizing-buttons--item posts-list-view__organizing-buttons--item-aqi"
                caTooltip
                caTooltipPlacement="top"
                [caTooltipAllowHover]="true"
                [caTooltipTemplate]="aqiTooltip"
                [sortingDirection]="postsAndDevicesFacade.sortingDirection.sortPostAqi"
                [text]="globalMmtName"
                (click)="
                    postsAndDevicesFacade.makePostSorting(
                        postsAndDevicesFacade.sortPostAqi,
                        'sortPostAqi'
                    )
                "
            ></shared-sort-list-item>

            <ng-template #aqiTooltip>
                <cs-aqi-details-tooltip
                    *ngIf="globalMmt === AqiType.instant && isRU"
                    (openDetailsLink)="openAqiFaqLink(AqiType.instant)"
                ></cs-aqi-details-tooltip>
            </ng-template>
        </div>
        <div class="spinner-wrapper" *ngIf="store.select(selectIsLoadingPosts)|async else dataTemplate">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
        <ng-template #dataTemplate

        >
            <ng-container *ngIf="
                cardMo
                    | multipleSearchfilter
                        : ['name', 'city', 'serialNumber']
                        : searchQuery as result
            ">
                <div
                    *ngFor="
                        let cm of result
                            | asyncSorting
                                : postsAndDevicesFacade.sortingPost
                                : postsAndDevicesFacade.sortingPostDirectionMain
                            | async
                    "
                >
                    <monitoring-object-plate
                        #MOCard
                        class="posts-list-view__monitoring-object-plate"
                        [idMo]="cm.id"
                        [name]="cm.name"
                        [city]="cm.city"
                        [digitsAfterDot]="digitsAfterDot"
                        [value]="store.select(selectPostCurrentValue(cm.id)) | async"
                        [valueColor]="
                            getColorFromZone(
                                store.select(selectZone) | async,
                                store.select(selectPostCurrentValue(cm.id)) | async
                            )
                        "
                        [isActive]="!!(getComparedListObject(cm.id) | async)"
                        [markerState]="getMarkerState(cm.id) | async"
                        [isComplexMode]="postsAndDevicesFacade.isSwitchDataSources"
                        [device]="cm.device"
                        [deviceSourceName]="cm.deviceSourcesName"
                        (openActionsPopup)="clickMenu($event, cm.id)"
                        (cbClickForStaticTagsDevice)="showDetails($event)"
                        (cbClickForCard)="clickToMarker(cm)"
                        [isShowKebabMenu]="
                            (store.select(selectMyRole) | async)?.view_device_data
                        "
                        [isNotConnectedDevices]="cm.isConnectedDevices"
                        [isNoDataSources]="cm.isNoDataSources"
                    ></monitoring-object-plate>
                </div>
                <search-result-placeholder
                    class="screen-placeholders"
                    *ngIf="!isShowNotDate && isShowResultPlaceholder && result.length === 0"
                    [clearText]="TEXTS.LIST_OM.clearSearch" (clear)="searchQuery = ''"
                ></search-result-placeholder>

                <non-data-screen
                    class="screen-placeholders"
                    *ngIf="isShowNotDate"
                    [text]="postDeviceText?.noMonitoringPosts"
                ></non-data-screen>
            </ng-container>
        </ng-template>
    </div>
    <ng-container *ngIf="store.select(selectUserRole) | async as roleId">
        <button-download-data
            class="posts-list-view__button-download-data"
            *ngIf="!isShowNotDate && roleId !== OBSERVER_ID"
            [text]="postDeviceText?.downloadDataFromPosts"
            (click)="redirectReportModules()"
        ></button-download-data>
    </ng-container>
</div>

<ng-template #cardMenu>
    <popup-ask
        class="posts-list-view__popup-ask"
        *ngIf="isShowDeletePopup"
        [@inOutAnimation]
        (cbAccept)="deleteAccept()"
        (cbClose)="deleteCancel()"
        [title]="TEXTS.POPUP_DELETE.title"
        [description]="TEXTS.POPUP_DELETE.body"
        [textAccept]="TEXTS.POPUP_DELETE.accept"
        [textCancel]="TEXTS.POPUP_DELETE.cancelNew"
        [isDeleteAction]="true"
    >
        <static-tags
            class="posts-list-view__static-tags"
            typeTag="mo"
            [text]="nameTargetCard"
        ></static-tags>
    </popup-ask>

    <card-actions-list
        class="posts-list-view__card-actions-list"
        *ngIf="isShowMenu"
        [@inOutAnimation]
        [position]="menuPositionTop"
        (clickOutside)="closePopup($event)"
    >
        <card-actions-item
            data-cy="action-rename"
            [text]="postDeviceText?.viewInformation"
            (click)="openPage($event, path + pages.details + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
            *ngIf="isRU"
            data-cy="action-close"
            [text]="postDeviceText?.downloadTZA4Report"
            (click)="openPage($event, path + pages.postReportTza + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
            data-cy="action-close"
            [text]="postDeviceText?.edit"
            (click)="openPage($event, path + pages.postEdit + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
            data-cy="action-close"
            type="danger"
            [text]="postDeviceText?.deletePost"
            (click)="showPopup($event, 'delete')"
        ></card-actions-item>
    </card-actions-list>
</ng-template>
