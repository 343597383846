import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TEXTS } from '@libs/common';

@Component({
    selector: 'ca-popup-confirm',
    templateUrl: 'popup-confirm.component.html',
    styleUrls: ['popup-confirm.component.less'],
})
export class PopupConfirmComponent {
    @Input() title = '';
    @Input() isActiveLoader = false;
    @Output() cbCloseBtn = new EventEmitter<void>();
    @Output() cbCancelBtn = new EventEmitter<void>();
    @Output() cbAcceptBtn = new EventEmitter<void>();
    @Input() isSaveBtnDisabled = false;
    @Input() btnCancel = TEXTS.COMMON.cancel;
    @Input() btnAccept = TEXTS.COMMON.save;

    TEXTS = TEXTS;

    splashClick = (event) => {
        if (event.currentTarget == event.target) {
            this.cbCloseBtn.emit();
        }
    };
}
