import { Pipe, PipeTransform } from '@angular/core';
import { SourceImpact, SourceType } from '@libs/common';
import { ImpactSourceType } from '../models';

@Pipe({ name: 'impactSourceType' })
export class ImpactSourceTypePipe implements PipeTransform {
    transform(source: SourceImpact): ImpactSourceType {
        if (source?.geo_type) {
            return source?.geo_type === SourceType.area ? 'square' : 'point';
        } else {
            const bbox = source.bbox.map((v) => +v.toFixed(2));
            if (bbox[0] === bbox[2] && bbox[1] === bbox[3]) {
                return 'point';
            } else {
                return 'square';
            }
        }
    }
}
