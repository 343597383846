import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TimelineState } from '../index';
import { ITimelineState } from '../core.reducer';

export const getTimelineState = createFeatureSelector<TimelineState>('timeline');

export const selectCore = createSelector(
    getTimelineState,
    (state: TimelineState): ITimelineState => state.core
);

export const selectChartEnabled = createSelector(
    selectCore,
    (state: ITimelineState) => state.chartEnabled
);

// TODO remove in other modules
export const selectPlayer = createSelector(selectCore, (state: ITimelineState) => state.player);

export const selectMainChartIsLoading = createSelector(selectCore, (state) => state.chartLoading);
