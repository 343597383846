import { Component, OnInit, Input } from '@angular/core';
import { markerState } from '@libs/common';

@Component({
    selector: 'floor-pin',
    templateUrl: './floor-pin.component.html',
    styleUrls: ['./floor-pin.component.less'],
})
export class FloorPinComponent implements OnInit {
    markerState = markerState;

    @Input() value: string;
    @Input() selected: boolean;

    ngOnInit(): void {}
}
