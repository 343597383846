import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import {
    ControlPointImpact,
    DateRangeImpact,
    getDigitsAfterDot,
    MeasureScheme,
    SourceImpact,
} from '@libs/common';
import {
    ImpactActions,
    selectActiveRun,
    selectActiveSources,
    selectControlPoints,
    selectImpactSchemaZones,
} from '@cityair/modules/impact/store/impact.feature';
import {
    CheckboxItem,
    CONTRIBUTIONS_COLORS_SOURCES_ORDER,
    getColorByIndex,
    LANGUAGE,
    MAIN_PAGES,
    TabModel,
    TEXTS,
} from '@libs/common';
import { Store } from '@ngrx/store';
import moment from 'moment-timezone';
import { filter, Subject, takeUntil } from 'rxjs';
import { COMMON_SOURCES_СONTRIBUTION } from '@cityair/modules/impact/consts';
import {
    ImpactStatActions,
    selectDateRangeSource,
    selectImpactStatistics,
    selectIsLoadingStatSource,
    selectMmtsListBySource,
} from '@cityair/modules/impact/store/statistic.feature';
import { selectCurrentMeasureScheme } from '@libs/shared-store';

enum PAGES {
    regions = 'regions',
    points = 'points',
}

const impactToRegion = [
    { name: 'Дзержинский', color: '#FF7344', value: 15, concentration: { pm25: 3.5 } },
    { name: 'Железнодорожный', color: '#F1C21F', value: 2, concentration: { pm25: 1.4 } },
    { name: 'Заельцовский', color: '#9966CB', value: 1, concentration: { pm25: 0.2 } },
    { name: 'Кировский', color: '#448EEC', value: 1, concentration: { pm25: 0.1 } },
    { name: 'Остальные', color: '#90B044', value: 0, concentration: { pm25: 0 } },
];

const impactToControlPoints = [
    { name: 'Аэропорт', color: '#FF7344', value: 5, concentration: { pm25: 2.0 } },
    { name: 'Берёзовка', color: '#F1C21F', value: 2, concentration: { pm25: 1.2 } },
    { name: 'Горский', color: '#9966CB', value: 2, concentration: { pm25: 0.4 } },
    { name: 'Раздольное', color: '#448EEC', value: 1, concentration: { pm25: 0.1 } },
    { name: 'Остальные', color: '#90B044', value: 0, concentration: { pm25: 0 } },
];

@Component({
    selector: 'impact-source',
    templateUrl: 'impact-source.component.html',
    styleUrls: ['impact-source.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactSourceComponent implements OnInit, OnDestroy {
    public textImpact = TEXTS.IMPACT;
    public textNames = TEXTS.NAMES;
    public textCard = TEXTS.IMPACT.sources.sourceCard;
    public source: SourceImpact = null;
    public sources: SourceImpact[] = [];
    public controlPoints: ControlPointImpact[] = [];
    public activeSourceId: number;
    public sourceType: string;
    readonly mainComponentUrl = `/${MAIN_PAGES.impact}/`;
    public ngDestroyed$ = new Subject<void>();
    public isInit = true;
    dateRange: DateRangeImpact;
    calendarIsOpened = false;
    numberOfFutureDays = 1;
    handleUpdateDays: (numberOfFutureDays?: number, minDate?: string) => void;
    dateTimeBegin: number;
    dateTimeEnd: number;
    public mmtList: CheckboxItem[] = [];
    public selectedSourceMmt: CheckboxItem;
    isShowDropdownForMmtList: boolean = false;
    selectIsLoadingStatSource = selectIsLoadingStatSource;
    selectImpactStatistics = selectImpactStatistics;
    measureScheme: MeasureScheme;
    numberAfterDot = 1;

    PAGES = PAGES;
    tabsDuration: TabModel[] = [
        {
            id: PAGES.points,
            title: 'Контрольные точки',
        },
        {
            id: PAGES.regions,
            title: 'Районы города',
        },
    ];
    currentTabDuration: TabModel = this.tabsDuration[0];

    tabsConcentration: TabModel[] = [
        {
            id: PAGES.points,
            title: 'Контрольные точки',
        },
        {
            id: PAGES.regions,
            title: 'Районы города',
        },
    ];
    currentTabConcentration: TabModel = this.tabsConcentration[0];

    tabsContribution: TabModel[] = [
        {
            id: PAGES.points,
            title: 'Контрольные точки',
        },
        {
            id: PAGES.regions,
            title: 'Районы города',
        },
    ];
    currentTabContribution: TabModel = this.tabsContribution[1];

    impactToRegion = impactToRegion;
    impactToControlPoints = impactToControlPoints;
    commonСontribution = COMMON_SOURCES_СONTRIBUTION;
    textsScheme = TEXTS.MEASURES_SCHEME;
    dataChart: number[] = [];
    labelChart: string[] = [];
    colorsChart: string[] = [];
    currentLang = LANGUAGE;
    activeRun$ = this.store.select(selectActiveRun);

    constructor(
        public store: Store,
        public router: Router,
        private route: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.sources }));
        this.route.params.subscribe((params) => {
            if (this.isInit) {
                this.activeSourceId = params?.id;
            }
            _changeDetectorRef.markForCheck();
        });
        this.store
            .select(selectDateRangeSource)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((dates) => {
                this.dateRange = dates;
                this.dateTimeBegin = moment(this.dateRange.start).valueOf();
                this.dateTimeEnd = moment(this.dateRange.end).valueOf();
            });

        this.store
            .select(selectMmtsListBySource)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => v !== null)
            )
            .subscribe(({ list, mmt }) => {
                if (list?.length) {
                    this.mmtList = [];
                    list.map((item) =>
                        this.mmtList.push({
                            id: item,
                            label: this.textNames[item],
                            selected: item === mmt,
                            value: item,
                        })
                    );
                    this.selectedSourceMmt = this.mmtList.find((v) => v.selected);
                    if (this.selectedSourceMmt?.id) {
                        this.updateNumberAfterDot();
                    }
                    this._changeDetectorRef.markForCheck();
                }
            });
        store
            .select(selectCurrentMeasureScheme)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.measureScheme = data;
                this.updateNumberAfterDot();
            });
    }

    ngOnInit() {
        this.dataChart = [];
        this.labelChart = [];
        this.colorsChart = [];

        this.commonСontribution.map((source, index) => {
            const color = getColorByIndex(CONTRIBUTIONS_COLORS_SOURCES_ORDER, index);
            this.dataChart = [...this.dataChart, source.value];
            this.labelChart = [...this.labelChart, source.name];
            this.colorsChart = [...this.colorsChart, color];
        });

        this.store
            .select(selectActiveSources)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v.length && this.isInit && this.activeSourceId !== null)
            )
            .subscribe((sources) => {
                this.sources = sources;
                if (sources) {
                    const currentSource = sources?.find(
                        (v) => v.id === Number(this.activeSourceId)
                    );
                    if (currentSource) {
                        this.source = currentSource;
                        this.store.dispatch(
                            ImpactStatActions.setSource({ payload: currentSource })
                        );
                    } else {
                        setTimeout(
                            () =>
                                this.router.navigate([
                                    `/${MAIN_PAGES.impact}/${IMPACT_PAGES.sources}`,
                                ]),
                            500
                        );
                    }
                    this.isInit = false;
                    this._changeDetectorRef.markForCheck();
                }
            });
        this.store
            .select(selectControlPoints)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((points) => {
                this.controlPoints = points;
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();
        this.store.dispatch(ImpactStatActions.setSource({ payload: null }));
        this.store.dispatch(ImpactStatActions.setImpactStatistics({ payload: null }));
    }

    backToSources() {
        this.router.navigate([`${this.mainComponentUrl}${IMPACT_PAGES.sources}/`]);
    }

    toggleCalendar() {
        if (this.calendarIsOpened) {
            this.handleUpdateDays(this.numberOfFutureDays);
        }
    }

    updateDateRange = (begin: number, finish: number) => {
        this.calendarIsOpened = false;
        const start = moment(begin).startOf('day').toISOString();
        const end = moment(finish).endOf('day').toISOString();
        this.store.dispatch(ImpactStatActions.updateDateRangeSource({ payload: { start, end } }));
    };

    updateDays = (fn: (numberOfFutureDays?: number, minDate?: string) => void) =>
        (this.handleUpdateDays = fn);

    changeTabDuration($event) {
        this.currentTabDuration = $event;
    }
    changeTabConcentration($event) {
        this.currentTabConcentration = $event;
    }
    changeTabContribution($event) {
        this.currentTabContribution = $event;
    }

    getRegionChartWidth(hours: number) {
        return `${Math.round((hours / 24) * 100)}%`;
    }

    selectSourceMmt(list: CheckboxItem[]) {
        const selectedMmt = this.mmtList.find(
            (dl) => dl.id === list.find((s) => s.selected == true).id
        );
        this.selectedSourceMmt = selectedMmt;
        this.store.dispatch(
            ImpactStatActions.updateSourceMmt({ payload: selectedMmt?.id as string })
        );
    }

    private updateNumberAfterDot() {
        if (this.selectedSourceMmt?.id && this.measureScheme) {
            this.numberAfterDot = getDigitsAfterDot(
                this.measureScheme,
                this.selectedSourceMmt.value
            );
        } else {
            this.numberAfterDot = 1;
        }
    }
}
