import { NgModule } from '@angular/core';
import { EntityDataService } from '@ngrx/data';

import { StationDataService } from './services/station/station-data.service';
import { ControlPointDataService } from './services/control-point/control-point-data.service';
import { RunConfigDataService } from './services/run-config/run-config-data.service';
import { RunDataService } from './services/run/run-data.service';
import * as CONSTANTS from './constants';

@NgModule({
    providers: [StationDataService],
})
export class EntityStorePlumesModule {
    constructor(
        entityDataService: EntityDataService,
        stationDataService: StationDataService,
        controlPointDataService: ControlPointDataService,
        runConfigDataService: RunConfigDataService,
        runDataService: RunDataService
    ) {
        entityDataService.registerService(CONSTANTS.STATION_ENTITY_STORE_KEY, stationDataService);
        entityDataService.registerService(
            CONSTANTS.CONTROL_POINT_ENTITY_STORE_KEY,
            controlPointDataService
        );
        entityDataService.registerService(
            CONSTANTS.RUN_CONFIG_ENTITY_STORE_KEY,
            runConfigDataService
        );
        entityDataService.registerService(CONSTANTS.RUN_ENTITY_STORE_KEY, runDataService);
    }
}
