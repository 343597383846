import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ca-popup-container',
    templateUrl: 'popup-container.component.html',
    styleUrls: ['popup-container.component.less'],
})
export class PopupContainerComponent {
    @Input() title = '';
    @Output() closeAction = new EventEmitter<void>();

    backdropClick(e: Event) {
        if (e.currentTarget === e.target) {
            this.close();
        }
    }

    close() {
        this.closeAction.emit();
    }
}
