<div (mouseenter)="isHover=true" (mouseleave)="isHover=false">
    <ng-container *ngIf="withGradient else defaultTemp">
        <ng-container *ngIf="isHover else notHoverIconTemp">
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                <rect
                    x="20"
                    y="20"
                    width="20"
                    height="20"
                    rx="10"
                    transform="rotate(-180 20 20)"
                    fill="url(#paint0_linear_9546_68457)"
                />
                <path
                    d="M10 15C10.5523 15 11 14.5523 11 14V10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10 15Z"
                    fill="white"
                />
                <path
                    d="M10 7C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7Z"
                    fill="white"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_9546_68457"
                        x1="40"
                        y1="20"
                        x2="22.0624"
                        y2="41.7045"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#9369D7" />
                        <stop offset="0.368408" stop-color="#2E60D9" />
                        <stop offset="1" stop-color="#7839DB" />
                    </linearGradient>
                </defs>
            </svg>
        </ng-container>
        <ng-template #notHoverIconTemp>
            <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <rect
                x="20"
                y="20"
                width="20"
                height="20"
                rx="10"
                transform="rotate(-180 20 20)"
                fill="url(#paint0_linear_9546_68457)"
            />
            <path
                d="M10 15C10.5523 15 11 14.5523 11 14V10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10 15Z"
                fill="white"
            />
            <path
                d="M10 7C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_9546_68457"
                    x1="40"
                    y1="20"
                    x2="22.0624"
                    y2="41.7045"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#6795ED" />
                    <stop offset="0.368408" stop-color="#7387E6" />
                    <stop offset="1" stop-color="#9369D7" />
                </linearGradient>
            </defs>
        </svg>
        </ng-template>
    </ng-container>
    <ng-template #defaultTemp>
        <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="info-icon"
            [class.info-icon--active]="active"
        >
            <circle cx="10" cy="10" r="10" class="info-icon-bg" />
            <path
                d="M10 15.0001C10.5523 15.0001 11 14.5523 11 14.0001V10.0001C11 9.44778 10.5523 9.00006 10 9.00006C9.44772 9.00006 9 9.44778 9 10.0001V14.0001C9 14.5523 9.44772 15.0001 10 15.0001Z"
                fill="white"
            />
            <path
                d="M10 7.00006C10.5523 7.00006 11 6.55235 11 6.00006C11 5.44778 10.5523 5.00006 10 5.00006C9.44772 5.00006 9 5.44778 9 6.00006C9 6.55235 9.44772 7.00006 10 7.00006Z"
                fill="white"
            />
        </svg>
    </ng-template>
</div>
