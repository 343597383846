import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from '@libs/shared-store';
import { PAGES } from '../../../../navigation/navigation-config';
import { checkInviteLink } from '@cityair/modules/core/utils';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const isLoggedIn = this.authService.isLoggedIn();
        const isLoginPage = route.routeConfig.path === PAGES.login;

        if (isLoggedIn && isLoginPage) {
            return this.router.navigate([]);
        }

        if (!isLoggedIn && !isLoginPage) {
            return this.router.navigate([PAGES.login], { queryParams: checkInviteLink() });
        }

        return true;
    }
}
