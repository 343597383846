import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { BarChartComponent } from './bar-chart.component';
import { AnalyticChartTitleModule } from './../analytic-chart-title/analytic-chart-title.module';

@NgModule({
    imports: [CommonModule, BaseChartDirective, AnalyticChartTitleModule],
    declarations: [BarChartComponent],
    exports: [BarChartComponent],
})
export class BarChartModule {}
