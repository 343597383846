import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Observable } from 'rxjs';
import { VangaAuthService } from '@libs/shared-store';
import { environment } from 'environments/environment';
import { IReportKind } from '@libs/common';
import { map } from 'rxjs/operators';
import { isRU } from '@libs/common';

@Injectable({ providedIn: 'root' })
export class ReportKindDataService extends DefaultDataService<IReportKind> {
    private lang = isRU ? 'ru' : 'en';
    private urlPath = this.httpUrlGenerator.collectionResource('ReportKind', '');

    constructor(
        http: HttpClient,
        urlGenerator: HttpUrlGenerator,
        private vangaAuthService: VangaAuthService
    ) {
        super('ReportKind', http, urlGenerator, {
            root: environment.vanga_api_url + '/socrates/v1',
        });
    }

    getAll(): Observable<IReportKind[]> {
        return super.getAll();
    }

    getById(id: string | number): Observable<IReportKind> {
        const httpOptions = this.getHttpOptions();
        const url = this.urlPath + id + '/';
        return this.http
            .get(url, httpOptions)
            .pipe(map((response: any) => response as IReportKind));
    }

    getWithQuery(params: string | QueryParams): Observable<any> {
        const httpOptions = this.getHttpOptions(params);
        return this.http.get(this.urlPath, httpOptions);
    }

    private getHttpOptions(params?: string | QueryParams | HttpParams): any {
        const token = this.vangaAuthService.getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': this.lang,
        };

        if (params) {
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }
}
