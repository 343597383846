<div class="password-reset-form">
    <div class="login-title password-reset-form-title">
        {{ TEXTS.LOGIN_WINDOWS.forgotYourPass }}
    </div>

    <div
        class="login-description password-reset-form-description"
        [innerHTML]="TEXTS.LOGIN_WINDOWS.pleaseConfirm"
    ></div>

    <form class="login__fieldset" (ngSubmit)="onSubmit()" [formGroup]="resetForm" novalidate>
        <input type="submit" [style.display]="'none'" />

        <div class="login-input-group password-reset-form-input-group">
            <input
                class="login-input"
                type="email"
                autocomplete="email"
                formControlName="email"
                [class.login-input--danger]="hasErrors"
                [attr.placeholder]="TEXTS.LOGIN_WINDOWS.emailAddress"
            />

            <div class="login__info password-reset-form__info">
                <div class="login-errors password-reset-form__errors">
                    <ng-container *ngIf="hasErrors">
                        {{ (harvester.formError$ | async) || TEXTS.LOGIN_WINDOWS.wrongMail }}
                    </ng-container>
                </div>
            </div>
        </div>

        <button type="submit" class="login-submit-button" data-cy="submit">
            <ca-login-button [disabled]="resetForm.invalid" (clickAction)="onSubmit()">
                <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
                <ng-template #showContent>{{ TEXTS.LOGIN_WINDOWS.sendReset }}</ng-template>
            </ca-login-button>
        </button>

        <a
            class="login-nav-link password-reset-form__back-btn"
            [routerLink]="['/', loginPage.Login]"
        >
            {{ TEXTS.LOGIN_WINDOWS.backToSign }}
        </a>
    </form>
</div>
