import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GradientSliderComponent } from './gradient-slider.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
    imports: [CommonModule, PipesModule],
    exports: [GradientSliderComponent],
    declarations: [GradientSliderComponent],
})
export class GradientSliderModule {}
