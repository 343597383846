import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { VangaAuthService } from '@libs/shared-store';
import { isRU, Reports } from '@libs/common';
import { QueryParams } from '@ngrx/data';

const API_URL = environment.vanga_api_url;
const ENDPOINT = `${API_URL}/socrates/v1/reports`;

@Injectable({
    providedIn: 'root',
})
export class ReportApiService {
    private lang = isRU ? 'ru' : 'en';

    constructor(private http: HttpClient, private vangaAuthService: VangaAuthService) {}

    public downloadFile(report: Reports): any {
        const httpOptions = this.getHttpOptions();
        const url = `${ENDPOINT}/${report.id}/url/`;

        return this.http.get(url, httpOptions);
    }

    private getHttpOptions(params?: string | QueryParams | HttpParams): any {
        const token = this.vangaAuthService.getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': this.lang,
        };

        if (params) {
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }
}
