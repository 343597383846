<ng-template #popupOutlet>
    <ca-popup-container
        *ngIf="showConfirmationPopup"
        [title]="textsPopup.title"
        (closeAction)="onClosePopup()"
    >
        <confirm-unsaved-popup
            [text]="TEXTS.POPUP_THREE_QUESTIONS.body"
            [continueText]="TEXTS.COMMON.doNotSave"
            [cancelText]="TEXTS.POPUP_THREE_QUESTIONS.cancel"
            (continueAction)="onCancelAndNavigation()"
            (cancelAction)="onClosePopup()"
        ></confirm-unsaved-popup>
    </ca-popup-container>
</ng-template>

<div class="settings_block scroll">
    <form (ngSubmit)="onSubmit()" [formGroup]="settingsForm" novalidate>
        <div class="settings_input_forms scroll" (scroll)="tooltipsService.clear()">
            <div class="settings_header">
                <div class="settings_header-wrapper">
                    <header-page
                        [cbBack]="cancel"
                        [textObject]="{
                            btnBack: textsNotification.back,
                            titlePage:
                                workingSubscription.id === null
                                    ? textsNotification.newSubscription
                                    : textsNotification.editSubscription
                        }"
                    ></header-page>
                </div>
            </div>

            <div class="settings__section">
                <div class="settings__input-wrapper settings__input-wrapper&#45;&#45;text">
                    <ca-input
                        type="text"
                        controlName="name"
                        [form]="settingsForm"
                        [valueForm]="getValueForm('name')"
                        [label]="textsNotification.titleSubscription"
                        [textError]="getError('name')"
                    ></ca-input>
                </div>
            </div>

            <div class="settings_subtitle">
                <div class="settings_subtitle__name">
                    {{ textsNotification.parameterForNotif }}
                </div>
            </div>
            <div class="settings__section">
                <div class="settings__input-wrapper settings__input-wrapper&#45;&#45;text">
                    <input-dropdown
                        class="input-dropdown"
                        [inputText]="selectedCheckboxText(getTags().length)"
                        [label]="textsNotification.selectPosts"
                        [(showDropdown)]="isShowDropdownForStations"
                        (clickOutside)="isShowDropdownForStations = false"
                        [textError]="
                            getTags().length === 0 ? textsNotification.errMsgPost : ''
                        "
                    >
                        <cs-selectbox
                            [listItems]="listStations"
                            (listItemsChange)="changePosts($event)"
                            [searchPlaceholderText]="TEXTS.NOTIFICATIONS.searchMoPlaceholderText"
                            [selectAllText]="TEXTS.NOTIFICATIONS.selectAll"
                        ></cs-selectbox>
                    </input-dropdown>
                </div>
                <div class="settings__input-wrapper settings__input-wrapper&#45;&#45;text">
                    <tags-list
                        class="tags-list"
                        [tags]="getTags()"
                        [textNodesFns]="postsListLabels()"
                        [truncateTo]="3"
                        [editable]="true"
                        (removeTag)="removeFromList($event)"
                    ></tags-list>
                </div>
                <div class="settings__input-wrapper">
                    <div class="settings__input-label">
                        {{ textsNotification.controlledSubstances }}
                    </div>
                    <div class="list_substances">
                        <div
                            class="list_substances__item mmt"
                            [class.list_substances__item-active]="
                                workingSubscription.pdk_check_settings.is_for_any_value_type
                            "
                            (click)="selectAllValues()"
                        >
                            {{ textsNotification.all }}
                        </div>
                        <div
                            class="list_substances__item mmt"
                            [class.list_substances__item-active]="isValueItemSelected(value)"
                            *ngFor="let value of allSubstances"
                            [innerHTML]="TEXTS.NAMES[value] ?? value"
                            (click)="toggleValueItem(value)"
                        ></div>
                    </div>
                </div>

                <div class="settings__input-wrapper">
                    <div class="settings__input-label-wrapper">
                        <div class="settings__input-label">
                            {{ textsNotification.excessLevel }}
                        </div>
                        <i
                            class="settings_subtitle__icon settings__input-label__icon"
                            *ngIf="showPdkTooltip"
                            caTooltip
                            [caTooltipTemplate]="pdkTooltip"
                        ></i>
                        <ng-template #pdkTooltip>
                            <ca-detailed-tooltip
                                [title]="textsNotification.subscriptionsPdkTooltip.title"
                                [description]="
                                    textsNotification.subscriptionsPdkTooltip.description
                                "
                                [text]="textsNotification.subscriptionsPdkTooltip.text"
                            ></ca-detailed-tooltip>
                        </ng-template>
                    </div>
                    <div class="gradient_slider">
                        <label class="gradient_slider__value">
                            <input
                                type="number"
                                inputmode="decimal"
                                class="input_name"
                                formControlName="excessLevel"
                                [min]="minPDK"
                                [max]="maxPDK"
                                step="0.1"
                                [value]="workingSubscription.pdk_check_settings.pdk_mr_multiplier"
                            />
                            {{ textsNotification.excessLevelUnits }}
                        </label>
                        <ca-gradient-slider
                            [min]="minPDK"
                            [max]="maxPDK"
                            [step]="0.1"
                            [sliderModel]="workingSubscription.pdk_check_settings.pdk_mr_multiplier"
                            (sliderModelChange)="updateSliderValue($event)"
                        ></ca-gradient-slider>
                    </div>
                </div>
            </div>

            <div class="settings_subtitle">
                <div class="settings_subtitle__name">
                    {{ textsNotification.recipients }}
                </div>
            </div>
            <div class="settings__section">
                <div class="settings__input-wrapper settings__input-wrapper&#45;&#45;text">
                    <div class="settings__input-label settings__input-label&#45;&#45;text">
                        {{ textsNotification.emailListInputLabel }}
                    </div>
                    <input
                        class="input_name"
                        type="text"
                        minlength="1"
                        autocomplete="email"
                        formControlName="emailsList"
                        [class.alert-error]="emailsListProxy.invalid"
                    />
                    <div [formGroup]="emailsListProxy" style="display: none">
                        <input
                            type="email"
                            email
                            *ngFor="let item of emailsListData"
                            [formControlName]="item.name"
                            [value]="item.value"
                        />
                    </div>
                    <div class="alert_error" [class.alert_error-active]="emailsListProxy.invalid">
                        <div *ngIf="emailsListProxy.invalid">
                            {{ textsNotification.errMsgIncorrectEmails }}
                        </div>
                    </div>
                </div>

                <div class="settings__input-wrapper">
                    <div class="settings_radio">
                        <label class="settings_radio__str">
                            <div>
                                <input
                                    class="settings_radio__btn settings_radio__btn-input"
                                    type="radio"
                                    formControlName="isPeriodic"
                                    [value]="true"
                                />
                                <div class="settings_radio__btn settings_radio__btn-img"></div>
                            </div>
                            <div class="settings_radio__text">
                                {{ textsNotification.notifyPeriodically }}
                            </div>
                        </label>
                        <label class="settings_radio__str">
                            <div>
                                <input
                                    class="settings_radio__btn settings_radio__btn-input"
                                    type="radio"
                                    formControlName="isPeriodic"
                                    [value]="false"
                                />
                                <div class="settings_radio__btn settings_radio__btn-img"></div>
                            </div>
                            <div class="settings_radio__text">
                                {{ textsNotification.notifyEvent }}
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <div class="settings_subtitle">
                <div class="settings_subtitle__name">
                    {{ textsNotification.dailyReport }}
                </div>
                <div
                    class="settings_subtitle__icon"
                    caTooltip
                    [caTooltipTemplate]="emailTooltip"
                ></div>
                <ng-template #emailTooltip>
                    <ca-info-tooltip
                        [text]="textsNotification.tooltipSettingsHintDailyReport"
                    ></ca-info-tooltip>
                </ng-template>
            </div>
            <div class="settings__section">
                <div class="settings__input-wrapper settings__input-wrapper&#45;&#45;text">
                    <div class="settings__input-label settings__input-label&#45;&#45;text">
                        {{ textsNotification.emailListInputLabel }}
                    </div>
                    <input
                        class="input_name"
                        type="text"
                        minlength="1"
                        autocomplete="email"
                        formControlName="emailsListReports"
                        [class.alert-error]="emailsListReportsProxy.invalid"
                    />
                    <div [formGroup]="emailsListReportsProxy" style="display: none">
                        <input
                            type="email"
                            email
                            *ngFor="let item of emailsListReportsData"
                            [formControlName]="item.name"
                            [value]="item.value"
                        />
                    </div>
                    <div
                        class="alert_error"
                        [class.alert_error-active]="emailsListReportsProxy.invalid"
                    >
                        <div *ngIf="emailsListReportsProxy.invalid">
                            {{ textsNotification.errMsgIncorrectEmails }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="settings_footer">
            <div class="action_button__wrapper">
                <ca-button type="cancel" (clickAction)="cancel()">
                    {{ textsNotification.cancel }}
                </ca-button>
                <ca-button
                    type="primary"
                    (clickAction)="save()"
                    [disabled]="
                        settingsForm.disabled
                        ||
                        settingsForm.invalid
                        ||
                        !formDataHasChanged
                        ||
                        (getMoIds()?.length === 0)
                    "
                >
                    <ca-spinner *ngIf="isLoadingForm; else showContent"></ca-spinner>
                    <ng-template #showContent>
                        {{ textsNotification.save }}
                    </ng-template>
                </ca-button>
            </div>
        </div>
    </form>
    <div class="error-response-wrapper" *ngIf="errorResponse">
        <div class="title-status">{{ textsNotification.error }}</div>
        {{ errorResponse }}
    </div>
</div>
