import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValueCircleComponent } from './value-circle.component';
import { PipesModule } from '@libs/shared-ui';

@NgModule({
    exports: [ValueCircleComponent],
    declarations: [ValueCircleComponent],
    imports: [CommonModule, PipesModule],
})
export class ValueCircleModule {}
