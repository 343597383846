import { Component, Input } from '@angular/core';

type ShiftDirection = 'none' | 'north' | 'south';

@Component({
    selector: 'boundary-marker',
    templateUrl: 'boundary-marker.component.html',
    styleUrls: ['boundary-marker.component.less'],
})
export class BoundaryMarkerComponent {
    @Input() shift: ShiftDirection = 'none';
}
