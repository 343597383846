import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'range-slider-option',
    templateUrl: './range-slider-option.component.html',
    styleUrls: ['./range-slider-option.component.less'],
})
export class RangeSliderOptionComponent {
    @Input() handleMinPosition: number;
    @Input() handleMaxPosition: number;
    @Input() currentMinValue: number;
    @Input() currentMaxValue: number;
    @Input() minValue: number;
    @Input() maxValue: number;
    @Input() measure: string;
    @Input() colors: string[];

    getGradient() {
        const length = this.colors.length;
        const percents = 100 / length;
        const bloorPercent = percents / 3;

        const colorsGradient = this.colors.map((color, index) => {
            const start = percents * index + bloorPercent;
            const end = percents * (index + 1);
            return `${color} ${start}%, ${color} ${end}%,`;
        });

        return `linear-gradient(90deg, ${colorsGradient.join(' ').slice(0, -1)})`;
    }
}
