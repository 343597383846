import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { getDigitsAfterDot } from '@libs/common';
import { AqiType, MapControlPins, MapPins, RegionPins, SourcePins } from '@libs/common';
import { IntervalEnum } from '@libs/common';
import { MeasureScheme } from '@libs/common';
import { isRU, TEXTS } from '@libs/common';
import {
    selectIsCityMode,
    selectPdkForChart,
    selectSourcesAsFeatures,
} from '@cityair/modules/core/store/selectors';
import { loadCity, removeFromComparison } from '@cityair/modules/core/store/actions';
import { ControlPoint } from '@cityair/modules/plumes/services/control-point/models';
import { GroupFeaturesService } from '@libs/shared-store';
import { getColorFromZone } from '@cityair/utils/utils';
import { GroupChartConfig } from '@libs/common';
import {
    ImpactActions,
    selectShowWindOnMap,
    selectActiveHeight,
    selectActiveMmt,
    selectImpactHeights,
    selectImpactSchemaZones,
    isWindLayerAvailable,
    selectDisableWindButton,
    selectDates,
    selectChartData,
    selectImpactTime,
    getControlPointImpactValue,
    selectActivePin,
    isDraggableControlPoint,
    selectActiveRunSources,
    selectImpactStationsForMap,
    getStationImpactValue,
    selectImpactSourcesForMap,
    selectImpactPointsForMap,
    selectActiveEventId,
    selectIsCollapsedPost,
    selectIsCollapsedSources,
    selectImpactRegionsPinForMap,
    selectActiveStation,
} from '@cityair/modules/impact/store/impact.feature';
import { NEW_CONTROL_POINT_OBJ_TYPE } from '@cityair/modules/plumes/constants';
import { Station } from '@cityair/modules/plumes/services/station/models';
import { NUMBER_ROUND_COORDINATES } from '@cityair/modules/impact/consts';
import {
    MapAdapterService,
    selectCurrentMapType,
    selectCurrentMeasureScheme,
    selectMapStyleLoading,
    selectMapStyleTypes,
    SharedCoreActions,
    selectSidebarIsOpen,
    selectUserTimezoneLabel,
} from '@libs/shared-store';

@Component({
    selector: 'impact-map',
    templateUrl: './impact-map.component.html',
    styleUrls: ['./impact-map.component.less'],
})
export class ImpactMapComponent implements OnInit, OnDestroy {
    labelMode = false;
    labelModeGroup = false;

    isRU = isRU;
    AqiType = AqiType;

    MeasureScheme = MeasureScheme;
    IntervalEnum = IntervalEnum;
    isLoading;

    selectSidebarIsOpen = selectSidebarIsOpen;
    selectSourcesAsFeatures = selectSourcesAsFeatures;
    selectPdkForChart = selectPdkForChart;
    removeFromComparison = removeFromComparison;
    selectMeasureScheme = selectCurrentMeasureScheme;
    selectIsCityMode = selectIsCityMode;
    setMapStyleType = SharedCoreActions.setMapStyleType;
    selectCurrentMapStyleType = selectCurrentMapType;
    selectMapStyleTypes = selectMapStyleTypes;
    selectMapStyleLoading = selectMapStyleLoading;
    selectUserTimezone = selectUserTimezoneLabel;

    selectShowWindOnMap = selectShowWindOnMap;
    isWindLayerAvailable = isWindLayerAvailable;
    selectDisableWindButton = selectDisableWindButton;
    selectImpactHeights = selectImpactHeights;
    selectActiveHeight = selectActiveHeight;
    selectActiveMmt = selectActiveMmt;
    selectImpactSchemaZones = selectImpactSchemaZones;
    selectDates = selectDates;
    selectChartData = selectChartData;
    selectImpactTime = selectImpactTime;
    selectActiveRunSources = selectActiveRunSources;

    chartSingleSelect: boolean;
    chartMinMax: GroupChartConfig;

    public textScheme = TEXTS.MEASURES_SCHEME;
    public textNames = TEXTS.NAMES;
    public ngDestroyed$ = new Subject<void>();
    public getDigitsAfterDot = getDigitsAfterDot;
    public showWindOnMap: boolean;
    public textWindLayer = TEXTS.PLUMES.windLayerButtonText;
    public windTooltipTexts = TEXTS.PLUMES.tooltipWIndButton;
    public isPlayerTimeline = false;

    constructor(
        readonly store: Store,
        private groupFeaturesService: GroupFeaturesService,
        private mapAdapterService: MapAdapterService
    ) {
        store
            .select(selectShowWindOnMap)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.showWindOnMap = data;
            });

        store
            .select(isWindLayerAvailable)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isOn) => {
                if (!isOn) {
                    this.store.dispatch(ImpactActions.toggleWindLayer({ payload: false }));
                }
            });
    }

    ngOnInit() {
        const controlPointPins: MapControlPins = {
            getPins: this.store.select(selectImpactPointsForMap),
            selectDigitsAfterDot: this.store
                .select(selectImpactSchemaZones)
                .pipe(map((data) => getDigitsAfterDot(data?.scheme, data?.mmt))),
            getSelectedPinIds: this.store
                .select(selectActivePin)
                .pipe(map((data) => (data ? [data] : []))),
            getValue: (pin) =>
                this.store
                    .select(getControlPointImpactValue(pin.id))
                    .pipe(map((val) => val?.value)),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectImpactSchemaZones),
                    this.store.select(getControlPointImpactValue(pin.id)),
                ]).pipe(map(([zone, val]) => getColorFromZone(zone?.zone, val.value))),
            clickCb: (pin) => {
                const point = pin as ControlPoint;
                if (point?.obj === NEW_CONTROL_POINT_OBJ_TYPE) {
                    return;
                }
                this.store.dispatch(ImpactActions.setActivePin({ payload: pin.id }));
            },
            isDraggable: (pin) => this.store.select(isDraggableControlPoint(pin)),
            dragEnd: ($event) => {
                const lat = Array.isArray($event) ? $event[1] : $event?._lngLat.lat;
                const lng = Array.isArray($event) ? $event[0] : $event?._lngLat.lng;
                this.store.dispatch(
                    ImpactActions.setCoordinates({
                        payload: {
                            lat: parseFloat(lat.toFixed(NUMBER_ROUND_COORDINATES)),
                            lon: parseFloat(lng.toFixed(NUMBER_ROUND_COORDINATES)),
                        },
                    })
                );
            },
            isCollapsed: this.store.select(selectActiveEventId).pipe(
                map((data) => {
                    return data !== null;
                })
            ),
        };

        const postPins: MapPins = {
            getPins: this.store.select(selectImpactStationsForMap),
            selectDigitsAfterDot: this.store
                .select(selectImpactSchemaZones)
                .pipe(map((data) => getDigitsAfterDot(data?.scheme, data?.mmt))),
            getSelectedPinIds: this.store
                .select(selectActiveStation)
                .pipe(map((data) => (data?.id ? [data?.id] : []))),
            getValue: (pin) => this.store.select(getStationImpactValue(pin.id)),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectImpactSchemaZones),
                    this.store.select(getStationImpactValue(pin.id)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone?.zone, value))),

            clickCb: (pin) => {
                this.store.dispatch(ImpactActions.setActiveStation({ payload: pin as Station }));
            },
            isCollapsed: (id) => this.store.select(selectIsCollapsedPost(id)),
        };

        const sourcePins: SourcePins = {
            getPins: this.store.select(selectImpactSourcesForMap),
            isCollapsed: (id) => this.store.select(selectIsCollapsedSources(id)),
        };

        const regionPins: RegionPins = {
            getPins: this.store.select(selectImpactRegionsPinForMap),
        };

        this.mapAdapterService.set({
            controlPointPins,
            postPins,
            cityPins: null,
            sourcePins,
            regionPins,
        });
    }

    ngOnDestroy() {
        this.mapAdapterService.set({
            postPins: null,
            controlPointPins: null,
            sourcePins: null,
            regionPins: null,
        });
        this.store.dispatch(ImpactActions.toggleWindLayer({ payload: false }));
        this.ngDestroyed$.next();
    }

    getDigits = (measureScheme: MeasureScheme) => (mmt) => getDigitsAfterDot(measureScheme, mmt);

    public goToCity(cityId: string) {
        this.store.dispatch(loadCity({ cityId, centringMap: true }));
    }

    toggleWindLayer() {
        this.store.dispatch(ImpactActions.toggleWindLayer({ payload: null }));
    }

    public changeHeight(height: number) {
        this.store.dispatch(ImpactActions.setActiveHeight({ payload: height }));
    }

    public changeTimeIndex($event) {
        this.store.dispatch(ImpactActions.setTimeIndex({ payload: $event }));
    }

    public getPlayingState(isPlaying) {
        this.isPlayerTimeline = isPlaying;
        this.store.dispatch(ImpactActions.disableWindButton({ payload: isPlaying }));
        if (this.showWindOnMap && isPlaying) {
            this.store.dispatch(ImpactActions.toggleWindLayer({ payload: false }));
        }
    }
}
