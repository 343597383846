import { COMPARE_LIMIT } from '@cityair/libs/shared/utils/other-utils';
import { createSelector } from '@ngrx/store';
import { ICoreState } from '../reducers';
import { AppState } from '../selectors';

export const coreSelector = (state: AppState) => state.core;

export const selectComparedItems = createSelector(
    coreSelector,
    (state: ICoreState) => state?.comparedItems
);

export const comparedItemsIds = createSelector(selectComparedItems, (comparedItems) =>
    comparedItems.map((el) => el.id)
);

export const getComparedListObjects = createSelector(
    selectComparedItems,
    (comparedItems) =>
        !comparedItems.map((c) => ({
            id: c.id,
            type: c.obj,
        }))
);

export const isComparedListEmpty = createSelector(
    selectComparedItems,
    (comparedItems) => !comparedItems.length
);

export const isComparedListNotEmpty = createSelector(
    selectComparedItems,
    (comparedItems) => !!comparedItems.length
);

export const isComparedListLimited = createSelector(
    selectComparedItems,
    (comparedItems) => comparedItems.length >= COMPARE_LIMIT
);

export const getComparedListObject = (id: string) =>
    createSelector(selectComparedItems, (comparedItems) => comparedItems.find((s) => s.id === id));

export const findIndexInComparedList = (id: string) =>
    createSelector(selectComparedItems, (comparedItems) =>
        comparedItems.findIndex((s) => s.id === id)
    );
