<div class="layer-button" [ngClass]="{'big-size': size === 'big'}">
    <div
        class="layer-button-icon layer-button-icon--{{ type }}"
        [class.layer-button-icon--active]="active"
        (click)="clickOnIcon.emit()"
    ></div>
    <div class="layer-button-selector" *ngIf="active">
        <ng-content select="ca-layer-button-item"></ng-content>
    </div>
</div>
