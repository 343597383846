import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { MeasureScheme, WindowGlobalVars } from '@libs/common';
import { environment } from 'environments/environment';

declare const window: WindowGlobalVars;

const API_URL = environment.api_url + '/harvester/v2/posts';

const API_URL_STAT = environment.harvester_v1_api_url + '/IndoorStatApi/GetIndoorStatInfo';

const API_URL_POST = environment.harvester_v1_api_url + '/IndoorStatApi/GetIndoorStatInfoSingle';

export interface PostsMeasurementsProps {
    timeBegin: number;
    timeEnd: number;
    groupId: string;
    measureScheme: MeasureScheme;
    ids?: number[];
}

export interface Units {
    temperature: string;
    pressure: string;
    humidity: string;
    tsp: string;
    pm2: string;
    pm10: string;
    co: string;
    co2: string;
    no: string;
    no2: string;
    so2: string;
    o3: string;
    h2s: string;
    ch2o: string;
    pws_wda: string;
    pws_wva: string;
    pws_prc: string;
}

export interface Meta {
    Units: Units;
}

export interface CityairAqi {
    value: number;
}

export interface AqiObject {
    dominant: string;
    valueScaled10: number;
    value: number;
    value10?: number;
}

export interface Aqi {
    cityairAqi: CityairAqi;
    epaAqi: AqiObject;
    indoorAqi: AqiObject;
    instantAqi?: AqiObject;
}

export interface Measurement {
    postId: number;
    date: string;
    version: number;
    temperature: number;
    pressure: number;
    humidity: number;
    pm2: number;
    pm25: number;
    pm10: number;
    co2: number;
    aqi: Aqi;
}

export interface Geo {
    latitude: number;
    longitude: number;
    gmtOffsetSeconds: number;
    timeZoneIana: string;
}

export interface Post {
    id: number;
    name: string;
    ownerId: string;
    description: string;
    isOnline: boolean;
    geo: Geo;
}

export interface PostsMeasurementsResponse {
    meta?: Meta;
    data: Measurement[];
    posts: Post[];
}

// ------------------ Stat Info -----------------------------------------

export interface ComfortSettings {
    WorkHoursBegin: number;
    WorkHoursEnd: number;
    WorkDays: boolean[];
    TemperatureMin: number;
    TemperatureMax: number;
    HumidityMin: number;
    HumidityMax: number;
    Co2Max: number;
}

export interface PercentInDay {
    Day: Date;
    ConditionOkCount: number;
    AllIncludeCount: number;
    Percent?: number;
    IndoorAqi?: number;
}

export interface MoIdValue {
    MoId: number;
    Value: number;
}

export interface Rooms {
    PercentBest: MoIdValue;
    PercentWorse: MoIdValue;
    IaqBest: MoIdValue;
    IaqWorse: MoIdValue;
}

export interface RoomRate {
    LastDay: Rooms;
    LastWeek: Rooms;
    LastMonth: Rooms;
}

export interface StatInfo {
    MoIds: number[];
    ComfortSettings: ComfortSettings;
    PercentInDays: PercentInDay[];
    RoomRate: RoomRate;
}

export interface PostRate {
    WorkHour: number;
    IndoorAqi: number;
}

export interface PostInfo {
    MoIds: number;
    PercentInDays: PercentInDay[];
    MonthStat: PostRate[];
    WeekStat: PostRate[];
}

export interface PostInfoResponse {
    Result: PostInfo;
    IsError: boolean;
    ErrorNumber: number;
    ErrorMessage?: any;
    ErrorMessageDetails?: any;
}

// -------------------------------------------
type IntervalV2Type = '5m' | '20m' | '1h' | '1d' | 'source';
function getApiV2Interval(timeBegin: number, timeEnd: number): IntervalV2Type {
    const days = Math.abs(moment(timeBegin).diff(moment(timeEnd), 'days'));

    if (days <= 3) return '5m';

    if (days <= 15) return '20m';

    if (days <= 45) return '1h';

    return '1d';
}
const transformProps = (props: PostsMeasurementsProps) => ({
    ids: '',
    interval: getApiV2Interval(props.timeBegin, props.timeEnd),
    date__gt: moment(props.timeBegin).toISOString(),
    date__lt: moment(props.timeEnd).toISOString(),
    with_posts: 'true',
    device_source_type: 'CityairIndoor',
    measure_value_types: 'pm2,pm10,temperature,humidity,co2',
    fill_holes: 'true',
    with_iaq: 'true',
    limit: '1000000',
    group_id: props.groupId.toString(),
    measure_scheme: props.measureScheme,
});
const transformPropsOutdoor = (props: PostsMeasurementsProps) => ({
    ids: props.ids.join(','),
    interval: getApiV2Interval(props.timeBegin, props.timeEnd),
    date__gt: moment(props.timeBegin).toISOString(),
    date__lt: moment(props.timeEnd).toISOString(),
    with_posts: 'true',
    measure_value_types: 'pm2,pm10,temperature,humidity,co2',
    fill_holes: 'true',
    with_iaq: 'true',
    with_aqi: 'true',
    limit: '1000000',
    group_id: props.groupId.toString(),
    measure_scheme: props.measureScheme,
});
class Options {
    headers: HttpHeaders;
    params?:
        | HttpParams
        | {
              [param: string]: string | string[];
          };

    constructor(params?: { [param: string]: string | string[] }) {
        this.params = params;
    }
}
@Injectable({
    providedIn: 'root',
})
export class IndoorApi {
    constructor(private http: HttpClient) {}

    private get(url: string, params?: any): any {
        return this.http.get(url, new Options(params));
    }

    getPostsMeasurements(
        timeData: { timeBegin: number; timeEnd: number },
        props: { groupId: string; measureScheme: MeasureScheme }
    ): Observable<PostsMeasurementsResponse> {
        const initParams: PostsMeasurementsProps = Object.assign({}, timeData, props);
        const params = transformProps(initParams);

        return this.get(`${API_URL}/measurements`, params);
    }

    getStatInfo(groupId: string): Observable<any> {
        const params = { GroupId: groupId, Token: window.JS_CP_TOKEN };

        return this.http.post(API_URL_STAT, params);
    }

    getPostInfo(GroupId: number, MoId: number): Observable<any> {
        const params = { GroupId: GroupId, MoId: MoId, Token: window.JS_CP_TOKEN };

        return this.http.post(API_URL_POST, params);
    }

    getPostsMeasurementsByIds(
        timeData: { timeBegin: number; timeEnd: number },
        props: { groupId: string; measureScheme: MeasureScheme },
        ids: { ids: number[] }
    ): Observable<PostsMeasurementsResponse> {
        const initParams: PostsMeasurementsProps = Object.assign({}, timeData, props, ids);
        const params = transformPropsOutdoor(initParams);

        return this.get(`${API_URL}/measurements`, params);
    }
}
