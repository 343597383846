<div
    class="login_page_m login-page--{{ clientConfig.name || 'unknown' }}"
    [style.backgroundImage]="assets.background && 'url(' + assets.background + ')'"
>
    <ca-language-selector-m
        class="login_page_m__language"
        (selectLang)="setLang($event)"
    ></ca-language-selector-m>
    <div class="login_page_m__logo">City Air</div>

    <div class="login_page_m__body">
        <router-outlet></router-outlet>
    </div>

    <div class="login_page_m__footer">
        <div class="login_page_m__text_support">{{ texts.support }}:</div>
        <div class="login_page_m__text_info">
            <a href="tel:{{ texts.phone[0] }}" [innerHTML]="texts.phone[1]"></a>
            <a style="padding-left: 20px" href="mailto:{{ texts.email }}">{{
                texts.email
            }}</a>
        </div>
        <div class="login_page_m__text_policy"><a href="{{texts.privicyPolicy.url}}" target="_blank">{{ texts.privicyPolicy.text }}</a></div>
        <cityair-login-fond class="login_page_m__fond" [isMobile]="true"></cityair-login-fond>
        <div class="login_page_m__text_c">{{ textC + texts.copyright }}</div>
    </div>

    <ng-container [ngSwitch]="navigation.activePopup">
        <ca-password-updated-popup
            *ngSwitchCase="loginPopup.PasswordUpdated"
        ></ca-password-updated-popup>
        <ca-link-expired-popup *ngSwitchCase="loginPopup.InviteLinkExpired"></ca-link-expired-popup>
    </ng-container>
</div>
