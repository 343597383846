import type {
    DomainConfigType,
    DataType,
    TileType,
} from '@cityair/modules/plumes/services/plumes-tiles-player/domain-config.type';

export class DomainConfig {
    dateFormat = 'YYYYMMDD_HHmmss';

    timeStep = 3600 * 1000; // 1 hour

    opacity = 0.5;

    url?: string;

    minzoom?: number;

    maxzoom?: number;

    domain: DomainConfigType;

    constructor(options: Partial<DomainConfig> & { domain: DomainConfigType }) {
        Object.assign(this, options);
    }

    getImagePath(dataType: DataType, tileType: TileType) {
        return [this.url, this.domain.slug, dataType, tileType].join('/');
    }
}
