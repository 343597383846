import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputForCoordinateComponent } from './input-for-coordinate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    exports: [InputForCoordinateComponent],
    declarations: [InputForCoordinateComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class InputForCoordinateModule {}
