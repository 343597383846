<div class="gradient-slider__wrapper">
    <div class="gradient-slider__bg" [ngClass]="{'default-bg': displayLabel}"></div>
    <div #slider class="gradient-slider">
        <div
            class="gradient-slider__container"
            [class.gradient-slider__container--drag]="drag"
            (click)="jumpToValue($event)"
        >
            <div class="gradient-slider__bar" [ngClass]="{'default-bg': displayLabel}"></div>
            <div #stub class="gradient-slider__stub"></div>
            <div #init class="gradient-slider__init-label" [ngClass]="{'hidden': initValue === sliderModel }"></div>
            <div
                #handle
                class="gradient-slider__handle"
                [class.gradient-slider__handle_border-black] = "showBorderForHandle"
                (mousedown)="startHandle($event)"
                (touchstart)="startTouchHandle($event)"
                (click)="$event.stopPropagation()"
            >
                <div *ngIf="showValue" class="gradient-slider__handle_value">{{ sliderModel }}</div>
            </div>
        </div>
        <ng-container *ngIf="displayLabel">
            <div class="labels">
                <div class="value">
                    {{min | valueFormatNumber : 2}} <span *ngIf="unit">{{unit}}</span>
                </div>
                <div class="value">
                    {{max| valueFormatNumber : 2}} <span  *ngIf="unit">{{unit}}</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>
