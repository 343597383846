import { Component, EventEmitter, Input, Output } from '@angular/core';

type Tag = string | number;
type TagMapper = (tag: Tag) => Tag;

@Component({
    selector: 'tags-list',
    templateUrl: 'tags-list.component.html',
    styleUrls: ['tags-list.component.less'],
})
export class TagsListComponent {
    @Input() tags: Tag[];

    @Input() tagsMapper: TagMapper = (tag) => tag;

    @Input() textNodesFns?: {
        all: () => string;
        expand: (count: number) => string;
        collapse: () => string;
    };

    @Input() truncateTo?: number = 4;

    @Input() editable?: boolean;

    @Output() removeTag = new EventEmitter<number>();

    isExpanded = false;

    remove(index: number) {
        this.removeTag.emit(index);
    }
}
