import {
    createFeatureSelector,
    createSelector,
    createSelectorFactory,
    MemoizedProjection,
    Store,
} from '@ngrx/store';
import { AnyFn } from '@ngrx/store/src/selector';

import { Feature } from '@libs/common';
import { Measurement, Post, PostsMeasurementsProps } from '../services/api';
import { IIndoorState, IndoorState, measurementAdapter } from './reducers';
import { indoorZones, MMT_INDOOR } from '@cityair/modules/indoor/config';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { DAY_MS, HOUR_MS, MeasureScheme } from '@libs/common';
import { AqiType } from '@libs/common';
import { isRU } from '@libs/common';
import { AVAILABLE_INTERVALS } from '@libs/common';
import { selectGroupId, selectCurrentMeasureScheme } from '@libs/shared-store';

function transformToFeature({
    post,
    measurements,
}: {
    post: Post;
    measurements: Measurement[];
}): Feature[] {
    return [
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [post.geo.longitude, post.geo.latitude],
            },
            properties: {
                uuid: post.id.toString(),
                name: post.name,
                name_ru: post.name,
                timeseries: {
                    date: measurements.map((d) => d.date),
                    [AqiType.indoor]: measurements.map(
                        (d) => d.aqi.indoorAqi?.valueScaled10 || null
                    ),
                    TEMPERATURE: measurements.map((d) => d.temperature),
                    HUMIDITY: measurements.map((d) => d.humidity),
                    PM25: measurements.map((d) => d.pm2),
                    PM10: measurements.map((d) => d.pm10),
                    CO2: measurements.map((d) => d.co2),
                },
                obj: 'source',
                has_any_timeseries: measurements.length > 0,
            },
        },
    ];
}

function transformToFeatureOutdoor({
    post,
    measurements,
}: {
    post: Post;
    measurements: Measurement[];
}): Feature[] {
    return [
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [post.geo.longitude, post.geo.latitude],
            },
            properties: {
                uuid: post.id.toString(),
                name: post.name,
                name_ru: post.name,
                timeseries: {
                    date: measurements.map((d) => d.date),
                    AQI: measurements.map((d) => d.aqi.instantAqi?.value10 || null),
                    TEMPERATURE: measurements.map((d) => d.temperature),
                    HUMIDITY: measurements.map((d) => d.humidity),
                    PM25: measurements.map((d) => d.pm2),
                    PM10: measurements.map((d) => d.pm10),
                    CO2: measurements.map((d) => d.co2),
                },
                obj: 'source',
                has_any_timeseries: measurements.length > 0,
            },
        },
    ];
}

export const getIndoorState = createFeatureSelector<IIndoorState>('indoor');

export const selectCore = createSelector(
    getIndoorState,
    (state: IIndoorState): IndoorState => state.core
);

const _activePost = createSelector(selectCore, (state: IndoorState) =>
    state.posts?.find((p) => p.id === state.activePointId)
);

function isEqualLists(a: any[], b: any[]) {
    return a.length === b.length && a.every((item) => b.includes(item));
}

function customListMemoize(t: AnyFn): MemoizedProjection {
    let lastResult = null;
    let overrideResult: any;

    function setResult(result: any) {
        overrideResult = result;
    }

    function clearResult() {
        overrideResult = undefined;
    }

    function memoized() {
        if (overrideResult !== undefined) {
            return overrideResult;
        }

        const result = t.apply(null, arguments);

        if (lastResult === null || !isEqualLists(result, lastResult)) {
            lastResult = result;
            return result;
        }

        return lastResult;
    }

    function reset() {
        lastResult = null;
    }

    return { memoized, reset, setResult, clearResult };
}

const _activeMeasurements = createSelectorFactory(customListMemoize)(
    selectCore,
    (state: IndoorState) =>
        measurementAdapter
            .getSelectors()
            .selectAll(state.measurements)
            .filter((m) => {
                const d = new Date(m.date).getTime();

                const isInRange =
                    d >= new Date(state.timeBegin).getTime() &&
                    d <= new Date(state.timeEnd).getTime();

                return m.postId === state.activePointId && isInRange;
            })
);
const _activePostOutdoorData = createSelector(selectCore, (state: IndoorState) => {
    if (!state.activeOutdoorId) {
        return null;
    }
    const post = state.outdoorPosts?.find((p) => p.id === state.activeOutdoorId);
    if (!post) {
        return null;
    }
    const measurements = state.outdoorData.filter((item) => item.postId === post.id);
    return transformToFeatureOutdoor({ post, measurements });
});

// TODO need indoor api with mpc
export const selectIndoorMeasureScheme = createSelector(selectCurrentMeasureScheme, (mmtScheme) => {
    if (mmtScheme === MeasureScheme.mpc) {
        return isRU ? MeasureScheme.default : MeasureScheme.usa_default;
    }
    return mmtScheme;
});

export const indoorSelectors = {
    time: createSelector(selectCore, (state: IndoorState) => state.currentTime),
    timeMs: createSelector(selectCore, (state: IndoorState) =>
        new Date(state.currentTime).getTime()
    ),
    activePointId: createSelector(selectCore, (state: IndoorState) => state.activePointId),
    activeOutdoorId: createSelector(selectCore, (state: IndoorState) => state.activeOutdoorId),
    listPoints: createSelector(selectCore, (state: IndoorState) => state.posts),
    currentMmt: createSelector(selectCore, (state: IndoorState) => state.currentMeasure),
    currentMmtAndScheme: createSelector(
        selectCore,
        selectIndoorMeasureScheme,
        (state: IndoorState, scheme) => ({
            mmt: state.currentMeasure,
            scheme,
        })
    ),
    getZone: createSelector(
        selectCore,
        selectIndoorMeasureScheme,
        (state: IndoorState, scheme) => indoorZones[scheme][state.currentMeasure]
    ),
    getMeasurement: (postId: number) =>
        createSelector(
            selectCore,
            (state: IndoorState) =>
                measurementAdapter.getSelectors().selectEntities(state.measurements)[
                    `${postId}_${state.currentTime}`
                ]
        ),
    getMeasurementValue: (postId: number) =>
        createSelector(selectCore, indoorSelectors.getMeasurement(postId), (state, data) => {
            const name = state.currentMeasure;

            if (name === MMT_INDOOR.indoorAqi) return data?.aqi?.indoorAqi?.valueScaled10;

            return data?.[name];
        }),
    activePost: _activePost,

    getActivePostWithMeasurements: createSelector(
        _activePost,
        _activeMeasurements,
        (post, measurements) => ({ post, measurements })
    ),
    getActivePostWithMeasurementsAsFeatures: createSelector(
        _activePost,
        _activeMeasurements,
        _activePostOutdoorData,
        (post, measurements, outdoorPostData) => {
            if (post) {
                return measurements?.length ? transformToFeature({ post, measurements }) : [];
            } else if (_activePostOutdoorData) {
                return outdoorPostData;
            }
        }
    ),

    selectPost: (id) =>
        createSelector(selectCore, (state: IndoorState) => state.posts?.find((m) => m.id === id)),

    getPost: async (store: Store<IndoorState>, markerId: number) =>
        lastValueFrom(store.select(indoorSelectors.selectPost(markerId)).pipe(take(1))),

    postInfo: createSelector(selectCore, (state: IndoorState) => state.postInfo),
    isPointsLoading: createSelector(selectCore, (state: IndoorState) => state.isPointsLoading),
    dateBegin: createSelector(selectCore, (state: IndoorState) => new Date(state.timeBegin)),
    dateEnd: createSelector(selectCore, (state: IndoorState) => new Date(state.timeEnd)),
    timeSequences: createSelector(selectCore, (state: IndoorState) => state.timeSequences),
    isAllowUpdate: createSelector(selectCore, (state: IndoorState) => {
        const end = new Date(state.timeEnd).getTime();
        const begin = new Date(state.timeBegin).getTime();

        const isToday = new Date().getTime() - end <= HOUR_MS;

        const isNotMaxTime = end - begin <= AVAILABLE_INTERVALS[0].days * DAY_MS;

        return isToday && isNotMaxTime;
    }),
    dateRange: createSelector(selectCore, (state: IndoorState) => ({
        dateBegin: new Date(state.timeBegin),
        dateEnd: new Date(state.timeEnd),
    })),
    selectMeasurement: createSelector(selectCore, (state: IndoorState) => state.selectMeasurement),
    statInfo: createSelector(selectCore, (state: IndoorState) => state.statInfoCollection),
    analyticType: createSelector(selectCore, (state: IndoorState) => state.analyticType),
    posts: createSelector(selectCore, (state: IndoorState) => state.posts),
    outdoorPosts: createSelector(selectCore, (state: IndoorState) => state.outdoorPosts),
    outdoorData: createSelector(selectCore, (state: IndoorState) => state.outdoorData),
};

export const getParamsPostMeasurementsIndoor = createSelector(
    selectGroupId,
    indoorSelectors.dateBegin,
    indoorSelectors.dateEnd,
    selectIndoorMeasureScheme,
    (groupId, dateBegin, dateEnd, mmtScheme) => {
        if (groupId && dateBegin && dateEnd) {
            const params: PostsMeasurementsProps = {
                timeBegin: new Date(dateBegin).valueOf(),
                timeEnd: new Date(dateEnd).valueOf(),
                groupId: groupId,
                measureScheme: mmtScheme,
            };
            return params;
        }
        return null;
    }
);
export const getParamsOutdoorPostMeasurementsIndoor = createSelector(
    selectGroupId,
    indoorSelectors.outdoorPosts,
    indoorSelectors.dateBegin,
    indoorSelectors.dateEnd,
    selectIndoorMeasureScheme,
    (groupId, posts, dateBegin, dateEnd, mmtScheme) => {
        if (groupId && posts.length && dateBegin && dateEnd) {
            const ids = posts.map((post) => post.id);
            const params: PostsMeasurementsProps = {
                timeBegin: new Date(dateBegin).valueOf(),
                timeEnd: new Date(dateEnd).valueOf(),
                groupId: groupId,
                measureScheme: mmtScheme,
                ids: ids,
            };
            return params;
        }
        return null;
    }
);
export const getParamsPostIndoor = createSelector(selectGroupId, (groupId) => {
    if (groupId) {
        const params = {
            groupId: groupId,
        };
        return params;
    }
    return null;
});
export const selectParamsStatInfo = createSelector(
    getParamsPostIndoor,
    indoorSelectors.posts,
    (params, posts) => {
        if (params?.groupId && posts && posts.length) {
            return params.groupId;
        }
        return null;
    }
);

export const getOutdoorValue = (postId: number) =>
    createSelector(
        indoorSelectors.currentMmt,
        indoorSelectors.outdoorData,
        indoorSelectors.time,
        (mmt, data, time) => {
            if (mmt && data.length && time) {
                const currentTimedata = data.find(
                    (item) => item.postId === postId && item.date === time
                );
                // display instantAqi
                const value =
                    mmt === 'indoorAqi'
                        ? currentTimedata?.aqi?.instantAqi?.value10
                        : currentTimedata?.[mmt];
                return value ?? null;
            }

            return null;
        }
    );
