import { detectMobile } from '@libs/common';

declare const navigator: Navigator;

export function isIos() {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

// TODO delete
export const COMPARE_LIMIT = detectMobile() ? 2 : 5;
