import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@libs/common';

@Pipe({
    name: 'getFeatureValue',
})
export class GetFeatureValuePipe implements PipeTransform {
    transform(obj: Feature, mmt: string, index: number): number {
        if (!obj) {
            return null;
        }

        const { timeseries } = obj.properties;
        const data = timeseries?.[mmt] || null;
        if (!data) {
            return null;
        } else {
            return data[index];
        }
    }
}
