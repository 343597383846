import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticComponent } from './analytic.component';
import { HeaderOfPanelModule } from '@libs/shared-ui';
import { InputDropdownModule } from '@libs/shared-ui';
import { SelectboxRadioModule } from '@libs/shared-ui';
import { SwitchItemModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';
import { DoughnutChartModule } from './analytic-charts/doughnut-chart/doughnut-chart.module';
import { BarChartModule } from './analytic-charts/bar-chart/bar-chart.module';
import { DirectivesModule } from '@libs/shared-ui';

@NgModule({
    imports: [
        CommonModule,
        HeaderOfPanelModule,
        InputDropdownModule,
        SelectboxRadioModule,
        SwitchItemModule,
        LoadersModule,
        DoughnutChartModule,
        BarChartModule,
        DirectivesModule,
    ],
    exports: [AnalyticComponent],
    declarations: [AnalyticComponent],
})
export class AnalyticModule {}
