import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { LoginUpdateService } from '../login-update/login-update.service';

@Injectable({
    providedIn: 'root',
})
export class ResetCodeGuard implements CanActivate {
    constructor(private router: Router, private loginUpdateService: LoginUpdateService) {}

    canActivate() {
        const resetEmail = this.loginUpdateService.getEmail();

        if (!resetEmail) {
            return this.router.navigate([]);
        }

        return true;
    }
}
