<div
    class="feedback-button-wrapper"
    [attr.data-cy]="'feedback-button'"
    caTooltip
    caTooltipPlacement="right"
    [caTooltipTemplate]="feedbackTooltip"
>
    <div class="feedback-button">
        <feedback-icon></feedback-icon>
    </div>
</div>
<ng-template #feedbackTooltip>
    <ca-tooltip [text]="TEXTS.FEEDBACK.leaveFeedback"></ca-tooltip>
</ng-template>
