<div class="room-item-circle">
    <div class="room-item-circle__description">{{ titleDescription }}</div>
    <div class="room-item-circle__title">{{ title }}</div>
    <div class="room-item-circle__grade">
        <value-circle
            [value]="grade"
            [color]="color"
            [numberAfterDot]="numberAfterDot"
        ></value-circle>
    </div>
</div>
