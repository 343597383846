import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TEXTS } from '@libs/common';

@Component({
    selector: 'stats-popup',
    templateUrl: './stats-popup.component.html',
    styleUrls: ['./stats-popup.component.less'],
})
export class StatsPopupComponent implements OnChanges {
    @Input() showComfort = false;
    @Input() showSafety = false;
    @Output() hideComfort = new EventEmitter<void>();
    @Output() hideSafety = new EventEmitter<void>();
    @Output() openPopup = new EventEmitter<void>();

    TEXTS = TEXTS;

    canClose = false;
    ngOnChanges() {
        if (this.showSafety || this.showComfort) {
            this.canClose = false;
            setTimeout(() => (this.canClose = true), 100); // for fix clickOutside
        }
    }

    closeAll() {
        if (this.canClose) {
            this.hideComfort.emit();
            this.hideSafety.emit();
        }
    }
}
