import { Pipe, PipeTransform } from '@angular/core';
import { SourceImpact } from '@libs/common';
import { LngLat } from 'mapbox-gl';
import { formatNumber } from '@angular/common';

@Pipe({ name: 'impactSourceSquare' })
export class ImpactSourceSquarePipe implements PipeTransform {
    transform(source: SourceImpact, locale: string = 'ru'): any {
        const bbox = source.bbox;
        const point1 = new LngLat(bbox[2], bbox[1]);
        const point2 = new LngLat(bbox[2], bbox[3]);
        const point3 = new LngLat(bbox[0], bbox[3]);

        const width = Math.round(point1.distanceTo(point2) * 100) / 100;
        const height = Math.round(point2.distanceTo(point3) * 100) / 100;
        const square = width * height;
        if (square < 1) {
            const distanceName = locale === 'ru' ? 'м<sup>2</sup>' : 'm<sup>2</sup>';
            return '~ 1 ' + distanceName;
        }
        if (square < 1000000) {
            const distanceName = locale === 'ru' ? 'м<sup>2</sup>' : 'm<sup>2</sup>';
            const value = formatNumber(square, locale, '1.0-0');
            return `${value} ${distanceName}`;
        }

        const distanceName = locale === 'ru' ? 'км<sup>2</sup>' : 'km<sup>2</sup>';
        const value = formatNumber(square / 1000000, locale, '1.0-0');
        return `${value} ${distanceName}`;
    }
}
