import { Component, Input } from '@angular/core';

@Component({
    selector: 'ca-eye-icon',
    templateUrl: 'eye-icon.component.html',
    styles: [
        `
            :host {
                width: 24px;
                height: 24px;
            }
        `,
    ],
})
export class EyeIconComponent {
    @Input() isOpened: boolean;
}
