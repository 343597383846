import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { TEXTS } from '@libs/common';
import { OffPanelPopupService } from '@libs/shared-store';
import { declOfNum } from '@libs/common';
import { filter, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RunConfigImpact, RunConfigImpactEdit } from '@libs/common';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {
    ImpactActions,
    selectConfigImpact,
    selectIsLoadingEditRun,
    selectErrorEditRun,
    selectSuccessEditRun,
} from '@cityair/modules/impact/store/impact.feature';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { MAIN_PAGES } from '@libs/common';
import { SharedCoreActions } from '@libs/shared-store';

export interface ErrorModel {
    name?: string;
    schedule_period?: string;
}

export interface DropdownSelect {
    id: number;
    name: number;
}

@Component({
    selector: 'ca-impact-edit-run',
    templateUrl: 'impact-edit-run.component.html',
    styleUrls: ['impact-edit-run.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactEditRunComponent implements OnInit, OnDestroy {
    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;
    hours: number;
    runConfig: RunConfigImpact;
    questionsText = TEXTS.POPUP_THREE_QUESTIONS;
    textPlumes = TEXTS.PLUMES;
    textEditStation = TEXTS.EDIT_STATION;
    public translateText = TEXTS.FORECAST;
    dropdownSelectHourId: number[] = [];
    dropdownSelectHour: DropdownSelect[] = [];
    showConfirmationPopup = false;
    editCalculation: UntypedFormGroup;
    errorObj: ErrorModel = {};
    selectIsLoadingEditRun = selectIsLoadingEditRun;
    public lastKeyError: string[] = [];
    private isInit = true;
    private initConfigId: string;
    private currentId: number;
    public ngDestroyed$ = new Subject<void>();

    constructor(
        private fb: UntypedFormBuilder,
        private popupProvider: OffPanelPopupService,
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        public store: Store
    ) {
        this.route.params.subscribe((params) => {
            if (this.isInit) {
                this.initConfigId = params?.id;
            } else {
                this.currentId = params?.id;
            }
            _changeDetectorRef.markForCheck();
        });
        this.store
            .select(selectConfigImpact)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v.length && this.isInit && this.initConfigId !== null)
            )
            .subscribe((configs) => {
                const currentConfig = configs.find((v) => v.id === Number(this.initConfigId));

                this.isInit = false;

                if (currentConfig) {
                    this.currentId = Number(this.initConfigId);
                    this.runConfig = currentConfig;
                    this.editCalculation = this.fb.group({
                        name: [
                            this.runConfig?.name,
                            [Validators.required, Validators.maxLength(60)],
                        ],
                    });
                    this.hours = this.runConfig?.schedule_period / 60;
                    _changeDetectorRef.markForCheck();
                } else {
                    this.router.navigate([`/${MAIN_PAGES.impact}/${IMPACT_PAGES.configs}`]);
                }
            });
    }

    ngOnInit() {
        this.store
            .select(selectErrorEditRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((value) => {
                if (value) {
                    const data = value.error?.error;
                    for (const key in data) {
                        if (data.hasOwnProperty(key) && this.editCalculation?.controls[key]) {
                            this.editCalculation.controls[key].setErrors({
                                incorrect: true,
                                message: data[key].join(', '),
                            });
                            this.lastKeyError.push(key);
                        } else if (data.hasOwnProperty('detail')) {
                            this.store.dispatch(
                                SharedCoreActions.setInfoMessage({
                                    payload: {
                                        id: new Date().valueOf(),
                                        message: `${data.detail}. ${this.translateText.tryAgain}.`,
                                        positionX: 'left',
                                        positionY: 'bottom',
                                        iconClass: 'error',
                                        duration: 10000,
                                        showCloseIcon: true,
                                        size: 'lg',
                                    },
                                })
                            );
                            setTimeout(() => {
                                this.store.dispatch(
                                    ImpactActions.setErrorEditRun({ payload: null })
                                );
                            }, 2500);
                        }
                    }
                }
            });

        this.store
            .select(selectSuccessEditRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((value) => {
                if (value) {
                    this.store.dispatch(
                        SharedCoreActions.setInfoMessage({
                            payload: {
                                id: new Date().valueOf(),
                                messageKey: 'Update_Success',
                                positionX: 'left',
                                positionY: 'bottom',
                                iconClass: 'success',
                                duration: 2000,
                                showCloseIcon: true,
                                size: 'lg',
                            },
                        })
                    );
                    setTimeout(() => {
                        this.store.dispatch(ImpactActions.setSuccessEditRun({ payload: false }));
                        this.router.navigate([`/${MAIN_PAGES.impact}/${IMPACT_PAGES.configs}`]);
                    }, 2000);
                }
            });

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            this.openPopup();
            return true;
        });

        for (let i = 1; i < 25; i++) {
            this.dropdownSelectHour.push({ id: i, name: i });
            this.dropdownSelectHourId.push(i);
        }
    }

    get name() {
        return this.editCalculation?.get('name');
    }

    onSubmit() {
        const schedule_period = this.hours * 60;
        const config: RunConfigImpactEdit = {
            id: this.runConfig.id,
            name: this.editCalculation.value.name,
            schedule_period,
        };
        this.store.dispatch(ImpactActions.updateRunConfig({ payload: config }));
    }

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    };

    onSavePopup = () => {
        this.showConfirmationPopup = false;
    };

    backOnCalculationResults = () => {
        this.router.navigate([`/${MAIN_PAGES.impact}/${IMPACT_PAGES.configs}`]);
    };

    selectHour = (id) => {
        this.dropdownSelectHour.map((d) => {
            if (d.id === id) {
                this.hours = d.name;
                this.editCalculation.get('name').markAsDirty();
            }
        });
    };

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
        this.ngDestroyed$.next();
    }

    declOfNum = declOfNum;

    public getError(field: string): string {
        if (
            this.editCalculation &&
            this.editCalculation.controls[field].invalid &&
            (this.editCalculation.controls[field].dirty ||
                this.editCalculation.controls[field].touched)
        ) {
            if (this.editCalculation.controls[field].errors.required) {
                return this.textPlumes.nameRequiredError;
            }
            if (this.editCalculation.controls[field].errors.maxlength) {
                return this.textPlumes.maxLength(
                    this.editCalculation.controls[field].errors.maxlength.requiredLength
                );
            }
        }

        return '';
    }
}
