<div
    [ngClass]="{
        input_for_dropdown__block: true,
        'input_for_dropdown__block-active': isOpenDropdown,
        'input_for_dropdown__block-error': isError,
        'input_for_dropdown__block-disable': isDisable
    }"
    (click)="toggleShow()"
>
    <div class="input_for_dropdown__text">
        <span *ngIf="inputValue; else noSelect" [innerHTML]="inputValue"></span>
        <ng-template #noSelect>
            {{ texts.LIST_USERS.noSelect }}
        </ng-template>
    </div>
    <div class="input_for_dropdown__title">
        <span style="position: relative" [innerHTML]="label"></span>
        <ca-info-icon
            class="input_for_dropdown__icon-info"
            *ngIf="showLabelInfoIcon && label"
            [withGradient]="true"
            (click)="clickInfo($event)"
        ></ca-info-icon>
    </div>
    <ca-arrow
        class="input_for_dropdown__wrapper_arrow"
        size="small"
        [direction]="isOpenDropdown ? 'up' : 'down'"
    ></ca-arrow>
</div>
