import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isEmptyChartData',
})
export class IsEmptyChartDataPipe implements PipeTransform {
    transform(datakey: any): boolean {
        return Object.values(datakey).every((v) => v);
    }
}
