<div class="aqi-indicator">
    <div class="aqi-indicator__datetime">
        <b>{{ time }}</b
        >&nbsp;<span>{{ date }}</span>
    </div>
    <div class="aqi-indicator__content">
        <div class="aqi-indicator__title">{{ TEXTS.NAMES[mmt] }}</div>
        <div class="aqi-indicator__value">
            <value-circle
                [value]="value"
                [color]="measureZones.getColor(value, mmt)"
            ></value-circle>
        </div>
    </div>
</div>
