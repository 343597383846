import * as moment from 'moment-timezone';

import { getColorIndex } from '@cityair/config';
import { NO_DATA_COLOR } from '@libs/common';
import { ColorZone } from '@libs/common';
import { isFalseNumber } from '@libs/common';
import { WD } from '@libs/common';

declare const window: any;

export function isOldBrowser(): boolean {
    if (!window.navigator) return true;

    const ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0) return true;

    return false;
}

export function createTimeSequence(timeBegin: number, timeEnd: number): Set<number> {
    const timeSequence: Set<number> = new Set();
    const time = moment.utc(timeBegin).startOf('hour');
    while (time < moment.utc(timeEnd)) {
        timeSequence.add(time.valueOf());
        time.add(1, 'hour');
    }

    return timeSequence;
}

// TODO: unify with createTimeSequence
export function createTimeSequencePlumes(timeBegin: number, timeEnd: number): Set<number> {
    const timeSequence: Set<number> = new Set();

    const time = moment.utc(timeBegin);
    while (time < moment.utc(timeEnd)) {
        timeSequence.add(time.valueOf());
        time.add(20, 'minutes');
    }

    return timeSequence;
}

function base64toBlob(b64Data, contentType = 'application/octet-binary', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export const saveDataToDisk = (data, filename) => {
    const blob = base64toBlob(data);
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
    downloadLink.setAttribute('download', filename);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
};
export const savePostDataToDisk = (data, filename) => {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', window.URL.createObjectURL(data));
    downloadLink.setAttribute('download', filename);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
};
export const hideHtmlLoader = () => {
    const elemStyle = window.commonLoader?.style;

    if (!elemStyle) {
        return;
    }

    elemStyle.opacity = '0';
    setTimeout(() => {
        elemStyle.display = 'none';
    }, 200);
};

export function getColorFromZone(colorZone: ColorZone, value: number) {
    if (isFalseNumber(value) || !colorZone) return NO_DATA_COLOR;

    const index = getColorIndex(colorZone.zone, value);

    return colorZone.color?.[index] ?? NO_DATA_COLOR;
}
export function getColorFromZoneForecast(colorZone: ColorZone, value: number, mmt: string) {
    if (isFalseNumber(value) || !colorZone)
        return mmt === WD && !isFalseNumber(value) ? '#404655' : NO_DATA_COLOR;

    const index = getColorIndex(colorZone.zone, value);

    return colorZone.color?.[index] ?? NO_DATA_COLOR;
}
