export const drawVs = `
precision highp float;

attribute float a_index;

uniform sampler2D u_particles;
uniform float u_particles_res;

varying vec2 v_particle_pos;

void main() {
    // координаты x, y
    float x = fract(a_index / u_particles_res);
    float y = floor(a_index / u_particles_res) / u_particles_res;
    // пиксель с положением
    vec4 particle = texture2D(u_particles, vec2(x, y));

    // преобразуем цвет в положение
    v_particle_pos = vec2(
        particle.r / 255.0 + particle.b,
        particle.g / 255.0 + particle.a);

    gl_PointSize = 2.0;
    // преобразуем координаты к системе -1 ... 1
    gl_Position = vec4(2.0 * v_particle_pos.x - 1.0, 1.0 - 2.0 * v_particle_pos.y, 0, 1);
}
`;
