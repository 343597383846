import {
    createActionGroup,
    createFeature,
    createFeatureSelector,
    createReducer,
    createSelector,
    emptyProps,
    on,
    props,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Post, PostCreateRequest } from '@libs/common';
import { selectAllCities } from './core.feature';

const SHARED_POST_FEATURE_KEY = 'sharedPost';
export const SharedPostActions = createActionGroup({
    source: 'SharedPost',
    events: {
        'Set All Posts': props<{ payload: Post[] }>(),
        'Clear All Posts': emptyProps(),
        'Set Is Loading Posts': props<{ payload: boolean }>(),
        'Update Posts List': emptyProps(),
        'Add Post': props<{ payload: { params: PostCreateRequest } }>(),
        'Add Post Success': props<{ payload: Post }>(),
        'Update Post': props<{ payload: { params: PostCreateRequest; id: string } }>(),
        'Update Post Success': props<{ payload: Post }>(),
        'Delete Post': emptyProps(),
        'Delete Post Error': emptyProps(),
        'Delete Post Success': props<{ payload: Post }>(),
    },
});
interface SharedPostState {
    posts: EntityState<Post>;
    isLoadingPosts: boolean;
}
export function sortByName(a: Post, b: Post): number {
    return a.name.localeCompare(b.name);
}
export const postAdapter: EntityAdapter<Post> = createEntityAdapter<Post>({
    sortComparer: sortByName,
});
const initialState: SharedPostState = {
    posts: postAdapter.getInitialState(),
    isLoadingPosts: true,
};
const featureSelector = createFeatureSelector<SharedPostState>(SHARED_POST_FEATURE_KEY);
export const selectAllPosts = createSelector(featureSelector, (state) =>
    postAdapter.getSelectors().selectAll(state.posts)
);
export const selectAllPostsIds = createSelector(featureSelector, (state) =>
    postAdapter.getSelectors().selectIds(state.posts)
);
export const selectAllPostsDic = createSelector(featureSelector, (state) =>
    postAdapter.getSelectors().selectEntities(state.posts)
);
export const selectPostEntitiesById = (id) =>
    createSelector(featureSelector, (state) => state.posts.entities[id] ?? null);
export const selectPostNameById = (id) =>
    createSelector(selectPostEntitiesById(id), (post) => post?.name ?? null);
export const selectPostParentNameById = (id) =>
    createSelector(selectPostEntitiesById(id), (post) => post?.ancestor?.name ?? null);
export const selectPostParentNameIfNeed = (id) =>
    createSelector(selectPostParentNameById(id), selectAllCities, (name, cities) =>
        cities?.length > 1 ? name : null
    );
export const sharedPostFeature = createFeature({
    name: SHARED_POST_FEATURE_KEY,
    reducer: createReducer(
        initialState,

        on(SharedPostActions.setAllPosts, (state, { payload }) => {
            const postsResponse = payload as Post[];
            const posts = postAdapter.setMany(postsResponse, state.posts);
            return {
                ...state,
                posts,
                isLoadingPosts: false,
            };
        }),

        on(SharedPostActions.clearAllPosts, (state) => {
            const posts = postAdapter.removeAll(state.posts);
            return {
                ...state,
                posts,
            };
        }),
        on(SharedPostActions.addPostSuccess, (state, { payload }) => {
            if (payload) {
                const postsResponse = payload as Post;
                const posts = postAdapter.addOne(postsResponse, state.posts);
                return {
                    ...state,
                    posts,
                };
            } else {
                return { ...state };
            }
        }),
        on(SharedPostActions.updatePostSuccess, (state, { payload }) => {
            if (payload) {
                const postsResponse = payload as Post;
                const posts = postAdapter.setOne(postsResponse, state.posts);
                return {
                    ...state,
                    posts,
                };
            } else {
                return { ...state };
            }
        }),
        on(SharedPostActions.setIsLoadingPosts, (state, { payload }) => ({
            ...state,
            isLoadingPosts: payload,
        })),
        on(SharedPostActions.deletePostSuccess, (state, { payload }) => {
            if (payload) {
                const postsResponse = payload as Post;
                const posts = postAdapter.removeOne(postsResponse.id, state.posts);
                return {
                    ...state,
                    posts,
                };
            } else {
                return { ...state };
            }
        })
    ),
});

export const { selectPosts, selectIsLoadingPosts } = sharedPostFeature;
