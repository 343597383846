import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonPlayComponent } from './button-play.component';

@NgModule({
    imports: [CommonModule],
    exports: [ButtonPlayComponent],
    declarations: [ButtonPlayComponent],
})
export class ButtonPlayModule {}
