export type DomainConfigType = {
    slug: string;
    substances: string[];
    coordinates: number[][];
    extent?: number[];
};

export enum DataType {
    Raster = 'raster',
    Vector = 'vector',
}

export enum TileType {
    DomainTiles = 'custom',
    Tiles = 'xyz',
}

export interface IAuthorizeHelper {
    getAuthHeader: () => Record<string, string>;
    refreshToken: () => Promise<void>;
}
