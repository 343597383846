<ng-template #popupOutlet>
    <stnd-ask-popup-three-questions
        *ngIf="showConfirmationPopup"
        [texts]="questionsText"
        [close]="onClosePopup"
        [extraAction]="backOnCalculationResults"
    >
    </stnd-ask-popup-three-questions>
</ng-template>

<div class="edit_calc_result">
    <div class="edit_calc_result__title_wrapper">
        <header-page
            [textObject]="{ titlePage: textPlumes.editRunConfigurationTitle }"
        ></header-page>
    </div>

    <div class="edit_calc_result__body">
        <form (ngSubmit)="onSubmit()" [formGroup]="editCalculation" novalidate>
            <div class="edit_calc_result__wrapper_input_name">
                <ca-input
                    type="text"
                    controlName="name"
                    [form]="editCalculation"
                    [valueForm]="name.value"
                    [label]="textPlumes.runConfigurationName"
                    [textError]="getError('name')"
                ></ca-input>
            </div>
            <div class="edit_calc_result__subtitle">{{ textPlumes.runInterval }}</div>
        </form>
    </div>
    <div>
        <div class="edit_calc_result__input_wrapper">
            <div class="edit_calc_result__input_block">
                <div class="edit_calc_result__label_select">{{ textPlumes.hour }}</div>
                <control-select
                    class="rows-no_border"
                    data-cy="plume-edit-selector1"
                    [format]="'simple'"
                    [model]="hours"
                    [items]="dropdownSelectHour"
                    [keyValue]="'id'"
                    [keyName]="'name'"
                    (closeEvent)="selectHour($event)"
                ></control-select>
            </div>
        </div>
    </div>

    <div
        *ngIf="errorObj.schedule_period && hours === 0"
        class="edit_calc_result__note edit_calc_result__note-error"
    >
        {{ errorObj.schedule_period }}
    </div>

    <div *ngIf="hours !== 0" class="edit_calc_result__note">
        {{ textPlumes.runIntervalDescription }}
        {{ hours | i18nPlural : textPlumes.every }}
        {{ hours }} {{ declOfNum(hours, textPlumes.timeIntervalHours) }}
    </div>

    <div class="edit_calc_result__action_btn_wrapper">
        <ca-button type="cancel" (clickAction)="backOnCalculationResults()">
            {{ textEditStation.cancel }}
        </ca-button>
        <ca-button
            type="primary"
            (clickAction)="onSubmit()"
            [disabled]="editCalculation.pristine || editCalculation.invalid"
        >
            <ca-spinner *ngIf="isLoading; else showContentSave"></ca-spinner>
            <ng-template #showContentSave>{{ textEditStation.save }}</ng-template>
        </ca-button>
    </div>
</div>
