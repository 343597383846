import { Pipe, PipeTransform } from '@angular/core';
import { ImpactStatistic } from '@cityair/modules/impact/service/api-model-impact';
import {
    ControlPointImpact,
    CONTRIBUTIONS_COLORS_SOURCES_ORDER,
    getColorByIndex,
    SourceImpact,
} from '@libs/common';
import { sortByValue } from '@cityair/modules/impact/utils';

@Pipe({ name: 'chartDataImpactSourcePoint' })
export class ChartDataImpactSourcePointPipe implements PipeTransform {
    transform(
        statsData: { [key: string]: number },
        points: ControlPointImpact[]
    ): { value: number; label: string; color: string }[] {
        const pointsKey = Object.keys(statsData);
        let data = pointsKey
            ?.map((id, index) => {
                const point = points.find((v) => v.id === Number(id));
                const value = statsData[id];
                if (point && value) {
                    return {
                        value,
                        label: point.name,
                        color: '',
                    };
                }
            })
            ?.filter((v) => !!v);
        data = sortByValue(data);

        return data?.map((v, index) =>
            Object.assign(v, {
                color: getColorByIndex(CONTRIBUTIONS_COLORS_SOURCES_ORDER, index),
            })
        );
    }
}
@Pipe({ name: 'chartDataPercentSource' })
export class ChartDataPercentSourcePipe implements PipeTransform {
    transform(
        statsData: ImpactStatistic,
        sources: SourceImpact[]
    ): {
        values: number[];
        labels?: string[];
        colors?: string[];
    } {
        const valuesData = Object.values(statsData.percentage_contribution);
        const isEmpty = valuesData.every((v) => v === 0);
        if (isEmpty) {
            return { values: [] };
        }
        const sourcesKey = Object.keys(statsData.percentage_contribution);
        let data = sourcesKey
            ?.map((id, index) => {
                const source = sources.find((v) => v.id === Number(id));
                if (source && statsData.percentage_contribution[id]) {
                    return {
                        value: statsData.percentage_contribution[id],
                        label: source.name,
                    };
                }
            })
            ?.filter((v) => v);

        data = sortByValue(data);

        return {
            values: data.map((v) => +v.value.toFixed(0)),
            labels: data.map((v) => v.label),
            colors: data.map((v, index) =>
                getColorByIndex(CONTRIBUTIONS_COLORS_SOURCES_ORDER, index)
            ),
        };
    }
}
@Pipe({ name: 'isNeedRoundingSource' })
export class ChartDataRoundingSourcePipe implements PipeTransform {
    transform(value: number, numberAfterDot: number): number {
        if (value > 1) {
            return null;
        } else if (+value.toFixed(numberAfterDot) === 0 && value > 0) {
            return 1 / Math.pow(10, numberAfterDot);
        }
        return null;
    }
}
