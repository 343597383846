import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, interval, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { MoExportType } from '@cityair/namespace';

import { onIsEnabledChart } from '@libs/shared-ui';
import { IndoorApi, Post, PostInfoResponse, PostsMeasurementsResponse } from '../services/api';
import {
    allPostsResponse,
    changeAnalyticPage,
    deselectPoints,
    destroy,
    init,
    initOutdoorPins,
    openDownloadPopup,
    postInfoResponse,
    selectPoint,
    selectPointOutdoor,
    setIsPointsLoading,
    setOutdoorResponse,
    statInfoResponse,
    updateIntervalData,
    updateTimeRange,
} from './actions';
import { IIndoorState } from './reducers';
import {
    getParamsOutdoorPostMeasurementsIndoor,
    getParamsPostMeasurementsIndoor,
    indoorSelectors,
    selectParamsStatInfo,
} from './selectors';
import { analyticType, getTimeBegin, getTimeEnd } from '@cityair/modules/indoor/config';
import { DownloadPopupsService } from '@cityair/components/download-popups-wrapper/download-popups.service';
import { MeasureScheme, MINUTE5_MS, TEXTS } from '@libs/common';
import { selectGroupId, SharedCoreActions } from '@libs/shared-store';

@Injectable()
export class IndoorEffects {
    constructor(
        private actions$: Actions,
        private indoorApi: IndoorApi,
        private store: Store<IIndoorState>,
        private coreStore: Store,
        private downloadPopupsService: DownloadPopupsService
    ) {}

    startInterval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(init),
            switchMap(() => this.store.select(indoorSelectors.isAllowUpdate)),
            switchMap(() =>
                interval(MINUTE5_MS).pipe(
                    takeUntil(this.actions$.pipe(ofType(destroy))),
                    takeUntil(
                        this.store
                            .select(indoorSelectors.isAllowUpdate)
                            .pipe(filter((isAllowUpdate) => !isAllowUpdate))
                    )
                )
            ),
            map(() => updateIntervalData())
        )
    );

    allPostsListRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(init, updateTimeRange),
            withLatestFrom(
                this.coreStore.select(getParamsPostMeasurementsIndoor),
                this.coreStore.select(indoorSelectors.activePointId)
            ),
            filter(([time, data]) => data !== null),
            tap(() => {
                this.coreStore.dispatch(setIsPointsLoading({ payload: true }));
            }),
            switchMap(([action, params, active]) => {
                // if init date in params, if updateTimeRange date in param
                const time = action as { timeBegin: number; timeEnd: number };

                return this.indoorApi
                    .getPostsMeasurements(
                        {
                            timeBegin: time.timeBegin || params.timeBegin,
                            timeEnd: time.timeEnd || params.timeEnd,
                        },
                        {
                            groupId: params.groupId,
                            measureScheme: params.measureScheme,
                        }
                    )
                    .pipe(
                        map((data: PostsMeasurementsResponse) => {
                            this.checkActivePoint(data.posts, active);
                            return allPostsResponse(data);
                        }),
                        catchError((error) => {
                            this.checkActivePoint([], active);

                            return of(
                                setIsPointsLoading({ payload: false }),
                                allPostsResponse({ data: [], posts: [] })
                            );
                        })
                    );
            })
        )
    );

    indoorUpdateIntervalData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateIntervalData),
            withLatestFrom(
                this.coreStore.select(getParamsPostMeasurementsIndoor),
                this.coreStore.select(indoorSelectors.activePointId)
            ),
            switchMap(([_, params, active]) =>
                this.indoorApi
                    .getPostsMeasurements(
                        {
                            timeBegin: new Date(getTimeBegin()).getTime(),
                            timeEnd: new Date(getTimeEnd()).getTime(),
                        },
                        {
                            groupId: params.groupId,
                            measureScheme: params.measureScheme,
                        }
                    )
                    .pipe(
                        map((data: PostsMeasurementsResponse) => {
                            this.checkActivePoint(data.posts, active);
                            return allPostsResponse(data);
                        }),
                        catchError((error) => {
                            this.checkActivePoint([], active);
                            return of(allPostsResponse({ data: [], posts: [] }));
                        })
                    )
            )
        )
    );

    outdoorPins$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initOutdoorPins),
            withLatestFrom(
                this.coreStore.select(getParamsPostMeasurementsIndoor),
                this.coreStore.select(indoorSelectors.activeOutdoorId)
            ),
            filter(([action, data]) => data !== null && !!action.ids),
            switchMap(([action, params, active]) =>
                this.indoorApi
                    .getPostsMeasurementsByIds(
                        {
                            timeBegin: params.timeBegin,
                            timeEnd: params.timeEnd,
                        },
                        {
                            groupId: params.groupId,
                            measureScheme: params.measureScheme,
                        },
                        {
                            ids: action.ids,
                        }
                    )
                    .pipe(
                        map((data: PostsMeasurementsResponse) => {
                            this.checkActivePoint(data.posts, active);
                            return setOutdoorResponse(data);
                        }),
                        catchError((error) => {
                            this.checkActivePoint([], active);
                            return of(setOutdoorResponse({ data: [], posts: [] }));
                        })
                    )
            )
        )
    );

    indoorUpdateOutDoorData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateIntervalData),
            withLatestFrom(
                this.coreStore.select(getParamsOutdoorPostMeasurementsIndoor),
                this.coreStore.select(indoorSelectors.activeOutdoorId)
            ),
            filter(([, params]) => params !== null),
            switchMap(([_, params, active]) =>
                this.indoorApi
                    .getPostsMeasurementsByIds(
                        {
                            timeBegin: new Date(getTimeBegin()).getTime(),
                            timeEnd: new Date(getTimeEnd()).getTime(),
                        },
                        {
                            groupId: params.groupId,
                            measureScheme: params.measureScheme,
                        },
                        { ids: params.ids }
                    )
                    .pipe(
                        map((data: PostsMeasurementsResponse) => {
                            this.checkActivePoint(data.posts, active);
                            return setOutdoorResponse(data);
                        }),
                        catchError((error) => {
                            this.checkActivePoint([], active);
                            return of(setOutdoorResponse({ data: [], posts: [] }));
                        })
                    )
            )
        )
    );

    indoorUpdateTimeOutdoorData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateTimeRange),
            withLatestFrom(
                this.coreStore.select(getParamsOutdoorPostMeasurementsIndoor),
                this.coreStore.select(indoorSelectors.activeOutdoorId)
            ),
            filter(([, params]) => params !== null),
            switchMap(([action, params, active]) =>
                this.indoorApi
                    .getPostsMeasurementsByIds(
                        {
                            timeBegin: action.timeBegin,
                            timeEnd: action.timeEnd,
                        },
                        {
                            groupId: params.groupId,
                            measureScheme: params.measureScheme,
                        },
                        { ids: params.ids }
                    )
                    .pipe(
                        map((data: PostsMeasurementsResponse) => {
                            this.checkActivePoint(data.posts, active);
                            return setOutdoorResponse(data);
                        }),
                        catchError((error) => {
                            this.checkActivePoint([], active);
                            return of(setOutdoorResponse({ data: [], posts: [] }));
                        })
                    )
            )
        )
    );
    indoorSelectPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectPoint),
            switchMap((props) =>
                this.coreStore.select(selectGroupId).pipe(
                    filter((groupId) => !!groupId),
                    map((groupId) => ({ groupId, moId: props.id }))
                )
            ),
            switchMap((props) =>
                this.indoorApi.getPostInfo(Number(props.groupId), props.moId).pipe(
                    map((data: PostInfoResponse) => postInfoResponse(data.Result)),
                    catchError((err, caught) => of({ type: 'catchError' }))
                )
            )
        )
    );

    getStatInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(allPostsResponse),
            withLatestFrom(this.coreStore.select(selectParamsStatInfo)),
            filter(([_, groupId]) => !!groupId),
            switchMap(([_, groupId]) =>
                this.indoorApi.getStatInfo(groupId).pipe(
                    map((data) => statInfoResponse({ payload: data.Result })),
                    catchError((err, caught) => of({ type: 'catchError' }))
                )
            )
        )
    );

    indoorShowChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectPoint),
            switchMap((props) => [
                onIsEnabledChart({ payload: !!props.id }),
                changeAnalyticPage({ payload: analyticType.post }),
            ])
        )
    );

    indoorShowChartForOutDoor = createEffect(() =>
        this.actions$.pipe(
            ofType(selectPointOutdoor),
            switchMap((props) => [onIsEnabledChart({ payload: !!props.id })])
        )
    );
    deselectOutdoorPinCloseChart = createEffect(() =>
        this.actions$.pipe(
            ofType(onIsEnabledChart),
            withLatestFrom(this.coreStore.select(indoorSelectors.activeOutdoorId)),
            filter(([props, activeId]) => !props.payload && !!activeId),
            map((_) => selectPointOutdoor({ id: null }))
        )
    );

    indoorHideChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onIsEnabledChart),
            filter((props) => !props.payload),
            switchMap((_) => [
                deselectPoints(),
                changeAnalyticPage({ payload: analyticType.floor }),
            ])
        )
    );

    indoorOpenDownloadPopup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(openDownloadPopup),
            withLatestFrom(
                this.store.select(indoorSelectors.activePointId),
                this.store.select(indoorSelectors.listPoints),
                this.store.select(indoorSelectors.currentMmtAndScheme)
            ),
            exhaustMap(([_, activeId, posts, ms]) => {
                const _posts = posts.map((post) => ({
                    id: `post_${post.id}`,
                    name: post.name,
                }));
                this.downloadPopupsService.openDownloadPopup$.next({
                    type:
                        ms.scheme === MeasureScheme.usa_default
                            ? MoExportType.moIndoor
                            : MoExportType.mo,
                    title: TEXTS.EDIT_STATION.downloadXLSData,
                    ids: activeId ? [`post_${activeId}`] : [],
                    mos: _posts,
                });

                return []; // empty
            })
        )
    );

    private checkActivePoint(posts: Post[], active: number): void {
        if (!active) {
            return;
        }
        const isExist = posts.length ? posts.find((item) => item.id === active) : undefined;
        if (!isExist) {
            this.coreStore.dispatch(onIsEnabledChart({ payload: false }));
            this.coreStore.dispatch(
                SharedCoreActions.setInfoMessage({
                    payload: {
                        id: new Date().valueOf(),
                        messageKey: 'notFoundPost',
                        positionX: 'left',
                        positionY: 'bottom',
                        iconClass: 'error',
                        duration: 10000,
                        showCloseIcon: true,
                        size: 'lg',
                    },
                })
            );
        }
    }
}
