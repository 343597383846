<div [className]="'analityc_header ' + headerClass">
    <div *ngIf="config?.showGlobalLink; else logoTemplate">
        <div class="link-wrapper">
            <a href="./{{ mainPages.global }}">Global Map</a>
        </div>
    </div>
    <ng-template #logoTemplate>
        <div [className]="'logo-wrapper ' + config?.logo?.className"></div>
    </ng-template>
</div>
