import { Pipe, PipeTransform } from '@angular/core';
import { ControlPointImpact, RegionApi } from '@libs/common';

import * as turf from '@turf/boolean-point-in-polygon';
import * as turfHelper from '@turf/helpers';

@Pipe({ name: 'controlPointsByRegion' })
export class ControlPointsByRegionPipe implements PipeTransform {
    transform(
        region: RegionApi,
        controlPoints: ControlPointImpact[]
    ): { inside: ControlPointImpact[] } {
        const poly = turfHelper.polygon([region.coords]);
        const insidePoints = controlPoints.filter((point) => {
            const pt = turfHelper.point([point.lon, point.lat]);

            return turf.booleanPointInPolygon(pt, poly);
        });
        return { inside: insidePoints };
    }
}
