import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import Map from 'ol/Map';
import Layer from 'ol/layer/Layer.js';
import {
    selectCurrentGroup,
    selectCurrentMapType,
    selectIsMovingMap,
    selectToGoPosition,
    setIsMovingMap,
    selectVangaTokenIsLoading,
    SharedCoreActions,
    VangaAuthService,
    setLoaded,
    URLS,
} from '@libs/shared-store';
import {
    EMPTY,
    first,
    firstValueFrom,
    from,
    fromEvent,
    iif,
    lastValueFrom,
    Observable,
    of,
    Subject,
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    finalize,
    map,
    pluck,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { LngLat, LngLatLike } from 'mapbox-gl';
import {
    getMarkerState,
    selectCurrentTime,
    selectCurrentTimeIndex,
    selectIsShowOnMapPublicForecast,
    selectIsShowOnMapLensForecast,
    selectLensForecastConfig,
    selectPublicForecastConfig,
    selectTimeRange,
    selectMapClickState,
} from '@cityair/modules/core/store/selectors';
import { Store } from '@ngrx/store';
import { RunPlume, Source, windLayerParams } from '@cityair/modules/plumes/services/run/models';
import { environment } from 'environments/environment';
import { loadWindData, WindVector } from '@cityair/modules/map/components/mapbox/windVector';
import { setControlPointLoading } from '@cityair/modules/plumes/store/actions';
import { setMapClickState } from '@cityair/modules/core/store/actions';

import {
    InfoPins,
    MapCenterAndZoom,
    MapControlPins,
    MapPins,
    MapPolygonInfo,
    SourceLine,
} from '@libs/common';
import {
    createBoundaries,
    NameModules,
    GroupMapSettings,
    markerState,
    GroupExtConfigName,
    TEXTS,
    detectTouchDevice,
    MAP_ACTION_DURATION,
    GroupTilePlayerSettings,
} from '@libs/common';

import { TilePlayer } from '@cityair/modules/map/components/mapbox/tile-player';
import { DomainTilesPlayer } from '@cityair/modules/map/components/mapbox/domain-tiles-player/domain-tiles-player';
import { PlumesTilesPlayer } from '@cityair/modules/plumes/services/plumes-tiles-player/plumes-tiles-player';
import { ExtraLayer } from '@cityair/modules/map/components/mapbox/mapbox.component';
import {
    createTimeSequence,
    createTimeSequencePlumes,
    getColorFromZone,
} from '@cityair/utils/utils';
import { MapboxFacadeService } from '@cityair/modules/map/components/mapbox/mapbox-facade.service';
import { GroupFeaturesService } from '@libs/shared-store';
import { CITY_ZOOM_SHOW_HEXAGON, STND_CITY_ZOOM, IAuthorizeHelper } from '@libs/common';
import { ImageWrapper } from 'ol';
import { selectPlayer } from '@libs/shared-ui';
import {
    currentForecastMmt,
    isValidToken,
    selectForecastCurrentTime,
    selectForecastDataForMap,
    selectForecasts,
    selectForecastTimeRange,
    showLayerOnMap as showForecastLayerOnMap,
} from '@cityair/modules/forecast/store/selectors';
import { playerReady, playerSetManaged, playerSetProgress } from '@libs/shared-ui';
import {
    isActivePlumes,
    isWindShowPlumesOnMap,
    selectActiveRunDates,
    selectActiveRunSources,
    selectPlumesTimeRange,
    selectPlumeTilesParams,
    selectPlumeWindParams,
    showLayerOnMap as showPlumesLayerOnMap,
} from '@cityair/modules/plumes/store/selectors';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Point } from 'ol/geom';
import {
    selectActiveMmtPublicForecast,
    selectIsShowLensForecast,
    selectIsShowPublicForecast,
    setMmtsPublicForecast,
} from '@cityair/modules/core/store/public-forecast/public-forecast.feature';
import { ImageCanvas } from 'ol/source';
import ImageLayer from 'ol/layer/Image';

@Component({
    selector: 'cityair-ol-map',
    templateUrl: './ol-map.component.html',
    styleUrls: ['./ol-map.component.less'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OlMapComponent implements OnChanges {
    @Input() zoom?: number;
    @Input() center?: LngLatLike;

    @Input() postPins?: MapPins;
    @Input() cityPins?: MapPins;
    @Input() notificationSelectedPins?: MapPins;
    @Input() correlationPins?: MapPins;
    @Input() sourceLine?: SourceLine;
    @Input() polygon?: MapPolygonInfo;
    @Input() infoPins?: InfoPins;
    @Input() controlPointPins?: MapControlPins;

    @Input() groupFeaturesLayer?: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
    @Input() pinsAreaData?: Observable<GeoJSON.FeatureCollection<GeoJSON.LineString>>;

    @Output() mapDragEnd = new EventEmitter<MapCenterAndZoom>();
    @Output() zoomChanged = new EventEmitter<{ zoom: number[]; center: number }>();

    style: string;
    forecastNameModule = NameModules.forecast;
    getMarkerState = (id: string) => this.store.select(getMarkerState(id));
    onDestroy$ = new Subject<void>();
    isTouchDevice: boolean;
    mapSettings: GroupMapSettings = {};
    markerState = markerState;
    GroupExtConfigName = GroupExtConfigName;
    showMarkersArea = false;
    attributionsText: string;
    TEXTS = TEXTS;
    getColorFromZone = getColorFromZone;
    selectIsShowOnMapPublicForecast = selectIsShowOnMapPublicForecast;
    selectIsShowOnMapLensForecast = selectIsShowOnMapLensForecast;
    selectCurrentMapStyleType = selectCurrentMapType;
    resizeFn: () => void;
    showMap = false;
    pinTooltipText = '';
    tilePlayers: {
        [layerId: string]: TilePlayer;
    } = {};

    runSources$: Observable<Source[]>;
    plumesAvailable: Observable<boolean>;

    showForecastLayer$: Observable<boolean>;
    showPlumesLayer$: Observable<boolean>;
    showPublicForecastLayer$: Observable<boolean>;
    showLensForecastLayer$: Observable<boolean>;

    domainTilesPlayer: DomainTilesPlayer;
    lensTilesPlayer: DomainTilesPlayer;
    domainTilesPlumesPlayer: DomainTilesPlayer;
    plumesTilesPlayer: PlumesTilesPlayer;
    domainTilesForecastPlayer: DomainTilesPlayer;
    private isAllowClick = false;

    availableExtraLayers: ExtraLayer[] = [];
    enabledExtraLayers: ExtraLayer[] = [];
    mapCenter: number[];
    mapZoom: number = STND_CITY_ZOOM;
    mapExtent: number[];
    currentZoom: number;
    private resizeMapTimeout: NodeJS.Timer;
    public windVector;
    public windLayer: Layer;
    public windData: any;
    public isWindShowOnMap: boolean;
    public isAllowNewMapControl = false;
    public map!: Map;
    public isMovingMap: boolean;
    public mapOffset: number[];

    constructor(
        private mapboxFacadeService: MapboxFacadeService,
        private groupFeaturesService: GroupFeaturesService,
        private vangaAuthService: VangaAuthService,
        public store: Store,
        private ngZone: NgZone
    ) {
        this.runSources$ = this.store.select(selectActiveRunSources);
        this.plumesAvailable = this.store.select(isActivePlumes);
        this.showPublicForecastLayer$ = this.store.select(selectIsShowPublicForecast);
        this.showLensForecastLayer$ = this.store.select(selectIsShowLensForecast);

        fromEvent(window, 'resize')
            .pipe(
                takeUntil(this.onDestroy$),
                filter(() => this.map && this.isWindShowOnMap && this.windVector),
                tap(() => this.windVector?.stopAnimation())
            )
            .subscribe((data) => {
                clearTimeout(this.resizeMapTimeout);
                this.resizeMapTimeout = setTimeout(() => this.updateWindLayer(), 100);
            });
        this.store
            .select(selectCurrentGroup)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((v) => !!v)
            )
            .subscribe((groupInfo) => {
                this.enableMap();
            });
        this.isTouchDevice = detectTouchDevice();
        this.store
            .select(selectToGoPosition)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((v) => !!v)
            )
            .subscribe((position) => {
                if (!this.isMovingMap) {
                    this.store.dispatch(setIsMovingMap({ payload: true }));
                    setTimeout(
                        () => this.store.dispatch(setIsMovingMap({ payload: false })),
                        MAP_ACTION_DURATION
                    );
                    this.ngZone.runOutsideAngular(() => {
                        const view = this.map.getView();
                        const currentCenter = position.center
                            ? [position.center?.lng, position.center?.lat]
                            : view.getCenter();
                        const centerPoint = new Point(currentCenter);
                        if (!position.offset && position.zoom) {
                            view.animate({
                                zoom: position.zoom + 1,
                                center: currentCenter,
                                duration: MAP_ACTION_DURATION,
                            });
                        } else {
                            view.fit(centerPoint, {
                                duration: MAP_ACTION_DURATION,
                                padding: position.offset,
                                maxZoom: position.zoom ? position.zoom + 1 : view.getZoom(),
                            });
                        }
                    });
                }
            });
        store
            .select(selectMapClickState)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((state) => (this.isAllowClick = state?.isAllow));
        this.store
            .select(selectIsMovingMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((isMovingMap) => (this.isMovingMap = isMovingMap));
        store
            .select(isWindShowPlumesOnMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                this.isWindShowOnMap = data;
                if (this.map) {
                    if (!data) {
                        this.map?.removeLayer(this.windLayer);
                    } else if (data && this.windLayer) {
                        this.map?.addLayer(this.windLayer);
                    }
                }
            });
        store
            .select(selectPlumeWindParams)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((v) => !!v),
                distinctUntilKeyChanged('url')
            )
            .subscribe((data) => this.getWindData(data));
        this.setupForecastTilePlayer();
        this.setupPlumesTilePlayer();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes?.center?.isFirstChange()) {
            const center = this.center || this.mapSettings.center;
            const mapCenter = this.convertCenter(center);
            if (mapCenter) {
                this.mapCenter = mapCenter;
            }
        }
        if (changes?.zoom) {
            if (!isNaN(changes.zoom.currentValue)) {
                this.mapZoom =
                    this.zoom ?? this.mapSettings.zoom !== 0 ? this.mapSettings.zoom + 0.5 : 2.5;
            }
        }
    }

    onZoom({ center, zoom }) {
        if (!this.isMovingMap) {
            this.zoomChanged.emit({ center, zoom });
        }
        this.currentZoom = zoom;
        this.showMarkersArea = this.mapSettings.showMarkersArea && zoom > CITY_ZOOM_SHOW_HEXAGON;
    }

    enableMap() {
        this.mapSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.mapSettings
        ) as GroupMapSettings;
        if (this.mapSettings.zoom || this.zoom) {
            this.mapZoom = (this.zoom || this.mapSettings.zoom) + 0.5;
        }
        if (this.mapSettings.center) {
            const center = this.center || this.mapSettings.center;
            this.mapCenter = this.convertCenter(center);
        }

        if (this.mapSettings.bounds) {
            const bounds = this.mapSettings.bounds;
            this.mapExtent = [bounds[0].lng, bounds[0].lat, bounds[1].lng, bounds[1].lat];
        }
        if (this.mapSettings.style) {
            this.style = this.mapSettings.style;
        }

        this.showMap = true;
    }

    public moveStart() {
        this.windVector?.stopAnimation();
    }

    public clickedMap($event: number[]) {
        if (this.isAllowClick && $event.length === 2) {
            this.store.dispatch(
                setMapClickState({
                    isAllow: this.isAllowClick,
                    coordinates: {
                        lat: $event[1],
                        lon: $event[0],
                    },
                })
            );
        }
    }

    public moveEnd({ center, zoom }) {
        this.windVector?.startAnimation();
        if (this.isMovingMap) return;
        const centerMap = new LngLat(center[0], center[1]);
        this.mapDragEnd.emit({
            center: centerMap,
            zoom: zoom,
        });
    }

    private async _createTilePlayer(
        settings: GroupTilePlayerSettings,
        tzOverride?: number,
        timeDelayMs: number = 0
    ) {
        const regenerateTilePlayer = ({ begin, end }) => {
            const { layerId } = settings;

            this.tilePlayers[layerId]?.destroy();

            this.tilePlayers[layerId] = new TilePlayer(
                this.store.pipe(
                    selectCurrentTime,
                    pluck('current'),
                    map((ts) => ts - timeDelayMs)
                ),
                [begin, end].map((d) => new Date(d)),
                settings
            );
        };

        const getTimelineRange = async () => {
            const time = await firstValueFrom(this.store.pipe(selectTimeRange));

            return {
                begin: time.begin - timeDelayMs,
                end: time.end - timeDelayMs,
            };
        };

        let range = await getTimelineRange();

        regenerateTilePlayer(range);

        this.store.pipe(selectCurrentTime, takeUntil(this.onDestroy$)).subscribe(async () => {
            const timelineRange = await getTimelineRange();

            if (range.begin !== timelineRange.begin || range.end !== timelineRange.end) {
                range = timelineRange;
                regenerateTilePlayer(range);
            }
        });
    }

    private async createTilePlayers() {
        const tpSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.tilePlayerSettings
        ) as GroupTilePlayerSettings;

        if (tpSettings) {
            const settings = { ...tpSettings, layerId: 'default' };
            await this._createTilePlayer(settings);
        }
    }

    async mapLoad(map: Map) {
        setTimeout(() => this.store.dispatch(setLoaded()), MAP_ACTION_DURATION * 2); // wait init map settings
        this.map = map;
        await this.createTilePlayers();
        this.createPublicForecastImagePlayer();
        this.createLensImagePlayer();
    }

    private getWindData(params: windLayerParams) {
        const url = `${environment.tile_server_url}/${params.url}.png`;
        const token =
            params.url.indexOf('public') >= 0 ? null : this.vangaAuthService.getAccessToken();
        const loadImage$ = from(loadWindData(url, params.bboxDomain, [-20.0, 20.0], token));
        this.store.dispatch(setControlPointLoading({ payload: true }));
        loadImage$.pipe(catchError((error) => of(null))).subscribe((data) => {
            this.store.dispatch(setControlPointLoading({ payload: false }));
            if (data !== null) {
                if (this.windVector) {
                    this.windVector.setData(data);
                } else {
                    this.createWindLayer(data);
                    this.map?.addLayer(this.windLayer);
                }
            } else {
                this.store.dispatch(
                    SharedCoreActions.setInfoMessage({
                        payload: {
                            id: new Date().valueOf(),
                            messageKey: 'windLoadError',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'error',
                            duration: 10000,
                            showCloseIcon: true,
                            size: 'lg',
                        },
                    })
                );
            }
        });
    }

    private createWindLayer(windData) {
        const _self = this;
        this.windData = windData;
        let canvas;
        const source = new ImageCanvas({
            canvasFunction: (extent, resolution, pixelRatio, size, projection) => {
                if (!canvas) {
                    canvas = document.createElement('canvas');
                    const canvasWidth = size[0].toString();
                    const canvasHeight = size[1].toString();
                    canvas.setAttribute('width', canvasWidth);
                    canvas.setAttribute('height', canvasHeight);
                    const gl = canvas.getContext('webgl');
                    _self.windVector = WindVector(gl, _self.map, true);
                    _self.windVector.setData(_self.windData);
                }
                return canvas;
            },
            projection: 'EPSG:3857',
            ratio: 1,
        });
        this.windLayer = new ImageLayer({
            source,
        });
        this.windLayer.on('prerender', () => {
            _self.windVector.draw();
        });
    }

    private updateWindLayer() {
        this.map?.removeLayer(this.windLayer);
        this.map?.addLayer(this.windLayer);
    }

    private createPublicForecastImagePlayer() {
        this.store
            .select(selectPublicForecastConfig)
            .pipe(
                takeUntil(this.onDestroy$),
                finalize(() => this.domainTilesPlayer?.destroy()),
                filter((domain) => !!domain),
                tap((domain) => {
                    this.domainTilesPlayer?.destroy();

                    this.domainTilesPlayer = new DomainTilesPlayer(
                        'forecast',
                        {
                            url: URLS.publicForecast,
                            domain,
                        },
                        this.store.pipe(selectCurrentTimeIndex),
                        this.store.pipe(selectTimeRange),
                        this.showPublicForecastLayer$
                    );
                    this.store.dispatch(setMmtsPublicForecast({ payload: domain.substances }));
                }),
                switchMap(() => this.store.select(selectActiveMmtPublicForecast)),
                filter((mmt) => !!mmt)
            )
            .subscribe((substance: string) => {
                this.domainTilesPlayer?.selectSubstance(substance);
            });
    }
    private createLensImagePlayer() {
        this.store
            .select(selectLensForecastConfig)
            .pipe(
                takeUntil(this.onDestroy$),
                finalize(() => this.lensTilesPlayer?.destroy()),
                filter((domain) => !!domain),
                tap((domain) => {
                    this.lensTilesPlayer?.destroy();

                    this.lensTilesPlayer = new DomainTilesPlayer(
                        'forecast',
                        {
                            url: URLS.publicForecast,
                            domain,
                        },
                        this.store.pipe(selectCurrentTimeIndex),
                        this.store.pipe(selectTimeRange),
                        this.showLensForecastLayer$
                    );
                }),
                switchMap(() => of('PM10')),
                filter((mmt) => !!mmt)
            )
            .subscribe((substance: string) => {
                this.lensTilesPlayer?.selectSubstance(substance);
            });
    }

    setupForecastTilePlayer() {
        this.store
            .select(selectPlayer)
            .pipe(
                takeUntil(this.onDestroy$),
                pluck('loading'),
                distinctUntilChanged(),
                switchMap((loading) => iif(() => loading, this.showForecastLayer$, EMPTY)),
                filter((showLayerOnMap) => showLayerOnMap),
                switchMap(() => this.store.pipe(selectForecastTimeRange, take(1)))
            )
            .subscribe(({ begin, end }) => {
                const timeSequence = createTimeSequence(begin, end);
                this.preloadLayerImagesNew(this.domainTilesForecastPlayer, timeSequence);
            });

        this.showForecastLayer$ = this.store.select(showForecastLayerOnMap);

        this.showForecastLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                filter((isEnabled) => isEnabled),
                switchMap(() => this.store.select(selectForecasts)),
                filter((forecast) => forecast.length !== 0),
                switchMap(() => this.store.select(isValidToken)),
                filter((isValid) => isValid)
            )
            .subscribe(() => {
                this.ngZone.run(() => {
                    this.createForecastImagePlayer();
                    this.store.dispatch(playerSetManaged({ payload: true }));
                });
            });

        this.showForecastLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                distinctUntilChanged(),
                filter((isEnabled) => !isEnabled)
            )
            .subscribe(() => {
                this.store.dispatch(playerSetManaged({ payload: false }));
            });
    }

    setupPlumesTilePlayer() {
        this.showPlumesLayer$ = this.store.select(showPlumesLayerOnMap);

        this.store
            .select(selectPlayer)
            .pipe(
                takeUntil(this.onDestroy$),
                pluck('loading'),
                distinctUntilChanged(),
                switchMap((loading) => iif(() => loading, this.showPlumesLayer$, EMPTY)),
                filter((showLayerOnMap) => showLayerOnMap),
                switchMap(() => this.store.pipe(selectPlumesTimeRange, take(1)))
            )
            .subscribe(({ begin, end }) => {
                const timeSequence = createTimeSequencePlumes(begin, end);
                this.preloadLayerImagesNew(this.plumesTilesPlayer, timeSequence);
            });

        this.showPlumesLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                filter((isEnabled) => isEnabled),
                switchMap(() => this.store.select(selectActiveRunDates)),
                filter((run) => !!run),
                distinctUntilKeyChanged('id')
            )
            .subscribe((run) => {
                this.createPlumesPlayer(run);
                this.store.dispatch(playerSetManaged({ payload: true }));
            });

        this.showPlumesLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                distinctUntilChanged(),
                filter((isEnabled) => !isEnabled)
            )
            .subscribe(() => {
                this.store.dispatch(playerSetManaged({ payload: false }));
            });
    }

    async preloadLayerImagesNew(
        domainTilesPlayer: DomainTilesPlayer | PlumesTilesPlayer,
        timeSequence: Set<number>
    ) {
        await domainTilesPlayer.preloadImages(timeSequence, (percent: number) =>
            this.store.dispatch(playerSetProgress({ progress: percent / 100 }))
        );

        this.store.dispatch(playerReady());
    }

    private createForecastImagePlayer() {
        this.store
            .select(selectForecastDataForMap)
            .pipe(
                filter((v) => v !== null),
                map(({ groupId, domain }) => ({
                    groupId,
                    domain,
                })),
                tap((data) => {
                    this.domainTilesForecastPlayer?.destroy();
                    this.domainTilesForecastPlayer = new DomainTilesPlayer(
                        'forecast',
                        {
                            url: URLS.tileUrl,
                            domain: {
                                ...data.domain,
                                slug: [data.groupId, 'forecast', data.domain.slug].join('/'),
                            },
                        },
                        this.store.pipe(
                            selectForecastCurrentTime,
                            filter((v) => !!v)
                        ),
                        this.store.pipe(selectForecastTimeRange),
                        this.showForecastLayer$,
                        this.tilesAuthorizerHelper
                    );
                }),
                switchMap(() => this.store.select(currentForecastMmt)),
                filter((mmt) => !!mmt)
            )
            .subscribe((substance: string) => {
                this.domainTilesForecastPlayer.selectSubstance(substance);
            });
    }

    tilesAuthorizerHelper: IAuthorizeHelper = {
        getAuthHeader: () => ({
            Authorization: `Bearer ${this.vangaAuthService.getAccessToken()}`,
        }),
        refreshToken: async () => {
            this.store.dispatch(SharedCoreActions.vangaTokenRefresh());

            const vangaTokenIsReady = this.store.select(selectVangaTokenIsLoading).pipe(
                filter((isLoading) => !isLoading),
                take(1)
            );

            await lastValueFrom(vangaTokenIsReady);
        },
    };

    private createPlumesPlayer(run: RunPlume) {
        this.store
            .select(selectCurrentGroup)
            .pipe(first())
            .subscribe((currentGroup) => {
                this.plumesTilesPlayer?.destroy();
                this.plumesTilesPlayer = new PlumesTilesPlayer(
                    'plumes',
                    {
                        url: URLS.tileUrl,
                        domain: {
                            slug: [currentGroup.id, 'plumes', run.id].join('/'),
                            substances: [],
                            coordinates: createBoundaries(run.domain.bbox),
                            extent: run.domain.bbox,
                        },
                        timeStep: run?.step_minutes,
                    },
                    this.store.select(selectPlumeTilesParams).pipe(filter((params) => !!params)),
                    this.store.pipe(selectPlumesTimeRange),
                    this.showPlumesLayer$,
                    this.tilesAuthorizerHelper
                );
            });
    }

    authorizeTileRequest = (image: ImageWrapper, url: string) => {
        const token = this.vangaAuthService.getAccessToken();
        const client = new XMLHttpRequest();
        client.responseType = 'blob';
        client.open('GET', url);
        if (url.startsWith(URLS.tileUrl)) {
            client.setRequestHeader('Authorization', `Bearer ${token}`);
        }
        client.onload = function () {
            // @ts-ignore
            image.getImage().src = URL.createObjectURL(client.response);
        };
        client.send();
    };

    private convertCenter(center: any): number[] {
        if (
            center instanceof LngLat ||
            (center instanceof Object &&
                center.hasOwnProperty('lng') &&
                center.hasOwnProperty('lat'))
        ) {
            return [center?.lng, center?.lat];
        } else if (center instanceof Array) {
            return center;
        }
    }
}
