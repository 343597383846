<div class="analytic scroll">
    <analityc-header [config]="config"></analityc-header>
    <cs-back-button class="analytic__back" data-cy="indoor-stat-back" (click)="back.emit()">
        Back to summary for all offices
    </cs-back-button>

    <div class="analytic__header">
        <div>
            <div class="analytic__title" data-cy="indoor-stat">{{ postName }}</div>
            <div class="analytic__today">{{ toDay }}</div>
        </div>
        <!--        <div class="analytic__config" data-cy="indoor-stat-btn" (click)="showConfig.emit()"></div>-->
    </div>
    <div class="analytic__name"></div>
    <div
        *ngIf="data && !isEmptyData"
        class="analytic__charts_wrapper"
        data-cy="indoor-analytic-have-data"
    >
        <div class="chart-wrapper">
            <div class="chart-wrapper__top">
                <div class="chart-wrapper__title">
                    {{ TEXTS.INDOOR.comfort }}
                    <ca-info-icon
                        [active]="true"
                        (click)="showComfortPopup = !showComfortPopup"
                    ></ca-info-icon>
                </div>
                <div>
                    <div class="chart-wrapper__value1">{{ TEXTS.INDOOR.title1 }}</div>
                    <div class="chart-wrapper__value2">
                        {{ percentData[0].data[percentData[0].data.length - 1] }}<i>%</i>
                    </div>
                </div>
            </div>
            <percent-chart [data]="percentData" [labels]="dailyLabels"></percent-chart>
        </div>

        <div class="chart-wrapper">
            <div class="chart-wrapper__top">
                <div class="chart-wrapper__title">
                    {{ TEXTS.INDOOR.safety }}
                    <ca-info-icon
                        data-cy="info-icon-2"
                        (click)="showSafetyPopup = !showSafetyPopup"
                    ></ca-info-icon>
                </div>
                <div class="chart-wrapper__value4">
                    <span class="chart-wrapper__value3">{{ TEXTS.INDOOR.title2 }}</span>
                    <value-circle
                        [value]="dailyData[0].data[dailyData[0].data.length - 1]"
                        [color]="
                            getIndoorColor(
                                indoorZones[MeasureScheme.default][MMT_INDOOR.indoorAqi],
                                dailyData[0].data[dailyData[0].data.length - 1]
                            )
                        "
                        [numberAfterDot]="2"
                    ></value-circle>
                </div>
            </div>

            <daily-chart [data]="dailyData" [labels]="dailyLabels"></daily-chart>
        </div>

        <div class="aqi_chart">
            <div class="aqi_chart__title">Average indoor air quality by hour of a day</div>
            <div class="aqi_chart__tabs">
                <switch-item
                    [tabs]="tabs"
                    [currentTab]="selectedTab"
                    (action)="showSelectedTab($event)"
                ></switch-item>
            </div>

            <aqi-chart
                [data]="selectedTab.type === 1 ? aqiWeek : aqiMonth"
                [labels]="selectedTab.type === 1 ? hoursWeek : hoursMonth"
            ></aqi-chart>
        </div>
    </div>
    <div *ngIf="data && isEmptyData" class="analytic__no_data">
        <div class="wrapper">
            <non-data-screen></non-data-screen>
            <div class="desc">{{ TEXTS.INDOOR.emptyStatInfo }}</div>
        </div>
    </div>
    <analytic-loader *ngIf="!data"></analytic-loader>
</div>

<stats-popup
    [showComfort]="showComfortPopup"
    [showSafety]="showSafetyPopup"
    (hideComfort)="showComfortPopup = false"
    (hideSafety)="showSafetyPopup = false"
    (openPopup)="showPopup.emit()"
></stats-popup>
