import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { TEXTS } from '@libs/common';

@Component({
    selector: 'cs-aqi-details-tooltip',
    templateUrl: 'aqi-details-tooltip.component.html',
    styleUrls: ['aqi-details-tooltip.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AqiDetailsTooltipComponent {
    @Output() openDetailsLink = new EventEmitter<void>();

    TEXTS = TEXTS;

    openDetails(e: Event) {
        e.preventDefault();
        this.openDetailsLink.emit();
    }
}
