<ng-template #popupOutlet>
    <stnd-ask-popup-three-questions
        *ngIf="showConfirmationPopup"
        [texts]="textQuestions"
        [close]="onClosePopup"
        [extraAction]="openCheckpoint"
    ></stnd-ask-popup-three-questions>
</ng-template>

<div class="edit_control_point">
    <div class="edit_control_point__title_wrapper">
        <header-page [textObject]="{ titlePage: title }"></header-page>
    </div>
    <div class="edit_control_point__config-name" *ngIf="currentConfig.name">{{currentConfig.name}}</div>
    <div class="edit_control_point__body">
        <form [formGroup]="editControlPoint" novalidate>
            <div class="edit_control_point__wrapper_input_name">
                <ca-input
                    type="text"
                    controlName="name"
                    [form]="editControlPoint"
                    [valueForm]="name.value"
                    [label]="textsPlumes.title"
                    [textError]="getError('name')"
                ></ca-input>
            </div>
            <div class="edit_control_point__subtitle">{{ textsPlumes.coordinates }}</div>
            <div class="edit_control_point__info-wrapper">
                <div class="edit_control_point__info">
                    <svg
                        class="edit_control_point__info-icon"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="20"
                            y="20"
                            width="20"
                            height="20"
                            rx="10"
                            transform="rotate(-180 20 20)"
                            fill="url(#paint0_linear_9546_68457)"
                        />
                        <path
                            d="M10 15C10.5523 15 11 14.5523 11 14V10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10 15Z"
                            fill="white"
                        />
                        <path
                            d="M10 7C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7Z"
                            fill="white"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_9546_68457"
                                x1="40"
                                y1="20"
                                x2="22.0624"
                                y2="41.7045"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#6795ED" />
                                <stop offset="0.368408" stop-color="#7387E6" />
                                <stop offset="1" stop-color="#9369D7" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div class="edit_control_point__info-description" [innerHTML]="infoText"></div>
                </div>
            </div>
            <div class="edit_control_point__input_coordinates">
                <input-for-coordinate
                    type="text"
                    controlName="lat"
                    [form]="editControlPoint"
                    [valueForm]="lat.value"
                    [label]="translateText.latLabel"
                    [textError]="getError('lat')"
                    [positioning]="'left'"
                ></input-for-coordinate>
                <input-for-coordinate
                    type="text"
                    controlName="lon"
                    [form]="editControlPoint"
                    [valueForm]="lon.value"
                    [label]="translateText.lngLabel"
                    [textError]="getError('lon')"
                    [positioning]="'right'"
                ></input-for-coordinate>
            </div>
            <ng-container *ngIf="showDomainError">
                <div class="domain-error">
                    {{translateText.domainError}}
                </div>
            </ng-container>
        </form>
    </div>
    <div class="edit_control_point__action_btn_wrapper">
        <ca-button type="cancel" (clickAction)="openCheckpoint()">
            {{ texts.cancel }}
        </ca-button>
        <ca-button
            type="primary"
            [disabled]="editControlPoint.pristine || editControlPoint.invalid"
            (clickAction)="save()"
        >
            <ca-spinner *ngIf="isLoading; else showContentSave"></ca-spinner>
            <ng-template #showContentSave>{{ texts.save }}</ng-template>
        </ca-button>
    </div>
</div>
