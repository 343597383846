import { createReducer, on } from '@ngrx/store';
import {
    addNotifiablePost,
    changeCurrentFormType,
    changeCurrentNotificationType,
    changeCurrentPage,
    changeCurrentSettingsType,
    closeSettingForm,
    formNotificationSettingsError,
    createNotificationSettingsSuccess,
    deleteNotificationSettingsSuccess,
    goToEvent,
    isLoadingEventsList,
    isLoadingForm,
    openSettingForm,
    removeNotifiablePost,
    setFilter,
    setNotificationEvents,
    setNotificationsEventsDevice,
    setNotificationsEventsPdk,
    setNotificationsSettings,
    updateNotifiablePosts,
    updateNotification,
    editNotificationSettingsSuccess,
    editNotificationSettingsActiveSuccess,
} from './actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
    AnalysisEvent,
    BaseNotificationEvent,
    ITimeseriesDataItem,
    NotificationSettings,
    NotificationType,
} from '@libs/common';
import {
    initQueryState,
    NOTIFICATIONS_EVENT_COUNT,
} from '@cityair/modules/notifications/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { timelineAdapter } from '@libs/shared-store';

export const settingsNotifyAdapter: EntityAdapter<NotificationSettings> =
    createEntityAdapter<NotificationSettings>();

export const eventNotifyAdapter: EntityAdapter<BaseNotificationEvent> =
    createEntityAdapter<BaseNotificationEvent>();

export interface QueryParamsNotify {
    isAvailableLoadMore: boolean;
    queryNumber: number;
}
export enum NotificationPages {
    EVENT_FEED = 'event-feed',
    SUBSCRIPTIONS = 'subscriptions',
    SETTINGS = 'settings',
}
export interface NotificationsState {
    notifiablePosts: string[];
    settings: EntityState<NotificationSettings>;
    eventsPdk: EntityState<BaseNotificationEvent>;
    eventsDevice: EntityState<BaseNotificationEvent>;
    queryState: {
        pdk: QueryParamsNotify;
        device: QueryParamsNotify;
    };
    currentEventType: NotificationType;
    currentSettingsType: NotificationType;
    currentFormType: NotificationType;
    isLoadingList: boolean;
    filter: { query: string };
    currentPage: NotificationPages;
    previousPage: NotificationPages;
    currentSettingForm: NotificationSettings;
    selectedEvent: BaseNotificationEvent;
    isLoadingForm: boolean;
    formError: HttpErrorResponse;
    isLoadingAnalysisEvent: boolean;
    analysisPostsTimeLine: EntityState<ITimeseriesDataItem>;
    analysisPostsDates: string[];
    currentAnalysisEvent: AnalysisEvent;
}

const initialState: NotificationsState = {
    notifiablePosts: [],
    settings: settingsNotifyAdapter.getInitialState(),
    eventsPdk: eventNotifyAdapter.getInitialState(),
    eventsDevice: eventNotifyAdapter.getInitialState(),
    queryState: initQueryState,
    currentEventType: NotificationType.pdkExcess,
    currentSettingsType: NotificationType.pdkExcess,
    currentFormType: NotificationType.pdkExcess,
    isLoadingList: true,
    filter: { query: null },
    currentPage: NotificationPages.EVENT_FEED,
    previousPage: null,
    currentSettingForm: null,
    selectedEvent: null,
    isLoadingForm: false,
    formError: null,
    isLoadingAnalysisEvent: false,
    analysisPostsTimeLine: timelineAdapter.getInitialState(),
    analysisPostsDates: [],
    currentAnalysisEvent: null,
};

export const notificationsReducer = createReducer(
    initialState,
    on(updateNotifiablePosts, (state: NotificationsState, { postIds }) => ({
        ...state,
        notifiablePosts: postIds,
    })),
    on(addNotifiablePost, (state: NotificationsState, { postId }) => ({
        ...state,
        notifiablePosts: [...state.notifiablePosts, postId],
    })),
    on(removeNotifiablePost, (state: NotificationsState, { postId }) => ({
        ...state,
        notifiablePosts: state.notifiablePosts.filter((p) => p !== postId),
    })),
    on(setNotificationsSettings, (state: NotificationsState, { payload }) => {
        const response = payload as NotificationSettings[];
        const settings = settingsNotifyAdapter.setMany(response, state.settings);
        return {
            ...state,
            settings,
        };
    }),
    on(setNotificationsEventsPdk, (state: NotificationsState, { payload }) => {
        const response = payload as BaseNotificationEvent[];
        const eventsPdk = eventNotifyAdapter.upsertMany(response, state.eventsPdk);
        const queryNumber =
            state.queryState.pdk.queryNumber === 0
                ? state.queryState.pdk.queryNumber + 1
                : state.queryState.pdk.queryNumber;

        return {
            ...state,
            eventsPdk,
            isLoadingList: false,
            queryState: {
                ...state.queryState,
                pdk: {
                    ...state.queryState.pdk,
                    queryNumber,
                    isAvailableLoadMore:
                        state.queryState.pdk.queryNumber === 0
                            ? response.length < NOTIFICATIONS_EVENT_COUNT
                                ? false
                                : true
                            : state.queryState.pdk.isAvailableLoadMore,
                },
            },
        };
    }),
    on(setNotificationsEventsDevice, (state: NotificationsState, { payload }) => {
        const response = payload as BaseNotificationEvent[];
        const eventsDevice = eventNotifyAdapter.upsertMany(response, state.eventsDevice);
        const queryNumber =
            state.queryState.device.queryNumber === 0
                ? state.queryState.device.queryNumber + 1
                : state.queryState.device.queryNumber;

        return {
            ...state,
            eventsDevice,
            isLoadingList: false,
            queryState: {
                ...state.queryState,
                device: {
                    ...state.queryState.device,
                    queryNumber,
                    isAvailableLoadMore:
                        state.queryState.device.queryNumber === 0
                            ? response.length < NOTIFICATIONS_EVENT_COUNT
                                ? false
                                : true
                            : state.queryState.device.isAvailableLoadMore,
                },
            },
        };
    }),
    on(changeCurrentNotificationType, (state: NotificationsState, { payload }) => ({
        ...state,
        currentEventType: payload,
    })),
    on(changeCurrentSettingsType, (state: NotificationsState, { payload }) => ({
        ...state,
        currentSettingsType: payload,
    })),
    on(changeCurrentFormType, (state: NotificationsState, { payload }) => ({
        ...state,
        currentFormType: payload,
    })),
    on(isLoadingEventsList, (state: NotificationsState, { payload }) => ({
        ...state,
        isLoadingList: payload,
    })),
    on(updateNotification, (state: NotificationsState, { payload }) => {
        const current = { ...payload, is_viewed: true };
        if (payload.type === NotificationType.pdkExcess) {
            const eventsPdk = eventNotifyAdapter.upsertOne(current, state.eventsPdk);
            return {
                ...state,
                eventsPdk,
            };
        } else {
            const eventsDevice = eventNotifyAdapter.upsertOne(current, state.eventsDevice);
            return {
                ...state,
                eventsDevice,
            };
        }
    }),
    on(setFilter, (state: NotificationsState, { payload }) => ({
        ...state,
        filter: { ...state.filter, query: payload },
    })),
    on(setNotificationEvents, (state: NotificationsState, { payload }) => {
        const response = payload.response as BaseNotificationEvent[];
        if (payload.params?.type === NotificationType.pdkExcess) {
            const response = payload.response as BaseNotificationEvent[];
            const eventsPdk = eventNotifyAdapter.upsertMany(response, state.eventsPdk);
            const queryNumber = state.queryState.pdk.queryNumber + 1;
            return {
                ...state,
                eventsPdk,
                isLoadingList: false,
                queryState: {
                    ...state.queryState,
                    pdk: {
                        ...state.queryState.pdk,
                        queryNumber,
                        isAvailableLoadMore:
                            response.length < NOTIFICATIONS_EVENT_COUNT ? false : true,
                    },
                },
            };
        } else if (payload.params?.type === NotificationType.deviceIncident) {
            const eventsDevice = eventNotifyAdapter.upsertMany(response, state.eventsDevice);
            const queryNumber = state.queryState.device.queryNumber + 1;
            return {
                ...state,
                eventsDevice,
                isLoadingList: false,
                queryState: {
                    ...state.queryState,
                    device: {
                        ...state.queryState.device,
                        queryNumber,
                        isAvailableLoadMore:
                            response.length < NOTIFICATIONS_EVENT_COUNT ? false : true,
                    },
                },
            };
        }
    }),
    on(changeCurrentPage, (state: NotificationsState, { payload }) => ({
        ...state,
        currentPage: payload,
    })),
    on(openSettingForm, (state: NotificationsState, { settings_type, currentSetting }) => ({
        ...state,
        currentPage: NotificationPages.SETTINGS,
        previousPage: state.currentPage,
        currentFormType: settings_type,
        currentSettingForm: currentSetting,
    })),
    on(closeSettingForm, (state: NotificationsState, { settings_type }) => ({
        ...state,
        currentPage: state.previousPage ?? NotificationPages.EVENT_FEED,
        previousPage: null,
        currentSettingForm: null,
    })),
    on(goToEvent, (state: NotificationsState, { payload }) => ({
        ...state,
        selectedEvent: payload,
    })),
    on(isLoadingForm, (state: NotificationsState, { payload }) => ({
        ...state,
        isLoadingForm: payload,
    })),
    on(createNotificationSettingsSuccess, (state: NotificationsState, { payload }) => {
        const response = payload as NotificationSettings;
        const settings = settingsNotifyAdapter.addOne(response, state.settings);
        return {
            ...state,
            settings,
            isLoadingForm: false,
        };
    }),
    on(formNotificationSettingsError, (state: NotificationsState, { payload }) => ({
        ...state,
        formError: payload,
    })),
    on(editNotificationSettingsSuccess, (state: NotificationsState, { payload }) => {
        const response = payload as NotificationSettings;
        const settings = settingsNotifyAdapter.upsertOne(response, state.settings);
        return {
            ...state,
            settings,
            isLoadingForm: false,
        };
    }),
    on(editNotificationSettingsActiveSuccess, (state: NotificationsState, { payload }) => {
        const response = payload as NotificationSettings;
        const settings = settingsNotifyAdapter.upsertOne(response, state.settings);
        return {
            ...state,
            settings,
        };
    }),
    on(deleteNotificationSettingsSuccess, (state: NotificationsState, { payload }) => {
        const currentSettings = payload;
        const settings = settingsNotifyAdapter.removeOne(payload.id, state.settings);
        if (payload.type === NotificationType.pdkExcess) {
            const eventsPdk = eventNotifyAdapter.removeAll(state.eventsPdk);
            return {
                ...state,
                eventsPdk,
                settings,
                queryState: {
                    ...state.queryState,
                    pdk: {
                        ...state.queryState.pdk,
                        queryNumber: 0,
                        isAvailableLoadMore: true,
                    },
                },
            };
        } else if (payload.type === NotificationType.deviceIncident) {
            const eventsDevice = eventNotifyAdapter.removeAll(state.eventsDevice);
            return {
                ...state,
                eventsDevice,
                settings,
                queryState: {
                    ...state.queryState,
                    device: {
                        ...state.queryState.device,
                        queryNumber: 0,
                        isAvailableLoadMore: true,
                    },
                },
            };
        }
    })
);
