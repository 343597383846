<ca-login-page-container [ngSwitch]="clientConfig.name" [inviteProps]="inviteData">
    <ca-mnrecology-login-page *ngSwitchCase="'mnrecology'"></ca-mnrecology-login-page>
    <ca-nornickel-login-page *ngSwitchCase="'nornickel'"></ca-nornickel-login-page>
    <ca-sakhalin-login-page *ngSwitchCase="'sakhalin'"></ca-sakhalin-login-page>
    <ca-spb112-login-page *ngSwitchCase="'spb112'"></ca-spb112-login-page>

    <ng-container *ngSwitchDefault>
        <ng-container *ngIf="isAirVoiceDomain else basicTemplate">
            <ca-airvoice-login-page></ca-airvoice-login-page>
        </ng-container>
        <ng-template  #basicTemplate>
            <ca-login-page *ngIf="!isMobile"></ca-login-page>
            <ca-login-page-m *ngIf="isMobile"></ca-login-page-m>
        </ng-template>
    </ng-container>
</ca-login-page-container>
