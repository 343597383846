import { Component, Input } from '@angular/core';

@Component({
    selector: 'item-radio',
    template: `<div class="item_radio {{ check ? 'item_radio-check' : '' }}"></div>`,
    styleUrls: ['item-radio.component.less'],
})
export class ItemRadioComponent {
    @Input() check: boolean;
}
