<div class="indoor__wrapper">
    <div class="indoor__top">
        <cityscreen-mobile-profile></cityscreen-mobile-profile>
        <indoor-calendar-mobile
            *ngIf="!isEmptyPosts"
            [timeBegin]="store.select(indoorSelectors.dateBegin) | async"
            [timeEnd]="store.select(indoorSelectors.dateEnd) | async"
            (updateDateRange)="updateTimeRange($event)"
        ></indoor-calendar-mobile>
    </div>

    <div class="indoor__center" *ngIf="configMap; else noMapTemplate">
        <floor-map [configMap]="configMap"></floor-map>

        <div class="indoor__timeline">
            <timeline-panel
                [isCityMode]="store.select(selectIsCityMode) | async"
                [currentTime]="store.select(indoorSelectors.timeMs) | async"
                [dates]="store.select(indoorSelectors.timeSequences) | async"
                [features]="
                    store.select(indoorSelectors.getActivePostWithMeasurementsAsFeatures) | async
                "
                [dateTime]="store.select(indoorSelectors.time) | async"
                [initSelectMeasurement]="
                    indoorNamesToGlobal[store.select(indoorSelectors.currentMmt) | async]
                "
                [showPlayButton]="false"
                [measureScheme]="store.select(selectMeasureScheme) | async"
                [timezone]="store.select(selectUserTimezone) | async"
                (changeTimeIndex)="store.dispatch(indoorUpdateTimeIndex({ index: $event }))"
            ></timeline-panel>
        </div>
    </div>
    <ng-template #noMapTemplate>
        <div class="indoor__no-map-wrapper">
            <div class="no-map-img"></div>
            <div class="no-map-desc">{{ TEXTS.INDOOR.moMapMobile }}</div>
        </div>
    </ng-template>

    <indoor-loading-indicator
        *ngIf="store.select(indoorSelectors.isPointsLoading) | async"
    ></indoor-loading-indicator>
    <div
        class="indoor__empty_posts"
        *ngIf="isEmptyPosts && (store.select(indoorSelectors.isPointsLoading) | async) === false"
    >
        <div class="indoor__top">
            <cityscreen-mobile-profile></cityscreen-mobile-profile>
        </div>
        <div class="empty-post-wrapper">
            <non-data-screen></non-data-screen>
            <div class="empty-post-desc">{{ TEXTS.INDOOR.emptyPosts }}</div>
        </div>
    </div>
</div>
