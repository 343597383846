import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { isRU, ControlPointImpactRequest } from '@libs/common';
import { VangaAuthService, URLS } from '@libs/shared-store';
import {
    CityDataResponse,
    ExcessStatisticsResponse,
    ImpactStatisticsResponse,
} from './api-model-impact';

@Injectable({
    providedIn: 'root',
})
export class ImpactApiService {
    private accessToken = '';
    private lang = isRU ? 'ru' : 'en';
    constructor(private http: HttpClient, private vangaAuthService: VangaAuthService) {}

    getRuns(params: HttpParamsOptions): Observable<any> {
        const httpOptions = this.getHttpOptions(params);

        return this.http.get(URLS.impactRun, httpOptions);
    }

    getConfig(params: HttpParamsOptions): Observable<any> {
        const httpOptions = this.getHttpOptions(params);

        return this.http.get(URLS.impactConfig, httpOptions);
    }

    updateConfig(params): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const urlPath = URLS.impactConfig + params.id + '/';

        return this.http.patch(urlPath, params, httpOptions);
    }

    getControlPoints(groupId: string): Observable<any> {
        const params: HttpParamsOptions = { fromObject: { group_id: groupId } };
        const httpOptions = this.getHttpOptions(params);

        return this.http.get(URLS.controlPoints, httpOptions);
    }

    getControlPointsTimeline(data: { id: number; params: HttpParamsOptions }): Observable<any> {
        const httpOptions = this.getHttpOptions(data.params);
        const url = URLS.impactRun + data.id + '/control_points/';

        return this.http.get(url, httpOptions);
    }

    getStationData(data: { id: string; params: HttpParamsOptions }): Observable<any> {
        const token = this.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': this.lang,
            },
            params: new HttpParams(data.params),
        };
        const url = URLS.post + data.id + '/Timeline';

        return this.http.get(url, httpOptions);
    }

    getStationContributionData(data: { id: string; params: HttpParamsOptions }): Observable<any> {
        const token = this.vangaAuthService.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': this.lang,
            },
            params: new HttpParams(data.params),
        };
        const url = URLS.timelineContribution(data.id);
        return this.http.get(url, httpOptions);
    }

    getCityData(params: HttpParamsOptions): Observable<CityDataResponse> {
        const token = this.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': this.lang,
            },
            params: new HttpParams(params),
        };
        const url = URLS.city;

        return this.http.get<CityDataResponse>(url, httpOptions);
    }

    addControlPoint(data: ControlPointImpactRequest): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const url = URLS.controlPoints;

        return this.http.post(url, data, httpOptions);
    }

    updateControlPoint(data: ControlPointImpactRequest): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const urlPath = URLS.controlPoints + data.id + '/';

        return this.http.patch(urlPath, data, httpOptions);
    }

    deleteControlPoint(id: number | string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        const urlPath = URLS.controlPoints + id + '/';

        return this.http.delete(urlPath, httpOptions);
    }

    getStatsSource(id: number, params: HttpParamsOptions): Observable<ImpactStatisticsResponse> {
        const token = this.vangaAuthService.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': this.lang,
            },
            params: new HttpParams(params),
        };
        const url = URLS.impactConfig + id + '/stats/';

        return this.http.get<ImpactStatisticsResponse>(url, httpOptions);
    }

    getStatsRegion(id: string, params: HttpParamsOptions): Observable<ExcessStatisticsResponse> {
        const token = this.vangaAuthService.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': this.lang,
            },
            params: new HttpParams(params),
        };
        const url = URLS.statsRegion + id + '/';

        return this.http.get<ExcessStatisticsResponse>(url, httpOptions);
    }

    getStatsControlPoint(id: number, params: HttpParamsOptions): Observable<ExcessStatisticsResponse> {
        const token = this.vangaAuthService.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': this.lang,
            },
            params: new HttpParams(params),
        };
        const url = URLS.statsControlPoint + id + '/';

        return this.http.get<ExcessStatisticsResponse>(url, httpOptions);
    }

    private getHttpOptions(data?: HttpParamsOptions): any {
        const token = this.vangaAuthService.getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': this.lang,
        };

        if (data) {
            const params = new HttpParams(data);
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }

    private getAccessToken() {
        return this.accessToken || (this.accessToken = localStorage.getItem('token'));
    }
}
