import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export type ButtonType = 'primary' | 'secondary';

@Component({
    selector: 'dark-button',
    templateUrl: 'dark-button.component.html',
    styleUrls: ['dark-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DarkButtonComponent {
    @Input() type: ButtonType = 'primary';
}
