import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { TEXTS, PM10 } from '@libs/common';
import { LayerButtonIconType, DomainConfigType } from '@libs/common';
import { RoutingService } from '@cityair/modules/core/routing.service';
import { intervalUpdate, togglePublicForecast } from '@cityair/modules/core/store/actions';
import { toggleLayerOnMap } from '@cityair/modules/plumes/store/actions';
import { currentPlumesMmt, showLayerOnMap } from '@cityair/modules/plumes/store/selectors';
import { takeUntil } from 'rxjs/operators';

import {
    selectIsCityMode,
    selectIsAllowPublicForecast,
    selectPublicForecastConfig,
} from '@cityair/modules/core/store/selectors';

import { getModulePageConfig } from '@libs/shared-store';
import {
    FORESACT_BEGIN_TIME,
    FORESACT_END_TIME,
    selectActiveMmtPublicForecast,
    selectIsShowPublicForecast,
    selectMmtsPublicForecast,
    setActiveMmt,
    toggleShowPublicForecast,
} from '@cityair/modules/core/store/public-forecast/public-forecast.feature';
import * as moment from 'moment-timezone';
import { getStndTimeBegin, getStndTimeEnd } from '@cityair/libs/shared/utils/config';

@Component({
    selector: 'cs-top-right-elements-m',
    styleUrls: ['top-right-elements-m.component.less'],
    templateUrl: 'top-right-elements-m.component.html',
})
export class TopRightElementsMobile implements OnInit, OnDestroy {
    TEXTS = TEXTS;

    onDestroy$ = new Subject<void>();

    isCityMode$ = this.store.select(selectIsCityMode);
    moduleConfig$ = this.store.select(getModulePageConfig);

    LayerButtonIconType = LayerButtonIconType;
    currentMeasure = PM10;
    showPlumesLayer: boolean;
    isActiveModelling = false;
    forecastConfig: DomainConfigType;
    selectIsAllowPublicForecast = selectIsAllowPublicForecast;
    selectMmtsPublicForecast = selectMmtsPublicForecast;
    selectActiveMmtPublicForecast = selectActiveMmtPublicForecast;

    setActiveMmt = setActiveMmt;

    constructor(readonly routingService: RoutingService, public store: Store) {
        this.store
            .select(currentPlumesMmt)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => (this.currentMeasure = data));
        this.store
            .select(showLayerOnMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => (this.showPlumesLayer = data));
        store
            .select(selectIsShowPublicForecast)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((isActive) => (this.isActiveModelling = isActive));
        store
            .select(selectPublicForecastConfig)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((config) => (this.forecastConfig = config));
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.toggleForecast(false);
    }

    togglePlumesLayer() {
        this.store.dispatch(toggleLayerOnMap({ payload: !this.showPlumesLayer }));
    }

    offPlume() {
        this.store.dispatch(toggleLayerOnMap({ payload: false }));
    }

    updateDateRange({ begin, end }) {
        this.store.dispatch(intervalUpdate({ begin, end }));
    }

    enableModelling() {
        this.toggleForecast(true);
    }

    disableModelling() {
        this.toggleForecast(false);
    }

    private toggleForecast(isActive) {
        if (isActive) {
            const begin = this.forecastConfig?.start
                ? moment(this.forecastConfig.start).valueOf()
                : FORESACT_BEGIN_TIME;
            const end = this.forecastConfig?.end
                ? moment(this.forecastConfig.end).valueOf()
                : FORESACT_END_TIME;
            this.store.dispatch(
                intervalUpdate({
                    begin,
                    end,
                })
            );
        } else {
            this.store.dispatch(
                intervalUpdate({
                    begin: getStndTimeBegin(),
                    end: getStndTimeEnd(),
                })
            );
        }
        this.store.dispatch(togglePublicForecast({ payload: isActive }));
        this.store.dispatch(toggleShowPublicForecast({ payload: isActive }));
    }
}
