import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({ name: 'cityPopulation' })
export class CityPopulationPipe implements PipeTransform {
    transform(population: number, locale: string = 'ru'): string {
        if (population < 1000) {
            const formatValue = formatNumber(population, locale, '1.0-0');
            return `~ ${formatValue}`;
        } else if (population < 1000000) {
            const label = locale === 'ru' ? 'тыс.' : 'ths.';
            const formatValue = formatNumber(population / 1000, locale, '1.1-1');
            return `~ ${formatValue} ${label}`;
        } else {
            const label = locale === 'ru' ? 'млн.' : 'mm.';
            const formatValue = formatNumber(population / 1000000, locale, '1.1-1');
            return `~ ${formatValue} ${label}`;
        }
    }
}
