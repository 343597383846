<div class="data-quality">
    <cross-button class="data-quality__close-btn" size="large" (click)="close()"></cross-button>
    <nav class="scroll-gray">
        <ul>
            <li
                *ngFor="let menuItem of menuItems; index as i"
                [class.current-section]="selectedSection === i"
                (click)="showSection(i)"
            >
                {{ menuItem }}
            </li>
        </ul>
    </nav>
    <article class="scroll-gray" #article>
        <h1 class="data-quality__section-header">{{textQualityDataInfo.menuItems.dataQuality}}</h1>
        <section class="data-quality__section">
            <p class="data-quality__description_color-grey7">
                {{textQualityDataInfo.sections.dataQuality}}:
                <a [href]="rsiLinks.cityair" target=”_blank”>{{textQualityDataInfo.sections.links.cityair}}</a>,
                <a [href]="rsiLinks.gModule" target=”_blank”>{{textQualityDataInfo.sections.links.gModule}}</a>,
                <a [href]="rsiLinks.dust" target=”_blank”>{{textQualityDataInfo.sections.links.dust}}</a>.
            </p>
        </section>

        <section class="data-quality__section">
            <h2 class="data-quality__section-header">{{textQualityDataInfo.menuItems.termsForUse}}</h2>
            <div class="terms-for-use">
                <div class="term-block" *ngIf="termOfUseDevice[TEMP]">
                    <div class="title">{{textQualityDataInfo.sections.termsForUse.tempTitle}}</div>
                    <div class="value">{{textQualityDataInfo.sections.termsForUse.tempValue(termOfUseDevice[TEMP]?.minValue, termOfUseDevice[TEMP]?.maxValue)}}</div>
                </div>
                <div class="term-block" *ngIf="termOfUseDevice[HUM]">
                    <div class="title">{{textQualityDataInfo.sections.termsForUse.phTitle}}</div>
                    <div class="value">{{textQualityDataInfo.sections.termsForUse.phValue(termOfUseDevice[HUM]?.maxValue)}}</div>
                </div>
                <div class="term-block">
                    <div class="title">{{textQualityDataInfo.sections.termsForUse.presTitle}}</div>
                    <div class="value">{{textQualityDataInfo.sections.termsForUse.presValue}}</div>
                </div>
            </div>
        </section>

        <section class="data-quality__section">
            <h2 class="data-quality__section-header">{{textQualityDataInfo.menuItems.mainProperty}}</h2>
            <div class="metrological-table">
                <div class="row-limit header">
                    <div class="mmt-name-header">{{textQualityDataInfo.sections.mainProperty.tableHeader.mmt}}</div>
                    <div class="mmt-range-header">
                        <div class="inner" [innerHtml]="textQualityDataInfo.sections.mainProperty.tableHeader.interval"></div>
                    </div>
                    <div class="mmt-value-header">
                        <div class="inner" [innerHtml]="textQualityDataInfo.sections.mainProperty.tableHeader.interval1"></div>
                    </div>
                    <div class="mmt-accuracy-header">
                        <div class="inner">
                            <div  [innerHtml]="textQualityDataInfo.sections.mainProperty.tableHeader.mainAccuracy"></div>
                            <div class="sub-header-row">
                                <div class="mmt-value-header">
                                    <div class="inner" [innerHtml]="textQualityDataInfo.sections.mainProperty.tableHeader.sub1"></div>
                                </div>
                                <div class="relative-accuracy" [innerHtml]="textQualityDataInfo.sections.mainProperty.tableHeader.sub2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-limit" *ngFor="let item of metrologicalData">
                    <div class="mmt-name">
                        <div [innerHtml]="textQualityDataInfo.sections.mainProperty.titleMmt[item.mmt] + ', ' + mmtNamesText[item.mmt]">
                        </div>
                    </div>
                    <div class="border-column"></div>
                    <div class="mmt-range">{{item.indicationRange}}</div>
                    <div class="border-column"></div>
                    <div class="mmt-value mmt-range double">
                        <div class="sub-row">{{item.measurementRanges[0]}}</div>
                        <div class="bottom-row">{{item.measurementRanges[1]}}</div>
                    </div>
                    <div class="border-column"></div>
                    <div class="mmt-value mmt-accuracy double">
                        <div class="sub-row">{{item.accuracy[0][0] ? item.accuracy[0][0] : '-'}}</div>
                        <div class="bottom-row">{{item.accuracy[0][1] ? item.accuracy[0][1] : '-'}}</div>
                    </div>
                    <div class="border-column"></div>
                    <div class="mmt-value mmt-accuracy double">
                         <div class="sub-row">{{item.accuracy[1][0] ? item.accuracy[1][0] : '-'}}</div>
                         <div class="bottom-row">{{item.accuracy[1][1] ? item.accuracy[1][1] : '-'}}</div>
                    </div>
                </div>
            </div>
            <div class="remark">{{textQualityDataInfo.sections.mainProperty.textLimit}}</div>
            <div class="comment" [innerHtml]="textQualityDataInfo.sections.mainProperty.textAccuracy"></div>
        </section>

        <section class="data-quality__section data-quality__section_color-grey8">
            <h2 class="data-quality__section-header">
                {{textQualityDataInfo.menuItems.markersData}}
            </h2>

            <p class="data-quality__description_weigth-bold">{{textQualityDataInfo.sections.markersData.header}}</p>
            <ul>
                <li>
                    {{textQualityDataInfo.sections.markersData.listHeader1}}
                    <ul>
                        <li>
                            {{textQualityDataInfo.sections.markersData.listLi[0]}}
                        </li>
                        <li>
                           {{textQualityDataInfo.sections.markersData.listLi[1]}}
                        </li>
                        <!--<li>
                           {{textQualityDataInfo.sections.markersData.listLi[2]}}
                        </li>-->
                    </ul>
                </li>
                <li>
                   {{textQualityDataInfo.sections.markersData.listHeader2}}
                </li>
            </ul>
        </section>

        <section class="data-quality__section data-quality__section_color-grey8">
            <h2 class="data-quality__section-header">{{textQualityDataInfo.menuItems.intervalMarkers}}</h2>
            <h3 class="h3-header">{{textQualityDataInfo.sections.intervalMarkers.titleAllData}}</h3>
            <div class="sub-header-border">{{textQualityDataInfo.sections.intervalMarkers.tempData.title}}</div>
            <ul>
                <li *ngIf="termOfUseDevice[TEMP]?.minValue" [innerHtml]="
                    textQualityDataInfo.sections.intervalMarkers.tempData.minValue(termOfUseDevice[TEMP]?.minValue)">
                </li>
                <li *ngIf="termOfUseDevice[TEMP]?.maxValue" [innerHtml]="
                    textQualityDataInfo.sections.intervalMarkers.tempData.maxValue(termOfUseDevice[TEMP]?.maxValue)">
                </li>
                <!--<li [innerHtml]="textQualityDataInfo.sections.intervalMarkers.tempData.gradient">
                </li>-->
            </ul>
            <div class="sub-header-border">{{textQualityDataInfo.sections.intervalMarkers.humData.title}}</div>
            <ul>
                <li *ngIf="termOfUseDevice[HUM]?.maxValue" [innerHtml]="
                    textQualityDataInfo.sections.intervalMarkers.humData.maxValue(termOfUseDevice[HUM]?.maxValue)">
                </li>
                <!--<li [innerHtml]="textQualityDataInfo.sections.intervalMarkers.humData.gradient">
                </li>-->
            </ul>
            <div class="data-quality__image">
                <img
                    [attr.src]="getAsset('articles/data-quality/example-critical_' + lang +'.png')"
                    alt="{{textQualityDataInfo.sections.intervalMarkers.imgExampleCritical}}"
                />
            </div>
            <h3 class="h3-header">{{textQualityDataInfo.sections.intervalMarkers.titleMmt}}</h3>
            <div class="mmt-limit-table">
                <div class="row-limit header">
                    <div class="mmt-name-header">{{textQualityDataInfo.sections.intervalMarkers.mmtData.tableHeader.mmt}}</div>
                    <div class="mmt-value-header">
                        <div class="inner" [innerHtml]="textQualityDataInfo.sections.intervalMarkers.mmtData.tableHeader.min1"></div>
                    </div>
                    <div class="mmt-value-header">
                        <div class="inner" [innerHtml]="textQualityDataInfo.sections.intervalMarkers.mmtData.tableHeader.max1"></div>
                    </div>
                    <div class="mmt-value-header">
                        <div class="inner" [innerHtml]="textQualityDataInfo.sections.intervalMarkers.mmtData.tableHeader.min2"></div></div>
                    <div class="mmt-value-header">
                        <div class="inner" [innerHtml]="textQualityDataInfo.sections.intervalMarkers.mmtData.tableHeader.max2"></div>
                    </div>
                </div>
                <div class="row-limit" *ngFor="let item of mmtData">
                    <div class="mmt-name" [innerHtml]="mmtNamesText[item.mmt] ?? item.mmt"></div>
                    <div class="mmt-value">{{item.minValue / 1000  | valueFormatNumber : 1}}</div>
                    <div class="mmt-value">{{item.maxValue / 1000 | valueFormatNumber : 1}}</div>
                    <div class="mmt-value">{{item.minValue | valueFormatNumber : 1}}</div>
                    <div class="mmt-value">{{item.maxValue | valueFormatNumber : 1}}</div>
                </div>
            </div>
            <div class="data-quality__image">
                <img
                    [attr.src]="getAsset('articles/data-quality/example-mmt_' + lang +'.png')"
                    alt="{{textQualityDataInfo.sections.intervalMarkers.imgExampleCritical}}"
                />
            </div>
        </section>

        <section class="data-quality__section">
            <h2 class="data-quality__section-header">
               {{textQualityDataInfo.menuItems.conditionsMarkers}}
            </h2>
            <p>
               {{textQualityDataInfo.sections.conditionsMarkers.text(qualityDataPercent)}}
            </p>
            <ul *ngIf="qualityDataPercent&& dataMarkerPackagesNumber">
                <li>
                    {{textQualityDataInfo.sections.conditionsMarkers.list.min5(dataMarkerPackagesNumber.min5)}}
                </li>
                <li>
                   {{textQualityDataInfo.sections.conditionsMarkers.list.min20(dataMarkerPackagesNumber.min20)}}
                </li>
                <li>
                   {{textQualityDataInfo.sections.conditionsMarkers.list.hour(dataMarkerPackagesNumber.hour)}}
                </li>
                <li>
                   {{textQualityDataInfo.sections.conditionsMarkers.list.day(dataMarkerPackagesNumber.day)}}
                </li>
            </ul>
        </section>

        <section>
            <h2 class="data-quality__section-header">
               {{textQualityDataInfo.menuItems.markersReports}}
            </h2>
            <p>
               {{textQualityDataInfo.sections.markersReports}}
            </p>
        </section>
    </article>
</div>
