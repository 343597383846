import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { SidebarToggleButtonModule } from '@libs/shared-ui';

import { MenuComponent } from './components/menu/menu.component';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';

@NgModule({
    imports: [CommonModule, DirectivesModule, TooltipsModule, SidebarToggleButtonModule],
    providers: [],
    exports: [MenuComponent, MenuButtonComponent],
    declarations: [MenuComponent, MenuButtonComponent],
})
export class SidebarModule {}
