<ng-container *ngIf="data.length">
    <ng-container *ngFor="let item of data">
        <div *ngIf="item?.fullScreen else smallTemplate" class="full-page-wrapper">
            <div class="content-full-page">
                 {{ getMessage(item) }}
            </div>
        </div>
        <ng-template #smallTemplate>
            <div [className]="'wrapper y' + item.positionY">
                <div [className]="'show-component-wrap x' + item.positionX">
                    <div class="content" [ngClass]="{ 'small': item?.size === 'md' }">
                        <div [className]="'icon ' + item.iconClass">
                        </div>
                        <div class="content-message">
                            <div *ngIf="item.iconClass === 'error' && item.size === 'lg'" class="title-error">
                                {{ errorTextTitle }}
                            </div>
                            <div [className]="'messages ' + item.size + ' ' + item.iconClass">
                                <div class="title">{{ getMessage(item) }}</div>
                                <div class="description" *ngIf="item?.description">{{ item?.description }}</div>
                            </div>
                        </div>
                        <cross-button
                            class="close"
                            [size]="item?.size === 'lg' ? 'medium' : 'small'"
                            [color]="'#FFFFFF'"
                            [needHover]="false"
                            (click)="close(item)"
                        ></cross-button>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-container>
