import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MeasureZones, measureZones, TEXTS } from '@libs/common';
import { Store } from '@ngrx/store';
import { ChartArea, ChartDataset, ChartConfiguration } from 'chart.js';

function hexToRgba(hex, opacity){
    let c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c = hex.substring(1).split('');
        if(c.length == 3){
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${opacity})`;
    }
    throw new Error('Bad Hex');
}

function getGradient(
    ctx: CanvasRenderingContext2D,
    chartArea: ChartArea,
    mmt: string,
    pinsZones: MeasureZones,
    data: number[]
) {
    const result = [...data];
    const min = Math.min(...result);
    const max = Math.max(...result);
    if (pinsZones) {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        const countEndPoints = pinsZones.getIndex(mmt, max);
        gradient.addColorStop(0, pinsZones.getColor(min, mmt));
        for (let k = 1; k <= countEndPoints - 2; k++) {
            gradient.addColorStop(k / countEndPoints, pinsZones.getColorByIndex(mmt, k + 1));
        }
        gradient.addColorStop(1, pinsZones.getColor(max, mmt));
        return gradient;
    }
}

function setGradientBg(
    ctx: CanvasRenderingContext2D,
    chartArea: ChartArea,
    mmt: string,
    pinsZones: MeasureZones,
    data: number[]
) {
    const result = [...data];
    const min = Math.min(...result);
    const max = Math.max(...result);

    if (pinsZones) {
        const gradientBackground = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        const countEndPoints = pinsZones.getIndex(mmt, max);
        gradientBackground.addColorStop(0, hexToRgba(pinsZones.getColor(min, mmt), 0.4));
        for (let i = 1; i <= countEndPoints - 2; i++) {
            gradientBackground.addColorStop(i / countEndPoints, hexToRgba(pinsZones.getColorByIndex(mmt, i + 1), 0.4));
        }
        gradientBackground.addColorStop(1, hexToRgba(pinsZones.getColor(max, mmt), 0.4));
        return gradientBackground;
    }
}

const options: ChartConfiguration['options'] = {
    responsive: true,
    animation: false,
    elements: {
        point: {
            radius: 0
        },
        line: {
            tension: 0.6
        }
    },
    scales: {
        x: {
            ticks: {
                display: false
            }    
        }
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        tooltip: {
            borderWidth: 1,
            bodyColor: '#000',
            titleColor: '#000',
            displayColors: true,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            callbacks: {
                label: (tooltipItem) => `${tooltipItem.formattedValue}%`,
                title: (tooltipItem) => {
                    let label = tooltipItem[0].label;
                    if (label.length > 14) {
                        label = label.substring(0, 12);
                        label += '...';
                        return label;
                    } else {
                        return `${tooltipItem[0].label}`;
                    }
                },
            },
        },
    },
};

@Component({
    selector: 'impact-line-chart',
    templateUrl: 'impact-line-chart.component.html',
    styleUrls: ['impact-line-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactLineChartComponent implements OnChanges {
    @Input() mmt: string; 
    @Input() chartData: number[] = [];
    public TEXTS = TEXTS;
    public lineDatasets: ChartDataset<'line', number[]>[] = [];
    public lineLabels: string[] = [];
    public lineOptions = options;

    constructor(public store: Store ){}

    ngOnChanges(changes: SimpleChanges): void {
        const values = this.chartData;
        const mmt = this.mmt;

        this.lineLabels = Array(this.chartData.length).fill(0);

        this.lineDatasets = [{
            data: values,
            fill: 'start',
            borderColor: function (context) {

                const chart = context.chart;
                const valuesY = values;
                const { ctx, chartArea } = chart;
    
                if (!chartArea) {
                    return null;
                }
                return getGradient(ctx, chartArea, mmt, measureZones, valuesY);
            },
            backgroundColor: function(context) {
                const chart = context.chart;
                const data = values;
                const {ctx, chartArea} = chart;
    
                if (!chartArea) {
                    return null;
                }
                return setGradientBg(ctx, chartArea, mmt, measureZones, data);
            }
        }];
    }
}
