import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TEXTS } from '@libs/common';
import { LayerButtonIconType } from '@libs/common';
import { ANIMATION_OPACITY } from '@libs/common';
import { selectSidebarIsOpen } from '@libs/shared-store';
import {
    ImpactActions,
    selectActiveRun,
    selectActiveMmt,
    selectSpeciesList,
    selectRunsImpact,
    selectDisplayObjOnMap,
    selectIsAllowLensImpact,
    selectShowLensLayer,
    selectShowMmrLayer,
    selectIsAllowMrrImpact,
    selectIsAdditionalLayer,
    selectShowMrrSourceLayer,
} from '@cityair/modules/impact/store/impact.feature';
import { CheckboxItem, RunImpact } from '@libs/common';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'ca-impact-map-element',
    templateUrl: 'impact-map-element.component.html',
    styleUrls: ['impact-map-element.component.less'],
    animations: ANIMATION_OPACITY,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactMapElementComponent implements OnDestroy {
    textNames = TEXTS.NAMES;
    textOnMap = TEXTS.MAP_CONTROL.on;
    textsSwitchButton = TEXTS.PLUMES.switchPostButton;
    textTitleMapControl = TEXTS.PLUMES.forecastOn + ':';
    LayerButtonIconType = LayerButtonIconType;

    selectSidebarIsOpen = selectSidebarIsOpen;
    selectActiveRun = selectActiveRun;
    selectActiveMmt = selectActiveMmt;
    selectSpeciesList = selectSpeciesList;
    selectRunsImpact = selectRunsImpact;
    selectDisplayObjOnMap = selectDisplayObjOnMap;
    selectIsAllowLensImpact = selectIsAllowLensImpact;
    selectIsAllowMrrImpact = selectIsAllowMrrImpact;
    selectIsAdditionalLayer = selectIsAdditionalLayer;
    isActiveMrr = false;
    isActiveLens = false;
    isActiveMrrSource = false;
    public ngDestroyed$ = new Subject<void>();
    constructor(public store: Store) {
        store
            .select(selectShowLensLayer)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isActive) => (this.isActiveLens = isActive));
        store
            .select(selectShowMmrLayer)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isActive) => (this.isActiveMrr = isActive));
        store
            .select(selectShowMrrSourceLayer)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isActive) => (this.isActiveMrrSource = isActive));
    }

    changeMmt(mmt) {
        this.store.dispatch(ImpactActions.setActiveMmt({ payload: mmt }));
    }

    changeRun(run: RunImpact) {
        this.store.dispatch(ImpactActions.setActiveRun({ payload: run }));
    }

    mapObjectChanges(list: CheckboxItem[]) {
        const listItems = [...list];
        this.store.dispatch(ImpactActions.setMapObjectTypes({ payload: listItems }));
    }

    ngOnDestroy() {
        if (this.isActiveMrr) {
            this.store.dispatch(ImpactActions.toggleMrrLayer({ payload: false }));
        }
        if (this.isActiveLens) {
            this.store.dispatch(ImpactActions.toggleLensLayer({ payload: false }));
        }
        this.ngDestroyed$.next();
    }

    enableMrr() {
        if (this.isActiveLens) {
            this.store.dispatch(ImpactActions.toggleLensLayer({ payload: false }));
        }
        this.store.dispatch(ImpactActions.toggleMrrLayer({ payload: true }));
    }

    disableMrr() {
        this.store.dispatch(ImpactActions.toggleMrrLayer({ payload: false }));
    }

    enableMrrSource() {
        if (this.isActiveLens) {
            this.store.dispatch(ImpactActions.toggleLensLayer({ payload: false }));
        }
        this.store.dispatch(ImpactActions.toggleMrrSourceLayer({ payload: true }));
    }

    disableMrrSource() {
        this.store.dispatch(ImpactActions.toggleMrrSourceLayer({ payload: false }));
    }

    enableLens() {
        if (this.isActiveMrr) {
            this.store.dispatch(ImpactActions.toggleMrrLayer({ payload: false }));
        }
        this.store.dispatch(ImpactActions.toggleLensLayer({ payload: true }));
    }

    disableLens() {
        this.store.dispatch(ImpactActions.toggleLensLayer({ payload: false }));
    }
}
