<div class="indoor-settings">
    <div class="indoor-settings__header">
        <div class="indoor-settings__back-btn">
            <cs-back-button data-cy="indoor-stat-back" (click)="closeConfig.emit()"
                >Back</cs-back-button
            >
        </div>
        <div class="indoor-settings__title">Settings</div>
    </div>
    <div class="indoor-settings__info-wrapper">
        <div class="indoor-settings__info">
            <svg
                class="indoor-settings__info-icon"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="20"
                    y="20"
                    width="20"
                    height="20"
                    rx="10"
                    transform="rotate(-180 20 20)"
                    fill="url(#paint0_linear_9546_68457)"
                />
                <path
                    d="M10 15C10.5523 15 11 14.5523 11 14V10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10 15Z"
                    fill="white"
                />
                <path
                    d="M10 7C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7Z"
                    fill="white"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_9546_68457"
                        x1="40"
                        y1="20"
                        x2="22.0624"
                        y2="41.7045"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#6795ED" />
                        <stop offset="0.368408" stop-color="#7387E6" />
                        <stop offset="1" stop-color="#9369D7" />
                    </linearGradient>
                </defs>
            </svg>
            <div class="indoor-settings__info-description">
                The schedule is used to calculate the IAQI. Feel free to write to your CityAir
                manager to change it. In the future, you will be able to correct the settings in the
                app.
            </div>
        </div>
    </div>
    <div class="settings-sections">
        <div class="settings-sections__title">General settings</div>
        <div class="settings-section settings-section__slider-range">
            <div class="settings-section__title">Working hours</div>
            <div class="settings-section__option">
                <range-slider-hours
                    [handleMinPosition]="hoursSlider.handleMinPosition"
                    [minPositionName]="hoursSlider.minPositionName"
                    [handleMaxPosition]="hoursSlider.handleMaxPosition"
                    [maxPositionName]="hoursSlider.maxPositionName"
                ></range-slider-hours>
            </div>
        </div>
        <div class="settings-section settings-section__list">
            <div class="settings-section__title">Working days</div>
            <div class="settings-section__option">
                <div class="list-days">
                    <div
                        *ngFor="let day of days"
                        class="list-days__item {{ day.active ? 'list-days__item_active' : '' }}"
                    >
                        {{ day.name }}
                    </div>
                </div>
            </div>
        </div>
        <div class="settings-section">
            <div class="settings-section__option">
                <input-dropdown
                    [inputText]="'All rooms are selected'"
                    [label]="'Rooms'"
                    [isDisable]="true"
                >
                </input-dropdown>

                <div class="room-list">
                    <tags-list [tags]="selectedRooms"></tags-list>
                </div>
            </div>
        </div>
    </div>
    <div class="settings-sections">
        <div class="settings-sections__title">Air comfort index settings</div>
        <div class="settings-section settings-section__slider-range">
            <div class="settings-section__title">
                <span>Temperature</span> rate to consider as comfortable
            </div>
            <div class="settings-section__option">
                <range-slider-option
                    [handleMinPosition]="temperatureSlider.handleMinPosition"
                    [handleMaxPosition]="temperatureSlider.handleMaxPosition"
                    [currentMinValue]="temperatureSlider.currentMinValue"
                    [currentMaxValue]="temperatureSlider.currentMaxValue"
                    [minValue]="temperatureSlider.minValue"
                    [maxValue]="temperatureSlider.maxValue"
                    [measure]="temperatureSlider.measure"
                    [colors]="temperatureSlider.colors"
                ></range-slider-option>
            </div>
        </div>
        <div class="settings-section settings-section__slider-range">
            <div class="settings-section__title">
                <span>Humidity</span> rate to consider as comfortable
            </div>
            <div class="settings-section__option">
                <range-slider-option
                    [handleMinPosition]="humiditySlider.handleMinPosition"
                    [handleMaxPosition]="humiditySlider.handleMaxPosition"
                    [currentMinValue]="humiditySlider.currentMinValue"
                    [currentMaxValue]="humiditySlider.currentMaxValue"
                    [minValue]="humiditySlider.minValue"
                    [maxValue]="humiditySlider.maxValue"
                    [measure]="humiditySlider.measure"
                    [colors]="humiditySlider.colors"
                ></range-slider-option>
            </div>
        </div>
        <div class="settings-section settings-section__slider-range">
            <div class="settings-section__title">
                <span>CO<sub>2</sub></span> concentration should not be higher than
            </div>
            <div class="settings-section__option">
                <range-slider-single
                    [handleMinPosition]="CO2Slider.handleMinPosition"
                    [currentMinValue]="CO2Slider.currentMinValue"
                    [minValue]="CO2Slider.minValue"
                    [maxValue]="CO2Slider.maxValue"
                    [measure]="CO2Slider.measure"
                    [colors]="CO2Slider.colors"
                ></range-slider-single>
            </div>
        </div>
    </div>
</div>
