import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AQI } from '@libs/common';
import { AqiType } from '@libs/common';
import { TEXTS } from '@libs/common';

const AQIs = [AQI, ...Object.values(AqiType)];
@Component({
    selector: 'shared-ui-mmt-selector',
    templateUrl: './mmt-selector.component.html',
    styleUrls: ['./mmt-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmtSelectorComponent {
    @Input() title?: string;
    @Input() mmts: string[];
    @Input() activeMmt: string;
    @Input() activeLayer?: boolean;
    @Input() showLayerButton?: boolean;
    @Input() disabledLayerButton? = false;
    @Input() meteoMmts: string[];

    @Output() selectMmt = new EventEmitter<string>();
    @Output() toggleLayer = new EventEmitter();

    textNames = TEXTS.NAMES;
    textLayer = TEXTS.MODELS;
    textSelector = TEXTS.MAP_CONTROL;
    AQIs = AQIs;
    AqiType = AqiType;

    select = (mmt: string) => this.selectMmt.emit(mmt);
    layerClick() {
        if (!this.disabledLayerButton) {
            this.toggleLayer.emit();
        }
    }
}
