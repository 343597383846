import { formatDayMonthYear, REPORTS_COLOR, InfoMessageIconType } from "@libs/common";
import * as moment from 'moment-timezone';

export interface DateRange {
    startDate: string;
    finishDate: string;
}
export enum REPORT_STATUS {
    CREATED = 'CREATED',
    QUEUED = 'QUEUED',
    IN_PROGRESS = 'IN_PROGRESS',
    ERROR = 'ERROR',
    READY = 'READY',
}
export interface statusMessage {
    id: number;
    status: REPORT_STATUS;
    title?: string;
    text?: string;
    value?: number;
}
export interface ProcessingDetails {
    code: string;
    status: REPORT_STATUS;
}
export interface UserReport {
    harvester_id: number;
    username: string;
    email: string;
    charAt?: string;
    avatarClass?: REPORTS_COLOR[keyof REPORTS_COLOR];
}
export enum REPORT_TEMPLATE_PARAMS_KEY {
    postIds = 'post_ids',
    startDate = 'start_date',
    finishDate = 'finish_date',
    availableFormats = 'available_formats',
    measure = 'measure',
    timezone = 'timezone',
    threshold = 'threshold',
    year = 'year',
    fileupload = 'fileupload',
    intervals = 'intervals',
    units = 'units',
    includeMpc = 'include_mpc',
}
export const EXCLUDE_CREATE_FORM_KEY = [
    REPORT_TEMPLATE_PARAMS_KEY.startDate,
    REPORT_TEMPLATE_PARAMS_KEY.finishDate,
    REPORT_TEMPLATE_PARAMS_KEY.timezone,
];
export function timeZoneOffset(tz: string): string {
    return `<i>(GMT ${moment.tz(tz).format('Z')})</i>`;
}
export interface IReportKindParams {
    user_id: number;
}
export const STATUS_NEED_UPDATE = [
    REPORT_STATUS.CREATED,
    REPORT_STATUS.QUEUED,
    REPORT_STATUS.IN_PROGRESS,
    REPORT_STATUS.ERROR,
];
export interface IReportKind {
    id: number;
    name: string;
    name_eng?: string;
    slug: string;
    short_description: string;
    short_description_eng: string;
    description: string;
    description_eng: string;
    default_format: string;
    available_format?: string[];
    params: any;
}
export interface IReport {
    id?: string;
    template_id?: number;
    file?: string;
    user?: UserReport;
    status?: REPORT_STATUS;
    create_date?: string;
    date?: number;
    update_date?: string;
    format?: string;
    name?: string;
    comment?: string;
    params?: any;
    kind?: IReportKind;
    group_id?: number;
    processing_details?: { [key: string]: ProcessingDetails };
    processing_output_file?: string;
}
export interface IKeyValue {
    key: REPORT_STATUS;
    value: number;
}
export interface IReportResponse {
    reports: IReport[];
    counter: IKeyValue[];
}
export interface ReportParams {
    postIds?: number[];
    measure?: string[];
    format?: string;
}
export interface UserData {
    userId: string;
    email: string;
    login: string;
    roleId: string;
    roleName?: string;
    shortName?: string;
}
export class Reports implements IReport {
    readonly id;
    public name;
    public file;
    readonly template_id;
    readonly user: UserReport;
    readonly status: REPORT_STATUS;
    readonly createDate: string;
    public updateDate: string;
    public date: number;
    public format: string;
    public isDisableDownload: boolean;
    public kind: IReportKind;
    public comment: string;
    public startDate: number;
    public period: string;
    public params: ReportParams = {};
    public hasDetail: boolean;
    public initParams: any;
    public timezoneOffset: string;
    public processing_details?: { [key: string]: ProcessingDetails };
    public processing_output_file?: string;

    constructor(report: IReport) {
        this.id = report.id;
        this.user = this.prepareUser(report.user);
        this.status = report.status;
        this.createDate = this.formatCreateDate(report.create_date);
        this.date = Date.parse(report.create_date);
        this.updateDate = report.update_date;
        this.file = report.file;
        this.format = this.getFormat(this.file);
        this.name = report.name;
        this.kind = report.kind;
        this.comment = report.comment;
        this.isDisableDownload = this.status !== REPORT_STATUS.READY;
        this.startDate = report.params.start_date ? moment(report.params.start_date).valueOf() : 0;
        this.period = this.getPeriod(report.params.start_date, report.params.finish_date);
        this.timezoneOffset = report.params.timezone ? timeZoneOffset(report.params.timezone) : '';
        this.initParams = report.params;
        if (report.params.post_ids) {
            this.params.postIds = report.params.post_ids;
        }
        if (report.params.measure) {
            this.params.measure = report.params.measure;
        }
        this.processing_details = report.processing_details;
        this.processing_output_file = report.processing_output_file;
        this.hasDetail =
            (report.params.post_ids && report.params.measure) ||
            report.processing_details ||
            report.processing_output_file
                ? true
                : false;
    }

    private prepareUser(user: UserReport): UserReport {
        if (!user) {
            return user;
        }
        user.charAt = user.email.charAt(0).toUpperCase();
        user.avatarClass = this.colorIconClass(user.harvester_id);
        return user;
    }

    private colorIconClass(id: number): REPORTS_COLOR[keyof REPORTS_COLOR] {
        let enumKeys = Object.keys(REPORTS_COLOR);
        enumKeys = enumKeys.filter((item) => item !== 'green'); // remove green
        const index = id % enumKeys.length;

        return enumKeys[index];
    }

    private getFormat(fileName: string): string {
        if (!fileName) {
            return '';
        }
        return fileName.split('.').pop();
    }

    private getPeriod(begin, end) {
        if (!begin || !end) {
            return null;
        }
        const textStart = formatDayMonthYear(moment(begin));
        const textEnd = formatDayMonthYear(moment(end));

        return `${textStart} - ${textEnd}`;
    }

    private formatCreateDate(date: string) {
        const m = moment(date);
        return `${m.format('D')} ${m.format('MMM').substring(0, 3)} ${m.format('YYYY HH:mm')}`;
    }
    private getFileName(fileName: string): string {
        return fileName.split('.').slice(0, -1).join('.') || fileName + '';
    }
}
export function getErrorTypeByReportStatus(status: REPORT_STATUS): InfoMessageIconType {
    let iconClass = 'success' as InfoMessageIconType;
    switch (status) {
        case REPORT_STATUS.CREATED:
        case REPORT_STATUS.READY:
            iconClass = 'success';
            break;
        case REPORT_STATUS.ERROR:
            iconClass = 'error';
            break;
        case REPORT_STATUS.IN_PROGRESS:
            iconClass: 'progress';
            break;
        case REPORT_STATUS.QUEUED:
            iconClass = 'info';
            break;
    }
    return iconClass;
}