import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ControlPoint } from './models';
import { CONTROL_POINT_ENTITY_STORE_KEY } from '../../constants';

@Injectable({ providedIn: 'root' })
export class ControlPointService extends EntityCollectionServiceBase<ControlPoint> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super(CONTROL_POINT_ENTITY_STORE_KEY, serviceElementsFactory);
    }
}
