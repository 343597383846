import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorrelationPinComponent } from './correlation-pin.component';

@NgModule({
    exports: [CorrelationPinComponent],
    declarations: [CorrelationPinComponent],
    imports: [CommonModule],
})
export class CorrelationPinModule {}
