import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlPointPinComponent } from './control-point-pin.component';

@NgModule({
    exports: [ControlPointPinComponent],
    declarations: [ControlPointPinComponent],
    imports: [CommonModule],
})
export class ControlPointPinModule {}
