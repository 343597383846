import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CalendarState } from './../../CalendarState';
import { CalendarMonth, Day } from './../../dataModel';
import * as moment from 'moment-timezone';
import { CalendarActions } from './../../CalendarActions';
import { ANIMATION_OPACITY, isToday, TEXTS } from '@libs/common';

@Component({
    selector: 'calendar-months',
    templateUrl: './calendar-months.component.html',
    styleUrls: ['./../calendar-months.component.less'],
    animations: ANIMATION_OPACITY,
})
export class CalendarMonthsComponent implements OnInit, OnChanges {
    @Input() show: boolean;
    @Input() timeBegin: number;
    @Input() timeEnd: number;
    @Input() tzOffset: number;
    @Input() applyCb: (begin: number, end: number) => void;
    @Input() toggleShowCb: () => void;
    @Input() updateDays: (fn: (numberOfFutureDays?: number, minDate?: string) => void) => void;
    @Input() monthsVisible: number;
    @Input() columns: number;
    @Input() dynamicWidth = false;
    @Input() numberOfMonths;
    @Input() isMobile: boolean;

    showMonth: number[] = []; // 0...12

    calendarState: CalendarState;
    months: CalendarMonth[];
    calendarActions: CalendarActions;

    TEXTS = TEXTS;

    ngOnInit() {
        for (let i = 0; i < this.monthsVisible; i++) {
            this.showMonth.unshift(i);
        }

        this.calendarState = new CalendarState();
        this.calendarActions = new CalendarActions(
            this.calendarState,
            this.applyCb,
            this.numberOfMonths
        );
        this.updateActiveBtn();
        if (this.updateDays) {
            this.updateDays(this.calendarActions.updateDays);
        } else {
            this.calendarActions.updateDays();
        }
    }

    ngOnChanges({ timeBegin, timeEnd }: SimpleChanges) {
        if (timeBegin?.currentValue || timeEnd?.currentValue) {
            this.updateActiveBtn();
        }
    }

    toLeft = (e: Event) => {
        e.stopPropagation();
        const arr = this.showMonth;

        const firstVisibleMonth = arr[0];

        if (firstVisibleMonth < this.numberOfMonths - this.monthsVisible)
            this.showMonth = arr.map((month) => month + this.monthsVisible);
    };

    toRight = (e: Event) => {
        e.stopPropagation();
        const arr = this.showMonth;

        const lastVisibleMonth = arr[arr.length - 1];

        if (lastVisibleMonth > 0) this.showMonth = arr.map((month) => month - this.monthsVisible);
    };

    timeButtonClick = (type) => {
        let begin;
        const end = moment().valueOf();

        const sub = (i: number) =>
            moment()
                .subtract(i - 1, 'day')
                .startOf('day')
                .valueOf();
        const subHour = (i: number) => moment().subtract(i, 'day').startOf('hour').valueOf();

        switch (type) {
            case 'month':
                begin = sub(31);
                break;
            case 'week':
                begin = sub(7);
                break;
            case '3':
                begin = subHour(3);
                break;
            case '1':
                begin = subHour(1);
                break;
        }

        this.calendarState.activeBtn = type;

        this.calendarState.begin = this.timeBegin = begin;
        this.calendarState.end = this.timeEnd = end;

        this.calendarActions.apply();
    };

    dayClick(day: Day) {
        if (day && !day.disabled) {
            this.calendarActions.dayClick(day.timestamp);
            this.calendarState.activeBtn = null;
        }
    }

    over(day: Day) {
        if (day && !day.disabled) {
            this.calendarActions.dayOver(day.timestamp);
        }
    }

    isHover(day: Day, i) {
        let dayBegin = this.calendarState.isMainTimePriority
            ? this.timeBegin
            : this.calendarState.begin;
        let dayEnd = this.calendarState.isMainTimePriority ? this.timeEnd : this.calendarState.end;

        const str = 'calendar__day-'; // используется для поиска и проскроливания к нему

        if (!day || day.disabled || !dayBegin || !dayEnd) {
            return '';
        }

        const startDay = (timestamp) => moment(timestamp).startOf('day').valueOf();
        dayBegin = startDay(dayBegin);
        dayEnd = startDay(dayEnd);
        const time = startDay(day.timestamp);

        if (dayBegin > dayEnd) {
            const a = dayBegin;
            dayBegin = dayEnd;
            dayEnd = a;
        }

        if (time < dayBegin || dayEnd < time) return '';

        let ret = '';
        if (time === dayBegin || i == 0) ret += str + 'begin ';

        if (time === dayEnd || i == 6) ret += str + 'end '; // используется для поиска и проскроливания к нему

        if (dayBegin <= time && time <= dayEnd) ret += str + 'hover ';

        return ret;
    }

    private updateActiveBtn() {
        if (this.calendarState) {
            const diff = moment(this.timeEnd).diff(moment(this.timeBegin), 'hours');
            const endIsToday = isToday(new Date(this.timeEnd));
            if (diff === 72 && endIsToday) {
                this.calendarState.activeBtn = '3';
            } else if (diff === 24 && endIsToday) {
                this.calendarState.activeBtn = '1';
            } else {
                this.calendarState.activeBtn = null;
            }
        }
    }
}
