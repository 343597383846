import {
    AQI_IN_ANALYTICS_GROUP_IDS,
    ADMIN_ID,
    OPERATOR_ID,
    AqiType,
    Group,
    GroupExtConfigName,
    MAIN_PAGES,
    prepareExtConfig,
} from '@libs/common';

declare const window: any;
export const hideHtmlLoader = () => {
    const elemStyle = window?.commonLoader?.style;

    if (!elemStyle) {
        return;
    }

    elemStyle.opacity = '0';
    setTimeout(() => {
        elemStyle.display = 'none';
    }, 200);
};
export function getAvailableModules(currentGroup, myRole, posts, cities, devices) {
    const pages = Object.values(MAIN_PAGES);
    const result = [];
    const allowPlumes = currentGroup.modules.find((module) => module.id === 2) ? true : false;
    const allowIndoor = currentGroup.modules.find((module) => module.id === 3) ? true : false;
    const allowNetwork = currentGroup.modules.find((module) => module.id === 5) ? true : false;
    const allowEvents = currentGroup.modules.find((module) => module.id === 4) ? true : false;
    const allowImpact = currentGroup.modules.find((module) => module.id === 6) ? true : false;
    const extConfig = prepareExtConfig(currentGroup.ext_config);
    const excludeIndexes = extConfig?.excludeIndexes?.split(',') ?? [];

    pages.forEach((page) => {
        if (
            page === MAIN_PAGES.analytics &&
            !allowImpact &&
            cities.length > 0 &&
            (excludeIndexes.indexOf(AqiType.instant) === -1 ||
                AQI_IN_ANALYTICS_GROUP_IDS.indexOf(currentGroup.id) >= 0)
        ) {
            result.push(page);
        }
        if (page === MAIN_PAGES.plumes && allowPlumes && !allowImpact) {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.notifications &&
            !allowIndoor &&
            !allowImpact &&
            myRole?.edit_station &&
            !!(devices.length || posts.length)
        ) {
            result.push(page);
        }
        if (page === MAIN_PAGES.networks && (devices?.length || posts?.length) > 0) {
            result.push(page);
        }

        if (page === MAIN_PAGES.settings) {
            result.push(page);
        }

        if (
            (page === MAIN_PAGES.indoor ||
                page === MAIN_PAGES.global ||
                page === MAIN_PAGES.indoorWidget) &&
            allowIndoor
        ) {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.reports &&
            (myRole?.id === ADMIN_ID || myRole?.id === OPERATOR_ID)
        ) {
            result.push(page);
        }
        if (page === MAIN_PAGES.forecast && extConfig[GroupExtConfigName.showForecastModule]) {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.analysis &&
            allowNetwork &&
            !allowImpact &&
            (myRole?.id === ADMIN_ID || myRole?.id === OPERATOR_ID)
        ) {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.analysisEvents &&
            allowEvents &&
            !allowImpact &&
            (myRole?.id === ADMIN_ID || myRole?.id === OPERATOR_ID)
        ) {
            result.push(page);
        }
        if (page === MAIN_PAGES.network && allowImpact) {
            result.push(page);
        }
        if (page === MAIN_PAGES.impact && allowImpact) {
            result.push(page);
        }
        if (page === MAIN_PAGES.events && allowImpact) {
            result.push(page);
        }
        if (page === MAIN_PAGES.modelingWorks && allowImpact && extConfig?.allowModelingWorks) {
            result.push(page);
        }
    });

    return result;
}

export function getDefaultMmt(current: string, currentGroup: Group, allMeasurements): string {
    const extConfig = prepareExtConfig(currentGroup.ext_config);
    const aqi = extConfig[GroupExtConfigName.defaultAqi];
    if (aqi && allMeasurements.includes(aqi)) {
        return aqi;
    }

    const pinsDataFormatType = extConfig[GroupExtConfigName.pinsDataFormat]
        ? extConfig[GroupExtConfigName.pinsDataFormat].type
        : null;

    if (pinsDataFormatType && allMeasurements.includes(pinsDataFormatType)) {
        return pinsDataFormatType;
    }

    if (allMeasurements.length && allMeasurements.indexOf(current) === -1) {
        return allMeasurements[0];
    }
    return current;
}
