<div class="back-button" [class.back-button--disabled]="disabled">
    <div class="back-button__arrow">
        <svg
            width="9"
            height="16"
            viewBox="0 0 9 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.71599 8.00002L8.6705 2.12285C9.10983 1.68921 9.10983 0.986155 8.6705 0.552521C8.23116 0.118886 7.51884 0.118886 7.0795 0.552521L0.329505 7.21486C-0.109835 7.64849 -0.109835 8.35155 0.329505 8.78519L7.0795 15.4475C7.51884 15.8812 8.23116 15.8812 8.6705 15.4475C9.10983 15.0139 9.10983 14.3108 8.6705 13.8772L2.71599 8.00002Z"
            />
        </svg>
    </div>
    <div class="back-button__text">
        <ng-content></ng-content>
    </div>
</div>
