<div class="ca_input" [formGroup]="form">
    <div class="ca_input__input_wrapper">
        <input
            #inputText
            [ngClass]="{
                ca_input__input: true,
                'ca_input__input-disabled': disabled,
                'ca_input__input-error': textError
            }"
            formControlName="{{ controlName }}"
            type="{{ type }}"
            autocomplete="off"
            value="{{ valueForm }}"
            step="{{ step }}"
        />
        <div class="ca_input__prompt" #promptText></div>
        <div
            [ngClass]="{
                ca_input__label: true,
                'ca_input__label-active': valueForm || textError
            }"
        >
            {{ label }}
        </div>
    </div>
    <div
        [ngClass]="{
            ca_input__description: true,
            'ca_input__description-info': !textError && textInfo
        }"
    >
        <ng-container *ngIf="!textError && textInfo">
            {{ textInfo }}
        </ng-container>
    </div>
    <div [ngClass]="{ ca_input__description: true, 'ca_input__description-error': textError }">
        <ng-container *ngIf="textError">
            {{ textError }}
        </ng-container>
    </div>
</div>
