<div class="aqi-legend" [@inOutAnimation]>
    <div class="aqi-legend__header">
        <div class="aqi-legend__title">Air Quality Index</div>
        <div class="aqi-legend__description" [innerHtml]="TEXTS.TEXT_DESCRIPTION.airQuality"></div>
    </div>
    <div class="aqi-legend__content scroll-gray">
        <div
            class="aqi-legend__item"
            [class.aqi-legend__item--highlight]="i === currentAqiDangerLevel"
            *ngFor="let description of TEXTS.AQI_THREE_TEXTS2; index as i"
        >
            <div class="aqi-legend__item-icon aqi-legend__item-icon--{{ i }}"></div>
            <div class="aqi-legend__item-description">{{ description }}</div>
        </div>
    </div>
</div>
