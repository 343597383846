<post-and-devices-map></post-and-devices-map>
<div class="page-wrapper"
    [ngClass]="{ 'open-sidebar': store.select(selectSidebarIsOpen) | async }"
>
  <router-outlet></router-outlet>
</div>
<ca-sidebar-toggle-button
    class="toggle-button"
    [ngClass]="{ 'open-sidebar': store.select(selectSidebarIsOpen) | async }"
    [isActive]="store.select(selectSidebarIsOpen) | async"
    (toggle)="toggleSideBar()"
></ca-sidebar-toggle-button>
