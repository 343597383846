import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { STND_CITY_MAX_ZOOM, STND_GLOBAL_MIN_ZOOM } from '@libs/common';
import {
    GroupChartConfig,
    FeaturesConfigType,
    GroupExtConfigName,
    MapStyleType,
    GroupMapSettings,
} from '@libs/common';

export const DEFAULT_MAP_STYLE = 'mapbox://styles/mapbox/light-v10';

export const CUSTOM_MAP_STYLE = 'mapbox://styles/cityair/cl521x0og003x14moz3e6g3je';
export const DEFAULT_QUALITY_DATA_PERCENT = 50;
export const MAP_STYLE = CUSTOM_MAP_STYLE;

const LOCAL_MAP_STYLE = localStorage.getItem('local_map_style');
export const DEFAULT_MAP_SETTINGS: GroupMapSettings = {
    style: LOCAL_MAP_STYLE || MAP_STYLE,
    center: {
        lat: 57,
        lng: 57,
    },
    bounds: [
        {
            lng: -360,
            lat: -84,
        },
        {
            lng: 360,
            lat: 84,
        },
    ],
    zoom: 2,
    minzoom: STND_GLOBAL_MIN_ZOOM,
    maxzoom: STND_CITY_MAX_ZOOM - 1.1,
    showMarkersArea: false,
    type: MapStyleType.cityair,
};
export const DEFAULT_CONFIG: FeaturesConfigType = {
    startModule: null,
    defaultMo: null,
    mapSettings: DEFAULT_MAP_SETTINGS,
    pinsDataFormat: null,
    chartTooltipByMeasurements: {},
    dataQualityPercent: DEFAULT_QUALITY_DATA_PERCENT,
    isDemoModeForEvents: false,
};

// TODO: move to store ??
@Injectable({
    providedIn: 'root',
})
export class GroupFeaturesService {
    private features: FeaturesConfigType = DEFAULT_CONFIG;

    private _readyBehavior$ = new BehaviorSubject<boolean>(false);
    readyBehavior$ = this._readyBehavior$.asObservable();

    setFeatures(features: FeaturesConfigType) {
        this.features = {
            ...DEFAULT_CONFIG,
            ...(features || {}),
            mapSettings: {
                ...DEFAULT_CONFIG.mapSettings,
                ...(features?.mapSettings || {}),
            },
        };

        this._readyBehavior$.next(true);
    }

    getConfig(name: GroupExtConfigName) {
        return this.features[name] || DEFAULT_CONFIG[name];
    }

    getGroupChartConfig() {
        return this.getConfig(GroupExtConfigName.chartMinMax) as GroupChartConfig;
    }

    getDefaultMo() {
        const defaultMo = this.getConfig(GroupExtConfigName.defaultMo);
        return defaultMo && Array.isArray(defaultMo) ? defaultMo : [defaultMo];
    }
}
