import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipsModule } from '@libs/shared-ui';
import { AqiDetailsTooltipComponent } from './aqi-details-tooltip.component';

@NgModule({
    imports: [CommonModule, TooltipsModule],
    exports: [AqiDetailsTooltipComponent],
    declarations: [AqiDetailsTooltipComponent],
})
export class AqiDetailsTooltipModule {}
