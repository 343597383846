<div *ngIf="displayValue else notValue"
    class="correlation-pin"
    [style]="{ backgroundColor: color }"
>
    <div class="correlation-pin__grade" [style]="{ color: color }">{{displayValue}}</div>
    <svg
        class="correlation-pin__end"
        attr.fill="{{ color }}"
        viewBox="0 0 18 9"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.9701 6.58119C4.25581 4.39418 2.8206 0.947368 0 0H8.98007H18C15.1794 0.947368 13.7442 4.39418 12.0299 6.58119L10.7741 8.16349C10.5748 8.41545 10.3156 8.6271 9.99668 8.7682C9.69767 8.91937 9.34884 9 9 9C8.65116 9 8.30233 8.91937 7.99336 8.77828C7.68439 8.63718 7.41528 8.42553 7.21595 8.16349L5.9701 6.58119Z"
        />
    </svg>
</div>
<ng-template #notValue>
    <ng-container *ngIf="selected else notSelected">
        <div class="selected-pin">
            <svg width="44" height="51" viewBox="0 0 44 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22 0C9.84974 0 0 9.84974 0 22C0 31.3796 5.86983 39.3883 14.1365 42.553C15.5829 43.4886 16.632 45.1033 17.6237 46.6295C18.0737 47.3222 18.5119 47.9967 18.9701 48.5812L20.2159 50.1635C20.4153 50.4255 20.6844 50.6372 20.9934 50.7783C21.3023 50.9194 21.6512 51 22 51C22.3488 51 22.6977 50.9194 22.9967 50.7682C23.3156 50.6271 23.5748 50.4155 23.7741 50.1635L25.0299 48.5812C25.4881 47.9967 25.9263 47.3222 26.3763 46.6295C27.368 45.1033 28.4171 43.4886 29.8635 42.553C38.1302 39.3883 44 31.3796 44 22C44 9.84974 34.1503 0 22 0Z" fill="url(#paint0_linear_16606_94307)"/>
                <rect x="11" y="11" width="22" height="22" rx="11" fill="white"/>
                <defs>
                <linearGradient id="paint0_linear_16606_94307" x1="0" y1="25.5" x2="44" y2="25.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4776E6"/>
                <stop offset="1" stop-color="#8E54E9"/>
                </linearGradient>
                </defs>
            </svg>
        </div>
    </ng-container>
    <ng-template #notSelected>
        <ng-container *ngIf="notActive else noSelect">
            <div class="no-selected-pin">
                <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5294 0C6.95275 0 0 6.95275 0 15.5294C0 22.1498 4.14276 27.8026 9.97735 30.0369C10.9987 30.6973 11.7394 31.8373 12.4396 32.9149C12.7572 33.4038 13.0666 33.8799 13.39 34.2925L14.2694 35.4094C14.4101 35.5944 14.6001 35.7438 14.8182 35.8434C15.0362 35.943 15.2825 35.9999 15.5287 35.9999C15.775 35.9999 16.0212 35.943 16.2323 35.8363C16.4574 35.7367 16.6403 35.5873 16.781 35.4094L17.6675 34.2925C17.9909 33.8799 18.3002 33.4038 18.6179 32.9149C19.3176 31.838 20.0578 30.6987 21.0781 30.0382C26.9145 27.8048 31.0588 22.1511 31.0588 15.5294C31.0588 6.95275 24.1061 0 15.5294 0Z" fill="#6c7484"/>
                    <rect x="8" y="8" width="15.0593" height="15.0593" rx="7.52964" fill="white"/>
                </svg>
            </div>
        </ng-container>
        <ng-template #noSelect>
            <div class="no-selected-pin">
                <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5294 0C6.95275 0 0 6.95275 0 15.5294C0 22.1498 4.14276 27.8026 9.97735 30.0369C10.9987 30.6973 11.7394 31.8373 12.4396 32.9149C12.7572 33.4038 13.0666 33.8799 13.39 34.2925L14.2694 35.4094C14.4101 35.5944 14.6001 35.7438 14.8182 35.8434C15.0362 35.943 15.2825 35.9999 15.5287 35.9999C15.775 35.9999 16.0212 35.943 16.2323 35.8363C16.4574 35.7367 16.6403 35.5873 16.781 35.4094L17.6675 34.2925C17.9909 33.8799 18.3002 33.4038 18.6179 32.9149C19.3176 31.838 20.0578 30.6987 21.0781 30.0382C26.9145 27.8048 31.0588 22.1511 31.0588 15.5294C31.0588 6.95275 24.1061 0 15.5294 0Z" fill="#404655"/>
                    <rect x="8" y="8" width="15.0593" height="15.0593" rx="7.52964" fill="white"/>
                </svg>
            </div>
        </ng-template>
    </ng-template>
</ng-template>
