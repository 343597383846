<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="checkbox"
    [class.checkbox--checked]="checked"
    [class.checkbox--disabled]="disabled"
>
    <ng-container *ngIf="checked; else default">
        <rect class="checkbox-background" width="16" height="16" rx="4" />
        <rect class="checkbox-stroke" x="0.5" y="0.5" width="15" height="15" rx="3.5" />
        <rect
            class="checkbox-tick"
            x="4.53906"
            y="6.47632"
            width="5"
            height="1.66667"
            rx="0.833333"
            transform="rotate(45 4.53906 6.47632)"
        />
        <rect
            class="checkbox-tick"
            x="6.25342"
            y="9.76331"
            width="7"
            height="1.55556"
            rx="0.777778"
            transform="rotate(-45 6.25342 9.76331)"
        />
    </ng-container>
    <ng-template #default>
        <rect class="checkbox-stroke" x="0.5" y="0.5" width="15" height="15" rx="3.5" />
    </ng-template>
</svg>
