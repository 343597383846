import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TEXTS } from '@libs/common';

@Component({
    selector: 'cityair-login-fond',
    templateUrl: './login-fond.component.html',
    styleUrls: ['./login-fond.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFondComponent {
    @Input() isMobile = false;
    texts = TEXTS.PROFILE;
}
