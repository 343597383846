<div class="control_select__title">{{ getText() ? title : '&nbsp;' }}</div>
<div class="control_select" (clickOutside)="clickOutside()">
    <div class="control_select__button {{ classButton }}" (click)="toggle()">
        <div
            class="control_select__text {{ classText }}"
            [innerHTML]="getText() || title | safeHtml"
        ></div>
        <i class="control_select__arrow {{ show ? 'control_select__arrow-show' : '' }}"></i>
    </div>
    <div
        class="control_select__options {{ show ? 'control_select__options-show' : '' }} {{
            classPopup
        }} scroll"
        [ngStyle]="{ width: optionsWidth }"
    >
        <div *ngIf="buttonsSelectAll" style="text-align: center">
            <div class="control_select__select_all" (click)="selectAll()">
                {{ TEXTS.LIST_OM.checkAll }}
            </div>
            <div class="control_select__select_all" (click)="deselectAll()">
                {{ TEXTS.LIST_OM.uncheckAll }}
            </div>
        </div>

        <string-choice
            *ngFor="let item of items"
            [format]="format"
            [checked]="equally(item)"
            [text]="item[keyName]"
            (click)="add(item)"
        >
        </string-choice>
    </div>
</div>
