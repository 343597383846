<div class="control-points" *ngIf="errorLoadlist; else templateList">
    <div class="load-error-data">
        <span *ngIf="errorLoadlist.status === 404; else otherError">{{
            translateText.errorLoadList404
        }}</span>
        <ng-template #otherError>{{ translateText.errorListLoad }}</ng-template>
    </div>
</div>
<ng-template #templateList>
    <div
        class="wrapper scroll"
        *ngIf="loaded$ | async as loaded; else loadingTemp"
        (scroll)="onScroll()"
    >
        <div class="add-button-wrapper">
            <button-add
                [text]="translateText.addControlPoint"
                (action)="addControlPoints()"
            ></button-add>
        </div>
        <div class="sort-wrapper">
            <div class="control-sort-name">
                <shared-sort-list-item
                    class="point_list__sort_name"
                    (click)="onSortingPoint(sortingPointName, 'name')"
                    [sortingDirection]="sortField === 'name' ? sortDirection : 0"
                    [text]="translateText.controlPointsTitle"
                ></shared-sort-list-item>
            </div>
            <div class="control-sort-mmt">
                <shared-sort-list-item
                    class="point_list__sort_name"
                    (click)="onSortingPoint(sortingPointValue, 'value')"
                    [sortingDirection]="sortField === 'value' ? sortDirection: 0"
                    [text]="mmtNames[currentMmt]"
                ></shared-sort-list-item>
            </div>
        </div>
        <div class="list-wrapper">
            <div class="loading" *ngIf="loading$ | async as loadingService; else listTemplate">
                <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
            </div>
            <ng-template #listTemplate>
                <div *ngIf="controlPoints as list">
                    <div class="list" *ngIf="list.length !== 0; else noData">
                        <cs-control-point
                            *ngFor="let item of list | sorting : sortingPoint : sortDirection * -1"
                            [item]="item"
                            [isActive]="item.id === activePoint?.id"
                            [units]="
                                unitsMmt[store.select(selectMeasureScheme) | async][currentMmt]
                            "
                            [numberAfterDot]="
                                getDigitsAfterDot(
                                    store.select(selectMeasureScheme) | async,
                                    currentMmt
                                )
                            "
                            [isObserver]="isObserver"
                            [formattedValue]="item | getValueControlPoint : currentMmt : timeIndex"
                            (click)="setActive(item)"
                            (openMenu)="clickMenu($event, item)"
                        ></cs-control-point>
                    </div>
                    <ng-template #noData>
                        <div class="no-data-wrapper-control-points">
                            <non-data-screen [text]="translateText.noData"></non-data-screen>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>
<ng-template #loadingTemp>
    <div class="wrapper-loading">
        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
    </div>
</ng-template>
<ng-template #cardMenu>
    <popup-ask
        class="posts-list-view__popup-ask"
        *ngIf="isShowDeletePopup"
        [@inOutAnimation]
        (cbAccept)="deleteAccept()"
        (cbClose)="deleteCancel()"
        [title]="translateText.titleDeletePopup(currentPoint.name)"
        [description]="translateText.bodyDeletePopup"
        [textAccept]="translateText.accept"
        [textCancel]="translateText.cancel"
        [showCloseIcon]="false"
    >
    </popup-ask>

    <card-actions-list
        class="card-actions-list"
        *ngIf="isShowMenu"
        [@inOutAnimation]
        [position]="menuPositionTop"
        (clickOutside)="closePopup($event)"
    >
        <card-actions-item
            data-cy="action-close"
            [text]="translateText.edit"
            (click)="editPoint($event, currentPoint.id)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
            data-cy="action-close"
            type="danger"
            [text]="translateText.deleteList"
            (click)="showPopup($event, 'close')"
        ></card-actions-item>
    </card-actions-list>
</ng-template>
