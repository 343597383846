import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from '@libs/shared-ui';
import { PipesModule } from '@libs/shared-ui';
import { SortList } from './sort-list/sort-list.component';
import { HeaderPageComponent } from './header-page/header-page.component';
import { ControlSelectComponent } from './control-select/control-select.component';
import { StringChoiceComponent } from './string-choice/string-choice.component';

import {
    StationLoadingSplash,
    StndAskPopup,
    StndAskPopup2,
    StndAskPopupThreeQuestions,
    StndErrorPopup,
    StndPopup,
} from './blank';

import { Loader, LoaderMap } from './imgs';
import { TooltipsModule } from '@libs/shared-ui';
import { ArrowModule } from '@libs/shared-ui';

import { ListHeaderModule } from './list-header/list-header.module';
import { ScrollTopModule } from './scroll-top/scroll-top.module';
import { SubstancesListModule } from './substances-list/substances-list.module';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { CrossButtonModule } from '@libs/shared-ui';
import { SearchInputModule } from '@libs/shared-ui';
import { ItemRadioModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';
import { TagsListModule } from '@libs/shared-ui';
import { SwitchItemModule } from '@libs/shared-ui';
import { AqiDetailsTooltipModule } from '@libs/shared-ui';
import { CheckboxModule } from '@libs/shared-ui';
import { ValueCircleModule } from '@libs/shared-ui';
import { ButtonModule } from '@libs/shared-ui';
import { ButtonsSaveCancelModule } from '@libs/shared-ui';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        TooltipsModule,
        LoadersModule,
        ArrowModule,
        CrossButtonModule,
        SearchInputModule,
        ItemRadioModule,
        TagsListModule,
        SwitchItemModule,
        CheckboxModule,
        AqiDetailsTooltipModule,
        ValueCircleModule,
        ListHeaderModule,
        ScrollTopModule,
        SubstancesListModule,
        ButtonModule,
        ButtonsSaveCancelModule,
    ],
    exports: [
        Loader,
        LoaderMap,
        StndPopup,
        StndErrorPopup,
        StndAskPopup,
        StndAskPopupThreeQuestions,
        StndAskPopup2,
        SortList,
        StationLoadingSplash,
        HeaderPageComponent,
        ControlSelectComponent,
        StringChoiceComponent,
        SubstancesListModule,
        DropdownButtonComponent,
        ListHeaderModule,
        ScrollTopModule,
        ButtonModule,
        ButtonsSaveCancelModule,
    ],
    declarations: [
        Loader,
        LoaderMap,
        StndPopup,
        StndErrorPopup,
        StndAskPopup,
        StndAskPopupThreeQuestions,
        StndAskPopup2,
        SortList,
        StationLoadingSplash,
        HeaderPageComponent,
        ControlSelectComponent,
        StringChoiceComponent,
        DropdownButtonComponent,
    ],
})
export class LittleComponentsModule {}
