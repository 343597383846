import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { AqiChartComponent } from '@cityair/modules/indoor/components/aqi-chart/aqi-chart.component';

@NgModule({
    imports: [CommonModule, BaseChartDirective],
    declarations: [AqiChartComponent],
    exports: [AqiChartComponent],
})
export class AqiChartModule {}
