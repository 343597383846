<div class="notifications_container notifications_service scroll" (scroll)="onScroll($event)">
    <ca-list-header
        [text]="textsNotification.activeSubscriptions"
        [itemsCount]="active?.length"
    >
    </ca-list-header>
    <div
        class="notifications__empty"
        *ngIf="settings?.length === 0; else subscriptionsList"
    >
        <div class="notifications_tips">
            <div class="notifications_tips__img notifications_tips__img-service"></div>
            <div class="notifications_tips__text">
                <div
                    class="notifications_tips__text-bold"
                    [innerHTML]="textsNotification.serviceDescription1"
                ></div>
                <div
                    class="notifications_tips__text-small"
                    [innerHTML]="textsNotification.addSubscriptionHint"
                ></div>
            </div>
        </div>
    </div>

    <ng-template #subscriptionsList>
        <div class="notifications__subscriptions-list">
            <subscription-card
                *ngFor="let sub of active"
                [subscription]="sub"
            >
                <subscription-card-service [subscription]="sub"></subscription-card-service>
            </subscription-card>

            <subscription-card
                *ngFor="let sub of deactivated"
                [subscription]="sub"
            >
                <subscription-card-service [subscription]="sub"></subscription-card-service>
            </subscription-card>
        </div>
    </ng-template>
</div>
