import { LngLatBounds, LngLatLike, MercatorCoordinate } from 'mapbox-gl';
import * as twgl from 'twgl.js';
import { drawVs } from './shaders/draw.vs';
import { drawFs } from './shaders/draw.fs';
import { screenFs } from './shaders/screen.fs';
import { quadVs } from './shaders/quad.vs';
import { updateFs } from './shaders/update.fs';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { drawolVs } from './shaders/drawol.vs';

enum windRenderingState {
    'PAUSED',
    'RENDERING',
}

function loadWindDataWithToken(url, token) {
    return fetch(url, {
        headers: { Authorization: `Bearer ${token}` },
        credentials: 'include',
    }).then((response) => response.blob());
}

export async function loadWindData(url, bounds, range, token?) {
    return new Promise(async (resolve, reject) => {
        const data = new Image();
        if (token) {
            from(loadWindDataWithToken(url, token))
                .pipe(catchError((error) => of(null)))
                .subscribe((response) => {
                    if (response) {
                        data.src = URL.createObjectURL(response);
                        data.onload = () =>
                            resolve({
                                data,
                                bounds,
                                range,
                            });
                        data.onerror = () => resolve(null);
                    } else {
                        reject(null);
                    }
                });
        } else {
            data.crossOrigin = 'anonymous';
            data.src = url;
            data.onload = () =>
                resolve({
                    data,
                    bounds,
                    range,
                });
            data.onerror = () => resolve(null);
        }
    });
}

export function WindVector(gl, map, isOl) {
    let data;
    let bounds;
    let range;
    let drawProgramInfo;
    let textures;
    let screenProgramInfo;
    let updateProgramInfo;
    let particleTextures;
    let numParticles;
    let framebuffer;
    let particleIndices;
    let particleRes;
    let state = windRenderingState.PAUSED;
    let mapBounds;
    let fadeOpacity;
    let speedFactor;
    let dropRate;
    let dropRateBump;
    let animationId;

    const nParticles = isOl ? 5000 : 6000;

    function setBounds(bounds) {
        const nw = bounds.getNorthWest();
        const se = bounds.getSouthEast();
        const nwMercator = MercatorCoordinate.fromLngLat(nw);
        const seMercator = MercatorCoordinate.fromLngLat(se);
        mapBounds = [nwMercator.x, seMercator.y, seMercator.x, nwMercator.y];
    }

    function setData(dataObject) {
        ({ data, bounds, range } = dataObject);
        initialize();
        startAnimation();
    }

    function setParticles(num) {
        // we create a square texture where each pixel will hold a particle position encoded as RGBA
        particleRes = Math.ceil(Math.sqrt(num));
        numParticles = particleRes * particleRes;

        const particleState = new Uint8Array(numParticles * 4);

        particleState.map((item) => Math.floor(Math.random() * 256));
        particleTextures = twgl.createTextures(gl, {
            particleTexture0: {
                mag: gl.NEAREST,
                min: gl.NEAREST,
                width: particleRes,
                height: particleRes,
                format: gl.RGBA,
                src: particleState,
                wrap: gl.CLAMP_TO_EDGE,
            },
            particleTexture1: {
                mag: gl.NEAREST,
                min: gl.NEAREST,
                width: particleRes,
                height: particleRes,
                format: gl.RGBA,
                src: particleState,
                wrap: gl.CLAMP_TO_EDGE,
            },
        });

        particleIndices = new Float32Array(numParticles);
        for (let i = 0; i < numParticles; i++) {
            particleIndices[i] = i;
        }
    }

    function initialize() {
        fadeOpacity = isOl ? 0.95 : 0.985;
        speedFactor = 0.575;
        dropRate = 0.003;
        dropRateBump = 0.01;
        drawProgramInfo = isOl
            ? twgl.createProgramInfo(gl, [drawolVs, drawFs])
            : twgl.createProgramInfo(gl, [drawVs, drawFs]);
        screenProgramInfo = twgl.createProgramInfo(gl, [quadVs, screenFs]);
        updateProgramInfo = twgl.createProgramInfo(gl, [quadVs, updateFs]);
        setParticles(nParticles);
        const emptyPixels = new Uint8Array(gl.canvas.width * gl.canvas.height * 4);

        textures = twgl.createTextures(gl, {
            u_image: {
                mag: gl.LINEAR,
                min: gl.LINEAR,
                width: data.width,
                height: data.height,
                format: gl.RGBA,
                src: data,
            },
            backgroundTexture: {
                mag: gl.NEAREST,
                min: gl.NEAREST,
                width: gl.canvas.width,
                height: gl.canvas.height,
                format: gl.RGBA,
                src: emptyPixels,
                wrap: gl.CLAMP_TO_EDGE,
            },
            screenTexture: {
                mag: gl.NEAREST,
                min: gl.NEAREST,
                width: gl.canvas.width,
                height: gl.canvas.height,
                format: gl.RGBA,
                src: emptyPixels,
                wrap: gl.CLAMP_TO_EDGE,
            },
        });

        framebuffer = gl.createFramebuffer();
    }

    function drawParticles() {
        gl.useProgram(drawProgramInfo.program);

        const arrays = {
            a_index: {
                numComponents: 1,
                data: particleIndices,
            },
        };

        const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);

        const uniforms = {
            u_wind: textures.u_image,
            u_particles: particleTextures.particleTexture0,
            u_particles_res: particleRes,
            u_wind_min: [range[0], range[0]],
            u_wind_max: [range[1], range[1]],
            u_bounds: mapBounds,
            u_data_bounds: bounds,
        };

        twgl.setBuffersAndAttributes(gl, drawProgramInfo, bufferInfo);
        twgl.setUniforms(drawProgramInfo, uniforms);

        twgl.drawBufferInfo(gl, bufferInfo, gl.POINTS);
    }

    function drawTexture(texture, opacity) {
        gl.useProgram(screenProgramInfo.program);

        const arrays = {
            a_pos: {
                numComponents: 2,
                data: new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]),
            },
        };

        const uniforms = {
            u_screen: texture,
            u_opacity: opacity,
        };

        const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);
        twgl.setBuffersAndAttributes(gl, screenProgramInfo, bufferInfo);
        twgl.setUniforms(screenProgramInfo, uniforms);
        twgl.drawBufferInfo(gl, bufferInfo);
    }

    function drawScreen() {
        // bind framebuffer
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
        // draw to screenTexture
        gl.framebufferTexture2D(
            gl.FRAMEBUFFER,
            gl.COLOR_ATTACHMENT0,
            gl.TEXTURE_2D,
            textures.screenTexture,
            0
        );
        // set viewport to size of canvas
        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

        // first disable blending
        gl.disable(gl.BLEND);

        // draw backgroundTexture to screenTexture target
        drawTexture(textures.backgroundTexture, fadeOpacity);
        // draw particles to screentexture
        drawParticles();

        // target normal canvas by setting FRAMEBUFFER to null
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);

        // enable blending for final render to map
        gl.enable(gl.BLEND);
        if (!isOl) {
            gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        }

        drawTexture(textures.screenTexture, 1.0);

        gl.disable(gl.BLEND);

        // swap background with screen
        const temp = textures.backgroundTexture;
        textures.backgroundTexture = textures.screenTexture;
        textures.screenTexture = temp;
    }

    function updateParticles() {
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
        gl.framebufferTexture2D(
            gl.FRAMEBUFFER,
            gl.COLOR_ATTACHMENT0,
            gl.TEXTURE_2D,
            particleTextures.particleTexture1,
            0
        );

        gl.viewport(0, 0, particleRes, particleRes);
        gl.useProgram(updateProgramInfo.program);

        const arrays = {
            a_pos: {
                numComponents: 2,
                data: new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]),
            },
        };

        const uniforms = {
            u_wind: textures.u_image,
            u_particles: particleTextures.particleTexture0,
            u_wind_min: [range[0], range[0]],
            u_wind_max: [range[1], range[1]],
            u_rand_seed: Math.random(),
            u_wind_res: [data.width, data.height],
            u_speed_factor: speedFactor,
            u_drop_rate: dropRate,
            u_drop_rate_bump: dropRateBump,
            u_bounds: mapBounds,
            u_data_bounds: bounds,
        };

        const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);
        twgl.setBuffersAndAttributes(gl, updateProgramInfo, bufferInfo);

        twgl.setUniforms(updateProgramInfo, uniforms);

        twgl.drawBufferInfo(gl, bufferInfo);

        const temp = particleTextures.particleTexture0;
        particleTextures.particleTexture0 = particleTextures.particleTexture1;
        particleTextures.particleTexture1 = temp;
    }

    function draw() {
        if (state != windRenderingState.RENDERING) return;
        gl.disable(gl.DEPTH_TEST);
        gl.disable(gl.STENCIL_TEST);

        drawScreen();
        updateParticles();
    }

    function frame() {
        if (isOl) {
            map.render();
        } else {
            map.triggerRepaint();
        }
        animationId = requestAnimationFrame(frame);
    }

    function startAnimation() {
        state = windRenderingState.RENDERING;
        const bounds = isOl ? map.getView().calculateExtent(map.getSize()) : map?.getBounds();
        if (!isOl) {
            setBounds(map.getBounds());
        } else {
            const convertBounds = new LngLatBounds(bounds);
            setBounds(convertBounds);
        }

        frame();
    }

    function stopAnimation() {
        state = windRenderingState.PAUSED;
        clear();
        cancelAnimationFrame(animationId);
    }

    function clear() {
        gl.clearColor(0.0, 0.0, 0.0, 0.0);

        // clear framebuffer textures
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
        gl.framebufferTexture2D(
            gl.FRAMEBUFFER,
            gl.COLOR_ATTACHMENT0,
            gl.TEXTURE_2D,
            textures.screenTexture,
            0
        );
        gl.clear(gl.COLOR_BUFFER_BIT);
        gl.framebufferTexture2D(
            gl.FRAMEBUFFER,
            gl.COLOR_ATTACHMENT0,
            gl.TEXTURE_2D,
            textures.backgroundTexture,
            0
        );
        gl.clear(gl.COLOR_BUFFER_BIT);

        // generate new random particle positions
        setParticles(nParticles);

        // target normal canvas
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);

        // clear canvas
        gl.clear(gl.COLOR_BUFFER_BIT);
    }

    return {
        setData,
        startAnimation,
        stopAnimation,
        draw,
    };
}
