import { Component, Input } from '@angular/core';

@Component({
    selector: 'room-item-circle',
    templateUrl: './room-item-circle.component.html',
    styleUrls: ['./room-item-circle.component.less'],
})
export class RoomItemCircleComponent {
    @Input() title: string;
    @Input() titleDescription: string;
    @Input() grade: number;
    @Input() numberAfterDot?: number = 2;
    @Input() color: string;
}
