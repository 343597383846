import { Component } from '@angular/core';
import { isAvailableModule } from '@cityair/modules/core/store/selectors';
import { Store } from '@ngrx/store';
import {
    selectCityCard,
    selectCurrentSummaryData,
    selectIsDemoAnalytic,
    selectIsLoadingSummary,
    setCityCard,
} from '@libs/shared-store';
import { selectAllCities } from '@libs/shared-store';
import { intervalUpdate } from '@cityair/modules/core/store/actions';
import { Locality, MAIN_PAGES } from '@libs/common';

@Component({
    selector: 'cityscreen-mobile-header',
    template: `
        <cityscreen-mobile-profile></cityscreen-mobile-profile>
        <calendar-m (updateDateRange)="updateDateRange($event)"></calendar-m>
        <ng-container *ngIf="store.select(isAvailableModule(MAIN_PAGES.analytics)) | async">
            <div class="analytic-button-wrapper">
                <div class="analytic-button">
                    <div class="analytic-button__icon" (click)="toggleAnalyticPage()"></div>
                </div>
            </div>
            <ng-container *ngIf="showAnalytic">
                <div class="analytic-wrapper">
                    <cross-button class="close-btn" (click)="toggleAnalyticPage()"></cross-button>
                    <div class="content scroll">
                        <shared-ui-analytic
                            [cities]="store.select(selectAllCities) | async"
                            [selectedCity]="store.select(selectCityCard) | async"
                            [isLoading]="store.select(selectIsLoadingSummary) | async"
                            [distributionSummary]="store.select(selectCurrentSummaryData) | async"
                            [isDemo]="store.select(selectIsDemoAnalytic) | async"
                            (setCityCard)="selectCity($event)"
                        ></shared-ui-analytic>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    `,
    styleUrls: ['./mobile-header.component.less'],
})
export class MobileHeaderComponent {
    selectAllCities = selectAllCities;
    selectCityCard = selectCityCard;
    selectIsLoadingSummary = selectIsLoadingSummary;
    selectCurrentSummaryData = selectCurrentSummaryData;
    isAvailableModule = isAvailableModule;
    selectIsDemoAnalytic = selectIsDemoAnalytic;
    MAIN_PAGES = MAIN_PAGES;
    public showAnalytic = false;

    constructor(readonly store: Store) {}

    updateDateRange(data: { begin: number; end: number }) {
        this.store.dispatch(intervalUpdate(data));
    }

    toggleAnalyticPage() {
        this.showAnalytic = !this.showAnalytic;
    }

    selectCity(city: Locality) {
        this.store.dispatch(setCityCard({ city: city }));
    }
}
