import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, filter, firstValueFrom, of, Subscription, take } from 'rxjs';
import {
    getColorFromZone,
    getDigitsAfterDot,
    IntervalEnum,
    Locality,
    MapPins,
    PinModel,
    TEXTS,
} from '@libs/common';
import {
    selectGlobalMeasurement,
    selectIsCityMode,
    isCompareMode,
    selectPdkForChart,
    selectSourcesAsFeatures,
    selectCurrentTimeByIndex,
    selectTimelineDateTimes,
    selectQualityDataMode,
    selectQualityDataTimeline,
    selectDefaultPins,
    selectPostCurrentValue,
    canClickOnMarker,
    selectDefaultCityPins,
    selectCityCurrentValue,
    selectSchemeAndMeasure,
    selectZone,
} from '@cityair/modules/core/store/selectors';
import {
    updateTimeIndex,
    destroyMainMap,
    initMainMap,
    loadCity,
    mapMarkerClick,
    clickOnCityMarker,
} from '@cityair/modules/core/store/actions';
import {
    getModulePageConfig,
    MapAdapterService,
    selectGroupId,
    selectCurrentMeasureScheme,
    selectSidebarIsOpen,
    selectUserTimezoneLabel,
} from '@libs/shared-store';
import { map } from 'rxjs/operators';
import { comparedItemsIds } from '@cityair/modules/core/store/compared-list/compared-list.selectors';

@Component({
    selector: 'default-map-mobile',
    templateUrl: './default-map-mobile.component.html',
    styleUrls: ['./default-map-mobile.component.less'],
})
export class DefaultMapMobileComponent implements OnInit, OnDestroy {
    selectMeasureScheme = selectCurrentMeasureScheme;
    IntervalEnum = IntervalEnum;
    selectSidebarIsOpen = selectSidebarIsOpen;
    selectSourcesAsFeatures = selectSourcesAsFeatures;
    selectTimelineDateTimes = selectTimelineDateTimes;
    selectIsComparisonModeActive = isCompareMode;
    selectPdkForChart = selectPdkForChart;
    updateTimeIndex = updateTimeIndex;
    selectCurrentTimeByIndex = selectCurrentTimeByIndex;
    selectGlobalMeasurement = selectGlobalMeasurement;
    selectIsCityMode = selectIsCityMode;
    selectQualityDataMode = selectQualityDataMode;
    selectQualityDataTimeline = selectQualityDataTimeline;
    selectUserTimezone = selectUserTimezoneLabel;

    moduleConfig$ = this.store.select(getModulePageConfig);

    private subscriptions: Subscription[] = [];

    TEXTS = TEXTS;
    isCompare = false;

    constructor(public store: Store, private mapAdapterService: MapAdapterService) {}

    ngOnInit() {
        this.store.dispatch(initMainMap());

        this.store
            .select(selectGroupId)
            .pipe(
                filter((g) => !!g),
                take(1)
            )
            .subscribe(async (g) => {
                this.setDefaultMap();
            });
    }

    ngOnDestroy() {
        this.store.dispatch(destroyMainMap());

        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    public goToCity(cityId: string) {
        this.store.dispatch(loadCity({ cityId, centringMap: true }));
    }

    private async setDefaultMap() {
        const postPins: MapPins = {
            getPins: this.store.select(selectDefaultPins).pipe(map((v) => v as PinModel[])),
            selectDigitsAfterDot: this.store
                .select(selectSchemeAndMeasure)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.store.select(comparedItemsIds),
            getValue: (pin) => this.store.select(selectPostCurrentValue(pin.id)),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectZone),
                    this.store.select(selectPostCurrentValue(pin.id)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),

            clickCb: async (pin) =>
                (await firstValueFrom(this.store.select(canClickOnMarker(pin.id)))) &&
                this.store.dispatch(mapMarkerClick({ markerId: pin.id })),
            isCollapsed: (id) => of(false),
        };

        const cityPins: MapPins = {
            getPins: this.store.select(selectDefaultCityPins).pipe(map((v) => v as PinModel[])),
            selectDigitsAfterDot: this.store
                .select(selectSchemeAndMeasure)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.store.select(comparedItemsIds),
            getValue: (pin) => this.store.select(selectCityCurrentValue(pin.id)),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectZone),
                    this.store.select(selectCityCurrentValue(pin.id)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),

            clickCb: async (pin) => {
                const canClickMarker = await firstValueFrom(
                    this.store.select(canClickOnMarker(pin.id))
                );

                if (canClickMarker) {
                    this.store.dispatch(clickOnCityMarker({ cityMarker: pin as Locality }));
                }
            },
        };
        this.mapAdapterService.set({
            cityPins,
            postPins,
            groupFeaturesLayer: await this.mapAdapterService.getDefaultGroupFeaturesLayer(),
            onMapDragEnd: this.mapAdapterService.defaultMapDragEnd,
            onMapZoomChanged: this.mapAdapterService.defaultMapZoomChanged,
        });
    }
}
