import { Injectable } from '@angular/core';
import { isRU } from '@libs/common';
import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { URLS, VangaAuthService } from '@libs/shared-store';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class VangaService {
    private accessToken = '';
    private lang = isRU ? 'ru' : 'en';

    constructor(private http: HttpClient, private vangaAuthService: VangaAuthService) {}

    getRuns(params: HttpParamsOptions): Observable<any> {
        const httpOptions = this.getHttpOptions(params);

        return this.http.get(URLS.impactRun, httpOptions);
    }

    getControlPoints(groupId: string): Observable<any> {
        const params: HttpParamsOptions = { fromObject: { group_id: groupId } };
        const httpOptions = this.getHttpOptions(params);

        return this.http.get(URLS.controlPoints, httpOptions);
    }

    getControlPointsTimeline(data: { id: number; params: HttpParamsOptions }): Observable<any> {
        const httpOptions = this.getHttpOptions(data.params);
        const url = URLS.impactRun + data.id + '/control_points/';

        return this.http.get(url, httpOptions);
    }

    private getHttpOptions(data?: HttpParamsOptions): any {
        const token = this.vangaAuthService.getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': this.lang,
        };

        if (data) {
            const params = new HttpParams(data);
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }

    private getAccessToken() {
        return this.accessToken || (this.accessToken = localStorage.getItem('token'));
    }
}
