import { Pipe, PipeTransform } from '@angular/core';
import { Feature, getColorByIndex } from '@libs/common';
import { CONTRIBUTIONS_COLORS_SOURCES_ORDER } from '@libs/common';
import { ContributionLabel } from './../../models/core';

@Pipe({
    name: 'contributionLabels',
})
export class ContributionLabelsPipe implements PipeTransform {
    transform(data: Feature[], mmts: string[]): ContributionLabel[] {
        const labels: ContributionLabel[] = [];
        if (data.length === 1 && mmts.length === 1 && data[0]?.properties?.contributions) {
            if (data[0]?.properties?.has_any_contribution) {
                const contribData = data[0]?.properties?.contributions[mmts[0]];
                if (contribData) {
                    const sources = data[0]?.properties?.contributionsDetails?.sources;
                    const order = data[0]?.properties?.contributionsDetails?.order;
                    order.forEach((key, k) => {
                        const color = getColorByIndex(CONTRIBUTIONS_COLORS_SOURCES_ORDER, k);
                        labels.push({
                            id: key.toString(),
                            color,
                            name: sources[key]?.name,
                        });
                    });
                }
            }
        }

        return labels;
    }
}
