import { Component, OnDestroy } from '@angular/core';
import { NgLocalization } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';

import { MoItemsDataToExcelRequestProps } from '@cityair/namespace';
import { TEXTS, LANGUAGE, CheckboxItem, postsListLabels } from '@libs/common';
import { LOAD_HISTORY_DEFAULT } from '@cityair/libs/shared/utils/config';
import { selectCurrentMo } from '@cityair/modules/core/store/selectors';
import {
    downloadDataPosts,
    selectDownloadResult,
} from '@cityair/modules/core/store/posts/posts.feature';
import { selectAllPosts } from '@libs/shared-store';
import { PATH } from '../../components/post-create/post-create.component';
import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';
import { Subject } from 'rxjs';

@Component({
    selector: 'post-report-tza',
    templateUrl: './post-report-tza.component.html',
    styleUrls: ['./post-report-tza.component.less'],
})
export class PostReportTzaComponent implements OnDestroy {
    TEXTS = TEXTS;
    pages = NETWORK_PAGES;
    navigationLink: PATH[] = [];
    isActiveLoader = false;
    errorDownloadMsg = '';
    targetMo = '';
    targetMoId: string = null;
    objectForReportMo: MoItemsDataToExcelRequestProps = {
        timeBegin: null,
        timeEnd: null,
        moIds: [],
        interval: 2,
        type: 2,
        unitsType: 1,
        insertPdk: false,
    };

    isShowDropdownForStations = false;

    listStations: CheckboxItem[] = [];
    private onDestroy$ = new Subject<void>();
    constructor(
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        private activatedRoute: ActivatedRoute,
        private ngLocalization: NgLocalization,
        public store: Store<any>
    ) {
        const idRouter = this.activatedRoute.snapshot.params.id;
        const isCheckExists = this.postsAndDevicesFacade.checkExistsPage(idRouter);
        if (isCheckExists) {
            this.getDataForReportTZA();
        } else {
            this.postsAndDevicesFacade.openPage(this.pages.networks + '/' + this.pages.postsList);
        }
        this.store
            .select(selectDownloadResult)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                if (data !== null) {
                    this.isActiveLoader = false;
                }
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    getDataForReportTZA() {
        this.objectForReportMo = { ...this.objectForReportMo, timeBegin: this.getStndTimeBegin() };
        this.objectForReportMo = { ...this.objectForReportMo, timeEnd: this.getStndTimeEnd() };
        const isAllMo = this.activatedRoute.snapshot.params.id === 'all';

        this.store
            .select(selectCurrentMo)
            .pipe(take(1))
            .subscribe((scm) => {
                this.targetMoId = scm?.id;
            });

        this.store
            .select(selectAllPosts)
            .pipe(filter((mos) => !!mos.length))
            .subscribe((mos) => {
                this.listStations = mos.map((s) => {
                    const isCurrentMo =
                        s.id === this.activatedRoute.snapshot.params.id ||
                        this.targetMoId === s.id.toString();

                    if (isCurrentMo) {
                        this.targetMo = s.name;
                    }

                    return {
                        id: s.id,
                        label: s.name,
                        selected: isCurrentMo || isAllMo,
                    };
                });
            });

        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.postAndDevices,
            path: this.pages.networks + '/' + this.activatedRoute.snapshot.url[0],
        });

        if (this.activatedRoute.snapshot.url[1].path == this.pages.details) {
            this.navigationLink.push({
                name: this.targetMo,
                path:
                    this.pages.networks +
                    '/' +
                    this.activatedRoute.snapshot.url[0] +
                    '/' +
                    this.activatedRoute.snapshot.url[1] +
                    '/' +
                    this.activatedRoute.snapshot.params.id,
            });
        }

        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.tza4,
            path: null,
        });
    }

    getStndTimeBegin: () => number = () =>
        moment().startOf('hour').subtract(LOAD_HISTORY_DEFAULT, 'days').valueOf();

    getStndTimeEnd: () => number = () => {
        const m = moment();
        m.startOf('minutes');
        m.subtract(m.get('minutes') % 20, 'minutes'); // floor of 20 minutes
        return m.valueOf();
    };

    getDateBeginRangeFromCalendar(time) {
        this.objectForReportMo = { ...this.objectForReportMo, timeBegin: time };
    }

    getDateEndRangeFromCalendar(time) {
        this.objectForReportMo = { ...this.objectForReportMo, timeEnd: time };
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    selectedCheckboxText(num: number = 0) {
        const { selected, post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);

        return [selected[category], num, post[category]].join(' ');
    }

    removeFromList(index: number) {
        const label = this.getTags()[index];
        this.listStations.find((s) => {
            if (s.label === label) {
                s.selected = false;
                return true;
            }
        });

        this.listStations = [...this.listStations];
    }

    getTags = () => this.listStations.filter((s) => s.selected).map((s) => s.label);

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    downloadReportMo = () => {
        this.isActiveLoader = true;
        // convert when wait new api for download by new ids
        const moIds = this.listStations
            .filter((s) => s.selected)
            .map((s) => {
                const id = s.id.toString().split('_');
                return id[1];
            });
        this.objectForReportMo = { ...this.objectForReportMo, moIds };
        const filename =
            this.TEXTS.EDIT_STATION.downloadReport + moment().format('_YYYYMMDD_HHmm') + '.xlsx';
        this.store.dispatch(downloadDataPosts({ params: this.objectForReportMo, filename }));
    };

    offActiveLoader() {
        setTimeout(() => {
            this.isActiveLoader = false;
        }, 1000);
    }
}
