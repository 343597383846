<div class="plumes-calculation">
    <div class="info-wrapper">
        <div class="plumes-calculation__interval first">
            <div class="plumes-calculation__interval__title">
                {{ textsPlumes.cardRun.interval }}
            </div>
            <div class="plumes-calculation__interval__units">
                {{ valText }}
            </div>
        </div>
        <div class="plumes-calculation__interval">
            <div class="plumes-calculation__interval__title">
                {{ textsPlumes.cardRun.duration }}
            </div>
            <div class="plumes-calculation__interval__units">
                {{ valDurationsText }}
            </div>
        </div>
        <div class="plumes-calculation__interval">
            <div class="plumes-calculation__interval__title">
                {{ textsPlumes.cardRun.wind }}
            </div>
            <div class="plumes-calculation__interval__units">
                <span *ngIf="windIsOn else offWind">{{ textsPlumes.cardRun.windOn }}</span>
                <ng-template #offWind>{{ textsPlumes.cardRun.windOff }}</ng-template>
            </div>
        </div>
    </div>
    <div class="plumes-calculation__substances">
        <div class="plumes-calculation__interval__title">{{textsPlumes.cardRun.measures}}</div>
        <ca-substances-list [items]="mmts" [type]="'blue'"></ca-substances-list>
    </div>
    <ng-container *ngIf="sources?.length">
        <div class="sources-list-wrapper">
            <div class="header">
                <div class="name">{{textsPlumes.sourcesListTitle}}</div>
                <div class="count"><sup>({{sources?.length}})</sup></div>
            </div>
            <div class="toggle-button" *ngIf="!isShowSourcesList" (click)="isShowSourcesList=true">{{textShowList}}</div>
            <div class="sources-list" *ngIf="isShowSourcesList">
                <div class="source" *ngFor="let source of sources">
                    <div class="item" *ngIf="sourcesSnapshot[source] as current">{{current?.name}}</div>
                </div>
            </div>
            <div class="toggle-button" *ngIf="isShowSourcesList" (click)="isShowSourcesList=false">{{textsPlumes.expandControlPoint}}</div>
        </div>
    </ng-container>
</div>
