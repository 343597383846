<div class="map-object-selector" (clickOutside)="showDropDown = false">
    <input-for-dropdown
        [inputValue]="'Объекты на карте'"
        [(isOpenDropdown)]="showDropDown"
    ></input-for-dropdown>
    <div class="selectbox-wrapper" *ngIf="showDropDown">
        <cs-selectbox
            [listItems]="list"
            (listItemsChange)="change($event)"
            [selectAllText]="'Выбрать все'"
            [showSearch]="false"
        ></cs-selectbox>
    </div>
</div>
