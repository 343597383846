import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    TemplateRef,
    ViewContainerRef,
    AfterViewInit,
} from '@angular/core';

const ANIMATION_TIMEOUT = 200;

@Directive({
    selector: '[clickOutside]',
})
export class ClickOutsideDirective {
    @Output() clickOutside: EventEmitter<Event> = new EventEmitter();

    constructor(private el: ElementRef) {}

    @HostListener('window:click', ['$event'])
    private onClickBody(event: Event) {
        if (typeof event !== 'undefined' && !this.el.nativeElement.contains(event.target)) {
            this.clickOutside.emit(event);
        }
    }
}

@Directive({
    selector: '[afterInitDirective]',
})
export class AfterInitDirective implements AfterViewInit {
    @Output() afterInitDirective: EventEmitter<Event> = new EventEmitter();

    ngAfterViewInit() {
        this.afterInitDirective.emit();
    }
}

@Directive({
    selector: '[showDirective]',
})
export class ShowDirective {
    el: HTMLElement;

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

    @Input() set showDirective(show: boolean) {
        if (show) {
            const viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
            const [el] = viewRef.rootNodes;
            this.el = el;
            el?.classList?.add('hide_show');
            setTimeout(() => el?.classList?.add('hide_show-show'), 50);
        } else {
            this.el?.classList?.remove('hide_show-show');
            setTimeout(() => this.viewContainer.clear(), ANIMATION_TIMEOUT);
        }
    }
}

@Directive({
    selector: '[isScrollTop]',
})
export class IsScrollTopDirective {
    @Output() isTop: EventEmitter<boolean> = new EventEmitter();

    constructor(private element: ElementRef) {}

    @HostListener('scroll') onMouseEnter() {
        this.isTop.emit(this.element.nativeElement.scrollTop === 0);
    }
}
