import { Component, Input } from '@angular/core';

@Component({
    selector: 'ca-layer-button-item',
    templateUrl: 'layer-button-item.component.html',
    styleUrls: ['layer-button-item.component.less'],
})
export class LayerButtonItemComponent {
    @Input() label: string;
    @Input() active = false;
    @Input() isLoading = false;
}
