import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChartDataset, ChartType } from 'chart.js';
import { options } from './city-district-chart.config';

@Component({
    selector: 'city-district-chart',
    templateUrl: './city-district-chart.component.html',
    styleUrls: ['./city-district-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityDistrictChartComponent implements OnInit {
    @Input() data: number[];
    @Input() tooltipDescription: string;
    @Input() measure?: string = '%';
    @Input() labels: string[] = [];
    @Input() colors: string[] = [];

    options = options;
    public doughnutChartType: ChartType = 'doughnut';
    public tooltip: string;
    public datasets: ChartDataset<'doughnut', number[]>[] = [];

    ngOnInit() {
        const backgroundColor = this.colors;
        this.datasets = [
            {
                data: this.data,
                backgroundColor,
                hoverBackgroundColor: backgroundColor,
                hoverBorderColor: 'transparent',
            },
        ];
    }
}
