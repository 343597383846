import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    selectSidebarIsOpen,
    SharedCoreActions,
} from '@libs/shared-store';
@Component({
    selector: 'posts-and-devices-page',
    templateUrl: './posts-and-devices-page.component.html',
    styleUrls: ['./posts-and-devices-page.component.less'],
})
export class PostsAndDevicesPageComponent {
    selectSidebarIsOpen = selectSidebarIsOpen;
    constructor(public store: Store) {}
    public toggleSideBar() {
        this.store.dispatch(SharedCoreActions.toggleSidebar({ payload: null }));
    }
}
