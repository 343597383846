import { Component } from '@angular/core';

@Component({
    selector: 'ca-cityair-logo',
    templateUrl: 'cityair-logo.component.svg',
    styles: [
        `
            :host {
                display: flex;
            }
        `,
    ],
})
export class CityairLogoComponent {}
