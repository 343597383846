<ng-template #popupDownload>
    <ca-popup-confirm
        *ngIf="downloadType === MoExportType.tza4"
        [isSaveBtnDisabled]="isSaveBtnDisabled"
        [title]="title"
        [isActiveLoader]="isActiveLoader"
        [btnAccept]="TEXTS.DOWNLOAD_POPUP.download"
        (cbCloseBtn)="closeDownloadPopup()"
        (cbCancelBtn)="closeDownloadPopup()"
        (cbAcceptBtn)="downloadReportMo()"
    >
        <download-popup-mo
            [selectedIds]="ids"
            [downloadPopupObj]="downloadPopupObj"
            [monitoringObjects]="mos"
            [errorDownloadMsg]="errorDownloadMsg"
            (objectForReport)="getObjectForReportMO($event)"
            (getSaveBtnDisabled)="getSaveBtnDisabled($event)"
        ></download-popup-mo>
    </ca-popup-confirm>
    <ca-popup-confirm
        *ngIf="downloadType === MoExportType.mo || downloadType === MoExportType.moIndoor"
        [isSaveBtnDisabled]="isSaveBtnDisabled"
        [title]="title"
        [btnAccept]="TEXTS.DOWNLOAD_POPUP.download"
        [isActiveLoader]="isActiveLoader"
        (cbCloseBtn)="closeDownloadPopup()"
        (cbCancelBtn)="closeDownloadPopup()"
        (cbAcceptBtn)="downloadReportMo()"
    >
        <download-popup-mo
            [selectedIds]="ids"
            [downloadPopupObj]="downloadPopupObj"
            [errorDownloadMsg]="errorDownloadMsg"
            [monitoringObjects]="mos"
            [currentTabInterval]="currentTabInterval"
            (objectForReport)="getObjectForReportMO($event)"
            (getSaveBtnDisabled)="getSaveBtnDisabled($event)"
        ></download-popup-mo>
    </ca-popup-confirm>
</ng-template>
