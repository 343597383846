import { Component, Input } from '@angular/core';

@Component({
    selector: 'cs-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.less'],
})
export class CheckboxComponent {
    @Input() checked: boolean;
    @Input() disabled: boolean;
}
