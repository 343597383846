import { Component, Input } from '@angular/core';

export type Direction = 'up' | 'down';

@Component({
    selector: 'ca-triangle-chevron',
    templateUrl: 'triangle-chevron.component.html',
    styleUrls: ['triangle-chevron.component.less'],
})
export class TriangleChevronComponent {
    @Input() direction: Direction = 'down';
}
