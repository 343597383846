import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubstanceTagComponent } from './substance-tag.component';

@NgModule({
    imports: [CommonModule],
    exports: [SubstanceTagComponent],
    declarations: [SubstanceTagComponent],
})
export class SubstanceTagModule {}
