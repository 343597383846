import {
    createFeature,
    createReducer,
    createActionGroup,
    props,
    on,
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';
import { ColorZone, ControlPointImpact, DateRangeImpact, SourceImpact } from '@libs/common';
import { ExcessStatistic, ImpactStatistic } from '../service/api-model-impact';
import {
    INIT_IMPACT_CONTROL_POINT_DATE,
    INIT_IMPACT_REGION_DATE,
    INIT_IMPACT_SOURCE_DATE,
} from '../consts';

import { HttpParamsOptions } from '@angular/common/http';
import { RegionApi } from '@libs/common';
import { getMockDataStatRegion } from '@cityair/modules/impact/utils';
import { selectCurrentMeasureScheme, selectMeasuresZones } from '@libs/shared-store';

export const IMPACT_STATISTIC_FEATURE_KEY = 'impactStat';

export const ImpactStatActions = createActionGroup({
    source: 'ImpactStat',
    events: {
        'Set Region': props<{ payload: RegionApi }>(),
        'Set Control Point': props<{ payload: ControlPointImpact }>(),
        'Set Source': props<{ payload: SourceImpact }>(),
        'Update Date Range Source': props<{ payload: DateRangeImpact }>(),
        'Update Date Range Control Point': props<{ payload: DateRangeImpact }>(),
        'Update Date Range Region': props<{ payload: DateRangeImpact }>(),
        'Set Mock Data Region': props<{ payload: { sources: SourceImpact[]; mmts: string[] } }>(),
        'Set Mock Data ControlPoint': props<{
            payload: { sources: SourceImpact[]; mmts: string[] };
        }>(),
        'Init Region Mmt': props<{ payload: string }>(),
        'Init Control Point Mmt': props<{ payload: string }>(),
        'Init Source Mmt': props<{ payload: string }>(),
        'Update Region Mmt': props<{ payload: string }>(),
        'Update Control Point Mmt': props<{ payload: string }>(),
        'Update Source Mmt': props<{ payload: string }>(),
        'Set Excess Statistics': props<{ payload: ExcessStatistic }>(),
        'Set Impact Statistics': props<{ payload: ImpactStatistic }>(),
        'Loading Stat for Region': props<{ payload: boolean }>(),
        'Loading Stat For Control Point': props<{ payload: boolean }>(),
        'Loading Stat for Source': props<{ payload: boolean }>(),
    },
});
interface ImpactStatState {
    dateRangeSource: DateRangeImpact;
    dateRangeControlPoint: DateRangeImpact;
    dateRangeRegion: DateRangeImpact;
    currentRegion: RegionApi;
    currentControlPoint: ControlPointImpact;
    currentSource: SourceImpact;
    regionMmt: string;
    controlPointMmt: string;
    sourceMmt: string;
    isLoadingStatRegion: boolean;
    isLoadingStatControlPoint: boolean;
    isLoadingStatSource: boolean;
    excessStatistics: ExcessStatistic;
    impactStatistics: ImpactStatistic;
}
const initialState: ImpactStatState = {
    dateRangeRegion: INIT_IMPACT_REGION_DATE,
    dateRangeControlPoint: INIT_IMPACT_CONTROL_POINT_DATE,
    dateRangeSource: INIT_IMPACT_SOURCE_DATE,
    currentRegion: null,
    currentControlPoint: null,
    currentSource: null,
    regionMmt: null,
    controlPointMmt: null,
    sourceMmt: null,
    isLoadingStatRegion: false,
    isLoadingStatControlPoint: false,
    isLoadingStatSource: false,
    excessStatistics: null,
    impactStatistics: null,
};

const impactSelector = createFeatureSelector<ImpactStatState>(IMPACT_STATISTIC_FEATURE_KEY);

export const impactStatFeature = createFeature({
    name: IMPACT_STATISTIC_FEATURE_KEY,
    reducer: createReducer(
        initialState,
        on(ImpactStatActions.setRegion, (state, { payload }) => ({
            ...state,
            currentRegion: payload,
        })),
        on(ImpactStatActions.setControlPoint, (state, { payload }) => ({
            ...state,
            currentControlPoint: payload,
        })),
        on(ImpactStatActions.setSource, (state, { payload }) => ({
            ...state,
            currentSource: payload,
        })),
        on(ImpactStatActions.updateDateRangeSource, (state, { payload }) => ({
            ...state,
            dateRangeSource: payload,
        })),
        on(ImpactStatActions.updateDateRangeControlPoint, (state, { payload }) => ({
            ...state,
            dateRangeControlPoint: payload,
        })),
        on(ImpactStatActions.updateDateRangeRegion, (state, { payload }) => ({
            ...state,
            dateRangeRegion: payload,
        })),
        on(ImpactStatActions.loadingStatForRegion, (state, { payload }) => ({
            ...state,
            isLoadingStatRegion: payload,
        })),
        on(ImpactStatActions.loadingStatForControlPoint, (state, { payload }) => ({
            ...state,
            isLoadingStatControlPoint: payload,
        })),
        on(ImpactStatActions.loadingStatForSource, (state, { payload }) => ({
            ...state,
            isLoadingStatSource: payload,
        })),
        on(ImpactStatActions.updateRegionMmt, (state, { payload }) => ({
            ...state,
            regionMmt: payload,
        })),
        on(ImpactStatActions.initRegionMmt, (state, { payload }) => ({
            ...state,
            regionMmt: payload,
        })),
        on(ImpactStatActions.initControlPointMmt, (state, { payload }) => ({
            ...state,
            controlPointMmt: payload,
        })),
        on(ImpactStatActions.updateControlPointMmt, (state, { payload }) => ({
            ...state,
            controlPointMmt: payload,
        })),
        on(ImpactStatActions.initSourceMmt, (state, { payload }) => ({
            ...state,
            sourceMmt: payload,
        })),
        on(ImpactStatActions.updateSourceMmt, (state, { payload }) => ({
            ...state,
            sourceMmt: payload,
        })),
        on(ImpactStatActions.setExcessStatistics, (state, { payload }) => ({
            ...state,
            excessStatistics: payload,
        })),
        on(ImpactStatActions.setImpactStatistics, (state, { payload }) => ({
            ...state,
            impactStatistics: payload,
        })),
        on(ImpactStatActions.setMockDataRegion, (state, { payload }) => {
            if (payload !== null) {
                const excessStatistics = getMockDataStatRegion(
                    payload?.sources,
                    state.dateRangeRegion,
                    state.regionMmt
                );
                return { ...state, excessStatistics, isLoadingStatRegion: false };
            }
            return { ...state, isLoadingStatRegion: false };
        }),
        on(ImpactStatActions.setMockDataControlPoint, (state, { payload }) => {
            if (payload !== null) {
                const excessStatistics = getMockDataStatRegion(
                    payload?.sources,
                    state.dateRangeControlPoint,
                    state.controlPointMmt
                );
                return { ...state, excessStatistics, isLoadingStatControlPoint: false };
            }
            return { ...state, isLoadingStatControlPoint: false };
        })
    ),
    extraSelectors: ({
        selectDateRangeRegion,
        selectRegionMmt,
        selectCurrentRegion,
        selectDateRangeControlPoint,
        selectControlPointMmt,
        selectCurrentControlPoint,
        selectCurrentSource,
        selectSourceMmt,
    }) => ({
        selectStatsRegionParams: createSelector(
            selectDateRangeRegion,
            selectRegionMmt,
            selectCurrentRegion,
            (dateRange, mmt, region) => {
                if (mmt && dateRange && region) {
                    const params: HttpParamsOptions = {
                        fromObject: {
                            start_date: dateRange.start,
                            finish_date: dateRange.end,
                            species_id: mmt,
                        },
                    };
                    return { id: region.id, params };
                }

                return null;
            }
        ),
        selectStatsControlPointParams: createSelector(
            selectDateRangeControlPoint,
            selectControlPointMmt,
            selectCurrentControlPoint,
            (dateRange, mmt, controlPoint) => {
                if (dateRange && mmt && controlPoint) {
                    const params: HttpParamsOptions = {
                        fromObject: {
                            start_date: dateRange.start,
                            finish_date: dateRange.end,
                            species_id: mmt,
                        },
                    };
                    return { id: controlPoint.id, params };
                }

                return null;
            }
        ),
        selectMmtsListBySource: createSelector(
            selectSourceMmt,
            selectCurrentSource,
            (currentMmt, source) => {
                if (source) {
                    const list = source?.emissions?.map((v) => v.species);
                    const mmt = currentMmt ?? list?.[0];
                    return { list, mmt };
                }
                return null;
            }
        ),
        selectImpactStatsRegionSchemaZones: createSelector(
            selectCurrentMeasureScheme,
            selectRegionMmt,
            selectMeasuresZones,
            (scheme, currentMmt, zones) => {
                if (zones && scheme && currentMmt && zones[scheme][currentMmt]) {
                    return {
                        scheme: scheme,
                        mmt: currentMmt,
                        zone: zones[scheme][currentMmt] as ColorZone,
                    };
                }
                return null;
            }
        ),
        selectImpactStatsPointsSchemaZones: createSelector(
            selectCurrentMeasureScheme,
            selectControlPointMmt,
            selectMeasuresZones,
            (scheme, currentMmt, zones) => {
                if (zones && scheme && currentMmt && zones[scheme][currentMmt]) {
                    return {
                        scheme: scheme,
                        mmt: currentMmt,
                        zone: zones[scheme][currentMmt] as ColorZone,
                    };
                }
                return null;
            }
        ),
    }),
});

export const {
    selectDateRangeSource,
    selectDateRangeControlPoint,
    selectDateRangeRegion,
    selectCurrentRegion,
    selectCurrentControlPoint,
    selectCurrentSource,
    selectRegionMmt,
    selectControlPointMmt,
    selectSourceMmt,
    selectIsLoadingStatRegion,
    selectIsLoadingStatControlPoint,
    selectIsLoadingStatSource,
    selectExcessStatistics,
    selectImpactStatistics,
    selectStatsRegionParams,
    selectStatsControlPointParams,
    selectMmtsListBySource,
    selectImpactStatsRegionSchemaZones,
    selectImpactStatsPointsSchemaZones,
} = impactStatFeature;
