import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'cs-switch-button',
    templateUrl: './switch-button.component.html',
    styleUrls: ['./switch-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchButtonComponent {
    @Input() text = '';
    @Input() active = false;
    @Input() disable = false;
    @Output() activeChange = new EventEmitter<boolean>();

    toggle() {
        if (!this.disable) {
            this.activeChange.emit(!this.active);
        }
    }
}
