import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataQualityComponent } from './data-quality.component';
import { CrossButtonModule } from '@libs/shared-ui';
import { IconsModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { PipesModule } from '@libs/shared-ui';

@NgModule({
    exports: [DataQualityComponent],
    declarations: [DataQualityComponent],
    imports: [CommonModule, CrossButtonModule, IconsModule, TooltipsModule, PipesModule],
})
export class DataQualityModule {}
