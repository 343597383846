import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';

import { MapService } from '../map/map.service';
import Overlay, { Options } from 'ol/Overlay';
import { DragPan } from 'ol/interaction';

@Component({
    selector: 'shared-ui-ol-overlay',
    template:
        '<div [class]="dynamicClass"' +
        ' #content (mouseup)="onMouseUp()"' +
        ' (mousedown)="onMouseDown()"' +
        '>' +
        '<ng-content></ng-content>' +
        '</div>',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayComponent implements OnChanges, OnDestroy, AfterViewInit, OnInit {
    @Input() id?: Options['id'];
    @Input() element?: Options['element'];
    @Input() offset?: Options['offset'];
    @Input() position?: Options['position'];
    @Input() positioning?: Options['positioning'];
    @Input() stopEvent: Options['stopEvent'];
    @Input() insertFirst?: Options['insertFirst'];
    @Input() autoPan?: Options['autoPan'];
    @Input() className?: string;
    @Input() dynamicClass?: string;
    @Input() dragging? = false;

    @Output() overlayDragEnd = new EventEmitter<any>();

    @ViewChild('content', { static: true }) content: ElementRef;

    overlayInstance?: Overlay;

    constructor(private mapService: MapService) {}

    ngOnInit() {}

    onMouseDown() {
        if (!this.dragging) {
            return;
        }

        const map = this.mapService.getMap();
        map.getInteractions().forEach(function (interaction) {
            if (interaction instanceof DragPan) {
                interaction.setActive(false);
            }
        });
        this.overlayInstance.set('dragging', true);
    }

    onMouseUp() {
        if (!this.dragging) {
            return;
        }
        const map = this.mapService.getMap();
        map.getInteractions().forEach(function (interaction) {
            if (interaction instanceof DragPan) {
                interaction.setActive(true);
            }
        });
        this.overlayInstance.set('dragging', false);
        const position = this.overlayInstance.getPosition();
        this.overlayDragEnd.emit(position);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.position?.currentValue) {
            this.overlayInstance?.setPosition(this.position);
        }
        if (changes?.offset && !changes?.offset.isFirstChange()) {
            this.overlayInstance?.setOffset(this.offset);
        }
    }

    ngAfterViewInit() {
        this.mapService.mapCreated$.subscribe(() => {
            this.addOverLay();
        });
    }

    ngOnDestroy() {
        this.removeOverlay();
    }

    private addOverLay() {
        this.overlayInstance = this.mapService.addOverlay({
            overlayOptions: {
                id: this.id,
                element: this.content.nativeElement,
                offset: this.offset,
                position: this.position,
                positioning: this.positioning,
                stopEvent: !!this.stopEvent,
                insertFirst: !!this.insertFirst,
                autoPan: this.autoPan,
                className: this.className,
            },
            overlayEvents: {
                overlayDragEnd: this.overlayDragEnd,
            },
        });
    }

    private removeOverlay() {
        if (this.overlayInstance) {
            this.mapService.removeMarker(this.overlayInstance);
            this.overlayInstance = undefined;
        }
    }
}
