<div class="device_card">
    <div class="device_card__header">
        <div class="device_card__title" (click)="clickForStaticTags('device', serialNumber)">
            {{ serialNumber }}
        </div>
        <div *ngIf="isOnline" class="device_card__wrapper_alert_tag alert_tags-onBattery">
        </div>
        <div class="device_card__wrapper_alert_tag">
            <alert-tags
                [typeTag]="isOnline ? 'royalBlue' : 'gray'"
                [text]="
                    isOnline
                        ? textOnline
                        : textOffline
                "
            ></alert-tags>
        </div>
        <div class="device_card__wrapper_kebab_menu">
            <kebab-menu (clickActions)="openActions($event)"></kebab-menu>
        </div>
    </div>
    <div class="device_card__body">
        <div *ngIf="post !== null" class="device_card__sub_title">{{ textList }}</div>
        <div *ngIf="post === null; else postTemplate"
            class="device_card__not_list device_card__not_mo"
            [innerHTML]="textNotMo"
        ></div>
        <ng-template #postTemplate>
            <div class="device_card__wrapper_list scroll">
                <div class="device_card__wrapper_items device_card__mo_list">
                    <static-tags
                        class="device_card__active_static_tag"
                        (click)="clickForStaticTags('MO', post.id)"
                        [isActiveElement]="true"
                        [text]="post?.name"
                        [description]="null"
                        [isOffline]="false"
                        [typeTag]="'mo'"
                    ></static-tags>
                </div>
            </div>
        </ng-template>
    </div>
</div>
