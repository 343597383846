import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { TEXTS } from '@libs/common';
import { ANIMATION_OPACITY } from '@libs/common';
import { selectMapClickState } from '@cityair/modules/core/store/selectors';
import { OffPanelPopupService } from '@libs/shared-store';

import { setMapClickState } from '@cityair/modules/core/store/actions';
import { filter, take } from 'rxjs/operators';
import { NEW_CONTROL_POINT_OBJ_TYPE, NUMBER_ROUND_COORDINATES } from '../../../../../consts';
import { selectGroupId } from '@libs/shared-store';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { ControlPointImpact, ControlPointImpactRequest, MAIN_PAGES } from '@libs/common';
import {
    ImpactActions,
    selectActiveConfig,
    selectControlPoints,
    selectCoordinates,
    selectIsLoadingControlPointForm,
    selectControlPointFormError,
} from '@cityair/modules/impact/store/impact.feature';

export interface PATH {
    name: string;
    path: string;
}

@Component({
    selector: 'ca-control-point-from',
    templateUrl: './control-point-form.component.html',
    styleUrls: ['./control-point-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class ControlPointFormComponent implements OnInit, OnDestroy {
    public currentId: number;
    selectIsLoadingControlPointForm = selectIsLoadingControlPointForm;
    private groupId: string;
    public ControlPointCreate: UntypedFormGroup;
    public translateText = TEXTS.FORECAST;
    public cancelText = TEXTS.COMMON.cancel;
    public acceptText = TEXTS.LIST_USERS.add;
    public saveText = TEXTS.COMMON.save;
    public deleteText = TEXTS.COMMON.delete;
    public currentPoint: ControlPointImpact;
    public ngDestroyed$ = new Subject<void>();
    public loadingResponse = false;
    public locationOnTheMap = TEXTS.POSTS_AND_DEVICES.locationOnTheMap;
    public isEditMode = false;
    public isShowDeletePopup = false;
    public loading = true;
    public errorResponse: string;
    public lastKeyError: string[] = [];
    public navigationLink: PATH[] = [
        {
            name: this.translateText.controlPointsTitle,
            path: MAIN_PAGES.impact + '/' + IMPACT_PAGES.controlPoints + '/' + IMPACT_PAGES.points,
        },
        {
            name: this.translateText.newControlPoint,
            path: null,
        },
    ];
    selectActiveConfig = selectActiveConfig;
    public infoText: string;
    public showDomainError = false;
    @ViewChild('deleteControl', { static: true }) deleteControl: TemplateRef<HTMLDivElement>;
    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        public popupProvider: OffPanelPopupService,
        readonly store: Store,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.controlPoints }));
        this.createForm();
        this.currentId = +this.route.snapshot.paramMap.get('id');

        if (
            this.router.url.indexOf(
                `/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${IMPACT_PAGES.points}/${IMPACT_PAGES.edit}`
            ) === 0
        ) {
            if (this.currentId) {
                this.store
                    .select(selectControlPoints)
                    .pipe(
                        takeUntil(this.ngDestroyed$),
                        // filter((data) => data?.length > 0),
                        take(1)
                    )
                    .subscribe((data) => {
                        const point = data?.find((v) => v.id === this.currentId);
                        if (point) {
                            this.loading = false;
                            this.setCurrentPoint(point);
                            this.store.dispatch(
                                ImpactActions.setEditControlPoint({ payload: point })
                            );
                        } else {
                            this.back();
                        }
                        _changeDetectorRef.markForCheck();
                    });
                this.setEditMode();
            } else {
                this.back();
            }
        } else {
            this.loading = false;
        }

        this.initState();
    }

    ngOnInit(): void {
        if (this.isEditMode) {
            this.infoText = this.translateText.infoTextEdit;
        } else {
            this.infoText = this.translateText.infoTextCreate;
            this.store.dispatch(setMapClickState({ isAllow: true }));
        }
    }

    ngOnDestroy(): void {
        if (!this.isEditMode) {
            this.store.dispatch(ImpactActions.setNewControlPoint({ payload: null }));
        } else {
            this.store.dispatch(ImpactActions.setEditControlPoint({ payload: null }));
        }
        this.store.dispatch(ImpactActions.setControlPointFormError({ payload: null }));
        this.store.dispatch(ImpactActions.setCoordinates({ payload: null }));
        this.ngDestroyed$.next();
    }

    public getFormValue(field: string) {
        return this.ControlPointCreate.get(field);
    }

    public back() {
        this.router.navigate([
            `/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${IMPACT_PAGES.points}`,
        ]);
    }

    public onSubmit() {
        const formData = this.ControlPointCreate.getRawValue();
        let point: ControlPointImpactRequest = {
            name: formData.name,
            lat: formData.lat,
            lon: formData.lon,
            group_id: this.groupId,
        };
        if (this.isEditMode && this.currentPoint) {
            point = { ...point, id: this.currentPoint.id };
            this.store.dispatch(
                ImpactActions.updateControlPoint({
                    payload: point,
                })
            );
        } else {
            this.store.dispatch(
                ImpactActions.addControlPoint({
                    payload: point,
                })
            );
        }
    }

    public getError(field: string): string {
        this.clearError(field);
        if (
            this.ControlPointCreate &&
            this.ControlPointCreate.controls[field].invalid &&
            (this.ControlPointCreate.controls[field].dirty ||
                this.ControlPointCreate.controls[field].touched)
        ) {
            if (this.ControlPointCreate.controls[field].errors.required) {
                return field === 'name'
                    ? this.translateText.nameRequiredError
                    : this.translateText.errorRequired;
            }
            if (this.ControlPointCreate.controls[field].errors.max) {
                return (
                    this.translateText.maxError +
                    ' ' +
                    this.ControlPointCreate.controls[field].errors.max.max
                );
            }
            if (this.ControlPointCreate.controls[field].errors.min) {
                return (
                    this.translateText.minError +
                    ' ' +
                    this.ControlPointCreate.controls[field].errors.min.min
                );
            }
            if (this.ControlPointCreate.controls[field].errors.maxlength) {
                return this.translateText.maxLength(
                    this.ControlPointCreate.controls[field].errors.maxlength.requiredLength
                );
            }

            if (this.ControlPointCreate.controls[field].errors.incorrect) {
                return this.ControlPointCreate.controls[field].errors.message;
            }
        }

        return '';
    }

    showDeletePopup() {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.deleteControl, () => (this.isShowDeletePopup = true));
        this.isShowDeletePopup = true;
    }

    deleteAccept() {
        this.isShowDeletePopup = false;
        this.store.dispatch(ImpactActions.deleteControlPoint({ payload: this.currentId }));
        this.back();
    }

    deleteCancel() {
        this.isShowDeletePopup = false;
    }

    private initState() {
        this.store
            .select(selectGroupId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((groupId) => (this.groupId = groupId));
        this.store
            .select(selectControlPointFormError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((value) => {
                if (value) {
                    const data = value.error;
                    if (data?.hasOwnProperty('lat') && data?.hasOwnProperty('lon')) {
                        this.showDomainError = true;
                        this.ControlPointCreate.controls['lat'].setErrors({
                            incorrect: true,
                            message: ' ',
                        });
                        this.ControlPointCreate.controls['lon'].setErrors({
                            incorrect: true,
                            message: ' ',
                        });
                        this.ControlPointCreate.get('lat').markAsDirty();
                        this.ControlPointCreate.get('lon').markAsDirty();
                    } else {
                        for (const key in data) {
                            if (data.hasOwnProperty(key) && this.ControlPointCreate.controls[key]) {
                                this.ControlPointCreate.controls[key].setErrors({
                                    incorrect: true,
                                    message: data[key].join(', '),
                                });
                                this.lastKeyError.push(key);
                            } else if (data.hasOwnProperty('detail')) {
                                this.errorResponse = data.detail;
                                this.loadingResponse = false;
                                setTimeout(() => {
                                    this.errorResponse = null;
                                    this.store.dispatch(
                                        ImpactActions.setControlPointFormError({ payload: null })
                                    );
                                    this._changeDetectorRef.markForCheck();
                                }, 2500);
                            }
                        }
                    }
                }
                this.loadingResponse = false;
                this._changeDetectorRef.markForCheck();
            });
        this.store
            .select(selectMapClickState)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((mapState) => !!mapState?.coordinates)
            )
            .subscribe((mapState) => {
                if (mapState?.coordinates) {
                    // create control points marker on the Map
                    const lat = parseFloat(
                        mapState.coordinates.lat.toFixed(NUMBER_ROUND_COORDINATES)
                    );
                    const lon = parseFloat(
                        mapState.coordinates.lon.toFixed(NUMBER_ROUND_COORDINATES)
                    );

                    this.store.dispatch(
                        ImpactActions.setNewControlPoint({
                            payload: {
                                name: this.translateText.newControlPointName,
                                lat: lat,
                                lon: lon,
                                obj: NEW_CONTROL_POINT_OBJ_TYPE,
                                id: null,
                            },
                        })
                    );
                    this.setCoordinatesToForm({ lat: lat, lon: lon });
                    this.store.dispatch(setMapClickState({ isAllow: false }));
                    this.showDomainError = false;
                    this._changeDetectorRef.markForCheck();
                }
            });
        this.store
            .select(selectCoordinates)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((coordinates) => !!coordinates)
            )
            .subscribe((coordinates) => {
                if (coordinates) {
                    this.setCoordinatesToForm(coordinates);
                    this._changeDetectorRef.markForCheck();
                }
            });
    }

    private createForm() {
        this.ControlPointCreate = this.fb.group({
            name: [null, [Validators.required, Validators.maxLength(60)]],
            lat: [null, [Validators.required, Validators.min(-90), Validators.max(90)]],
            lon: [null, [Validators.required, Validators.min(-180), Validators.max(180)]],
        });
    }

    private setCurrentPoint(data: ControlPointImpact): void {
        if (!data) {
            return;
        }
        this.loading = false;
        this.currentPoint = data;
        this.ControlPointCreate.patchValue({
            name: data.name,
            lat: data.lat,
            lon: data.lon,
        });
    }

    private setEditMode(): void {
        this.isEditMode = true;
        this.navigationLink = [
            {
                name: this.translateText.controlPointsTitle,
                path: MAIN_PAGES.impact + '/' + IMPACT_PAGES.controlPoints,
            },
            {
                name: this.translateText.editControlPoint,
                path: null,
            },
        ];
        this.acceptText = TEXTS.EDIT_STATION.edit;
    }

    private clearError(field: string) {
        this.lastKeyError.forEach((key) => {
            if (this.ControlPointCreate.controls[key] && field === key) {
                this.ControlPointCreate.get(key).updateValueAndValidity();
            }
        });
        this.lastKeyError = [];
    }

    private setCoordinatesToForm(coordinates) {
        if (!this.ControlPointCreate) return;
        this.ControlPointCreate.controls.lat.setValue(coordinates.lat);
        this.ControlPointCreate.controls.lon.setValue(coordinates.lon);
        this.ControlPointCreate.get('lat').markAsDirty();
        this.ControlPointCreate.get('lon').markAsDirty();
        this.showDomainError = false;
    }
}
