<div class="stats-popup-wrapper" (clickOutside)="closeAll()">
    <div *showDirective="showComfort" class="stats-popup">
        <cross-button
            class="stats-popup__close"
            size="medium"
            (click)="hideComfort.emit()"
        ></cross-button>
        <div class="stats-popup__title">{{ TEXTS.INDOOR.comfort }}</div>
        <div class="stats-popup__content" [innerHTML]="TEXTS.INDOOR.popupComfort | safeHtml"></div>
    </div>

    <div *showDirective="showSafety" class="stats-popup">
        <cross-button
            class="stats-popup__close"
            size="medium"
            (click)="hideSafety.emit()"
        ></cross-button>
        <div class="stats-popup__title">{{ TEXTS.INDOOR.safety }}</div>
        <div class="stats-popup__content" [innerHTML]="TEXTS.INDOOR.popupSafety | safeHtml"></div>
        <div class="stats-popup__link" data-cy="indoor-info-popup-link" (click)="openPopup.emit()">
            Read more about the calculation methodology
        </div>
    </div>
</div>
