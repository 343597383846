import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Calendar } from './calendar';
import { Calendar_m } from './calendar.mobile';
import {
    InputForDropdownModule,
    InputDateRangeModule,
    CalendarMonthsModule,
    DirectivesModule,
} from '@libs/shared-ui';
import { FormatDateMobilePipe } from './format-date-mobile.pipe';
import { FormatDatePipe } from './format-date.pipe';

@NgModule({
    imports: [
        InputForDropdownModule,
        CommonModule,
        DirectivesModule,
        InputDateRangeModule,
        CalendarMonthsModule,
    ],
    exports: [Calendar, Calendar_m],
    declarations: [Calendar, Calendar_m, FormatDateMobilePipe, FormatDatePipe],
})
export class CalendarModule {}
