import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
    selector: 'popup-ask',
    templateUrl: './popup-ask.component.html',
    styleUrls: ['./popup-ask.component.less'],
})
export class PopupAskComponent {
    @Input() title = '';
    @Input() description = '';
    @Input() textCancel = '';
    @Input() textAccept = '';
    @Input() isConfirmAccept = false;
    @Input() showCloseIcon = true;
    @Input() isDeleteAction = false;

    @Output() cbAccept = new EventEmitter<void>();
    @Output() cbClose = new EventEmitter<void>();

    close() {
        this.cbClose.emit();
    }

    accept() {
        this.cbAccept.emit();
    }

    @HostListener('window:keydown.enter', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        this.cbAccept.emit();
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.cbClose.emit();
    }
}
