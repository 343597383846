<div class="doughnut__wrapper">
    <div class="doughnut-chart">
        <canvas
            baseChart
            [type]="doughnutChartType"
            [datasets]="datasets"
            [labels]="labels"
            [options]="options"
        ></canvas>
    </div>

    <div class="doughnut__items_wrapper">
        <div *ngFor="let val of data; index as i">
            <div *ngIf="val" class="doughnut__item">
                <span class="doughnut__circle" [style]="{ background: colors[i] }"></span>
                <span class="doughnut__point" [innerHTML]="labels[i]"></span>
                <div class="doughnut__group">
                    <span class="doughnut__value">{{ val }}</span>
                    <span class="doughnut__unit" [innerHTML]="measure"></span>
                </div>
            </div>
        </div>
    </div>
</div>
