import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

import { lastValueFrom } from 'rxjs';
import { MapService } from './map.service';
import { OlMapEvent } from './map.types';
import { ViewOptions } from 'ol/View';
import Map from "ol/Map";
import { MapStyleType } from '@libs/common';
@Component({
    selector: 'shared-ui-ol-map',
    template: '<div #container></div><div #attribution><shared-ui-ol-attribution [type]="type"></shared-ui-ol-attribution></div>',
    styles: [
        `
            :host {
                display: block;
            }
            div {
                height: 100%;
                width: 100%;
            }
        `,
    ],
    providers: [MapService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnChanges, AfterViewInit, ViewOptions, OlMapEvent {
    @Input() type?: MapStyleType;
    @Input() key?: string;
    @Input() style?: string;
    @Input() offset?: number[];
    @Input() center?: ViewOptions['center'];
    @Input() constrainRotation?: ViewOptions['constrainRotation'];
    @Input() enableRotation?: ViewOptions['enableRotation'];
    @Input() extent?: ViewOptions['extent'];
    @Input() constrainOnlyCenter?: ViewOptions['constrainOnlyCenter'];
    @Input() smoothExtentConstraint?: ViewOptions['smoothExtentConstraint'];
    @Input() maxResolution?: ViewOptions['maxResolution'];
    @Input() minResolution?: ViewOptions['minResolution'];
    @Input() maxZoom?: ViewOptions['maxZoom'];
    @Input() minZoom?: ViewOptions['minZoom'];
    @Input() multiWorld?: ViewOptions['multiWorld'];
    @Input() constrainResolution?: ViewOptions['constrainResolution'];
    @Input() smoothResolutionConstraint?: ViewOptions['smoothResolutionConstraint'];
    @Input() showFullExtent?: ViewOptions['showFullExtent'];
    @Input() projection?: ViewOptions['projection'];
    @Input() resolution?: ViewOptions['resolution'];
    @Input() resolutions?: ViewOptions['resolutions'];
    @Input() rotation?: ViewOptions['rotation'];
    @Input() zoom?: ViewOptions['zoom'];
    @Input() zoomFactor?: ViewOptions['zoomFactor'];
    @Input() padding?: ViewOptions['padding'];

    @Output() zoomEvt = new EventEmitter<{ center: number[]; zoom: number }>;
    @Output() mapClick = new EventEmitter<number[]>;
    @Output() mapMoveStart = new EventEmitter<any>();
    @Output() mapMoveEnd = new EventEmitter<{ center: number[]; zoom: number }>();
    @Output() mapZoom = new EventEmitter<number>();
    @Output() mapCreate = new EventEmitter<Map>();
    @Output() mapLoad = new EventEmitter<any>();

    get mapInstance(): Map {
        return this.mapService.mapInstance;
    }

    @ViewChild('container', { static: true }) mapContainer: ElementRef;
    @ViewChild('attribution', { static: true }) attribution: ElementRef;

    constructor(private mapService: MapService) {}

    ngAfterViewInit() {
        if (this.mapContainer) {
            this.mapService.setup(this.mapContainer.nativeElement, {
                mapOptions: {
                    key: this.key,
                    style: this.style,
                    type: this.type,
                    attribution: this.attribution.nativeElement
                },
                viewOptions: {
                    constrainRotation: this.constrainRotation,
                    enableRotation: this.enableRotation,
                    extent: this.extent,
                    constrainOnlyCenter: this.constrainOnlyCenter,
                    smoothExtentConstraint: this.smoothExtentConstraint,
                    maxResolution: this.maxResolution,
                    minResolution: this.minResolution,
                    maxZoom: this.maxZoom,
                    minZoom: this.minZoom,
                    multiWorld: this.multiWorld,
                    constrainResolution: this.constrainResolution,
                    smoothResolutionConstraint: this.smoothResolutionConstraint,
                    showFullExtent: this.showFullExtent,
                    projection: this.projection,
                    resolution: this.resolution,
                    resolutions: this.resolutions,
                    rotation: this.rotation,
                    zoom: this.zoom,
                    zoomFactor: this.zoomFactor,
                    padding: this.padding,
                    center: this.center
                },
                mapEvents: this,
            });
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        await lastValueFrom(this.mapService.mapCreated$);
        if (changes?.minZoom && !changes?.minZoom.isFirstChange()) {
            this.mapService.updateMinZoom(changes?.minZoom.currentValue);
        }
        if (changes?.type && !changes?.type.isFirstChange()) {
            this.mapService.updateMapType(changes?.type);
        }
        if (changes?.maxZoom && !changes?.maxZoom.isFirstChange()) {
            this.mapService.updateMaxZoom(changes?.maxZoom.currentValue);
        }
        if (changes?.extent && !changes?.extent.isFirstChange()) {
            this.mapService.updateMaxBounds(changes?.extent.currentValue);
        }
        if ((changes?.center && !changes?.center?.isFirstChange())
            || (changes?.zoom && !changes?.zoom?.isFirstChange())
            ) {
            this.mapService.move(
                changes?.center && this.center ? this.center : undefined,
                changes?.zoom && this.zoom ? this.zoom : undefined,
                );
        }
    }
}
