import * as moment from 'moment-timezone';
import {
    CONTRIBUTIONS_COLORS_SOURCES_ORDER,
    ContributionsSeries,
    createBoundaries,
    DateRangeImpact,
    getColorByIndex,
    pdkcc,
    SourceType,
    TIME_FORMAT,
    Timeseries,
} from '@libs/common';
import { formatDayMonth } from '@libs/common';
import { EventImpact, ExcessStatistic } from '@cityair/modules/impact/service/api-model-impact';
import { MeasureScheme, RunImpact, SourceImpact } from '@libs/common';
import { Feature } from 'geojson';

export function formatTime(m: moment.Moment) {
    return m.format(TIME_FORMAT);
}
export function formatDate(m: moment.Moment) {
    return `${formatTime(m)} ${formatDayMonth(m)}`;
}

export function getSourcesByRuns(runs: RunImpact[]): SourceImpact[] {
    const configIds = [];
    const result = [];
    const resultIds = [];
    runs.forEach((run) => {
        if (!configIds.includes(run.config)) {
            configIds.push(run.config);
            run.sources_snapshot?.forEach((source) => {
                if (!resultIds.includes(source.id)) {
                    resultIds.push(source.id);
                    result.push(source);
                }
            });
        }
    });
    return result;
}
type VangaSchemeType = 'default' | 'mpc_instantaneous' | 'c_mmhg_mg';
export function getVangaScheme(scheme: MeasureScheme): VangaSchemeType {
    switch (scheme) {
        case MeasureScheme.mpc:
            return 'mpc_instantaneous';
            break;
        case MeasureScheme.c_mmhg_mg:
            return 'c_mmhg_mg';
            break;
        default:
            return 'default';
            break;
    }
}
export function prepareImpactEvents(events, runs): EventImpact[] {
    const runWithDates = runs.map((run) => ({
        id: run.id,
        start: moment(run.evaluation_time).add(run.step_minutes, 'minutes'),
        end: moment(run.evaluation_time).add(run.duration_minutes, 'minutes'),
    }));
    const result = [];
    events?.forEach((event) => {
        if (event.start && event.end) {
            const activeRun = runWithDates?.find((run) =>
                moment(event.start).isBetween(run.start, run.end)
            );

            if (activeRun) {
                const eventCurrent = { ...event, run_id: activeRun.id };
                result.push(eventCurrent);
            }
        }
    });

    return result;
}
export function getSourcesSquareTypeBorder(sources: SourceImpact[]): Feature[] {
    return sources
        .filter((source) => {
            if (source.geo_type !== null) {
                return source.geo_type === SourceType.area;
            } else {
                const bbox = source.bbox.map((v) => +v.toFixed(2));
                return !(bbox[0] === bbox[2] && bbox[1] === bbox[3]);
            }
        })
        .map((source, index) => {
            const coordinates = createBoundaries(source.bbox);
            coordinates.push(coordinates[0]);
            const color = getColorByIndex(CONTRIBUTIONS_COLORS_SOURCES_ORDER, index);
            return {
                type: 'Feature',
                properties: {
                    color,
                    dasharray: [2, 2],
                    name: source.name,
                },
                geometry: {
                    coordinates,
                    type: 'LineString',
                },
            };
        });
}
export function sortByValue(data: any[]) {
    return data.sort((a, b) => {
        if (a.value < b.value) {
            return 1;
        }
        if (a.value > b.value) {
            return -1;
        }

        return 0;
    });
}

export function prepareContribution(
    contributions: ContributionsSeries,
    timeseries: Timeseries
): {
    contributions: ContributionsSeries;
    order: number[];
} {
    const order = [];

    if (contributions) {
        const values = Object.values(contributions).filter((value) => !Array.isArray(value));
        const currentMmt = Object.keys(contributions).filter((v) => v !== 'date')?.[0];
        const data = timeseries[currentMmt];
        if (values?.length && currentMmt) {
            const allValues = values[0];
            const clearData = Object.fromEntries(
                Object.entries(allValues).filter(
                    ([key]) => !allValues[key]?.every((i) => +i.toFixed(7) === 0)
                )
            );

            const keys = Object.keys(clearData);
            if (keys.length === 0) {
                return { contributions: {}, order: [] };
            } else {
                let prepareForSortByTotal = keys?.map((key) => {
                    let sum = 0;
                    clearData[key].forEach((a, index) => {
                        if (a !== 0) {
                            const currentVal = (data[index] * a) / 100;
                            sum += currentVal;
                        }
                    });
                    return {
                        value: sum,
                        key,
                    };
                });
                prepareForSortByTotal = sortByValue(prepareForSortByTotal);
                const order = prepareForSortByTotal.map((v) => Number(v.key));
                const newContribution = {
                    ...contributions,
                    [currentMmt]: clearData,
                } as ContributionsSeries;
                return { contributions: newContribution, order };
            }
        }
    }

    return { contributions, order };
}

function shuffle(arr) {
    let j, x, i;
    for (i = arr.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = arr[i];
        arr[i] = arr[j];
        arr[j] = x;
    }
    return arr;
}
function getRandomValues(len: number, max: number, range: number) {
    return Array.from({ length: len }, () => Math.random() * max + range);
}
function getMockTimeline(dateRange: DateRangeImpact) {
    const dates = [];
    const currDate = moment(dateRange.start).startOf('day');
    const lastDate = moment(dateRange.end).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().toISOString());
    }

    return dates;
}
export function getMockDataStatRegion(
    sources: SourceImpact[],
    dateRange: DateRangeImpact,
    mmt: string
): ExcessStatistic {
    const sourcesIds = sources.map((v) => v.id);
    shuffle(sourcesIds);
    let percentValue = 100;
    const timeline = getMockTimeline(dateRange);
    const sources_contribution = {};
    const average_concentration = {
        dates: timeline,
        values: getRandomValues(timeline?.length, pdkcc[mmt], 7),
    };
    const number_exceed_concentration = {
        dates: timeline,
        values: getRandomValues(timeline?.length, 5, 1),
    };
    sourcesIds.forEach((id) => {
        const randomV = Math.random() * 17 + 3;
        if (percentValue - randomV > 0) {
            percentValue -= randomV;
            sources_contribution[id] = randomV;
        }
    });

    return {
        sources_contribution,
        average_concentration,
        number_exceed_concentration,
    };
}
