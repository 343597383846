import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ClosePopupsHelper } from '../subscription-card/subscription-card.component';
import { Store } from '@ngrx/store';
import { NotificationSettings, User, NotificationType, TEXTS } from '@libs/common';
import { openSettingForm } from '@cityair/modules/notifications/store/actions';

@Component({
    templateUrl: './subscriptions-measurements.component.html',
    styleUrls: ['./subscriptions-measurements.component.less'],
    selector: 'subscriptions-measurements',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionsMeasurements extends ClosePopupsHelper implements OnInit, OnChanges {
    @Input() scrolled: Observable<Event>;
    @Input() settings: NotificationSettings[];
    @Input() userGroupData: any;

    textsNotification = TEXTS.NOTIFICATIONS;
    private iAm: User;
    private users: User[];
    public active: NotificationSettings[] = [];
    public deactivated: NotificationSettings[] = [];
    constructor(private store: Store) {
        super();
    }

    ngOnInit() {
        this.scrolled.subscribe((e) => this.onScroll(e));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.settings && changes.settings.currentValue) {
            this.active = this.settings.filter((v) => v.is_active);
            this.deactivated = this.settings.filter((v) => !v.is_active);
        }
        if (changes.userGroupData && changes.userGroupData.currentValue) {
            this.iAm = this.userGroupData?.iAm;
            const { email, id } = this.userGroupData?.iAm;

            const userDefaults = {
                groupId: this.userGroupData?.groupId,
                emailsList: [email],
            };
        }
    }

    addSubscription() {
        this.store.dispatch(
            openSettingForm({ settings_type: NotificationType.pdkExcess, currentSetting: null })
        );
    }

    editSubscription(setting: NotificationSettings) {
        this.store.dispatch(
            openSettingForm({ settings_type: NotificationType.pdkExcess, currentSetting: setting })
        );
    }
}
