import { Component } from '@angular/core';

import { TEXTS } from '@libs/common';

@Component({
    selector: 'ca-link-expired-popup',
    templateUrl: 'link-expired-popup.component.html',
    styleUrls: ['link-expired-popup.component.less'],
})
export class LinkExpiredPopupComponent {
    TEXTS = TEXTS;
}
