import * as moment from 'moment-timezone';

function createMonth(i: number, numberOfFutureDays: number, _minDate: string, isFeature = false) {
    function getDay(date) {
        // получить номер дня недели, от 0(пн) до 6(вс)
        let day = date.day();
        if (day == 0) day = 7;
        return day - 1;
    }

    let arr: Day[][] = [];

    let d = moment().subtract(i, 'months').startOf('month');
    if (isFeature) {
        d = moment().add(i, 'months').startOf('month');
    }
    const days = d.daysInMonth();
    const maxDate = moment().add(numberOfFutureDays, 'days');
    const minDate = moment(_minDate || 0).valueOf();

    // заполнить первый ряд от понедельника
    // и до дня, с которого начинается месяц
    arr[0] = [];
    for (let i = 0; i < getDay(d); i++) arr[0].push(null);

    let week = 0;
    for (let i = 1; i <= days; i++) {
        if (getDay(d) % 7 == 0) {
            // понедельник
            week++;
            arr[week] = [];
        }
        arr[week].push({
            text: i,
            timestamp: d.valueOf(),
            disabled: d.valueOf() > maxDate.valueOf() || d.valueOf() < minDate,
        });
        d.add(1, 'days');
    }

    for (let i = arr[week].length; i < 7; i++) {
        arr[week].push(null);
    }

    arr = arr.filter((w) => w.length);

    return arr;
}

export class Day {
    text: number;
    timestamp: number;
    disabled: boolean;
}

export class CalendarMonth {
    name: string;
    nameWithoutYear: string;
    year: string;
    daysArr: Day[][];
}

export function createCalendarDays(
    numberOfMonths: number,
    numberOfFutureDays: number = 0,
    minDate: string = ''
): CalendarMonth[] {
    const days: CalendarMonth[] = [];
    // add month for future
    if (numberOfFutureDays) {
        const numberOfFeatureDate = moment().add(numberOfFutureDays, 'days');
        if (!moment().isSame(numberOfFeatureDate, 'months')) {
            const currentMonth = moment().add(1, 'months');
            days.push({
                name: currentMonth.format('MMMM YYYY'),
                nameWithoutYear: currentMonth.format('MMMM'),
                year: currentMonth.format('YYYY'),
                daysArr: createMonth(1, numberOfFutureDays, minDate, true),
            });
        }
    }

    for (let i = 0; i < numberOfMonths; i++) {
        const currentMonth = moment().subtract(i, 'months');
        days.push({
            name: currentMonth.format('MMMM YYYY'),
            nameWithoutYear: currentMonth.format('MMMM'),
            year: currentMonth.format('YYYY'),
            daysArr: createMonth(i, numberOfFutureDays, minDate),
        });
    }

    return days;
}
