import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ca-sidebar-toggle-button',
    templateUrl: 'sidebar-toggle-button.component.html',
    styleUrls: ['sidebar-toggle-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarToggleButtonComponent {
    @Input() isActive: boolean;
    @Output() toggle = new EventEmitter<boolean>();

    togglePanel() {
        this.toggle.emit(!this.isActive);
    }
}
