<div class="feedback-success">
    <div class="feedback-success__content">
        <div class="feedback-success__poster">
            <feedback-success-poster></feedback-success-poster>
        </div>
        <div class="feedback-success__title">{{ TEXTS.FEEDBACK.successTitle }}</div>
        <div class="feedback-success__message">{{ TEXTS.FEEDBACK.successMessage }}</div>
    </div>
    <dark-button type="secondary" class="feedback-success__close-btn" (click)="close()">
        {{ TEXTS.COMMON.close }}
    </dark-button>
</div>
