<div class="capi-aqi-legend" [@inOutAnimation]>
    <div class="capi-aqi-legend__header">
        <div class="capi-aqi-legend__title">КИЗА</div>
        <div
            class="capi-aqi-legend__description"
            [innerHtml]="TEXTS.TEXT_DESCRIPTION.capiAirQuality"
        ></div>
    </div>
    <div class="capi-aqi-legend__content scroll-gray">
        <div class="capi-aqi-legend__values-text">
            Значения шкалы определяются следующим образом:
        </div>
        <div
            class="capi-aqi-legend__item"
            [class.aqi-legend__item--highlight]="i === currentAqiDangerLevel"
            *ngFor="let description of TEXTS.CAPI_THREE_TEXTS; index as i"
        >
            <div class="capi-aqi-legend__item-icon capi-aqi-legend__item-icon--{{ i }}">
                {{ TEXTS.CAPI_GRADES[i] }}
            </div>
            <div class="capi-aqi-legend__item-line">—</div>
            <div class="capi-aqi-legend__item-description">{{ description }}</div>
        </div>
    </div>
</div>
