<div class="side-menu-button" (click)="onClick($event)">
    <div
        class="side-menu-button__icon side-menu-button__icon-{{ name }}"
        [class.side-menu-button__icon--not-available]="!isAvailable"
        [attr.data-cy]="isAvailable ? 'menu_icon_active' : ''"
    >
        <div
            class="side-menu-button__tooltip"
            *ngIf="!isTouchDevice && showTooltip"
            [attr.data-cy]="name"
            caTooltip
            caTooltipPlacement="right"
            [caTooltipTemplate]="menuTooltip"
        ></div>
        <ng-template #menuTooltip>
            <ca-tooltip
                [text]="
                    isAvailable
                        ? TEXTS.ADMIN_PANEL[name]
                        : TEXTS.ADMIN_PANEL[name] + TEXTS.ADMIN_PANEL['not' + name]
                "
            ></ca-tooltip>
        </ng-template>
    </div>
</div>
