import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ca-info-icon',
    templateUrl: 'info-icon.component.html',
    styleUrls: ['info-icon.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoIconComponent {
    @Input() active = true;
    @Input() withGradient = false;

    isHover = false;
}
