import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { GroupFeaturesService, setModuleConfig, SharedCoreActions } from '@libs/shared-store';
import {
    GroupExtConfigName,
    MAIN_PAGES,
    AVAILABLE_MOBILE_PAGES,
    detectMobile,
    defaultModuleConfig,
} from '@libs/common';

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    firstStart = true;

    private _pageChange$ = new BehaviorSubject<string>('');
    public pageChange$ = this._pageChange$.asObservable();

    constructor(
        private groupFeaturesService: GroupFeaturesService,
        private store: Store,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
            if (this.firstStart) {
                this.checkDefaultModule();
                this.firstStart = false;
            }

            const page = this.getActivePage();
            if (page === MAIN_PAGES.networks || page === '') {
                this.store.dispatch(setModuleConfig({ config: defaultModuleConfig }));
            }
            this.store.dispatch(SharedCoreActions.toggleSidebar({ payload: !!page }));

            this._pageChange$.next(this.getActivePage());
        });
    }

    private checkDefaultModule() {
        this.groupFeaturesService.readyBehavior$
            .pipe(
                filter((val) => val),
                take(1)
            )
            .subscribe(() => {
                const startModule = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.startModule
                );

                if (startModule && !this.getActivePage()) {
                    if (detectMobile() && !AVAILABLE_MOBILE_PAGES.includes(startModule))
                        this.goToPage(MAIN_PAGES.default);
                    else this.goToPage(startModule);
                }
            });
    }

    public isActiveRoute(page: MAIN_PAGES) {
        return this.router.url.includes(page);
    }

    public getActivePage() {
        return this.router.url.split('/')[1];
    }

    public goToPage(page: MAIN_PAGES) {
        this.router.navigate([page], {
            relativeTo: this.activatedRoute,
        });
    }
}
