import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PlumesRunSummaryComponent } from './plumes-run-summary.component';

@NgModule({
    imports: [CommonModule],
    exports: [PlumesRunSummaryComponent],
    declarations: [PlumesRunSummaryComponent],
})
export class PlumesRunSummaryModule {}
