<div class="chart-header">
    <div class="chart-title">
        {{ title }}
    </div>
    <div class="chart-tooltip hidden-xs">
        <ca-info-icon
            [active]="true"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipTemplate]="tooltipAllHistory"
        ></ca-info-icon>
        <ng-template #tooltipAllHistory>
            <ca-tooltip [text]="tooltip1 +'<br/>' + tooltip2"></ca-tooltip>
        </ng-template>
    </div>
</div>
