import {
    createFeature,
    createReducer,
    createSelector,
    createActionGroup,
    props,
    on,
    createFeatureSelector,
    emptyProps,
    select,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IMPACT_PAGES, ImpactMapObjectType } from '@cityair/modules/impact/models';
import { DateRange, sourcesAdapter } from '@cityair/modules/plumes/store/reducers';
import {
    ControlPointImpact,
    ControlPointImpactRequest,
    ControlPointTimeline,
    DateRangeImpact,
    RunConfigImpact,
    RunConfigImpactEdit,
    RunImpact,
    SourceImpact,
} from '@libs/common';
import {
    EventImpact,
    NetworkDensityData,
    Region,
} from '@cityair/modules/impact/service/api-model-impact';
import {
    DATE_FOR_CITY,
    INIT_IMPACT_DATE,
    INIT_OBJECT_ON_MAP,
    LENS_ISKITIM,
    MRR_ISKITIM_SOURCE,
    NEW_CONTROL_POINT_OBJ_TYPE,
    REGIONS,
} from '@cityair/modules/impact/consts';
import * as moment from 'moment-timezone';
import {
    getIntervalByRun,
    Station,
    StationContributionResponse,
} from '@cityair/modules/plumes/services/station/models';
import { MeasureScheme } from '@libs/common';
import { ColorZone } from '@libs/common';
import { isFalseNumber } from '@libs/common';
import { getSourcesSquareTypeBorder, getVangaScheme } from '@cityair/modules/impact/utils';
import { HttpErrorResponse, HttpParamsOptions } from '@angular/common/http';
import { Feature } from '@libs/common';
import { ControlPoint } from '@cityair/modules/plumes/services/control-point/models';
import { pipe } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TilesUpdateParams } from '@cityair/modules/plumes/services/plumes-tiles-player/plumes-tiles-player';
import { selectCurrentCity } from '@cityair/modules/core/store/current-city/current-city.feature';
import { QueryParams } from '@ngrx/data';
import { CorrelationData, EventDateRange, RegionApi, TimeSeriesData } from '@libs/common';
import { CheckboxItem } from '@libs/common';
import {
    selectGroupId,
    selectCurrentMeasureScheme,
    selectMeasuresZones,
    selectExtConfig,
} from '@libs/shared-store';
import {
    selectCurrentSource,
    selectDateRangeSource,
    selectRegionMmt,
    selectControlPointMmt,
    selectSourceMmt,
} from './statistic.feature';
import { LENS_NSK, MRR_ISKITIM } from './../consts';

const IMPACT_FEATURE_KEY = 'impact';
export const impactRunAdapter: EntityAdapter<RunImpact> = createEntityAdapter<RunImpact>();
export const impactSourcesAdapter: EntityAdapter<SourceImpact> =
    createEntityAdapter<SourceImpact>();
export const impactSourcesDemoAdapter: EntityAdapter<SourceImpact> =
    createEntityAdapter<SourceImpact>();
export const impactConfigAdapter: EntityAdapter<RunConfigImpact> =
    createEntityAdapter<RunConfigImpact>();
export const controlPointTimelineAdapter: EntityAdapter<ControlPointTimeline> =
    createEntityAdapter<ControlPointTimeline>({
        selectId: (point: ControlPointTimeline) => point.control_point,
    });
export const regionAdapter: EntityAdapter<RegionApi> = createEntityAdapter<RegionApi>();
export const stationAdapter: EntityAdapter<Station> = createEntityAdapter<Station>();
export const ImpactActions = createActionGroup({
    source: 'Impact',
    events: {
        'Init Module': emptyProps(),
        Noop: emptyProps(),
        'Set Is Available': props<{ payload: boolean }>(),
        'Set Active Tab': props<{ payload: IMPACT_PAGES }>(),
        'Set Loading': props<{ payload: boolean }>(),
        'Update Date Range Run': props<{ payload: DateRange }>(),
        'Set Run Load Error': props<{ payload: boolean }>(),
        'Clear Runs': emptyProps(),
        'Set Runs': props<{ payload: RunImpact[] }>(),
        'Set Run Configs': props<{ payload: RunConfigImpact[] }>(),
        'Set Run Config': props<{ payload: RunConfigImpact }>(),
        'Update Run Config': props<{ payload: RunConfigImpactEdit }>(),
        'Set Sources': props<{ payload: SourceImpact[] }>(),
        'Set Sources List Demo': props<{ payload: SourceImpact[] }>(),
        'Set Loading Edit Run': props<{ payload: boolean }>(),
        'Set Success Edit Run': props<{ payload: boolean }>(),
        'Set Error Edit Run': props<{ payload: any }>(),
        'Set Active Run': props<{ payload: RunImpact }>(),
        'Set Active Mmt': props<{ payload: string }>(),
        'Toggle Wind Layer': props<{ payload: boolean }>(),
        'Set Is Disable Wind Button': props<{ payload: boolean }>(),
        'Set Active Height': props<{ payload: number }>(),
        'Set Time Index': props<{ payload: number }>(),
        'Set Control Points': props<{ payload: ControlPointImpact[] }>(),
        'Set Control Points Timeline': props<{ payload: ControlPointTimeline[] }>(),
        'Set Control Points Is Loading': props<{ payload: boolean }>(),
        'Set Dates': props<{ payload: string[] }>(),
        'Expand Pin': props<{ payload: any }>(),
        'Set Edit Control Point': props<{ payload: ControlPointImpact }>(),
        'Set Active Pin': props<{ payload: any }>(),
        'Set Chart Data': props<{ payload: Feature[] }>(),
        'Set City Data': props<{ payload: TimeSeriesData }>(),
        'Set Stations Data': props<{ payload: Station[] }>(),
        'Set Active Station': props<{ payload: Station }>(),
        'Set Active Station Id': props<{ payload: string }>(),
        'Set Loading Contribution Station Data': props<{ payload: boolean }>(),
        'Set Contribution Data To Active Station': props<{
            payload: StationContributionResponse;
        }>(),
        'Set Network Density': props<{ payload: NetworkDensityData }>(),
        'Set Regions': props<{ payload: Region[] }>(),
        'Disable Wind Button': props<{ payload: boolean }>(),
        'Set Regions Api': props<{ payload: RegionApi[] }>(),
        'Set Regions Api Is Loading': props<{ payload: boolean }>(),
        'Set Current Source': props<{ payload: SourceImpact }>(),
        'Set Map Object Types': props<{ payload: CheckboxItem[] }>(),
        'Set Events': props<{ payload: EventImpact[] }>(),
        'Set Active Event': props<{ payload: string }>(),
        'Set Correlation Data': props<{ payload: CorrelationData }>(),
        'Get Event Details Data': props<{ payload: EventImpact }>(),
        'Set Event Details Data': props<{ payload: { [key: string]: Feature[] } }>(),
        'Set Is Loading Events': props<{ payload: boolean }>(),
        'Clear Events': emptyProps(),
        'Update Date Range Events': props<{ payload: EventDateRange }>(),
        'Add Control Point': props<{ payload: ControlPointImpactRequest }>(),
        'Add Control Point Success': props<{ payload: ControlPointImpactRequest }>(),
        'Update Control Point': props<{ payload: ControlPointImpactRequest }>(),
        'Delete Control Point': props<{ payload: number }>(),
        'Delete Control Point Success': props<{ payload: any }>(),
        'Set Control Point Form Error': props<{ payload: HttpErrorResponse }>(),
        'Set New Control Point': props<{ payload: ControlPointImpact }>(),
        'Set Coordinates': props<{ payload: { lat: number; lon: number } }>(),
        'Set is Loading Control Point Form': props<{ payload: boolean }>(),
        'Toggle Mrr Layer': props<{ payload: boolean }>(),
        'Toggle Lens Layer': props<{ payload: boolean }>(),
        'Toggle Mrr Source Layer': props<{ payload: boolean }>(),
        'Set Is Loading Sources': props<{ payload: boolean }>(),
        'Changed Search Query Source': props<{ payload: string }>(),
        'Set Is Loading Posts Timeline': props<{ payload: boolean }>(),
    },
});
interface ImpactState {
    isActiveImpact: boolean;
    activeTab: IMPACT_PAGES;
    isLoading: boolean;
    dateRangeImpactRuns: DateRangeImpact;
    impactTimeIndex: number;
    runsLoadError: boolean;
    isLoadingRun: boolean;
    isLoadingControlPoints: boolean;
    isLoadingPostsTimeline: boolean;
    isLoadingEditRun: boolean;
    successEditRun: boolean;
    errorEditRun: any;
    showWindOnMap: boolean;
    displayObjOnMap: CheckboxItem[];
    disableWindButton: boolean;
    expandControlPoint: string;
    runs: EntityState<RunImpact>;
    configs: EntityState<RunConfigImpact>;
    sources: EntityState<SourceImpact>;
    activeRun: RunImpact;
    runDates: RunImpact;
    activeMmt: string;
    activeHeight: number;
    controlPoints: ControlPointImpact[];
    controlPointsTimeline: EntityState<ControlPointTimeline>;
    stations: EntityState<Station>;
    dates: string[];
    chartData: Feature[];
    activePin: any;
    expandPin: string;
    editControlPoint: ControlPointImpact;
    currentCityData: TimeSeriesData;
    activeStation: Station;
    networkDensity: NetworkDensityData;
    regions: Region[];
    regionsApi: EntityState<RegionApi>;
    isLoadingRegionsApi: boolean;
    currentSource: SourceImpact;
    sourcesListDemo: EntityState<SourceImpact>;
    events: EventImpact[];
    activeEventId: string;
    correlationData: CorrelationData;
    eventDetailData: { [key: string]: Feature[] };
    isLoadingEvents: boolean;
    dateRangeEvents: EventDateRange;
    coordinates: { lat: number; lon: number };
    isLoadingControlPointForm: boolean;
    controlPointFormError: HttpErrorResponse;
    showMmrLayer: boolean;
    showMrrSourceLayer: boolean;
    showLensLayer: boolean;
    isLoadingSources: boolean;
    searchQuerySource: string;
}
const initialState: ImpactState = {
    isActiveImpact: false,
    activeTab: null,
    isLoading: true,
    dateRangeImpactRuns: INIT_IMPACT_DATE,
    impactTimeIndex: 0,
    runsLoadError: false,
    isLoadingRun: false,
    isLoadingControlPoints: false,
    isLoadingPostsTimeline: true,
    isLoadingEditRun: false,
    successEditRun: false,
    errorEditRun: null,
    showWindOnMap: false,
    displayObjOnMap: INIT_OBJECT_ON_MAP,
    disableWindButton: false,
    expandControlPoint: null,
    runs: impactRunAdapter.getInitialState(),
    configs: impactConfigAdapter.getInitialState(),
    sources: impactSourcesAdapter.getInitialState(),
    activeRun: null,
    runDates: null,
    activeMmt: null,
    activeHeight: null,
    controlPoints: [],
    controlPointsTimeline: controlPointTimelineAdapter.getInitialState(),
    stations: stationAdapter.getInitialState(),
    dates: [],
    chartData: [],
    activePin: null,
    expandPin: null,
    editControlPoint: null,
    currentCityData: null,
    activeStation: null,
    networkDensity: null,
    regions: REGIONS,
    regionsApi: regionAdapter.getInitialState(),
    isLoadingRegionsApi: false,
    currentSource: null,
    sourcesListDemo: impactSourcesAdapter.getInitialState(),
    events: null,
    activeEventId: null,
    correlationData: null,
    eventDetailData: {},
    isLoadingEvents: true,
    dateRangeEvents: null,
    coordinates: null,
    isLoadingControlPointForm: false,
    controlPointFormError: null,
    showMmrLayer: false,
    showLensLayer: false,
    showMrrSourceLayer: false,
    isLoadingSources: true,
    searchQuerySource: '',
};

const impactSelector = createFeatureSelector<ImpactState>(IMPACT_FEATURE_KEY);
export const selectRunsImpact = createSelector(impactSelector, (state) =>
    impactRunAdapter.getSelectors().selectAll(state.runs)
);
export const selectSourcesImpactAll = createSelector(impactSelector, (state) =>
    impactSourcesAdapter.getSelectors().selectAll(state.sources)
);
export const selectSourcesImpact = createSelector(impactSelector, (state) =>
    impactSourcesAdapter.getSelectors().selectEntities(state.sources)
);
export const selectSourceImpactById = (id) =>
    createSelector(selectSourcesImpact, (sources) => sources?.[id] ?? null);

export const selectSourceListDemoImpact = createSelector(impactSelector, (state) =>
    impactSourcesDemoAdapter.getSelectors().selectAll(state.sourcesListDemo)
);
export const selectConfigImpact = createSelector(impactSelector, (state) =>
    impactConfigAdapter.getSelectors().selectAll(state.configs)
);
export const selectConfigImpactDic = createSelector(impactSelector, (state) =>
    impactConfigAdapter.getSelectors().selectEntities(state.configs)
);
export const selectControlPointsTimeline = createSelector(impactSelector, (state) =>
    controlPointTimelineAdapter.getSelectors().selectAll(state.controlPointsTimeline)
);
export const selectControlPointsTimelineDic = createSelector(impactSelector, (state) =>
    controlPointTimelineAdapter.getSelectors().selectEntities(state.controlPointsTimeline)
);
export const selectControlPointsTimelineById = (id) => {
    createSelector(selectControlPointsTimelineDic, (points) => points?.[id] ?? null);
};
export const selectImpactStations = createSelector(impactSelector, (state) =>
    stationAdapter.getSelectors().selectAll(state.stations)
);
export const selectImpactStationsDic = createSelector(impactSelector, (state) =>
    stationAdapter.getSelectors().selectEntities(state.stations)
);
export const selectRegions = createSelector(impactSelector, (state) =>
    regionAdapter.getSelectors().selectAll(state.regionsApi)
);
export const selectRegionsDic = createSelector(impactSelector, (state) =>
    regionAdapter.getSelectors().selectEntities(state.regionsApi)
);
export const impactFeature = createFeature({
    name: IMPACT_FEATURE_KEY,
    reducer: createReducer(
        initialState,
        on(ImpactActions.setIsAvailable, (state, { payload }) => ({
            ...state,
            isActiveImpact: payload,
        })),
        on(ImpactActions.setActiveTab, (state, { payload }) => ({
            ...state,
            activeTab: payload,
        })),
        on(ImpactActions.setLoading, (state, { payload }) => ({
            ...state,
            isLoading: payload,
        })),
        on(ImpactActions.setRunLoadError, (state, { payload }) => ({
            ...state,
            runsLoadError: payload,
        })),
        on(ImpactActions.updateDateRangeRun, (state, { payload }) => ({
            ...state,
            dateRangeImpactRuns: payload,
        })),
        on(ImpactActions.clearRuns, (state) => ({
            ...state,
            runs: impactRunAdapter.removeAll(state.runs),
        })),
        on(ImpactActions.setRuns, (state, { payload }) => {
            const runsResponse = payload as RunImpact[];
            const runs = impactRunAdapter.setMany(runsResponse, state.runs);
            return {
                ...state,
                runs,
            };
        }),
        on(ImpactActions.setControlPoints, (state, { payload }) => ({
            ...state,
            controlPoints: payload as ControlPointImpact[],
        })),
        on(ImpactActions.setNewControlPoint, (state, { payload }) => {
            let controlPoints = [...state.controlPoints];
            if (payload) {
                controlPoints.push(payload);
            } else {
                controlPoints = state.controlPoints.filter((point) => point.id);
            }
            return {
                ...state,
                controlPoints,
            };
        }),
        on(ImpactActions.setRunConfigs, (state, { payload }) => {
            const runsResponse = payload as RunConfigImpact[];
            const configs = impactConfigAdapter.setMany(runsResponse, state.configs);
            return {
                ...state,
                configs,
            };
        }),
        on(ImpactActions.setRunConfig, (state, { payload }) => {
            const configs = impactConfigAdapter.setOne(payload, state.configs);
            return { ...state, configs };
        }),
        on(ImpactActions.setSources, (state, { payload }) => {
            const sources = sourcesAdapter.setMany(payload, state.sources);
            return {
                ...state,
                sources,
                isLoadingSources: false,
            };
        }),
        on(ImpactActions.setSourcesListDemo, (state, { payload }) => {
            const sourcesListDemo = impactSourcesDemoAdapter.setMany(
                payload,
                state.sourcesListDemo
            );
            return {
                ...state,
                sourcesListDemo,
            };
        }),
        on(ImpactActions.setLoadingEditRun, (state, { payload }) => ({
            ...state,
            isLoadingEditRun: payload,
        })),
        on(ImpactActions.setSuccessEditRun, (state, { payload }) => ({
            ...state,
            successEditRun: payload,
        })),
        on(ImpactActions.setErrorEditRun, (state, { payload }) => ({
            ...state,
            errorEditRun: payload,
        })),
        on(ImpactActions.setActiveRun, (state, { payload }) => {
            let activeMmt = state.activeMmt;
            if (!activeMmt || (activeMmt && !payload?.species_list.includes(activeMmt))) {
                activeMmt = payload?.default_species;
            }
            let activeHeight = state.activeHeight;
            if (!activeHeight || (activeHeight && !payload?.heights.includes(activeHeight))) {
                activeHeight = payload?.heights?.[0] ?? null;
            }
            return {
                ...state,
                activeRun: payload,
                activeMmt,
                activeHeight,
            };
        }),
        on(ImpactActions.setActiveMmt, (state, { payload }) => ({
            ...state,
            activeMmt: payload,
        })),
        on(ImpactActions.setActiveHeight, (state, { payload }) => ({
            ...state,
            activeHeight: payload,
        })),
        on(ImpactActions.toggleWindLayer, (state, { payload }) => ({
            ...state,
            showWindOnMap: payload === null ? !state.showWindOnMap : payload,
        })),
        on(ImpactActions.setControlPointsIsLoading, (state, { payload }) => ({
            ...state,
            isLoadingControlPoints: payload,
        })),
        on(ImpactActions.setIsLoadingPostsTimeline, (state, { payload }) => ({
            ...state,
            isLoadingPostsTimeline: payload,
        })),
        on(ImpactActions.setRegionsApiIsLoading, (state, { payload }) => ({
            ...state,
            isLoadingRegionsApi: payload,
        })),
        on(ImpactActions.setTimeIndex, (state, { payload }) => ({
            ...state,
            impactTimeIndex: payload,
        })),
        on(ImpactActions.setDates, (state, { payload }) => {
            if (payload?.length) {
                const dates = [...payload];
                let currentIndex = dates.indexOf(state.dates?.[state.impactTimeIndex]);
                if (currentIndex == state.dates.length - 1) {
                    currentIndex = -1;
                }
                const index = currentIndex == -1 ? 0 : currentIndex;
                return {
                    ...state,
                    dates: dates,
                    impactTimeIndex: index,
                    runDates: { ...state.activeRun },
                };
            }

            return state;
        }),
        on(ImpactActions.setControlPointsTimeline, (state, { payload }) => {
            const response = payload as ControlPointTimeline[];
            const controlPointsTimeline = controlPointTimelineAdapter.setMany(
                response,
                state.controlPointsTimeline
            );
            return {
                ...state,
                controlPointsTimeline,
            };
        }),
        on(ImpactActions.setStationsData, (state, { payload }) => {
            const response = payload as Station[];
            const stations = stationAdapter.setMany(response, state.stations);
            return {
                ...state,
                stations,
            };
        }),
        on(ImpactActions.expandPin, (state, { payload }) => ({
            ...state,
            expandPin: payload?.toString(),
        })),
        on(ImpactActions.setEditControlPoint, (state, { payload }) => ({
            ...state,
            editControlPoint: payload,
        })),
        on(ImpactActions.setActivePin, (state, { payload }) => ({
            ...state,
            activePin: payload,
        })),
        on(ImpactActions.setChartData, (state, { payload }) => ({
            ...state,
            chartData: payload,
        })),
        on(ImpactActions.setCityData, (state, { payload }) => ({
            ...state,
            currentCityData: payload,
        })),
        on(ImpactActions.setNetworkDensity, (state, { payload }) => ({
            ...state,
            networkDensity: payload,
        })),
        on(ImpactActions.setRegions, (state, { payload }) => ({
            ...state,
            regions: payload,
        })),
        on(ImpactActions.setActiveStation, (state, { payload }) => ({
            ...state,
            activeStation: payload,
        })),
        on(ImpactActions.setCurrentSource, (state, { payload }) => ({
            ...state,
            currentSource: payload,
        })),
        on(ImpactActions.setContributionDataToActiveStation, (state, { payload }) => {
            let activeStation = state.activeStation;
            if (activeStation) {
                activeStation = { ...activeStation, dataContribution: payload };
            }
            return {
                ...state,
                activeStation,
            };
        }),
        on(ImpactActions.disableWindButton, (state, { payload }) => ({
            ...state,
            disableWindButton: payload,
        })),
        on(ImpactActions.setRegionsApi, (state, { payload }) => {
            const regions = payload ? (payload as RegionApi[]) : [];
            const regionsApi = regionAdapter.setMany(regions, state.regionsApi);
            return {
                ...state,
                regionsApi,
            };
        }),
        on(ImpactActions.setMapObjectTypes, (state, { payload }) => ({
            ...state,
            displayObjOnMap: payload,
        })),
        on(ImpactActions.setEvents, (state, { payload }) => ({
            ...state,
            events: payload,
        })),
        on(ImpactActions.setActiveEvent, (state, { payload }) => ({
            ...state,
            activeEventId: payload,
        })),
        on(ImpactActions.setCorrelationData, (state, { payload }) => ({
            ...state,
            correlationData: payload,
        })),
        on(ImpactActions.setEventDetailsData, (state, { payload }) => ({
            ...state,
            eventDetailData: payload,
        })),
        on(ImpactActions.clearEvents, (state) => ({
            ...state,
            events: null,
        })),
        on(ImpactActions.updateDateRangeEvents, (state, { payload }) => ({
            ...state,
            dateRangeEvents: payload,
        })),
        on(ImpactActions.setIsLoadingEvents, (state, { payload }) => ({
            ...state,
            isLoadingEvents: payload,
        })),
        on(ImpactActions.setCoordinates, (state, { payload }) => ({
            ...state,
            coordinates: payload,
        })),
        on(ImpactActions.setIsLoadingControlPointForm, (state, { payload }) => ({
            ...state,
            isLoadingControlPointForm: payload,
        })),
        on(ImpactActions.setControlPointFormError, (state, { payload }) => ({
            ...state,
            controlPointFormError: payload,
        })),
        on(ImpactActions.toggleMrrLayer, (state, { payload }) => ({
            ...state,
            showMmrLayer: payload,
        })),
        on(ImpactActions.toggleMrrSourceLayer, (state, { payload }) => ({
            ...state,
            showMrrSourceLayer: payload,
        })),
        on(ImpactActions.toggleLensLayer, (state, { payload }) => ({
            ...state,
            showLensLayer: payload,
        })),
        on(ImpactActions.changedSearchQuerySource, (state, { payload }) => ({
            ...state,
            searchQuerySource: payload,
        }))
    ),
    extraSelectors: ({
        selectDateRangeImpactRuns,
        selectActiveMmt,
        selectActiveRun,
        selectActiveHeight,
        selectDates,
        selectImpactTimeIndex,
        selectExpandPin,
        selectActiveStation,
    }) => ({
        selectRunImpactParams: createSelector(
            selectGroupId,
            selectDateRangeImpactRuns,
            (groupId, dateRange) => {
                if (groupId && dateRange) {
                    const params: HttpParamsOptions = {
                        fromObject: {
                            group_id: groupId,
                            evaluation_time__gte: dateRange.start,
                            evaluation_time__lte: dateRange.end,
                            expand: 'domain',
                        },
                    };
                    return params;
                }

                return null;
            }
        ),
        selectRunConfigParams: createSelector(selectGroupId, (groupId) => {
            if (groupId) {
                const params: HttpParamsOptions = {
                    fromObject: {
                        group_id: groupId,
                        expand: 'domain',
                    },
                };
                return params;
            }

            return null;
        }),
        selectConfigById: (id) =>
            createSelector(selectConfigImpact, (configs) => {
                const config = configs.find((v) => v.id === id);
                return config ?? null;
            }),
        selectStationParams: createSelector(
            selectGroupId,
            selectActiveMmt,
            selectActiveRun,
            selectCurrentMeasureScheme,
            (groupId, mmt, activeRun, scheme) => {
                if (groupId && activeRun) {
                    const begin = moment(activeRun.evaluation_time)
                        .add(activeRun.step_minutes, 'minutes')
                        .valueOf();
                    const end = moment(activeRun.evaluation_time)
                        .add(activeRun.duration_minutes, 'minutes')
                        .valueOf();
                    const params: QueryParams = {
                        date__gt: new Date(begin).toISOString(),
                        date__lt: new Date(end).toISOString(),
                        interval: getIntervalByRun(activeRun.step_minutes),
                        measure_scheme:
                            scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                        concentration_in_mpc: scheme === MeasureScheme.mpc,
                        obj_filter: 'outdoor_post',
                        packet_type: mmt,
                    };
                    return { groupId: groupId, params };
                }
                return null;
            }
        ),
        selectParamsForEvent: createSelector(
            selectGroupId,
            selectActiveMmt,
            selectActiveRun,
            selectActiveHeight,
            selectCurrentMeasureScheme,
            (groupId, mmt, activeRun, height, scheme) => {
                if (groupId && activeRun && height && mmt) {
                    const params = {
                        h: height.toString(),
                        species_list: mmt,
                        measure_scheme: getVangaScheme(scheme),
                    };
                    return { id: activeRun.id?.toString(), params };
                }
                return null;
            }
        ),
        selectParamsStationContribution: createSelector(
            selectGroupId,
            selectActiveMmt,
            selectActiveRun,
            selectActiveHeight,
            selectCurrentMeasureScheme,
            selectActiveStation,
            selectExpandPin,
            (groupId, mmt, activeRun, height, scheme, station, expandPin) => {
                if (groupId && activeRun && height && station && mmt) {
                    const active = station.id === expandPin;
                    const params: HttpParamsOptions = {
                        fromObject: {
                            lon: station?.geometry?.coordinates[0]?.toString(),
                            lat: station?.geometry?.coordinates[1]?.toString(),
                            h: height.toString(),
                            species_list: mmt,
                            measure_scheme: getVangaScheme(scheme),
                        },
                    };
                    return { id: activeRun.id?.toString(), params, active: active !== null };
                }
                return null;
            }
        ),

        isWindLayerAvailable: createSelector(selectActiveRun, (activeRun) => activeRun?.wind_on),
        selectSpeciesList: createSelector(selectActiveRun, (activeRun) => activeRun?.species_list),
        selectImpactHeights: createSelector(
            selectActiveRun,
            (activeRun) => activeRun?.heights ?? []
        ),
        selectImpactSchemaZones: createSelector(
            selectCurrentMeasureScheme,
            selectActiveMmt,
            selectMeasuresZones,
            (scheme, currentMmt, zones) => {
                if (zones && scheme && currentMmt && zones[scheme][currentMmt]) {
                    return {
                        scheme: scheme,
                        mmt: currentMmt,
                        zone: zones[scheme][currentMmt] as ColorZone,
                    };
                }
                return null;
            }
        ),
        selectParamsControlPointsByRun: createSelector(
            selectGroupId,
            selectActiveRun,
            selectActiveHeight,
            selectActiveMmt,
            selectCurrentMeasureScheme,
            (groupId, activeRun, height, mmt, scheme) => {
                if (groupId && activeRun && height) {
                    const params: HttpParamsOptions = {
                        fromObject: {
                            measure_scheme: getVangaScheme(scheme),
                            h: height.toString(),
                            species: mmt,
                        },
                    };

                    return { id: activeRun.id, params };
                }
                return null;
            }
        ),
        selectImpactTime: createSelector(selectDates, selectImpactTimeIndex, (dates, index) =>
            new Date(dates[index]).getTime()
        ),
        selectActiveSources: createSelector(
            selectActiveRun,
            selectSourcesImpact,
            (run, sources) => {
                if (run?.sources?.length) {
                    const result = [];
                    run.sources.forEach((v) => {
                        if (sources[v]) {
                            result.push(sources[v]);
                        }
                    });
                    return result;
                }
                return [];
            }
        ),
        selectActiveRunSources: createSelector(selectActiveRun, (run) => {
            if (run) {
                return { sources: run.sources_snapshot };
            }
        }),
        selectActiveRegions: createSelector(selectRegions, (regions) => {
            if (regions) {
                return { regions: regions };
            }
        }),
        selectParamsForLocality: createSelector(
            selectCurrentMeasureScheme,
            selectGroupId,
            selectCurrentCity,
            (scheme, groupId, city) => {
                if (scheme && groupId && city) {
                    const params: QueryParams = {
                        date__gt: DATE_FOR_CITY.date__gt,
                        date__lt: DATE_FOR_CITY.date__lt,
                        interval: '1',
                        measure_scheme:
                            scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                        concentration_in_mpc: (scheme === MeasureScheme.mpc).toString(),
                        group_id: groupId,
                    };
                    return {
                        cityId: city.id,
                        cityName: city.name,
                        cityMmts: DATE_FOR_CITY.mmtsList,
                        params,
                    };
                }
                return null;
            }
        ),
        selectParamsForRegion: createSelector(
            selectGroupId,
            selectActiveMmt,
            selectActiveRun,
            selectActiveHeight,
            selectCurrentMeasureScheme,
            (groupId, mmt, activeRun, height, scheme) => {
                if (groupId && activeRun && height && mmt) {
                    const params = {
                        h: height.toString(),
                        species_list: mmt,
                        measure_scheme:
                            scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                    };
                    return { id: activeRun.id?.toString(), params };
                }
                return null;
            }
        ),
    }),
});

export const {
    selectIsActiveImpact,
    selectActiveTab,
    selectIsLoading,
    selectIsLoadingRun,
    selectRunsLoadError,
    selectSources,
    selectDateRangeImpactRuns,
    selectRunImpactParams,
    selectRunConfigParams,
    selectConfigById,
    selectIsLoadingEditRun,
    selectErrorEditRun,
    selectSuccessEditRun,
    selectActiveRun,
    selectActiveMmt,
    selectStationParams,
    isWindLayerAvailable,
    selectShowWindOnMap,
    selectDisableWindButton,
    selectSpeciesList,
    selectImpactHeights,
    selectActiveHeight,
    selectImpactSchemaZones,
    selectImpactTimeIndex,
    selectParamsControlPointsByRun,
    selectIsLoadingControlPoints,
    selectIsLoadingRegionsApi,
    selectDates,
    selectChartData,
    selectImpactTime,
    selectActivePin,
    selectActiveSources,
    selectExpandPin,
    selectEditControlPoint,
    selectActiveRunSources,
    selectActiveRegions,
    selectParamsForLocality,
    selectCurrentCityData,
    selectParamsStationContribution,
    selectActiveStation,
    selectRunDates,
    selectDisplayObjOnMap,
    selectEvents,
    selectActiveEventId,
    selectCorrelationData,
    selectParamsForEvent,
    selectEventDetailData,
    selectDateRangeEvents,
    selectIsLoadingEvents,
    selectControlPoints,
    selectCoordinates,
    selectIsLoadingControlPointForm,
    selectControlPointFormError,
    selectShowMmrLayer,
    selectShowLensLayer,
    selectShowMrrSourceLayer,
    selectSearchQuerySource,
    selectIsLoadingPostsTimeline,
} = impactFeature;
export const getControlPointImpactValue = (id) =>
    createSelector(
        selectActiveMmt,
        selectImpactTimeIndex,
        selectControlPointsTimelineDic,
        (mmt, index, points) => {
            if (!isFalseNumber(index) && mmt && points) {
                const arr = points[id]?.timeseries?.[mmt];
                return { value: arr ? arr[index] : null };
            }
            return { value: null };
        }
    );
export const getControlPoint = (id) =>
    createSelector(selectControlPoints, (points) => {
        const point = points.find((v) => v.id === id);
        return point ?? null;
    });

export const getStationImpactValue = (id) =>
    createSelector(
        selectActiveMmt,
        selectImpactTimeIndex,
        selectImpactStationsDic,
        (mmt, index, stations) => {
            if (!isFalseNumber(index) && mmt && stations) {
                const arr = stations[id]?.data?.measurements?.[mmt]?.values;
                return arr ? arr[index] : null;
            }
        }
    );
export const getStationImpact = (id) =>
    createSelector(selectImpactStationsDic, (stations) => stations[id] ?? null);

export const isDraggableControlPoint = (point) =>
    createSelector(selectEditControlPoint, (controlPoint) => {
        if (controlPoint && point) {
            return controlPoint?.id === +point.id;
        }
        return point.obj === NEW_CONTROL_POINT_OBJ_TYPE;
    });
export const selectControlPointsForMap = createSelector(
    selectControlPoints,
    selectControlPointsTimelineDic,
    selectDates,
    (points, pointsTimeline, dates) => {
        const result: ControlPoint[] = [];
        points?.forEach((point) => {
            const timeline = { ...pointsTimeline[point.id]?.timeseries, date: dates };
            const contributions = { ...pointsTimeline[point.id]?.contributions, date: dates };

            const controlPoint: ControlPoint = {
                name: point.name,
                lat: point.lat,
                lon: point.lon,
                id: point.id?.toString(),
                obj: point.obj,
                timeline,
                contributions,
            };
            result.push(controlPoint);
        });
        return result;
    }
);
export const selectImpactTimeRangeWithoutDistinct = createSelector(selectDates, (dates) => ({
    begin: new Date(dates[0]).getTime(),
    end: new Date(dates[dates.length - 1]).getTime(),
}));

export const selectImpactTimeRange = pipe(
    select(selectImpactTimeRangeWithoutDistinct),
    distinctUntilChanged(
        (prev, current) => prev.begin === current.begin && prev.end === current.end
    )
);
export const selectCurrentTimeTs = createSelector(
    selectDates,
    selectImpactTimeIndex,
    (dates, index) => {
        if (dates && dates[index]) {
            return new Date(dates[index]).getTime();
        }
        return null;
    }
);
export const selectCurrentTimeIndexImpact = pipe(
    select(selectCurrentTimeTs),
    distinctUntilChanged((prev, current) => prev === current)
);
export const selectImpactTilesParams = createSelector(
    selectCurrentTimeTs,
    selectActiveHeight,
    selectActiveMmt,
    (ts, height, mmt) => {
        if (ts && height && mmt) {
            const params: TilesUpdateParams = {
                ts,
                height: height,
                substance: mmt,
            };
            return params;
        }
        return null;
    }
);
export const selectImpactStationsForMap = createSelector(
    selectImpactStations,
    selectDisplayObjOnMap,
    (stations, displayObj) => {
        const postsDisplay = displayObj.find((v) => v.id === ImpactMapObjectType.posts)?.selected;
        if (postsDisplay) {
            return stations;
        }
        return [];
    }
);
export const selectImpactPointsForMap = createSelector(
    selectControlPointsForMap,
    selectDisplayObjOnMap,
    (stations, displayObj) => {
        const postsDisplay = displayObj.find((v) => v.id === ImpactMapObjectType.points)?.selected;
        if (postsDisplay) {
            return stations;
        }
        return [];
    }
);
export const selectImpactSourcesForMap = createSelector(
    selectActiveSources,
    selectDisplayObjOnMap,
    (stations, displayObj) => {
        const postsDisplay = displayObj.find((v) => v.id === ImpactMapObjectType.sources)?.selected;
        if (postsDisplay) {
            return stations;
        }
        return [];
    }
);

export const selectImpactRegionsPinForMap = createSelector(
    selectRegions,
    selectDisplayObjOnMap,
    (regions, displayObj) => {
        const postsDisplay = displayObj.find((v) => v.id === ImpactMapObjectType.regions)?.selected;
        if (postsDisplay) {
            return regions;
        }
        return [];
    }
);

export const getRegionImpactValue = (id) =>
    createSelector(selectImpactTimeIndex, selectRegions, (index, regions) => {
        const region = regions.find((v) => v.id === id);
        if (!isFalseNumber(index) && region) {
            const arr = region.timeseries;
            return arr ? arr[index] : null;
        }
    });

export const selectImpactWindParams = createSelector(
    selectDates,
    selectImpactTimeIndex,
    selectShowWindOnMap,
    selectActiveRun,
    selectGroupId,
    selectActiveHeight,
    selectImpactHeights,
    (dates, index, isActive, run, groupId, currentHeight, heights) => {
        if (dates && dates[index] && isActive && run && groupId) {
            const hour = moment(dates[index]).utcOffset(0);

            if (hour.get('minutes') > 30) hour.add(1, 'hours');

            const splitDate = dates[index].split('T');
            const date = splitDate[0].replace(/-/g, '');

            if (run.wind_on) {
                const begin = moment(run.evaluation_time)
                    .add(run.step_minutes, 'minutes')
                    .valueOf();
                const end = moment(run.evaluation_time)
                    .add(run.duration_minutes, 'minutes')
                    .valueOf();
                const currentTime = moment(dates[index]).valueOf();

                if (
                    currentTime <= end &&
                    currentTime >= begin &&
                    run.wind_domain &&
                    currentHeight === heights[0]
                ) {
                    const filename = `${date}_${hour.format('HH')}0000`;
                    return {
                        url: `v1/r/${groupId}/plumes/${run.id}/raster/custom/${currentHeight}/wind/${filename}`,
                        bboxDomain: run.wind_domain,
                    };
                }
                return null;
            }
            return null;
        }

        return null;
    }
);
export const selectRegionFeatures = createSelector(selectRegions, (regions) => {
    if (regions?.length) {
        const features = [];
        regions.forEach((region) => {
            features.push({
                type: 'Feature',
                properties: {
                    color: '#6C7484',
                    dasharray: [2, 2],
                },
                geometry: {
                    coordinates: region.coords,
                    type: 'LineString',
                },
            });
        });
        const result: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
            type: 'FeatureCollection',
            features,
        };

        return result;
    }
    return null;
});
export const selectImpactRegionsForMap = createSelector(
    selectRegionFeatures,
    selectDisplayObjOnMap,
    (data, displayObj) => {
        const postsDisplay = displayObj.find((v) => v.id === ImpactMapObjectType.regions)?.selected;
        if (postsDisplay) {
            return data;
        } else {
            const result: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
                type: 'FeatureCollection',
                features: [],
            };
            return result;
        }
    }
);
export const selectImpactSourcesSquareMap = createSelector(
    selectActiveSources,
    selectDisplayObjOnMap,
    (data, displayObj) => {
        const sourcesDisplay = displayObj.find(
            (v) => v.id === ImpactMapObjectType.sources
        )?.selected;
        if (sourcesDisplay) {
            const features = getSourcesSquareTypeBorder(data);
            const result: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
                type: 'FeatureCollection',
                features,
            };
            return result;
        } else {
            const result: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
                type: 'FeatureCollection',
                features: [],
            };
            return result;
        }
    }
);

export const selectShowLayerImpactOnMap = createSelector(
    selectIsActiveImpact,
    selectDisplayObjOnMap,
    (isActiveImpact, displayObj) => {
        const sourcesDisplay = displayObj.find(
            (v) => v.id === ImpactMapObjectType.sources
        )?.selected;
        if (sourcesDisplay && isActiveImpact) {
            return sourcesDisplay;
        } else {
            return false;
        }
    }
);
export const selectIsCollapsedPost = (id) =>
    createSelector(selectEvents, selectActiveEventId, (events, active) => {
        if (active === null) {
            return false;
        }
        const currentEventPostsIds = events
            .find((v) => v.id === active)
            ?.events?.map((v) => v.post_id);
        return !currentEventPostsIds?.includes(id);
    });
export const selectIsCollapsedSources = (id) =>
    createSelector(selectEvents, selectActiveEventId, (events, active) => {
        if (active === null) {
            return false;
        }
        const sourceIds = [];
        const currentEvent = events.find((v) => v.id === active);
        currentEvent?.events?.forEach((v) => {
            v.data_source?.forEach((v) => {
                if (!sourceIds.includes(v)) {
                    sourceIds.push(v);
                }
            });
        });
        return !sourceIds?.includes(id);
    });
export const selectParamsForEvents = createSelector(
    selectDateRangeEvents,
    selectGroupId,
    (dateRange, groupId) => {
        if (dateRange && groupId) {
            const params: QueryParams = {
                group: groupId,
                date__gt: dateRange.start,
                date__lt: dateRange.end,
            };
            return params;
        }
        return null;
    }
);
export const selectActiveConfig = createSelector(
    selectActiveRun,
    selectConfigImpactDic,
    (activeRun, configs) => {
        if (activeRun && configs[activeRun.config]) {
            return configs[activeRun.config];
        }
    }
);
export const selectIsInitRegionPage = createSelector(
    selectRegions,
    selectActiveSources,
    selectRegionMmt,
    (regions, sources, mmt) => {
        if (regions?.length && sources?.length && mmt) {
            return { regions, sources };
        }
        return null;
    }
);
export const selectMockDataStatRegion = createSelector(
    selectActiveSources,
    selectSpeciesList,
    (sources, mmts) => {
        if (sources?.length && mmts?.length) {
            return { sources, mmts };
        }
        return null;
    }
);

export const selectMmtsListByRegion = createSelector(
    selectActiveRun,
    selectRegionMmt,
    (run, mmt) => {
        if (run && mmt) {
            const list = run.species_list;
            return { list, mmt };
        }
        return null;
    }
);
export const selectIsAllowLensImpact = createSelector(
    selectActiveRun,
    selectExtConfig,
    (run, config) => {
        if (run && config?.impactSettings?.runIdForMrr) {
            if (Array.isArray(config?.impactSettings?.runIdForMrr)) {
                return config?.impactSettings?.runIdForMrr?.includes(run.id);
            }
            return run.id === config?.impactSettings?.runIdForMrr;
        }
        return false;
    }
);
export const selectIsAllowMrrImpact = createSelector(
    selectActiveRun,
    selectExtConfig,
    (run, config) => {
        if (run && config?.impactSettings?.runIdMrr) {
            if (Array.isArray(config?.impactSettings?.runIdMrr)) {
                return config?.impactSettings?.runIdMrr?.includes(run.id);
            }
            return run.id === config?.impactSettings?.runIdMrr;
        }
        return false;
    }
);
export const selectIsAdditionalLayer = createSelector(
    selectIsAllowLensImpact,
    selectIsAllowMrrImpact,
    (lens, mrr) => lens || mrr
);
export const selectIsShowOnMapLensImpact = createSelector(
    selectIsAllowLensImpact,
    selectShowLensLayer,
    (isAllow, isShow) => isAllow && isShow
);
export const selectIsShowOnMapMrrImpact = createSelector(
    selectIsAllowLensImpact,
    selectShowMmrLayer,
    (isAllow, isShow) => isAllow && isShow
);
export const selectLensImpactConfig = createSelector(
    selectGroupId,
    selectIsAllowLensImpact,
    (groupId, isAllow) => {
        if (groupId && isAllow) {
            return groupId === '213' ? LENS_NSK : groupId === '266' ? LENS_ISKITIM : null;
        }
        return null;
    }
);
export const selectMrrImpactConfig = createSelector(
    selectGroupId,
    selectIsAllowMrrImpact,
    (groupId, isAllow) => {
        if (groupId && isAllow) {
            return groupId === '266' ? MRR_ISKITIM : null;
        }
        return null;
    }
);
export const selectMrrSourceConfig = createSelector(
    selectGroupId,
    selectIsAllowLensImpact,
    (groupId, isAllow) => {
        if (groupId && isAllow) {
            return groupId === '266' ? MRR_ISKITIM_SOURCE : null;
        }
        return null;
    }
);
export const selectStatsSourceParams = createSelector(
    selectDateRangeSource,
    selectSourceMmt,
    selectActiveRun,
    selectCurrentSource,
    selectCurrentMeasureScheme,
    (dateRange, mmt, run, source, scheme) => {
        if (dateRange && run && source) {
            const mmts = source.emissions?.map((v) => v.species);
            const species = mmt && mmts.includes(mmt) ? mmt : mmts[0];
            const params: HttpParamsOptions = {
                fromObject: {
                    start: moment(dateRange.start).format('YYYY-MM-DD'),
                    end: moment(dateRange.end).format('YYYY-MM-DD'),
                    species,
                    source: source.id,
                    measure_scheme: getVangaScheme(scheme),
                },
            };
            return { id: run.config, params };
        }

        return null;
    }
);
export const selectIsInitControlPointPage = createSelector(
    selectControlPoints,
    selectActiveSources,
    selectControlPointMmt,
    (controlPoints, sources, mmt) => {
        if (controlPoints?.length && sources?.length && mmt) {
            return { controlPoints, sources };
        }
        return null;
    }
);

export const selectMockDataStatControlPoint = createSelector(
    selectActiveSources,
    selectSpeciesList,
    (sources, mmts) => {
        if (sources?.length && mmts?.length) {
            return { sources, mmts };
        }
        return null;
    }
);

export const selectMmtsListByControlPoint = createSelector(
    selectActiveRun,
    selectControlPointMmt,
    (run, mmt) => {
        if (run && mmt) {
            const list = run.species_list;
            return { list, mmt };
        }
        return null;
    }
);

export const selectFilteredSources = createSelector(
    selectSearchQuerySource,
    selectActiveSources,
    (search, sources) => {
        if (!search) {
            return sources;
        }

        return sources?.filter((source) => {
            const formatSearch = search.toLowerCase();
            return source.name.toLowerCase().includes(formatSearch);
        });
    }
);
