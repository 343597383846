import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';

import { CardComponent } from './components/card/card.component';
import { CardActionsModule } from '@libs/shared-ui';

@NgModule({
    imports: [CommonModule, DirectivesModule, TooltipsModule, CardActionsModule],
    exports: [CardComponent],
    declarations: [CardComponent],
})
export class CardModule {}
