<div class="device-description scroll">
    <div class="device-description__others-info">
        <div class="device-description__others-info--column">
            <information-column [title]="TEXTS.POSTS_AND_DEVICES.dataTransfer">
                <alert-tags
                    [text]="
                        device.is_offline
                            ? TEXTS.POSTS_AND_DEVICES.offline
                            : TEXTS.POSTS_AND_DEVICES.online
                    "
                    [typeTag]="device.is_offline ? 'gray' : 'royalBlue'"
                ></alert-tags>
            </information-column>
        </div>
        <div class="device-description__others-info--column">
            <information-column [title]="TEXTS.POSTS_AND_DEVICES.power">
                <alert-tags [text]="getTextOfPower()" [typeTag]="getTypeOfPower()"></alert-tags>
            </information-column>
        </div>
    </div>
    <information-column
        class="device-description__others-info--mo"
        [title]="TEXTS.LIST_OM.objectsView"
    >
        <div *ngIf="post">
                <static-tags
                    (click)="
                        openPage(
                            pages.networks +
                                '/' +
                                pages.postsList +
                                '/' +
                                pages.details +
                                '/' +
                                device.ancestor?.id
                        )
                    "
                    class="device-description__static-tags--mo"
                    [isActiveElement]="true"
                    [text]="post?.name"
                    [typeTag]="'mo'"
                ></static-tags>
        </div>
        <div
            class="device-description__others-info--not-attached"
            *ngIf="!post"
            [innerHTML]="TEXTS.LIST_DEVICES.deviceNoPost"
        ></div>
    </information-column>
    <section-line [text]="TEXTS.POSTS_AND_DEVICES.info"></section-line>
    <div class="device-description__others-info" *ngIf="device">
        <div class="device-description__others-info--column">
            <information-column
                [title]="TEXTS.POSTS_AND_DEVICES.type"
                [description]="store.select(selectDeviceSourceNameByType(device?.source_type))| async"
            ></information-column>
            <information-column
            [title]="TEXTS.EDIT_STATION.lastTimeStation"
            [description]="device?.device_last_date | timeDate : '---'"
        ></information-column>
        </div>
        <div class="device-description__others-info--column">
            <information-column
                [title]="TEXTS.EDIT_STATION.versionH"
                [description]="device.ext_info?.hardware_version ?? '---'"
            ></information-column>
            <information-column
                [title]="TEXTS.LIST_DEVICES.interval"
                [description]="
                    device.ext_info?.data_delivery_period_sec ? device.ext_info?.data_delivery_period_sec / 60 + TEXTS.LIST_DEVICES.min : '---'
                "
            ></information-column>
        </div>
        <div class="device-description__others-info--column">
            <information-column
                [title]="TEXTS.EDIT_STATION.versionS"
                [description]="device.ext_info?.software_version ?? '---'"
            ></information-column>
        </div>
    </div>
</div>
<button-download-data
    [text]="TEXTS.LIST_DEVICES.loadPrimaryData"
    (click)="openPage(path + '/' + pages.deviceReport + '/' + activatedRoute.snapshot.params.id)"
    class="device-description__button-download-data"
></button-download-data>
