<div class="instant-aqi-legend" [@inOutAnimation]>
    <div class="instant-aqi-legend__header">
        <div class="instant-aqi-legend__title">{{ TEXTS.AQI_DETAILED_INFO.title }}</div>
        <div class="instant-aqi-legend__description">{{ TEXTS.AQI_DETAILED_INFO.description }}</div>
    </div>
    <div class="instant-aqi-legend__content scroll-gray">
        <div class="instant-aqi-legend__legend-title">
            {{ TEXTS.AQI_DETAILED_INFO.legendTitle }}:
        </div>
        <div class="instant-aqi-legend__list">
            <div
                class="instant-aqi-legend__item"
                [class.instant-aqi-legend__item--highlight]="i === currentAqiDangerLevel"
                *ngFor="let description of aqiDangerLevels; index as i"
            >
                <div
                    class="instant-aqi-legend__item-icon instant-aqi-legend__item-icon--{{ i }}"
                ></div>
                <div class="instant-aqi-legend__item-separator">—</div>
                <div class="instant-aqi-legend__item-description">{{ description }}</div>
            </div>
        </div>
        <div class="instant-aqi-legend__more">
            <a class="instant-aqi-legend__more-link" (click)="openDetails($event)">
                {{ TEXTS.AQI_DETAILED_INFO.gotoFAQ }}
            </a>
        </div>
    </div>
</div>
