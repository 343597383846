import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, filter, first } from 'rxjs/operators';
import * as moment from 'moment-timezone';

import { TimelineState } from './store/index';
import {
    onIsEnabledChart,
    playerLoadingData,
    playerReady,
    playerStart,
    playerStop,
} from './store/core.actions';
import {
    selectChartEnabled,
    selectMainChartIsLoading,
    selectPlayer,
} from './store/selectors/core.selectors';

import { MeasureScheme } from '@libs/common';
import { PdkType } from '@libs/common';
import { detectMobile } from '@libs/common';
import { GroupChartConfig } from '@libs/common';
import { Feature } from './models/core';
import { STEP_DURATION } from './constants';
import { TimeRunnerComponent } from './time-runner/time-runner.component';
import { DragAndDrop } from './dnd';
import { GroupTooltipsMmt } from '@libs/common';
import { DataQualityInfo, DataQualityTimeline } from '@libs/common';
import { getIntervalInHours } from './utils';
import { TEXTS } from '@libs/common';

@Component({
    selector: 'timeline-panel',
    templateUrl: './timeline-panel.component.html',
    styleUrls: ['./timeline-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelinePanelComponent implements OnInit, OnDestroy, OnChanges {
    @Input() isCityMode = false;
    @Input() sidebarIsOpened: boolean;
    @Input() currentTime: number;
    @Input() dates: string[];
    private _features: Feature[] = [];

    @Input()
    set features(value: Feature[]) {
        this._features = value;

        if (!this._features?.length) {
            this.charSize$.next(null);
        }
    }

    get features(): Feature[] {
        return this._features;
    }
    @Input() dateTime: string;
    @Input() initSelectMeasurement?: string;
    @Input() showPlayButton = true;
    @Input() labelMode = false;
    @Input() showNow = false;
    @Input() pdk?: { type: PdkType; pdks: { [key in string]: number } };
    @Input() aqiTooltipTemplate: TemplateRef<any>;
    @Input() comparisonEnabled: boolean;
    @Input() isComparisonModeActive: boolean;
    @Input() mmtInfoIcon?: {
        name: string;
        cb: () => void;
    };
    @Input() chartMinMax: GroupChartConfig;
    @Input() measureScheme: MeasureScheme;
    @Input() groupTooltip: GroupTooltipsMmt;
    @Input() qualityDataMode: number;
    @Input() dataQualityTimeline: DataQualityTimeline[] = [];
    @Input() timezone?: string;

    @Output() changeTimeIndex = new EventEmitter<number>();
    @Output() changeTime = new EventEmitter<number>();
    @Output() goToCity = new EventEmitter<string>();
    @Output() removeFromComparison = new EventEmitter<string>();
    @Output() setCompareMode = new EventEmitter<void>();
    @Output() setPlayingState = new EventEmitter<boolean>();
    @Output() showQualityDataInfo = new EventEmitter<DataQualityInfo>();

    charSize$ = new BehaviorSubject<{ left: number; width: number }>(null);
    charSizeSub$ = this.charSize$.asObservable().pipe(distinctUntilChanged(), debounceTime(3));
    isMobile = detectMobile();

    public city: Feature;
    public chartEnabled: boolean;
    public dateArray: string[] = [];
    hasDataByIndex: boolean[] = [];
    public pointSeriesData: Feature[];
    playerState$: Observable<{
        playing: boolean;
        loading: boolean;
        loaded: boolean;
        progress: number;
    }>;
    positionPercent = 0;
    currentTimeStrs: string[] = null;
    timeIndex: number;
    private tm: NodeJS.Timeout;
    subscriptions: Subscription[] = [];
    public currentWidthTimeline: number;
    public textClose = TEXTS.COMMON.closeChart;
    public selectedMeasurements: string[] = [];

    @ViewChild(TimeRunnerComponent) timelineRunnerComponent: TimeRunnerComponent;
    @ViewChild('timelineRunner', { read: ElementRef }) timelineRunner: ElementRef<HTMLElement>;
    @ViewChild('timelineTrack', { read: ElementRef }) timelineTrack: ElementRef<HTMLElement>;

    constructor(
        private store: Store<TimelineState>,
        private el: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef,
        private zone: NgZone
    ) {
        this.playerState$ = this.store.select(selectPlayer);

        const playerStateSub = this.playerState$.subscribe((playerState) => {
            if (playerState.playing && playerState.loaded) {
                this.startPlayingOnReady();
            }
        });

        this.subscriptions.push(playerStateSub);
        const playerStateSubOutput = this.playerState$
            .pipe(distinctUntilKeyChanged('playing'))
            .subscribe((playerState) => {
                this.setPlayingState.emit(playerState.playing);
            });

        this.subscriptions.push(playerStateSubOutput);

        this.setInit();
    }

    ngOnInit() {
        this.store
            .select(selectPlayer)
            .pipe(
                distinctUntilKeyChanged('loading'),
                filter((player) => player.loading && !player.managed)
            )
            .subscribe(() => {
                this.store.dispatch(playerReady());
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dateTime && !changes.dateTime.firstChange && this.currentTimeStrs === null) {
            this.currentTimeStrs = this.formatCurrentTime(changes.dateTime.currentValue);
        }

        if (changes.currentTime) {
            this.updateTime();
        }

        if (changes.dates || changes.sidebarIsOpened || changes.features) {
            this.store
                .select(selectMainChartIsLoading)
                .pipe(first())
                .subscribe((mainChartLoading) => {
                    if (!mainChartLoading) {
                        this.updateDates();
                        this.updateTime();
                    }
                });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    closeChart() {
        this.store.dispatch(onIsEnabledChart({ payload: false }));
    }

    private updateTimeline() {
        const dates = this.dates || [];
        const data = this.features || [];
        this.pointSeriesData = [...data];
        this.dateArray = this.getDates();
        if (data.length === 1) {
            const { properties } = data[0];
            const usableDates = properties.timeseries.date ?? dates;
            const featureHasDataByIndex = data.map((f) => {
                const { timeseries } = f.properties;
                const keys = Object.keys(timeseries).filter((k) => k !== 'date');
                const data = keys.map((k) => timeseries[k]) as number[][];
                return this.indicateData(usableDates.length, data);
            });

            if (
                data[0].properties?.obj === 'station' &&
                data[0].properties.contributions &&
                data[0].properties.contributionsDetails
            ) {
                const dataArr = Array(usableDates.length).fill(true);
                this.hasDataByIndex = this.indicateData(usableDates.length, dataArr).map(
                    (v) => !!v
                );
            } else {
                this.hasDataByIndex = this.indicateData(
                    usableDates.length,
                    featureHasDataByIndex
                ).map((v) => !!v);
            }
        } else {
            this.clearData();
        }
    }

    private setInit() {
        const chartEnabledSub = this.store.select(selectChartEnabled).subscribe((data) => {
            this.chartEnabled = data;
            if (!this.chartEnabled) {
                this.clearData();
            }
        });
        this.subscriptions.push(chartEnabledSub);
    }

    private getDates() {
        const featuresDates = this.features?.[0]?.properties.timeseries.date || [];
        return featuresDates.length ? featuresDates : this.dates;
    }

    private updateTime() {
        const dates = this.getDates();

        this.timeIndex = dates.length - 1;
        dates.find((d, index) => {
            const time = new Date(d).getTime();
            const timeNext = new Date(dates[index + 1]).getTime();

            if (
                time === this.currentTime ||
                (this.currentTime > time && this.currentTime < timeNext)
            ) {
                this.timeIndex = index;
                return true;
            }
        });
        this.currentTimeStrs = this.formatCurrentTime(dates[this.timeIndex]);
        this.setRunnerPosition(this.timeIndex);
    }

    private updateDates() {
        const dates = this.getDates();

        if (dates?.length) {
            this.updateTimeline();
            this.dateArray = dates;
        }
    }

    private indicateData(len: number, data: number[][]): number[] {
        const init: number[] = Array(len).fill(null);
        return data.reduce((acc, v) => acc.map((a, i) => (a || v?.[i] !== null ? 1 : null)), init);
    }

    onTrackClick(index: number) {
        this.stopPlaying();
        this.setTime(index);
    }

    public goCity(cityId: string) {
        this.goToCity.emit(cityId);
    }

    public updateMmts($event: string[]) {
        this.selectedMeasurements = [...$event];
    }

    dragAndDrop: DragAndDrop;

    dragStart(e: MouseEvent | TouchEvent) {
        this.stopPlaying();
        this.zone.runOutsideAngular(() => {
            this.dragAndDrop = new DragAndDrop(this.timelineRunner.nativeElement, (x: number) => {
                const position = this.timelineTrack.nativeElement.getBoundingClientRect();
                const relPos = Math.round(
                    (x - position.left) / (position.width / this.dateArray.length)
                );
                if (relPos >= 0 && relPos < this.dateArray.length) {
                    this.zone.run(() => {
                        this.setTime(relPos);
                    });
                }
                if (relPos <= 0) {
                    this.zone.run(() => {
                        this.setTime(0);
                    });
                }

                if (relPos >= this.dateArray.length) {
                    this.zone.run(() => {
                        this.setTime(this.dateArray.length - 1);
                    });
                }
            });

            const pageX = e instanceof MouseEvent ? e.pageX : e.touches[0].pageX;
            this.dragAndDrop.dragStart(pageX);
        });
    }

    @HostListener('window:mouseup')
    @HostListener('window:touchend')
    dragEnd() {
        if (this.dragAndDrop) {
            this.dragAndDrop = null;
        }
    }

    setRunnerPosition(index: number) {
        const len = this.dateArray.length;

        if (len === 0 && index === 0) {
            this.positionPercent = 0;
        } else {
            const halfInterval = (100 * 0.5) / len;
            this.positionPercent = (100 * index) / len + halfInterval;
        }
    }

    playPause(isPlaying: boolean, isLoading: boolean) {
        if (isPlaying || isLoading) {
            this.stopPlaying();
        } else {
            this.startPlaying();
        }
    }

    public goToEndTime() {
        this.setTime(this.dateArray.length - 1);
    }

    public showGoTOEnd = () => !this.isMobile && this.timeIndex !== this.dateArray.length - 1;

    public setShowQualityDataInfo($event) {
        this.showQualityDataInfo.emit(
            new DataQualityInfo(
                this.dataQualityTimeline[$event].dataMarkers,
                this.features[0],
                $event
            )
        );
    }

    updateChartSize(value: { left: number; width: number }) {
        this.charSize$.next(value);
        this.cdr.detectChanges();
    }

    private startPlaying() {
        this.store.dispatch(playerLoadingData());
    }

    private startPlayingOnReady() {
        let index = this.timeIndex;
        const date = this.dateArray;

        if (index === -1 || index === date.length - 1) {
            index = 0;
        }

        if (this.tm) {
            clearInterval(this.tm);
        }

        this.tm = setInterval(() => {
            if (++index > date.length - 1) {
                this.stopPlaying();
            } else {
                this.setTime(index);
            }
        }, STEP_DURATION);

        this.store.dispatch(playerStart());
    }

    private stopPlaying() {
        if (this.tm) {
            clearInterval(this.tm);
        }

        this.store.dispatch(playerStop());
    }

    private setTime(index: number) {
        if (index >= 0) {
            if (index === this.timeIndex) return;

            this.changeTimeIndex.emit(index);
            this.changeTime.emit(new Date(this.dateArray[index]).getTime());
        } else {
            this.stopPlaying();
        }
    }

    private formatCurrentTime(value: string): string[] {
        const hoursInterval = getIntervalInHours(this.dateArray[0], this.dateArray[1]);
        const isShowDayFormat = hoursInterval >= 24;
        const localTime = moment(value);
        const month = localTime.format('MMM').slice(0, 3);

        return [
            `${localTime.format('D')} ${month}` + (isShowDayFormat ? '' : `, `),
            isShowDayFormat ? undefined : localTime.format('HH:mm'),
        ];
    }

    private clearData(): void {
        const { length } = this.dateArray;
        this.hasDataByIndex = Array(length).fill(true);
    }
}
