<div class="track-panel-wrapper" #trackPanel>
    <div class="track-data">
        <ng-container
            *ngIf="dates | toTimelineData: hasDataByIndex : currentWidthTimeline : showNow : dataQuality  as timeline">
            <div
                class="block-wrapper"
                *ngFor="let el of timeline; index as i"
                [ngClass]="{ 'block-wrapper--has-data': el.hasData }"
            >
                <div class="block"
                     [style.backgroundColor]="i | timelineBlockBackgroundColor: el"
                     [ngClass]="{ active: i === index && !el.isNow, 'is-now': el.isNow }"
                    (click)="setIndex(i)"
                    caTooltip
                    [caTooltipPlacement]=" i < 30 ? 'top-left' : 'top-right'"
                    [caTooltipAllowHover]="true"
                    [hoverDetachTime]="1000"
                    [caTooltipTemplate]="tooltip"
                >
                    <ng-container *ngIf="el.label">
                        <div class="label" [innerHTML]="el.label | safeHtml"></div>
                        <div class="tick"></div>
                    </ng-container>
                    <ng-container *ngIf="el.isNow">
                        <div
                            class="now-wrapper"
                            [ngClass]="{ 'chart-open': chartEnabled, 'with-label': el.label }"
                        >
                            <div class="label-now">{{ nowText }}</div>
                            <div class="now-border"></div>
                        </div>
                    </ng-container>
                    <ng-template #tooltip>
                        <ng-container *ngIf="!isMobile&&qualityDataMode&&chartEnabled&&dataQuality[i]&&hasDataByIndex[i]">
                            <ca-tooltip [pointerColor]="'#404655'" class="quality-data-tooltip-wrapper">
                                <div class="tooltip-wrapper" *ngIf="getTooltipText(i) as tooltip">
                                    <div class="quality-data-tooltip" [innerHTML]="tooltip?.text"></div>
                                    <a class="quality-data-link"
                                       *ngIf="tooltip?.link" (click)="openDetailsQuality(i)">
                                         {{textsQuality.tooltipLink}}
                                    </a>
                                </div>
                            </ca-tooltip>
                        </ng-container>
                        <ng-container *ngIf="!hasDataByIndex[i]">
                            <ca-tooltip [pointerColor]="'#404655'" class="quality-data-tooltip-wrapper">
                                <div class="tooltip-wrapper">
                                    <div class="quality-data-tooltip" [innerHTML]="textsQuality.tooltip.noData"></div>
                                </div>
                            </ca-tooltip>
                        </ng-container>
                    </ng-template>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="track-label-quality-data" *ngIf="qualityDataMode&&chartEnabled&&dataQuality.length">{{textsQuality.timelineLabel}}</div>
    <div class="timezone-info" *ngIf="timezone">{{textConfig}}: {{timezone}}</div>
</div>

