import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './modules/core/services/route-guards/auth.guard';
import { loginRoutes } from './modules/login/login-routing.module';
import { CityScreenPage } from './cityScreenPage';
import { ClientLoginPageComponent } from './pages/client-login-page/client-login-page.component';
import { cityScreenPageRoutes } from '@cityair/cityscreen-page.routing.module';
import { MobileGuard } from '@cityair/modules/core/services/route-guards/mobile.guard';

export const cityscreenRoutes: Routes = [
    {
        path: '',
        component: CityScreenPage,
        children: cityScreenPageRoutes,
        canActivate: [AuthGuard, MobileGuard],
    },
    {
        path: 'login',
        component: ClientLoginPageComponent,
        children: loginRoutes,
        canActivate: [AuthGuard],
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(cityscreenRoutes)],
    exports: [RouterModule],
})
export class CityscreenRoutingModule {}
