// TODO: move to the Directive
export class DragAndDrop {
    constructor(private el: HTMLElement, private onMoveCallback: (pos: number) => void) {
        this.el = el;
    }

    dragStart(pageX: number) {
        const shiftX = pageX - this.el.getBoundingClientRect().left + window.pageXOffset;

        this.el.style.transition = 'none';
        document.onmousemove = (e) => this.moveTo(e.pageX, shiftX);
        document.ontouchmove = (e) => this.moveTo(e.touches[0].pageX, shiftX);

        document.onmouseup = document.ontouchend = () => {
            this.el.style.transition = '';
            document.onmousemove = document.ontouchmove = null;
            document.onmouseup = document.ontouchend = null;
        };
    }

    moveTo(targetX: number, shiftX: number) {
        const left = Math.round(targetX - shiftX);
        window.getSelection().removeAllRanges();
        this.onMoveCallback(left);
    }
}
