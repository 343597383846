<div *ngIf="isMobile" class="market_link">
    <ng-container *ngIf="isIos">
        <div class="market_link__text">
            {{ TEXTS.MARKET.text }}
            <span style="color: #5d31bf">{{ TEXTS.MARKET.airvoice }}</span>
        </div>
        <a
            target="_blank"
            [href]="TEXTS.MARKET.linkApple"
            class="market_link__img market_link__img-apple"
        ></a>
    </ng-container>

    <ng-container *ngIf="!isIos">
        <div class="market_link__text">
            {{ TEXTS.MARKET.text }}
            <span style="color: #5d31bf">{{ TEXTS.MARKET.airvoice }}</span>
        </div>
        <a
            target="_blank"
            [href]="TEXTS.MARKET.linkGoogle"
            class="market_link__img market_link__img-google"
        ></a>
    </ng-container>
</div>

<div *ngIf="!isMobile" class="market_link_tablet">
    <ng-container *ngIf="isIos">
        <div class="market_link_tablet__text">
            {{ TEXTS.MARKET.text }} {{ TEXTS.MARKET.airvoice }}
        </div>
        <a
            target="_blank"
            [href]="TEXTS.MARKET.linkApple"
            class="market_link_tablet__img market_link__img-apple"
        ></a>
    </ng-container>

    <ng-container *ngIf="!isIos">
        <div class="market_link_tablet__text">
            {{ TEXTS.MARKET.text }} {{ TEXTS.MARKET.airvoice }}
        </div>
        <a
            target="_blank"
            [href]="TEXTS.MARKET.linkGoogle"
            class="market_link_tablet__img market_link__img-google"
        ></a>
    </ng-container>
</div>
