import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Measurement, Post, PostInfo, PostsMeasurementsResponse, StatInfo } from '../services/api';
import * as indoorActions from './actions';
import type { NotificationEvent } from './props.type';
import { analyticType, MMT_INDOOR, getTimeBegin, getTimeEnd } from '@cityair/modules/indoor/config';
import { isFalseNumber } from '@libs/common';

export const measurementAdapter: EntityAdapter<Measurement> = createEntityAdapter<Measurement>({
    selectId: (m: Measurement) => `${m.postId}_${m.date}`,
});

// TODO разбить на группы
export interface IndoorState {
    posts: Post[];
    outdoorPosts: Post[];
    outdoorData: Measurement[];
    measurements: EntityState<Measurement>;
    currentMeasure: MMT_INDOOR;
    analyticType: analyticType;
    activePointId: number;
    activeOutdoorId: number;
    isPointsLoading: boolean;
    postInfo: PostInfo;
    timeBegin: string;
    timeEnd: string;
    currentTime: string;
    timeSequences: string[];
    selectMeasurement: string;
    statInfoCollection: StatInfo;
}

export const initialState: IndoorState = {
    posts: [],
    outdoorPosts: [],
    outdoorData: [],
    measurements: measurementAdapter.getInitialState(),
    currentMeasure: MMT_INDOOR.indoorAqi,
    postInfo: null,
    analyticType: analyticType.floor,
    activePointId: null,
    activeOutdoorId: null,
    isPointsLoading: true,
    timeBegin: getTimeBegin(),
    timeEnd: getTimeEnd(),
    currentTime: null,
    timeSequences: [],
    selectMeasurement: null,
    statInfoCollection: null,
};

const _indoorReducer = createReducer(
    initialState,

    on(indoorActions.updateTimeRange, (state: IndoorState, props) => ({
        ...state,
        isPointsLoading: true,
    })),

    on(indoorActions.allPostsResponse, (state: IndoorState, props: PostsMeasurementsResponse) => {
        const result = prepareDataResponse.postData(props.data);

        if (result.length) {
            const timeSequencesSet = new Set(result.map((d) => d.date));
            const timeSequences = Array.from(timeSequencesSet);

            const timeBegin = timeSequences[0];
            const _timeBegin = new Date(timeBegin).getTime();
            const timeEnd = timeSequences[timeSequences.length - 1];
            const _timeEnd = new Date(timeEnd).getTime();
            const _current = new Date(state.currentTime).getTime();

            const findNearestTime = () => {
                if (!state.currentTime || !timeSequences.includes(state.currentTime)) {
                    return timeEnd;
                }

                if (_current < _timeBegin) {
                    return timeBegin;
                }

                if (_current > _timeEnd) {
                    return timeEnd;
                }

                return state.currentTime;
            };

            return {
                ...state,
                timeBegin: timeBegin,
                timeEnd: timeEnd,
                isPointsLoading: false,
                currentTime: findNearestTime(),
                posts: props.posts,
                measurements: measurementAdapter.setAll(result, state.measurements),
                timeSequences,
            };
        } else {
            return {
                ...state,
                posts: [],
                isPointsLoading: false,
            };
        }
    }),
    on(indoorActions.setOutdoorResponse, (state: IndoorState, props: PostsMeasurementsResponse) => {
        const result = prepareDataResponse.postData(props.data);
        return {
            ...state,
            outdoorPosts: props.posts,
            outdoorData: result,
        };
    }),
    on(indoorActions.postInfoResponse, (state: IndoorState, postInfo: PostInfo) => ({
        ...state,
        postInfo,
    })),

    on(indoorActions.selectPoint, (state: IndoorState, { id }) => ({
        ...state,
        activePointId: id,
    })),

    on(indoorActions.selectMmt, (state: IndoorState, { payload }) => ({
        ...state,
        currentMeasure: payload,
    })),

    on(indoorActions.deselectPoints, (state: IndoorState) => ({
        ...state,
        activePointId: null,
        postInfo: null,
    })),

    on(indoorActions.changeAnalyticPage, (state: IndoorState, props) => ({
        ...state,
        analyticType: props.payload,
    })),

    on(indoorActions.indoorUpdateTimeIndex, (state: IndoorState, { index }) => ({
        ...state,
        currentTime: state.timeSequences[index],
    })),

    on(indoorActions.indoorShowEventOnChart, (state: IndoorState, props: NotificationEvent) => ({
        ...state,
        timeBegin: props.beginDate,
        timeEnd: props.endDate,
        selectMeasurement: props.selectMeasurement,
    })),

    on(indoorActions.statInfoResponse, (state: IndoorState, props) => ({
        ...state,
        statInfoCollection: props.payload,
    })),
    on(indoorActions.setHeaderGlobalLink, (state: IndoorState, props) => ({
        ...state,
        isHeaderGlobalLink: props.payload,
    })),
    on(indoorActions.setPostErrorResponse, (state: IndoorState) => ({
        ...state,
        isPointsLoading: false,
    })),
    on(indoorActions.setIsPointsLoading, (state: IndoorState, props) => ({
        ...state,
        isPointsLoading: props.payload,
    })),
    on(indoorActions.selectPointOutdoor, (state: IndoorState, props) => ({
        ...state,
        activeOutdoorId: props.id,
        activePointId: null,
    }))
);
const prepareDataResponse = {
    postData: (data: Measurement[]) => {
        const result = data.map((d) => ({
            ...d,
            pm25: !isFalseNumber(d.pm2) ? d.pm2 : null,
        }));

        return result;
    },
};
export interface IIndoorState {
    core: IndoorState;
}

export function indoorReducer(state, action): any {
    return _indoorReducer(state, action);
}

export const indoorReducers: ActionReducerMap<IIndoorState> = {
    core: indoorReducer,
};
