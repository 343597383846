import { createFeatureSelector, createSelector } from '@ngrx/store';

import { eventNotifyAdapter, NotificationsState, settingsNotifyAdapter } from './reducers';
import { selectAllPosts } from '@libs/shared-store';
import { BaseNotificationEvent, NotificationQueryParams, NotificationType } from '@libs/common';
import { annotateWithDates, shortDateFormatWithClosest } from '@libs/common';
import { getRelevantTime } from '@cityair/modules/notifications/notifications.utils';
import { selectGroupId } from '@libs/shared-store';
import { NOTIFICATIONS_EVENT_COUNT } from '@cityair/modules/notifications/constants';
import * as moment from 'moment-timezone';

export const selectNotificationsState = createFeatureSelector<NotificationsState>('notifications');

export const selectNotifiablePostIds = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.notifiablePosts
);

export const selectNotifiablePosts = createSelector(
    selectAllPosts,
    selectNotifiablePostIds,
    (markers, postIds) => markers.filter((m) => postIds.includes(m.id))
);

export const selectNotNotifiablePosts = createSelector(
    selectAllPosts,
    selectNotifiablePostIds,
    (markers, postIds) => markers.filter((m) => !postIds.includes(m.id))
);
export const selectNotificationsBasicSettings = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => settingsNotifyAdapter.getSelectors().selectAll(state.settings)
);
export const selectNotificationSettingById = (id) =>
    createSelector(selectNotificationsState, (state) => state.settings.entities[id] ?? null);

export const selectNotificationSettingTitleById = (id) =>
    createSelector(selectNotificationsState, (state) => state.settings.entities[id]?.name ?? null);

export const selectAllNotificationsEventsPdk = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => eventNotifyAdapter.getSelectors().selectAll(state.eventsPdk)
);
export const selectNotificationsPdkById = (id) =>
    createSelector(selectNotificationsState, (state) => state.eventsPdk.entities[id] ?? null);
export const selectCurrentSettingsType = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.currentSettingsType
);
export const selectCurrentPage = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.currentPage
);
export const selectPreviousPage = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.previousPage
);
export const selectCurrentFormType = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.currentFormType
);
export const selectCurrentSettingForm = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.currentSettingForm
);
export const selectCurrentSettingFormWithGroup = createSelector(
    selectCurrentSettingForm,
    selectGroupId,
    (currentForm, groupId) => ({ ...currentForm, group_id: groupId })
);
export const selectNotificationsSettingsByType = createSelector(
    selectNotificationsBasicSettings,
    selectCurrentSettingsType,
    (settings, type) => settings?.filter((v) => v.type === type)
);
export const selectAllNotificationsEventsDevice = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => eventNotifyAdapter.getSelectors().selectAll(state.eventsDevice)
);

export const selectActiveNotifySettings = createSelector(selectNotificationsBasicSettings, (data) =>
    data.filter((item) => item.is_active)
);

export const selectDeactivatedNotifySettings = createSelector(
    selectNotificationsBasicSettings,
    (data) => data.filter((item) => !item.is_active)
);
export const selectNotificationsQueryState = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.queryState
);
export const selectParamsLoadDeviceEvent = createSelector(selectGroupId, (group) => {
    if (group) {
        const params: NotificationQueryParams = {
            group,
            skip: 0,
            take: NOTIFICATIONS_EVENT_COUNT,
            type: NotificationType.deviceIncident,
        };
        return params;
    }
    return null;
});
export const selectParamsLoadPdkEvent = createSelector(selectGroupId, (group) => {
    if (group) {
        const params: NotificationQueryParams = {
            group,
            skip: 0,
            take: NOTIFICATIONS_EVENT_COUNT,
            type: NotificationType.pdkExcess,
        };
        return params;
    }
    return null;
});

export const selectTotalNotifyEventsPdk = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => eventNotifyAdapter.getSelectors().selectTotal(state.eventsPdk)
);
export const selectCurrentEventType = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.currentEventType
);
export const selectSettingsNotifyByType = createSelector(
    selectNotificationsBasicSettings,
    selectCurrentEventType,
    (settings, currentType) => settings?.filter((v) => v.type === currentType)
);
export const selectFilterText = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.filter.query
);
export const selectSettingsIdsByName = createSelector(
    selectNotificationsBasicSettings,
    selectFilterText,
    (settings, filterText) => {
        const result = settings?.filter((v) =>
            v?.name.toLowerCase().includes(filterText?.toLowerCase())
        );
        return result.map((v) => v.id);
    }
);
export const selectAllNotificationsEvents = createSelector(
    selectCurrentEventType,
    selectAllNotificationsEventsPdk,
    selectAllNotificationsEventsDevice,
    (currentType, pdk, device) => (currentType === NotificationType.pdkExcess ? pdk : device)
);
export const selectIsLoadingEventsList = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.isLoadingList
);
export const selectPostsIdsByName = createSelector(
    selectAllPosts,
    selectFilterText,
    (posts, filterText) => {
        const result = posts?.filter((v) =>
            v?.name?.toLowerCase().includes(filterText?.toLowerCase())
        );
        return result.map((v) => v.id);
    }
);
export const selectAllNotificationsEventsWithFilter = createSelector(
    selectAllNotificationsEvents,
    selectPostsIdsByName,
    selectSettingsIdsByName,
    selectFilterText,
    (data, posts, settings, filterText) => {
        let result;
        if (filterText === undefined || filterText === null || filterText === '') {
            result = [...data];
        } else {
            result = [...data].filter(
                (item) =>
                    posts.indexOf(item.post_id) !== -1 ||
                    posts.indexOf(item.service_excess_info?.post_id) !== -1 ||
                    settings.indexOf(item.settings_id) !== -1 ||
                    item.pdk_value_type?.toLowerCase().includes(filterText.toLowerCase()) ||
                    item.service_excess_info?.device_serial
                        .toLowerCase()
                        .includes(filterText.toLowerCase())
            );
        }

        return annotateWithDates<BaseNotificationEvent>(
            (item) => getRelevantTime(item),
            shortDateFormatWithClosest
        )(result);
    }
);

export const selectLoadMoreParams = createSelector(
    selectGroupId,
    selectCurrentEventType,
    selectNotificationsQueryState,
    (group, type, query) => {
        if (group) {
            const queryNumber =
                type === NotificationType.pdkExcess
                    ? query.pdk.queryNumber
                    : query.device.queryNumber;

            const params: NotificationQueryParams = {
                group,
                skip: queryNumber * NOTIFICATIONS_EVENT_COUNT,
                take: NOTIFICATIONS_EVENT_COUNT,
                type,
            };
            return params;
        }
        return null;
    }
);
export const selectIsLoadingForm = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.isLoadingForm
);
export const selectFormError = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.formError
);
export const selectIsAvailableLoadMore = createSelector(
    selectCurrentEventType,
    selectNotificationsQueryState,
    (type, query) =>
        type === NotificationType.pdkExcess
            ? query.pdk.isAvailableLoadMore
            : query.device.isAvailableLoadMore
);
export const currentEvent = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.selectedEvent
);
export const currentEventDataBeginTime = createSelector(
    currentEvent,
    (selectedEvent) => selectedEvent?.begin_time ?? null
);
export const currentEventDataRange = createSelector(currentEventDataBeginTime, (dateTime) => {
    // use +/- 1 day, since there is TZ-shift in timeline after calling updateTimeDiapason
    if (dateTime) {
        const timePaddingInMs = 24 * 60 * 60 * 1000;

        const eventTime = moment(dateTime).valueOf();

        return {
            begin: eventTime - timePaddingInMs,
            end: pastDateOrNow(eventTime + timePaddingInMs),
        };
    }
    return null;
});
function pastDateOrNow(dateTime: number) {
    return Math.min(dateTime, Date.now());
}
export const selectCurrentAnalysisEvent = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.currentAnalysisEvent
);
export const selectAnalysisDates = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.analysisPostsDates
);
