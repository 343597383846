export class UserItems {
    email: string;
    login: string;
    userId: number;
    roleId?: number;

    constructor() {
        this.userId = 0;
        this.email = '';
        this.login = '';
    }
}

export enum MoExportType {
    mo = 1,
    tza4 = 2,
    moIndoor = 3,
}

export class DownloadPopupData {
    type: MoExportType;
    title: string;
    ids: string[];
    mos?: { id: string; name: string }[];
    devices?: { id: number; serialNumber: string }[];
    currentTabInterval?: number;
}

export enum ModulesIds {
    forecast = 1,
    plumes = 2,
    indoor = 3,
}

export type ModelDownloadPopup = {
    timeBegin: number;
    timeEnd: number;
    downloadType: MoExportType;
    downloadTitle: string;
};

export class MoItemsDataToExcelRequestProps {
    timeBegin: number;
    timeEnd: number;
    moIds: string[];
    interval: number;
    type: number;
    unitsType: number;
    insertPdk: boolean;
}

export class StationDataToExcelRequestProps {
    type: number;
    timeBegin: number;
    timeEnd: number;
    ids: number[];
}
