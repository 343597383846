<div class="search" [formGroup]="form">
    <input
        class="search__input ellipsis"
        formControlName="textInput"
        [attr.placeholder]="textPlaceholderInner"
        (focus)="onFocus()"
        (blur)="onBlur()"
    />
    <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="search__loupe"
            [class.search__loupe--active]="focus"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4904 8.10264C16.4904 4.17996 13.2191 1 9.18367 1C5.14828 1 1.87695 4.17996 1.87695 8.10264C1.87695 12.0253 5.14828 15.2053 9.18367 15.2053C10.6902 15.2053 12.0903 14.7621 13.2534 14.0024L16.0318 16.7028C16.4394 17.0991 17.1004 17.0991 17.508 16.7028C17.9156 16.3066 17.9156 15.6641 17.508 15.2679L14.8053 12.64C15.8576 11.4095 16.4904 9.82789 16.4904 8.10264ZM3.96459 8.10261C3.96459 5.30069 6.30125 3.02929 9.18367 3.02929C12.0661 3.02929 14.4028 5.30069 14.4028 8.10261C14.4028 10.9045 12.0661 13.1759 9.18367 13.1759C6.30125 13.1759 3.96459 10.9045 3.96459 8.10261Z" fill="#6C7484"/>
</svg>
    <cross-button
        class="search__clear"
        [class.search__clear--active]="textInner"
        (click)="clear()"
    ></cross-button>
</div>
