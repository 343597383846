import { Component, Output, Input, EventEmitter } from '@angular/core';
import { TabModel } from '@libs/common';

@Component({
    selector: 'tabs-view-mode',
    templateUrl: './tabs-view-mode.component.html',
    styleUrls: ['./tabs-view-mode.component.less'],
})
export class TabsViewModeComponent {
    @Input() tabs: TabModel[];
    @Input() selectedTab: TabModel;
    @Output() showTab = new EventEmitter<TabModel>();

    selectTab(tab: TabModel) {
        this.showTab.emit(tab);
    }
}
