export class ModulePageConfig {
    enableCalendar = false;
    enableAqi = true;

    enableMap = true;
    enableTopElements = true;
    fullScreen = false;

    lang?: string;
    mapClassName?: string;
}

export const defaultModuleConfig: ModulePageConfig = {
    ...new ModulePageConfig(),
    enableCalendar: true,
    enableAqi: true,
};
