import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BtnAcceptComponent } from './btn-accept.component';
import { LoadersModule } from '@libs/shared-ui';

@NgModule({
    imports: [CommonModule, LoadersModule],
    exports: [BtnAcceptComponent],
    declarations: [BtnAcceptComponent],
})
export class BtnAcceptModule {}
