<div class="subscription_card_content">
    <div class="subscription_card_content__title">
        {{ textsNotification.accommodationObjects }}
    </div>
    <div class="subscription_card_content__container subscription_card_content__container-mo">
        <tags-list
            *ngIf="currentMos?.length !== 0; else showStubTag"
            [tags]="currentMos"
            [truncateTo]="maxInlineMos"
            [textNodesFns]="postsListLabels()"
        ></tags-list>
        <ng-template #showStubTag>
            <tags-list *ngIf="subscription.pdk_check_settings?.is_for_all_posts" [tags]="stubTags"></tags-list>
        </ng-template>
    </div>

    <div class="subscription_card_content__title">{{ textsNotification.controlledSubstances }}</div>
    <div class="subscription_card_content__container">
        <div
            class="subscription_card_content__item mmt"
            *ngIf="subscription.pdk_check_settings?.is_for_any_value_type; else listValues"
        >
            {{ textsNotification.all }}
        </div>
        <ng-template #listValues>
            <div
                class="subscription_card_content__item mmt"
                *ngFor="let value of subscription.pdk_check_settings?.selected_value_types"
                [innerHTML]="getValueName(value)"
            ></div>
        </ng-template>
    </div>

    <div class="subscription_card_content__title" style="margin-bottom: 12px;">{{ textsNotification.levelExcess }}</div>
    <div class="subscription_card_content__container">
        <div class="subscription_card_content__item level-excess">
            {{ subscription.pdk_check_settings?.pdk_mr_multiplier | valueFormatNumber : 1}}&nbsp;{{ textsNotification.excessLevelUnits }}
        </div>
    </div>
    <div class="subscription_card_content__title">{{ textsNotification.recipients }}</div>
    <div class="subscription_card_content__container">
        <div class="subscription_card_content__item" *ngIf="getEmails().length as lenEmail else emailsNotSpecified">
            <div class="sender" *ngFor="let email of getEmails() | slice: 0 : truncateToSender ">{{email}}</div>
            <div class="sender link" *ngIf="!showMoreSender && lenEmail > truncateToSender" (click)="toggleEmailMore()"> {{textsNotification.showMore}} {{ lenEmail - truncateToSender}}</div>
            <div class="sender link" *ngIf="showMoreSender" (click)="toggleEmailMore()">{{ textsNotification.collapse}}</div>
        </div>
        <ng-template #emailsNotSpecified>
            <div class="subscription_card_content__item">{{textsNotification.emailsNotSpecified}}</div>
        </ng-template>
    </div>
</div>
