import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { TEXTS } from '@libs/common';
import { OffPanelPopupService } from '@libs/shared-store';
import * as moment from 'moment-timezone';
import {
    DownloadPopupData,
    ModelDownloadPopup,
    MoExportType,
    MoItemsDataToExcelRequestProps,
} from '@cityair/namespace';
import { Store } from '@ngrx/store';
import { first, Subject } from 'rxjs';
import { selectTimeRange } from '@cityair/modules/core/store/selectors';
import { DownloadPopupsService } from './download-popups.service';
import { takeUntil } from 'rxjs/operators';

import {
    downloadDataPosts,
    selectDownloadResult,
} from '@cityair/modules/core/store/posts/posts.feature';

@Component({
    selector: 'download-popups-wrapper',
    templateUrl: './download-popups-wrapper.component.html',
})
export class DownloadPopupsWrapperComponent implements OnDestroy {
    @ViewChild('popupDownload', { static: true }) popupDownload: TemplateRef<HTMLDivElement>;

    MoExportType = MoExportType;
    downloadType: MoExportType;
    title: string;
    ids: string[];
    mos: { id: string; name: string }[];
    devices: { id: number; serialNumber: string }[];
    currentTabInterval?: number;

    TEXTS = TEXTS;

    isSaveBtnDisabled = false;
    isActiveLoader = false;

    objectForReportMo: MoItemsDataToExcelRequestProps;
    downloadPopupObj: ModelDownloadPopup;

    errorDownloadMsg: string;
    private onDestroy$ = new Subject<void>();
    constructor(
        private popupProvider: OffPanelPopupService,
        private store: Store,
        private downloadPopupsService: DownloadPopupsService
    ) {
        this.downloadPopupsService.openDownloadPopup$.asObservable().subscribe((data) => {
            this.showDownloadPopup(data);
        });
        this.store
            .select(selectDownloadResult)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                if (data !== null) {
                    if (data) this.closeDownloadPopup();
                    this.isActiveLoader = false;
                }
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    closeDownloadPopup = () => {
        this.popupProvider.clear();
    };

    getSaveBtnDisabled = (event: boolean) => {
        this.isSaveBtnDisabled = event;
    };

    getObjectForReportMO = (event) => {
        this.objectForReportMo = event;
    };

    private showDownloadPopup = (data: DownloadPopupData) => {
        this.store
            .pipe(selectTimeRange)
            .pipe(first())
            .subscribe((time) => {
                this.isSaveBtnDisabled = !data.ids?.length;
                this.downloadType = data.type;
                this.title = data.title;
                this.ids = data.ids;
                this.devices = data.devices;
                this.mos = data.mos;
                this.currentTabInterval = data.currentTabInterval;

                this.errorDownloadMsg = '';
                this.downloadPopupObj = {
                    timeBegin: time.begin,
                    timeEnd: time.end,
                    downloadType: data.type,
                    downloadTitle: data.title,
                };

                this.objectForReportMo = {
                    timeBegin: time.begin,
                    timeEnd: time.end,
                    moIds: this.ids,
                    interval: 2,
                    type: data.type,
                    unitsType: 1,
                    insertPdk: false,
                };

                this.popupProvider.setTemplate(this.popupDownload);
            });
    };

    downloadReportMo = () => {
        this.isActiveLoader = true;
        const filename =
            this.downloadPopupObj.downloadTitle + moment().format('_YYYYMMDD_HHmm') + '.xlsx';
        this.store.dispatch(downloadDataPosts({ params: this.objectForReportMo, filename }));
    };
}
