import { isFalseNumber } from '@libs/common';

export const findNearestTime = (current: number, begin: number, end: number) => {
    if (isFalseNumber(current)) {
        return end;
    }

    if (current < begin) {
        return begin;
    }

    if (current > end) {
        return end;
    }

    return current;
};
