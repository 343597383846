<ca-menu>
    <div
        class="client-icon"
        *ngIf="assets?.logo"
        [style.backgroundImage]="assets?.logo"
        [style.backgroundSize]="assets?.styleBackgroundSize"
    ></div>
    <shared-ui-user-profile *ngIf="userData"
        [userData]="userData"
        (exit)="exit()"
    ></shared-ui-user-profile>
    <shared-ui-group-change-selector
         *ngIf="groupList?.length > 1"
         [withProfile]="userData !== null"
         [groupList]="groupList"
         [currentGroupId]="currentGroupId"
         (selectGroup)="selectGroup($event)"
    ></shared-ui-group-change-selector>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.networks)"
        name="postsAndDevices"
        data-cy="posts-and-devices-btn"
        [ngClass]="{ 'active': activeModule === MAIN_PAGE.networks }"
        (click)="select(MAIN_PAGE.networks)"
    ></ca-menu-button>

    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.analysis)"
        name="analysis"
        data-cy="analysis-btn"
        [ngClass]="{'active': activeModule === MAIN_PAGE.analysis }"
        (click)="select(MAIN_PAGE.analysis)"
    ></ca-menu-button>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.network)"
        name="network"
        data-cy="analysis-btn"
        [ngClass]="{'active': activeModule === MAIN_PAGE.network }"
        (click)="select(MAIN_PAGE.network)"
    ></ca-menu-button>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.analysisEvents)"
        name="analysisEvents"
        data-cy="analysis-btn"
        [ngClass]="{'active': activeModule === MAIN_PAGE.analysisEvents }"
        (click)="select(MAIN_PAGE.analysisEvents)"
    ></ca-menu-button>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.analytics)"
        name="cityCard"
        data-cy="analytic-btn"
        [ngClass]="{ 'active': activeModule === MAIN_PAGE.analytics }"
        (click)="select(MAIN_PAGE.analytics)"
    ></ca-menu-button>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.forecast)"
        name="forecast"
        data-cy="forecast-btn"
        [ngClass]="{ 'active': activeModule === MAIN_PAGE.forecast }"
        (click)="select(MAIN_PAGE.forecast)"
    ></ca-menu-button>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.plumes)"
        name="plumes"
        [ngClass]="{ 'active': activeModule === MAIN_PAGE.plumes }"
        (click)="select(MAIN_PAGE.plumes)"
    ></ca-menu-button>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.impact)"
        name="impact"
        [ngClass]="{ 'active': activeModule === MAIN_PAGE.impact }"
        (click)="select(MAIN_PAGE.impact)"
    ></ca-menu-button>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.modelingWorks)"
        name="modelingWorks"
        [ngClass]="{ 'active': activeModule === MAIN_PAGE.modelingWorks }"
        (click)="select(MAIN_PAGE.modelingWorks)"
    ></ca-menu-button>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.events)"
        name="events"
        [ngClass]="{ 'active': activeModule === MAIN_PAGE.events }"
        (click)="select(MAIN_PAGE.events)"
    ></ca-menu-button>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.indoor)"
        name="indoor"
        data-cy="indoor-btn"
        [ngClass]="{ 'active': activeModule === MAIN_PAGE.indoor }"
        (click)="select(MAIN_PAGE.indoor)"
    ></ca-menu-button>

    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.notifications)"
        name="notifications"
        data-cy="notifications-btn"
        [ngClass]="{ 'active': activeModule === MAIN_PAGE.notifications }"
        (click)="select(MAIN_PAGE.notifications)"
    ></ca-menu-button>

    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.reports)"
        name="reports"
         [ngClass]="{ 'active': activeModule === MAIN_PAGE.reports }"
        (click)="select(MAIN_PAGE.reports)"
    ></ca-menu-button>
    <ca-menu-button
        *ngIf="isEnabled(MAIN_PAGE.settings)"
        name="configPanel"
        data-cy="settings-btn"
        [ngClass]="{ 'active': activeModule === MAIN_PAGE.settings }"
        (click)="select(MAIN_PAGE.settings)"
    ></ca-menu-button>
    <cs-feedback-button
        class="sidebar__feedback-button"
        (click)="showFeedbackForm()"
    ></cs-feedback-button>
    <div class="cityair-logo" *ngIf="showLogo"></div>
</ca-menu>

