export const COLORS_CORRELATION = [
    '#02367B',
    '#016CA5',
    '#0396C7',
    '#04BBDF',
    '#55E3EA',
    '#B8BFCC',
    '#85EECE',
    '#5CCDA7',
    '#009D71',
    '#026842',
    '#095338',
];

export const LABEL_CORRELATION = [1.0, 0.8, 0.6, 0.4, 0.2, 0, -0.2, -0.4, -0.6, -0.8, -1.0];
export const CORRELATION_VALUE = [-0.9, -0.8, -0.6, -0.4, -0.1, 0.1, 0.4, 0.6, 0.8, 0.9];
export const LINE_CORRELATION_WIDTH = [3, 3, 3, 3, 3, 4, 4, 5, 5, 5, 6];
export function getCorrelationColor(value) {
    const zone = CORRELATION_VALUE;
    if (value >= zone[zone.length - 1]) {
        return COLORS_CORRELATION[zone.length];
    }
    const index = zone.findIndex((v) => value < v);
    return COLORS_CORRELATION[index];
}
export function getCorrelationLineWidth(value) {
    const zone = CORRELATION_VALUE;
    if (value >= zone[zone.length - 1]) {
        return LINE_CORRELATION_WIDTH[zone.length];
    }
    const index = zone.findIndex((v) => value < v);
    return LINE_CORRELATION_WIDTH[index];
}
export function getColorsForCorrelationTableLegend() {
    const smallBlockColor = [COLORS_CORRELATION[0], COLORS_CORRELATION[10]];
    const result: string[] = [];
    const colors = [...COLORS_CORRELATION].reverse();
    colors?.forEach((color) => {
        result.push(color);
        if (smallBlockColor.indexOf(color) === -1) {
            result.push(color);
        }
    });
    return result;
}
