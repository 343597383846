import { Component } from '@angular/core';
import { IndoorMainComponent } from '@cityair/modules/indoor/components/indoor-main/indoor-main.component';

@Component({
    selector: 'indoor-main-mobile',
    templateUrl: './indoor-main-mobile.component.html',
    styleUrls: ['./indoor-main-mobile.component.less'],
})
export class IndoorMainMobileComponent extends IndoorMainComponent {
    isMobile = true;
}
