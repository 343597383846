import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, take } from 'rxjs';
import { ANIMATION_OPACITY, TEXTS } from '@libs/common';
import { LayerButtonIconType } from '@libs/common';
import { changeCurrentMmt, toggleLayerOnMap, toggleDisplayPostOnMap } from '../../store/actions';
import { selectAllPosts } from '@libs/shared-store';
import {
    currentPlumesMmt,
    selectActiveRun,
    selectLoadingAllData,
    selectPlumesSpeciesList,
    showLayerOnMap,
    selectIsDisplayPinsOnMap,
} from '../../store/selectors';
import { takeUntil } from 'rxjs/operators';
import { RunPlume } from '../../services/run/models';
import { AuthService, selectSidebarIsOpen } from '@libs/shared-store';

@Component({
    selector: 'plumes-map-elements',
    templateUrl: 'plumes-map-elements.component.html',
    styleUrls: ['plumes-map-elements.component.less'],
    animations: ANIMATION_OPACITY,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlumesMapElementsComponent implements OnInit, OnDestroy {
    textNames = TEXTS.NAMES;
    textOnMap = TEXTS.MAP_CONTROL.on;
    textsSwitchButton = TEXTS.PLUMES.switchPostButton;
    textTitleMapControl = TEXTS.PLUMES.forecastOn + ':';
    LayerButtonIconType = LayerButtonIconType;
    currentMeasure;
    isLoading;
    run: RunPlume;
    showPlumesLayer$: Observable<boolean>;
    selectLoadingAllData = selectLoadingAllData;
    selectSidebarIsOpen = selectSidebarIsOpen;
    selectAllPosts = selectAllPosts;
    isShowPins: boolean;
    public speciesList = [];
    public ngDestroyed$ = new Subject<void>();

    constructor(
        public store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private authService: AuthService
    ) {
        store
            .select(selectActiveRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.run = data;
                this._changeDetectorRef.markForCheck();
            });
        store
            .select(selectIsDisplayPinsOnMap)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.isShowPins = data;
                this._changeDetectorRef.markForCheck();
            });
        store
            .select(selectPlumesSpeciesList)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                if (data?.length) {
                    this.speciesList = data;
                }
                this._changeDetectorRef.markForCheck();
            });
        store
            .select(currentPlumesMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.currentMeasure = data;
                this._changeDetectorRef.markForCheck();
            });
        store
            .select(selectLoadingAllData)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.isLoading = data;
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnInit() {
        this.showPlumesLayer$ = this.store.select(showLayerOnMap);
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    togglePostPin() {
        this.store.dispatch(toggleDisplayPostOnMap({ payload: !this.isShowPins }));
    }

    togglePlumesLayer() {
        this.showPlumesLayer$.pipe(take(1)).subscribe((isActive) => {
            this.store.dispatch(toggleLayerOnMap({ payload: !isActive }));
        });
    }

    changeMmt(mmt) {
        if (this.currentMeasure !== mmt) {
            this.store.dispatch(changeCurrentMmt({ payload: mmt }));
        }
    }

    logOut() {
        this.authService.logOut();
    }
}
