import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { LoginUpdateService } from '../login-update/login-update.service';

@Injectable({
    providedIn: 'root',
})
export class NewPasswordGuard implements CanActivate {
    constructor(private router: Router, private loginUpdateService: LoginUpdateService) {}

    canActivate() {
        const { email, verificationCode } = this.loginUpdateService.getParams();

        if (!email || !verificationCode) {
            return this.router.navigate([]);
        }

        return true;
    }
}
