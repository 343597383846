import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export type ButtonSize = 'large' | 'medium' | 'small';

@Component({
    selector: 'cross-button',
    templateUrl: 'cross-button.component.html',
    styleUrls: ['cross-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrossButtonComponent implements OnChanges {
    @Input() size: ButtonSize = 'medium';
    @Input() color = '#6C7484';
    @Input() bgGradient = false;
    @Input() needHover = true;
    sizeButton: number;
    viewBox: string;
    path1: string;
    path2: string;

    constructor() {
        this.sizeButton = 15;
        this.viewBox = `0 0 16 16`;
        this.path1 = 'M1.33903 1.34402L14.6609 14.6659';
        this.path2 = 'M1.33907 14.6659L14.6609 1.34401';
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.size && changes.size?.currentValue) {
            switch (this.size) {
                case 'large':
                    this.sizeButton = 22;
                    this.viewBox = `0 0 16 16`;
                    this.path1 = 'M1.33909 1.33907L14.6609 14.6609';
                    this.path2 = 'M1.33907 14.6609L14.6609 1.339';
                    break;
                case 'small':
                    this.sizeButton = 8;
                    this.viewBox = `0 0 8 8`;
                    this.path1 = 'M1.03961 1.03962L6.96043 6.96044';
                    this.path2 = 'M1.03962 6.96045L6.96044 1.03963';
                    break;
                case 'medium':
                default:
                    this.sizeButton = 15;
                    this.viewBox = `0 0 16 16`;
                    this.path1 = 'M1.33903 1.34402L14.6609 14.6659';
                    this.path2 = 'M1.33907 14.6659L14.6609 1.34401';
                    break;
            }
        }
    }
}
