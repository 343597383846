import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonDownloadDataComponent } from './button-download-data.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ButtonDownloadDataComponent],
    exports: [ButtonDownloadDataComponent],
})
export class ButtonDownloadDataModule {}
