import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimeRunnerComponent } from './time-runner.component';

@NgModule({
    imports: [CommonModule],
    exports: [TimeRunnerComponent],
    declarations: [TimeRunnerComponent],
})
export class TimeRunnerModule {}
