import { Pipe, PipeTransform } from '@angular/core';
import { EventImpact } from '@cityair/modules/impact/service/api-model-impact';

@Pipe({
    name: 'orderEvents',
})
export class OrderEventsPipe implements PipeTransform {
    transform(items: EventImpact[], propName: string, direction = 1) {
        const arr = [...items];
        return arr.sort((a, b) => {
            if (propName === 'max_value') {
                const arrA = a?.events?.map((v) => v.max_value);
                const valueA = Math.max(...arrA);
                const arrB = b?.events?.map((v) => v.max_value);
                const valueB = Math.max(...arrB);

                if (valueA < valueB) {
                    return 1 * direction;
                }
                if (valueA > valueB) {
                    return -1 * direction;
                }

                return 0;
            } else {
                a = a[propName];
                b = b[propName];
                if (a < b) {
                    return 1 * direction;
                }
                if (a > b) {
                    return -1 * direction;
                }

                return 0;
            }
        });
    }
}
