import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    ErrorModel,
    PATH,
} from '@cityair/modules/posts-and-devices/components/post-create/post-create.component';
import { PostsAndDevicesFacade } from '@cityair/modules/posts-and-devices/posts-and-devices.facade';
import {
    ANIMATION_OPACITY,
    CheckboxItem,
    Locality,
    PostCreateRequest,
    Post,
    FIELD_COMMON_FORM_ERROR,
    TEXTS,
} from '@libs/common';
import { ActivatedRoute } from '@angular/router';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';

import { Store } from '@ngrx/store';
import { selectCurrentMo } from '@cityair/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';
import { OffPanelPopupService } from '@libs/shared-store';
import { Subject, takeUntil } from 'rxjs';
import {
    addPostError,
    closePostForm,
    isLoadingForm,
    selectIsClosePostForm,
    selectPostFormError,
} from '@cityair/modules/core/store/posts/posts.feature';
import {
    selectAllCities,
    selectGroupId,
    SharedCoreActions,
    SharedPostActions,
} from '@libs/shared-store';
@Component({
    selector: 'post-edit',
    templateUrl: './post-edit.component.html',
    styleUrls: ['./post-edit.component.less'],
    animations: ANIMATION_OPACITY,
})
export class PostEditComponent implements OnInit, OnDestroy {
    TEXTS = TEXTS;
    public translateText = TEXTS.FORECAST;
    PostEdit: UntypedFormGroup;
    errorObj: ErrorModel = {};
    pages = NETWORK_PAGES;
    createMo: Post;
    navigationLink: PATH[] = [];
    isShowDropdownForCity = false;
    isShowDeletePopup = false;
    selectedStation = '';
    nameMO = '';
    getCities: CheckboxItem[] = [];
    selectedCity: CheckboxItem = {
        id: '',
        label: '',
    };
    public ngDestroyed$ = new Subject<void>();
    private groupId: string;
    isLoadingForm = isLoadingForm;

    @ViewChild('postEdit', { static: true }) postEdit: TemplateRef<HTMLDivElement>;

    constructor(
        private fb: UntypedFormBuilder,
        public popupProvider: OffPanelPopupService,
        public store: Store<any>,
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        private activatedRoute: ActivatedRoute
    ) {
        this.store
            .select(selectGroupId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((groupId) => (this.groupId = groupId));
    }

    ngOnInit() {
        const idRouter = this.activatedRoute.snapshot.params.id;
        const isCheckExists = this.postsAndDevicesFacade.checkExistsPage(idRouter);
        if (isCheckExists) {
            this.getDataForPostEdit();
        } else {
            this.postsAndDevicesFacade.openPage(this.pages.networks + '/' + this.pages.postsList);
        }
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
        this.ngDestroyed$.next();
        this.store.dispatch(closePostForm({ payload: false }));
    }

    getDataForPostEdit() {
        this.store
            .select(selectCurrentMo)
            .pipe(
                take(1),
                filter((v) => !!v)
            )
            .subscribe((scm) => {
                this.createMo = scm;
                this.nameMO = scm.name;
                this.PostEdit = this.fb.group({
                    name: [scm.name, [Validators.required, Validators.maxLength(100)]],
                    longitude: [
                        scm.geometry.coordinates[0],
                        [Validators.required, Validators.min(-180), Validators.max(180)],
                    ],
                    latitude: [
                        scm.geometry.coordinates[1],
                        [Validators.required, Validators.min(-90), Validators.max(90)],
                    ],

                    group_id: [this.groupId],
                    locality_id: [scm.ancestor?.id ?? null, [Validators.required]],
                });
                this.createBreadCrumbs();
            });

        this.store
            .select(selectAllCities)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((sgc) => !!sgc.length)
            )
            .subscribe((sgc) => {
                this.getCities = [];
                sgc.forEach((s) => {
                    this.getCities.push({
                        id: s.id,
                        label: s.name,
                        selected: s.id === this.createMo.ancestor.id,
                    });
                    if (s.id == this.createMo.ancestor.id) {
                        this.getSelectedLocation(s);
                    }
                });
                this.getCities.sort(this.byField('label'));
            });
        this.store
            .select(selectIsClosePostForm)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => v)
            )
            .subscribe((isClose) => {
                this.openPage(this.navigationLink[this.navigationLink.length - 2].path);
            });

        this.store
            .select(selectPostFormError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((formError) => {
                if (formError) {
                    const data = formError.error?.errors;
                    for (const key in data) {
                        if (data.hasOwnProperty(key) && this.PostEdit?.controls[key]) {
                            this.PostEdit.controls[key].setErrors({
                                incorrect: true,
                                message: data[key].join(', '),
                            });
                        } else if (data.hasOwnProperty(FIELD_COMMON_FORM_ERROR)) {
                            this.store.dispatch(
                                SharedCoreActions.setInfoMessage({
                                    payload: {
                                        id: new Date().valueOf(),
                                        message: `${data[FIELD_COMMON_FORM_ERROR]}. ${this.translateText.tryAgain}.`,
                                        positionX: 'left',
                                        positionY: 'bottom',
                                        iconClass: 'error',
                                        duration: 10000,
                                        showCloseIcon: true,
                                        size: 'lg',
                                    },
                                })
                            );

                            setTimeout(() => {
                                this.store.dispatch(addPostError({ payload: null }));
                            }, 2500);
                        }
                    }
                }
            });
    }

    public getError(field: string) {
        if (
            this.PostEdit &&
            this.PostEdit.controls[field] &&
            this.PostEdit.controls[field].invalid &&
            (this.PostEdit.controls[field].dirty || this.PostEdit.controls[field].touched)
        ) {
            if (this.PostEdit.controls[field].errors.required) {
                return this.TEXTS.POSTS_AND_DEVICES.formError.required;
            }
            if (this.PostEdit.controls[field].errors.max) {
                return (
                    this.translateText.maxError + ' ' + this.PostEdit.controls[field].errors.max.max
                );
            }
            if (this.PostEdit.controls[field].errors.min) {
                return (
                    this.translateText.minError + ' ' + this.PostEdit.controls[field].errors.min.min
                );
            }
            if (this.PostEdit.controls[field].errors.maxlength) {
                return this.TEXTS.POSTS_AND_DEVICES.formError.maxLength(
                    this.PostEdit.controls[field].errors.maxlength.requiredLength
                );
            }

            if (this.PostEdit.controls[field].errors.incorrect) {
                return this.PostEdit.controls[field].errors.message;
            }

            return null;
        }

        return null;
    }

    byField(field) {
        return (a, b) => (a[field] > b[field] ? 1 : -1);
    }

    getSelectedLocation(s: Locality) {
        this.selectedCity = {
            label: s.name,
            id: s.id,
        };
    }

    createBreadCrumbs() {
        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.postAndDevices,
            path: this.pages.networks + '/' + this.activatedRoute.snapshot.url[0],
        });
        if (this.activatedRoute.snapshot.url[1].path == this.pages.details) {
            this.navigationLink.push({
                name: this.createMo.name,
                path:
                    this.pages.networks +
                    '/' +
                    this.activatedRoute.snapshot.url[0] +
                    '/' +
                    this.activatedRoute.snapshot.url[1] +
                    '/' +
                    this.activatedRoute.snapshot.params.id,
            });
        }
        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.editMo,
            path: null,
        });
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    public getValueForm(field) {
        return this.PostEdit?.get(field)?.value || '';
    }

    getSelectedCity(city: CheckboxItem[]) {
        this.selectedCity = city.find((c) => c.selected == true);
        this.PostEdit.controls.locality_id.setValue(this.selectedCity.id);
        this.PostEdit.get('locality_id').markAsDirty();
    }

    openPage(pageName: string) {
        this.postsAndDevicesFacade.openPage(pageName);
    }

    showDeletePopup() {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.postEdit, () => (this.isShowDeletePopup = true));
        this.isShowDeletePopup = true;
    }

    deleteAccept() {
        this.isShowDeletePopup = false;
        this.store.dispatch(SharedPostActions.deletePost());
        this.postsAndDevicesFacade.openPage(this.navigationLink[0].path);
    }
    deleteCancel() {
        this.isShowDeletePopup = false;
    }

    onSubmit() {
        const formData = this.PostEdit.getRawValue();
        const params: PostCreateRequest = {
            group_id: formData.group_id,
            name: formData.name,
            coordinates: {
                latitude: formData.latitude,
                longitude: formData.longitude,
            },
            locality_id: formData.locality_id,
        };
        this.store.dispatch(
            SharedPostActions.updatePost({ payload: { params: params, id: this.createMo.id } })
        );
    }
}
