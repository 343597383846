import { Component } from '@angular/core';

import { LoginNavigationService } from '../../services/login-navigation/login-navigation.service';

@Component({
    selector: 'ca-login-info-popup',
    templateUrl: 'login-info-popup.component.html',
    styleUrls: ['login-info-popup.component.less'],
})
export class LoginInfoPopupComponent {
    constructor(private navigation: LoginNavigationService) {}

    closePopup() {
        this.navigation.closePopup();
    }
}
