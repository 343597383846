import {
    createActionGroup,
    createFeature,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';
import { Group, IBasicResponse, NameModules, MapStyleType, DEFAULT_MAP_STYLES } from '@libs/common';

const SHARED_GROUP_FEATURE_KEY = 'sharedGroup';
export const SharedGroupActions = createActionGroup({
    source: 'SharedGroup',
    events: {
        'Set Current Group': props<{ payload: Group }>(),
        'Set Group List': props<{ payload: IBasicResponse }>(),
        'Group Info Load': props<{ groupId: string }>(),
    },
});
interface SharedGroupState {
    groups: Group[];
    currentGroup: Group;
}

const initialState: SharedGroupState = {
    groups: [],
    currentGroup: null,
};

export const sharedGroupFeature = createFeature({
    name: SHARED_GROUP_FEATURE_KEY,
    reducer: createReducer(
        initialState,

        on(SharedGroupActions.setGroupList, (state, { payload }) => {
            const dataResponse = payload?.data as Group[];
            const groups = [...dataResponse]?.sort((a, b) =>
                a.name > b.name ? 1 : a.name < b.name ? -1 : 0
            );
            return {
                ...state,
                groups: groups ?? [],
            };
        }),

        on(SharedGroupActions.setCurrentGroup, (state, { payload }) => ({
            ...state,
            currentGroup: payload,
        }))
    ),
    extraSelectors: ({ selectCurrentGroup }) => ({
        selectGroupId: createSelector(selectCurrentGroup, (group) => {
            return group?.id ?? null;
        }),
        selectExtConfig: createSelector(selectCurrentGroup, (group) => {
            return group?.ext_config ?? null;
        }),
        allowModule: (module: NameModules) =>
            createSelector(selectCurrentGroup, (group) => {
                const currentModule = group?.modules?.find((m) => m.name === module);
                return currentModule ? true : false;
            }),
        selectCurrentRegionCoefs: createSelector(selectCurrentGroup, (group) => {
            return group?.region_coefs ?? null;
        }),
        selectGroupCreateDate: createSelector(selectCurrentGroup, (group) => {
            return group?.create_date ?? null;
        }),
        selectMapStyleTypes: createSelector(selectCurrentGroup, (group) => {
            if (group?.ext_config?.mapStyleSelector) {
                return group.ext_config.mapStyleSelector as MapStyleType[];
            }
            return DEFAULT_MAP_STYLES;
        }),
    }),
});

export const {
    selectGroups,
    selectCurrentGroup,
    selectGroupId,
    selectExtConfig,
    allowModule,
    selectCurrentRegionCoefs,
    selectGroupCreateDate,
    selectMapStyleTypes,
} = sharedGroupFeature;
