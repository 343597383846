<plumes-map></plumes-map>
<div class="plumes-page" [ngClass]="{ 'open-sidebar': store.select(selectSidebarIsOpen) | async }">
    <div *ngIf="!editMode" class="page-content__wrapper">
         <div class="ca-header-wrapper with-tabs">
            <div class="header">
                <header-page
                    [textObject]="{
                        titlePage: textsAdminPlumes
                    }"
                ></header-page>
            </div>
             <ca-page-tabs
                [tabs]="tabs"
                [selectedTab]="selectedTab"
                (showTab)="showSelectedTab($event)"
            ></ca-page-tabs>
        </div>
        <div class="plumes-page__container" [ngSwitch]="currentPage">
            <plumes-calculation-results
                *ngSwitchCase="plumesPages.CALCULATION_RESULTS"
                [runs]="runsWithFilter"
                [loadError]="loadError"
                [plumesVisible]="showPlumesLayer$ | async"
            ></plumes-calculation-results>
            <plumes-sources-list
                *ngSwitchCase="plumesPages.SOURCES"
                [loadError]="loadError"
                [isOperator]="isOperator"
                (cbOpenCalculationResultsEdit)="openCalculationResultsEdit($event)"
                (changeCurrentPage)="changesCurrentPage($event)"
            ></plumes-sources-list>
            <plumes-control-points
                *ngSwitchCase="plumesPages.CONTROL_POINTS"
                [currentMeasure]="currentMeasure"
                [loadError]="loadError"
                [runs]="runs"
                [configs]="configs"
                [(controlPointForEdit)]="controlPointForEdit"
                (cbOpenAddCheckpoint)="openAddCheckpoint()"
                (cbOpenEditCheckpoint)="openEditCheckpoint()"
                [openChartControlPoint]="openChartControlPoint"
                [centringOnMarker]="mapboxActions.centringOnMarker"
            ></plumes-control-points>
        </div>
    </div>

    <div *ngIf="editMode">
        <div [ngSwitch]="currentEditPage">
            <div *ngSwitchCase="plumesEditPages.ADD_CHECKPIONT">
                <edit-control-point
                    (cbCloseEditMode)="closeEditMode()"
                    [controlPointForEdit]="controlPointForEdit"
                    [title]="titleEditPage"
                ></edit-control-point>
            </div>
            <div *ngSwitchCase="plumesEditPages.EDIT_CALCULATION">
                <plumes-calculation-results-edit
                    [runConfig]="currentEditRunConfig"
                    (cbCloseEditMode)="closeEditMode()"
                ></plumes-calculation-results-edit>
            </div>
        </div>
    </div>
</div>
<ca-sidebar-toggle-button
    class="plumes-toggle-button"
    [ngClass]="{ 'open-sidebar': store.select(selectSidebarIsOpen) | async }"
    [isActive]="store.select(selectSidebarIsOpen) | async"
    (toggle)="toggleSideBar()"
></ca-sidebar-toggle-button>