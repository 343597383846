export enum IMPACT_PAGES {
    runResults = 'runs-results',
    configs = 'configs',
    controlPoints = 'control-points',
    regions = 'regions',
    points = 'points',
    posts = 'posts',
    add = 'add',
    edit = 'edit',
    cities = 'cities',
    sources = 'sources',
    events = 'events',
    list = 'list',
}
export const IMPACTS_INIT_PAGE = IMPACT_PAGES.cities;

export enum ImpactMapObjectType {
    posts = 'posts',
    points = 'points',
    sources = 'sources',
    regions = 'regions',
}
export type ImpactSourceType = 'point' | 'square';
export interface ImpactEmissionValue {
    [key: string]: number;
}
