<div class="impact_control_points">
    <div class="switch-item-wrapper">
        <switch-item
            class="switch-item"
            [currentTab]="currentTab"
            [tabs]="tabs"
            (action)="changeTab($event)"
        >
        </switch-item>
        <button-add *ngIf="currentTab?.id === IMPACT_PAGES.points"
            [text]="texts.buttonAdd"
            (action)="openAddCheckpoint()"
        ></button-add>
    </div>
    <router-outlet></router-outlet>
</div>
