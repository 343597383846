import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { RunPlume } from './models';
import { RUN_ENTITY_STORE_KEY } from '../../constants';

@Injectable({ providedIn: 'root' })
export class RunService extends EntityCollectionServiceBase<RunPlume> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super(RUN_ENTITY_STORE_KEY, serviceElementsFactory);
    }
}
