export const MONTH_FORMAT = (date) => date.format('MMM').slice(0, 3);
export const YEAR_FORMAT = 'YYYY';
export const DAY_FORMAT = 'D';
export const TIME_FORMAT = 'HH:mm';
export const DURATION_TIME_NAMES = {
    ru: {
        years: 'г',
        months: 'мес',
        days: 'дн',
        hours: 'ч',
        minutes: 'мин',
    },
    en: {
        years: 'yr',
        months: 'mo',
        days: 'd',
        hours: 'h',
        minutes: 'min',
    },
};
