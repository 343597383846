<div class="selectbox" (click)="$event.stopPropagation()">
    <search-input *ngIf="showSearch"
        [textPlaceholder]="searchPlaceholderText"
        [(text)]="searchText"
        [debounceTime]="400"
    ></search-input>
    <div class="selectbox__list scroll">
        <div
            class="selectbox__list-item selectbox__list-item--all"
            *ngIf="selectAllText"
            (click)="allSelect()"
        >
            <cs-checkbox [checked]="isAllSelected()"></cs-checkbox>
            <span class="selectbox__list-item__label">{{ selectAllText }}</span>
        </div>
        <div
            class="selectbox__list-item"
            *ngFor="let item of items | multipleSearchfilter : ['label'] : searchText"
            (click)="select(item)"
        >
            <cs-checkbox [checked]="item.selected"></cs-checkbox>
            <span class="selectbox__list-item__label">{{ item.label }}</span>
        </div>
    </div>
</div>
