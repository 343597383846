import { NgModule } from '@angular/core';
import { EntityDataService } from '@ngrx/data';
import { StationDataService } from './services/station-data.service';
import { ControlPointDataService } from './services/control-point-data.service';
import { ForecastsDataService } from './services/forecasts-data.service';

@NgModule({
    providers: [StationDataService],
})
export class EntityStoreForecastModule {
    constructor(
        entityDataService: EntityDataService,
        stationDataService: StationDataService,
        controlPointDataService: ControlPointDataService,
        forecastsDataService: ForecastsDataService
    ) {
        entityDataService.registerService('Station', stationDataService);
        entityDataService.registerService('ControlPoint', controlPointDataService);
        entityDataService.registerService('Forecasts', forecastsDataService);
    }
}
