import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { SharedCoreActions } from '@libs/shared-store';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (!navigator.onLine) {
                    this.store.dispatch(
                        SharedCoreActions.setInfoMessage({
                            payload: {
                                id: new Date().valueOf(),
                                messageKey: 'internet_offline',
                                positionX: 'center',
                                positionY: 'top',
                                iconClass: 'warning',
                                size: 'md',
                            },
                        })
                    );
                }

                return throwError(err);
            })
        );
    }
}
