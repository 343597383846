import * as moment from 'moment-timezone';
import {
    BehaviorSubject,
    Observable,
    Subscription,
    of,
    from,
    merge,
    EMPTY,
    lastValueFrom,
} from 'rxjs';
import {
    withLatestFrom,
    filter,
    map,
    distinctUntilChanged,
    exhaustMap,
    concatMap,
    switchMap,
} from 'rxjs/operators';
import { DomainConfig } from '@cityair/modules/plumes/services/plumes-tiles-player/domain-config';
import {
    DomainConfigType,
    DataType,
    TileType,
    IAuthorizeHelper,
} from '@cityair/modules/plumes/services/plumes-tiles-player/domain-config.type';
import { HttpStatusCode } from '@angular/common/http';

type DateRange = {
    begin: number;
    end: number;
};
export type TilesUpdateParams = {
    ts: number;
    height: number;
    substance: string;
};
type PlayerOptions = Partial<DomainConfig> & { domain: DomainConfigType };

// transparent 1px
const EMPTY_IMAGE =
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export class PlumesTilesPlayer {
    private _config: DomainConfig;

    private _imageUrl$ = new BehaviorSubject<string>('');

    private _imageUrlPreload$ = new BehaviorSubject<string>('');

    private imageUrlPreload$ = this._imageUrlPreload$.asObservable().pipe(filter((url) => !!url));

    private _subscriptions: Subscription[] = [];

    private dateRange: DateRange;

    private currentHeight: number;

    private substance: string;

    imageUrl$ = this._imageUrl$.asObservable();

    onImageUpdate$: Observable<TilesUpdateParams>;

    onRangeUpdate$: Observable<DateRange>;

    id: string;

    authorizeHelper: IAuthorizeHelper;

    private withTokenEnsured = (r: Response) => {
        if (!r) return EMPTY;
        if (r.ok) {
            // Success
            return of(r.url);
        } else if (r.status === HttpStatusCode.Unauthorized) {
            // Unauthorized
            return from(this.authorizeHelper.refreshToken()).pipe(map(() => r.url));
        } else if (r.status === HttpStatusCode.NotFound) {
            // Not found
            return of(EMPTY_IMAGE);
        } else {
            // Ignore any other error
            return EMPTY;
        }
    };

    constructor(
        id: string,
        config: PlayerOptions,
        onImageUpdate$: Observable<TilesUpdateParams>,
        onRangeUpdate$: Observable<DateRange>,
        isEnabled$: Observable<boolean> = of(true),
        authorizeHelper?: IAuthorizeHelper
    ) {
        this.id = id;

        this.authorizeHelper = authorizeHelper;

        this.onImageUpdate$ = isEnabled$.pipe(
            filter((isEnabled) => isEnabled),
            switchMap(() => onImageUpdate$)
        );

        this.onRangeUpdate$ = onRangeUpdate$;

        this.createConfig(config);

        const onImageUpdateSub = this.onImageUpdate$
            .pipe(distinctUntilChanged())
            .subscribe((params) => {
                this.currentHeight = params.height;
                this.substance = params.substance;
                this.updateImage(params);
            });

        const onRangeUpdateSub = this.onRangeUpdate$.subscribe((range) => {
            this.dateRange = range;
        });

        const readyImagesSub = this.imageUrlPreload$
            .pipe(
                exhaustMap((url) => this.loadImage(url)),
                concatMap(this.withTokenEnsured),
                withLatestFrom(this.imageUrlPreload$)
            )
            .subscribe(([_, url]) => {
                this._imageUrl$.next(url);
            });

        this._subscriptions = [onImageUpdateSub, onRangeUpdateSub, readyImagesSub];
    }

    private updateImage(params: TilesUpdateParams) {
        const config = this._config;
        if (config) {
            const timeStep = config.timeStep;
            params.ts = Math.floor(params.ts / timeStep) * timeStep; // normalize according to the step size
            const url = this.getImageUrl(params);
            this._imageUrl$.next(url);
        }
    }

    private async loadImage(url: string) {
        return fetch(
            url,
            this.authorizeHelper
                ? {
                      headers: this.authorizeHelper.getAuthHeader(),
                      credentials: 'include',
                  }
                : {}
        ).catch(() => console.log('rejected', url));
    }

    private getImageUrl(params: TilesUpdateParams) {
        const config = this._config;
        const format = config?.dateFormat ?? 'YYYYMMDD_HHmmss';
        const dateTime = moment.utc(params.ts).format(format);
        const url = config.getImagePath(DataType.Raster, TileType.DomainTiles);
        const imageUrl = `${url}/${params.height}/${params.substance}/${dateTime}.png`;

        return imageUrl;
    }

    private createConfig(cfg: PlayerOptions) {
        this._config = new DomainConfig(cfg);
    }

    destroy() {
        this._config = null;

        this._subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });

        this._imageUrl$.next('');
        this._imageUrl$.complete();

        this.onImageUpdate$ = null;
        this.onRangeUpdate$ = null;

        this._subscriptions = [];
    }

    get config() {
        return this._config;
    }

    preloadImages(
        timeSequence: Set<number>,
        reportProgress: (percentage: number) => void = () => {}
    ) {
        const toLoad = [...timeSequence];

        const frames = toLoad.length;

        if (frames) {
            const ts0 = toLoad[0];
            const preloadParams = {
                ts: ts0,
                height: this.currentHeight,
                substance: this.substance,
            };
            const url0 = this.getImageUrl(preloadParams);

            let count = 0;

            const doneLoading = from(this.loadImage(url0)).pipe(
                concatMap(this.withTokenEnsured),
                switchMap(() =>
                    merge(
                        ...toLoad.map((ts) => {
                            const params = {
                                ts: ts,
                                height: this.currentHeight,
                                substance: this.substance,
                            };
                            const url = this.getImageUrl(params);

                            return this.loadImage(url).finally(() =>
                                reportProgress((++count / frames) * 100)
                            );
                        })
                    )
                ),
                map(() => {})
            );

            return lastValueFrom(doneLoading);
        } else {
            return Promise.resolve();
        }
    }

    clear() {
        this._imageUrl$.next(EMPTY_IMAGE);
    }
}
