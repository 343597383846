<div
    [ngClass]="{
        'value-rounded': true,
        'value-rounded_state-selected': selected,
        'value-rounded_state-disable': hasNoData
    }"
    [style]="{ backgroundColor: color }"
>
    <span class="value-rounded__grade">{{ displayValue }}</span>
    <span class="value-rounded__sign"></span>
</div>
