import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValueRoundedComponent } from './value-rounded.component';

@NgModule({
    exports: [ValueRoundedComponent],
    declarations: [ValueRoundedComponent],
    imports: [CommonModule],
})
export class ValueRoundedModule {}
