<div class="indoor-widget">
    <div class="indoor-widget__top">
        <div class="indoor-widget__menu">
            <img [attr.src]="getAsset('indoor-widget/menu.svg')" alt="" />
        </div>
        <div class="indoor-widget__header">
            <div class="indoor-widget__title">{{ name }}</div>
            <div class="indoor-widget__date">{{ lastTime }}</div>
        </div>
        <div class="indoor-widget__settings">
            <img [attr.src]="getAsset('indoor-widget/settings.svg')" alt="" />
        </div>
    </div>

    <div class="indoor-widget__center" *ngIf="!loading">
        <div
            class="indoor-widget__center-content"
            *ngIf="!showErrorMsg && indoorAqi !== null; else offlineTemplate"
        >
            <div class="indoor-widget__center-measurement">IAQ</div>
            <div class="indoor-widget__center-grade">{{ indoorAqi }}</div>
        </div>
        <ng-template #offlineTemplate>
            <div class="indoor-widget__center-content">
                <div class="indoor-widget__center-measurement">{{ noDataText }}</div>
            </div>
        </ng-template>
        <div class="indoor-widget__waves">
            <div class="indoor-waves__layer-1">
                <div class="layer-1__wrapper">
                    <div class="layer-1__element-1 layer__element">
                        <img
                            *ngIf="indoorAqi !== null; else layer1el1NoData"
                            [attr.src]="getAsset('indoor-widget/' + className + '/element-1.svg')"
                            alt=""
                        />
                        <ng-template #layer1el1NoData>
                            <img
                                [attr.src]="getAsset('indoor-widget/no-data/element-1.svg')"
                                alt=""
                            />
                        </ng-template>
                    </div>
                    <div class="layer-1__element-2 layer__element">
                        <img
                            *ngIf="indoorAqi !== null; else layer1el2NoData"
                            [attr.src]="getAsset('indoor-widget/' + className + '/element-2.svg')"
                            alt=""
                        />
                        <ng-template #layer1el2NoData>
                            <img
                                [attr.src]="getAsset('indoor-widget/no-data/element-2.svg')"
                                alt=""
                            />
                        </ng-template>
                    </div>
                    <div class="layer-1__element-3 layer__element">
                        <img
                            *ngIf="indoorAqi !== null; else layer1el3NoData"
                            [attr.src]="getAsset('indoor-widget/' + className + '/element-3.svg')"
                            alt=""
                        />
                        <ng-template #layer1el3NoData>
                            <img
                                [attr.src]="getAsset('indoor-widget/no-data/element-3.svg')"
                                alt=""
                            />
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="indoor-waves__layer-2">
                <div class="layer-2__wrapper">
                    <div class="layer-2__element-1 layer__element">
                        <img
                            *ngIf="indoorAqi !== null; else layer2el1NoData"
                            [attr.src]="getAsset('indoor-widget/' + className + '/element-1.svg')"
                            alt=""
                        />
                        <ng-template #layer2el1NoData>
                            <img
                                [attr.src]="getAsset('indoor-widget/no-data/element-1.svg')"
                                alt=""
                            />
                        </ng-template>
                    </div>
                    <div class="layer-2__element-2 layer__element">
                        <img
                            *ngIf="indoorAqi !== null; else layer2el2NoData"
                            [attr.src]="getAsset('indoor-widget/' + className + '/element-2.svg')"
                            alt=""
                        />
                        <ng-template #layer2el2NoData>
                            <img
                                [attr.src]="getAsset('indoor-widget/no-data/element-2.svg')"
                                alt=""
                            />
                        </ng-template>
                    </div>
                    <div class="layer-2__element-3 layer__element">
                        <img
                            *ngIf="indoorAqi !== null; else layer2el3NoData"
                            [attr.src]="getAsset('indoor-widget/' + className + '/element-3.svg')"
                            alt=""
                        />
                        <ng-template #layer2el3NoData>
                            <img
                                [attr.src]="getAsset('indoor-widget/no-data/element-3.svg')"
                                alt=""
                            />
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="indoor-widget__bottom" *ngIf="!loading">
        <span *ngIf="errorSettingWidget else goodStatus">
             <span>{{ errorSettingWidget }}</span>
        </span>
        <ng-template #goodStatus>
            <span *ngIf="!showErrorMsg && indoorAqi !== null; else offlineStatusTemplate">{{
                textStatus
            }}</span>
            <ng-template #offlineStatusTemplate>
                <span>{{ statusTextOffline }}</span>
            </ng-template>
        </ng-template>
    </div>
    <div class="indoor-widget__loading" *ngIf="loading">
        <ca-spinner [color]="'#FFFFFF'"></ca-spinner>
    </div>
</div>
