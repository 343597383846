import { Injectable } from '@angular/core';
import * as clientConfigs from './client-configs.json';

export class ClientConfig {
    name: string;

    isDemo: boolean;

    disableBranding: {
        all?: boolean;
    } = {};

    login: {
        logo?: string;
        background?: string;
        clientFooter?: boolean;
    } = {};

    sidebar: {
        logo?: string;
        logoWidth?: number;
    } = {};

    mobile: {
        logo?: string;
        replaceAppNameWithDomainName?: boolean;
    } = {};

    constructor(init: Partial<ClientConfig>) {
        Object.assign(this, init);
    }
}

declare const window: any;

@Injectable({
    providedIn: 'root',
})
export class ClientConfigService {
    private config: Readonly<ClientConfig> = new ClientConfig(null);

    load(config: ClientConfig) {
        this.config = config;
    }

    loadFromGlobals() {
        this.load(new ClientConfig(window.CLIENT_CONFIG));
    }

    loadFromGlobalsLegacy() {
        const name = window.LOGIN_STYLE;

        const existingConfig = clientConfigs[name];

        this.load(new ClientConfig(existingConfig || { name }));
    }

    getConfig() {
        return this.config;
    }

    getName() {
        return this.config.name;
    }

    getBrandingOptions() {
        return {
            ...this.config.disableBranding,
        };
    }
}
