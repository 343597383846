import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NonDataScreenComponent } from './non-data-screen.component';

@NgModule({
    exports: [NonDataScreenComponent],
    declarations: [NonDataScreenComponent],
    imports: [CommonModule],
})
export class NonDataScreenModule {}
