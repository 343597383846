<div class="wrapper-dropdown">
    <div
        class="calculation_results"
        [ngClass]="{ active: isShowList }"
        (clickOutside)="clickOutside()"
        (click)="toggleList()"
    >
        <div class="calculation_results__main" [ngClass]="{ 'no-run': !currentRun }">
            <div class="calculation_results__label">{{ textPlumes.nowOnMap }}</div>
            <div *ngIf="!currentRun">{{ textPlumes.noRunSelected }}</div>
            <div class="plumes-run-summary"  *ngIf="currentRun">
                <plumes-run-summary
                    class="wrapper_run_summary"
                    [run]="currentRun"
                    [config]="configs[currentRun.config]"
                ></plumes-run-summary>
                <div class="arrow-icon" *ngIf="runs?.length" [ngClass]="{ up: isShowList }"></div>
            </div>
        </div>
        <ng-container *ngIf="isShowList">
            <div class="calculation_results__dropdown_list scroll">
                <ng-container *ngFor="let run of runs" class="calculation_results__dropdown-item">
                    <div class="annotation-wrapper">
                        <div class="annotation" *ngIf="run.annotation">
                            {{ run.annotation }}
                        </div>
                    </div>
                    <div class="item-wrapper">
                        <div class="card_wrapper" (click)="selectRun(run.item)">
                            <plumes-run-summary
                                class="wrapper_run_summary"
                                [run]="run.item"
                                [config]="configs[run.item.config]"
                            ></plumes-run-summary>
                            <div class="check-icon-wrapper">
                                <span
                                    *ngIf="run.item.id === currentRun.id"
                                    class="active-run"
                                ></span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
