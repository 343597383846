import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Post } from '@cityair/modules/indoor/services/api';
import { Store } from '@ngrx/store';
import { orderOfMeasures } from '@cityair/modules/indoor/config';
import { take } from 'rxjs/operators';
import { AqiType, ROOM, TEXTS } from '@libs/common';
import {
    indoorSelectors,
    selectIndoorMeasureScheme,
} from '@cityair/modules/indoor/store/selectors';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'point-list',
    templateUrl: './point-list.component.html',
    styleUrls: ['./point-list.component.less'],
})
export class PointListComponent {
    @Input() points: Post[];
    @Output() clickOnPoint = new EventEmitter<number>();
    @Output() showInfo = new EventEmitter<void>();

    @ViewChild('list') feed: ElementRef<HTMLInputElement>;

    IAQ = AqiType.indoor;
    ROOM = ROOM;

    orderOfMeasures = orderOfMeasures;
    TEXTS = TEXTS;
    searchQuery = '';

    isScrollTop = true;

    searchFilters = [
        (post: Post) => post.name,
        (post: Post) => post.id + '',
        (post: Post) => post.description,
    ];

    sortDirection = 1;
    sortCallBack;
    listCbs;

    indoorSelectors = indoorSelectors;
    selectMeasureScheme = selectIndoorMeasureScheme;

    constructor(public store: Store) {
        this.listCbs = {
            [ROOM]: (item: Post) => item.name,
            [AqiType.indoor]: async (item: Post) => {
                const measure = await lastValueFrom(
                    this.store.select(indoorSelectors.getMeasurement(item.id)).pipe(take(1))
                );

                return measure.aqi.indoorAqi?.valueScaled10 || null;
            },
        };

        orderOfMeasures.forEach((name) => {
            this.listCbs[name] = async (item: Post) => {
                const measure = await lastValueFrom(
                    this.store.select(indoorSelectors.getMeasurement(item.id)).pipe(take(1))
                );

                return measure[name.toLowerCase()];
            };
        });
    }

    setSortingCb = (sortCb) => {
        if (this.sortCallBack === sortCb) this.sortDirection *= -1;
        else this.sortCallBack = sortCb;
    };

    scrollTop() {
        this.feed.nativeElement.scrollTop = 0;
    }
}
