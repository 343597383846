import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupListItemComponent } from './group-list-item.component';

@NgModule({
    imports: [CommonModule],
    exports: [GroupListItemComponent],
    declarations: [GroupListItemComponent],
})
export class GroupListItemModule {}
