<div class="open_profile_btn" data-cy="mobile-profile" (click)="showMobileMenu = true"></div>

<div *showDirective="showMobileMenu" class="user_profile" [ngSwitch]="currentView">
    <ng-container *ngSwitchDefault>
        <div class="user_profile__close" (click)="showMobileMenu = false"></div>
        <div class="user_profile__cityair-logo"></div>
        <ca-language-selector-m
            class="user_profile__lang"
            (selectLang)="setLang($event)"
        ></ca-language-selector-m>

        <cityscreen-user-card
            [user]="store.select(selectIAm) | async"
            [roleId]="store.select(selectUserRoleId) | async"
            (logout)="authService.logOut()"
        ></cityscreen-user-card>

        <div class="user_profile__group_text">{{ TEXTS.PROFILE.network }}</div>

        <div class="user_profile__groups">
            <group-list-item-m
                *ngFor="let item of store.select(selectGroupList) | async"
                class="user_profile__group"
                [selected]="item.id === (currentGroupId$ | async)"
                [iconUrl]="item.group_icon_url"
                [name]="item.name"
                [postsCount]="item.posts_count"
                (click)="changeGroup(item.id)"
            ></group-list-item-m>
        </div>

        <div class="user_profile__footer">
            <cs-feedback-mobile-button
                class="user_profile__feedback-button"
                (click)="currentView = views.FeedbackForm"
            ></cs-feedback-mobile-button>
            <div class="user_profile__text_support">{{ TEXTS.PROFILE.support }}:</div>
            <div class="user_profile__text_info">
                <a href="tel:{{ TEXTS.PROFILE.phone[0] }}" [innerHTML]="TEXTS.PROFILE.phone[1]"></a>
                <a href="mailto:{{ TEXTS.PROFILE.email }}">{{ TEXTS.PROFILE.email }}</a>
            </div>
            <div class="user_profile__text_c">{{ textC + TEXTS.PROFILE.copyright }}</div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="views.FeedbackForm">
        <cs-back-button class="user_profile__back-btn" (click)="currentView = views.Default">
            {{ TEXTS.ADMIN_PANEL.back }}
        </cs-back-button>
        <cs-feedback-form
            class="user_profile__feedback-form"
            [isMobile]="true"
            [success]="feedbackForm.success$"
            [errors]="feedbackForm.errors$"
            (submitForm)="submitFeedback($event)"
            (closeForm)="currentView = views.Default"
        ></cs-feedback-form>
    </ng-container>

    <ng-container *ngSwitchCase="views.FeedbackSuccess">
        <cs-feedback-success
            class="user_profile__feedback-message"
            (closeView)="currentView = views.Default"
        ></cs-feedback-success>
    </ng-container>

    <ng-container *ngSwitchCase="views.FeedbackError">
        <cs-feedback-error
            class="user_profile__feedback-message"
            [title]="feedbackErrorMessage.title"
            [message]="feedbackErrorMessage.message"
            (closeView)="currentView = views.Default"
        ></cs-feedback-error>
    </ng-container>
</div>
