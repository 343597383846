import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackButtonComponent } from './back-button.component';

@NgModule({
    imports: [CommonModule],
    exports: [BackButtonComponent],
    declarations: [BackButtonComponent],
})
export class BackButtonModule {}
