import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    ViewChild,
    Output,
    EventEmitter,
} from '@angular/core';

import { FlagDirection } from '../models/core';

@Component({
    selector: 'ca-time-runner',
    templateUrl: './time-runner.component.html',
    styleUrls: ['./time-runner.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeRunnerComponent {
    @Input() flagDirection: FlagDirection = 'right';
    @Input() time: string[];
    @Input() chartEnabled: boolean;
    @Input() isLastTime: boolean;
    @Output() goToEnd = new EventEmitter();

    @ViewChild('flag', { static: true }) flag: ElementRef<HTMLDivElement>;

    getWidth() {
        return this.flag.nativeElement.getBoundingClientRect().width;
    }

    goEnd() {
        this.goToEnd.emit();
    }
}
