import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostPinComponent } from './post-pin.component';
import { PipesModule } from '@libs/shared-ui';

@NgModule({
    exports: [PostPinComponent],
    declarations: [PostPinComponent],
    imports: [CommonModule, PipesModule],
})
export class PostPinModule {}
