import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ImpactApiService } from './../service/impact-api.service';

import { iif, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { getActionBasicError, SharedCoreActions } from '@libs/shared-store';
import {
    ImpactStatActions,
    selectStatsRegionParams,
    selectRegionMmt,
    selectStatsControlPointParams,
    selectControlPointMmt,
} from '@cityair/modules/impact/store/statistic.feature';

import { USE_IMPACT_API } from '@cityair/modules/impact/consts';
import {
    ImpactActions,
    selectMockDataStatControlPoint,
    selectMockDataStatRegion,
    selectStatsSourceParams,
} from '@cityair/modules/impact/store/impact.feature';

@Injectable()
export class ImpactStatsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private impactService: ImpactApiService
    ) {}

    loadStatByRegion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ImpactStatActions.setRegion,
                ImpactStatActions.updateRegionMmt,
                ImpactStatActions.updateDateRangeRegion
            ),
            withLatestFrom(
                this.store.select(selectStatsRegionParams),
                this.store.select(selectMockDataStatRegion)
            ),
            filter(([_, params]) => params !== null),
            tap(() =>
                this.store.dispatch(ImpactStatActions.loadingStatForRegion({ payload: true }))
            ),
            switchMap(([_, { id, params }, data]) =>
                iif(
                    () => USE_IMPACT_API,
                    this.impactService.getStatsRegion(id, params).pipe(
                        switchMap((data: any) => [
                            ImpactStatActions.setExcessStatistics({
                                payload: data?.response ?? null,
                            }),
                            ImpactStatActions.loadingStatForRegion({ payload: false }),
                        ]),
                        catchError((errorResponse: HttpErrorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(
                                errorAction,
                                ImpactStatActions.loadingStatForRegion({ payload: false })
                            );
                        })
                    ),
                    of(ImpactStatActions.setMockDataRegion({ payload: data })).pipe(delay(700))
                )
            )
        )
    );
    initRegionMmt = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveRun),
            withLatestFrom(this.store.select(selectRegionMmt)),
            filter(([_, mmt]) => mmt === null),
            switchMap(([action, mmt]) => {
                if (action?.payload?.species_list?.length) {
                    const mmt = action.payload.species_list[0];
                    return [ImpactStatActions.initRegionMmt({ payload: mmt })];
                }
                return [];
            })
        )
    );

    loadStatByControlPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ImpactStatActions.setControlPoint,
                ImpactStatActions.updateControlPointMmt,
                ImpactStatActions.updateDateRangeControlPoint
            ),
            withLatestFrom(
                this.store.select(selectStatsControlPointParams),
                this.store.select(selectMockDataStatControlPoint)
            ),
            filter(([_, params]) => params !== null),
            tap(() =>
                this.store.dispatch(ImpactStatActions.loadingStatForControlPoint({ payload: true }))
            ),
            switchMap(([_, { id, params }, data]) =>
                iif(
                    () => USE_IMPACT_API,
                    this.impactService.getStatsControlPoint(id, params).pipe(
                        switchMap((data: any) => [
                            ImpactStatActions.setExcessStatistics({
                                payload: data?.response ?? null,
                            }),
                            ImpactStatActions.loadingStatForControlPoint({ payload: false }),
                        ]),
                        catchError((errorResponse: HttpErrorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(
                                errorAction,
                                ImpactStatActions.loadingStatForControlPoint({ payload: false })
                            );
                        })
                    ),
                    of(ImpactStatActions.setMockDataControlPoint({ payload: data })).pipe(
                        delay(700)
                    )
                )
            )
        )
    );

    initControlPointMmt = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveRun),
            withLatestFrom(this.store.select(selectControlPointMmt)),
            filter(([_, mmt]) => mmt === null),
            switchMap(([action, mmt]) => {
                if (action?.payload?.species_list?.length) {
                    const mmt = action.payload.species_list[0];
                    return [ImpactStatActions.initControlPointMmt({ payload: mmt })];
                }
                return [];
            })
        )
    );

    loadStatsBySource$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ImpactStatActions.updateSourceMmt,
                ImpactStatActions.setSource,
                ImpactStatActions.updateDateRangeSource
            ),
            withLatestFrom(this.store.select(selectStatsSourceParams)),
            filter(([_, params]) => params !== null),
            tap(() =>
                this.store.dispatch(ImpactStatActions.loadingStatForSource({ payload: true }))
            ),
            tap(() =>
                this.store.dispatch(ImpactStatActions.setImpactStatistics({ payload: null }))
            ),
            switchMap(([_, { id, params }]) =>
                this.impactService.getStatsSource(id, params).pipe(
                    switchMap((data: any) => [
                        ImpactStatActions.loadingStatForSource({ payload: false }),
                        ImpactStatActions.setImpactStatistics({ payload: data.response }),
                    ]),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(
                            SharedCoreActions.setInfoMessage({
                                payload: {
                                    id: new Date().valueOf(),
                                    messageKey: 'notFoundObject',
                                    positionX: 'left',
                                    positionY: 'bottom',
                                    iconClass: 'error',
                                    duration: 5000,
                                    showCloseIcon: true,
                                    size: 'lg',
                                },
                            }),
                            ImpactStatActions.loadingStatForSource({ payload: false })
                        );
                    })
                )
            )
        )
    );
}
