<div *ngIf="chartOptions.chart as chart">
    <div class="chart-content">
        <canvas
            baseChart
            type="line"
            [datasets]="chart.datasets"
            [labels]="chart.labels"
            [options]="chart.options"
            [plugins]="chart.plugins"
        ></canvas>
    </div>
</div>
