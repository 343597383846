import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { markerState, isFalseNumber } from '@libs/common';

@Component({
    selector: 'city-pin',
    templateUrl: './city-pin.component.html',
    styleUrls: ['./city-pin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityPinComponent implements OnInit, OnChanges {
    markerState = markerState;

    @Input() city: string;
    @Input() value: number;
    @Input() numberAfterDot?: number = 0;
    @Input() color: string;
    @Input() selected: boolean;
    @Input() state: markerState;

    hasNoData: boolean;
    isHoliday = false;

    ngOnInit() {
        const now = new Date();
        const month = now.getMonth();
        const date = now.getDate();
        this.isHoliday = (month === 11 && date >= 20) || (month === 0 && date <= 9);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.hasNoData = isFalseNumber(this.value);
        }
    }
}
