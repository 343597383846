import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderOfPanelComponent } from './header-of-panel.component';

@NgModule({
    exports: [HeaderOfPanelComponent],
    declarations: [HeaderOfPanelComponent],
    imports: [CommonModule],
})
export class HeaderOfPanelModule {}
