<div class="region-detail scroll">
    <div class="back-btn" (click)="backToPoints()">
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.71599 8.00027L8.6705 2.12309C9.10983 1.68946 9.10983 0.986399 8.6705 0.552765C8.23116 0.119131 7.51884 0.119131 7.0795 0.552765L0.329505 7.2151C-0.109835 7.64874 -0.109835 8.3518 0.329505 8.78543L7.0795 15.4478C7.51884 15.8814 8.23116 15.8814 8.6705 15.4478C9.10983 15.0141 9.10983 14.3111 8.6705 13.8774L2.71599 8.00027Z" fill="#6097F3"/>
        </svg>
        <span>{{ textImpact.region.backTitle }}</span>
    </div>
    <ng-container *ngIf="region">
        <div class="region">
            <div class="region__title">{{ region.name }}</div>
            <div class="region__subtitle">{{ textImpact.region.subtitle }}</div>
            <div class="counters">
                <ng-container *ngIf="store.select(selectControlPoints) | async as controlPoints">
                    <div class="counter" *ngIf="region | controlPointsByRegion : controlPoints  as insidePoints">
                        <div class="name">{{textImpact.mapObjectTitle.points}}:</div>
                        <div class="value">{{insidePoints?.inside?.length }}</div>
                    </div>
                    <div class="sep"></div>
                </ng-container>
                <div class="counter">
                    <div class="name">{{textImpact.mapObjectTitle.posts}}:</div>
                    <div class="value">{{region.post_ids?.length}}</div>
                </div>
                <div class="sep"></div>
                <div class="counter">
                    <div class="name">{{textImpact.networkDensity.headers.density}}:</div>
                    <div class="value">{{ region | impactDensityRegion | valueFormatNumber : 0}} %</div>
                </div>
            </div>
            <div class="content">
                <div class="content__title">{{ textImpact.region.statisticTitle }}</div>
                <div class="filters">
                    <div class="calendar-filter"
                        (clickOutside)="calendarIsOpened = false"
                    >
                        <div (click)="toggleCalendar()">
                            <input-for-dropdown
                                [label]="textImpact.sources.filters.period"
                                [inputValue]="dateRange | dateRangeText"
                                [(isOpenDropdown)]="calendarIsOpened"
                            ></input-for-dropdown>
                        </div>
                        <div class="plumes_calc__calendar">
                            <calendar-months
                                [show]="calendarIsOpened"
                                [timeBegin]="dateTimeBegin"
                                [timeEnd]="dateTimeEnd"
                                [applyCb]="updateDateRange"
                                [monthsVisible]="1"
                                [updateDays]="updateDays"
                                [columns]="1"
                                [numberOfMonths]="12"
                            ></calendar-months>
                        </div>
                    </div>
                    <div class="mmt-filter">
                        <input-dropdown
                            [inputText]="selectedSourceMmt?.label ?? 'Не выбрано'"
                            [label]="textImpact.sources.filters.measure"
                            [(showDropdown)]="isShowDropdownForMmtList"
                            (clickOutside)="isShowDropdownForMmtList = false"
                        >
                            <selectbox-radio
                                [listItems]="mmtList"
                                (listItemsChange)="selectSourceMmt($event); isShowDropdownForMmtList = false"
                            ></selectbox-radio>
                        </input-dropdown>
                    </div>
                </div>
                <ng-container *ngIf="store.select(isLoadingStatRegion) | async else contentTemp">
                    <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
                </ng-container>
                <ng-template #contentTemp>
                    <ng-container *ngIf="store.select(selectExcessStatistics) | async as statsData else noData">
                        <div class="widgets">
                            <div *ngIf="statsData | chartDataExceedRegion : sources: true: textImpact.region.otherLabel as chartData" class="section-widget">
                                <div class="section-widget__title">
                                    {{textImpact.region.titleChartExceed}}
                                    <span [innerHTML]="selectedSourceMmt?.label"></span>
                                </div>
                                <ng-container *ngIf="chartData.values?.length else noChartTemp">
                                    <city-district-chart
                                        class="widget-chart"
                                        [data]="chartData.values"
                                        [labels]="chartData.labels"
                                        [colors]="chartData.colors"
                                    ></city-district-chart>
                                </ng-container>
                                <ng-template #noChartTemp>
                                    <div class="chart-no-data">{{textImpact.region.noChartExceed}}</div>
                                </ng-template>
                            </div>
                            <div class="section-widget">
                                <div class="section-widget__title">
                                    {{textImpact.region.titleChartMax}}
                                    <span [innerHTML]="selectedSourceMmt.label"></span>
                                </div>
                                <div class="widget-chart">
                                    <impact-line-chart
                                        [mmt]="selectedSourceMmt.value"
                                        [chartData]="MAX_CONCENTRATION_DEMO_DATA[selectedSourceMmt.id]"
                                    ></impact-line-chart>
                                </div>
                            </div>
                            <div class="section-widget" *ngIf="statsData.average_concentration | chartDataAverageMmtRegion : zone  as chartData">
                                <div class="section-widget__title">
                                    {{ textImpact.region.titleChartAverageMmt }}</div>
                                <ng-container *ngIf="chartData.values?.length else noChartTemp">
                                    <impact-stats-bar-chart
                                        class="widget-chart"
                                        [data]="chartData.values"
                                        [labels]="chartData.labels"
                                        [colors]="chartData.colors"
                                        [measure]="textsScheme['default'][selectedSourceMmt?.value]"
                                    ></impact-stats-bar-chart>
                                </ng-container>
                                <ng-template #noChartTemp>
                                    <div class="chart-no-data">{{textImpact.region.noChartAverageMmt}}</div>
                                </ng-template>
                            </div>
                            <div class="section-widget" *ngIf="statsData?.number_exceed_concentration | chartDataAverageMmtRegion : zone as chartData">
                                <div class="section-widget__title">
                                    {{ textImpact.region.titleChartNumberExceed }}
                                </div>
                                <ng-container *ngIf="chartData.values?.length else noChartTemp">
                                    <impact-stats-bar-chart
                                        class="widget-chart"
                                        [data]="chartData.values"
                                        [labels]="chartData.labels"
                                        [colors]="chartData.colors"
                                        [measure]="''"
                                    ></impact-stats-bar-chart>
                                </ng-container>
                                <ng-template #noChartTemp>
                                    <div class="chart-no-data">{{textImpact.region.noChartNumberExceed}}</div>
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noData>
                        <non-data-screen
                            class="screen-placeholders"
                            [text]="textImpact.region.noStatsRegion"
                        ></non-data-screen>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </ng-container>
</div>
