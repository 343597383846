import { EntityMetadataMap } from '@ngrx/data';
import { Reports } from '@libs/common';
const entityMetadata: EntityMetadataMap = {
    Report: {
        filterFn: (entities: Reports[], { name }: Partial<Reports>) => {
            if (!name) {
                return entities;
            }
            return entities.filter((entity) =>
                entity.id.toString().includes(name) ||
                entity.name.toLowerCase().indexOf(name) >= 0 ||
                entity.comment.toLowerCase().indexOf(name) >= 0
                    ? true
                    : false
            );
        },
        entityDispatcherOptions: {
            optimisticAdd: false,
            optimisticUpdate: false,
            optimisticDelete: true,
            optimisticUpsert: false,
            optimisticSaveEntities: false,
        },
    },
    ReportKind: {},
};

const pluralNames = {
    ReportKind: 'kinds',
};
export const entityReportsConfig = {
    entityMetadata,
    pluralNames,
};
