import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceCardComponent } from './device-card.component';
import { KebabMenuModule } from '@libs/shared-ui';
import { StaticTagsModule } from '@libs/shared-ui';
import { AlertTagsModule } from '@libs/shared-ui';

@NgModule({
    imports: [CommonModule, KebabMenuModule, StaticTagsModule, AlertTagsModule],
    exports: [DeviceCardComponent],
    declarations: [DeviceCardComponent],
})
export class DeviceCardModule {}
