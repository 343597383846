<div class="plumes_control_points">
    <div *ngIf="loadError">
        <div class="load-error-data">
            <span>{{ errorListLoadText }}</span>
        </div>
    </div>
    <div *ngIf="!loadError">
        <ca-plumes-dropdown-list
            class="plumes_control_points__dropdown_control_point_wrapper"
            [currentRun]="run"
            [runs]="runs"
            [configs]="configs"
        ></ca-plumes-dropdown-list>
        <ca-list-header [showCounter]="false">
            <button-add
                [text]="textsPlumes.addControlPoint"
                (action)="openAddCheckpoint()"
            ></button-add>
        </ca-list-header>
        <div class="plumes_control_points__sort_wrapper" *ngIf="controlPoints.length">
            <sort-list
                [properties]="[
                    {
                        props: sortControlPointName,
                        title: textsPlumes.controlPoints
                    },
                    {
                        props: sortControlPointValue,
                        title: mmtNames[currentMeasure]
                    }
                ]"
                [cbSortFun]="sortingOfControlPoint"
                [sortingProps]="sortingControlPoint"
                [sortingDirection]="sortingDirection"
            ></sort-list>
        </div>
        <div class="loader" *ngIf="loading; else listTemplate">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
        <ng-template #listTemplate>
            <ng-container *ngIf="controlPoints.length === 0; else pointsContent">
                <div class="no_data_text">{{ textsPlumes.noControlPoints }}</div>
                <div class="no_data__svg"></div>
            </ng-container>
            <ng-template #pointsContent>
                <div (scroll)="onScroll($event)" class="plumes_control_points__list scroll">
                    <div class="wrapper">
                        <plumes-control-points-card
                            *ngFor="
                                let point of controlPoints
                                    | sorting : sortingControlPoint : sortingDirection
                            "
                            [currentPointValues]="
                                store.select(getControlPointValues(point)) | async
                            "
                            [measureUnit]="
                                textsScheme[store.select(selectMeasureScheme) | async][
                                    currentMeasure
                                ]
                            "
                            [point]="point"
                            [isActive]="point.id === activeControlPoint?.id"
                            [numberAfterDot]="
                                getDigitsAfterDot(
                                    store.select(selectMeasureScheme) | async,
                                    currentMeasure
                                )
                            "
                            (click)="openControlPointChart(point)"
                            (openActionsPopup)="openActionsPopup($event, point)"
                        ></plumes-control-points-card>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
    <ng-template #popupDeleteOutlet>
        <stnd-ask-popup
            *ngIf="isDeletePopupOpen"
            [accept]="deleteControlPointAccept"
            [close]="closeDeleteControlPointDialog"
            [texts]="popupDeleteTexts"
        ></stnd-ask-popup>
    </ng-template>

    <ng-template #actionsOutlet>
        <card-actions-list [position]="popupPositionTop" (clickOutside)="closeActionsPopup($event)">
            <card-actions-item
                [text]="editStation.edit"
                (click)="editControlPoint()"
            ></card-actions-item>

            <card-actions-separator></card-actions-separator>

            <card-actions-item
                type="danger"
                [text]="editStation.delete"
                (click)="deleteControlPointDialog($event, selectedPoint)"
            ></card-actions-item>
        </card-actions-list>
    </ng-template>
</div>
