import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';

import * as moment from 'moment-timezone';
import { Observable, Subject, takeUntil } from 'rxjs';

import { RunPlume, RunPlumeAnnotation } from '@cityair/modules/plumes/services/run/models';
import { CheckboxItem, TEXTS } from '@libs/common';
import { Store } from '@ngrx/store';
import {
    setActiveRun,
    setFilterForRuns,
    setRunsLoadError,
    updateDateRangeRun,
} from '../../store/actions';
import { RunService } from '../../services/run/run.service';
import {
    selectActiveRun,
    selectDateRangeRuns,
    selectRunLoading,
    selectRunsConfigById,
    selectConfigByRuns,
} from '../../store/selectors';
import { filter } from 'rxjs/operators';
import { DateRange } from '@cityair/modules/plumes/store/reducers';

@Component({
    selector: 'plumes-calculation-results',
    templateUrl: 'plumes-calculation-results.component.html',
    styleUrls: ['plumes-calculation-results.component.less'],
})
export class PlumesCalculationResults implements OnDestroy {
    @Input() plumesVisible: boolean;
    @Input() loadError: boolean;
    @Input() runs: RunPlumeAnnotation[];
    @ViewChild('scrollable') scrollable: ElementRef<HTMLDivElement>;

    public loaded$: Observable<boolean>;
    public loading$: Observable<boolean>;
    public isLoadingRun: boolean;
    isScrolledToTop = true;
    textNoRunsAvailable = TEXTS.PLUMES.selectedPeriodNoRunsAvailable;
    errorListLoadText = TEXTS.FORECAST.errorListLoad;
    textWind = TEXTS.PLUMES.quickWindButton;
    labelPeriod = TEXTS.PLUMES.runResultLabelPeriod;
    labelConfigFilter = TEXTS.PLUMES.runConfiguration;
    textAllFilter = TEXTS.NOTIFICATIONS.all;
    isShowDropdownForFilter = false;
    selectRunsConfigById = selectRunsConfigById;
    currentRunId: number;
    dateRangeFilterText: string;
    dateRange: DateRange;
    calendarIsOpened = false;
    numberOfFutureDays = 1;
    handleUpdateDays: (numberOfFutureDays?: number, minDate?: string) => void;
    public dateTimeBegin: number;
    public dateTimeEnd: number;
    public ngDestroyed$ = new Subject<void>();
    public configByRuns: CheckboxItem[] = [];
    public selectedFilter: CheckboxItem;

    constructor(public store: Store, private runService: RunService) {
        this.loading$ = runService.loading$;
        this.loaded$ = runService.loaded$;
        this.store
            .select(selectDateRangeRuns)
            .pipe(filter((v) => v !== null))
            .subscribe((dateRange) => {
                this.dateRange = dateRange;
                this.dateTimeBegin = moment(dateRange.start).valueOf();
                this.dateTimeEnd = moment(dateRange.end).valueOf();
            });
        this.store
            .select(selectConfigByRuns)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((configByRuns) => {
                this.configByRuns = configByRuns;
                this.selectedFilter = this.configByRuns.find((s) => s.selected == true);
            });
        this.store
            .select(selectActiveRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((activeRun) => (this.currentRunId = activeRun?.id ?? null));
        this.store
            .select(selectRunLoading)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isLoading) => (this.isLoadingRun = isLoading));
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    toggleCalendar() {
        if (this.calendarIsOpened) {
            this.handleUpdateDays(this.numberOfFutureDays);
        }
    }

    scrollTop() {
        this.scrollable.nativeElement.scrollTo(0, 0);
    }

    onScroll() {
        this.isScrolledToTop = !this.scrollable.nativeElement.scrollTop;
    }

    updateDays = (fn: (numberOfFutureDays?: number, minDate?: string) => void) =>
        (this.handleUpdateDays = fn);

    updateDateRange = (begin: number, end: number) => {
        this.calendarIsOpened = false;
        // fix start date after calendar
        const start = moment(begin).startOf('day').toISOString();
        const finish = moment(end).endOf('day').toISOString();

        this.store.dispatch(setRunsLoadError({ payload: false }));
        this.store.dispatch(
            updateDateRangeRun({
                payload: {
                    start,
                    end: finish,
                },
            })
        );
        this.runService.clearCache();
    };

    selectRun(run: RunPlume) {
        if (this.currentRunId && this.currentRunId != run.id) {
            this.store.dispatch(setActiveRun({ payload: run }));
        }
    }

    selectFilter(list: CheckboxItem[]) {
        this.selectedFilter = this.configByRuns.find(
            (dl) => dl.id === list.find((s) => s.selected == true).id
        );
        this.store.dispatch(setFilterForRuns({ payload: +this.selectedFilter?.id ?? null }));
    }
}
