import { DEFAULT_MEASURE_SCHEME } from '../consts/default-measure-scheme.const';
import { NO_DATA_COLOR } from '../consts/no-data-color.const';
import { PDK_MR, PDK_SS } from '../consts/pdk.consts';
import { ZONES, zone_default } from '../consts/zone.const';
import { MeasureScheme } from '../enums/measure-scheme';
import { MeasuresZones } from '../types/measures-zones';
import { isFalseNumber } from '../utils/utils';

export class MeasureZones {
    zones: MeasuresZones = zone_default;
    scheme: MeasureScheme = DEFAULT_MEASURE_SCHEME;

    setScheme(scheme: MeasureScheme) {
        this.scheme = scheme;
        this.zones = ZONES[this.scheme];
    }

    setZones(zones: MeasuresZones = {}) {
        this.zones = {
            ...this.zones,
            ...zones,
        };
    }

    getZone(mmt: string) {
        return this.zones[mmt]?.zone;
    }

    getColors(mmt: string) {
        return this.zones[mmt]?.color;
    }

    getIndex(name: string, value: number) {
        const zone = this.getZone(name);

        if (!zone) {
            return 0;
        }

        if (value >= zone[zone.length - 1]) {
            return zone.length;
        }

        return zone.findIndex((v) => value < v);
    }

    getColorByIndex(mmt, index) {
        return this.zones[mmt]?.color?.[index] ?? NO_DATA_COLOR;
    }

    // TODO delete after new chart
    getColor = (value, type: string) => {
        if (isFalseNumber(value)) return NO_DATA_COLOR;

        const index = this.getIndex(type, value);

        return this.getColorByIndex(type, index);
    };

    getPDKcc(name: string) {
        return PDK_SS[this.scheme][name];
    }

    getPDKmr(name: string) {
        return PDK_MR[this.scheme][name];
    }
}

export const measureZones = new MeasureZones();
