import { Pipe, PipeTransform } from '@angular/core';
import { IsNumberPipe } from './is-number.pipe';

@Pipe({
    name: 'exceedsPdkValue',
})
export class ExceedsPdkValuePipe implements PipeTransform {
    constructor(private isNumberPipe: IsNumberPipe) {}

    transform(value: number, pdkValues: { [key in string]: number }, name: string) {
        const pdkValue = pdkValues[name];

        if (
            !pdkValue ||
            !this.isNumberPipe.transform(pdkValue) ||
            !this.isNumberPipe.transform(value)
        ) {
            return false;
        }

        return value >= pdkValue;
    }
}
