import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { LittleComponentsModule } from '@libs/shared-ui';
import { CrossButtonModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';
import { FeedbackButtonComponent } from './components/feedback-button/feedback-button.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { DarkButtonComponent } from './components/dark-button/dark-button.component';
import { FeedbackIconComponent } from './components/feedback-icon/feedback-icon.component';
import { FeedbackMobileButtonComponent } from './components/feedback-mobile-button/feedback-mobile-button.component';
import { FeedbackSuccessComponent } from './components/feedback-success/feedback-success.component';
import { FeedbackSuccessPosterComponent } from './components/feedback-success-poster/feedback-success-poster.component';
import { FeedbackErrorComponent } from './components/feedback-error/feedback-error.component';
import { ButtonModule } from '@libs/shared-ui';
import { ButtonsSaveCancelModule } from '@libs/shared-ui';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        TooltipsModule,
        LittleComponentsModule,
        LoadersModule,
        CrossButtonModule,
        ButtonModule,
        ButtonsSaveCancelModule,
    ],
    exports: [
        FeedbackButtonComponent,
        FeedbackFormComponent,
        FeedbackMobileButtonComponent,
        FeedbackSuccessComponent,
        FeedbackErrorComponent,
    ],
    declarations: [
        FeedbackButtonComponent,
        FeedbackFormComponent,
        DarkButtonComponent,
        FeedbackIconComponent,
        FeedbackMobileButtonComponent,
        FeedbackSuccessComponent,
        FeedbackSuccessPosterComponent,
        FeedbackErrorComponent,
    ],
})
export class FeedbackModule {}
