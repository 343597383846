import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { LayerButtonIconType, DomainConfigType } from '@libs/common';
import {
    selectCurrentTimeByIndex,
    selectCityCurrentValue,
    selectGlobalMeasurement,
    selectShowAqiWidget,
    selectIsAllowPublicForecast,
    selectPublicForecastConfig,
    selectLensForecastConfig,
    selectIsAllowLensForecast,
} from '@cityair/modules/core/store/selectors';
import { selectCityById } from '@libs/shared-store';
import {
    intervalUpdate,
    openInstantAqiFaq,
    togglePublicForecast,
} from '@cityair/modules/core/store/actions';
import { ANIMATION_OPACITY, isRU, TEXTS, PM10, AqiType, Locality } from '@libs/common';
import { AuthService, getModulePageConfig } from '@libs/shared-store';
import { selectCurrentLocationId } from '@cityair/modules/core/store/current-city/current-city.feature';
import {
    FORESACT_BEGIN_TIME,
    FORESACT_END_TIME,
    selectActiveMmtPublicForecast,
    selectIsShowPublicForecast,
    selectMmtsPublicForecast,
    setActiveMmt,
    toggleShowPublicForecast,
    selectIsShowLensForecast,
    toggleShowLensForecast,
} from '@cityair/modules/core/store/public-forecast/public-forecast.feature';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { getStndTimeBegin, getStndTimeEnd } from '@cityair/libs/shared/utils/config';

@Component({
    selector: 'posts-and-devices-top-map-controls',
    templateUrl: 'top-map-controls.component.html',
    styleUrls: ['top-map-controls.component.less'],
    animations: ANIMATION_OPACITY,
})
export class TopMapControlsComponent implements OnDestroy {
    TEXTS = TEXTS;
    isRU = isRU;
    AqiType = AqiType;

    currentLocationId$ = this.store.select(selectCurrentLocationId);
    moduleConfig$ = this.store.select(getModulePageConfig);

    LayerButtonIconType = LayerButtonIconType;
    currentMeasure = PM10;

    showAqiLegend = false;
    isActiveModelling = false;
    isActiveLens = false;
    selectCurrentTimeByIndex = selectCurrentTimeByIndex;
    selectCityCurrentValue = selectCityCurrentValue;
    selectGlobalMeasurement = selectGlobalMeasurement;
    selectShowAqiWidget = selectShowAqiWidget;
    selectIsAllowPublicForecast = selectIsAllowPublicForecast;
    selectIsAllowLensForecast = selectIsAllowLensForecast;
    selectMmtsPublicForecast = selectMmtsPublicForecast;
    selectActiveMmtPublicForecast = selectActiveMmtPublicForecast;

    setActiveMmt = setActiveMmt;
    forecastConfig: DomainConfigType;
    forecastLensConfig: DomainConfigType;
    public ngDestroyed$ = new Subject<void>();
    constructor(readonly store: Store, readonly authService: AuthService) {
        store
            .select(selectIsShowPublicForecast)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isActive) => (this.isActiveModelling = isActive));
        store
            .select(selectPublicForecastConfig)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((config) => (this.forecastConfig = config));
        store
            .select(selectIsShowLensForecast)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isActive) => (this.isActiveLens = isActive));
        store
            .select(selectLensForecastConfig)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((config) => (this.forecastLensConfig = config));
    }

    getCityById(id: string): Observable<Locality> {
        return this.store.select(selectCityById(id));
    }

    ngOnDestroy() {
        if (this.isActiveModelling) {
            this.store.dispatch(toggleShowPublicForecast({ payload: false }));
        }
        if (this.isActiveLens) {
            this.store.dispatch(toggleShowLensForecast({ payload: false }));
        }
        this.ngDestroyed$.next();
    }

    updateDateRange({ begin, end }) {
        this.store.dispatch(intervalUpdate({ begin, end }));
    }

    enableModelling() {
        if (this.isActiveLens) {
            this.store.dispatch(toggleShowLensForecast({ payload: false }));
        }
        this.toggleForecast(true);
    }

    disableModelling() {
        this.toggleForecast(false);
    }

    enableLens() {
        if (this.isActiveModelling) {
            this.store.dispatch(toggleShowPublicForecast({ payload: false }));
        }
        this.toggleLens(true);
    }

    disableLens() {
        this.toggleLens(false);
    }

    private toggleForecast(isActive) {
        this.intervalUpdate(this.forecastConfig, isActive);
        this.store.dispatch(togglePublicForecast({ payload: isActive }));
        this.store.dispatch(toggleShowPublicForecast({ payload: isActive }));
    }

    private toggleLens(isActive) {
        this.intervalUpdate(this.forecastLensConfig, isActive);
        this.store.dispatch(togglePublicForecast({ payload: isActive }));
        this.store.dispatch(toggleShowLensForecast({ payload: isActive }));
    }

    openDetailsLink() {
        this.store.dispatch(openInstantAqiFaq());
    }

    private intervalUpdate(config: DomainConfigType, isActive: boolean) {
        if (isActive) {
            const begin = config?.start ? moment(config.start).valueOf() : FORESACT_BEGIN_TIME;
            const end = config?.end ? moment(config.end).valueOf() : FORESACT_END_TIME;
            this.store.dispatch(
                intervalUpdate({
                    begin,
                    end,
                })
            );
        } else {
            this.store.dispatch(
                intervalUpdate({
                    begin: getStndTimeBegin(),
                    end: getStndTimeEnd(),
                })
            );
        }
    }
}
