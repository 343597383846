import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTabs } from './page-tabs.component';

@NgModule({
    exports: [PageTabs],
    declarations: [PageTabs],
    imports: [CommonModule],
})
export class PageTabsModule {}
