import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FormErrors, TEXTS } from '@libs/common';
import { environment } from 'environments/environment';

const API_URL = environment.api_url;

const ENDPOINTS = {
    FEEDBACK: `${API_URL}/harvester/v2/forms/add_user_feedback`,
};

interface FormBody {
    form: string;
    group_id: string;
    data: Record<string, string | number | boolean | null>;
}

interface FormResponse<T> {
    id: number;
    form: string;
    group_id: number;
    user_id: number;
    data: T;
    date_created: string;
}

interface FeedbackResponse {
    message: string;
}

@Injectable({
    providedIn: 'root',
})
export class FeedbackApiService {
    private _errorMessage$ = new BehaviorSubject<string>(null);
    errorMessage$ = this._errorMessage$.asObservable();

    private _error$ = new BehaviorSubject<FormErrors>(null);
    error$ = this._error$.asObservable();

    constructor(private http: HttpClient) {}

    private handleError(httpError: HttpErrorResponse) {
        const { status, error } = httpError;

        let shouldThrow = false;

        if (status === 0) {
            console.error('A client-side or network error occurred:', error);
            this._errorMessage$.next(`${TEXTS.REQUEST_ERRORS.clientOrNetworkError}.`);
        } else if (!error?.error) {
            shouldThrow = true;
            console.error('Unrecognized error:', error);
            this._errorMessage$.next(
                `${TEXTS.REQUEST_ERRORS.unrecognizedError}. ${TEXTS.REQUEST_ERRORS.tryAgainLater}.`
            );
        } else {
            this._error$.next(error);
            this._errorMessage$.next(error.message?.fields?.message || error.error);
        }

        if (shouldThrow) {
            return throwError(() => error || httpError);
        } else {
            return of();
        }
    }

    private post(endpoint: string, requestBody: FormBody) {
        type FeedbackFormResponse = FormResponse<FeedbackResponse>;

        return this.http.post<FeedbackFormResponse>(endpoint, requestBody).pipe(
            catchError<FeedbackFormResponse, Observable<FeedbackFormResponse>>(
                this.handleError.bind(this)
            ),
            map((resp) => ({ ok: !!resp.id }))
        );
    }

    sendFeedback(message: string, groupId: string) {
        return this.post(ENDPOINTS.FEEDBACK, {
            form: 'feedback',
            group_id: groupId,
            data: {
                message,
            },
        });
    }
}
