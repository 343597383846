<svg *ngIf="type === 'tube'" width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0117188" width="34" height="34" rx="17" fill="url(#paint0_linear_22532_45589)" fill-opacity="0.6"/>
    <circle cx="10.6264" cy="6.77781" r="0.70945" fill="white"/>
    <circle cx="10.9812" cy="9.55148" r="1.06417" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7546 12.6794C12.7546 12.0917 13.231 11.6152 13.8187 11.6152H20.2038C20.7915 11.6152 21.268 12.0917 21.268 12.6794C21.268 13.2671 20.7915 13.7436 20.2038 13.7436H13.8187C13.231 13.7436 12.7546 13.2671 12.7546 12.6794ZM9.91699 26.868C9.91699 26.2802 10.3934 25.8038 10.9812 25.8038H11.3344L13.8258 13.7448H19.9167L22.6849 25.8038H23.0418C23.6295 25.8038 24.106 26.2802 24.106 26.868C24.106 27.4557 23.6295 27.9321 23.0418 27.9321H10.9812C10.3934 27.9321 9.91699 27.4557 9.91699 26.868ZM17.1613 17.333C17.1975 16.942 16.6814 16.7694 16.4751 17.1036L14.68 20.0116C14.5272 20.2592 14.7053 20.5784 14.9962 20.5784H16.6021C16.8208 20.5784 16.9922 20.7664 16.972 20.9842L16.858 22.2161C16.8218 22.6071 17.3379 22.7796 17.5441 22.4455L19.3392 19.5374C19.492 19.2899 19.314 18.9707 19.0231 18.9707H17.4172C17.1984 18.9707 17.027 18.7827 17.0472 18.5649L17.1613 17.333Z" fill="white"/>
    <defs>
        <linearGradient id="paint0_linear_22532_45589" x1="0.0117186" y1="17" x2="34.0117" y2="17" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2F80ED"/>
            <stop offset="1" stop-color="#9375E4"/>
        </linearGradient>
    </defs>
</svg>

<svg *ngIf="type === 'agro'" width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0117188" width="34" height="34" rx="17" fill="url(#paint0_linear_22532_45600)" fill-opacity="0.6"/>
    <path d="M15.6296 11.2024L17.0114 11.3087V25.8244H15.7879C15.2605 25.8244 14.7813 25.5178 14.5603 25.0389C14.3929 24.6763 14.0737 24.4062 13.6883 24.3011L13.6529 24.2914C12.7916 24.0565 12.3533 23.0989 12.7385 22.2935L12.9646 21.8207C13.085 21.569 13.1475 21.2936 13.1475 21.0146V20.8736C13.1475 20.5568 13.087 20.243 12.9694 19.9489L11.9093 17.2986C11.761 16.9278 11.671 16.5362 11.6425 16.1379L11.581 15.277C10.1282 16.4272 8.0619 16.3782 6.66518 15.1606L5.26361 13.9387C5.12911 13.8214 5.13733 13.6099 5.28051 13.5034L6.28904 12.7535C7.89736 11.5576 10.134 11.6973 11.581 13.084L11.2749 10.7113C11.2039 10.1615 11.3724 9.60773 11.7374 9.19054L12.4677 8.35593C12.4986 8.32067 12.5552 8.35507 12.5381 8.3987C12.0261 9.70717 12.3864 11.1961 13.44 12.1258L13.4608 12.1441C13.9834 11.4908 14.7954 11.1382 15.6296 11.2024Z" fill="white"/>
    <path d="M18.3928 11.2024L17.0111 11.3087V25.8244H18.2345C18.7619 25.8244 19.2412 25.5178 19.4622 25.0389C19.6296 24.6763 19.9488 24.4062 20.3341 24.3011L20.3695 24.2914C21.2309 24.0565 21.6691 23.0989 21.2839 22.2935L21.0578 21.8207C20.9375 21.569 20.875 21.2936 20.875 21.0146V20.8736C20.875 20.5568 20.9354 20.243 21.0531 19.9489L22.1132 17.2986C22.2615 16.9278 22.3515 16.5362 22.3799 16.1379L22.4414 15.277C23.8942 16.4272 25.9606 16.3782 27.3573 15.1606L28.7589 13.9387C28.8933 13.8214 28.8851 13.6099 28.7419 13.5034L27.7334 12.7535C26.1251 11.5576 23.8884 11.6973 22.4414 13.084L22.7476 10.7113C22.8185 10.1615 22.6501 9.60773 22.285 9.19054L21.5548 8.35593C21.5239 8.32067 21.4673 8.35507 21.4844 8.3987C21.9964 9.70717 21.6361 11.1961 20.5825 12.1258L20.5617 12.1441C20.039 11.4908 19.227 11.1382 18.3928 11.2024Z" fill="white"/>
    <path d="M20.061 22.7644C20.061 23.2238 19.6886 23.5963 19.2291 23.5963C18.7697 23.5963 18.3973 23.2238 18.3973 22.7644C18.3973 22.305 18.7697 21.9326 19.2291 21.9326C19.6886 21.9326 20.061 22.305 20.061 22.7644Z" fill="url(#paint1_linear_22532_45600)"/>
    <path d="M15.6246 22.7644C15.6246 23.2238 15.2522 23.5963 14.7928 23.5963C14.3334 23.5963 13.9609 23.2238 13.9609 22.7644C13.9609 22.305 14.3334 21.9326 14.7928 21.9326C15.2522 21.9326 15.6246 22.305 15.6246 22.7644Z" fill="url(#paint2_linear_22532_45600)"/>
    <defs>
        <linearGradient id="paint0_linear_22532_45600" x1="0.0117186" y1="17" x2="34.0117" y2="17" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2F80ED"/>
            <stop offset="1" stop-color="#9375E4"/>
        </linearGradient>
        <linearGradient id="paint1_linear_22532_45600" x1="4.22056" y1="23.5963" x2="27.5384" y2="23.5963" gradientUnits="userSpaceOnUse">
            <stop stop-color="#74EEFF"/>
            <stop offset="1" stop-color="#E338B3"/>
        </linearGradient>
        <linearGradient id="paint2_linear_22532_45600" x1="4.22056" y1="23.5963" x2="27.5384" y2="23.5963" gradientUnits="userSpaceOnUse">
            <stop stop-color="#74EEFF"/>
            <stop offset="1" stop-color="#E338B3"/>
        </linearGradient>
    </defs>
</svg>

<svg *ngIf="type === 'factory'" width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0117188" width="34" height="34" rx="17" fill="url(#paint0_linear_22532_45583)" fill-opacity="0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.8627 14.0167C24.6983 13.9284 24.4984 13.9376 24.3411 14.0421L20.0566 16.8977V14.4642C20.0566 14.2765 19.9531 14.105 19.7887 14.0167C19.6243 13.9284 19.4244 13.9376 19.2671 14.0421L14.457 17.2478L14.0054 10.9114H14.4752C14.7553 10.9114 14.9826 10.6841 14.9826 10.404V9.38923C14.9826 9.10915 14.7553 8.88184 14.4752 8.88184H9.40126C9.12117 8.88184 8.89386 9.10915 8.89386 9.38923V10.404C8.89386 10.6841 9.12117 10.9114 9.40126 10.9114H9.87111L8.89487 24.5757C8.88473 24.7157 8.93344 24.8537 9.02984 24.9572C9.12625 25.0597 9.2602 25.1186 9.40126 25.1186C9.40126 25.1186 12.7576 25.1186 14.4357 25.1186C17.8315 25.1186 24.6232 25.1186 24.6232 25.1186C24.9033 25.1186 25.1306 24.8913 25.1306 24.6112V14.4642C25.1306 14.2765 25.0271 14.105 24.8627 14.0167ZM18.0251 21.0595H15.9955V19.0299H18.0251V21.0595ZM23.1019 21.0595H21.0723V19.0299H23.1019V21.0595Z" fill="white"/>
    <defs>
        <linearGradient id="paint0_linear_22532_45583" x1="0.0117186" y1="17" x2="34.0117" y2="17" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2F80ED"/>
            <stop offset="1" stop-color="#9375E4"/>
        </linearGradient>
    </defs>
</svg>

<svg *ngIf="type === 'private-sector'" width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0117188" width="34" height="34" rx="17" fill="url(#paint0_linear_22532_45607)" fill-opacity="0.6"/>
    <path d="M23.1582 21.7483H10.8665V28.1429C10.8665 28.6163 11.2382 29 11.6967 29H15.2572V23.5614H18.7692V29H22.328C22.7865 29 23.1582 28.6163 23.1582 28.1429V21.7483Z" fill="white"/>
    <path d="M16.4533 13.2089C16.7699 12.9117 17.2535 12.9117 17.5702 13.2089L25.875 21.0036C26.1558 21.2671 25.9752 21.7493 25.5958 21.7493H8.42765C8.04824 21.7493 7.86768 21.2671 8.14843 21.0036L16.4533 13.2089Z" fill="white"/>
    <path d="M19.6461 13.5909H23.1581V20.8426H19.6461V13.5909Z" fill="white"/>
    <path d="M19.8877 10.9767L21.2205 12.1808C21.5319 12.4621 22.0067 12.1513 21.893 11.7405L21.649 10.8589C21.4912 10.2886 21.5694 9.6763 21.8649 9.16773L22.0933 8.77486C22.4795 8.11027 22.488 7.28217 22.1156 6.60925L21.2729 5.08666C21.1884 4.93403 20.9626 4.99592 20.9626 5.17171V7.94605C20.9626 8.07624 20.9053 8.19937 20.8069 8.2807L19.9298 9.00515C19.3241 9.50539 19.3039 10.4494 19.8877 10.9767Z" fill="white"/>
    <defs>
        <linearGradient id="paint0_linear_22532_45607" x1="0.0117186" y1="17" x2="34.0117" y2="17" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2F80ED"/>
            <stop offset="1" stop-color="#9375E4"/>
        </linearGradient>
    </defs>
</svg>

<svg *ngIf="type === 'azs'" width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0117188" width="34" height="34" rx="17" fill="url(#paint0_linear_22532_45610)" fill-opacity="0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5127 8C10.3409 8 9.3909 8.92455 9.3909 10.065V24.6577H19.8232V10.065C19.8232 8.92455 18.8732 8 17.7014 8H11.5127ZM11.5127 9.37667C11.1221 9.37667 10.8054 9.68484 10.8054 10.065V14.1262C10.8054 14.5064 11.1221 14.8145 11.5127 14.8145H17.7014C18.092 14.8145 18.4086 14.5064 18.4086 14.1262V10.065C18.4086 9.68484 18.092 9.37667 17.7014 9.37667H11.5127Z" fill="white"/>
    <path d="M8.01172 24.8987C8.01172 24.5565 8.29671 24.2792 8.64826 24.2792H20.5305C20.882 24.2792 21.167 24.5565 21.167 24.8987V25.3805C21.167 25.7226 20.882 26 20.5305 26H8.64826C8.29671 26 8.01172 25.7226 8.01172 25.3805V24.8987Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7818 9.57099C21.0719 9.31643 21.5191 9.33896 21.7807 9.62131L25.8252 13.9872C25.9478 14.1195 26.0144 14.2921 26.0116 14.4703L25.8949 22.0418C25.8754 23.3023 24.7963 24.3016 23.5017 24.2579C22.3326 24.2185 21.3777 23.3357 21.2773 22.2014L20.9805 18.851C20.949 18.4951 20.6429 18.2218 20.2758 18.2218H19.6994V16.8451H20.2758C21.377 16.8451 22.2952 17.665 22.3898 18.7328L22.6866 22.0831C22.7256 22.5238 23.0966 22.8667 23.5508 22.8821C24.0537 22.899 24.4729 22.5108 24.4805 22.0211L24.5932 14.7132L20.7301 10.5431C20.4685 10.2608 20.4917 9.82555 20.7818 9.57099Z" fill="white"/>
    <path d="M22.6346 14.4595V12.2339C22.6346 12.2196 22.6515 12.2115 22.6631 12.2202L25.1032 14.0522C25.1075 14.0555 25.11 14.0605 25.11 14.0658V17.5265C25.11 17.5418 25.0911 17.5495 25.0799 17.5388L23.157 15.6807C22.8226 15.3576 22.6346 14.918 22.6346 14.4595Z" fill="white"/>
    <defs>
        <linearGradient id="paint0_linear_22532_45610" x1="0.0117186" y1="17" x2="34.0117" y2="17" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2F80ED"/>
            <stop offset="1" stop-color="#9375E4"/>
        </linearGradient>
    </defs>
</svg>
<svg *ngIf="type === 'auto'" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="34" rx="17" fill="url(#paint0_linear_22882_4353)" fill-opacity="0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.05957 17.7826C7.05957 16.2523 8.30012 15.0117 9.83042 15.0117H24.1697C25.7 15.0117 26.9406 16.2523 26.9406 17.7826V21.7473C26.9406 22.0533 26.6925 22.3014 26.3864 22.3014H7.61374C7.30768 22.3014 7.05957 22.0533 7.05957 21.7473V17.7826ZM21.0487 18.8029C21.0487 18.4979 21.2959 18.2507 21.6009 18.2507H24.1781C24.4831 18.2507 24.7303 18.4979 24.7303 18.8029C24.7303 19.1079 24.4831 19.3552 24.1781 19.3552H21.6009C21.2959 19.3552 21.0487 19.1079 21.0487 18.8029ZM9.82014 18.2507C9.51514 18.2507 9.26789 18.4979 9.26789 18.8029C9.26789 19.1079 9.51514 19.3552 9.82014 19.3552H12.3973C12.7023 19.3552 12.9496 19.1079 12.9496 18.8029C12.9496 18.4979 12.7023 18.2507 12.3973 18.2507H9.82014Z" fill="white"/>
    <path d="M22.3018 22.3018H26.278V23.8442C26.278 24.4563 25.7817 24.9526 25.1696 24.9526H23.4101C22.798 24.9526 22.3018 24.4563 22.3018 23.8442V22.3018Z" fill="white"/>
    <path d="M7.72168 22.3018H11.6979V23.8442C11.6979 24.4563 11.2017 24.9526 10.5895 24.9526H8.83002C8.2179 24.9526 7.72168 24.4563 7.72168 23.8442V22.3018Z" fill="white"/>
    <path d="M12.2938 9.60158L10.5047 14.4575H22.8319L21.0429 9.60158L12.2938 9.60158Z" stroke="white" stroke-width="1.10834"/>
    <path d="M23.627 15.4175C23.627 14.0958 24.6984 13.0244 26.02 13.0244" stroke="white" stroke-width="1.10834" stroke-linecap="round"/>
    <path d="M10.1143 15.4175C10.1143 14.0958 9.04284 13.0244 7.72117 13.0244" stroke="white" stroke-width="1.10834" stroke-linecap="round"/>
    <defs>
        <linearGradient id="paint0_linear_22882_4353" x1="-1.8999e-07" y1="17" x2="34" y2="17" gradientUnits="userSpaceOnUse">
        <stop stop-color="#2F80ED"/>
        <stop offset="1" stop-color="#9375E4"/>
        </linearGradient>
    </defs>
</svg>
<svg *ngIf="type === 'career'" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="34" rx="17" fill="url(#paint0_linear_22882_4363)" fill-opacity="0.6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0699 11.1666H10.1813C9.96655 11.1666 9.79245 11.3407 9.79245 11.5554V15.0551H14.4587V11.5554C14.4587 11.3407 14.2846 11.1666 14.0699 11.1666ZM10.1813 10C9.32227 10 8.62588 10.6964 8.62588 11.5554V16H8.00049C6.89592 16 6.00049 16.8954 6.00049 18V18.9787C5.24113 19.4617 4.7373 20.3106 4.7373 21.2772C4.7373 22.7805 5.95598 23.9992 7.45929 23.9992H18.3472C19.8505 23.9992 21.0692 22.7805 21.0692 21.2772C21.0692 20.2664 20.5182 19.3842 19.7002 18.9147V18.6006H19.7158V17.1128L24.2208 14.9313L26.5118 19.5133L23.3417 23.1711C23.0611 23.4949 23.291 23.9986 23.7195 23.9986H27.2612C28.7245 23.9986 29.6924 22.4788 29.0736 21.1528L28.0057 18.8644C27.9629 18.7727 27.8977 18.7019 27.8208 18.6533L25.612 14.2357C25.2278 13.4673 24.2935 13.1559 23.5252 13.5401L19.148 15.6597C18.7848 15.2871 18.2773 15.0557 17.7158 15.0557H16.0698C15.917 15.0557 15.7682 15.0728 15.6253 15.1052V11.5554C15.6253 10.6964 14.9289 10 14.0699 10H10.1813ZM7.45929 19.7218H18.3472C19.2063 19.7218 19.9026 20.4181 19.9026 21.2772C19.9026 22.1362 19.2063 22.8326 18.3472 22.8326H7.45929C6.60025 22.8326 5.90387 22.1362 5.90387 21.2772C5.90387 20.4181 6.60025 19.7218 7.45929 19.7218ZM8.23718 22.4436C8.88145 22.4436 9.40374 21.9213 9.40374 21.277C9.40374 20.6328 8.88145 20.1105 8.23718 20.1105C7.5929 20.1105 7.07061 20.6328 7.07061 21.277C7.07061 21.9213 7.5929 22.4436 8.23718 22.4436ZM14.0696 21.277C14.0696 21.9213 13.5473 22.4436 12.903 22.4436C12.2587 22.4436 11.7364 21.9213 11.7364 21.277C11.7364 20.6328 12.2587 20.1105 12.903 20.1105C13.5473 20.1105 14.0696 20.6328 14.0696 21.277ZM17.5696 22.4436C18.2139 22.4436 18.7362 21.9213 18.7362 21.277C18.7362 20.6328 18.2139 20.1105 17.5696 20.1105C16.9253 20.1105 16.403 20.6328 16.403 21.277C16.403 21.9213 16.9253 22.4436 17.5696 22.4436Z" fill="white"/>
    <defs>
        <linearGradient id="paint0_linear_22882_4363" x1="-1.8999e-07" y1="17" x2="34" y2="17" gradientUnits="userSpaceOnUse">
        <stop stop-color="#2F80ED"/>
        <stop offset="1" stop-color="#9375E4"/>
        </linearGradient>
    </defs>
</svg>
<svg *ngIf="type === 'pti'" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="34" rx="17" fill="url(#paint0_linear_22882_4366)" fill-opacity="0.6"/>
    <path d="M17.653 7.9904C17.7032 7.46805 17.0175 7.23092 16.7343 7.67271L9.82942 18.4444C9.6161 18.7771 9.85507 19.2142 10.2504 19.2142H16.4491C16.7442 19.2142 16.9751 19.4684 16.9468 19.762L16.3461 26.0101C16.2958 26.5325 16.9815 26.7696 17.2647 26.3278L24.1696 15.5562C24.3829 15.2234 24.144 14.7863 23.7487 14.7863H17.5499C17.2548 14.7863 17.024 14.5322 17.0522 14.2385L17.653 7.9904Z" fill="white"/>
    <defs>
        <linearGradient id="paint0_linear_22882_4366" x1="-1.8999e-07" y1="17" x2="34" y2="17" gradientUnits="userSpaceOnUse">
        <stop stop-color="#2F80ED"/>
        <stop offset="1" stop-color="#9375E4"/>
        </linearGradient>
    </defs>
</svg>

<svg *ngIf="type === 'trash'" width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0117188" width="34" height="34" rx="17" fill="url(#paint0_linear_22532_45613)" fill-opacity="0.6"/>
    <path d="M26.21 26.1458H9.13115C9.08541 25.9326 9.06134 25.7114 9.06134 25.4846C9.06134 23.7519 10.4659 22.3475 12.1984 22.3475C12.2423 22.3475 12.286 22.3488 12.3295 22.3504C12.7929 20.545 14.431 19.2105 16.3811 19.2105C17.9863 19.2105 19.3802 20.1149 20.0816 21.4416C20.401 21.3505 20.738 21.3019 21.0866 21.3019C23.0023 21.3019 24.5741 22.7736 24.7332 24.648H24.7464C25.5469 24.648 26.1972 25.2905 26.2102 26.0876V26.1363C26.2101 26.1395 26.2101 26.1427 26.21 26.1458Z" fill="white"/>
    <path d="M8.2051 26.789C8.2051 26.4338 8.49303 26.1459 8.84819 26.1459H26.426C26.7811 26.1459 27.069 26.4338 27.069 26.789C27.069 27.1442 26.7811 27.4321 26.426 27.4321H8.84819C8.49303 27.4321 8.2051 27.1442 8.2051 26.789Z" fill="white"/>
    <path d="M9.11624 23.7301C8.61396 23.2278 8.61396 22.4134 9.11624 21.9112C9.61853 21.4089 10.4329 21.4089 10.9352 21.9112L12.1478 23.1238C12.6501 23.6261 12.6501 24.4404 12.1478 24.9427C11.6455 25.445 10.8311 25.445 10.3289 24.9427L9.11624 23.7301Z" fill="white"/>
    <path d="M21.8903 20.8742C22.5619 20.183 23.6591 20.1443 24.3777 20.7866C24.8359 21.1961 25.0531 21.8114 24.9537 22.4178L24.78 23.4771C24.5589 24.8257 23.1452 25.6222 21.8772 25.1125C20.4688 24.5464 20.0845 22.7327 21.1423 21.644L21.8903 20.8742Z" fill="white"/>
    <path d="M8.71558 22.0602C8.57774 21.9224 8.57774 21.6989 8.71558 21.5611L8.76339 21.5133C8.90124 21.3754 9.12473 21.3754 9.26257 21.5133L11.6487 23.8994C11.7866 24.0372 11.7866 24.2607 11.6487 24.3986L11.6009 24.4464C11.4631 24.5842 11.2396 24.5842 11.1017 24.4464L8.71558 22.0602Z" fill="white"/>
    <path d="M23.9599 20.9215C23.8608 20.9943 23.7225 20.9145 23.736 20.7923L23.831 19.9305C23.8422 19.8292 23.9537 19.7728 24.0419 19.8237L24.6457 20.1723C24.7339 20.2232 24.7408 20.348 24.6587 20.4084L23.9599 20.9215Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3552 14.9312C20.1919 15.1153 20.1824 15.2527 20.2008 15.3228C20.2752 15.6056 20.1063 15.8952 19.8235 15.9696C19.5407 16.044 19.2511 15.875 19.1768 15.5922C19.0402 15.0731 19.2487 14.5828 19.563 14.2285C19.8802 13.8708 20.3449 13.5968 20.8738 13.4576C21.4784 13.2986 22.0751 13.4443 22.5298 13.7015C22.5777 13.7286 22.6252 13.7576 22.6719 13.7884C22.6974 13.7386 22.7244 13.69 22.7528 13.6428C23.0221 13.1952 23.4698 12.7748 24.0745 12.6157C24.6034 12.4766 25.1427 12.4865 25.5949 12.6418C26.0429 12.7957 26.4656 13.1199 26.6022 13.639C26.6766 13.9218 26.5076 14.2113 26.2248 14.2857C25.942 14.3601 25.6525 14.1912 25.5781 13.9084C25.5596 13.8382 25.4838 13.7233 25.251 13.6433C25.0224 13.5648 24.6988 13.5464 24.3439 13.6398C24.0646 13.7132 23.8218 13.9199 23.6602 14.1886C23.49 14.4716 23.4659 14.7256 23.4951 14.8365L22.471 15.1059C22.4418 14.995 22.2958 14.7857 22.0084 14.6231C21.7355 14.4688 21.4224 14.4083 21.1432 14.4817C20.7883 14.5751 20.5156 14.7503 20.3552 14.9312Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.01879 13.2155C8.06555 13.4837 7.89979 13.7475 7.63176 13.818C7.34897 13.8924 7.05942 13.7234 6.98503 13.4406C6.88514 13.0609 7.04206 12.719 7.24342 12.492C7.44773 12.2617 7.738 12.0939 8.0579 12.0098C8.39325 11.9215 8.71848 11.9778 8.98191 12.0943C9.1539 11.8632 9.40938 11.6543 9.74474 11.566C10.0646 11.4819 10.3999 11.4851 10.6911 11.5851C10.9781 11.6837 11.2829 11.9041 11.3828 12.2838C11.4572 12.5666 11.2882 12.8562 11.0054 12.9305C10.7374 13.0011 10.4633 12.853 10.3721 12.5965C10.366 12.5937 10.3578 12.5903 10.3472 12.5867C10.2796 12.5634 10.16 12.5517 10.0141 12.5901C9.94392 12.6086 9.86443 12.667 9.80472 12.7663C9.77623 12.8136 9.75953 12.8591 9.75217 12.8938C9.74727 12.917 9.74793 12.9279 9.74787 12.9279C9.74783 12.9279 9.7475 12.9235 9.74525 12.915L8.72117 13.1843C8.72342 13.1929 8.72531 13.1969 8.72527 13.1969C8.72522 13.1969 8.72041 13.1871 8.70476 13.1694C8.68126 13.1427 8.64434 13.1114 8.59624 13.0842C8.4954 13.0271 8.39747 13.0154 8.32728 13.0338C8.18136 13.0722 8.08304 13.1412 8.03562 13.1947C8.02813 13.2031 8.02269 13.2101 8.01879 13.2155Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8582 7.74253C17.7817 8.02379 17.4921 8.19046 17.2103 8.11495C16.9279 8.03927 16.7602 7.74895 16.8359 7.4665C16.9515 7.03535 17.3014 6.77786 17.6489 6.66029C18.0007 6.5413 18.412 6.53744 18.8093 6.6439C19.2668 6.76647 19.6034 7.07824 19.8094 7.40828C20.1527 7.22544 20.6002 7.12376 21.0576 7.24633C21.4549 7.35278 21.8092 7.5618 22.0544 7.84072C22.2965 8.11631 22.4709 8.51427 22.3553 8.94542C22.2796 9.22787 21.9893 9.39549 21.7069 9.3198C21.4251 9.2443 21.2576 8.95514 21.332 8.67332C21.3324 8.66439 21.3292 8.61973 21.2589 8.53975C21.1699 8.43842 21.0071 8.32906 20.7835 8.26916C20.6356 8.22953 20.4574 8.25861 20.292 8.35118C20.2116 8.39616 20.1464 8.44991 20.1009 8.50094C20.0786 8.52607 20.0631 8.54813 20.0531 8.5654C20.0482 8.5739 20.0449 8.58064 20.0429 8.58548L20.0407 8.59094C20.0403 8.5922 20.0401 8.59282 20.0401 8.5928L19.0173 8.31873C19.0173 8.31875 19.0174 8.31813 19.0177 8.31682L19.0186 8.31102C19.0192 8.3058 19.0198 8.29834 19.0197 8.28852C19.0197 8.26856 19.0174 8.24172 19.0105 8.20876C18.9967 8.14183 18.9671 8.06268 18.9199 7.98354C18.823 7.82068 18.6832 7.70637 18.5353 7.66674C18.3117 7.60684 18.116 7.62014 17.9883 7.66337C17.8874 7.69749 17.8624 7.73461 17.8582 7.74253ZM17.8576 7.74407C17.8575 7.74405 17.8578 7.74332 17.8582 7.74253L17.8576 7.74407ZM21.3318 8.67499C21.3318 8.67499 21.3318 8.6742 21.332 8.67332L21.3318 8.67499Z" fill="white"/>
    <defs>
        <linearGradient id="paint0_linear_22532_45613" x1="0.0117186" y1="17" x2="34.0117" y2="17" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2F80ED"/>
            <stop offset="1" stop-color="#9375E4"/>
        </linearGradient>
    </defs>
</svg>