<div class="login-page">
    <div class="login-page__wrapper">
        <div class="login-page__top">
            <div class="login-page__header">
                <div class="login-page__logo">
                    <div class="login-logo"></div>
                </div>
            </div>
            <div
                class="login-page__body">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div>
            <div class="login-page__footer">
                <div class="contacts-info">
                    <div class="contacts-info__title">
                        Tech support:
                        <a
                            href="mailto:{{ infoEmail }}"
                            class="contacts-info__link contacts-info__email">
                            {{ infoEmail }}
                        </a>
                    </div>

                    <div
                        *ngIf="phone1 && phone2"
                        class="contacts-info__details">
                        <a
                            href="tel:{{ phone1Link }}"
                            class="contacts-info__link contacts-info__phone phone1">
                            {{ phone1 }}
                        </a>

                        <a
                            href="tel:{{ phone2Link }}"
                            class="contacts-info__link contacts-info__phone phone2">
                            {{ phone2 }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="privacy-policy">
                <a
                    target="_blank"
                    [href]="privacyPolicyLink">
                    Privacy Policy
                </a>
            </div>
        </div>
    </div>
    <div
        class="login-page__background"></div>
</div>
