<div class="info-point-pin" [ngClass]="{'add': type === 'add'}">
    <ng-container *ngIf="type === 'add' else defaultTemp">
        <div class="info-point-pin__body plus">
            <div class="info-point-pin__circle plus">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <rect x="9" y="5" width="2" height="10" rx="1" fill="#FAFAFA"/>
                     <rect x="5" y="9" width="10" height="2"  rx="1"  fill="#FAFAFA"/>
                </svg>
            </div>
        </div>
        <div class="info-point-pin__line plus"></div>
        <div class="info-point-pin__end plus"></div>
    </ng-container>
    <ng-template #defaultTemp>
        <div class="info-point-pin__body" (mouseover)="isShowName = true" (mouseleave)="isShowName = false" [ngClass]="{'show-name': isShowName}">
            <div class="info-point-pin__circle" [style]="{ backgroundColor: color }">
                <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.99844 13.9996C2.77164 13.9996 3.39844 13.3728 3.39844 12.5996V6.99961C3.39844 6.22641 2.77164 5.59961 1.99844 5.59961C1.22524 5.59961 0.598438 6.22641 0.598438 6.99961V12.5996C0.598438 13.3728 1.22524 13.9996 1.99844 13.9996Z" fill="white"/>
                    <path d="M1.99844 2.8C2.77164 2.8 3.39844 2.1732 3.39844 1.4C3.39844 0.626801 2.77164 0 1.99844 0C1.22524 0 0.598438 0.626801 0.598438 1.4C0.598438 2.1732 1.22524 2.8 1.99844 2.8Z" fill="white"/>
                </svg>
            </div>
            <div class="info-point-pin__value" *ngIf="isShowName">{{name}}</div>
        </div>
        <div class="info-point-pin__line"></div>
        <div
            class="info-point-pin__end"
            [style]="{ backgroundColor: color }"
        ></div>
    </ng-template>
</div>
