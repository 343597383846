import { Injectable } from '@angular/core';
import { INIT_DAYS_INTERVAL, REFRESH_INTERVAL_STATION } from '../constants';
import { Store } from '@ngrx/store';
import { updateForecastConfig, updateStation } from '../store/actions';
import { selectDatesForecast } from '../store/selectors';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root',
})
export class UpdaterService {
    private _interval: NodeJS.Timeout;
    private _dateTimeObj: Date = new Date();
    private _lastExecute: number = this._dateTimeObj.getTime();
    private _currentTime: number = null;
    private dates: string[];

    constructor(private store: Store) {
        this.store.select(selectDatesForecast).subscribe((dates) => {
            this.dates = dates;
            if (dates && this.checkIsNeedUpdate()) {
                this.init();
            } else {
                this.stop();
            }
        });
    }

    public init(): void {
        if (this._interval) {
            return;
        }
        if (!this.checkIsNeedUpdate()) {
            return;
        }

        this._dateTimeObj = new Date();
        this._currentTime = this._dateTimeObj.getTime();

        if (this._currentTime - this._lastExecute >= REFRESH_INTERVAL_STATION) {
            this._refresher();
        }

        this._interval = setInterval(() => {
            this._refresher();
        }, REFRESH_INTERVAL_STATION);
    }

    public stop(): void {
        clearInterval(this._interval);
        this._interval = null;
    }

    private _refresher() {
        this._dateTimeObj = new Date();
        const lastExecuteStartDay = moment(this._lastExecute).startOf('day');
        this._lastExecute = this._dateTimeObj.getTime();
        const finishDate = moment(this.dates[this.dates.length - 1]);
        const diff = finishDate.diff(lastExecuteStartDay, 'days');
        if (diff < INIT_DAYS_INTERVAL) {
            this.store.dispatch(updateForecastConfig());
        } else {
            this.store.dispatch(updateStation());
        }
    }

    private checkIsNeedUpdate(): boolean {
        if (this.dates.length === 0) {
            return false;
        }
        const datesHaveMicroseconds = this.dates[0].indexOf('.000') !== -1 ? '.000' : '';
        const nowTimeUTC =
            moment().utc().format(`YYYY-MM-DDTHH:00:00${datesHaveMicroseconds}`) + 'Z';
        return this.dates.indexOf(nowTimeUTC) !== -1;
    }
}
