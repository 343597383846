import {
    ApiRequest,
    DeviceSourceItem,
    DeviceItem,
    MoItem,
    MoLocationItem,
    RegionCoefsItem,
    PacketValueType,
    PacketsValueTypeItem,
} from '@cityair/modules/login/services/harvester-api/models';

export enum MoIntervalType {
    Interval5M = 1,
    Interval20M = 2,
    Interval1H = 3,
    Interval1D = 4,
    Source = 100,
}

export enum DeviceExportType {
    DevicePacketsXls = 1,
    DevicePacketsCsv,
    DeviceHealthReports,
}

export class DeviceExportDesc {
    ExportType: DeviceExportType;
    Title: string;
    Filename: string;
}

// ------------------------------

export enum NotificationSubscribeType {
    None = 0,
    PdkExcess,
    ServiceDevice,
}

export enum NotificationEventType {
    None = 0,
    Begin,
    Continue,
    End,
}

// ------------------------------

export class GroupsRolesItem {
    GroupRoleId: number;
    Name: string;
    NameRu: string;

    EditGroup: boolean;
    EditStation: boolean;
    EditUser: boolean;
    EditDeviceProperties: boolean;
    DownloadDeviceData: boolean;

    ViewDeviceData: boolean;
    ViewStationData: boolean;

    AllowUiAdminApp: boolean;
}

export class GroupsModuleItem {
    GroupModuleId: number;
    Name: string;
    NameRu: string;
}

export class GroupsRolesMemberItem {
    UserId: number;
    GroupId: number;
    GroupRoleId: number; // if GroupRoleId == 0 remove group role from Login
}

export class GroupsMosUsersItem {
    UserId: number;
    GroupId: number;
    MonitoringObjectsIds: number[];
}

export class PdkMrMoCheckSetting {
    IsForAllMoItems = true;
    SelectedMoItems: number[] = [];

    IsForAnyValueType = true;
    SelectedValueTypes: PacketValueType[] = [];

    IntervalType: MoIntervalType = MoIntervalType.Interval20M;

    // Умножение этого параметра на PdkMr даёт величину выше которой будет срабатывать оповещение
    PdkMrMultiplier = 1.5;

    // Уведомлять о продолжении превышения (Мыло о Continue или поднимать плашку в Feed)
    IsContinueNotify = false;
}

export class ServiceDeviceCheckSetting {
    NoDataFromDevicesWithMoDurationInHours = 3;
    NoPs220InDevicesWithMoDurationInHours = 3;
    NotifyCertificateExpirationInDays = 14;
    IsForAllMoItems = true;
    SelectedMoItems: number[] = [];
}

export class NotifyMethodSetting {
    // Уведомлять всех членов группы (кроме наблюдателей),
    // NotificationFeedUserIds - не действует
    NotifyAllGroupMembers: boolean;

    // Иды юзеров которые будут видеть оповещения от данного NotificationId
    NotificationFeedUserIds: string[];

    // Email'ы по которым будут рассылаться уведомления почтой
    NotificationEmails: string[] = [];

    // Список Emails для отсылки "отчета за день"
    DailyReportEmails: string[] = [];
    DailyReportStartHour = 0;
    DailyReportTimeZone: number;
}

export class GroupNotificationSetting {
    NotificationId: number;
    NotificationTitle: string;
    GroupId: string;
    IsActive: boolean;
    SubscribeType: NotificationSubscribeType;

    NotifySetting: NotifyMethodSetting;
    PdkCheckSetting: PdkMrMoCheckSetting;
    ServiceCheckSetting: ServiceDeviceCheckSetting;
}

export enum GroupNotificationChangeType {
    Add = 0,
    Edit,
    Delete,
}

export class GroupNotificationChange {
    ChangeType: GroupNotificationChangeType;
    Item: GroupNotificationSetting;
}

//------------------------------------------

export class GroupItem {
    GroupId: number;
    Name: string;
    Description: string;
    MoIds: number[];
    DeviceIds: number[];
    LocationIds: number[];
    RoleMembers: GroupsRolesMemberItem[];
    MosUsers: GroupsMosUsersItem[];
    ModulesIds: number[];
    RegionCoefsId?: number;
    ExtConfig: { [key: string]: string };
    GroupIconUrl: string;
    GroupWhiteLabelUrl: string;

    // front only
    StationsCount: number;
    UserStationsCount: number;
    IsChecked: boolean;

    constructor(groupId: number, name: string) {
        this.GroupId = groupId;
        this.Name = name;
        this.Description = '';
        this.MoIds = [];
        this.DeviceIds = [];
        this.LocationIds = [];
        this.RoleMembers = [];
        this.MosUsers = [];
        this.ExtConfig = {};
    }
}

// ------------------------------

export class UserPortalPageItem {
    Title: string;
    IsChecked: boolean;

    constructor(title: string, isChecked: boolean) {
        this.Title = title;
        this.IsChecked = isChecked;
    }
}

export class CpUserItem {
    UserId: number;
    Login: string;
    Email: string;
    BioInfo: string;
    SiteLang: string;
    Tag: string;
    IsAdmin: boolean;
    MeasureScheme: string;
    UserPermissions: UserPermission[];

    // Используется внутри moLinksView
    GroupRoleId: number;
}

export class CpUserItemPermission {
    UserId: number;
    Login: string;

    MoIds: number[];
    DeviceIds: number[];
    LocationIds: number[];
    GroupMembers: GroupsRolesMemberItem[];
}

export class UserTokenRequest extends ApiRequest {
    Item: CpUserToken;

    constructor(tokenId: number, userId: string, title: string) {
        super();
        this.Item = {
            TokenId: tokenId,
            UserId: userId,
            Title: title,
            CreateDate: new Date(),
            LastDate: new Date(),
            ExecCount: 0,
            IsApiKey: false,
            RequestIp: null,
            UserAgent: null,
        };
    }
}

//-------------------------------

export class AdminApiRequest extends ApiRequest {}

export class GroupsListResponse {
    Groups: GroupItem[];
    GroupsRoles: GroupsRolesItem[];
    GroupsModules: GroupsModuleItem[];
    CityairLocations: MoLocationItem[];
    PacketValueTypes: PacketsValueTypeItem[];
}

//--------------

export class GroupRequest extends AdminApiRequest {
    Item: GroupItem;

    constructor(item: GroupItem) {
        super();
        this.Item = item;
    }
}

export class GroupInfoResponse {
    Group: GroupItem;
    GroupsRoles: GroupsRolesItem[];
    RegionCoefs: RegionCoefsItem[];
    DeviceExportTypes: DeviceExportDesc[];
    GroupNotificationSettings: GroupNotificationSetting[];
    DeviceSources: DeviceSourceItem[];
    Devices: DeviceItem[];
    MonitoringObjects: MoItem[];
    Users: CpUserItem[];
}

// --------------

export class GroupNotificationChangeRequest extends AdminApiRequest {
    ChangeType: GroupNotificationChangeType;
    Item: GroupNotificationSetting;

    constructor(changeType: GroupNotificationChangeType, item: GroupNotificationSetting) {
        super();
        this.ChangeType = changeType;
        this.Item = item;
    }
}

// --------------

export class GroupEditPermissionRequest extends AdminApiRequest {
    GroupId: number;
    ItemId: number;
    Permit: boolean;

    constructor(groupId: number, itemId: number) {
        super();
        this.GroupId = groupId;
        this.ItemId = itemId;
        this.Permit = false;
    }
}

// --------------

export class GroupRoleMemberRequest extends AdminApiRequest {
    Item: GroupsRolesMemberItem;

    constructor(item: GroupsRolesMemberItem) {
        super();
        this.Item = item;
    }
}

//--------------

export class UserItemRequest extends AdminApiRequest {
    Item: CpUserItem;

    constructor(item: CpUserItem) {
        super();
        this.Item = item;
    }
}

export enum EmailTypeEnum {
    Welcome = 0,
    ResetPwd,
}

export class UserItemGroupRequest extends UserItemRequest {
    GroupId: number;
    EmailType: EmailTypeEnum;

    constructor(item: CpUserItem, groupId: number, emailType: EmailTypeEnum) {
        super(item);
        this.GroupId = groupId;
        this.EmailType = emailType;
    }
}

export class UsersListResponse {
    MonitoringObjects: MoItem[];
    DeviceItems: DeviceItem[];
    Groups: GroupItem[];
    GroupsRoles: GroupsRolesItem[];
    MeasureSchemes: string[];
    Users: CpUserItem[];
}

export class UserPermissionResponse {
    Permission: CpUserItemPermission;
    Tokens: CpUserToken[];
    UserPermissions: UserPermission[];
    ActiveTokenId?: number;
}

export class UserPermission {
    IsSelected: boolean;
    Description: string;
    Name: string;
    PermissionId: number;
}

export class CpUserToken {
    TokenId: number;
    UserId: string;
    Title: string;
    IsApiKey: boolean;
    CreateDate: Date;
    LastDate: Date;
    ExecCount: number;
    RequestIp: string;
    UserAgent: string;
}

//----------------------------------------------

export class MoExcessInfo {
    MoId: number;
    Name: string;
    TimeZoneIana: string;
    GmtOffsetSeconds: number;
}

export class PdkMoExcessContent {
    MoInfo: MoExcessInfo;
    MaxValue: number;
    PdkValue: number;
    PdkMultiplier: number;
}

export enum ServiceExcessType {
    Unknown = 0,
    DeviceNoPs220 = 1,
    DeviceNoData = 2,
}

export class DeviceExcessInfo {
    DeviceId: number;
    SerialNumber: string;
    DeviceSource: string;
}

export class ServiceExcessContent {
    MoInfo: MoExcessInfo;
    DeviceInfo: DeviceExcessInfo;
}

export class NotificationFeedItem {
    FeedItemId: number;
    NotificationId: number;
    NotificationTitle: string;
    SubscribeType: NotificationSubscribeType;
    EventType: NotificationEventType;

    BeginTime: Date;
    EndTime?: Date;

    // Pdk
    PdkMoId: number;
    PdkValueType: PacketValueType;
    PdkMoContent: PdkMoExcessContent;

    // Service
    ServiceDeviceId: number;
    ServiceExcessType: ServiceExcessType;
    ServiceContent: ServiceExcessContent;

    UpdateTime: Date;
    IsViewed?: boolean;
}

export class UserFeedItemRequest extends AdminApiRequest {
    FeedUserId: string;
    Item: NotificationFeedItem;
    GroupId: string;

    constructor(feedUserId: string, groupId: string, item: NotificationFeedItem) {
        super();
        this.FeedUserId = feedUserId;
        this.GroupId = groupId;
        this.Item = item;
    }
}

export class UserFeedResponse {
    PacketValueTypes: PacketsValueTypeItem[];
    FeedItems: NotificationFeedItem[];
}

export class FeedItemsExportRequest extends AdminApiRequest {
    NotificationId: string;
    TimeBegin: Date;
    TimeEnd: Date;
    TimeZone: number;
    Lang: string;
}

//--------------

export class SetRegionCoefsItemRequest extends AdminApiRequest {
    Item: RegionCoefsItem;

    constructor(item: RegionCoefsItem) {
        super();
        this.Item = item;
    }
}

//--------------

export class CityairLicense {
    Name: string;
    ExpirationDate: Date;
    CityScreenModules: string[];
    Stations: string[];
    GModules: string[];
}

export class CityairLicenseRequest extends AdminApiRequest {
    Item: CityairLicense;

    constructor(item: CityairLicense) {
        super();
        this.Item = item;
    }
}

export class CityairLicenseImportRequest extends AdminApiRequest {
    LicenseBase64: string;

    constructor(licenseBase64: string) {
        super();
        this.LicenseBase64 = licenseBase64;
    }
}
