import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { LANGUAGE, RunImpact, SourceImpact, SourceType } from '@libs/common';
import {
    ImpactActions,
    impactFeature,
    selectActiveRun,
    selectActiveSources,
    selectFilteredSources,
} from '@cityair/modules/impact/store/impact.feature';
import { MAIN_PAGES } from '@libs/common';
import { TEXTS } from '@libs/common';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import {
    ImpactSourceEmissionTotalPipe,
    ImpactTotalEmissionPipe,
} from '@cityair/modules/impact/pipes/source-emission.pipe';

export type SummaryItem = {
    id: string;
    title: string;
    isTitleHighlight: boolean;
    value: number;
    unit?: string;
};

@Component({
    selector: 'ca-impact-sources',
    templateUrl: 'impact-sources.component.html',
    styleUrls: ['impact-sources.component.less'],
    providers: [ImpactSourceEmissionTotalPipe, ImpactTotalEmissionPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactSourcesComponent implements OnDestroy {
    sources$ = this.store.select(selectActiveSources);
    public currentLang = LANGUAGE;
    public textImpact = TEXTS.IMPACT;
    public textNames = TEXTS.NAMES;
    public SourceType = SourceType;
    public ngDestroyed$ = new Subject<void>();
    public sortDirection: number = -1;
    public sortField: string = 'icon_type';
    public summaryItems: SummaryItem[] = [
        {
            id: 'total',
            title: this.textImpact.sources.summary.total,
            isTitleHighlight: true,
            value: 0,
        },
        {
            id: 'type-point',
            title: this.textImpact.sources.summary.typePoint,
            isTitleHighlight: false,
            value: 0,
        },
        {
            id: 'type-square',
            title: this.textImpact.sources.summary.typeSquare,
            isTitleHighlight: false,
            value: 0,
        },
        {
            id: 'emission',
            title: this.textImpact.sources.summary.emission,
            isTitleHighlight: false,
            value: 0,
            unit: this.textImpact.sources.sourceCard.estimatedEmissionsFullUnit,
        },
    ];
    public activeRun: RunImpact;
    public isLoadingSources = impactFeature.selectIsLoadingSources;
    public searchQuery: string;
    public selectFilteredSources = selectFilteredSources;

    readonly mainComponentUrl = `/${MAIN_PAGES.impact}/`;

    constructor(
        public store: Store,
        private router: Router,
        private impactSourceEmissionTotalPipe: ImpactSourceEmissionTotalPipe,
        private impactTotalEmissionPipe: ImpactTotalEmissionPipe
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.sources }));
        this.store
            .select(selectActiveRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.activeRun = data;
            });
        this.store
            .select(selectActiveSources)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                const sources = data;
                let typePoint = 0;
                let typeSquare = 0;

                sources.map((source: SourceImpact) => {
                    if (source.geo_type) {
                        if (source.geo_type === SourceType.area) {
                            typeSquare++;
                        } else if (source.geo_type === SourceType.point) {
                            typePoint++;
                        }
                    } else {
                        const bbox = source?.bbox.map((v) => +v.toFixed(2));
                        if (bbox[0] === bbox[2] && bbox[1] === bbox[3]) {
                            typePoint++;
                        } else {
                            typeSquare++;
                        }
                    }
                });

                const emission = this.impactSourceEmissionTotalPipe.transform(
                    sources,
                    this.activeRun
                );

                const totalValue = this.summaryItems.find((item) => item.id === 'total');
                const totalPoint = this.summaryItems.find((item) => item.id === 'type-point');
                const totalSquare = this.summaryItems.find((item) => item.id === 'type-square');
                const totalEmission = this.summaryItems.find((item) => item.id === 'emission');
                totalValue.value = data.length;
                totalPoint.value = typePoint;
                totalSquare.value = typeSquare;
                totalEmission.value = Math.round(this.impactTotalEmissionPipe.transform(emission));
            });
        this.store
            .select(impactFeature.selectSearchQuerySource)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((query) => {
                this.searchQuery = query;
            });
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
    }

    public setSortingCb(sortCb: string): void {
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
    }

    goToSource(source: SourceImpact) {
        this.router.navigate([`${this.mainComponentUrl}${IMPACT_PAGES.sources}/${source.id}`]);
    }

    public textChangeIn($event) {
        this.store.dispatch(ImpactActions.changedSearchQuerySource({ payload: $event }));
    }

    public clearFilter() {
        this.store.dispatch(ImpactActions.changedSearchQuerySource({ payload: '' }));
    }
}
