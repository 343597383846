import { Component, OnDestroy } from '@angular/core';
import { getColorFromZone } from '@cityair/utils/utils';
import { TEXTS } from '@libs/common';
import { ColorZone } from '@libs/common';
import { getDigitsAfterDot } from '@libs/common';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { selectPostNameById } from '@libs/shared-store';
import { Station } from '@cityair/modules/plumes/services/station/models';
import {
    ImpactActions,
    selectImpactStations,
    selectActiveMmt,
    getStationImpactValue,
    selectActiveStation,
    selectImpactSchemaZones,
    selectIsLoadingPostsTimeline,
} from '@cityair/modules/impact/store/impact.feature';
import { selectCurrentMeasureScheme } from '@libs/shared-store';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';

@Component({
    selector: 'impact-posts',
    templateUrl: './impact-posts.component.html',
    styleUrls: ['./impact-posts.component.less'],
})
export class ImpactPostsComponent implements OnDestroy {
    public textNames = TEXTS.NAMES;
    public textsScheme = TEXTS.MEASURES_SCHEME;
    texts = TEXTS.IMPACT.controlPoints;
    selectPostNameById = selectPostNameById;
    getStationImpactValue = getStationImpactValue;
    selectImpactStations = selectImpactStations;
    selectIsLoadingPostsTimeline = selectIsLoadingPostsTimeline;
    selectMeasureScheme = selectCurrentMeasureScheme;
    getColorFromZone = getColorFromZone;

    public sortField: string = 'name';
    public sortDirection: number = -1;

    public digitsAfterDot: number;
    public ngDestroyed$ = new Subject<void>();
    public currentMeasure: string;
    public zone: ColorZone;
    public activePost: string;

    constructor(public store: Store) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.posts }));
        store
            .select(selectImpactSchemaZones)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.digitsAfterDot = getDigitsAfterDot(data?.scheme, data?.mmt);
                this.zone = data?.zone;
            });
        store
            .select(selectActiveMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.currentMeasure = data));
        store
            .select(selectActiveStation)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.activePost = data?.id;
            });
    }

    public setSortingCb(sortCb: string): void {
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
    }

    setActiveStation(station) {
        this.store.dispatch(ImpactActions.setActiveStation({ payload: station as Station }));
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }
}
