import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { MapService, SetupImage } from '../map/map.service';
import { Extent } from 'ol/extent';
import { LoadFunction } from 'ol/Image';
import { Layer } from 'ol/layer';

@Component({
    selector: 'shared-ui-ol-image',
    template: '',
})
export class ImageComponent implements OnInit, OnChanges, OnDestroy {
    @Input() className?: string;
    @Input() opacity?: number;
    @Input() url: string;
    @Input() projection?: string;
    @Input() imageLoadFunction: LoadFunction;
    @Input() visible?: boolean;
    @Input() imageExtent?: Extent;
    @Input() maxZoom?: number;
    @Input() zIndex?: number;
    @Input() data?: any;

    private isAdded = false;
    private sub: Subscription;
    private imageInstance: Layer;
    constructor(private mapService: MapService) {}

    ngOnInit() {
        this.mapService.mapCreated$.subscribe(() => {
            this.init();
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        await lastValueFrom(this.mapService.mapCreated$);
        if (!this.isAdded) {
            return;
        }
        if (
            (changes?.url && !changes?.url.isFirstChange()) ||
            (changes?.imageExtent && !changes?.imageExtent.isFirstChange())
        ) {
            this.init();
        }
    }

    ngOnDestroy() {
        if (this.isAdded) {
            this.mapService.removeLayer(this.imageInstance);
        }
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    private init() {
        const image: SetupImage = {
            imageOptions: {
                opacity: this.opacity,
            },
            sourceOptions: {
                url: this.url,
                projection: this.projection,
                imageExtent: this.imageExtent,
                imageLoadFunction: this.imageLoadFunction,
            },
        };
        this.imageInstance = this.isAdded
            ? this.mapService.updateImage(this.imageInstance, image)
            : this.mapService.addImage(image);
        this.isAdded = true;
    }
}
